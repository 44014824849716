import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import useDocumentVersionQuery from 'documents/hooks/useDocumentVersionQuery';
import useDocumentVersionIfcImportMutation from 'documents/hooks/useDocumentVersionIfcImportMutation';
import useDocumentVersionPlanImportMutation from 'documents/hooks/useDocumentVersionPlanImportMutation';
import FileType from 'documents/types/FileType';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useDocumentVersionBuildingUpdateMutation from 'documents/hooks/useDocumentVersionBuildingUpdateMutation';
import useDocumentVersionDisciplinesUpdateMutation from 'documents/hooks/useDocumentVersionDisciplinesUpdateMutation';
import useDocumentVersionFloorUpdateMutation from 'documents/hooks/useDocumentVersionFloorUpdateMutation';

interface DocumentModelImportModalProps {
  documentVersionId: string | undefined,
  onClose: () => void,
}

export default function DocumentModelImportModal({
  documentVersionId,
  onClose,
}: DocumentModelImportModalProps) {
  const { t } = useTranslation('documents');
  const { data: documentVersion, isLoading: isLoadingDocumentVersion } = useDocumentVersionQuery(documentVersionId);
  const { mutateAsync: mutateImportIfcDocumentVersionAsync, isLoading: isLoadingIfcImport } = useDocumentVersionIfcImportMutation();
  const { mutateAsync: mutateImportPlanDocumentVersionAsync, isLoading: isLoadingPlanImport } = useDocumentVersionPlanImportMutation();
  const { mutateAsync: mutateDocumentVersionDisciplinesAsync, isLoading: isLoadingDocumentVersionDisciplinesUpdate } = useDocumentVersionDisciplinesUpdateMutation();
  const { mutateAsync: mutateDocumentVersionBuildingAsync, isLoading: isLoadingDocumentVersionBuildingUpdate } = useDocumentVersionBuildingUpdateMutation();
  const { mutateAsync: mutateDocumentVersionFloorAsync, isLoading: isLoadingDocumentVersionFloorUpdate } = useDocumentVersionFloorUpdateMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { data: floors } = useProjectFloorsQuery();
  const { data: disciplines } = useProjectDisciplinesQuery();
  const { data: buildings } = useProjectBuildingsQuery();
  const areControlsDisabled = useMemo(() => isLoadingDocumentVersion || isLoadingIfcImport || isLoadingPlanImport || isLoadingDocumentVersionDisciplinesUpdate || isLoadingDocumentVersionBuildingUpdate || isLoadingDocumentVersionFloorUpdate, [isLoadingDocumentVersion, isLoadingDocumentVersionBuildingUpdate, isLoadingDocumentVersionDisciplinesUpdate, isLoadingDocumentVersionFloorUpdate, isLoadingIfcImport, isLoadingPlanImport]);
  const documentVersionHasMultipleDisciplines = useMemo(() => !!documentVersion && documentVersion.disciplineMetaDataIds.length > 1, [documentVersion]);

  const [selectedFloorMetaDataId, setSelectedFloorMetaDataId] = useState<string | undefined>(documentVersion?.floorMetaId);
  const [selectedDisciplineMetaDataId, setSelectedDisciplineMetaDataId] = useState<string | undefined>(documentVersion?.disciplineMetaDataIds.length === 1 ? documentVersion?.disciplineMetaDataIds[0] : undefined);
  const [selectedBuildingMetaDataId, setSelectedBuildingMetaDataId] = useState<string | undefined>(documentVersion?.buildingMetaDataId);
  useEffect(() => setSelectedFloorMetaDataId(documentVersion?.floorMetaId), [documentVersion]);
  useEffect(() => setSelectedDisciplineMetaDataId(documentVersion?.disciplineMetaDataIds.length === 1 ? documentVersion?.disciplineMetaDataIds[0] : undefined), [documentVersion]);
  useEffect(() => setSelectedBuildingMetaDataId(documentVersion?.buildingMetaDataId), [documentVersion]);

  const importDocumentVersion = useCallback(async () => {
    if (!documentVersion || !selectedDisciplineMetaDataId || !selectedBuildingMetaDataId || !selectedFloorMetaDataId) return false;
    try {
      if (documentVersion.fileType === FileType.Ifc) {
        await mutateImportIfcDocumentVersionAsync({
          id: documentVersion.id,
          disciplineId: selectedDisciplineMetaDataId,
          buildingId: selectedBuildingMetaDataId,
          floorId: selectedFloorMetaDataId,
        });
      } else {
        await Promise.all([
          await mutateImportPlanDocumentVersionAsync({
            documentVersionId: documentVersion.id,
            disciplineId: selectedDisciplineMetaDataId,
            buildingId: selectedBuildingMetaDataId,
            floorId: selectedFloorMetaDataId,
          }),
          mutateDocumentVersionDisciplinesAsync({
            id: documentVersion.id,
            disciplineIds: [selectedDisciplineMetaDataId],
          }),
          await mutateDocumentVersionBuildingAsync({
            id: documentVersion.id,
            buildingId: selectedBuildingMetaDataId,
          }),
          await mutateDocumentVersionFloorAsync({
            id: documentVersion.id,
            floorId: selectedFloorMetaDataId,
          }),
        ]);
      }
    } catch (error) {
      setErrorMessage(
        (axios.isAxiosError(error)
          ? error.response?.data?.message
            ? error.response?.data?.message
            : error.message
          : 'unknown error'),
      );
      return false;
    }
    return true;
  }, [documentVersion, mutateDocumentVersionBuildingAsync, mutateDocumentVersionDisciplinesAsync, mutateDocumentVersionFloorAsync, mutateImportIfcDocumentVersionAsync, mutateImportPlanDocumentVersionAsync, selectedBuildingMetaDataId, selectedDisciplineMetaDataId, selectedFloorMetaDataId]);

  const onConfirm = useCallback(async () => {
    const success = await importDocumentVersion();
    if (success) {
      onClose();
    }
  }, [importDocumentVersion, onClose]);

  const onCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog id="DocumentModelImportModal" open PaperProps={{ sx: { width: 500 } }}>
      <DialogTitle component="div">
        <Typography variant="h4">{t('document-model-import-modal_title', 'Set up your model file')}</Typography>
        <Typography>{documentVersion?.originalFileName}</Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl sx={{ mt: 2 }} fullWidth>
          <InputLabel id="document-model-import-modal_floor-label">{t('filter-drawer_floor-label', 'Floor')}</InputLabel>
          <Select
            labelId="document-model-import-modal_floor-label"
            label={t('document-model-import-modal_floor-label', 'Floor')}
            value={selectedFloorMetaDataId ?? ''}
            disabled={!floors?.length || areControlsDisabled}
            onChange={(e) => setSelectedFloorMetaDataId(e.target.value || undefined)}
          >
            {floors?.map((floorMetaData) => (
              <MenuItem value={floorMetaData.id} key={floorMetaData.id}>{floorMetaData.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {!!documentVersionHasMultipleDisciplines && !selectedDisciplineMetaDataId && (
          <Alert severity="error" sx={{ mt: 2 }}>{t('document-model-import-modal_multiple-disciplines-warning', 'This IFC file has multiple categories assigned. Please assign a single category to this document version to continue with the import.')}</Alert>
        )}
        <FormControl sx={{ mt: 2 }} fullWidth>
          <InputLabel id="document-model-import-modal_discipline-label">{t('filter-drawer_discipline-label', 'Discipline')}</InputLabel>
          <Select
            labelId="document-model-import-modal_discipline-label"
            label={t('document-model-import-modal_discipline-label', 'Discipline')}
            value={selectedDisciplineMetaDataId ?? ''}
            disabled={!disciplines?.length || areControlsDisabled}
            onChange={(e) => setSelectedDisciplineMetaDataId(e.target.value || undefined)}
            error={!!documentVersionHasMultipleDisciplines && !selectedDisciplineMetaDataId}
          >
            {disciplines?.map((disciplineMetaData) => (
              <MenuItem value={disciplineMetaData.id} key={disciplineMetaData.id}>{disciplineMetaData.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ mt: 2 }} fullWidth>
          <InputLabel id="document-model-import-modal_building-label">{t('filter-drawer_building-label', 'Building')}</InputLabel>
          <Select
            labelId="document-model-import-modal_building-label"
            label={t('document-model-import-modal_building-label', 'Building')}
            value={selectedBuildingMetaDataId ?? ''}
            disabled={!buildings?.length || areControlsDisabled}
            onChange={(e) => setSelectedBuildingMetaDataId(e.target.value || undefined)}
          >
            {buildings?.map((buildingMetaData) => (
              <MenuItem value={buildingMetaData.id} key={buildingMetaData.id}>{buildingMetaData.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {!!errorMessage && (
          <Alert severity="error" sx={{ mt: 2 }}>{errorMessage}</Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onCancel} disabled={areControlsDisabled}>
          {t('document-model-import-modal_cancel', 'Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm} disabled={areControlsDisabled || !selectedFloorMetaDataId || !selectedDisciplineMetaDataId || !selectedBuildingMetaDataId} sx={{ ml: 'auto' }}>
          {t('document-model-import-modal_confirm', 'Import')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
