import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import {
  Menu, MenuList, MenuItem, useTheme, ListItem, ListItemButton, ListItemText, IconButton, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, Alert, Typography, CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ListOptionsIcon from 'icons/ListOptionsIcon';
import LockIcon from '@mui/icons-material/Lock';
import ISxProps from 'common/types/ISxProps';
import CollaboratorRoleDefinition from 'projects/types/CollaboratorRoleDefinition';
import useCollaboratorRoleDefinitionUpdateMutation from 'projects/hooks/useCollaboratorRoleDefinitionUpdateMutation';
import PersistCollaboratorRoleDefinitionDto from 'projects/types/PersistCollaboratorRoleDefinitionDto';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import useCollaboratorRoleDefinitionDeleteMutation from 'projects/hooks/useCollaboratorRoleDefinitionDeleteMutation';
import RoleCollaboratorsListPopup from 'settings/components/RoleCollaboratorsListPopup';

interface RoleDefinitionListItemProps extends ISxProps {
  roleDefinition: CollaboratorRoleDefinition,
  onClick: () => void,
  selected: boolean,
}

export default function RoleDefinitionListItem({
  sx,
  roleDefinition,
  onClick,
  selected,
}: RoleDefinitionListItemProps) {
  const { t } = useTranslation('settings');
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const btnRef = useRef<HTMLButtonElement>(null);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isCollaboratorsListPopupOpen, setIsCollaboratorsListPopupOpen] = useState(false);
  const [renameValue, setRenameValue] = useState<string>(roleDefinition.name);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const { mutateAsync: mutateUpdateAsync, isLoading: isLoadingUpdate } = useCollaboratorRoleDefinitionUpdateMutation();
  const { mutateAsync: mutateDeleteAsync, isLoading: isLoadingDelete } = useCollaboratorRoleDefinitionDeleteMutation();
  const { data: currentProject } = useCurrentProjectQuery();
  const existingRoleDefinitionNames = useMemo(() => (currentProject ? new Set<string>(currentProject?.collaboratorRoleDefinitions.map((d) => d.name.trim())) : undefined), [currentProject]);
  const renameValueHasNamingConflict = useMemo(() => renameValue.trim() !== roleDefinition.name.trim() && existingRoleDefinitionNames?.has(renameValue.trim()), [existingRoleDefinitionNames, renameValue, roleDefinition.name]);

  const onClickRename = useCallback(() => {
    setRenameValue(roleDefinition.name);
    setIsRenameDialogOpen(true);
    setIsMenuOpen(false);
  }, [roleDefinition.name]);

  const onClickConfirmRename = useCallback(async () => {
    setErrorMessage(undefined);
    const originalName = roleDefinition.name;
    const persistDto: PersistCollaboratorRoleDefinitionDto = {
      id: roleDefinition.id,
      name: { value: renameValue.trim() },
    };
    try {
      await mutateUpdateAsync(persistDto);
      setSuccessMessage(t('role-options-button-menu_rename-success-message', 'Role "{{oldRoleName}}" was renamed to "{{newRoleName}}".', { oldRoleName: originalName, newRoleName: renameValue }));
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
    setIsRenameDialogOpen(false);
    setRenameValue(roleDefinition.name);
  }, [getRequestErrorMessage, mutateUpdateAsync, renameValue, roleDefinition, t]);

  const onClickCancelRename = useCallback(() => {
    setIsRenameDialogOpen(false);
    setRenameValue(roleDefinition.name);
    setErrorMessage(undefined);
  }, [roleDefinition.name]);

  const canDelete = useMemo(() => !roleDefinition.isAdmin && currentProject?.collaboratorRoles.every((r) => r.roleDefinitionId !== roleDefinition.id), [currentProject?.collaboratorRoles, roleDefinition.id, roleDefinition.isAdmin]);

  const onClickDelete = useCallback(() => {
    setIsDeleteDialogOpen(true);
    setIsMenuOpen(false);
  }, []);

  const onClickConfirmDelete = useCallback(async () => {
    setErrorMessage(undefined);
    try {
      await mutateDeleteAsync(roleDefinition.id);
      setSuccessMessage(t('role-options-button-menu_delete-success-message', 'Role "{{roleName}}" was deleted.', { roleName: roleDefinition.name }));
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
    setIsDeleteDialogOpen(false);
  }, [getRequestErrorMessage, mutateDeleteAsync, roleDefinition, t]);

  const onClickCancelDelete = useCallback(() => {
    setIsDeleteDialogOpen(false);
  }, []);

  const onClickViewCollaborators = useCallback(() => {
    setIsCollaboratorsListPopupOpen(true);
    setIsMenuOpen(false);
  }, []);

  return (
    <ListItem
      disablePadding
      secondaryAction={selected ? (
        <IconButton onClick={() => setIsMenuOpen(true)} sx={{ ...sx, color: theme.palette.text.primary, minWidth: 'unset' }} ref={btnRef} id="RoleOptionsMenuButton">
          <ListOptionsIcon />
        </IconButton>
      ) : undefined}
    >
      <ListItemButton selected={selected} onClick={onClick}>
        <ListItemText
          primary={(
            <>
              {roleDefinition.name}
              {!!roleDefinition.isAdmin && <LockIcon sx={{ ml: 1, height: '14px' }} />}
            </>
          )}
          primaryTypographyProps={{ sx: { fontWeight: selected ? 600 : undefined, display: 'flex' } }}
        />
      </ListItemButton>
      <Menu
        anchorEl={btnRef.current}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <MenuList
          dense
        >
          <MenuItem onClick={() => onClickRename()} disabled={roleDefinition.isAdmin}>{t('role-options-button-menu_rename-button-label', 'Rename')}</MenuItem>
          <MenuItem onClick={() => onClickDelete()} disabled={!canDelete}>{t('role-options-button-menu_delete-button-label', 'Delete')}</MenuItem>
          <MenuItem onClick={() => onClickViewCollaborators()}>{t('role-options-button-menu_view-collaborators-button-label', 'View Collaborators')}</MenuItem>
        </MenuList>
      </Menu>
      {isRenameDialogOpen && (
        <Dialog open PaperProps={{ sx: { minWidth: '450px' } }}>
          <DialogTitle>{t('role-options-button-menu_rename-dialog-title', 'Rename Role "{{roleName}}"', { roleName: roleDefinition.name })}</DialogTitle>
          <DialogContent sx={{ minHeight: '84px' }}>
            <Box sx={{ pt: 1 }}>
              <TextField
                fullWidth
                label={t('role-options-button-menu_rename-textfield-label', 'New Role Name')}
                value={renameValue}
                onChange={(e) => setRenameValue(e.target.value)}
                error={renameValueHasNamingConflict}
                helperText={renameValueHasNamingConflict ? t('role-options-button-menu_rename-conflict-messge', 'A role with this name already exists') : undefined}
              />
              {!!errorMessage && <Alert sx={{ mt: 1 }} severity="error">{errorMessage}</Alert>}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={onClickCancelRename}>{t('role-options-button-menu_rename-dialog-cancel-button-label', 'Cancel')}</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onClickConfirmRename}
              sx={{ ml: 2 }}
              disabled={!renameValue.trim().length || renameValue.trim() === roleDefinition.name.trim() || renameValueHasNamingConflict || isLoadingUpdate}
            >
              {isLoadingUpdate && <CircularProgress size={12} sx={{ mr: 1 }} />}
              {t('role-options-button-menu_rename-dialog-confirm-button-label', 'Rename')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {isDeleteDialogOpen && (
        <Dialog open>
          <DialogTitle>{t('role-options-button-menu_delete-dialog-title', 'Delete Role "{{roleName}}"', { roleName: roleDefinition.name })}</DialogTitle>
          <DialogContent sx={{ minHeight: '84px' }}>
            <Typography>{t('role-options-button-menu_delete-dialog-message', 'Are you sure you want to permanently delete the role "{{roleName}}"?', { roleName: roleDefinition.name })}</Typography>
            {!!errorMessage && <Alert sx={{ mt: 1 }} severity="error">{errorMessage}</Alert>}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={onClickCancelDelete}>{t('role-options-button-menu_delete-dialog-cancel-button-label', 'Cancel')}</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onClickConfirmDelete}
              disabled={isLoadingDelete}
              sx={{ ml: 2 }}
            >
              {isLoadingDelete && <CircularProgress size={12} sx={{ mr: 1 }} />}
              {t('role-options-button-menu_delete-dialog-confirm-button-label', 'Delete')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {isCollaboratorsListPopupOpen && <RoleCollaboratorsListPopup roleDefinition={roleDefinition} onClose={() => setIsCollaboratorsListPopupOpen(false)} />}
      {!!successMessage && (
      <Dialog open>
        <DialogTitle>{t('role-options-button-menu_success-dialog-title', 'Success')}</DialogTitle>
        <DialogContent>
          <Alert severity="success">
            {successMessage}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setSuccessMessage(undefined)}>{t('role-options-button-menu_success-dialog-close', 'Close')}</Button>
        </DialogActions>
      </Dialog>
      )}
    </ListItem>
  );
}
