import {
  QueryKey, UseQueryOptions, useQuery, useQueryClient,
} from '@tanstack/react-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import useDefaultOdataEndpointQueryFn from 'api/hooks/useDefaultOdataEndpointQueryFn';
import { useCallback, useMemo } from 'react';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';

export default function useDocumentVersionQuery(id: string | undefined, options?: Omit<UseQueryOptions<DocumentVersionDto[] | undefined, unknown, DocumentVersionDto | undefined, QueryKey>, 'queryKey' | 'queryFn' | 'select' | 'initialData' | 'enabled'>) {
  const { getDetailsByIdQueryKey, listQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const queryKey = getDetailsByIdQueryKey(id ?? '');
  const queryFn = useDefaultOdataEndpointQueryFn<DocumentVersionDto>(ApiEndpoint.DocumentVersion, { filter: { id: { eq: id } } });
  const select = useCallback((data: Readonly<DocumentVersionDto>[] | undefined) => data?.[0], []);
  const queryClient = useQueryClient();
  const initialData = useCallback(() => {
    if (!id) return undefined;
    const documentVersion = queryClient.getQueriesData<DocumentVersionDto[] | undefined>(listQueryKey).flatMap((item) => {
      if (!item) return [];
      const [, versions] = item;
      if (!versions) return [];
      return versions;
    }).find((v) => v.id === id);
    return documentVersion ? [documentVersion] : undefined;
  }, [id, listQueryKey, queryClient]);
  const enabled = useMemo(() => Boolean(id), [id]);
  const queryOptions: UseQueryOptions<DocumentVersionDto[] | undefined, unknown, DocumentVersionDto | undefined, QueryKey> = useMemo(() => ({
    ...options,
    queryKey,
    queryFn,
    select,
    initialData,
    enabled,
  }), [options, queryKey, queryFn, select, initialData, enabled]);
  return useQuery(queryOptions);
}
