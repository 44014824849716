import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { gql } from 'graphql-request';
import IssueItemContent, { ISSUE_ITEM_CONTENT_ISSUE_FRAGMENT } from './IssueItemContent';
import * as Request from '../../Helpers/Request';
import { IssueItem_issue } from './__generated__/IssueItem_issue';

export const ISSUE_ITEM_ISSUE_FRAGMENT = gql`
${ISSUE_ITEM_CONTENT_ISSUE_FRAGMENT}
fragment IssueItem_issue on Issue {
  ...IssueItemContent_issue
  id
  viewpoints {
    imageName
    thumbnailImageId
    imageId
  }
}
`;

interface IssueItemProps {
  issue: IssueItem_issue,
  index: any,
  viewIssue: any,
  group: any,
}

export default function IssueItem({
  issue,
  index,
  viewIssue,
  group,
}: IssueItemProps) {
  const [imageUrl, setImageUrl] = useState('');
  const [imageName, setImageName] = useState('');

  useEffect(() => {
    const updateViewpointImages = async () => {
      if (!issue?.viewpoints) return;
      const blobImages = await Promise.all(
        issue.viewpoints
          .filter((item) => item?.imageName)
          .map(async (item) => {
            setImageName(item?.imageName);
            const result = await Request.GET(
              `api/resource/${item?.thumbnailImageId || item?.imageId}`,
            );
            return result.status === 200 ? result.body : null;
          }),
      );
      const image = blobImages.find((b) => !!b);

      if (!image) return;
      const reader = new FileReader();
      // @ts-ignore
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        setImageUrl((reader.result ?? '') as string);
      };
    };
    updateViewpointImages();
  }, [issue]);

  return (
    <Draggable draggableId={`${issue.id}-${group.id}`} index={index}>
      {(provided) => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
          className="issue-item"
          onClick={(e) => e.detail !== 2 && viewIssue(issue)}
          ref={provided.innerRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.draggableProps}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.dragHandleProps}
        >
          <IssueItemContent
            issueId={issue.id}
            issue={issue}
            imageUrl={imageUrl}
            imageName={imageName}
            viewIssue={viewIssue}
          />
        </div>
      )}
    </Draggable>
  );
}
