import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DateRangePicker from 'common/components/DateRangePicker';
import DocumentFilterState from 'documents-filter/types/DocumentFilterState';

interface TimeFilterSectionProps {
  value: DocumentFilterState,
  onChange: (state: DocumentFilterState) => void,
}

export default function TimeFilterSection({
  value,
  onChange,
}: TimeFilterSectionProps) {
  const { t } = useTranslation('documents-filter');
  return (
    <>
      <Typography variant="h4" sx={{ mt: 3 }}>{t('filter-drawer_time-header', 'Time')}</Typography>
      <Typography variant="h5" sx={{ mt: 2 }}>{t('filter-drawer_upload-date-header', 'Upload Date')}</Typography>
      <DateRangePicker value={value.uploadDateRange} onChange={(v) => onChange({ ...value, uploadDateRange: v })} />
      <Typography variant="h5" sx={{ mt: 2 }}>{t('filter-drawer_creation-date-header', 'Creation Date')}</Typography>
      <DateRangePicker value={value.creationDateRange} onChange={(v) => onChange({ ...value, creationDateRange: v })} />
    </>
  );
}
