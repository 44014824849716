import * as Request from "../Helpers/Request";
import { uploadFiles } from "../Helpers/utils";

export const getResource = async (resourceId) => {
  const result = await Request.GET(`api/resource/${resourceId}`);
  return result.status === 200 ? result.body : null;
};

export const uploadResource = async (projectId, token, file) => {
  const url = `${Request.API_URL}api/resource/${projectId}`;
  return uploadFiles(file, token, url);
};
