import { gql } from 'graphql-request';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkedSubTasks_issue } from './__generated__/LinkedSubTasks_issue';

export const LINKED_SUB_TASKS_ISSUE_FRAGMENT = gql`
fragment LinkedSubTasks_issue on Issue {
  children {
    id
    title
  }
}
`;

export interface LinkedSubTasksProps {
  issue: LinkedSubTasks_issue,
  viewIssue: (issue: { id: string }) => void,
}

function LinkedSubTasks({ issue, viewIssue }: LinkedSubTasksProps) {
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);

  return (
    <div className="issue-item__subtask">
      {!!issue.children?.length && (
        <>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div
            className="issue-item__subtask-label cursor-pointer"
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(!isExpanded);
            }}
          >
            <i
              className={`arrow ${!isExpanded ? 'arrow-right' : 'arrow-down'}`}
            />
            <svg className="icon">
              <use xlinkHref="/img/sprite.svg#task" />
            </svg>
            <p>
              {`${issue.children.length} ${t(
                'sub_tasks',
                'Sub Tasks',
              )}`}
            </p>
          </div>
          {isExpanded && (
            <div className="issue-item__subtask-dropdown">
              {issue.children.map((subIssue) => (
                // TODO: replace by interactive element
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
                <p
                  key={`sub-task-${subIssue.id}`}
                  className="issue-item__subtask-dropdown-label text-ellipsis"
                  onClick={(e) => {
                    e.stopPropagation();
                    viewIssue(subIssue);
                  }}
                >
                  {subIssue.title}
                </p>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default LinkedSubTasks;
