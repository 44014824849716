import React, { useCallback, useState } from 'react';
import { Alert, Box, Button, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import MutateLabelDialog, { DialogResult } from 'settings/components/MutateLabelDialog';
import LabelType from 'labels/types/LabelType';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';

export default function ModelSettingsBuildingsPanel() {
  const { t } = useTranslation('settings');
  const { data: buildings } = useProjectBuildingsQuery();
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const onCloseSuccessMessage = useCallback(() => setSuccessMessage(undefined), []);
  const [buildingIdCurrentlyEditing, setBuildingIdCurrentlyEditing] = useState<string | undefined>(undefined);
  const [isCreatingBuilding, setIsCreatingBuilding] = useState<boolean>(false);
  const onClickAdd = useCallback(() => setIsCreatingBuilding(true), []);
  const onCloseDialog = useCallback((result: DialogResult) => {
    setIsCreatingBuilding(false);
    setBuildingIdCurrentlyEditing(undefined);
    if (result === DialogResult.Created) {
      setSuccessMessage(t('model-settings-building-panel_create-building-success-message', 'New building created'));
    } else if (result === DialogResult.Updated) {
      setSuccessMessage(t('model-settings-building-panel_edit-building-success-message', 'The changes to the building have been saved'));
    } else if (result === DialogResult.Deleted) {
      setSuccessMessage(t('model-settings-building-panel_delete-building-success-message', 'Building deleted'));
    }
  }, [t]);
  return (
    <Paper id="ModelSettingsBuildingsPanel" elevation={1} square sx={{ p: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Typography variant="h4">{t('model-settings-building-panel_title', 'Buildings')}</Typography>
        <Button variant="contained" color="primary" onClick={onClickAdd} sx={{ ml: 'auto', pl: 1 }}>
          <AddIcon sx={{ mr: 1 }} />
          {t('model-settings-building-panel_add-button-label', 'Add')}
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('model-settings-building-panel_name-column-header', 'Name')}</TableCell>
            <TableCell>{t('model-settings-building-panel_abbreviation-column-header', 'Abbr.')}</TableCell>
            <TableCell sx={{ width: 35 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {buildings?.map((building) => (
            <TableRow key={building.id}>
              <TableCell>{building.name}</TableCell>
              <TableCell>{building.abbreviation}</TableCell>
              <TableCell>
                <IconButton onClick={() => setBuildingIdCurrentlyEditing(building.id)} size="small">
                  <EditIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!!buildingIdCurrentlyEditing && <MutateLabelDialog labelType={LabelType.Building} id={buildingIdCurrentlyEditing} onClose={onCloseDialog} />}
      {isCreatingBuilding && <MutateLabelDialog labelType={LabelType.Building} onClose={onCloseDialog} />}
      {!!successMessage && (
      <Snackbar open onClose={onCloseSuccessMessage} autoHideDuration={6000}>
        <Alert severity="success" onClose={onCloseSuccessMessage}>{successMessage}</Alert>
      </Snackbar>
      )}
    </Paper>
  );
}
