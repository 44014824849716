import { useEffect, useState } from 'react';
import {
  HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, JsonHubProtocol, LogLevel,
} from '@microsoft/signalr';
import useApiConfig from 'api/hooks/useApiConfig';
import ProjectSyncLogSummaryDto from 'api/types/ProjectSyncLogSummaryDto';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';
import { useNavigate, useParams } from 'react-router-dom';
import UrlParam from 'common/types/UrlParam';
import { useQueryClient } from '@tanstack/react-query';
import ProjectSyncLogSummaryEntryDto from 'api/types/ProjectSyncLogSummaryEntryDto';
import DataType from 'api/types/DataType';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import { useDispatch } from 'react-redux';
import { setSyncData } from 'Containers/ProjectContainer/Actions';
import UserChangedDto from 'api/types/UserChangedDto';
import OperationType from 'api/types/OperationType';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import useDefaultOdataEndpointFetchFn from 'api/hooks/useDefaultOdataEndpointFetchFn';
import DocumentCommentDto from 'documents/types/DocumentCommentDto';

const IS_DEVELOPMENT_ENVIRONMENT = process.env.NODE_ENV === 'development';

export default function useSync() {
  const { syncUrl } = useApiConfig();
  const { authUserToken, signOut } = useVisoplanApiContext();
  const queryClient = useQueryClient();
  const { projectId: currentProjectId } = useParams<UrlParam>();
  const [, setWatchedProjectId] = useState<string | undefined>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { baseQueryKey: projectsBaseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Project);
  const { baseQueryKey: pdfAnnotationBaseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.PdfAnnotation);
  const { baseQueryKey: issueBaseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Issue);
  const { listQueryKey: commentListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Comment);
  const { listQueryKey: modelListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Model);
  const { listQueryKey: documentListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Document);
  const { listQueryKey: activityListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Activity);
  const { listQueryKey: documentVersionListsQueryKey, getDetailsByIdQueryKey: getDocumentVersionDetailsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const { getOdataQueryQueryKey: getDocumentCommentOdataQueryKey, odataQueryQueryKey: documentCommentOdataQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentComment);
  const { listQueryKey: buildingMetaDataListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.BuildingMetaData);
  const { listQueryKey: disciplineMetaDataListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DisciplineMetaData);
  const { listQueryKey: floorMetaDataListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.FloorMetaData);
  const { listQueryKey: issueLogListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.IssueLog);
  const { listQueryKey: folderTreeListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.FolderTree);
  const { listQueryKey: folderListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Folder);
  const { listQueryKey: processListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Process);
  const { listQueryKey: tagListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Tag);
  const { listQueryKey: documentListListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentList);
  const { listQueryKey: issueTypeListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.IssueType);
  const { listQueryKey: issueStatusListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.IssueStatus);
  const { listQueryKey: issuePriorityListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.IssuePriority);
  const { listQueryKey: inboxEmailListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.InboxEmail);
  const { listQueryKey: dynamicLayoutListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DynamicLayout);
  const { listQueryKey: propertyListListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.PropertyList);
  const { listQueryKey: smartViewListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.SmartView);
  const { listQueryKey: filterListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.QueryFilter);
  const { listQueryKey: userGroupListsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.UserGroup);

  const { data: currentUser } = useCurrentUserQuery();
  const fetchDocumentComments = useDefaultOdataEndpointFetchFn<DocumentCommentDto>(ApiEndpoint.DocumentComment);

  const [connectionPromise] = useState<Promise<HubConnection | undefined>>(async () => {
    const connection = new HubConnectionBuilder()
      .withUrl(syncUrl, {
        logMessageContent: IS_DEVELOPMENT_ENVIRONMENT,
        logger: IS_DEVELOPMENT_ENVIRONMENT ? LogLevel.Warning : LogLevel.Error,
        accessTokenFactory: authUserToken ? () => authUserToken.bearer : undefined,
        transport: HttpTransportType.LongPolling,
        headers: { 'Visoplan-Client-Id': 'Visoplan Webclient' },
      })
      .withAutomaticReconnect()
      .withHubProtocol(new JsonHubProtocol())
      .configureLogging(LogLevel.Information)
      .build();

    connection.on('OnProjectChangedSummaryAsync', (dto: ProjectSyncLogSummaryDto) => {
      // TODO: remove this dispatch call (and according setup code elsewhere) as soon as the old redux based sync consumers have all been reworked to react query
      dispatch(setSyncData(dto));

      if (dto.wasProjectUpdated || dto.isProjectDeleted) {
        queryClient.invalidateQueries(projectsBaseQueryKey);
      }
      dto.dataSummaryEntries.forEach((entryDto: ProjectSyncLogSummaryEntryDto) => {
        switch (entryDto.dataType) {
          case DataType.Project:
            queryClient.invalidateQueries(projectsBaseQueryKey);
            queryClient.invalidateQueries(['roleDefinitions', 'list']);
            break;
          case DataType.Issue:
            queryClient.invalidateQueries(issueBaseQueryKey);
            break;
          case DataType.Comment:
            queryClient.invalidateQueries(commentListsQueryKey);
            queryClient.invalidateQueries(issueBaseQueryKey);
            break;
          case DataType.Model:
            queryClient.invalidateQueries(modelListsQueryKey);
            break;
          case DataType.Document:
            queryClient.invalidateQueries(documentListsQueryKey);
            break;
          case DataType.Activity:
            queryClient.invalidateQueries(activityListsQueryKey);
            break;
          case DataType.DocumentVersion:
            // when an entity was edited or deleted, update specific entity's detail cache
            [...entryDto.updatedIds, ...entryDto.deletedIds].forEach((id) => {
              queryClient.invalidateQueries(getDocumentVersionDetailsQueryKey(id));
            });
            // list cache invalidation
            // if an entity was edited, added or removed, any list query can potentially be affected (e.g. any ODATA query)
            queryClient.invalidateQueries(documentVersionListsQueryKey);
            if (entryDto.addedIds.length || entryDto.deletedIds.length) {
              queryClient.invalidateQueries(folderListsQueryKey);
              queryClient.invalidateQueries(folderTreeListsQueryKey);
            }
            queryClient.invalidateQueries(pdfAnnotationBaseQueryKey);
            break;
          case DataType.BuildingMetaData:
            queryClient.invalidateQueries(buildingMetaDataListsQueryKey);
            break;
          case DataType.DisciplineMetaData:
            queryClient.invalidateQueries(disciplineMetaDataListsQueryKey);
            break;
          case DataType.FloorMetaData:
            queryClient.invalidateQueries(floorMetaDataListsQueryKey);
            break;
          case DataType.IssueLog:
            queryClient.invalidateQueries(issueLogListsQueryKey);
            break;
          case DataType.Folder:
            queryClient.invalidateQueries(folderListsQueryKey);
            queryClient.invalidateQueries(folderTreeListsQueryKey);
            break;
          case DataType.Process:
            queryClient.invalidateQueries(processListsQueryKey);
            break;
          case DataType.Tag:
            queryClient.invalidateQueries(tagListsQueryKey);
            break;
          case DataType.DocumentList:
            queryClient.invalidateQueries(documentListListsQueryKey);
            if (entryDto.addedIds?.length || entryDto.deletedIds?.length) {
              queryClient.invalidateQueries(projectsBaseQueryKey);
            }
            break;
          case DataType.IssueType:
            queryClient.invalidateQueries(issueTypeListsQueryKey);
            break;
          case DataType.IssueStatus:
            queryClient.invalidateQueries(issueStatusListsQueryKey);
            break;
          case DataType.IssuePriority:
            queryClient.invalidateQueries(issuePriorityListsQueryKey);
            break;
          case DataType.InboxEmail:
            queryClient.invalidateQueries(inboxEmailListsQueryKey);
            break;
          case DataType.DynamicLayout:
            queryClient.invalidateQueries(dynamicLayoutListsQueryKey);
            break;
          case DataType.CollaboratorRoleDefinition:
            queryClient.invalidateQueries(['roleDefinitions', 'list']);
            break;
          case DataType.PropertyListEntry:
            queryClient.invalidateQueries(propertyListListsQueryKey);
            queryClient.invalidateQueries(['property', 'graphql']);
            break;
          case DataType.SmartView:
            queryClient.invalidateQueries(smartViewListsQueryKey);
            break;
          case DataType.DocumentComment:
            {
              const idsToRequest = entryDto.addedIds.concat(entryDto.updatedIds);
              if (idsToRequest.length > 0) {
                fetchDocumentComments({ filter: { id: { in: idsToRequest } } })
                  .then((comments) => {
                    if (!comments) return;
                    const commentsById = new Map(comments.map((c) => [c.id, c]));
                    entryDto.addedIds.forEach((id) => {
                      const comment = commentsById.get(id);
                      if (!comment) return;
                      const queryKey = getDocumentCommentOdataQueryKey({ filter: { documentVersionId: comment.documentVersionId }, orderBy: 'creationDate desc' });
                      queryClient.setQueryData<DocumentCommentDto[] | undefined>(queryKey, (prev) => {
                        if (!prev) return [comment];
                        let result: DocumentCommentDto[];
                        if (prev.some((c) => c.id === id)) {
                          result = prev.map((c) => (c.id === id ? comment : c)); // deals with possible race condition between cache update and sync handler
                        } else {
                          result = [comment, ...prev];
                        }
                        return result;
                      });
                    });
                    entryDto.updatedIds.forEach((id) => {
                      const comment = commentsById.get(id);
                      if (!comment) return;
                      const queryKey = getDocumentCommentOdataQueryKey({ filter: { documentVersionId: comment.documentVersionId } });
                      queryClient.setQueryData<DocumentCommentDto[] | undefined>(queryKey, (prev) => prev?.map((c) => (c.id === id ? comment : c)));
                    });
                    entryDto.deletedIds.forEach((id) => {
                      queryClient.setQueriesData<DocumentCommentDto[] | undefined>(documentCommentOdataQueryKey, (prev) => prev?.filter((c) => c.id !== id));
                    });
                  });
              }
            }
            break;
          case DataType.Filter:
            queryClient.invalidateQueries(filterListsQueryKey);
            break;
          case DataType.UserGroup:
            queryClient.invalidateQueries(userGroupListsQueryKey);
            queryClient.invalidateQueries(folderTreeListsQueryKey);
            break;
          default:
            break;
        }
      });
    });

    connection.on('OnUserChangedAsync', (dto: UserChangedDto) => {
      // TODO: remove this dispatch call (and according setup code elsewhere) as soon as the old redux based sync consumers have all been reworked to react query
      dispatch(setSyncData(dto));

      if (dto.userId === currentUser?.id) {
        if (dto.operationType === OperationType.Delete) {
          signOut();
        }
        queryClient.invalidateQueries(['user', 'current']);
      } else {
        queryClient.invalidateQueries(['user', 'detail', dto.userId]);
        queryClient.invalidateQueries(['user', 'list']);
      }
    });

    connection.on('OnUserAddedToProjectAsync', (userId: string) => {
      if (!currentUser || userId === currentUser?.id) {
        queryClient.invalidateQueries(['user', 'current']);
      }
      queryClient.invalidateQueries(projectsBaseQueryKey);
    });

    connection.on('OnUserRemovedFromProjectAsync', (userId: string) => {
      if (!currentUser || userId === currentUser?.id) {
        queryClient.invalidateQueries(['user', 'current']);
        navigate('/projects');
      }
      queryClient.invalidateQueries(projectsBaseQueryKey);
    });

    try {
      await connection.start();
    } catch (e) {
      // establishing a sync connection failed => ignore
      if (connection.state === HubConnectionState.Disconnected) return undefined;
    }
    return connection;
  });

  useEffect(() => {
    if (currentUser) {
      connectionPromise.then((connection) => connection?.invoke('WatchCurrentUserAsync'));
    } else {
      connectionPromise.then((connection) => connection?.invoke('StopWatchingCurrentUserAsync'));
    }
  }, [connectionPromise, currentUser]);

  useEffect(() => {
    setWatchedProjectId((previouslyWatchedProjectId) => {
      if (currentProjectId !== previouslyWatchedProjectId) {
        if (previouslyWatchedProjectId) {
          connectionPromise.then((connection) => connection?.invoke('StopWatchingProjectAsync', previouslyWatchedProjectId));
        }
        if (currentProjectId) {
          connectionPromise.then((connection) => connection?.invoke('WatchProjectAsync', currentProjectId));
        }
        return currentProjectId;
      }
      return previouslyWatchedProjectId;
    });
  }, [currentProjectId, connectionPromise]);
}
