import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import FolderCreateDto from 'documents-folders/types/FolderCreateDto';
import FolderDto from 'documents-folders/types/FolderDto';
import useFolderTreeQueryKey from 'documents-folders/hooks/useFolderTreeQueryKey';

export default function useFolderCreateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const folderTreeQueryKey = useFolderTreeQueryKey();
  return useMutation<FolderDto | undefined, unknown, FolderCreateDto>(async (dto: FolderCreateDto) => {
    const { data: folderDto } = await axiosInstance.post<FolderDto>(`${ApiEndpoint.Folder}`, dto);
    return folderDto;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(folderTreeQueryKey);
    },
  });
}
