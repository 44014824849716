import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { VIEWER_OFFSET_Y } from "../../../Helpers/Common";

const MenuColorItem = ({ color, onSelectColor }) => {
  const selectColor = () => {
    onSelectColor(color);
  };

  return (
    <div
      className="viewer-contextmenu_colorItem"
      style={{ backgroundColor: color }}
      onClick={selectColor}
    />
  );
};

const ColorSubMenu = ({ pos, selectColor, onSubMenu }) => {
  const { t } = useTranslation();

  const onReset = () => {
    selectColor("reset color");
  };

  const onMouseEnter = () => {
    onSubMenu(true);
  };

  const onMouseLeave = () => {
    onSubMenu(false);
  };

  return (
    <div
      className="viewer-contextmenu_subItem"
      style={{ position: "absolute", left: `${pos.x}px`, top: `${pos.y}px` }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="viewer-contextmenu_colorSubMenu">
        <MenuColorItem color="#eec56a" onSelectColor={selectColor} />
        <MenuColorItem color="#e56d42" onSelectColor={selectColor} />
        <MenuColorItem color="#ca404e" onSelectColor={selectColor} />
        <MenuColorItem color="#704ca1" onSelectColor={selectColor} />
        <MenuColorItem color="#5fb0e0" onSelectColor={selectColor} />
        <MenuColorItem color="#2cbda8" onSelectColor={selectColor} />
        <MenuColorItem color="#d770ad" onSelectColor={selectColor} />
        <MenuColorItem color="#c2c8cf" onSelectColor={selectColor} />
        <MenuColorItem color="#444b53" onSelectColor={selectColor} />
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div style={{ textDecoration: "underline" }} onClick={onReset}>
          {t("reset", "Reset")}
        </div>
        <div style={{ textDecoration: "underline" }}>{t("close", "Close")}</div>
      </div>
    </div>
  );
};

const MenuItem = ({ content, title, selectItem, style }) => {
  const handleSelectItem = () => {
    selectItem(content);
  };

  return (
    <li onClick={handleSelectItem} style={style}>
      {title}
    </li>
  );
};

const ColorMenuItem = ({ title, selectItem, isExpand }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [menuPos, setMenuPos] = useState({ x: 0, y: 0 });
  const [isOnSubMenu, setIsOnSubMenu] = useState(false);

  const onSelectColor = (color) => {
    selectItem(color);
  };

  const onMouseEnter = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = rect.width + 3;
    const y = isExpand ? 68 : 34;
    setMenuPos({ x, y });
    setShowSubMenu(true);
  };

  const onMouseLeave = () => {
    if (isOnSubMenu) return;

    setTimeout(() => {
      setShowSubMenu(false);
    }, 100);
  };

  const onSubMenu = (isOn) => {
    setIsOnSubMenu(isOn);
  };

  return (
    <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className="menu-item">
        <a aria-haspopup>{title}</a>
        <i className="arrow-line right"></i>
      </div>
      {showSubMenu && (
        <ColorSubMenu
          pos={menuPos}
          selectColor={onSelectColor}
          onSubMenu={onSubMenu}
        />
      )}
    </li>
  );
};

const ViewerContextMenu = ({
  selectItem,
  selectedCount,
  isTransparent,
  menuPos,
}) => {
  const { t } = useTranslation();
  const pos = useMemo(() => {
    let left = menuPos.x;
    let top = menuPos.y;
    const height = selectedCount === 0 ? 70 : 446;

    if (top + height > window.innerHeight) {
      top = top - height;
    }
    if (left + 160 > window.innerWidth) {
      left = left - 160;
    }

    top -= VIEWER_OFFSET_Y;
    return {
      left,
      top,
    };
  }, [menuPos, selectedCount]);
  const onSelectItem = (e) => {
    selectItem(e);
  };

  return (
    <ul
      className="viewer-contextmenu"
      style={{ left: `${pos.left}px`, top: `${pos.top}px` }}
    >
      {selectedCount === 0 ? (
        <>
          <MenuItem
            id="CreateSectionViewerToolbarButton"
            content={"section"}
            title={t("create_section", "Create Section")}
            selectItem={onSelectItem}
          />
          <MenuItem
            id="CreateIssueViewerToolbarButton"
            content={"issue"}
            title={t("create_issue", "Create issue")}
            selectItem={onSelectItem}
          />
        </>
      ) : (
        <>
          <MenuItem
            id="ComponentViewerToolbarButton"
            content={"component"}
            style={{ background: "#4B6FFC", color: "white" }}
            title={`${selectedCount} ${
              selectedCount > 1
                ? t("components", "Components")
                : t("component", "Component")
            }`}
            selectItem={onSelectItem}
          />
          {selectedCount === 1 && (
            <MenuItem
              id="ComponentInformationViewerToolbarButton"
              content="component-info"
              title={t("component_info", "Component Information")}
              selectItem={onSelectItem}
            />
          )}
          <ColorMenuItem
            id="AddColorViewerToolbarButton"
            title={t("add_color", "Add Color")}
            selectItem={onSelectItem}
            isExpand={selectedCount === 1}
          />
          <hr />
          <MenuItem
            id="ZoomToSelectionViewerToolbarButton"
            content={"zoom"}
            title={t("zoom_to_selection", "Zoom to selection")}
            selectItem={onSelectItem}
          />
          <MenuItem
            id="HideObjectViewerToolbarButton"
            content={"hide"}
            title={t("hide_object", "Hide object")}
            selectItem={onSelectItem}
          />
          <MenuItem
            id="HideOtherTypeViewerToolbarButton"
            content={"hide other type"}
            title={t("hide_other_type", "Hide other type")}
            selectItem={onSelectItem}
          />
          <MenuItem
            id="IsolateObjectViewerToolbarButton"
            content={"isolate"}
            title={t("isolate_object", "Isolate object")}
            selectItem={onSelectItem}
          />
          {/* this feature is broken (#15073) and hard to fix, so it was decided to disable it, and instead spend the time on the new viewer
           <MenuItem
            content={"isolate and transparent"}
            title={t("isolate_and_transprent", "Isolate and Transparent")}
            selectItem={onSelectItem}
          /> */}
          <MenuItem
            id="IsolateStoreysViewerToolbarButton"
            content={"isolate storeys"}
            title={t("isolate_storeys", "Isolate Storeys")}
            selectItem={onSelectItem}
          />
          {isTransparent ? (
            <MenuItem
              id="TransparentOnViewerToolbarButton"
              content={"transparent-on"}
              title={t("transparent_on", "Transparent on")}
              selectItem={onSelectItem}
            />
          ) : (
            <MenuItem
              id="TransparentOffViewerToolbarButton"
              content={"transparent-off"}
              title={t("transparent_off", "Transparent off")}
              selectItem={onSelectItem}
            />
          )}
          <MenuItem
            id="ResetStateViewerToolbarButton"
            content={"reset state"}
            title={t("reset_state", "Reset state")}
            selectItem={onSelectItem}
          />
          <hr />
          <MenuItem
            id="CreateSectionViewerToolbarButton"
            content={"section"}
            title={t("create_section", "Create Section")}
            selectItem={onSelectItem}
          />
          <MenuItem
            id="CreateIssueViewerToolbarButton"
            content={"issue"}
            title={t("create_issue", "Create issue")}
            selectItem={onSelectItem}
          />
        </>
      )}
    </ul>
  );
};

export default ViewerContextMenu;
