import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import usePdfAnnotationEntityFactory from 'documents-annotations/hooks/usePdfAnnotationEntityFactory';
import CreatePdfAnnotationDto from 'documents-annotations/types/CreatePdfAnnotationDto';
import ApiEndpoint from 'api/types/ApiEndpoint';
import PdfAnnotation from 'documents-annotations/types/PdfAnnotation';
import PdfAnnotationDto from 'documents-annotations/types/PdfAnnotationDto';
import { useCallback } from 'react';

export default function usePdfAnnotationCreateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { baseQueryKey: annotationsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.PdfAnnotation);
  const { baseQueryKey: issuesQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Issue);
  const { baseQueryKey: documentVersionsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const entityFactory = usePdfAnnotationEntityFactory();
  const mutationFn = useCallback(async (dto: CreatePdfAnnotationDto) => {
    const { data: updatedPdfAnnotationDto } = await axiosInstance.post<PdfAnnotationDto>(`${ApiEndpoint.PdfAnnotation}`, dto);
    if (!updatedPdfAnnotationDto) throw new Error('The server responded with an empty result.');
    const createdEntity = entityFactory(updatedPdfAnnotationDto);
    return createdEntity;
  }, [axiosInstance, entityFactory]);
  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: annotationsQueryKey });
    queryClient.invalidateQueries({ queryKey: issuesQueryKey });
    queryClient.invalidateQueries({ queryKey: documentVersionsQueryKey });
  }, [annotationsQueryKey, documentVersionsQueryKey, issuesQueryKey, queryClient]);
  return useMutation<PdfAnnotation, unknown, CreatePdfAnnotationDto>({ mutationFn, onSuccess });
}
