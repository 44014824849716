import React, { useCallback, useEffect, useState } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { MenuItem, Select } from '@mui/material';
import useProjectWorkPhasesQuery from 'labels/hooks/useProjectWorkPhasesQuery';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import DocumentVersionWorkPhaseUpdateDto from 'documents/types/DocumentVersionWorkPhaseUpdateDto';
import useDocumentVersionWorkPhaseUpdateMutation from 'documents/hooks/useDocumentVersionWorkPhaseUpdateMutation';
import { useTranslation } from 'react-i18next';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import useCurrentUserFolderAccessLevel from 'documents-folders/hooks/useCurrentUserFolderAccessLevel';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';

export default function WorkPhaseCell({
  data,
  value,
}: ICellRendererParams<DocumentsDataGridRow, string | undefined>) {
  const { t } = useTranslation('documents');
  const currentUserRole = useCurrentUserRole();
  const { data: workphases } = useProjectWorkPhasesQuery();
  const { mutateAsync, isLoading } = useDocumentVersionWorkPhaseUpdateMutation();
  const [displayValue, setDisplayValue] = useState<string>(value ?? '');
  const accessLevel = useCurrentUserFolderAccessLevel(data?.folderId);

  const onChange = useCallback(async (selectedWorkMetadataId: string) => {
    if (!data) return;
    setDisplayValue(selectedWorkMetadataId);
    const persistDto: DocumentVersionWorkPhaseUpdateDto = {
      id: data.id,
      workPhaseId: selectedWorkMetadataId || undefined,
    };
    await mutateAsync(persistDto);
  }, [data, mutateAsync]);

  useEffect(() => setDisplayValue(value ?? ''), [value]);

  return (
    <Select
      value={displayValue}
      disabled={!workphases?.length || isLoading || data?.isArchived || !currentUserRole?.allowedActions?.has(RoleAction.Document_Editing) || accessLevel !== CollaboratorFolderAccessLevel.ReadWrite}
      onChange={(e) => onChange(e.target.value)}
      variant="standard"
      sx={{ width: '100%' }}
    >
      <MenuItem value=""><i>{t('workphase-cell_value-none', 'Unassigned')}</i></MenuItem>
      {workphases?.map((workphaseMetaData) => (
        <MenuItem value={workphaseMetaData.id} key={workphaseMetaData.id}>{workphaseMetaData.name}</MenuItem>
      ))}
    </Select>
  );
}
