import React from 'react';
import ISxProps from 'common/types/ISxProps';
import HeaderBox from 'common/components/HeaderBox';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

interface TimelinePageHeaderProps extends ISxProps {
}

export default function TimelinePageHeader({
  sx,
}: TimelinePageHeaderProps) {
  const { t } = useTranslation('timeline');
  return (
    <HeaderBox id="TimelinePageHeader" sx={{ ...sx, boxShadow: 'inset 2px 2px 4px -2px rgba(0,0,0,0.1)' }}>
      <Typography variant="h2" sx={{ width: 292, pl: 4 }}>{t('timeline-page-header_title', 'Timeline')}</Typography>
    </HeaderBox>
  );
}
