import React, { useCallback, useContext, useMemo } from 'react';
import { Checkbox } from '@mui/material';
import DocumentSelectionContext, { DocumentSelectionContextState } from 'documents/contexts/DocumentSelectionContext';
import DocumentStatisticsContext, { DocumentStatisticsContextState } from 'documents/contexts/DocumentStatisticsContext';

export default function CheckboxHeaderCell() {
  const { selectedDocumentVersionIds, nonDeselectableVersionIds, setSelectedDocumentVersionIds } = useContext<DocumentSelectionContextState>(DocumentSelectionContext);
  const { currentDocumentVersionIds } = useContext<DocumentStatisticsContextState>(DocumentStatisticsContext);
  const allSelected = useMemo(() => !!currentDocumentVersionIds?.length && selectedDocumentVersionIds.length === currentDocumentVersionIds.length, [currentDocumentVersionIds, selectedDocumentVersionIds.length]);
  const indeterminate = useMemo(() => !!currentDocumentVersionIds && selectedDocumentVersionIds.length > 0 && selectedDocumentVersionIds.length < currentDocumentVersionIds.length, [currentDocumentVersionIds, selectedDocumentVersionIds.length]);
  const disabled = useMemo(() => !currentDocumentVersionIds?.length, [currentDocumentVersionIds?.length]);

  const onClick = useCallback(() => {
    if (allSelected) setSelectedDocumentVersionIds(Array.from(nonDeselectableVersionIds ?? []));
    else setSelectedDocumentVersionIds(currentDocumentVersionIds ?? []);
  }, [allSelected, currentDocumentVersionIds, nonDeselectableVersionIds, setSelectedDocumentVersionIds]);

  return <Checkbox checked={allSelected} disabled={disabled} indeterminate={indeterminate} sx={{ ml: '1px' }} onClick={onClick} />;
}
