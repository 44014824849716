import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  VIEWER_MARGIN,
  VIEWER_OFFSET_X,
  VIEWER_OFFSET_Y,
} from "../../../Helpers/Common";
import CustomSelect from "../../CustomSelect";
import { getGeneralSvg } from "../../SVGs";
import Switch from "../../Switch";
import { TOOLBAR_POS } from "./ViewerToolbar";

const ViewpointSettingPanel = ({
  containerRect,
  closePanel,
  isVisible,
  setVisible,
  filterSetting,
  setFilterSetting,
  statuses,
  types,
  users,
  priorities,
  disciplines,
  floors,
  buildings,
}) => {
  const { t } = useTranslation();
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [isMoving, setIsMoving] = useState(false);
  const default_select = { value: null, label: t("all", "All") };
  const [filterItem, setFilterItem] = useState({
    status: default_select,
    type: default_select,
    assigner: default_select,
    priority: default_select,
    discipline: default_select,
    building: default_select,
    floor: default_select,
  });
  const movingRef = useRef(isMoving);
  const rectRef = useRef(containerRect);

  useEffect(() => {
    document.addEventListener("mousemove", onDocMouseMove);
    document.addEventListener("mouseup", onDocMouseUp);

    return () => {
      document.removeEventListener("mousemove", onDocMouseMove);
      document.removeEventListener("mouseup", onDocMouseUp);
    };

    function onDocMouseMove(e) {
      if (movingRef.current) {
        calculatePos(e.clientX - VIEWER_OFFSET_X, e.clientY - VIEWER_OFFSET_Y);
      }
    }

    function onDocMouseUp(e) {
      if (movingRef.current && e.button === 0) {
        setIsMoving(false);
      }
    }
  }, []);

  useEffect(() => {
    movingRef.current = isMoving;
  });

  useEffect(() => {
    rectRef.current = containerRect;

    if (pos.x === 0 && pos.y === 0) {
      const domString = localStorage.getItem(TOOLBAR_POS);
      if (domString) {
        const toolbarHtml = document.getElementById("viewer-toolbar");
        const toolbarPos = JSON.parse(domString);
        calculatePos(
          toolbarPos.x + toolbarHtml.clientWidth + VIEWER_MARGIN,
          toolbarPos.y
        );
      }
    } else {
      calculatePos(pos.x, pos.y);
    }
  }, [containerRect]);

  useEffect(() => {
    document.documentElement.style.cursor = isMoving ? "move" : "default";
  }, [isMoving]);

  useEffect(() => {
    const item = {
      status: statuses.find((s) => s.value === filterSetting.status),
      type: types.find((t) => t.value === filterSetting.type),
      assigner: users.find((u) => u.value === filterSetting.assigner),
      priority: priorities.find((p) => p.value === filterSetting.priority),
      discipline: disciplines.find((d) => d.value === filterSetting.discipline),
      building: buildings.find((b) => b.value === filterSetting.building),
      floor: floors.find((f) => f.value === filterSetting.floor),
    };

    setFilterItem(item);
  }, [filterSetting]);

  const onMouseDown = (e) => {
    if (e.button === 0) {
      setIsMoving(true);
    }
  };

  const onMouseUp = (e) => {
    if (isMoving && e.button === 0) {
      setIsMoving(false);
    }
  };

  const calculatePos = (posX, posY) => {
    if (rectRef.current) {
      const htmlElement = document.getElementById("viewpoint-setting-panel");
      const width = htmlElement ? htmlElement.clientWidth : 0;
      const height = htmlElement ? htmlElement.clientHeight : 0;

      const x = Math.min(
        Math.max(posX, rectRef.current.left - VIEWER_OFFSET_X + VIEWER_MARGIN),
        rectRef.current.right - width - VIEWER_OFFSET_X - VIEWER_MARGIN
      );
      const y = Math.min(
        Math.max(posY, rectRef.current.top - VIEWER_OFFSET_Y + VIEWER_MARGIN),
        rectRef.current.bottom - VIEWER_OFFSET_Y - height - VIEWER_MARGIN
      );

      setPos({ x, y });
    }
  };

  const handleVisibility = () => {
    setVisible(!isVisible);
  };

  const handleApply = () => {
    const setting = {
      status: filterItem.status.value,
      type: filterItem.type.value,
      assigner: filterItem.assigner.value,
      priority: filterItem.priority.value,
      discipline: filterItem.discipline.value,
      building: filterItem.building.value,
      floor: filterItem.floor.value,
    };

    setFilterSetting(setting);
  };

  const handleChangeFilterItem = (key, value) => {
    const item = { ...filterItem, [key]: value };
    setFilterItem(item);
  };

  return (
    <div
      id="viewpoint-setting-panel"
      className="clipping-panel"
      style={{
        left: `${pos.x}px`,
        top: `${pos.y}px`,
        width: "400px",
        height: "520px",
      }}
      onMouseUp={onMouseUp}
    >
      <div className="clipping-panel_header">
        <div className="moving" onMouseDown={onMouseDown}>
          {getGeneralSvg("moving-label")}
        </div>
        <div className="section-manager">
          <label>{t("viewpoint_settings", "Viewpoint Settings")}</label>
          <div style={{ cursor: "pointer" }} onClick={closePanel}>
            {getGeneralSvg("clipping-close")}
          </div>
        </div>
      </div>
      <div className="clipping-panel_viewpoint_setting">
        <div className="visibility">
          <label>{t("visibility", "Visibility")}</label>
          <div className="visibility mark-viewpoint">
            <label>{t("marked_viewpoints", "Marked Viewpoints")}</label>
            <Switch handleChange={handleVisibility} checked={isVisible} />
          </div>
        </div>
        <div className="setting-board">
          <label>{t("filter", "Filter")}</label>
          <div className="select-item">
            <div>
              <p>{t("issue_state", "Issue State")}</p>
              <CustomSelect
                values={statuses}
                value={filterItem.status}
                onChange={(value) => handleChangeFilterItem("status", value)}
                isModel={true}
              />
            </div>
            <div>
              <p>{t("type", "Type")}</p>
              <CustomSelect
                values={types}
                value={filterItem.type}
                onChange={(value) => handleChangeFilterItem("type", value)}
                isModel={true}
              />
            </div>
          </div>
          <div className="select-item">
            <div>
              <p>{t("assigned_to", "Assigned to")}</p>
              <CustomSelect
                values={users}
                value={filterItem.assigner}
                onChange={(value) => handleChangeFilterItem("assigner", value)}
                isModel={true}
              />
            </div>
            <div>
              <p>{t("priority", "Priority")}</p>
              <CustomSelect
                values={priorities}
                value={filterItem.priority}
                onChange={(value) => handleChangeFilterItem("priority", value)}
                isModel={true}
              />
            </div>
          </div>
          <label>{t("model", "Model")}</label>
          <div className="select-item">
            <div>
              <p>{t("discipline", "Discipline")}</p>
              <CustomSelect
                values={disciplines}
                value={filterItem.discipline}
                onChange={(value) =>
                  handleChangeFilterItem("discipline", value)
                }
                isModel={true}
              />
            </div>
            <div>
              <p>{t("building", "Building")}</p>
              <CustomSelect
                values={buildings}
                value={filterItem.building}
                onChange={(value) => handleChangeFilterItem("building", value)}
                isModel={true}
              />
            </div>
          </div>
          <div className="select-item">
            <div>
              <p>{t("floor", "Floor")}</p>
              <CustomSelect
                values={floors}
                value={filterItem.floor}
                onChange={(value) => handleChangeFilterItem("floor", value)}
                isModel={true}
              />
            </div>
          </div>
        </div>
        <div className="viewpoint_btnSetting">
          <button
            className="btn btn--primary"
            style={{ width: "120px", height: "30px" }}
            onClick={handleApply}
          >
            {t("apply_filter", "Apply Filter")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewpointSettingPanel;
