import useDefaultEntityFactory from 'api/hooks/useDefaultEntityFactory';
import { useCallback } from 'react';
import User from 'users/types/User';
import { UserDto } from 'users/types/UserDto';

export default function useUserEntityFactory() {
  const defaultEntityFactory = useDefaultEntityFactory<UserDto, User>();
  return useCallback((dto: UserDto): Readonly<User> => ({
    ...defaultEntityFactory(dto),
    editDateParsed: new Date(dto.editDate),
    creationDateParsed: new Date(dto.editDate),
  }), [defaultEntityFactory]);
}
