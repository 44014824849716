import { useMemo } from 'react';
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { QueryOptions } from 'odata-query';
import useDefaultOdataEndpointQueryFn from 'api/hooks/useDefaultOdataEndpointQueryFn';

interface QueryArg {
  name: string,
  value: string,
}

export interface OdataEndpointOptions {
  includeProjectIdInUrl?: boolean,
  queryArgs?: QueryArg[],
}

export default function useDefaultOdataEndpointQuery<
  TDto,
  TQueryDto = TDto,
  TCachedEntity extends TDto = TDto,
  TSelectedData extends TCachedEntity[] | TCachedEntity | undefined = TCachedEntity[] | undefined>(
  endpoint: ApiEndpoint,
  odataQuery: Partial<QueryOptions<TQueryDto>> | undefined,
  entityFactory?: ((dto: TDto) => Readonly<TCachedEntity>) | undefined,
  options?: Omit<UseQueryOptions<TCachedEntity[] | undefined, unknown, TSelectedData, QueryKey>, 'queryKey' | 'queryFn'>,
  endpointOptions?: OdataEndpointOptions,
) {
  const { getOdataQueryQueryKey } = useDefaultEntityQueryKeys(endpoint);
  const queryKey = useMemo(() => (odataQuery ? getOdataQueryQueryKey(odataQuery) : []), [getOdataQueryQueryKey, odataQuery]);
  const queryFn = useDefaultOdataEndpointQueryFn<TDto, TQueryDto, TCachedEntity>(endpoint, odataQuery, entityFactory, endpointOptions);
  return useQuery<TCachedEntity[] | undefined, unknown, TSelectedData>(queryKey, queryFn, { ...options, enabled: (options?.enabled ?? true) && (!!odataQuery) });
}
