import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import {
  useIssueFilterItems,
  usePreviewVersions,
  useTags,
} from "../Helpers/Hooks";
import { MODE_ADD_NONE, SyncDataType } from "../Helpers/Common";
import {
  DocumentService,
  EmailService,
  IssueService,
  ModelService,
  ProjectService,
} from "../Services";
import {
  setModelLeaveConfirm,
  setCancelIssueCreation,
} from "../Containers/ProjectContainer/Actions";
import { getChangedIds } from "../Helpers/utils";

import CustomSelect from "../Components/CustomSelect";
import NotificationModal from "../Components/NotificationModal";
import { getGeneralSvg } from "../Components/SVGs";
import ModelListFilter from "../Components/Models/ModelListFilter";
import ModelView from "../Components/Models/ModelView";
import ModelItem from "../Components/Models/ModelItem";
import Loader from "../Components/Loader";
import ViewerSettingModal from "../Components/Models/Viewer/ViewerSettingModal";
import OpenWindowsClientLink from "../Components/OpenWindowsClientLink/OpenWindowsClientLink";
import ModelComponentInfo from "../Components/Models/ModelComponentInfo";
import IssueOverviewModalOld from "../Components/IssueBoard/IssueOverviewModalOld";
import { Modal } from "../HOC";
import DocumentPreviewModal from "../Components/DocumentPreviewModal";
import ModelIssues from "../Components/Models/ModelIssues";
import IssueModal from "../Components/IssueModal";
import IssueSavedModal from "../Components/IssueSavedModal";
import { DEFAULT_SETTING } from "../Helpers/Viewer";
import useCurrentProjectQuery from "projects/hooks/useCurrentProjectQuery";
import RoleAction from "projects/types/RoleAction";
import NotAllowed from "error/NotAllowed";
import SuspenseWithErrorBoundary from 'error/SuspenseWithErrorBoundary';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';
import useLegacySettingsNotificationDialogContext from 'settings/hooks/useLegacySettingsNotificationDialogContext';

const VIEWER_SETTING = "ifc_viewer_setting";

export default function Models() {
  const { setHasUnsavedChanges } = useLegacySettingsNotificationDialogContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: currentProject } = useCurrentProjectQuery();
  const { data: disciplines } = useProjectDisciplinesQuery();
  const { data: buildings } = useProjectBuildingsQuery();
  const { data: floors } = useProjectFloorsQuery();
  const { sync_data } = useSelector((state) => state.project);
  const [isIssueModal, setIsIssueModal] = useState(false);
  const [modalCount, setModalCount] = useState(1);
  const [issueSavedModalVisible, setIssuesSavedModalVisible] = useState(false);
  const [selectOptions, setSelectOptions] = useState({
    discipline: [],
    building: [],
    floor: [],
  });
  const [allModelFiles, setAllModelFiles] = useState([]);
  const [filterMetaData, setFilterMetaData] = useState({
    disciplines: [],
    buildings: [],
    floors: [],
  });
  const [curFileVersionList, setCurFileVersionList] = useState([]);
  const [selectedModelFiles, setSelectedModelFiles] = useState([]);
  const [selectedComponentInfo, setSelectedComponentInfo] = useState({
    gIds: [],
    mIds: [],
    components: [],
  });
  const [componentInfo, setComponentInfo] = useState({
    gIds: [],
    mIds: [],
  });
  const [issueModalProps, setIssueModalProps] = useState({});
  const [isModifiedIssueComponents, setIsModifiedIssueComponents] =
    useState(false);
  const [addIssueComponents, setAddIssueComponents] = useState([]);
  const [addIssueViewpoints, setAddIssueViewpoints] = useState([]);
  const [issueAddMode, setIssueAddMode] = useState(MODE_ADD_NONE);
  const currentUserRole = useCurrentUserRole();
  const views = useMemo(() => {
    const modelsItem = { value: 0, label: t("models", "Models") };
    const issuesItem = { value: 1, label: t("issues", "Issues") };
    const componentDetailsItem = { value: 2, label: t("component_details", "Component Details"), isDisabled: selectedComponentInfo.gIds.length !== 1 };
     if (currentUserRole?.allowedActions?.has(RoleAction.MainMenu_Issues)) {
      return [modelsItem, issuesItem, componentDetailsItem];
     } else {
      return [modelsItem, componentDetailsItem];
     }
  }, [selectedComponentInfo, currentUserRole]);
  const [selectedView, setSelectedView] = useState(views[0]);
  const [selectModelIds, setSelectModelIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [viewerSettings, setViewerSettings] = useState(DEFAULT_SETTING);
  const [selectedModelMetaDatas, setSelectedModelMetaDatas] = useState({
    discipline: [],
    building: [],
    floor: [],
  });
  const [collapsedModelMetaDatas, setCollapsedModelMetaDatas] = useState({
    discipline: [],
    building: [],
  });
  const [floorMapSetting, setFloorMapSetting] = useState({
    visible: false,
    storey: undefined,
    floorMaps: [],
  });
  const [history, setHistory] = useState(null);
  const [historyNode, setHistoryNode] = useState();
  const { issues, issueFilterItems } = useIssueFilterItems();
  const [allIssues, setAllIssues] = useState([]);
  const { tagOptions } = useTags(currentProject?.id, currentProject?.tagIds);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [selectedIssueForIssueList, setSelectedIssueForIssueList] =
    useState(null);
  const [selectedPreviewDoc, setSelectedPreviewDoc] = useState(null);
  const [allDocuments, setAllDocuments] = useState([]);
  const [allEmails, setAllEmails] = useState([]);
  const previewVersions = usePreviewVersions({
    allDocs: allDocuments,
    previewDoc: selectedPreviewDoc
  });
  const [viewpoint, setViewpoint] = useState();
  const isVisibleModelIssues = useMemo(() => {
    return selectedView.value === 1;
  }, [selectedView.value, views.length]);
  const isVisibleModelComponentInfo = useMemo(() => {
    return selectedView.value === 2 && history && componentInfo;
  }, [selectedView.value, views.length, history, componentInfo]);
  const [bubbleIssue, setBubbleIssue] = useState();
  const [notification, setNotification] = useState(null);
  const [isCreatingIssue, setIsNewIssue] = useState(false);
  const [defaultMetaData, setDefaultMetaData] = useState({
    arcDiciplineId: "",
    allFloorId: ""
  });
  const [storeyIds, setStoreyIds] = useState([]);

  useEffect(() => {
    const settingStream = localStorage.getItem(VIEWER_SETTING);
    if (settingStream) {
      const settings = JSON.parse(settingStream);
      setViewerSettings(settings);
    }
  }, []);

  const onShowComponentDetails = useCallback(
    async (info) => {
      const comInfo = info || selectedComponentInfo;
      const comHistory = await ModelService.getModelComponentHistory(
        currentProject?.id,
        comInfo.gIds[0]
      );
      if (!comHistory) return;
      setComponentInfo(comInfo);
      setSelectedView(views[views.length - 1]);
      setHistory(comHistory);
    },
    [currentProject?.id, selectedView, selectedComponentInfo, views]
  );

  //update selected models according to the meta data filter
  useEffect(() => {
    let { discipline, building, floor } = selectedModelMetaDatas;

    discipline = discipline.filter(
      (item) =>
        !!filterMetaData.disciplines.find((dis) => dis.id === item.discipline)
    );
    building = building.filter(
      (item) =>
        !!filterMetaData.disciplines.find(
          (dis) => dis.id === item.discipline
        ) && !!filterMetaData.buildings.find((dis) => dis.id === item.building)
    );
    floor = floor.filter(
      (item) =>
        filterMetaData.disciplines.find((dis) => dis.id === item.discipline) &&
        filterMetaData.buildings.find((dis) => dis.id === item.building) &&
        filterMetaData.floors.find((dis) => dis.id === item.floor)
    );
    setSelectedModelMetaDatas({
      discipline,
      building,
      floor,
    });
  }, [filterMetaData]);

  const changeVersion = (documentId, version) => {
    const filterDoc = allDocuments.find(
      (item) => item.documentId === documentId && item.versionNumber === version
    );

    setSelectedPreviewDoc(filterDoc);
  };

  const handleSelectView = (view) => {
    setSelectedView(view);
    if (view.value === views.length - 1)
      //component details
      onShowComponentDetails();
  };

  const updateTag = useCallback(async (tags, issueId) => {
    const tagIds = tags.map((tag) => tag.value);
    const params = [
      {
        id: issueId,
        tagIds: { value: tagIds },
      },
    ];
    await IssueService.editIssue(params);
  }, []);

  const selectModelList = useCallback(
    (key, metaData) => {
      const metaDatas = { ...selectedModelMetaDatas };
      if (
        metaDatas[key].find(
          (item) => JSON.stringify(item) === JSON.stringify(metaData)
        )
      ) {
        //deselect
        metaDatas[key] = metaDatas[key].filter(
          (item) => JSON.stringify(item) !== JSON.stringify(metaData)
        );
        if (key === "discipline") {
          metaDatas.building = metaDatas.building.filter(
            (item) => item.discipline !== metaData.discipline
          );
          metaDatas.floor = metaDatas.floor.filter(
            (item) => item.discipline !== metaData.discipline
          );
        }
        if (key === "building") {
          metaDatas.discipline = metaDatas.discipline.filter(
            (item) => item.discipline !== metaData.discipline
          );
          metaDatas.floor = metaDatas.floor.filter(
            (item) =>
              item.discipline !== metaData.discipline ||
              item.building !== metaData.building
          );
        }
        if (key === "floor") {
          metaDatas.discipline = metaDatas.discipline.filter(
            (item) => item.discipline !== metaData.discipline
          );
          metaDatas.building = metaDatas.building.filter(
            (item) =>
              item.discipline !== metaData.discipline ||
              item.building !== metaData.building
          );
        }
      } else {
        //select
        if (key === "discipline") metaDatas[key].push(metaData);
        else if (key === "building") {
          if (
            metaDatas.discipline.find(
              (item) => item.discipline === metaData.discipline
            )
          ) {
            metaDatas.discipline = metaDatas.discipline.filter(
              (item) => item.discipline !== metaData.discipline
            );
            metaDatas.floor = metaDatas.floor.filter(
              (item) => item.building !== metaData.building
            );
            metaDatas.building = filterMetaData.buildings
              .filter((item) => item.id !== metaData.building)
              .map((item) => ({
                discipline: metaData.discipline,
                building: item.id,
              }));
          } else metaDatas[key].push(metaData);
        } else if (key === "floor") {
          const isSelectedDiscipline = !!metaDatas.discipline.find(
            (item) => item.discipline === metaData.discipline
          );
          const isSelectedBuilding = !!metaDatas.building.find(
            (item) =>
              item.discipline === metaData.discipline &&
              item.building === metaData.building
          );
          if (isSelectedDiscipline || isSelectedBuilding) {
            metaDatas.discipline = metaDatas.discipline.filter(
              (item) => item.discipline !== metaData.discipline
            );
            metaDatas.building = metaDatas.building.filter(
              (item) =>
                item.discipline !== metaData.discipline ||
                item.building !== metaData.building
            );
            metaDatas.floor = filterMetaData.floors
              .filter((item) => item.id !== metaData.floor)
              .map((item) => ({
                discipline: metaData.discipline,
                building: metaData.building,
                floor: item.id,
              }));
            if (isSelectedDiscipline) {
              metaDatas.building = filterMetaData.buildings
                .filter((item) => item.id !== metaData.building)
                .map((item) => ({
                  discipline: metaData.discipline,
                  building: item.id,
                }));
            }
          } else {
            metaDatas[key].push(metaData);
          }
        }
      }
      setSelectedModelMetaDatas(metaDatas);
    },
    [selectedModelMetaDatas]
  );

  useEffect(() => {
    //get selected model files
    let modelFiles = [];
    selectedModelMetaDatas.discipline.forEach((item) => {
      modelFiles.push(
        ...allModelFiles.filter(
          (file) => file.disciplineMetaDataId === item.discipline
        )
      );
    });
    selectedModelMetaDatas.building.forEach((item) => {
      const files = allModelFiles.filter(
        (file) =>
          file.disciplineMetaDataId === item.discipline &&
          file.buildingMetaDataId === item.building
      );
      //check if file is already existed in modelFiles
      files.forEach((file) => {
        if (!modelFiles.find((mf) => mf.id === file.id)) modelFiles.push(file);
      });
    });
    selectedModelMetaDatas.floor.forEach((item) => {
      const files = allModelFiles.filter(
        (file) =>
          file.disciplineMetaDataId === item.discipline &&
          file.buildingMetaDataId === item.building &&
          file.floorMetaDataId === item.floor
      );
      //check if file is already existed in modelFiles
      files.forEach((file) => {
        if (!modelFiles.find((mf) => mf.id === file.id)) modelFiles.push(file);
      });
    });
    modelFiles = modelFiles.filter((file) => {
      const versionListItem = curFileVersionList.find(
        (item) =>
          item.discipline === file.disciplineMetaDataId &&
          item.building === file.buildingMetaDataId &&
          item.floor === file.floorMetaDataId
      );
      return file.version === versionListItem?.version;
    });
    setSelectedModelFiles(modelFiles);
  }, [selectedModelMetaDatas, curFileVersionList, allModelFiles]);

  const collapseModelList = useCallback(
    (key, metaData) => {
      const metaDatas = { ...collapsedModelMetaDatas };
      if (
        metaDatas[key].find(
          (item) => JSON.stringify(item) === JSON.stringify(metaData)
        )
      )
        metaDatas[key] = metaDatas[key].filter(
          (item) => JSON.stringify(item) !== JSON.stringify(metaData)
        );
      else metaDatas[key].push(metaData);
      setCollapsedModelMetaDatas(metaDatas);
    },
    [collapsedModelMetaDatas]
  );

  useEffect(() => {
    if (!allModelFiles?.length) return;

    const modelDesciplineIds = [
      ...new Set(allModelFiles.map((item) => item.disciplineMetaDataId)),
    ];
    const modelBuildingIds = [
      ...new Set(allModelFiles.map((item) => item.buildingMetaDataId)),
    ];
    const modelFloorIds = [
      ...new Set(allModelFiles.map((item) => item.floorMetaDataId)),
    ];
    const disciplinesWithModels = disciplines?.filter((item) =>
      modelDesciplineIds.includes(item.id)
    ) ?? [];
    const buildingsWithModels = buildings?.filter((item) =>
      modelBuildingIds.includes(item.id)
    ) ?? [];
    const floorsWithModels = floors?.filter((item) =>
      modelFloorIds.includes(item.id)
    ) ?? [];
    const options = getOptions(
      disciplinesWithModels,
      buildingsWithModels,
      floorsWithModels
    );
    setSelectOptions(options);
    setFilterMetaData({
      disciplines: options.discipline,
      buildings: options.building,
      floors: options.floor,
    });
    const versionList = [];
    options.discipline.forEach((discipline) => {
      options.building.forEach((building) => {
        options.floor.forEach((floor) => {
          versionList.push({
            discipline: discipline.id,
            building: building.id,
            floor: floor.id,
            version: 1,
          });
        });
      });
    });
    setCurFileVersionList(versionList);

    const arcDiscipline = disciplines?.find(d => d.id === currentProject.architectureLabelId);
    const allFloor = floors?.find(f => f.abbreviation === "XX");
    setDefaultMetaData({
      arcDiciplineId: arcDiscipline?.id,
      allFloorId: allFloor?.id
    });
  }, [disciplines, buildings, floors, allModelFiles]);

  const getOptions = (discs, builds, floors) => ({
    discipline: discs.map((item) => ({
      ...item,
      value: item.id,
      label: item.abbreviation,
    })),
    building: builds.map((item) => ({
      ...item,
      value: item.id,
      label: item.abbreviation,
    })),
    floor: floors.map((item) => ({
      ...item,
      value: item.id,
      label: item.abbreviation,
    })),
  });

  const handleSelectChange = useCallback(
    (value, name) => {
      switch (name) {
        case "buildings":
          setFilterMetaData({ ...filterMetaData, buildings: value });
          break;
        case "disciplines":
          setFilterMetaData({ ...filterMetaData, disciplines: value });
          break;
        case "floors":
          setFilterMetaData({ ...filterMetaData, floors: value });
          break;
        default:
          break;
      }
    },
    [filterMetaData]
  );

  const updateModelFilesBySync = async (changedIds) => {
    const uploadModels = await ModelService.getModels(changedIds);
    if (!uploadModels?.length) return;

    let allFiles = [...allModelFiles];
    uploadModels.forEach((uploadModel) => {
      if (!allFiles.find((file) => file.id === uploadModel.changedFileId))
        allFiles = [...allFiles, uploadModel.changedFile];
      else
        allFiles = allFiles.map((file) => {
          if (file.id == uploadModel.changedFileId)
            return uploadModel.changedFile;
          return file;
        });
    });
    setAllModelFiles(allFiles);
  }

  const updateIssuesBySync = async (changedIds) => {
    const issues = await IssueService.getIssuesByIds(changedIds);
    if (!issues?.length) return;

    let buffer = [...allIssues];
    issues.forEach((item) => {
      if (!allIssues.find((issue) => issue.id === item.id))
        buffer = [...allIssues, item];
      else
        buffer = buffer.map((issue) => {
          if (issue.id == item.id)
            return item;
          return issue;
        });
    });
    setAllIssues(buffer);
  }

  useEffect(() => {
    const effect = async () => {
      if (!sync_data?.dataSummaryEntries) return;

      sync_data.dataSummaryEntries.forEach(async (entry) => {
        if (entry.dataType === SyncDataType.Model)
          await updateModelFilesBySync(getChangedIds(entry));
        else if (entry.dataType === SyncDataType.Issue)
          await updateIssuesBySync(getChangedIds(entry));
      });
    };
    effect();
  }, [sync_data]);

  useEffect(() => {
    if (!currentProject?.id) return;
    const currentProjectId = currentProject?.id;
    const initLoading = async () => {
      const currentProject = await ProjectService.getProject(currentProjectId);
      const allModels = await ModelService.getModels(currentProject.modelIds);
      let modelFiles = allModels.map((model) => model.changedFile);
      const docs = await DocumentService.getDocumentVersionsByProjectId(
        currentProjectId
      );
      const emails = await EmailService.getEmailsByProjectId(
        currentProjectId
      );

      modelFiles = modelFiles.map((model) => {
        const item = docs.find((doc) => doc.fileId === model.fileId);
        if (!item) return model;
        return {
          ...model,
          importFinishDate: item.importFinishDate,
        };
      });
      setAllModelFiles(modelFiles);
      setAllDocuments(docs);
      setAllEmails(emails);
    };
    initLoading();
  }, [currentProject?.id]);

  useEffect(() => {
    setAllIssues(issues);
  }, [issues]);

  const handleSelectFileVersion = useCallback(
    (disciplineId, buildingId, floorId, version) => {
      const list = curFileVersionList.map((item) => {
        if (
          item.discipline === disciplineId &&
          item.building === buildingId &&
          item.floor === floorId
        ) {
          item.version = version;
        }
        return item;
      });
      setCurFileVersionList(list);
    },
    [curFileVersionList]
  );

  const loadModels = () => {
    const fileIds = selectedModelFiles.map((f) => f.id);
    const recentDate = new Date(selectedModelFiles.sort((a, b) => (new Date(b.creationDate)) - (new Date(a.creationDate)))[0]?.creationDate);
    const filteredModels = allModelFiles.filter((m) => ((new Date(m.creationDate)) <= recentDate) && m.disciplineMetaDataId === defaultMetaData.arcDiciplineId);

    const storeyModelIds = [];
    const buildingIds = [... new Set(selectedModelFiles.map((f) => f.buildingMetaDataId))];
    const floorIds = [... new Set(selectedModelFiles.map((f) => f.floorMetaDataId))];

    buildingIds.forEach((bId) => {
      floorIds.forEach((fId) => {
        let subModels = filteredModels.filter((m) =>
          m.buildingMetaDataId === bId &&
          m.floorMetaDataId === fId
        ).sort((a, b) => b.version - a.version);

        if (subModels.length) {
          storeyModelIds.push(subModels[0].id);
        } else {
          subModels = filteredModels.filter((m) =>
            m.buildingMetaDataId === bId &&
            m.floorMetaDataId === defaultMetaData.allFloorId
          ).sort((a, b) => b.version - a.version);

          if (subModels.length)
            storeyModelIds.push(subModels[0].id);
        }
      });
    });

    setHasUnsavedChanges(true);
    setSelectModelIds(fileIds);
    setStoreyIds([...new Set(storeyModelIds)]);
  };

  const showSettingModal = () => {
    setShowSetting(true);
  };

  const handleSettings = (setting) => {
    if (setting) {
      setViewerSettings(setting);
      localStorage.setItem(VIEWER_SETTING, JSON.stringify(setting));
      setNotification({
        title: t("viewer_setting_saved", "Viewer Settings saved"),
        description: t(
          "viewer_setting_saved_description",
          "You successfully saved your viewer settings.\nYour settings will be saved, even though you leave the viewer."
        ),
      });
    }

    setShowSetting(false);
  };

  const handleStoreys = (storeys) => {
    setFloorMapSetting({
      visible: false,
      storey: undefined,
      floorMaps: storeys,
    });
  };

  const handleFloorMapSetting = useCallback(
    (setting) => {
      setFloorMapSetting({
        ...floorMapSetting,
        visible: setting.visible,
        storey: setting.storey,
      });
    },
    [floorMapSetting]
  );

  const handleSelectIssue = useCallback(
    (issue) => {
      setSelectedIssue(allIssues.find((item) => item.id === issue.id));
    },
    [allIssues]
  );

  const handleSelectNode = (history) => {
    setHistoryNode(history?.history?.node);
  };

  useEffect(() => {
    const changeIssueCreationState = () => {
      if (isIssueModal) {
        dispatch(setCancelIssueCreation(true));
        if (issueModalProps.editIssue)
          setAddIssueComponents(selectedComponentInfo.components);
        else
          setAddIssueComponents([]);
      } else {
        dispatch(setCancelIssueCreation(false));
      }

      setIsNewIssue(false);
    };
    changeIssueCreationState();
  }, [isIssueModal]);

  useEffect(() => {
    const effect = async () => {
      if (
        selectedComponentInfo.gIds.length === 1 &&
        selectedView.value === views.length - 1
      ) {
        const comHistory = await ModelService.getModelComponentHistory(
          currentProject?.id,
          selectedComponentInfo.gIds[0]
        );
        if (!comHistory) return;
        setComponentInfo(selectedComponentInfo);
        setHistory(comHistory);
      }
    };
    effect();
  }, [selectedComponentInfo]);

  const selectBubbleIssue = (issue) => {
    setSelectedView(views[1]);
    setBubbleIssue(issue);
  };

  const createIssue = () => {
    setIsNewIssue(true);
  };

  if (!currentUserRole?.allowedActions?.has(RoleAction.MainMenu_Models)) return <NotAllowed />;

  return (
    <main className="main models">
      <SuspenseWithErrorBoundary suspenseFallback={<CenteredCircularProgress />}>
        <Loader isLoading={isLoading} />
        <div className="header models-header" style={{ height: 60 }}>
          <div className="models-header_left">
            <label className="title">{t("select_view", "Select View")}</label>
            <CustomSelect
              id="SelectViewModelDropdown"
              values={views}
              onChange={handleSelectView}
              value={selectedView}
              isModel={true}
            />
          </div>
          <div className="models-header_right">
            <div
              className="view-settings"
              style={{ cursor: "pointer" }}
              onClick={showSettingModal}
            >
              {getGeneralSvg("settings-empty")}
              <span>{t("viewer_settings", "Viewer Settings")}</span>
            </div>
            <div>
              <div className="beta-version">
                <div className="beta">{t("beta", "BETA")}</div>
                <p className="full-version">
                  {t("for_full_version", "For the full version")}
                </p>
                <OpenWindowsClientLink view="live" modelFileIds={selectModelIds}>
                  <p id="FullVersionHyperlink" className="link">{t("click_here", "click here")}</p>
                </OpenWindowsClientLink>
              </div>
            </div>
          </div>
        </div>
        <div className="main__content models-content">
          <div
            className={`models-content-list ${selectedView.value === views.length ? "list-component-info" : ""
              }`}
          >
            <ModelComponentInfo
              isVisible={isVisibleModelComponentInfo}
              allIssues={allIssues}
              history={history}
              selectedComponentInfo={componentInfo}
              handleSelectIssue={handleSelectIssue}
              handleSelectHistory={handleSelectNode}
            />
            <ModelIssues
              isVisible={isVisibleModelIssues}
              allIssues={allIssues}
              allDocuments={allDocuments}
              allEmails={allEmails}
              tagOptions={tagOptions}
              issueFilterItems={issueFilterItems}
              issueAddMode={issueAddMode}
              addIssueComponents={addIssueComponents}
              selectedIssue={selectedIssueForIssueList}
              setSelectedPreviewDoc={setSelectedPreviewDoc}
              setIssueModalProps={setIssueModalProps}
              setIsIssueModal={setIsIssueModal}
              setSelectedIssue={setSelectedIssueForIssueList}
              setSelectedViewpoint={setViewpoint}
              setAddIssueComponents={setAddIssueComponents}
              bubbleIssue={bubbleIssue}
              createIssue={createIssue}
            />
            <div
              className={
                !isVisibleModelComponentInfo && !isVisibleModelIssues
                  ? ""
                  : "hide"
              }
            >
              <div className="title-wrapper">
                <h3 className="title">{t("models", "Models")}</h3>
                <div>
                  {/*<button
                    className="btn btn--secondary"
                    onClick={() => setShowUploadProgress(true)}
                  >
                    {t("upload_model", "Upload Model")}
                  </button>*/}
                </div>
              </div>
              <ModelListFilter
                selectOptions={selectOptions}
                filterMetaData={filterMetaData}
                handleSelectChange={handleSelectChange}
              />
              <div className="list-header-wrapper">
                <label className="title">{t("model_list", "Model List")}</label>
                <button
                  id="UpdateViewerModelButton"
                  className="btn btn--primary"
                  onClick={loadModels}
                  disabled={!selectedModelFiles.length}
                >
                  {t("update_viewer", "Update Viewer")}
                </button>
              </div>
              <div className="information-mark">
                {getGeneralSvg("information")}
                <span>{t("load_model", "Load Model")}</span>
                <span>{t("version", "Version")}</span>
              </div>
              <div className="models-content-list_items custom-scrollbar">
                {filterMetaData.disciplines.map(
                  (discipline, index) =>
                    discipline.value !== null && (
                      <ModelItem
                        key={`modelitem-${index}`}
                        index={index}
                        discipline={discipline}
                        modelFiles={allModelFiles}
                        buildings={filterMetaData.buildings}
                        floors={filterMetaData.floors}
                        selectedModelMetaDatas={selectedModelMetaDatas}
                        collapsedModelMetaDatas={collapsedModelMetaDatas}
                        handleSelectFileVersion={handleSelectFileVersion}
                        selectModelList={selectModelList}
                        collapseModelList={collapseModelList}
                      />
                    )
                )}
              </div>
            </div>
          </div>
          <ModelView
            issues={allIssues}
            issueFilterItems={issueFilterItems}
            isNoModelUploaded={!allModelFiles?.length}
            selectModels={selectModelIds}
            viewerSettings={viewerSettings}
            handleLoading={setIsLoading}
            registerStoreys={handleStoreys}
            floorMapSetting={floorMapSetting}
            onShowComponentDetails={onShowComponentDetails}
            selectedComponentInfo={selectedComponentInfo}
            setSelectedComponentInfo={setSelectedComponentInfo}
            historyNode={historyNode}
            setIsModifiedIssueComponents={setIsModifiedIssueComponents}
            setAddIssueComponents={setAddIssueComponents}
            setAddIssueViewpoints={setAddIssueViewpoints}
            issueAddMode={issueAddMode}
            setIssueAddMode={setIssueAddMode}
            linkedComponentsGlobalIds={
              selectedIssueForIssueList
                ? isIssueModal && !issueModalProps.editIssue
                  ? null
                  : selectedIssueForIssueList.linkedComponentsGlobalIds
                : null
            }
            setIsIssueModal={setIsIssueModal}
            isIssueModal={isIssueModal}
            selectedViewpoint={viewpoint}
            resetViewpoint={() => setViewpoint(null)}
            setBubbleIssue={selectBubbleIssue}
            issueComponents={addIssueComponents}
            selectedIssue={selectedIssueForIssueList}
            isCreatingIssue={isCreatingIssue}
            storeyModelIds={storeyIds}
          />
        </div>
        <IssueOverviewModalOld
          show_aside={!!selectedIssue}
          {...issueFilterItems}
          issue={selectedIssue}
          tagOptions={tagOptions}
          toggleAside={() => setSelectedIssue(null)}
          allDocuments={allDocuments}
          allIssues={allIssues}
          updateTag={updateTag}
          setSelectedPreviewDoc={setSelectedPreviewDoc}
          setCurrentIssue={setSelectedIssue}
        />
        {selectedPreviewDoc && (
          <Modal>
            <DocumentPreviewModal
              versions={previewVersions}
              setSelectedPreviewDoc={setSelectedPreviewDoc}
              previewDoc={selectedPreviewDoc}
              changeVersion={changeVersion}
              issueDocument={true}
              tagOptions={tagOptions}
              issues={allIssues}
              {...issueFilterItems}
            />
          </Modal>
        )}
        {!!notification && (
          <NotificationModal
            onDone={() => setNotification(null)}
            notification={notification}
            isRenderContent={true}
          />
        )}
        {showSetting ? (
          <ViewerSettingModal
            handleSetting={handleSettings}
            settings={viewerSettings}
            floorMapSetting={floorMapSetting}
            handleFloorMapSetting={handleFloorMapSetting}
          />
        ) : (
          <></>
        )}
        {isIssueModal && (
          <Modal
            className={`issue-modal--wrapper ${issueAddMode === MODE_ADD_NONE ? "" : "hide"
              }`}
          >
            <IssueModal
              setShowCreateModal={setIsIssueModal}
              setIssueSavedModalVisible={setIssuesSavedModalVisible}
              canEditIssue={true}
              setModalCount={setModalCount}
              modalCount={modalCount}
              showCreateModal={isIssueModal}
              setIsLinkedCreatIssue={() => { }}
              fromDocuments={true}
              allIssues={allIssues}
              setIssueAddMode={setIssueAddMode}
              components={addIssueComponents}
              viewpoints={addIssueViewpoints}
              isModifiedIssueComponents={isModifiedIssueComponents}
              setAddComponents={setAddIssueComponents}
              setAddViewpoints={setAddIssueViewpoints}
              setIsModifiedIssueComponents={setIsModifiedIssueComponents}
              setSelectedPreviewDoc={setSelectedPreviewDoc}
              {...issueModalProps}
            />
          </Modal>
        )}
        {issueSavedModalVisible && (
          <Modal>
            <IssueSavedModal />
          </Modal>
        )}
      </SuspenseWithErrorBoundary>
    </main>
  );
}
