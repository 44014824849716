import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { sortBy } from "lodash";

import IssueHistory from "./IssueHistory";

import { useResourceIds } from "../../Helpers/Hooks";
import SymbolTextInput from "../Inputs/SymbolTextInput";
import MentionView from "../MentionView";
import useCurrentUserRole from "users/hooks/useCurrentUserRole";
import RoleAction from "projects/types/RoleAction";

const IssueComments = ({
  issue,
  inputComment,
  comments,
  users,
  disciplines,
  floors,
  buildings,
  workphases,
  allDocuments,
  tagOptions,
  isVisibleHistory,
  statuses,
  priorities,
  types,
  allIssues,
  toggleHistory,
  handleCommentChange,
  addComment,
}) => {
  const { t } = useTranslation();
  const currentUserRole = useCurrentUserRole();
  const {
    usersById,
    disciplinesById,
    buildingsById,
    floorsById,
    documentsById,
    tagsById,
  } = useResourceIds({
    users,
    allDisciplines: disciplines,
    floors,
    buildings,
    allDocuments,
    tagOptions,
  });

  return (
    <div className="issue-comments">
      <div className="accordion-title">
        <div className="accordion-title-left">
          <h3>{t("comments", "Comments")}</h3>
          {!isVisibleHistory && (
            <div className="issue-comments--count">
              {`${comments.length} ${t("comments", "Comments")}`}
            </div>
          )}
        </div>
        <div className="accordion-title-right">
          <span
            className={`btn-comment${isVisibleHistory ? "" : " active"}`}
            onClick={(event) => toggleHistory(event, false)}
          >
            <svg id="CommentIssueButton" className="icon">
              <use xlinkHref="/img/sprite.svg#comments" />
            </svg>
          </span>
          <span
            className={`btn-history${isVisibleHistory ? " active" : ""}`}
            onClick={(event) => toggleHistory(event, true)}
          >
            <svg id="HistoryIssueButton" className="icon">
              <use xlinkHref="/img/sprite.svg#history" />
            </svg>
          </span>
        </div>
      </div>
      <div className="comments">
        <div className="comments__content custom-scrollbar">
          <div className="comments__content-inner">
            {!isVisibleHistory
              ? comments.map((item) => {
                  return (
                    <div key={`comment-${item.id}`} className="comments__item">
                      <div>
                        <span className="comments__author">
                          {item.createAuthor
                            ? `${item.createAuthor.firstName || ""} ${
                                item.createAuthor.lastName || ""
                              }`
                            : ""}
                        </span>
                        <span className="comments__date">
                          {moment(new Date(item.creationDate)).format(
                            "DD/MM/YYYY - HH:mm"
                          )}
                        </span>
                      </div>
                      <MentionView value={item.text} />
                    </div>
                  );
                })
              : !!issue.issueLog?.issueLogEntries?.length &&
                sortBy(issue.issueLog.issueLogEntries, "creationDate")
                  .reverse()
                  .map((log, index) => (
                    <IssueHistory
                      key={index}
                      log={log}
                      users={usersById}
                      documents={documentsById}
                      floors={floorsById}
                      disciplines={disciplinesById}
                      buildings={buildingsById}
                      tags={tagsById}
                      workphases={workphases}
                      statuses={statuses}
                      priorities={priorities}
                      types={types}
                      allIssues={allIssues}
                    />
                  ))}
          </div>
        </div>

        {currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_CreatingComments) &&
          !isVisibleHistory && (
            <form
              id="comments__form"
              className="comments__form"
              onSubmit={addComment}
            >
              <SymbolTextInput
                id="CommentIssueInput"
                value={inputComment}
                placeholder={t("leave_a_comment", "Leave a comment")}
                onChange={handleCommentChange}
                isAboveCursor={true}
              />
              <button id="SubmitCommentIssueButton" className="btn btn--primary btn--sm" type="submit">
                {t("send", "Send")}
              </button>
            </form>
          )}
      </div>
    </div>
  );
};

export default IssueComments;
