import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ModelSettingsDisciplinesPanel from 'settings/components/ModelSettingsDisciplinesPanel';
import ModelSettingsBuildingsPanel from 'settings/components/ModelSettingsBuildingsPanel';
import ModelSettingsFloorsPanel from 'settings/components/ModelSettingsFloorsPanel';

export default function ModelSettingsTabPane() {
  const { t } = useTranslation('settings');
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h2" sx={{ mb: 4, p: 4, pb: 2 }}>
        {t('model-settings-tab-pane_title', 'Model Metadata')}
      </Typography>
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 4 }}>
        <ModelSettingsDisciplinesPanel />
        <ModelSettingsBuildingsPanel />
        <ModelSettingsFloorsPanel />
      </Box>
    </Box>
  );
}
