import React from 'react';
import { Box } from '@mui/material';
import { mdiFlag, mdiHome, mdiLayers, mdiTag, mdiWrench } from '@mdi/js';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import LabelChip from 'labels/components/LabelChip';
import ISxProps from 'common/types/ISxProps';

interface IssueLabelsChipListProps extends ISxProps {
  issueId: string,
}

export default function IssueLabelsChipList({
  sx,
  issueId,
}: IssueLabelsChipListProps) {
  const { data: issue } = useIssueQuery(issueId);
  const { data: disciplines } = useLabelsOdataQuery(issue ? { filter: { id: { in: issue.disciplineMetaDataIds }, isDeleted: false } } : undefined);
  const { data: buildings } = useLabelsOdataQuery(issue ? { filter: { id: { in: issue.buildingMetaDataIds }, isDeleted: false } } : undefined);
  const { data: floors } = useLabelsOdataQuery(issue ? { filter: { id: { in: issue.floorMetaDataIds }, isDeleted: false } } : undefined);
  const { data: workPhases } = useLabelsOdataQuery(issue?.workPhaseId ? { filter: { id: issue.workPhaseId, isDeleted: false } } : undefined);
  const { data: tags } = useLabelsOdataQuery(issue ? { filter: { id: { in: issue.tagIds }, isDeleted: false } } : undefined);
  if (!issue) return null;
  return (
    <Box sx={{ ...sx, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {!!disciplines && disciplines.map((discipline) => <LabelChip key={discipline.id} label={discipline} mdiIconPath={mdiWrench} />)}
      {!!buildings && buildings.map((building) => <LabelChip key={building.id} label={building} mdiIconPath={mdiHome} />)}
      {!!floors && floors.map((floor) => <LabelChip key={floor.id} label={floor} mdiIconPath={mdiLayers} />)}
      {!!workPhases && workPhases.map((workPhase) => <LabelChip key={workPhase.id} label={workPhase} mdiIconPath={mdiFlag} />)}
      {!!tags && tags.map((tag) => <LabelChip key={tag.id} label={tag} mdiIconPath={mdiTag} />)}
    </Box>
  );
}
