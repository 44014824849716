import { Core } from '@pdftron/webviewer';

export interface AnnotAction {
  action: AnnotActionType;
  annotations: Core.Annotations.Annotation[];
}

export enum AnnotActionType {
  ADD,
  MODIFY,
  DELETE,
  SELECTION_ADDED,
  SELECTION_REMOVED,
}
