import useDefaultEntitiesByIdsEndpointQuery from 'api/hooks/useDefaultEntitiesByIdsEndpointQuery';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import CommentDto from 'issues/types/CommentDto';
import { useCallback } from 'react';

export default function useIssueCommentsQuery(ids: string[] | undefined, options?: Omit<UseQueryOptions<CommentDto[] | undefined, unknown, CommentDto[] | undefined, QueryKey>, 'queryKey' | 'queryFn'> | undefined) {
  const entityFactory = useCallback((dto: CommentDto) => dto, []);
  return useDefaultEntitiesByIdsEndpointQuery(ApiEndpoint.Comment, ids, entityFactory, options);
}
