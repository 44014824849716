import React, { useCallback, useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiContentCopy } from '@mdi/js';

interface CopyButtonProps {
  value: string | undefined,
}

export default function CopyButton({
  value,
}: CopyButtonProps) {
  const { t } = useTranslation('emails');
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const onClick = useCallback(() => {
    if (!value) return;
    navigator.clipboard.writeText(value);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 2500);
  }, [value]);
  return (
    <Tooltip open={tooltipOpen} title={t('copy-button_copy-button-feedback', 'Copied!')}>
      <Button sx={{ minWidth: 'unset', px: 1 }} variant="contained" color="secondary" onClick={onClick} title={t('copy-button_title', 'Copy to clipboard')}>
        <Icon path={mdiContentCopy} size={0.75} />
      </Button>
    </Tooltip>
  );
}
