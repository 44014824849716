import { uniq, sortBy } from "lodash";
import i18n from "i18next";
import buildQuery from "odata-query";

import * as Request from "../Helpers/Request";
import { disciplineColors } from "../Helpers/Common";
import { calcBusinessDays } from "../Helpers/Date";
import { uploadFiles } from "../Helpers/utils";
import * as ViewpointService from "./ViewpointService";
import RoleAction from "projects/types/RoleAction";

export const IssueColors = [
  "#C6C6C6",
  "#E93E3E",
  "#42A563",
  "#9E6BF3",
  "#0496BF",
  "#6BC9F3",
  "#FFD800",
  "#806B00",
  "#803400",
  "#800001",
  "#01FFFF",
  "#F25C5C",
  "#FE0000",
  "#0026FF",
  "#017F01",
  "#00D801",
  "#FE6A00",
];

export const GraphColors = [
  "#42A563",
  "#9E6BF3",
  "#6BC9F3",
  "#427aa5",
  "#C6C6C6",
  "#E93E3E",
];

export const OTHER_COLOR = "#a09b9b";

export const IssueLogType = {
  IssueCreated: 1,
  TitleUpdated: 2,
  DescriptionUpdated: 3,
  TypeUpdated: 4,
  PriorityUpdated: 5,
  DueDateUpdated: 6,
  DueDateRemoved: 7,
  AssignedToUpdated: 8,
  AssignedToRemoved: 9,
  LabelAdded: 10,
  LabelRemoved: 11,
  StageAdded: 12,
  StageUpdated: 13,
  StageRemoved: 14,
  DocumentFirstLinked: 15,
  DocumentFirstLinkRemoved: 16,
  DocumentLinkVersionUpdated: 17,
  StatusUpdate: 18,
  ReviewerUpdated: 19,
  ReviewerRemoved: 20,
  DisciplineMetaDataUpdated: 21,
  DisciplineMetaDataRemoved: 22,
  BuildingMetaDataUpdated: 23,
  BuildingMetaDataRemoved: 24,
  FloorMetaDataUpdated: 25,
  FloorMetaDataRemoved: 26,
  CustomDisciplineMetaDataUpdated: 27,
  CustomDisciplineMetaDataRemoved: 28,
  CustomBuildingMetaDataUpdated: 29,
  CustomBuildingMetaDataRemoved: 30,
  CustomFloorMetaDataUpdated: 31,
  CustomFloorMetaDataRemoved: 32,
  ModelsUpdated: 33,
  ModelsRemoved: 34,
  LinkedComponentsUpdated: 35,
  LinkedComponentsRemoved: 36,
  TagsUpdated: 37,
  TagsRemoved: 38,
  ViewpointAdded: 39,
  ViewpointRemoved: 40,
  StartingDateUpdated: 41,
  StartingDateRemoved: 42,
  WorkPhaseUpdated: 43,
  WorkPhaseRemoved: 44,
  VisibilityUpdated: 45,
  AllowedRolesAdded: 46,
  LinkedEmailUpdated: 47,
  LinkedEmailRemoved: 48,
  ParentUpdated: 49,
  ParentRemoved: 50,
  StatusIdUpdated: 51,
  TypeIdUpdated: 52,
  PriorityIdUpdated: 53,
};

export const defaultIssueColumns = [
  { name: "Name", width: 203 },
  { name: "Status", width: 135 },
  { name: "Creation date", width: 145 },
  { name: "Due Date", width: 125 },
  { name: "Discipline", width: 139 },
  { name: "Models", width: 125 },
  { name: "Assigned to", width: 139 },
  { name: "Reviewer", width: 116 },
  { name: "Workphase", width: 120 },
  { name: "Tags", width: 192 },
];

export const defaultLinkIssueColumns = [
  { name: "ID", width: 30 },
  { name: "Type", width: 61 },
  { name: "Number", width: 51 },
  { name: "Name", width: 220 },
  { name: "Status", width: 105 },
  { name: "Due Date", width: 129 },
  { name: "Discipline", width: 107 },
  { name: "Assigned to", width: 126 },
  { name: "Tags", width: 196 },
];

export const getDisciplineColor = (index) => {
  if (index < disciplineColors.length) return disciplineColors[index];

  //generate random color
  let maxVal = 0xfffff;
  let randomNumber = Math.random() * maxVal;
  randomNumber = Math.floor(randomNumber);
  randomNumber = randomNumber.toString(16);
  let randColor = randomNumber.padStart(6, 0);
  return `#${randColor.toUpperCase()}`;
};

export const getVisibilityOptions = () => [
  { value: 0, label: i18n.t("Open Access", "FIXME Translation missing") },
  { value: 1, label: i18n.t("Private", "FIXME Translation missing") },
  { value: 2, label: i18n.t("Restricted Access", "FIXME Translation missing") },
];

export const getKeysWithoutAll = (object) => {
  let result = Object.assign({}, object);
  delete result["all"];
  return result;
};

export const getStatusOptionsWithoutClosed = (object) => {
  let result = Object.assign({}, object);
  delete result["all"];
  delete result["closed"];
  return result;
};

export const getIssuesWithLogViewpoints = async (issues) => {
  let buffer = [...issues];
  //fill viewpoints and issueLogs
  const viewpointIds = [];
  issues.forEach((item) => {
    viewpointIds.push(...item.viewpointIds);
  });
  const viewpointBuffer = await ViewpointService.getViewpointsByIds(
    viewpointIds
  );

  const issueLogs = !buffer?.length
    ? []
    : await getIssueLogs(uniq(buffer.map((b) => b.issueLogId)));

  buffer = buffer.map((item) => {
    item.viewpoints = viewpointBuffer.filter((viewpoint) =>
      item.viewpointIds.includes(viewpoint.id)
    );
    item.issueLog = issueLogs.find((log) => log.id === item.issueLogId) || {
      issueLogEntries: [],
    };
    return item;
  });

  return buffer;
};

export const formatIssue = (item, disciplines, buildings, floors) => {
  let show_label = "new";

  if (item.startingDate) {
    item.startingDate = new Date(new Date(item.startingDate).getTime());
  }
  if (item.dueDate) {
    item.dueDate = new Date(new Date(item.dueDate).getTime());
  }
  const createdDaysAgo = calcBusinessDays(
    new Date(item.creationDate),
    new Date()
  );
  const updatedDaysAgo = calcBusinessDays(new Date(item.editDate), new Date());
  const notUpdated =
    new Date(item.editDate).getTime() === new Date(item.creationDate).getTime();

  if (notUpdated && createdDaysAgo < 2) {
    show_label = "new";
  } else if (!notUpdated && updatedDaysAgo < 2) {
    show_label = "update";
  } else {
    show_label = "";
  }

  item.label = show_label;
  item.linkedComponentsLength = item.linkedComponentsGlobalIds?.length || 0;
  item.commentCount = item.commentCount || 0;
  item.source = item.source || "Visoplan";

  const buildingIds = uniq(
    (item.buildingMetaDataIds || []).concat(
      item.customBuildingMetaDataIds || []
    )
  );
  const subBuildings =
    buildingIds.length === 0
      ? []
      : buildings.filter((b) => buildingIds.includes(b.id));

  const disciplineIds = uniq(
    (item.disciplineMetaDataIds || []).concat(
      item.customDisciplineMetaDataIds || []
    )
  );
  const subCategories =
    disciplineIds.length === 0
      ? []
      : disciplines.filter((d) => disciplineIds.includes(d.id));

  const floorMetaIds = uniq(
    (item.floorMetaDataIds || []).concat(item.customFloorMetaDataIds || [])
  );
  const subFloors =
    floorMetaIds.length === 0
      ? []
      : floors.filter((f) => floorMetaIds.includes(f.id));

  item.buildings = subBuildings;
  item.disciplines = subCategories;
  item.floors = subFloors;
  return item;
};

export const getMetaDataWithoutOther = (metaDatas) => {
  if (!metaDatas) return [];
  return sortBy(metaDatas, "order").slice(0, metaDatas.length - 1);
};

export async function getIssuesByIds(ids) {
  return Request.getChunkedEntitiesByIds("api/issue", ids);
}

export async function getIssuesByProjectId(projectId, top, skip, filter) {
  const query = buildQuery({ filter, top, skip, orderBy: "editDate desc" });
  const result = await Request.GET(`api/issue${query}`);
  return result.status === 200 ? result.body : [];
}

const handleApiError = (response, setApiError = null) => {
  if (setApiError)
    setApiError(
      response?.errors[0]
        ? response.errors[0].errorMessage
        : "server error"
    );
  return null;
}

export async function createIssue(body, setApiError) {
  const result = await Request.POST("api/issue", body);
  if(result.status === 200)
    return result.body;
  else
    handleApiError(result?.body, setApiError);
}

export async function editIssue(body, setApiError) {
  const result = await Request.PUT("api/issue", body);
  if (result.status === 200) return result.body;
  else handleApiError(result?.body, setApiError);
}

export async function editIssueMany(body, setApiError) {
  Object.keys(body).forEach((key) => {
    if (!body[key]) {
      delete body[key];
    }
  });

  const result = await Request.PATCH("api/issue/many", body);
  if (result.status === 200) return true;
  else handleApiError(result?.body, setApiError);
}

export function editIssuebyPromise(body, setApiError) {
  return Request.PUT("api/issue", body).then((result) => {
    if (result.status === 200) return result.body;
    else handleApiError(result?.body, setApiError);
  });
}

export async function getIssueLogs(ids) {
  return Request.getChunkedEntitiesByIds("api/issue/issuelog", ids);
}

export async function uploadIssue(file, projectId, token, setProgress) {
  const url = Request.API_URL + "api/report";
  return uploadFiles(file, token, url, setProgress);
}

export const updateIssue = async (id, data) => {
  return Request.PUT(`api/issue`, [
    {
      id,
      ...data,
    },
  ]);
};

export const getViewMode = () => {
  return localStorage.getItem("issues-view-mode") || "table";
};

export const setViewMode = (mode) => {
  localStorage.setItem("issues-view-mode", mode);
};

export const getEditIssueState = (issue, allowedActions, user) => {
  let state = false;
  if (issue) {
    if (issue.issueStatus?.originalName === "Closed" /*close status*/)
      state = false;
    else if (allowedActions?.has(RoleAction.IssueManagement_Editing)) state = true;
    else if (
      issue.createAuthorId === user.id &&
      allowedActions?.has(RoleAction.IssueManagement_Creator_Editing)
    )
      state = true;
    else if (
      issue.reviewerId === user.id &&
      allowedActions?.has(RoleAction.IssueManagement_Reviewer_Editing)
    )
      state = true;
    else if (
      issue.assignedUserIds &&
      issue.assignedUserIds.includes(user.id) &&
      allowedActions?.has(RoleAction.IssueManagement_Assigned_Editing)
    )
      state = true;
  } else return true;
  return state;
};

export const getValuesAddedAll = (values) => {
  if (!values) return [];
  return [{ value: null, label: i18n.t("all", "All") }, ...values];
};

export const getComments = async (ids) => {
  const result = await Request.GET(`api/comment/${ids}`);
  return result.status === 200 ? result.body : [];
}