import { useMemo } from 'react';
import useDefaultEntityQueryKeys, { VisoOdataQueryKey } from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import DocumentListDto from 'documents-lists/types/DocumentListDto';

export type DocumentListsOdataQueryKey = VisoOdataQueryKey<ApiEndpoint.DocumentList, DocumentListDto>;

export default function useDocumentListsOdataQueryKey() {
  const {
    odataQueryQueryKey: queryKeyBase,
    getOdataQueryQueryKey: getQueryKey,
  } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentList);
  return useMemo(() => ({
    queryKeyBase,
    getQueryKey: getQueryKey<DocumentListDto>,
  }), [getQueryKey, queryKeyBase]);
}
