import { useTranslation } from 'react-i18next';

/**
 * Get the date format pattern for the given locale.
 * @source https://stackoverflow.com/a/69095836/1078146
 * @example
 *     getDateFormatPattern('en-AU');   // dd/mm/yyyy
 *     getDateFormatPattern('en-US');   // m/d/yyyy
 */
const getDateFormatPattern = (locale: string) => {
  const getPatternForPart = (part: Intl.DateTimeFormatPart) => {
    switch (part.type) {
      case 'day':
        return 'D'.repeat(part.value.length);
      case 'month':
        return 'M'.repeat(part.value.length);
      case 'year':
        return 'Y'.repeat(part.value.length);
      case 'literal':
        return part.value;
      default:
        return '';
    }
  };

  return new Intl.DateTimeFormat(locale, { dateStyle: 'medium' }).formatToParts(new Date('2021-01-01'))
    .map(getPatternForPart)
    .join('');
};

export default function useLocalizedDatePattern() {
  const { i18n } = useTranslation();
  return getDateFormatPattern(i18n.language);
}
