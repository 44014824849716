import {
  UseQueryOptions, useQuery, useQueryClient,
} from '@tanstack/react-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import { useCallback, useMemo } from 'react';
import IssueDto from 'issues/types/IssueDto';
import { IssueQueryKey } from 'issues/hooks/useIssueQueryOptions';

type IssueUseQueryOptions = UseQueryOptions<IssueDto | undefined, unknown, IssueDto | undefined, IssueQueryKey | readonly []>;

export default function useIssueQuery(id: string | undefined, options?: Omit<IssueUseQueryOptions, 'intitialData' | 'queryKey' | 'queryFn'> | undefined) {
  const { getDetailsByIdQueryKey, listQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Issue);
  const queryKey = useMemo(() => (id ? getDetailsByIdQueryKey(id) : ([] as const)), [getDetailsByIdQueryKey, id]);
  const queryClient = useQueryClient();
  const initialData = useCallback(() => {
    const issue = queryClient.getQueriesData<IssueDto[] | undefined>({ queryKey: listQueryKey }).flatMap((item) => {
      if (!item) return [];
      const [, issues] = item;
      if (!issues) return [];
      return issues;
    }).find((v) => v.id === id);
    return issue;
  }, [id, listQueryKey, queryClient]);
  const queryOptions = useMemo<IssueUseQueryOptions>(() => ({
    ...options,
    queryKey,
    initialData,
    enabled: (options?.enabled !== false) && (queryKey.length > 0),
  }), [initialData, options, queryKey]);
  return useQuery(queryOptions);
}
