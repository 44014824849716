import { useState, useRef } from "react";

const useMenuPlacement = ({ selectRef, menuPlacement }) => {
  const [innerMenuPlacement, setInnerMenuPlacement] = useState();
  const menuObserver = useRef({});

  const onMenuOpen = () => {
    const observeOnscreen = (entries = []) => {
      const { boundingClientRect, intersectionRect } = entries[0];
      const isOffscreen = intersectionRect.height < boundingClientRect.height;

      if (menuPlacement === "auto" && isOffscreen) {
        setInnerMenuPlacement("top");
      }
    };

    setTimeout(() => {
      const menuList = selectRef.current.menuListRef;
      menuObserver.current = new IntersectionObserver(observeOnscreen);
      menuObserver.current.observe(menuList);
    }, 1);
  };

  const onMenuClose = () => {
    menuObserver.current && menuObserver.current.disconnect();
  };

  const placement = innerMenuPlacement || menuPlacement;

  return {
    onMenuOpen,
    onMenuClose,
    placement,
  };
};

export default useMenuPlacement;
