import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import IssuesDataGridRow from 'issues/types/IssuesDataGridRow';
import LabelChip from 'labels/components/LabelChip';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import { CircularProgress } from '@mui/material';

export default function LabelIdsChipsCell({
  value,
}: ICellRendererParams<IssuesDataGridRow, Promise<string[]>>) {
  const { data: labels } = useLabelsOdataQuery({ filter: { id: { in: value } } });
  if (!value) return null;
  if (!labels) return <CircularProgress size={12} />;
  return labels.map((label) => <LabelChip key={label.id} sx={{ mr: 1 }} label={label} />);
}
