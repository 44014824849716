import IssueFilterSettings from 'issues/types/IssueFilterSettings';
import IssueFilterSettingsDto from 'issues/types/IssueFilterSettingsDto';
import { useCallback } from 'react';

export default function useIssueFilterSettingsEntityFactory() {
  return useCallback((dto: IssueFilterSettingsDto) => ({
    ...dto,
    editDateParsed: dto.editDate ? new Date(dto.editDate) : undefined,
    creationDateParsed: dto.creationDate ? new Date(dto.creationDate) : undefined,
  } as Readonly<IssueFilterSettings>), []);
}
