import { useMemo } from 'react';
import { QueryObserverOptions } from '@tanstack/react-query';
import usePdfAnnotationEntityFactory from 'documents-annotations/hooks/usePdfAnnotationEntityFactory';
import useDefaultOdataQueryFn from 'api/hooks/useDefaultOdataQueryFn';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { VisoOdataQueryKey } from 'api/hooks/useDefaultEntityQueryKeys';
import PdfAnnotationDto from 'documents-annotations/types/PdfAnnotationDto';
import PdfAnnotation from 'documents-annotations/types/PdfAnnotation';

export type PdfAnnotationsQueryKey = VisoOdataQueryKey<ApiEndpoint.PdfAnnotation, PdfAnnotationDto>;
export type PdfAnnotationsQueryQueryFnData = PdfAnnotation[] | undefined;
export type PdfAnnotationsQueryOptions = Partial<Omit<QueryObserverOptions<PdfAnnotationsQueryQueryFnData, unknown, PdfAnnotationsQueryQueryFnData, PdfAnnotationsQueryQueryFnData, PdfAnnotationsQueryKey>, 'queryKey'>>;

export default function usePdfAnnotationsQueryOptions() {
  const entityFactory = usePdfAnnotationEntityFactory();
  const queryFn = useDefaultOdataQueryFn<PdfAnnotationDto, PdfAnnotation, ApiEndpoint.PdfAnnotation>(entityFactory);
  return useMemo<PdfAnnotationsQueryOptions>(() => ({
    queryFn,
  }), [queryFn]);
}
