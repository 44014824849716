import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import { useTranslation } from 'react-i18next';
import useIssueCommentsQuery from 'issues/hooks/useIssueCommentsQuery';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import RichTextEditorContextProvider from 'rich-text/contexts/RichTextEditorContextProvider';
import RichTextField from 'rich-text/components/RichTextField';
import useRichTextEditorContext from 'rich-text/hooks/useRichTextEditorContext';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import PersistIssueCommentDto from 'issues/types/PersistIssueCommentDto';
import useCommentCreateMutation from 'issues/hooks/useCommentCreateMutation';
import IssueCommentItem from 'issues/components/IssueCommentItem';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';

interface IssueCommentsPanelProps {
  issueId: string,
  nonInteractive?: boolean | undefined,
}

function IssueCommentsPanel({
  issueId,
  nonInteractive,
}: IssueCommentsPanelProps) {
  const theme = useTheme();
  const { editor, text: commentText, setIsDisabled } = useRichTextEditorContext();
  const { t } = useTranslation('issues');
  const { data: issue } = useIssueQuery(issueId);
  const { data: comments } = useIssueCommentsQuery(issue?.commentIds);
  const { data: currentUser } = useCurrentUserQuery();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const orderedComments = useMemo(() => {
    if (!comments) return undefined;
    const items = comments.map((comment) => ({ comment, creationDateTimestamp: new Date(comment.creationDate).getTime() }));
    const sortedComments = items.sort((a, b) => b.creationDateTimestamp - a.creationDateTimestamp).map(({ comment }) => comment);
    return sortedComments;
  }, [comments]);
  const getRequestErrorMessage = useRequestErrorMessage();
  const { mutateAsync: createComment, isLoading: isLoadingCommentMutation } = useCommentCreateMutation();
  const [isCommenting, setIsCommenting] = useState<boolean>(false);
  const onClickComment = useCallback(() => setIsCommenting(true), []);
  const onClickDiscardComment = useCallback(() => {
    if (!editor) return;
    editor.commands.clearContent();
    setIsCommenting(false);
    setErrorMessage(undefined);
  }, [editor]);
  const onClickConfirmComment = useCallback(async () => {
    if (!editor) return;
    setErrorMessage(undefined);
    setIsDisabled(true);
    try {
      const persistIssueCommentDto: PersistIssueCommentDto = {
        issueId: { value: issueId },
        text: { value: commentText.trim() },
      };
      await createComment(persistIssueCommentDto);
      setIsCommenting(false);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    } finally {
      editor.commands.clearContent();
      setIsDisabled(false);
    }
  }, [commentText, createComment, editor, getRequestErrorMessage, issueId, setIsDisabled]);

  if (!issue || !currentUser || (issue.commentIds.length === 0 && nonInteractive)) return null;
  return (
    <Box id="IssueCommentsPanel">
      <Box sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.3)',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
      >
        <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
          <Typography variant="h4">
            {t('issue-comments-panel_header', 'Comments')}
            {!!orderedComments && ` (${orderedComments.length})`}
            {!orderedComments && <CircularProgress size={12} sx={{ ml: 1 }} />}
          </Typography>
          {!nonInteractive && (
            <Button
              variant="contained"
              color="secondary"
              sx={{ gap: 0.25, ml: 'auto', pl: 0.5, pr: 0.75, flexShrink: 0 }}
              disabled={isCommenting}
              onClick={onClickComment}
              size="small"
            >
              <Icon path={mdiPlus} size={0.75} />
              {t('issue-comments-panel_add-comment-button-label', 'Comment')}
            </Button>
          )}
        </Box>
        {!!isCommenting && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: 130 }}>
              <RichTextField
                sx={{ minHeight: 0, flexGrow: 1 }}
                label={t('issue-comments-panel_comment-textfield-label', 'Comment')}
              />
              {!!errorMessage && (
                <Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>
              )}
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={onClickDiscardComment}
                disabled={isLoadingCommentMutation}
              >
                {t('issue-comments-panel_discard-comment-button-label', 'Cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onClickConfirmComment}
                sx={{ flexGrow: 1 }}
                disabled={isLoadingCommentMutation || commentText.trim().length === 0}
              >
                {t('issue-comments-panel_send-comment-button-label', 'Send')}
              </Button>
            </Box>
          </Box>
        )}
        {!!orderedComments?.length && orderedComments.map((comment) => (
          <IssueCommentItem key={comment.id} comment={comment} />
        ))}
      </Box>
    </Box>
  );
}

export default function IssueCommentsPanelWrapper(props: IssueCommentsPanelProps) {
  return (
    <RichTextEditorContextProvider>
      <IssueCommentsPanel {...props} />
    </RichTextEditorContextProvider>
  );
}
