import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import { Modal } from "../../../HOC";
import SetupModel from "./SetupModel";
import UploadProgress from "./UploadProgress";

// Helpers
import { ModelService } from "../../../Services";

const UploadProgressModal = ({
  progress,
  isHide = false,
  header,
  onDone,
  doneText,
  uploadCategory = 0 /*0: issue, 1: model */,
  uploadFileSize = null,
  projectId,
  uploadHandles,
  hubConnection,
  options = { discipline: [], building: [], floor: [] },
  apiError,
  setConvertProgress,
  setNotification,
  uploadFileProgress,
  setConfirmNotification,
}) => {
  const { t } = useTranslation();
  const [metadatas, setMetadatas] = useState({});
  const [isStoreDoc, setIsStoreDoc] = useState({});
  const [currentSetupModelIndex, setCurrentSetupModelIndex] = useState(-1);
  const [totalUploadSize, setTotalUploadSize] = useState(0);
  const [allProgress, setAllProgress] = useState(progress);
  const [allUploadFileSize, setAllUploadFileSize] = useState(uploadFileSize);
  const uploadState = useMemo(() => {
    if (!allProgress || isEmpty(allProgress)) return 0;
    const state = parseFloat(
      Object.values(allProgress).reduce(
        (prevValue, curValue) => parseInt(prevValue) + parseInt(curValue)
      ) / Object.values(allProgress).length
    ).toFixed(0);
    return state;
  }, [allProgress]);

  useEffect(() => {
    if (!progress || isEmpty(progress)) {
      setAllProgress({});
      setCurrentSetupModelIndex(-1);
      return;
    }
    setAllProgress((prev) => {
      Object.keys(progress).forEach((fileName) => {
        prev[fileName] = progress[fileName];
      });
      return { ...prev };
    });
  }, [progress]);

  useEffect(() => {
    if (!uploadFileSize || isEmpty(uploadFileSize)) {
      setAllUploadFileSize({});
      return;
    }
    setAllUploadFileSize((prev) => {
      Object.keys(uploadFileSize).forEach((fileName) => {
        prev[fileName] = uploadFileSize[fileName];
      });
      return { ...prev };
    });
  }, [uploadFileSize]);

  useEffect(() => {
    if (uploadCategory !== 1) return;
    if (
      Object.keys(allUploadFileSize)?.length === Object.keys(metadatas)?.length
    )
      return;

    const buffer = { ...metadatas };
    const bufferAdd = { ...isStoreDoc };
    let totalSize = 0;
    for (let key in allUploadFileSize) {
      buffer[key] = {};
      buffer[key]["discipline"] =
        options.discipline.length > 0 ? options.discipline[0] : {};
      buffer[key]["building"] =
        options.building.length > 0 ? options.building[0] : {};
      buffer[key]["floor"] = options.floor.length > 0 ? options.floor[0] : {};
      bufferAdd[key] = true;
      totalSize += parseFloat(allUploadFileSize[key]);
    }
    setMetadatas(buffer);
    setIsStoreDoc(bufferAdd);
    setTotalUploadSize(totalSize.toFixed(1));
  }, [allUploadFileSize, options.discipline, options.building, options.floor]);

  const handleButtonClick = useCallback(
    async (e) => {
      const name = e.target.name;

      switch (name) {
        case "back":
          setCurrentSetupModelIndex(currentSetupModelIndex - 1);
          break;
        case "next":
          setCurrentSetupModelIndex(currentSetupModelIndex + 1);
          break;
        case "finish":
          await Promise.all(
            Object.keys(allUploadFileSize).map(async (fileName) => {
              const params = {
                categoryMetaDataId: metadatas[fileName]["discipline"].value,
                buildingMetaDataId: metadatas[fileName]["building"].value,
                floorMetaDataId: metadatas[fileName]["floor"].value,
                uploadHandle: uploadHandles[fileName],
                storeAsDocument: isStoreDoc[fileName],
              };
              await ModelService.uploadModelMetaData(projectId, params);
            })
          );

          onDone();
          setNotification({
            title: t("all_models_setup", "All models set up"),
            description: t(
              "converted_models_sucessfully",
              "You successfully set up your models. Your models will get <b>converted</b> now."
            ),
          });
          setConvertProgress(99);
          setTimeout(() => setConvertProgress(100), 5000);
          break;
        default:
          break;
      }
    },
    [
      currentSetupModelIndex,
      allUploadFileSize,
      projectId,
      metadatas,
      isStoreDoc,
      uploadHandles,
      hubConnection,
      uploadState,
    ]
  );

  const onChangeCheckbox = useCallback(
    (e, fileName) => {
      const value = e.target.checked;
      const buffer = { ...isStoreDoc };
      buffer[fileName] = value;
      setIsStoreDoc(buffer);
    },
    [isStoreDoc]
  );

  const handleSelectChange = useCallback(
    (value, category, fileName) => {
      const buffer = { ...metadatas };
      buffer[fileName][category] = value;
      setMetadatas(buffer);
    },
    [metadatas]
  );

  return (
    <Modal className={isHide ? "hide" : ""}>
      <div className="upload-progress-modal--wrapper">
        {uploadCategory !== 1 ? (
          <UploadProgress
            uploadCategory={uploadCategory}
            uploadFileSize={allUploadFileSize}
            header={header}
            progress={allProgress}
            doneText={doneText}
            currentSetupModelIndex={currentSetupModelIndex}
            handleButtonClick={handleButtonClick}
            onDone={onDone}
            apiError={apiError}
          />
        ) : (
          <SetupModel
            uploadFileSize={allUploadFileSize}
            uploadCategory={uploadCategory}
            currentSetupModelIndex={currentSetupModelIndex}
            totalUploadSize={totalUploadSize}
            uploadState={uploadState}
            progress={allProgress}
            disciplines={options.discipline}
            buildings={options.building}
            floors={options.floor}
            metadatas={metadatas}
            isStoreDoc={isStoreDoc}
            doneText={doneText}
            handleButtonClick={handleButtonClick}
            handleSelectChange={handleSelectChange}
            onChangeCheckbox={onChangeCheckbox}
            onDone={onDone}
            uploadFileProgress={uploadFileProgress}
            setConfirmNotification={setConfirmNotification}
          />
        )}
      </div>
    </Modal>
  );
};

export default UploadProgressModal;
