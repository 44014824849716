import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import CustomSelect from "../CustomSelect";
import IssueStatusItem from "../IssueStatusItem";
import { IconButton } from "../Buttons";

const LinkedIssueExpand = ({
  issues,
  linkedIssueIds,
  isShowDropdown,
  statuses,
  statusFilter,
  onShowExpand,
  onCloseExpand,
  handleChangeStatusFilter,
  setSelectedIssue,
}) => {
  const { t } = useTranslation();
  const filteredIssues = useMemo(
    () =>
      issues.filter(
        (issue) =>
          linkedIssueIds.includes(issue.id) &&
          statusFilter.some((item) => item.id === issue.issueStatus?.id)
      ),
    [linkedIssueIds, statusFilter, issues]
  );

  return (
    <>
      {!!linkedIssueIds?.length && (
        <div className="issue-count-mark">
          <div className="count-label" onClick={onShowExpand}>
            {`+${linkedIssueIds.length}`}
          </div>
          <div
            id="count_dropdown"
            className={`count-dropdown ${isShowDropdown ? "show" : "hide"}`}
          >
            <div className="count-dropdown-header">
              <div className="count-dropdown-header-label">
                {t("status", "Status")}
              </div>
              <CustomSelect
                values={statuses}
                onChange={handleChangeStatusFilter}
                value={statuses.filter((status) =>
                  statusFilter.some((item) => item.id === status.value)
                )}
                isMulti={true}
                isModel={true}
                name="status"
              />
              <IconButton
                className="btn-close"
                iconId="close"
                onClick={onCloseExpand}
              />
            </div>
            <div className="count-dropdown-content custom-scrollbar">
              {filteredIssues.map((issue) => (
                <IssueStatusItem
                  key={`issue-status-${issue.id}`}
                  status={statuses.find(
                    (item) => item.value === issue.issueStatus?.id
                  )}
                  name={issue.title}
                  onClick={() => setSelectedIssue(issue)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LinkedIssueExpand;
