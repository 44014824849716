import React, { useCallback, useEffect, useState } from 'react';
import {
  Box, FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TimeFrame from 'documents-filter/types/TimeFrame';
import LocalizedDatePicker from 'common/components/LocalizedDatePicker';
import DateRange from 'common/types/DateRange';
import DateRangeKind from 'common/types/DateRangeKind';
import useTimeFrameFromToDates from 'common/hooks/useTimeFrameDates';

enum SpecialTimeFrame {
  Custom = -1,
  Undefined = -2,
}

type TimeFrameSelection = TimeFrame | SpecialTimeFrame;

interface DateRangePickerProps {
  value: DateRange | undefined,
  onChange: (value: DateRange | undefined) => void,
}

export default function DateRangePicker({
  value,
  onChange,
}: DateRangePickerProps) {
  const { t } = useTranslation();
  const getTimeFrameDates = useTimeFrameFromToDates();
  const [timeFrame, setTimeFrame] = useState<TimeFrameSelection>(SpecialTimeFrame.Undefined);
  const [from, setFrom] = useState<Date | undefined>(undefined);
  const [to, setTo] = useState<Date | undefined>(undefined);

  const notifyParent = useCallback((timeFrameValue: TimeFrameSelection, fromValue: Date | undefined, toValue: Date | undefined) => {
    if (timeFrameValue === SpecialTimeFrame.Custom) {
      if (fromValue !== undefined || toValue !== null) {
        onChange({
          dateRangeKind: DateRangeKind.Custom,
          from: fromValue,
          to: toValue,
        });
      }
    } else if (timeFrameValue !== SpecialTimeFrame.Undefined) {
      onChange({
        dateRangeKind: DateRangeKind.Relative,
        timeFrame: timeFrameValue,
      });
    } else {
      onChange(undefined);
    }
  }, [onChange]);

  const handleChangeTimeFrame = useCallback((timeFrameValue: TimeFrameSelection) => {
    if (timeFrame === timeFrameValue) return;
    let fromValue: Date | undefined;
    let toValue: Date | undefined;

    if (timeFrameValue === SpecialTimeFrame.Undefined) {
      fromValue = undefined;
      toValue = undefined;
    } else if (timeFrameValue === SpecialTimeFrame.Custom) {
      fromValue = from;
      toValue = to;
    } else {
      ({ fromValue, toValue } = getTimeFrameDates(timeFrameValue));
    }

    setTimeFrame(timeFrameValue);
    setFrom(fromValue);
    setTo(toValue);
    notifyParent(timeFrameValue, fromValue, toValue);
  }, [from, getTimeFrameDates, notifyParent, timeFrame, to]);

  const handleChangeFrom = useCallback((fromValue: Date | undefined) => {
    if (from === fromValue) return;
    setFrom(fromValue);
    notifyParent(timeFrame, fromValue, to);
  }, [notifyParent, timeFrame, from, to]);

  const handleChangeTo = useCallback((toValue: Date | undefined) => {
    if (to === toValue) return;
    setTo(toValue);
    notifyParent(timeFrame, from, toValue);
  }, [notifyParent, timeFrame, from, to]);

  useEffect(() => {
    if (!value) {
      setTimeFrame(SpecialTimeFrame.Undefined);
      setFrom(undefined);
      setTo(undefined);
    } else if (value.dateRangeKind === DateRangeKind.Relative) {
      const { fromValue, toValue } = getTimeFrameDates(value.timeFrame);
      setTimeFrame(value.timeFrame);
      setFrom(fromValue);
      setTo(toValue);
    } else if (value.dateRangeKind === DateRangeKind.Custom) {
      setTimeFrame(SpecialTimeFrame.Custom);
      setFrom(value.from);
      setTo(value.to);
    }
  }, [getTimeFrameDates, value]);

  return (
    <Box sx={{
      display: 'grid', gridTemplateColumns: '.5fr .5fr', columnGap: '4px', rowGap: '8px', mt: 1,
    }}
    >
      <FormControl sx={{ width: '100%', gridColumn: '1 / 3' }}>
        <InputLabel id="date-range-picker_timeframe-label">{t('date-range-picker_timeframe-label', 'Time Frame')}</InputLabel>
        <Select
          labelId="date-range-picker_timeframe-label"
          label={t('date-range-picker_timeframe-label', 'Time Frame')}
          value={timeFrame !== SpecialTimeFrame.Undefined ? timeFrame : ''}
          onChange={(e) => handleChangeTimeFrame(e.target.value as TimeFrameSelection)}
        >
          <MenuItem value={SpecialTimeFrame.Undefined}><em>{t('date-range-picker_item-any', 'Any')}</em></MenuItem>
          <MenuItem value={SpecialTimeFrame.Custom}>{t('date-range-picker_item-custom', 'Custom')}</MenuItem>
          <MenuItem value={TimeFrame.Today}>{t('date-range-picker_item-today', 'Today')}</MenuItem>
          <MenuItem value={TimeFrame.Yesterday}>{t('date-range-picker_item-yesterday', 'Yesterday')}</MenuItem>
          <MenuItem value={TimeFrame.ThisWeek}>{t('date-range-picker_item-thisWeek', 'This Week')}</MenuItem>
          <MenuItem value={TimeFrame.LastWeek}>{t('date-range-picker_item-lastWeek', 'Last Week')}</MenuItem>
          <MenuItem value={TimeFrame.ThisMonth}>{t('date-range-picker_item-thisMonth', 'This Month')}</MenuItem>
          <MenuItem value={TimeFrame.LastMonth}>{t('date-range-picker_item-lastMonth', 'Last Month')}</MenuItem>
        </Select>
      </FormControl>
      <LocalizedDatePicker
        label={t('date-range-picker_from', 'From')}
        value={from ?? null}
        disabled={timeFrame !== SpecialTimeFrame.Custom}
        onChange={(v) => handleChangeFrom(v?.toDate())}
        maxDate={to}
      />
      <LocalizedDatePicker
        label={t('date-range-picker_to', 'To')}
        value={to ?? null}
        disabled={timeFrame !== SpecialTimeFrame.Custom}
        onChange={(v) => handleChangeTo(v?.toDate())}
        minDate={from}
      />
    </Box>
  );
}
