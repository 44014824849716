import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Core, UI } from '@pdftron/webviewer';

import { FileTypeIndexes } from 'Helpers/Common';
import { useWindowDimensions, useAnnotationChangedHandler } from 'Helpers/Hooks';
import {
  editAnnotation,
  getAnnotationsForDocumentVersion,
} from 'Services/PdfAnnotationService';
import {
  customPDFWebviewerButtons,
  initPDFWebviewerUI,
} from 'Helpers/PDFUIHelper';
import useStampInsertionDownloadFunction from 'Components/DocumentPreviewModal/hooks/useStampInsertionDownloadFunction';
import useWebViewerInstance from 'Components/DocumentPreviewModal/hooks/useWebViewerInstance';
import { AnnotActionType } from 'Components/DocumentPreviewModal/types';
import { DocumentVersionOld } from 'Entities/RestEntities/DocumentVersion';
import $TSFixMe from 'common/types/FixMeAny';
import IssueOld from 'issues/types/IssueOld';
import useFileUrl from 'Components/DocumentPreviewModal/hooks/useFileUrl';
import { PDFAnnotation } from 'Entities/RestEntities/PDFAnnotation';
import useApiConfig from 'api/hooks/useApiConfig';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';

export interface PDFPreviewProps {
  resourceId?: string;
  resourceType: number;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  previewDoc: DocumentVersionOld;
  optionsToken: $TSFixMe;
  setAddIssue: React.Dispatch<React.SetStateAction<boolean>>;
  documentLinkedIssue: IssueOld[];
  setDocumentLinkedIssue: React.Dispatch<React.SetStateAction<IssueOld[]>>;
  setIsLinkExistingIssue: React.Dispatch<React.SetStateAction<boolean>>;
  selectedIssue: IssueOld | undefined;
  setPreviewerUI: React.Dispatch<React.SetStateAction<typeof UI | null>>;
  setShownIssuesList: (issueIds?: string[]) => void;
  openLinkedIssues: () => void;
  pdfAnnotationManager: Core.AnnotationManager | null;
  setPDFAnnotationManager: React.Dispatch<React.SetStateAction<Core.AnnotationManager | null>>;
  onInitialized: () => void;
}

function PDFPreview({
  resourceId,
  resourceType,
  setLoading,
  previewDoc,
  optionsToken,
  setAddIssue,
  documentLinkedIssue,
  setDocumentLinkedIssue,
  setIsLinkExistingIssue,
  selectedIssue,
  setPreviewerUI,
  setShownIssuesList,
  openLinkedIssues,
  pdfAnnotationManager,
  setPDFAnnotationManager,
  onInitialized,
}: PDFPreviewProps) {
  const { t } = useTranslation();
  const projectId = useCurrentProjectId();
  const pdfUrl = useFileUrl(resourceId, resourceType);
  const { apiUrl } = useApiConfig();
  const docView = useRef<any>(null);
  const [pdfUI, setPDFUI] = useState<typeof UI | null>(null);
  const [selectedAnnotations, setSelectedAnnotations] = useState<
  Core.Annotations.Annotation[]
  >([]);
  const [annotationMap, setAnnotationMap] = useState<{
    [pdtTronAnnotationId: string]: PDFAnnotation | undefined;
  }>({});
  const { i18n } = useTranslation();
  const { width } = useWindowDimensions();
  const { setAnnotAction, annotAction } = useAnnotationChangedHandler({
    pdfAnnotationManager,
    setAnnotationMap,
    annotationMap,
    projectId,
    documentVersionId: previewDoc.id,
  });

  const url1 = useMemo(
    () => `${apiUrl}/resource/${previewDoc.fileType === FileTypeIndexes.DWG
      ? previewDoc.previewFileId
      : previewDoc.fileId
    }`,
    [apiUrl, previewDoc.fileId, previewDoc.fileType, previewDoc.previewFileId],
  );

  const pdftronAnnotToPdfAnnot = useCallback(
    (pdfTronAnnotations: Core.Annotations.Annotation[]) => pdfTronAnnotations.map((a) => annotationMap[a.Id]) as PDFAnnotation[],
    [annotationMap],
  );

  const webViewerInstance = useWebViewerInstance(docView.current);
  const stampInsertionDownloadFunction = useStampInsertionDownloadFunction(webViewerInstance, previewDoc);

  useEffect(() => {
    if (
      !webViewerInstance
      || !projectId
      || !i18n.language
      || !previewDoc
      || !onInitialized
      || !setAddIssue
      || !setAnnotAction
      || !setIsLinkExistingIssue
      || !setPreviewerUI
      || !setPDFAnnotationManager
      || !stampInsertionDownloadFunction
    ) return;

    const core = webViewerInstance.Core;
    const ui = webViewerInstance.UI;
    let isDocumentLoading = true;
    const contextMenuItems: any[] = ui.contextMenuPopup.getItems();
    const lastItem = contextMenuItems[contextMenuItems.length - 1];
    setPDFUI(ui);
    setPDFAnnotationManager(core.annotationManager);
    setPreviewerUI(ui);
    ui.setLanguage(i18n.language);
    ui.enableFeatures([webViewerInstance.UI.Feature.Measurement]);
    ui.disableElements(['menuButton', 'downloadButton', 'saveAsButton', 'annotationDeleteButton', 'eraserToolButton', 'undoButton', 'redoButton']);

    initPDFWebviewerUI(
      ui,
      lastItem,
      setAddIssue,
      setIsLinkExistingIssue,
    );

    core.documentViewer.addEventListener('documentLoaded', async () => {
      // adding an event listener for when a document is loaded
      const currentAnnotations = await getAnnotationsForDocumentVersion(
        projectId,
        previewDoc?.id,
      );
      if (currentAnnotations === null) {
        throw new Error('Error during annotation loading.');
      }

      const embeddedAnnotations = [
        ...core.annotationManager.getAnnotationsList(),
      ];

      embeddedAnnotations.forEach((a) => {
        // eslint-disable-next-line no-param-reassign
        a.Listable = false;
        // eslint-disable-next-line no-param-reassign
        a.Locked = true;
      });

      await Promise.all(
        currentAnnotations.map(async (pdfAnnotation: any) => {
          const pdfTronAnnotations: Core.Annotations
            .Annotation[] = await core.annotationManager.importAnnotations(
            pdfAnnotation.xfdfString,
          );

          if (pdfTronAnnotations.length === 0) return;
          if (pdfTronAnnotations.length !== 1) {
            throw new Error(
              'XFDF-String should only contain single annotations',
            );
          }

          setAnnotationMap((prevState) => ({
            ...prevState,
            [pdfTronAnnotations[0].Id]: pdfAnnotation,
          }));

          if (pdfTronAnnotations) {
            pdfTronAnnotations.forEach((annotation) => {
              core.annotationManager.redrawAnnotation(annotation);
            });
          }
        }),
      );
      core.annotationManager.addEventListener(
        'annotationChanged',
        async (annotations, action: string) => {
          if (isDocumentLoading) return;
          if (action === 'add') {
            setAnnotAction({ action: AnnotActionType.ADD, annotations });
          } else if (action === 'modify') {
            setAnnotAction({ action: AnnotActionType.MODIFY, annotations });
          } else if (action === 'delete') {
            setAnnotAction({ action: AnnotActionType.DELETE, annotations });
          }
        },
      );
      core.annotationManager.addEventListener(
        'annotationSelected',
        (_annotations: Core.Annotations.Annotation[], action: string) => {
          if (action === 'selected') {
            setAnnotAction({
              action: AnnotActionType.SELECTION_ADDED,
              annotations: core.annotationManager.getSelectedAnnotations(),
            });
          } else if (action === 'deselected') {
            setAnnotAction({
              action: AnnotActionType.SELECTION_REMOVED,
              annotations: core.annotationManager.getSelectedAnnotations(),
            });
          }
        },
      );

      const doc = core.documentViewer.getDocument();
      doc.getFileData = stampInsertionDownloadFunction;

      isDocumentLoading = false;
      onInitialized();
    });
    core.documentViewer.addEventListener('pageNumberUpdated', () => {
      // adding an event listener for when the page number has changed
    });
  }, [webViewerInstance, projectId, i18n.language, onInitialized, previewDoc,
    setAddIssue, setAnnotAction, setIsLinkExistingIssue, setPDFAnnotationManager, setPreviewerUI, stampInsertionDownloadFunction]);

  useEffect(() => {
    if (!documentLinkedIssue.length) {
      return;
    }

    const linkedIssueId = documentLinkedIssue[0].id;
    setDocumentLinkedIssue([]);

    const exisitingPDFAnnotations = pdftronAnnotToPdfAnnot(selectedAnnotations);

    const editDtos = exisitingPDFAnnotations.map((a) => ({
      ...a!,
      linkedIssueId,
    }));
    editDtos.forEach((editDto) => {
      editAnnotation(editDto).then((result: any) => {
        if (!result) {
          throw new Error('Error during Issue linking');
        }
        setAnnotationMap((prevState) => {
          const newState = { ...prevState };
          selectedAnnotations.forEach((pdfTronAnnotation, index) => {
            newState[pdfTronAnnotation.Id] = editDtos[index];
          });
          return newState;
        });
      });
    });
  }, [
    pdftronAnnotToPdfAnnot,
    selectedAnnotations,
    documentLinkedIssue,
    setDocumentLinkedIssue,
  ]);

  useEffect(() => {
    if (!pdfUrl || !pdfUI) return;
    pdfUI.loadDocument(url1, { ...optionsToken, filename: previewDoc.originalFileName });
    setLoading(false);
  }, [pdfUrl, pdfUI, url1, optionsToken, setLoading, previewDoc.originalFileName]);

  useEffect(() => {
    if (!pdfAnnotationManager) return;
    const allAnnotations = pdfAnnotationManager.getAnnotationsList();
    if (!selectedIssue) {
      pdfAnnotationManager.showAnnotations(allAnnotations);
      return;
    }
    const issueId = selectedIssue.id;

    const showList: Core.Annotations.Annotation[] = [];
    const hideList: Core.Annotations.Annotation[] = [];

    allAnnotations.forEach((a) => {
      const pdfAnnotation = annotationMap[a.Id];
      if (!pdfAnnotation) return;
      if (pdfAnnotation.linkedIssueId === issueId) {
        showList.push(a);
      } else {
        hideList.push(a);
      }
    });
    pdfAnnotationManager.showAnnotations(showList);
    pdfAnnotationManager.hideAnnotations(hideList);
    pdfAnnotationManager.deselectAnnotations(hideList);
  }, [pdfAnnotationManager, selectedIssue, annotationMap]);

  useEffect(() => {
    if (
      annotAction?.action !== AnnotActionType.SELECTION_ADDED
      && annotAction?.action !== AnnotActionType.SELECTION_REMOVED
    ) {
      return;
    }

    setAnnotAction(null);
    const newSelectionList = annotAction.annotations;
    setSelectedAnnotations(newSelectionList);
    if (newSelectionList.length === 0) {
      setShownIssuesList();
    } else {
      const pdfAnnotations = pdftronAnnotToPdfAnnot(newSelectionList);

      const issueIds: string[] = pdfAnnotations
        // (Fix for #14803)
        // if we selected an annotation that was just created before the API response is there,
        // it will map to undefined. It's okay to ignore these entries for retrieving the linked issues,
        // because new annotations can't have linked issues
        .filter((a) => a !== undefined)
        .filter((a) => a!.linkedIssueId)
        .map((a) => a!.linkedIssueId!);
      setShownIssuesList(issueIds);
    }

    if (annotAction.action === AnnotActionType.SELECTION_ADDED) { openLinkedIssues(); }
  }, [annotAction, setShownIssuesList, pdftronAnnotToPdfAnnot, setAnnotAction, openLinkedIssues]);

  useEffect(() => {
    if (!pdfUI || previewDoc.isPrivate) {
      return;
    }

    const { linkIssue, options } = customPDFWebviewerButtons(
      t,
      // width,
      setAddIssue,
      setIsLinkExistingIssue,
    );
    // display custom buttom in webviewer menu
    if (selectedAnnotations.length > 0) {
      pdfUI.setHeaderItems((header: any) => {
        const items = header
          .getHeader('default')
          .getItems()
          .filter((item: any) => {
            if (item.dataElement === 'linkIssue') {
              linkIssue.disabled = false;
              linkIssue.style.opacity = '1';
            } else {
              return item;
            }
            return false;
          });
        header.update(items);
      });

      const contextMenuItems = pdfUI.contextMenuPopup.getItems();
      const filtredMenuItems = contextMenuItems.filter((item: any) => {
        if (
          item.dataElement === 'linkExistingIssue'
          || item.dataElement === 'createNewIssue'
        ) {
          return item;
        }
        return false;
      });

      if (filtredMenuItems.length) {
        pdfUI.enableElements(['linkExistingIssue', 'createNewIssue']);
      }
    }
    if (selectedAnnotations.length === 0) {
      pdfUI.setHeaderItems((header: any) => {
        linkIssue.disabled = true;
        const items = header
          .getHeader('default')
          .getItems()
          .filter((item: any) => {
            if (item.dataElement === 'linkIssue') {
              linkIssue.disabled = true;
              linkIssue.style.opacity = '0.3';
              return false;
            } if (item.dataElement !== 'options') return item;
            return false;
          });
        header.update(items);
      });
      pdfUI.disableElements(['linkExistingIssue', 'createNewIssue']);
    }
    pdfUI.setHeaderItems((header: any) => {
      const isElementCreated = header
        .getHeader('default')
        .getItems()
        .some(
          (item: any) => item.dataElement === 'linkIssue',
        );
      if (!isElementCreated) {
        header.getHeader('default').push(
          {
            type: 'customElement',
            dataElement: 'options',
            render: () => options,
          },
          {
            type: 'customElement',
            dataElement: 'linkIssue',
            render: () => linkIssue,
          },
        );
      } else {
        header
          .getHeader('default')
          .getItems()
          .map((item: any) => {
            if (item.dataElement === 'options') {
              return {
                type: 'customElement',
                dataElement: 'options',
                render: () => options,
              };
            }
            if (item.dataElement === 'linkIssue') {
              return {
                type: 'customElement',
                dataElement: 'linkIssue',
                render: () => linkIssue,
              };
            }
            return undefined;
          });
      }
    });
  }, [
    t,
    pdfUI,
    selectedAnnotations,
    previewDoc,
    width,
    setAddIssue,
    setIsLinkExistingIssue,
  ]);

  return <div id="PDFPreview" className="preview-section-content" ref={docView} />;
}

PDFPreview.defaultProps = {
  resourceId: undefined,
};

export default PDFPreview;
