import { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import useDefaultEntitiesByIdsEndpointQuery from 'api/hooks/useDefaultEntitiesByIdsEndpointQuery';
import useProjectEntityFactory from 'projects/hooks/useProjectEntityFactory';
import ApiEndpoint from 'api/types/ApiEndpoint';
import Project from 'projects/types/Project';
import ProjectDto from 'projects/types/ProjectDto';

export default function useProjectsQuery<TSelectedData extends Project[] | Project | undefined = Project[] | undefined>(ids: string[] | undefined, options?: Omit<UseQueryOptions<Project[] | undefined, unknown, TSelectedData, QueryKey>, 'queryKey' | 'queryFn'> | undefined) {
  const entityFactory = useProjectEntityFactory();
  return useDefaultEntitiesByIdsEndpointQuery<ProjectDto, Project, TSelectedData>(
    ApiEndpoint.Project,
    ids,
    entityFactory,
    options,
  );
}
