import * as Request from "../Helpers/Request";

export const getIssueTypeIcons = () => {
  const icons = {
    default: [],
    other: [],
  };
  //default icons
  for (let i = 1; i <= 10; i++) icons.default.push(`icon-default-type-${i}`);
  //other icons
  for (let i = 1; i <= 30; i++) icons.other.push(`issue-other-type-${i}`);
  return icons;
};

export async function getIssueStatus(projectId) {
  const result = await Request.GET("api/issue-status");
  return result.status === 200 ? result.body : [];
}

export async function createIssueStatus(params) {
  const result = await Request.POST("api/issue-status", params);
  return result.status === 200 ? result.body : [];
}

export async function updateIssueStatus(params) {
  const result = await Request.PATCH("api/issue-status", params);
  return result.status === 200 ? result.body : [];
}

export async function updateIssueStatusOrder(params) {
  const result = await Request.PUT("api/issue-status/order", params);
  return result.status === 200;
}

export async function deleteIssueStatus(ids) {
  const result = await Request.DELETE(`api/issue-status/${ids}`);
  return result.status === 200;
}

export async function getIssueType(projectId) {
  const result = await Request.GET("api/issue-type");
  return result.status === 200 ? result.body : [];
}

export async function createIssueType(params) {
  const result = await Request.POST("api/issue-type", params);
  return result.status === 200 ? result.body : [];
}

export async function updateIssueType(params) {
  const result = await Request.PATCH("api/issue-type", params);
  return result.status === 200 ? result.body : [];
}

export async function updateIssueTypeOrder(params) {
  const result = await Request.PUT("api/issue-type/order", params);
  return result.status === 200;
}

export async function deleteIssueType(ids) {
  const result = await Request.DELETE(`api/issue-type/${ids}`);
  return result.status === 200;
}

export async function getIssuePriority(projectId) {
  const result = await Request.GET("api/issue-priority");
  return result.status === 200 ? result.body : [];
}

export async function createIssuePriority(params) {
  const result = await Request.POST("api/issue-priority", params);
  return result.status === 200 ? result.body : [];
}

export async function updateIssuePriority(params) {
  const result = await Request.PATCH("api/issue-priority", params);
  return result.status === 200 ? result.body : [];
}

export async function updateIssuePriorityOrder(params) {
  const result = await Request.PUT("api/issue-priority/order", params);
  return result.status === 200;
}

export async function deleteIssuePriority(ids) {
  const result = await Request.DELETE(`api/issue-priority/${ids}`);
  return result.status === 200;
}
