import { useMutation } from '@tanstack/react-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDocumentVersionUpdateMutationFn from 'documents/hooks/useDocumentVersionUpdateMutationFn';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import DocumentVersionFloorUpdateDto from 'documents/types/DocumentVersionFloorUpdateDto';

export default function useDocumentVersionFloorUpdateMutation() {
  const mutationFn = useDocumentVersionUpdateMutationFn<DocumentVersionFloorUpdateDto>(ApiEndpoint.DocumentVersionFloor);
  return useMutation<DocumentVersionDto, unknown, DocumentVersionFloorUpdateDto>(mutationFn);
}
