import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { uniq } from "lodash";

import {
  IssueService,
  EmailService,
  ViewpointService,
} from "../../Services";
import { getNameLinkMapParams } from "../../Helpers/utils";
import { IssueModalMode } from "../../Helpers/Common";

import Loader from "../Loader";
import LinkIssues from "./LinkIssues";
import IssueModalButton from "./IssueModalButton";
import IssueModalColumn from "./IssueModalColumn";
import IssueModalContent from "./IssueModalContent";
import IssueModalModel from "./IssueModalModel";

import useCurrentProjectQuery from "projects/hooks/useCurrentProjectQuery";
import useUserGroupsQuery from 'users-groups/hooks/useUserGroupsQuery';

const IssueModal = ({
  users,
  categories: disciplines,
  buildings,
  workphases,
  floors,
  priorities,
  types,
  statuses,
  tagOptions,
  toggleIssueModal,
  editIssue = null,
  mode, //0: hide, 1: create, 2: edit
  selected,
  setIssueSavedModalVisible,
  folders,
  issues,
  collaboratorRoleDefinitions = [],
  baseIssuesUrl,
  selectedDate,
  canEditIssue,
  setModalCount,
  modalCount,
  isTimeline,
  linkEmail,
  currentIssue,
  setCurrentIssue,
  showCreateModal,
  setShowCreateModal,
  setIsLinkedCreatIssue,
  setEditIssue,
  isMassEdit,
  setIsMassEdit,
  updateEmail,
  isLinkedIssues,
  linkedIssues,
  previewDocument,
  setDocumentLinkedIssue,
  documentLinkedIssue,
  isLinkExistingIssue,
  setIsLinkExistingIssue,
  allIssues,
  openIssueDetails,
  addIssue,
  onIssueCreate,
  setIssueAddMode,
  components,
  viewpoints,
  isModifiedIssueComponents,
  setAddComponents,
  setAddViewpoints,
  setIsModifiedIssueComponents,
  planListDocuments = [], // TODO create issue from planlist
}) => {
  const { t } = useTranslation();
  const { data: currentProject } = useCurrentProjectQuery();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(t("new_issue", "New Issue"));
  const [explain, setExplain] = useState("");
  const [workers, setWorkers] = useState([]);
  const [reviewer, setReviewer] = useState(null);
  const [priority, setPriority] = useState(null);
  const [discipline, setDiscipline] = useState([]);
  const [building, setBuilding] = useState([]); //optional
  const [workphase, setWorkphase] = useState(null); //optional
  const [floor, setFloor] = useState([]); //optional
  const [type, setType] = useState({});
  const [tags, setTags] = useState([]);
  const [dueDate, setDueDate] = useState(null);
  const [status, setStatus] = useState(null);
  const [startingDate, setStartingDate] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [visibility, setVisibility] = useState("public");
  const [editMarkStates, setEditMarkStates] = useState({
    name: false,
    explain: false,
    workers: false,
    reviewer: false,
    priority: false,
    discipline: false,
    building: false,
    floor: false,
    type: false,
    tags: false,
    dueDate: false,
    startingDate: false,
    status: false,
    visibility: false,
    workphase: false,
    parentId: false,
    linkedEmail: false,
  });
  const [linkedEmails, setLinkedEmails] = useState(
    linkEmail ? [linkEmail] : []
  );
  const [loadedViewpoints, setLoadedViewpoints] = useState(null);
  const [apiError, setApiError] = useState("");
  const [isOverwriteTag, setIsOverwriteTag] = useState(true);
  const [mainTask, setMainTask] = useState([]);
  const [subTasks, setSubTasks] = useState([]);
  const [deletedMainTasks, setDeletedMainTasks] = useState([]);
  const [newSubTasks, setNewSubTasks] = useState([]);
  const [deletedSubTasks, setDeletedSubTasks] = useState([]);
  const [allowedUserGroups, setAllowedUserGroups] = useState([]);
  const [showLinkIssues, setShowLinkIssues] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [isDiscardChanges, setIsDiscardChanges] = useState(false);
  const [isDocumentLinkedIssue, setIsDocumentLinkedIssue] = useState(false);
  const [linkedDocumentVersionIds, setLinkedDocumentVersionIds] = useState(undefined);

  const title = useMemo(() => {
    if (mode === IssueModalMode.CREATE)
      return t("create_issue", "Create Issue");
    else if (isMassEdit) return t("mass_edit", "Mass Edit");
    else
      return `${t("edit_settings", "Edit Issue")} ${
        editIssue?.issueNumber || ""
      }`;
  }, [t, mode, isMassEdit]);

  const { data: userGroupsData } = useUserGroupsQuery();
  const visibilityOptions = useMemo(() => {
    if (!userGroupsData) return [];
    return userGroupsData.filter((g) => !(g.isDefault && g.name === 'External')).map((g) => ({
      label: g.name,
      value: g.id,
    }));
  }, [userGroupsData]);

  const isAllowedToSave = useMemo(
    () => subTasks.some((task) => task.id === mainTask[0]?.id),
    [subTasks, mainTask]
  );

  const adjustedTypes = useMemo(
    () =>
      isMassEdit
        ? [
            {
              value: null,
              label: t("no_selection", "No Selection"),
              order: -1,
            },
            ...types,
          ]
        : types,
    [isMassEdit, types, t]
  );

  useEffect(() => {
    if (isLinkExistingIssue) {
      setIsLoading(true);
      setTimeout(() => {
        setShowLinkIssues(true);
        setIsParent(true);
        setIsDocumentLinkedIssue(true);
        setIsLoading(false);
      }, 250);
    }
  }, [isLinkExistingIssue]);

  useEffect(() => {
    const effect = async () => {
      if (mode === IssueModalMode.EDIT && editIssue) {
        setLinkedDocumentVersionIds(Array.from(new Set(editIssue.linkedDocumentVersionIds)));
        setName(editIssue.title);
        setExplain(editIssue.description);
        if (editIssue.visibility === 1) {
          setVisibility("private");
        }

        if (editIssue.visibility === 0) {
          setVisibility("public");
        }

        if (editIssue.visibility === 2) {
          setVisibility("restricted");
          setAllowedUserGroups(
            visibilityOptions.filter(
              (o) => (editIssue.allowedUserGroups || []).indexOf(o.value) > -1
            )
          );
        }

        let tmpInnerWorkers = [];
        if (editIssue.assignedUsers) {
          const tmpWorkers = editIssue.assignedUsers.map((issueItem) =>
            Object.values(users).filter(
              (item) => item.value === issueItem.id
            )
          );
          tmpWorkers.forEach((item) => {
            if (item.length > 0) {
              tmpInnerWorkers.push(item[0]);
              setWorkers(tmpInnerWorkers);
            }
          });
        }

        const tmpReviewer = Object.values(users).find(
          (item) => item.value === editIssue.reviewerId
        );
        setReviewer(tmpReviewer);

        const tmpPriority = priorities.find(
          (item) => item.value === editIssue.issuePriority?.id
        );
        setPriority(tmpPriority);

        const tmpDisciplines = editIssue.disciplines?.map((issueItem) =>
          Object.values(disciplines).filter(
            (item) => item.id && item.id === issueItem.id
          )
        );
        let tmpInnerDisciplines = [];
        tmpDisciplines?.forEach((item) => {
          if (item.length > 0) {
            tmpInnerDisciplines.push(item[0]);
            setDiscipline(tmpInnerDisciplines);
          }
        });

        const tmpBuildings = editIssue.buildings?.map((issueItem) =>
          Object.values(buildings).filter(
            (item) => item.id && item.id === issueItem.id
          )
        );
        let tmpInnerBuildings = [];
        tmpBuildings?.forEach((item) => {
          if (item.length > 0) {
            tmpInnerBuildings.push(item[0]);
            setBuilding(tmpInnerBuildings);
          }
        });

        const tmpFloors = editIssue.floors?.map((issueItem) =>
          Object.values(floors).filter(
            (item) => item.id && item.id === issueItem.id
          )
        );
        let tmpInnerFloors = [];
        tmpFloors?.forEach((item) => {
          if (item.length > 0) {
            tmpInnerFloors.push(item[0]);
            setFloor(tmpInnerFloors);
          }
        });

        const tmpType = adjustedTypes.find(
          (item) => item.value === editIssue.issueType?.id
        );
        setType(tmpType);

        const tmpStatus = statuses.find(
          (item) => item.value === editIssue.issueStatus?.id
        );
        setStatus(tmpStatus);

        const tmpTags = tagOptions.filter((item) =>
          editIssue.tagIds?.includes(item.value)
        );
        setTags(tmpTags);

        const tmpWorkphase = workphases.find(
          (phase) => phase.id === editIssue.workPhaseId
        );
        setWorkphase(tmpWorkphase);

        const tmpStartingDate = editIssue.startingDate
          ? new Date(editIssue.startingDate)
          : null;
        setStartingDate(tmpStartingDate);

        const tmpDueDate = editIssue.dueDate ? new Date(editIssue.dueDate) : null;
        setDueDate(tmpDueDate);

        let tmpLinkedEmails = [];
        if (editIssue.linkedEmailIds?.length) {
          const allEmails = await EmailService.getInbox(currentProject?.id);
          tmpLinkedEmails = allEmails.filter((email) =>
            editIssue.linkedEmailIds.some((id) => id === email.id)
          );
        }
        setLinkedEmails(tmpLinkedEmails);

        if (setAddViewpoints) {
          setAddViewpoints(editIssue.viewpoints);
          setLoadedViewpoints(editIssue.viewpoints);
        }

        const tmpInitialValues = {
          name: editIssue.title,
          explain: editIssue.description,
          workers: tmpInnerWorkers,
          reviewer: tmpReviewer || null,
          priority: tmpPriority || null,
          discipline: tmpInnerDisciplines,
          building: tmpInnerBuildings,
          workphase: tmpWorkphase,
          floor: tmpInnerFloors,
          type: tmpType || null,
          tags: tmpTags,
          dueDate: tmpDueDate,
          startingDate: tmpStartingDate,
          status: tmpStatus,
          linkedEmails: tmpLinkedEmails,
        };
        setInitialValues(tmpInitialValues);
      } else {
        if (mode === IssueModalMode.CREATE)
          setPriority(priorities.find((item) => item.originalName === "Normal"));
        if (currentIssue) {
          setCurrentIssue(null);
        }
        if (selectedDate) setStartingDate(selectedDate);
        if (setAddViewpoints) setAddViewpoints([]);
      }
      isDiscardChanges && setIsDiscardChanges(false);
    };
    effect();
  }, [currentProject?.id, editIssue]);

  useEffect(() => {
    if (modalCount) {
      setModalCount(modalCount + 1);
    }
    setIsLinkedCreatIssue(true);
  }, []);

  const changeEditMarkState = useCallback((changedValue, key) => {
    setEditMarkStates((prev) => {
      let tmpEditMarkStates = Object.assign({}, prev);
      let initValue = JSON.stringify(initialValues[key]);
      let value = JSON.stringify(changedValue);
  
      if (key === "dueDate" || key === "startingDate") {
        initValue = initValue?.split(".")[0];
        value = value.split(".")[0];
      }
  
      if (initValue === value) tmpEditMarkStates[key] = false;
      else tmpEditMarkStates[key] = true;
      return tmpEditMarkStates;
    });
  }, []);

  const resetVariables = useCallback(() => {
    setName(t("new_issue", "New Issue"));
    setExplain("");
    setWorkers([]);
    setReviewer([]);
    setPriority(null);
    setDiscipline([]);
    setBuilding([]);
    setFloor([]);
    setLinkedDocumentVersionIds([]);

    let localType = null;
    if (isTimeline) {
      if (linkEmail)
        localType = adjustedTypes.find(
          (type) => type.originalName === "Request" || type.name === "Request"
        );
      else
        localType = adjustedTypes.find(
          (type) =>
            type.originalName === "Appointment" || type.name === "Appointment"
        );
    } else
      localType = adjustedTypes.find(
        (type) => type.originalName === "Issue" || type.name === "Issue"
      );
    setType(localType);
    setTags([]);
    setStartingDate(null);
    setDueDate(null);
    setStatus(null);
    setLinkedDocumentVersionIds([]);
    setWorkphase([]);
    setNewSubTasks([]);

    if (setAddViewpoints) setAddViewpoints([]);
    if (setAddComponents) setAddComponents([]);
  }, [
    adjustedTypes,
    priorities,
    isTimeline,
    linkEmail,
    setAddViewpoints,
    setAddComponents,
  ]);

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;
      const name = e.target.name;
      if (!name) {
        //description
        setExplain(value);
        if (mode === IssueModalMode.EDIT && editIssue)
          changeEditMarkState(value, "explain");
      } else {
        if (name === "input-name") {
          setName(value);
          if (mode === IssueModalMode.EDIT && editIssue)
            changeEditMarkState(value, "name");
        }
      }
    },
    [mode, changeEditMarkState, editIssue]
  );

  const handleSelectChange = useCallback(
    (value, name) => {
      if (value?.length && name !== "visibility") {
        value = value.filter((item) => item.id !== "reset");
      }

      switch (name) {
        case "worker":
          setWorkers(value);
          break;
        case "reviewer":
          setReviewer(value);
          break;
        case "discipline":
          setDiscipline(value);
          break;
        case "building":
          setBuilding(value);
          break;
        case "workphase":
          setWorkphase(value);
          break;
        case "floor":
          setFloor(value);
          break;
        case "priority":
          setPriority(value);
          break;
        case "type":
          setType(value);
          break;
        case "tags":
          setTags(value);
          break;
        case "status":
          setStatus(value);
          break;
        case "visibility":
          setVisibility(value);
          break;
        case "roles":
          setAllowedUserGroups(value);
          break;
        default:
          break;
      }
      if (mode === IssueModalMode.EDIT && editIssue) {
        const key = name === "worker" ? "workers" : name;
        changeEditMarkState(value, key);
      }
    },
    [mode, changeEditMarkState, editIssue]
  );

  const handleClick = useCallback(
    (e) => {
      switch (e.target.name) {
        case "btn-cancel":
          if (showCreateModal) {
            setShowCreateModal(false);
          } else {
            toggleIssueModal(IssueModalMode.HIDE);
          }
          setIsLinkedCreatIssue(false);
          if (setIsMassEdit && !isLinkedIssues) setIsMassEdit(false);
          if (!previewDocument) {
            setEditIssue([]);
            setLinkedDocumentVersionIds([]);
          }
          break;
        default:
          break;
      }
    },
    [showCreateModal]
  );

  const configureEditRequest = (body) => {
    if (!editIssue) {
      const result = {
        ...body,
        ids: selected,
        addTags: !isOverwriteTag,
      };
      delete result.description;
      delete result.linkedDocumentVersionIds;
      delete result.title;

      return result;
    }
    return [
      {
        ...body,
        id: editIssue.id,
      },
    ];
  };

  const createViewpoints = async (viewpointDtos, issueId) => {
    const responseViewpoint = !!(await ViewpointService.createViewpoints(
      viewpointDtos.map((item) => ({
        ...item.persistViewpointDto,
        issueId: { value: issueId },
        visibility:
          item.persistViewpointDto
            .Visibiity /** it needs to be changed Visibiity to Visbility from ifc-viewer**/,
      })),
      setApiError
    ));
    return responseViewpoint;
  };

  const handleSubmit = useCallback(
    async (e) => {
      if (isAllowedToSave) return;
      if (mode === IssueModalMode.CREATE && !explain)
        return setApiError("Explanation is mandatory");

      e.preventDefault();
      setIsLoading(true);

      let params = {};
      if (isMassEdit) {
        params = {
          disciplinesIds: {
            value:
              discipline && discipline.id === "reset"
                ? []
                : (discipline || []).map((item) => item.id),
          },
          buildingsIds: {
            value:
              building && building.id === "reset"
                ? []
                : (building || []).map((item) => item.id),
          },
          floorsIds: {
            value:
              floor && floor.id === "reset"
                ? []
                : (floor || []).map((item) => item.id),
          },
        };
      } else {
        params = {
          disciplines: discipline ? { value: discipline.map((item) => item.id) } : undefined,
          buildings: building ? { value: building.map((item) => item.id) } : undefined,
          floors: floor ? { value: floor.map((item) => item.id) } : undefined,
        };
      }

      let body = {
        title: { value: name },
        dueDate: dueDate ? { value: dueDate.toISOString() } : undefined,
        startingDate: startingDate ? { value: startingDate.toISOString() } : undefined,
        assignedUserIds: workers?.id === "reset" ? { value: [] } : workers?.length ? { value: workers.map((item) => item.value) } : undefined,
        reviewerId: reviewer?.id === "reset" ? { value: null } : reviewer?.value ? { value: reviewer.value } : undefined,
        tagIds: tags?.length ? { value: tags.map((tag) => tag.value) } : undefined,
        description: { value: explain || '' },
        linkedDocumentVersionIds: linkedDocumentVersionIds ? { value: Array.from(new Set(linkedDocumentVersionIds)) } : undefined,
        workPhaseId: workphase?.id === "reset" ? { value: null } : workphase?.value ? { value: workphase.value } : undefined,
        parentId: mainTask.length ? { value: mainTask[0].id } : undefined,
        statusId: status?.value ? { value: status?.value } : undefined,
        priorityId: priority?.id === "reset" ? { value: null } : priority?.value ? { value: priority.value } : undefined,
        typeId: type?.value ? { value: type.value } : undefined,
        linkedEmailIds: linkedEmails?.length ? { value: linkedEmails.map((email) => email.id) } : undefined,
        nameLinkMap: getNameLinkMapParams(
          explain,
          currentProject.userPreviews
        ) ?? undefined,
        linkedComponentsGlobalIds: components?.length ? { value: components.map((item) => item.globalId) } : undefined,
        ...params,
      };

      if (visibility === "private") {
        body.visibility = { value: 1 };
      }
      if (visibility === "public") {
        body.visibility = { value: 0 };
      }
      if (visibility === "restricted") {
        body.visibility = { value: 2 };
      }

      if (body.visibility) {
        if (isMassEdit) {
          body.allowedUserGroups = {
            value:
              body.visibility.value === 2
                ? allowedUserGroups.map((v) => v.value)
                : null,
          };
        } else {
          if (body.visibility.value === 2) {
            body.allowedGroups = { value: allowedUserGroups.map((v) => v.value) };
          }
        }
      }

      if (mode === IssueModalMode.EDIT) {
        if (editIssue) {
          if (!editMarkStates.name) body.title = null;
          if (!editMarkStates.dueDate) body.dueDate = null;
          if (!editMarkStates.workers) body.assignedUserIds = null;
          if (!editMarkStates.reviewer) body.reviewerId = null;
          if (!editMarkStates.type) body.typeId = null;
          if (!editMarkStates.tags) body.tagIds = null;
          if (!editMarkStates.priority) body.priorityId = null;
          if (!editMarkStates.explain) body.description = null;
          if (!editMarkStates.status) body.statusId = null;
          if (!editMarkStates.discipline) body.disciplines = null;
          if (!editMarkStates.building) body.buildings = null;
          if (!editMarkStates.floor) body.floors = null;
          if (!editMarkStates.startingDate) body.startingDate = null;
          if (!editMarkStates.parentId) body.parentId = null;
          if (!editMarkStates.linkedEmail) body.linkedEmailIds = null;
          if (!editMarkStates.workphase) body.workPhaseId = null;
          if (!editMarkStates.visibility) {
            delete body.visibility;
          }
          if (!editMarkStates.roles) {
            delete body.allowedGroups;
          }
          if (!isModifiedIssueComponents) body.linkedComponentsGlobalIds = null;
        } else {
          body.title = null;
          body.description = null;
          body.linkedDocumentVersionIds = [];

          if (!body.statusId?.value) body.statusId = null;
          if (!body.reviewerId?.value && reviewer?.id !== "reset")
            body.reviewerId = null;
          if (!body.description?.value) body.description = null;
          if (!body.workPhaseId?.value && workphase?.id !== "reset")
            body.workPhaseId = null;
          if (!body.assignedUserIds?.value?.length && workers?.id !== "reset")
            body.assignedUserIds = null;
          if (!body.tagIds?.value?.length) body.tagIds = null;
          if (!body.typeId?.value) body.typeId = null;
          if (!body.priorityId?.value && priority?.id !== "reset")
            body.priorityId = null;
          if (!body.linkedComponentsGlobalIds?.value)
            body.linkedComponentsGlobalIds = null;
          if (!body.linkedEmailIds?.value?.length) body.linkedEmailIds = null;
          if (!body.startingDate?.value) body.startingDate = null;
          if (!body.dueDate?.value) body.dueDate = null;
          if (isMassEdit) {
            if (!body.allowedGroupsIds?.value) body.allowedGroupsIds = null;
            if (
              !body.disciplinesIds?.value?.length &&
              discipline?.id !== "reset"
            )
              body.disciplinesIds = null;
            if (!body.buildingsIds?.value?.length && building?.id !== "reset")
              body.buildingsIds = null;
            if (!body.floorsIds?.value?.length && floor?.id !== "reset")
              body.floorsIds = null;
            if (!body.parentId?.value) body.parentId = null;
          } else {
            if (!body.allowedGroups?.value) body.allowedGroups = null;
            if (!body.disciplines?.value?.length) body.disciplines = null;
            if (!body.buildings?.value?.length) body.buildings = null;
            if (!body.floors?.value?.length) body.floors = null;
          }
        }
      } else {
        body.source = { value: "Visoplan" };
        const statusId = (statuses || []).find((item) => item.isDefault && item.originalName === "Open")?.value ?? null;
        body.statusId = statusId ? { value: statusId } : undefined;
      }
      let result = [];

      if (mode === IssueModalMode.EDIT) {
        let response = true;

        if (deletedMainTasks.length && deletedSubTasks.length) {
          response = await IssueService.editIssuebyPromise(
            [
              {
                id: editIssue.id,
                parentId: { value: null },
              },
            ],
            setApiError
          );
        }

        if (deletedSubTasks.length) {
          response = await IssueService.editIssueMany(
            {
              ids: deletedSubTasks.map((issue) => issue.id),
              parentId: { value: null },
            },
            setApiError
          );
        }

        if (newSubTasks.length) {
          response = await IssueService.editIssueMany(
            {
              ids: newSubTasks.map((issue) => issue.id),
              parentId: { value: editIssue.id },
            },
            setApiError
          );
        }

        //viewpoints edit
        if (viewpoints?.length) {
          //create viewpoints
          let crtResponseViewpoint = true,
            delresponseViewpoint = true;
          const newViewpoints = viewpoints.filter((item) => !item.issueId);
          if (newViewpoints?.length) {
            crtResponseViewpoint = await createViewpoints(
              newViewpoints,
              editIssue?.id
            );
          }

          //delete viewpoints
          const delViewpoints = loadedViewpoints.filter(
            (view) => !viewpoints.find((item) => view.id === item.id)
          );
          if (delViewpoints?.length) {
            delresponseViewpoint = await ViewpointService.deleteViewpoints(
              delViewpoints.map((item) => item.id)
            );
          }

          if (!crtResponseViewpoint || !delresponseViewpoint) {
            setIsLoading(false);
            return;
          }
        }

        if (response) {
          let result = null;
          if (editIssue) {
            result = await IssueService.editIssue(
              configureEditRequest(body),
              setApiError
            );
          } else {
            result = await IssueService.editIssueMany(
              configureEditRequest(body),
              setApiError
            );
          }

          if (result && setIssueSavedModalVisible !== undefined) {
            setIssueSavedModalVisible(true);
            if (modalCount) setModalCount(modalCount - 1);

            if (
              !components /* on 3D view */ &&
              !isTimeline &&
              !linkEmail &&
              result[0]?.id
            ) {
              navigate(`${baseIssuesUrl}${result[0].id}`);
            }
            if (showCreateModal) {
              setShowCreateModal(false);
            } else {
              toggleIssueModal(IssueModalMode.HIDE);
            }
            if (modalCount !== 2) {
              setIsLinkedCreatIssue(false);
              setEditIssue && setEditIssue(null);
            }
            resetVariables();
            setTimeout(() => {
              setIssueSavedModalVisible(false);
            }, 1000);
          }
        }
      } else {
        result = await IssueService.createIssue([body], setApiError);
        if (!result?.length) {
          setIsLoading(false);
          return;
        }
        let responseSubTasks = true;
        let responseMainTask = true;
        isLinkedIssues && onAddIssues(result, false);
        if (newSubTasks.length) {
          responseSubTasks = await IssueService.editIssueMany(
            {
              ids: newSubTasks.map((issue) => issue.id),
              parentId: { value: result[0].id },
            },
            setApiError
          );
        }

        if (mainTask.length) {
          responseMainTask = await IssueService.editIssuebyPromise(
            [
              {
                id: result[0].id,
                parentId: { value: mainTask[0].id },
              },
            ],
            setApiError
          );
        }

        if (responseMainTask && responseSubTasks) {
          //create viewpoints
          if (viewpoints?.length) {
            const responseViewpoint = await createViewpoints(
              viewpoints,
              result[0].id
            );

            if (!responseViewpoint) {
              setIsLoading(false);
              return;
            }
          }

          if (showCreateModal) {
            setShowCreateModal(false);
          } else {
            toggleIssueModal(IssueModalMode.HIDE);
          }
          if (modalCount === 2) {
            setIsLinkedCreatIssue(true);
          }
          if (modalCount !== 2) {
            setIsLinkedCreatIssue(false);
            setEditIssue && setEditIssue(null);
          }

          if (modalCount) setModalCount(modalCount - 1);
          if (onIssueCreate) onIssueCreate();
          resetVariables();
          setIssueSavedModalVisible(true);
          setSubTasks([]);
          setMainTask([]);
          if (setIsMassEdit) setIsMassEdit(false);

          if (currentIssue) {
            setCurrentIssue(null);
          }
          if (!isTimeline && !linkEmail && baseIssuesUrl)
            navigate(`${baseIssuesUrl}${result[0].id}`);
          if (setIssueSavedModalVisible !== undefined) {
            setTimeout(() => {
              setIssueSavedModalVisible(false);
            }, 1000);
          }
        }
      }

      if (updateEmail) updateEmail();
      setIsLoading(false);
    },
    [
      editIssue,
      currentProject?.id,
      name,
      dueDate,
      workers,
      workphase,
      reviewer,
      type,
      priority,
      explain,
      tags,
      visibility,
      discipline,
      building,
      floor,
      status,
      editMarkStates,
      selected,
      issues,
      startingDate,
      allowedUserGroups,
      mainTask,
      newSubTasks,
      deletedSubTasks,
      isTimeline,
      linkEmail,
      linkedEmails,
      components,
      viewpoints,
      loadedViewpoints,
      resetVariables,
      linkedDocumentVersionIds,
    ]
  );

  useEffect(() => {
    const effect = async () => {
      let localType = null;

      if (isTimeline) {
        if (linkEmail)
          localType = adjustedTypes.find(
            (type) => type.originalName === "Request" || type.name === "Request"
          );
        else
          localType = adjustedTypes.find(
            (type) =>
              type.originalName === "Appointment" || type.name === "Appointment"
          );
      } else {
        if (isMassEdit) localType = adjustedTypes[0];
        else
          localType = adjustedTypes.find(
            (type) => type.originalName === "Issue" || type.name === "Issue"
          );
      }
      setType(localType);

      if (mode === IssueModalMode.EDIT && editIssue) {
        const currentType = adjustedTypes.find(
          (type) => type.value === editIssue.issueType?.id
        );
        setType(currentType);

        if (editIssue.childrenIds?.length > 0)
          IssueService.getIssuesByIds(editIssue.childrenIds).then((result) => {
            if (result !== undefined) setSubTasks(result);
          });

        if (editIssue.parentId !== undefined)
          IssueService.getIssuesByIds([editIssue.parentId]).then((result) => {
            if (result?.length > 0) setMainTask(result);
          });
      }
    };
    effect();
  }, [
    isDiscardChanges,
    isTimeline,
    linkEmail,
    editIssue,
    adjustedTypes,
  ]);

  const handleDelete = (id, fileType) => {
    if (fileType === "email") {
      const filtredEmails = linkedEmails.filter((email) => email.id !== id);
      setLinkedEmails(filtredEmails);
      changeEditMarkState(
        filtredEmails.map((email) => email.id),
        "linkedEmail"
      );
    }
    if (fileType === "mainTask") {
      const filtredMainTask = mainTask.filter((issue) => issue.id !== id);
      setMainTask(filtredMainTask);
      const mainTasksDeleted = mainTask.filter((issue) => issue.id === id);
      setDeletedMainTasks((prevState) => [...prevState, ...mainTasksDeleted]);
      changeEditMarkState(
        mainTask.map((issue) => issue.id),
        "parentId"
      );
    }
    if (fileType === "subTasks") {
      const filtredSubTasks = subTasks.filter((issue) => issue.id !== id);
      setSubTasks(filtredSubTasks);
      const filtredNewSubTasks = newSubTasks.filter((issue) => issue.id !== id);
      setNewSubTasks(filtredNewSubTasks);
      const subTasksDeleted = subTasks.filter((issue) => issue.id === id);
      setDeletedSubTasks((prevState) => [...prevState, ...subTasksDeleted]);
    }
  };

  const onAddIssues = useCallback((findTasks, isParent) => {
    if (isLinkedIssues && (addIssue !== "create" || modalCount !== 2)) {
      setDocumentLinkedIssue(findTasks);
      openIssueDetails(findTasks);
    } else if (isParent) {
      setMainTask(findTasks);
      changeEditMarkState(
        findTasks.map((issue) => issue.id),
        "parentId"
      );
    } else {
      setSubTasks((prevState) => [...prevState, ...findTasks]);
      setNewSubTasks((prevState) => [...prevState, ...findTasks]);
    }
    setIsParent(false);
    setShowLinkIssues(false);
  }, []);

  const onCancelAddingIssues = useCallback(() => {
    setShowLinkIssues(false);
    setIsParent(false);
    if (currentIssue && mode === IssueModalMode.CREATE) {
      setCurrentIssue(null);
    }
  }, []);

  const selectedTasksForLinkIssuePopup = useMemo(() => {
    if (isLinkedIssues) return documentLinkedIssue.map((task) => task.id);
    else {
      if (isParent) return mainTask.map((task) => task.id);
      else return subTasks.map((task) => task.id);
    }
  }, [isLinkedIssues, documentLinkedIssue, mainTask, subTasks]);

  const issueModalProps = {
    projectId: currentProject?.id,
    users,
    categories: disciplines,
    buildings,
    workphases,
    floors,
    statuses,
    priorities,
    types,
    tagOptions,
    editIssue,
    mode: IssueModalMode.CREATE,
    selected,
    folders,
    issues,
    collaboratorRoleDefinitions,
    baseIssuesUrl,
    selectedDate,
    setEditIssue,
    previewDocument,
  };

  const { issueId: currentIssueId } = useParams();

  return (
    <div className={`issue-modal ${viewpoints ? "full-width" : ""}`}>
      {!isLinkExistingIssue && <Loader isLoading={isLoading} />}

      {showLinkIssues ? (
        <LinkIssues
          onAddIssues={onAddIssues}
          onCancelAddingIssues={onCancelAddingIssues}
          isParent={isParent}
          selectedTask={selectedTasksForLinkIssuePopup}
          issueModalProps={issueModalProps}
          editIssue={editIssue}
          currentIssue={currentIssue}
          setCurrentIssue={setCurrentIssue}
          issueModalCount={modalCount}
          setIsLinkedCreatIssue={setIsLinkedCreatIssue}
          linkedIssues={linkedIssues}
          isDocumentLinkedIssue={isDocumentLinkedIssue}
          setIsDocumentLinkedIssue={setIsDocumentLinkedIssue}
          handleClick={handleClick}
          setIsLinkExistingIssue={setIsLinkExistingIssue}
          closeModal={() =>
            isLinkExistingIssue
              ? (toggleIssueModal(IssueModalMode.HIDE),
                setIsLinkExistingIssue(false))
              : {}
          }
          isLinkedIssues={isLinkedIssues}
          allIssues={allIssues}
        />
      ) : (
        ""
      )}
      {!showLinkIssues ? (
        <div
          style={{
            display: isLinkExistingIssue ? "none" : "block",
            padding: '30px',
          }}
        >
          <h2 className="title">{title}</h2>
          {!!apiError && <p className="error-label">{apiError}</p>}
          <div className="content-wrapper">
            {!!viewpoints && (
              <IssueModalModel
                components={components}
                viewpoints={viewpoints}
                setIssueAddMode={setIssueAddMode}
                setAddComponents={setAddComponents}
                setAddViewpoints={setAddViewpoints}
                setIsModifiedIssueComponents={setIsModifiedIssueComponents}
              />
            )}
            <div>
              <IssueModalContent
                editIssue={editIssue}
                mode={mode}
                editMarkStates={editMarkStates}
                handleChange={handleChange}
                issueTypes={IssueService.getMetaDataWithoutOther(adjustedTypes)}
                handleSelectChange={handleSelectChange}
                type={type}
                name={name}
                explain={explain}
                startingDate={startingDate}
                dueDate={dueDate}
                setStartingDate={setStartingDate}
                changeEditMarkState={changeEditMarkState}
                setDueDate={setDueDate}
                statusOptions={IssueService.getMetaDataWithoutOther(statuses)}
                status={status}
                users={users}
                workers={workers}
                reviewer={reviewer}
                visibility={visibility}
                visibilityOptions={visibilityOptions}
                allowedUserGroups={allowedUserGroups}
                priorities={IssueService.getMetaDataWithoutOther(priorities)}
                priority={priority}
                workphases={workphases}
                workphase={workphase}
                disciplines={disciplines}
                discipline={discipline}
                buildings={buildings}
                building={building}
                floors={floors}
                floor={floor}
                isOverwriteTag={isOverwriteTag}
                setIsOverwriteTag={setIsOverwriteTag}
                tagOptions={tagOptions}
                tags={tags}
                apiError={apiError}
                isMassEdit={isMassEdit}
              />
              <IssueModalColumn
                editIssue={editIssue}
                mode={mode}
                stagedDocumentVersionIds={linkedDocumentVersionIds}
                setStagedDocumentVersionIds={setLinkedDocumentVersionIds}
                handleDelete={handleDelete}
                mainTask={mainTask}
                subTasks={subTasks}
                canEditIssue={canEditIssue}
                linkedEmails={linkedEmails}
                setIsParent={setIsParent}
                setShowLinkIssues={setShowLinkIssues}
                isAllowedToSave={isAllowedToSave}
              />
            </div>
          </div>
          <IssueModalButton
            handleClick={handleClick}
            baseIssuesUrl={baseIssuesUrl}
            mode={mode}
            handleSubmit={handleSubmit}
            onDiscardChanges={() => {
              resetVariables();
              setIsDiscardChanges(true);
            }}
            modalCount={modalCount}
            setModalCount={setModalCount}
            setIsLinkedCreatIssue={setIsLinkedCreatIssue}
            isMassEdit={isMassEdit}
            setCurrentIssue={setCurrentIssue}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default IssueModal;
