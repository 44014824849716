import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import EmailSettingsDto from 'emails/types/EmailSettingsDto';
import UpdateEmailSettingsDto from 'emails/types/UpdateEmailSettingsDto';
import useEmailSettingsEntityFactory from 'emails/hooks/useEmailSettingsEntityFactory';
import EmailSettings from 'emails/types/EmailSettings';

export default function useEmailSettingsUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { baseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.EmailSettings);
  const entityFactory = useEmailSettingsEntityFactory();
  return useMutation<EmailSettingsDto, unknown, UpdateEmailSettingsDto, EmailSettings>(async (dto: UpdateEmailSettingsDto) => {
    const { data } = await axiosInstance.put<EmailSettingsDto>(`${ApiEndpoint.EmailSettings}`, dto);
    return data;
  }, {
    onMutate: (dto) => {
      const previousEntity = queryClient.getQueryData<EmailSettings>(baseQueryKey);
      const optimisticEntity = entityFactory(dto);
      queryClient.setQueryData(baseQueryKey, () => optimisticEntity);
      return previousEntity;
    },
    onError: (error, dto, previousEntity: EmailSettings | undefined) => {
      queryClient.setQueryData(baseQueryKey, () => previousEntity); // roll back
    },
  });
}
