import React, { useMemo, useState } from 'react';
import IChildren from 'common/types/IChildren';
import IssuesFilterContext, { IssuesFilterContextState } from 'issues/contexts/IssuesFilterContext';
import HardcodedIssueFilter from 'issues/types/HardcodedIssueFilter';
import IssuesSortMode from 'issues/types/IssuesSortMode';
import useIssueOdataQueryFilterBuilder from 'issues/hooks/useIssueOdataQueryFilterBuilder';

export type DateInterval = { start: Date; end: Date };
export default function IssuesFilterContextProvider({
  children,
}: IChildren) {
  const { buildIssueOdataFilterQuery } = useIssueOdataQueryFilterBuilder();
  const [documentId, setDocumentId] = useState<string | undefined>(undefined);
  const [quickFilter, setQuickFilter] = useState<string>('');
  const [hardcodedFilter, setHardcodedFilter] = useState<HardcodedIssueFilter>(HardcodedIssueFilter.AllActiveIssues);
  const [sortMode, setSortMode] = useState<IssuesSortMode>(IssuesSortMode.EditDateDesc);
  const [dueDateInterval, setDueDateInterval] = useState<DateInterval | undefined>(undefined);
  const odataQuery = useMemo(() => {
    const filter = buildIssueOdataFilterQuery(hardcodedFilter, quickFilter, documentId, dueDateInterval);
    if (!filter) return undefined;
    const orderBy = sortMode as string;
    return { filter, orderBy };
  }, [buildIssueOdataFilterQuery, dueDateInterval, documentId, hardcodedFilter, quickFilter, sortMode]);

  const value = useMemo<IssuesFilterContextState>(() => ({
    documentId,
    setDocumentId,
    quickFilter,
    setQuickFilter,
    hardcodedFilter,
    setHardcodedFilter,
    sortMode,
    setSortMode,
    dateInterval: dueDateInterval,
    setDateInterval: setDueDateInterval,
    odataQuery,
  }), [documentId, quickFilter, hardcodedFilter, sortMode, dueDateInterval, odataQuery]);
  return <IssuesFilterContext.Provider value={value}>{children}</IssuesFilterContext.Provider>;
}
