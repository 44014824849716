import React, { useCallback, useState } from 'react';
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useDocumentListQuery from 'documents-lists/hooks/useDocumentListQuery';
import useDocumentListDeleteMutation from 'documents-lists/hooks/useDocumentListDeleteMutation';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import { useNavigate } from 'react-router-dom';

interface DeletePlanlistDialogProps {
  planlistId: string,
  onClose: () => void,
}

export default function DeletePlanlistDialog({
  planlistId,
  onClose,
}: DeletePlanlistDialogProps) {
  const { t } = useTranslation('documents-lists');
  const navigate = useNavigate();
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { data: planlist } = useDocumentListQuery(planlistId);
  const getRequestErrorMessage = useRequestErrorMessage();
  const { mutateAsync, isLoading: isLoadingDelete } = useDocumentListDeleteMutation();
  const [deletedPlanlistName, setDeletedPlanlistName] = useState<undefined | string>();
  const onConfirm = useCallback(async () => {
    if (!planlistId || !planlist) return;
    setErrorMessage(undefined);
    try {
      await mutateAsync(planlistId);
      setDeletedPlanlistName(planlist.name);
      setSuccessDialogOpen(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [planlistId, planlist, mutateAsync, getRequestErrorMessage]);
  const onCloseSuccessDialog = useCallback(() => {
    navigate('../planlist');
    onClose();
  }, [navigate, onClose]);
  return (
    <Dialog id="DeletePlanlistDialog" open PaperProps={{ sx: { minWidth: '480px' } }}>
      <DialogTitle>{t('delete-planlist-dialog_title', 'Delete Plan List')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Alert severity="warning">{t('delete-planlist-dialog_message', 'Are you sure that you want to delete the plan list {{planlist}}?', { planlist: planlist?.name })}</Alert>
        {!!errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose} sx={{ mr: 'auto' }}>
          {t('delete-planlist-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm} disabled={isLoadingDelete || !planlist}>
          {t('delete-planlist-dialog_confirm-button-label', 'Delete Planlist')}
          {isLoadingDelete && <CircularProgress size={12} sx={{ ml: 1 }} />}
        </Button>
      </DialogActions>
      {!!successDialogOpen && (
        <Dialog id="DeletePlanlistSuccessDialog" open PaperProps={{ sx: { minWidth: '480px' } }}>
          <DialogTitle>{t('delete-planlist-success-dialog_title', 'Plan List Deleted')}</DialogTitle>
          <DialogContent>
            <Alert severity="success">{t('delete-planlist-success-dialog_message', 'The plan list "{{planlist}}" has been deleted.', { planlist: deletedPlanlistName })}</Alert>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={onCloseSuccessDialog}>
              {t('delete-planlist-success-dialog_close-button-label', 'Close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
}
