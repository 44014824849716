import React, {
  MouseEvent, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import FolderTreeGridRow, { RowType } from 'documents-folders/types/FolderTreeGridRow';
import { useTranslation } from 'react-i18next';
import {
  Box, Button, Typography, useTheme,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DocumentDragAndDropContext, { DocumentDragAndDropContextState } from 'documents/contexts/DocumentDragAndDropContext';
import useFolder from 'documents-folders/hooks/useFolder';
import Color from 'color';
import useFolderIconName from 'documents-folders/hooks/useFolderIconName';
import useCurrentUserFolderAccessLevel from 'documents-folders/hooks/useCurrentUserFolderAccessLevel';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';

export default function FolderTreeCellRenderer({
  data,
  node,
}: ICellRendererParams<FolderTreeGridRow, string | undefined>) {
  const { draggedOverFolderId } = useContext<DocumentDragAndDropContextState>(DocumentDragAndDropContext);
  const { t } = useTranslation('documents-folders');
  const theme = useTheme();
  const [, setForceRerenderFlip] = useState<boolean>(false);
  const folder = useFolder(data?.rowType === RowType.FolderRow ? data?.id : undefined);

  // AG Grid's way of passing data in here seems to not work well with react.
  // In short, this component does not re-render when node.expanded changes, and
  // its initial state cannot be trusted. That's why we subscribe to the expandedChanged external event
  // and then force re-rendering by calling setForceRerenderFlip. Not beautiful, but a required workaround.

  const forceRerender = useCallback(() => {
    setForceRerenderFlip((prev) => !prev);
  }, []);

  useEffect(() => {
    node.addEventListener('expandedChanged', forceRerender);
    return () => node.removeEventListener('expandedChanged', forceRerender);
  }, [forceRerender, node]);

  useEffect(() => {
    node.addEventListener('hasChildrenChanged', forceRerender);
    return () => node.removeEventListener('hasChildrenChanged', forceRerender);
  }, [forceRerender, node]);

  const label = useMemo(() => (folder ? folder.name : data?.rowType === RowType.AccessTypeRow ? t(data.nameTranslationKey, data.nameTranslationKey) : ''), [folder, data, t]);
  const ExpandButtonIcon = node.isExpandable() ? (node.expanded ? ArrowDropDownIcon : ArrowRightIcon) : undefined;
  const indent = data?.rowType === RowType.FolderRow ? (node.level - 1) : 0;
  const countText = useMemo(() => {
    if (!folder) return undefined;
    if (!folder.documentCount && !folder.childFolderIds?.length) return t('empty', 'Empty');
    const counts: string[] = [];
    if (folder.documentCount) counts.push(`${t('n_documents', '{{count}} Documents', { count: folder.documentCount })}`);
    if (folder.childFolderIds?.length) counts.push(`${t('n_subfolders', '{{count}} Subfolders', { count: folder.childFolderIds.length })}`);
    return counts.join(' | ');
  }, [folder, t]);
  const isFolderCurrentlyDraggedOver = useMemo(() => data?.rowType === RowType.FolderRow && draggedOverFolderId === node.id, [data?.rowType, draggedOverFolderId, node.id]);
  const folderIcon = useFolderIconName(folder?.accessType);
  const onClickExpandButton = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    node.setExpanded(!node.expanded);
    event.preventDefault();
  }, [node]);
  const accessLevel = useCurrentUserFolderAccessLevel(folder?.id);
  const backgroundColor = useMemo(() => {
    if (!isFolderCurrentlyDraggedOver) return 'unset';
    if (accessLevel === CollaboratorFolderAccessLevel.ReadWrite) return Color(theme.palette.secondary.light).lightness(98).hex();
    return Color(theme.palette.error.light).lightness(98).hex();
  }, [accessLevel, isFolderCurrentlyDraggedOver, theme.palette.error.light, theme.palette.secondary.light]);
  return (
    <Box
      id="FolderTreeCellRenderer"
      sx={{
        display: 'flex', pt: '7px', height: '100%', ml: indent * 4, backgroundColor,
      }}
      title={folder?.name}
    >
      <Box sx={{ width: 24, flexShrink: 0 }}>
        {!!ExpandButtonIcon && (
        <Button
          sx={{
            minWidth: 'revert',
            width: '16px',
            height: '16px',
            borderRadius: '8px',
            ml: '4px',
            mt: '8px',
            p: 0,
            background: 'transparent',
          }}
          variant="contained"
          color="secondary"
          onClick={onClickExpandButton}
        >
          <ExpandButtonIcon sx={{ width: 20, height: 20, color: data?.rowType === RowType.AccessTypeRow ? theme.palette.text.primary : theme.palette.text.secondary }} />
        </Button>
        )}
      </Box>
      {data?.rowType === RowType.FolderRow
      && (
      <>
        <Box sx={{
          width: '32px', height: '32px', flexShrink: 0, ml: 0.5,
        }}
        >
          {folderIcon && (
          <svg viewBox="0 -6 32 32">
            <use xlinkHref={`/img/sprite.svg#${folderIcon}`} />
          </svg>
          )}
        </Box>
        <Box sx={{ display: 'inline-grid', gridTemplateRows: 'auto 1fr', ml: 0.5 }}>
          <Typography
            title={folder?.name}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              fontWeight: 600,
              color: !!folder && !folder.hasFolderAccess ? theme.palette.text.disabled : undefined,
            }}
          >
            {folder?.name}
          </Typography>
          {folder?.hasFolderAccess && (
            <Typography
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontSize: 12,
                color: theme.palette.text.secondary,
              }}
            >
              {countText}
            </Typography>
          )}
          {!!folder && !folder.hasFolderAccess && (
          <Typography sx={{
            fontSize: 12, color: theme.palette.text.disabled, mt: '2px', fontStyle: 'italic',
          }}
          >
            {t('folder-tree-cell-renderer_no-access', 'No Access')}
          </Typography>
          )}
        </Box>
      </>
      )}
      {data?.rowType === RowType.AccessTypeRow && <Typography sx={{ fontWeight: 700, mt: 1 }}>{label}</Typography>}
    </Box>
  );
}
