import React, { useCallback, useRef, useState } from 'react';
import { Button, Menu, MenuItem, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';
import useDocumentListQuery from 'documents-lists/hooks/useDocumentListQuery';
import useCurrentPlanlistId from 'documents-lists/hooks/useCurrentPlanlistId';
import DeletePlanlistDialog from 'documents-lists/components/DeletePlanlistDialog';
import ClearPlanlistDialog from 'documents-lists/components/ClearPlanlistDialog';

interface DeletePlanlistMenuButtonProps extends ISxProps {
}

export default function DeletePlanlistMenuButton({
  sx,
}: DeletePlanlistMenuButtonProps) {
  const { t } = useTranslation('documents-lists');
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const onCloseMenu = useCallback(() => setMenuOpen(false), []);
  const currentPlanlistId = useCurrentPlanlistId();
  const { data: planlist } = useDocumentListQuery(currentPlanlistId);
  const [deletePlanlistDialogOpen, setDeletePlanlistDialogOpen] = useState(false);
  const onClickDeletePlanlist = useCallback(() => {
    setDeletePlanlistDialogOpen(true);
    setMenuOpen(false);
  }, []);
  const onCloseDeletePlanlistDialog = useCallback(() => setDeletePlanlistDialogOpen(false), []);
  const [clearPlanlistDialogOpen, setClearPlanlistDialogOpen] = useState(false);
  const onClickClearPlanlist = useCallback(() => {
    setClearPlanlistDialogOpen(true);
    setMenuOpen(false);
  }, []);
  const onCloseClearPlanlistDialog = useCallback(() => setClearPlanlistDialogOpen(false), []);
  return (
    <>
      <Tooltip title={t('delete-planlist-menu-button_tooltip', 'Delete or Clear Plan List')}>
        <span>
          <Button
            onClick={() => setMenuOpen(true)}
            ref={buttonRef}
            sx={{ ...sx, minWidth: 'unset', px: 1 }}
            variant="contained"
            id="DeletePlanlistMenuButton"
          >
            <DeleteIcon />
          </Button>
        </span>
      </Tooltip>
      <Menu
        anchorEl={buttonRef.current}
        open={menuOpen}
        onClose={onCloseMenu}
      >
        <MenuItem onClick={onClickDeletePlanlist}>
          {t('delete-planlist-menu-button_delete-planlist-menu-item', 'Delete Plan List "{{planlist}}"', { planlist: planlist?.name ?? '' })}
        </MenuItem>
        <MenuItem onClick={onClickClearPlanlist}>
          {t('delete-planlist-menu-button_clear_planlist-menu-item', 'Clear Plan List "{{planlist}}"', { planlist: planlist?.name ?? '' })}
        </MenuItem>
      </Menu>
      {!!deletePlanlistDialogOpen && currentPlanlistId && (
        <DeletePlanlistDialog planlistId={currentPlanlistId} onClose={onCloseDeletePlanlistDialog} />
      )}
      {!!clearPlanlistDialogOpen && currentPlanlistId && (
        <ClearPlanlistDialog planlistId={currentPlanlistId} onClose={onCloseClearPlanlistDialog} />
      )}
    </>
  );
}
