enum DataType {
  Project,
  Issue,
  Comment,
  Model,
  Document,
  Activity,
  DocumentVersion,
  BuildingMetaData,
  DisciplineMetaData,
  FloorMetaData,
  IssueLog,
  FolderTree,
  Folder,
  Process,
  Tag,
  DocumentList,
  IssueType,
  IssueStatus,
  IssuePriority,
  InboxEmail,
  DynamicLayout,
  CollaboratorRoleDefinition,
  PropertyListEntry,
  SmartView,
  DocumentComment,
  Filter,
  UserGroup,
}

export default DataType;
