import React, { useCallback, useState } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import CollaboratorsDataGridRow from 'collaborators/types/CollaboratorsDataGridRow';
import CollaboratorRoleEditSelect from 'collaborators/components/CollaboratorRoleEditSelect';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import {
  Alert, CircularProgress, Portal, Snackbar,
} from '@mui/material';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import useCollaboratorRoleMapUpdateMutation from 'collaborators/hooks/useCollaboratorRoleMapUpdateMutation';
import UpdateCollaboratorRoleMapDto from 'collaborators/types/UpdateCollaboratorRoleMapDto';

export default function RoleCell({
  data,
}: ICellRendererParams<CollaboratorsDataGridRow, string>) {
  const [roleIdDisplayValue, setRoleIdDisplayValue] = useState(data?.role?.id);
  const { data: currentProject } = useCurrentProjectQuery();
  const currentUserRole = useCurrentUserRole();
  const { mutateAsync, isLoading: isLoadingMutation } = useCollaboratorRoleMapUpdateMutation();
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onChangeSelect = useCallback(async (nextId: string) => {
    if (!currentProject || !roleIdDisplayValue || !data) return;
    setRoleIdDisplayValue(nextId);
    const persistDto: UpdateCollaboratorRoleMapDto = {
      collaboratorRoles: currentProject.collaboratorRoles.map((m) => (m.collaboratorId === data.id ? { ...m, roleDefinitionId: nextId } : m)),
    };
    try {
      await mutateAsync(persistDto);
    } catch (error) {
      setRoleIdDisplayValue(data?.role?.id);
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [currentProject, data, getRequestErrorMessage, mutateAsync, roleIdDisplayValue]);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  if (!currentUserRole?.allowedActions?.has(RoleAction.Project_EditMembers) || !currentUserRole?.isAdmin) return data?.role?.name;
  return (
    <>
      <CollaboratorRoleEditSelect value={roleIdDisplayValue} onChange={onChangeSelect} disabled={isLoadingMutation} />
      {isLoadingMutation && <CircularProgress size={12} sx={{ ml: 1 }} />}
      <Portal>
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={onCloseErrorMessage}
        >
          <Alert onClose={onCloseErrorMessage} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
}
