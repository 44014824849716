import useToday from 'common/hooks/useToday';
import { closedIssueStatusFilterPredicate } from 'issues/hooks/useClosedIssueStatus';
import { resolvedIssueStatusFilterPredicate } from 'issues/hooks/useResolvedIssueStatus';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import { useMemo } from 'react';

export default function useIssueOverdueCheck(issueId: string | undefined) {
  const { data: issue } = useIssueQuery(issueId);
  const today = useToday();
  const isCurrent = useMemo(() => !!issue?.issueStatus && !closedIssueStatusFilterPredicate(issue.issueStatus) && !resolvedIssueStatusFilterPredicate(issue.issueStatus), [issue]);
  const dueDay = useMemo(() => {
    if (!issue?.dueDate) return undefined;
    const parsedDueDate = new Date(issue.dueDate);
    return new Date(parsedDueDate.getFullYear(), parsedDueDate.getMonth(), parsedDueDate.getDate());
  }, [issue]);
  return useMemo(() => {
    if (!dueDay || !today) return undefined;
    return isCurrent && dueDay < today;
  }, [dueDay, isCurrent, today]);
}
