import useFolderTreeQuery from 'documents-folders/hooks/useFolderTreeQuery';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';
import FolderAccessType from 'documents-folders/types/FolderAccessType';
import { useMemo } from 'react';
import useUserGroupsQuery from 'users-groups/hooks/useUserGroupsQuery';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';

export default function useCurrentUserFolderAccessLevel(folderId: string | undefined) {
  const { data: folderTreeData } = useFolderTreeQuery();
  const { data: userGroups } = useUserGroupsQuery();
  const { data: currentUser } = useCurrentUserQuery();

  const currentUserUserGroupIds = useMemo(() => {
    if (!userGroups || !currentUser) return undefined;
    return userGroups?.filter((g) => g.userIds.some((id) => id === currentUser.id)).map((g) => g.id);
  }, [currentUser, userGroups]);

  return useMemo(() => {
    if (!folderId || !folderTreeData) return undefined;
    const folder = folderTreeData.foldersById.get(folderId);
    if (!folder?.hasFolderAccess) return CollaboratorFolderAccessLevel.None;
    if (!folder || !userGroups || !currentUser) return undefined;
    const readOnlyGroupIdsSet = new Set<string>(folder.readOnlyGroupsIds);
    const readOnlyCollaboratorIdsSet = new Set<string>(folder.readOnlyUserIds);
    const addedGroupIdsSet = new Set<string>(folder.accessGroupsIds);
    const addedCollaboratorIdsSet = new Set<string>(folder.accessUserIds);
    if (folder.accessType === FolderAccessType.Private) {
      const isCreator = currentUser.id === folder.creatorId;
      return isCreator ? CollaboratorFolderAccessLevel.ReadWrite : CollaboratorFolderAccessLevel.None;
    }
    if (folder.accessType === FolderAccessType.Public) {
      if (!readOnlyCollaboratorIdsSet || !readOnlyGroupIdsSet || !currentUserUserGroupIds) return undefined;
      const readOnly = readOnlyCollaboratorIdsSet.has(currentUser.id) || currentUserUserGroupIds.some((id) => readOnlyGroupIdsSet.has(id));
      return readOnly ? CollaboratorFolderAccessLevel.Read : CollaboratorFolderAccessLevel.ReadWrite;
    }
    if (folder.accessType === FolderAccessType.Restricted) {
      if (!addedCollaboratorIdsSet || !addedGroupIdsSet || !readOnlyCollaboratorIdsSet || !readOnlyGroupIdsSet || !currentUserUserGroupIds) return undefined;
      const hasUserWriteRight = addedCollaboratorIdsSet.has(currentUser.id) && !readOnlyCollaboratorIdsSet.has(currentUser.id);
      if (hasUserWriteRight) return CollaboratorFolderAccessLevel.ReadWrite;
      const hasGroupWriteRight = currentUserUserGroupIds.some((groupId) => addedGroupIdsSet.has(groupId) && !readOnlyGroupIdsSet.has(groupId));
      if (hasGroupWriteRight) return CollaboratorFolderAccessLevel.ReadWrite;
      const hasAccess = addedCollaboratorIdsSet.has(currentUser.id) || currentUserUserGroupIds.some((id) => addedGroupIdsSet.has(id));
      if (hasAccess) return CollaboratorFolderAccessLevel.Read;
      return CollaboratorFolderAccessLevel.None;
    }
    return undefined;
  }, [currentUser, currentUserUserGroupIds, folderId, folderTreeData, userGroups]);
}
