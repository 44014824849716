import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BubbleState } from "./Types/viso-types";
import { IfcViewerAPI } from "./Types/ifc-viewer-api";
import { Color, Vector3 } from "three";
import statsmin from "stats.js";
import { useSelector } from "react-redux";
import useApiConfig from "api/hooks/useApiConfig";
import ViewerContextMenu from "./Viewer/ViewerContextMenu";
import ViewerToolbar from "./Viewer/ViewerToolbar";
import ClippingPanel from "./Viewer/ClippingPanel";
import MeasurePanel from "./Viewer/MeasurePanel";
import PlanPanel from "./Viewer/PlanPanel";
import ViewpointSettingPanel from "./Viewer/ViewpointSettingPanel";
import { getValuesAddedAll } from "../../Services/IssueService";
import { getViewpointLines } from "../../Services/ViewpointService";
import AddingComponentPanel from "./Viewer/AddingComponentPanel";
import ViewpointDetailPanel from "./Viewer/ViewpointDetailPanel";
import {
  MODE_ADD_COMPONENT,
  MODE_ADD_NONE,
  MODE_ADD_VIEWPOINT,
  VIEWER_MARGIN,
  VIEWER_OFFSET_X,
  VIEWER_OFFSET_Y,
} from "../../Helpers/Common";
import { ModelService } from "../../Services";
import ComponentTooltip from "./Viewer/ComponentTooltip";
import VisualStateModal from "./Viewer/VisualStateModal";
import useCurrentProjectQuery from "projects/hooks/useCurrentProjectQuery";
import useVisoplanApiContext from "api/hooks/useVisoplanApiContext";

const initialMetaData = {
  disciplines: [],
  buildings: [],
  floors: [],
};

const IFCViewer = ({
  issues,
  issueFilterItems,
  selectedModels,
  handleLoading,
  settings,
  viewpoint,
  registerStoreys,
  floorMapSetting,
  onShowComponentDetails,
  selectedComponentInfo,
  setSelectedComponentInfo,
  historyNode,
  setIsModifiedIssueComponents,
  setAddIssueComponents,
  setAddIssueViewpoints,
  issueAddMode,
  setIssueAddMode, //MODE_ADD_NONE, MODE_ADD_COMPONENT, MODE_ADD_VIEWPOINT
  linkedComponentsGlobalIds,
  setIsIssueModal,
  isIssueModal,
  resetViewpoint,
  setBubbleIssue,
  issueComponents,
  selectedIssue,
  isCreatingIssue,
  storeyModelIds,
}) => {
  const { t } = useTranslation();
  const { authUserToken, authProjectToken } = useVisoplanApiContext();
  const token = useMemo(
    () => authProjectToken ?? authUserToken,
    [authProjectToken, authUserToken]
  );
  const viewerRef = useRef();
  const [hoveredComponent, setHoveredComponent] = useState();
  const [isTransparent, setIsTransparent] = useState(true);
  const [menuPos, setMenuPos] = useState({ x: 0, y: 0 });
  const [isMenuvisible, setIsMenuVisible] = useState(false);
  const [isClipping, setIsClipping] = useState(false);
  const [sections, setSections] = useState([]);
  const [section, setSection] = useState();
  const [isMeasure, setIsMeasure] = useState(false);
  const [is2D, setIs2D] = useState(false);
  const [measurements, setMeasurements] = useState([]);
  const [measurement, setMeasurement] = useState();
  const [isMeasuring, setIsMeasuring] = useState(false);
  const [rect, setRect] = useState();
  const [storeys, setStoreys] = useState([]);
  const [cameraInfo, setCameraInfo] = useState();
  const [isViewMark, setIsViewMark] = useState(false);
  const [visibleMark, setVisibleMark] = useState(true);
  const { data: currentProject } = useCurrentProjectQuery();
  const { statuses, types, priorities, users, categories, buildings, floors } =
    issueFilterItems;
  const [filterItem, setFilterItem] = useState({
    status: null,
    type: null,
    assigner: null,
    priority: null,
    discipline: null,
    building: null,
    floor: null,
  });
  const [addedComponents, setAddedComponents] = useState([]);
  const [colorIds, setColorIds] = useState([]);
  const [bubbleState, setBubbleState] = useState(BubbleState.BUBBLE_NONE);
  const [metaData, setMetaData] = useState(initialMetaData);
  const [tooltip, setTooltip] = useState({
    visible: false,
    xPos: 0,
    yPos: 0,
    component: undefined,
  });
  const menuRef = useRef(isMenuvisible);
  const measureRef = useRef(isMeasuring);
  const measurementRef = useRef(measurements);
  const markRef = useRef(visibleMark);
  const addModeRef = useRef(issueAddMode);
  const sectionRef = useRef(section);
  const sectionsRef = useRef(sections);
  const bubbleStateRef = useRef(bubbleState);
  const metaDataRef = useRef(metaData);
  const hoveredComRef = useRef(hoveredComponent);
  const [isOpenVSetting, setIsOpenVSetting] = useState(false);
  const [viewerState, setViewerState] = useState({
    isKeptChange: false,
    isDontShow: false,
  });

  const { apiUrl } = useApiConfig();

  useEffect(() => {
    // Initialize WebViewer
    const container = document.getElementById("viewer-container");
    let viewer = viewerRef.current;
    if (!viewer) {
      viewer = new IfcViewerAPI({ container }, apiUrl);
      viewerRef.current = viewer;
    }
    viewer.addStats(
      "position:fixed;top:58px;right:3px;z-index:1;",
      new statsmin()
    );
    viewer.addNavCube(
      "position:absolute;width:150px;height:150px;top:80px;right:35px;z-index:2;"
    );
    viewer.enableNavCube(true);
    setRect(container.getBoundingClientRect());

    // Add Events
    document.addEventListener("keydown", onKeyDown);
    window.addEventListener("resize", onResize);

    let timeout = 0;
    // Pre-select Element
    container.onmousemove = async (e) => {
      clearTimeout(timeout);
      setTooltip({ visible: false });

      if (
        measureRef.current ||
        bubbleStateRef.current === BubbleState.BUBBLE_CREATING
      )
        return;

      timeout = setTimeout(() => {
        handleComponentTooltip(e);
      }, 1000);

      if (markRef.current) {
        viewer.IFC.bubble.pickBubble();
      }

      await viewer.IFC.selector.prePickIfcItem();
    };

    // Select Element
    container.onclick = async (e) => {
      if (!viewer.isModelLoaded() || menuRef.current) return;

      if (e.button === 0) {
        if (measureRef.current) {
          viewer.dimensions.create();
        } else if (bubbleStateRef.current === BubbleState.BUBBLE_CREATING) {
          setBubbleState(BubbleState.BUBBLE_CREATED);
        } else {
          if (addModeRef.current === MODE_ADD_COMPONENT) {
            await viewer.IFC.selector.pickIfcItem(true);
          } else {
            await viewer.IFC.selector.pickIfcItem(e.ctrlKey);
          }
        }
      }
    };

    // Select and Focus Element
    container.ondblclick = async (e) => {
      if (menuRef.current || measureRef.current) return;

      if (e.button === 0) {
        await viewer.IFC.selector.pickIfcItem(false, true);
      }
    };

    // Event Listener From Viewer
    viewer.IFC.selector.onHover.on((preSelected) => {
      setHoveredComponent(preSelected);
    });

    viewer.IFC.selector.onSelect.on((data) => {
      showHistoryNode(null);
      if (addModeRef.current === MODE_ADD_COMPONENT) {
        setAddedComponents(data.components);
      } else {
        const globalIds = data.components.map((c) => c.globalId);
        setSelectedComponentInfo({
          gIds: [...globalIds],
          mIds: data.modelFileIds ? [...data.modelFileIds] : [],
          components: data.components,
        });
        setIsTransparent(data.isTrans);
      }
    });

    viewer.context.ifcCamera.onChange.on((data) => {
      setCameraInfo(data);
    });

    viewer.dimensions.onClosed.on((data) => {
      setDimension(data);
    });

    viewer.clipper.onClip.on((data) => {
      const items = data.map((s, key) => {
        return {
          value: key,
          label: `${t("section", "Section")} ${key + 1}`,
          data: s,
        };
      });

      setSections(items);
      setSection(items[items.length - 1]);

      viewer.updateSectionSpaces();
    });

    viewer.IFC.selector.onColorSelect.on((data) => {
      setColorIds(data);
    });

    viewer.IFC.bubble.onBubbleClick.on((issue) => {
      setBubbleIssue(issue);
    });

    return () => {
      viewer.IFC.cancelRequest();
      viewer.dispose();
      viewer.IFC.closeManager();
      viewer.dimensions.deleteAll();
      viewer.removeStats();

      document.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("resize", onResize);
    };

    function onKeyDown(e) {
      // Toggle Stats
      if (e.key === "F9") {
        viewer.toggleStats();
      } else if (e.key === "Escape") {
        if (measureRef.current) {
          setDimension(viewer.dimensions.drawEnd());
        }
      } else if (e.key === "p") {
        if (!isIssueModal || (isIssueModal && issueAddMode != MODE_ADD_NONE)) {
          viewer.IFC.visoLoader.ifcManager.subsets.toggleParentSelection();
        }
      }
    }

    function onResize() {
      setRect(container.getBoundingClientRect());
    }
  }, []);

  useEffect(() => {
    menuRef.current = isMenuvisible;
    measureRef.current = isMeasuring;
    measurementRef.current = measurements;
    markRef.current = visibleMark;
    addModeRef.current = issueAddMode;
    sectionRef.current = section;
    sectionsRef.current = sections;
    metaDataRef.current = metaData;
    hoveredComRef.current = hoveredComponent;
  });

  useEffect(() => {
    const viewer = viewerRef.current;

    if (viewer) {
      if (viewer.isModelLoaded() && !viewerState.isDontShow) {
        setIsOpenVSetting(true);
        return;
      }

      loadModelFiles();
    }
  }, [selectedModels]);

  useEffect(() => {
    loadModelFiles();
  }, [viewerState]);

  useEffect(() => {
    const viewer = viewerRef.current;
    if (viewer) {
      viewer.setViewerSetting(settings);
    }
  }, [settings]);

  useEffect(() => {
    const viewer = viewerRef.current;
    if (viewer) {
      viewer.dimensions.active = isMeasure;
      if (!isMeasure) {
        viewer.dimensions.drawEnd();
      }
    }
  }, [isMeasure]);

  useEffect(() => {
    const viewer = viewerRef.current;
    if (viewer) {
      viewer.dimensions.previewActive = isMeasuring;
    }
  }, [isMeasuring]);

  useEffect(() => {
    viewerRef.current.dimensions.selectDimension(measurement?.label);
  }, [measurement]);

  useEffect(() => {
    if (
      !!viewerRef.current &&
      viewerRef.current.isModelLoaded() &&
      !!viewpoint
    ) {
      viewerRef.current.viewpoint.showViewpoint(getVisoViewpoint(viewpoint));
      resetViewpoint();
    }

    function getVisoViewpoint(vp) {
      let viso_vp = {};
      viso_vp.camera = getCameraInfo(vp);
      if (vp.sections.length) {
        viso_vp.sections = getSections(vp.sections);
      }

      if (vp.visualStates) {
        viso_vp.visualState = getVisualState(vp);
      }

      return viso_vp;
    }

    function getCameraInfo() {
      return {
        isPerspective: viewpoint.cameraType === 0,
        location: getThreeVector(viewpoint.location),
        direction: getThreeVector(viewpoint.direction),
        up: getThreeVector(viewpoint.up),
        factor: viewpoint.cameraFactor,
      };
    }

    function getSections(sectionData) {
      return sectionData.map((s) => {
        let plane = {};
        plane.normal = getThreeVector(s.normal);
        plane.location = getThreeVector(s.location);

        return plane;
      });
    }

    function getVisualState(vp) {
      let visualState = { visibility: vp.visibility };
      const visual = vp.visualStates;

      if (visual.selectedGlobalIds.length) {
        visualState.selectedIDs = visual.selectedGlobalIds;
      }

      if (visual.transparentGlobalIds.length) {
        visualState.transparentIDs = visual.transparentGlobalIds;
      }

      if (visual.visbilityExceptionGlobalIds.length) {
        visualState.exceptedIDs = visual.visbilityExceptionGlobalIds;
      }

      if (visual.colorGroups.length) {
        visualState.colorGroups = visual.colorGroups.map((g) => {
          return { color: g.color, componentIDs: g.componentGlobalIds };
        });
      }

      return visualState;
    }

    function getThreeVector(vector) {
      return { x: vector.x, y: vector.z, z: -vector.y };
    }
  }, [viewpoint]);

  useEffect(() => {
    if (viewerRef.current) {
      viewerRef.current.clipper.active = isClipping;
      if (isClipping) {
        sectionsRef.current?.forEach((s) => {
          s.data.visible = sectionRef.current?.value === s.value;
        });
      }
    }
  }, [isClipping]);

  useEffect(() => {
    registerStoreys(storeys);
  }, [storeys]);

  useEffect(() => {
    if (floorMapSetting.visible) {
      const storey = floorMapSetting.storey?.value;
      if (storey) {
        viewerRef.current?.storey.setStorey(storey, token);
      }
    } else {
      viewerRef.current?.storey.reset();
    }
  }, [floorMapSetting]);

  useEffect(() => {
    showHistoryNode(historyNode);
  }, [historyNode]);

  useEffect(() => {
    if (visibleMark) {
      setViewpointBubble();
    }
  }, [filterItem]);

  useEffect(() => {
    if (!viewerRef.current?.isModelLoaded()) return;

    if (visibleMark) {
      setViewpointBubble();
    } else {
      viewerRef.current?.IFC.bubble.showBubble([]);
    }
  }, [visibleMark]);

  useEffect(() => {
    //when clicking "Add Component" and "Add Viewpoint" buttons, if the model isn't loaded, not hide issue creation modal
    if (issueAddMode !== MODE_ADD_NONE && !viewerRef.current?.isModelLoaded()) {
      setIssueAddMode(MODE_ADD_NONE);
      return;
    }

    viewerRef.current?.IFC.selector.unpickIfcItems();

    if (issueAddMode === MODE_ADD_VIEWPOINT) {
      viewerRef.current?.IFC.hideBubble();
    } else if (issueAddMode === MODE_ADD_COMPONENT) {
      viewerRef.current?.IFC.hideBubble();
      viewerRef.current?.IFC.selector.pickComponents(issueComponents);
    } else {
      viewerRef.current?.IFC.bubble.restoreBubble();
    }
  }, [issueAddMode]);

  useEffect(() => {
    let valueToSetIssue = [];
    if (linkedComponentsGlobalIds) {
      valueToSetIssue = viewerRef.current?.IFC.getComponentInfos(
        linkedComponentsGlobalIds
      );
    }
    setAddIssueComponents(valueToSetIssue);
  }, [linkedComponentsGlobalIds]);

  useEffect(() => {
    bubbleStateRef.current = bubbleState;
    viewerRef.current?.IFC.bubble.setBubbleState(bubbleState);
  }, [bubbleState]);

  useEffect(() => {
    // disable navigation while the issue modal is displayed
    // (otherwise typing "A" in any text field will move the camera if it's in WASD mode)
    const isNavigationEnabled =
      !isIssueModal || (isIssueModal && issueAddMode != MODE_ADD_NONE);
    viewerRef.current?.IFC.context.ifcCamera.currentNavMode.toggle(
      isNavigationEnabled
    );
  }, [isIssueModal, issueAddMode]);

  const getColorInfo = (visoColor) => {
    let colorName = visoColor.replace("#", "").toLowerCase();
    let opacity = undefined;
    if (colorName.length > 6) {
      opacity = parseInt(colorName.substr(6, 2), 16) / 255.0;
      colorName = colorName.substr(0, 6);
    }
    const color = new Color(`#${colorName}`);

    return { color, opacity };
  };

  useEffect(() => {
    selectLinkedComponents();
    setViewpointBubble();
  }, [selectedIssue]);

  const selectLinkedComponents = () => {
    if (selectedIssue) {
      const componentInfos = viewerRef.current?.IFC.getComponentInfos(
        selectedIssue.linkedComponentsGlobalIds
      );
      viewerRef.current?.IFC.selector.unpickIfcItems();
      viewerRef.current?.IFC.selector.pickComponents(componentInfos);
    }
  };

  const setViewpointBubble = () => {
    let bubbles = [];
    getViewpointLines(currentProject.id, getIssueQuery())
      .then((lines) => {
        lines.forEach((line) => {
          line.lines.forEach((l) => {
            if (isBubble(l)) {
              const status = statuses.find((s) => s.id === line.issueStatusId);
              const { color } = getColorInfo(status.color);
              const issue = issues.find((i) => i.id === line.issueId);

              bubbles.push({
                title: line.issueTitle,
                issueId: line.issueId,
                color: color,
                position: new Vector3(l.start.x, l.start.z, -l.start.y),
                issue,
              });
            }
          });
        });

        if (selectedIssue) {
          bubbles = bubbles.filter((b) => b.issueId === selectedIssue.id);
        }

        viewerRef.current?.IFC.bubble.showBubble(bubbles);
      })
      .catch((err) => {
        console.log(err.message);
      });

    function isBubble(line) {
      const { start, end } = line;
      return start.x === end.x && start.y === end.y && start.z === end.z;
    }
  };

  const getIssueQuery = () => {
    let query = [];
    const keys = Object.keys(filterItem).filter((key) => filterItem[key]);
    if (keys) {
      query = keys.map((key) => {
        let value = undefined;
        switch (key) {
          case "status":
            value = `statusId eq '${filterItem[key]}'`;
            break;
          case "type":
            value = `typeId eq '${filterItem[key]}'`;
            break;
          case "priority":
            value = `priorityId eq '${filterItem[key]}'`;
            break;
          case "assigner":
            value = `assignerUserIds/any/(user: user eq '${filterItem[key]}')`;
            break;
          case "discipline":
            value = `disciplineMetaDataIds/any/(d: d eq '${filterItem[key]}')`;
            break;
          case "building":
            value = `buildingMetaDataIds/any/(b: b eq '${filterItem[key]}')`;
            break;
          case "floor":
            value = `floorMetaDataIds/any/(f: f eq '${filterItem[key]}')`;
            break;
          default:
            break;
        }

        return value;
      });
    }

    return query.length ? query.join(" and ") : undefined;
  };

  const setDimension = (dimension) => {
    setIsMeasuring(false);
    if (dimension) {
      const values = [...measurementRef.current];
      const item = {
        value: values.length,
        label: dimension.dimensionLabel,
        data: dimension,
      };
      values.push(item);

      setMeasurements(values);
      setMeasurement(item);
    }
  };

  const showHistoryNode = (node) => {
    viewerRef.current?.IFC.showHistoryNode(node, token);
  };

  const deleteAllCuttingPlanes = (viewer) => {
    viewer.clipper.deleteAllPlanes();
    setSection(undefined);
  };

  useEffect(() => {
    if (isCreatingIssue) {
      onCreateIssue();
    }
  }, [isCreatingIssue]);

  const onCreateIssue = () => {
    setIsIssueModal(true);
    viewerRef.current?.IFC.selector.hideHoverSelection();
    createViewpoint();
  };

  const handleSelectMenuItem = (item) => {
    const viewer = viewerRef.current;
    function onCreateSection() {
      setIsClipping(true);
      viewer.clipper.active = true;
      viewer.addClippingPlane(isClipping);
    }
    if (viewer) {
      if (item.includes("#")) {
        viewer.IFC.setColorComponent(item);
      } else {
        switch (item) {
          case "component-info":
            onShowComponentDetails();
            break;
          case "section":
            onCreateSection();
            break;
          case "zoom":
            viewer.IFC.zoomSelectedComponent();
            break;
          case "hide":
            viewer.IFC.hideSelectedComponent();
            break;
          case "isolate":
            viewer.IFC.isolateComponent();
            break;
          case "isolate and transparent":
            viewer.IFC.isolateAndTranslateComponent();
            break;
          case "transparent-on":
            viewer.IFC.transparentComponent(true);
            break;
          case "transparent-off":
            viewer.IFC.transparentComponent(false);
            break;
          case "reset state":
            viewer.IFC.resetComponent();
            break;
          case "reset color":
            viewer.IFC.resetColorComponent();
            break;
          case "hide other type":
            viewer.IFC.hideOtherType(selectedComponentInfo.components);
            break;
          case "isolate storeys":
            viewer.IFC.isolateStoreys(selectedComponentInfo.components);
            break;
          case "issue":
            onCreateIssue();
            break;
          default:
            break;
        }
      }
    }
  };

  // Context Menu
  const handleContextMenu = (e) => {
    e.preventDefault();

    const viewer = viewerRef.current;
    if (!viewer) return;

    if (viewer.context.ifcCamera.isLocked) return;

    if (!isMenuvisible) {
      document.addEventListener("click", function onClickOutside() {
        setIsMenuVisible(false);
        document.removeEventListener("click", onClickOutside);
      });
    }

    if (
      !!hoveredComponent?.globalId ||
      selectedComponentInfo.gIds?.length > 0
    ) {
      const x = e.clientX - 62; // *Adjust as aside width*
      setMenuPos({ x, y: e.clientY });
      setIsMenuVisible(true);
    }
  };

  const resetModel = () => {
    const viewer = viewerRef.current;
    if (viewer) {
      deleteAllCuttingPlanes(viewer);
      handleResetMeasurements();
      viewer.IFC.resetModel();
    }
  };

  const handleClipping = () => {
    setIsClipping((prev) => !prev);
  };

  const handleAddSection = (startView) => {
    const viewer = viewerRef.current;
    if (viewer) {
      const position = new Vector3();
      const normal = new Vector3();
      switch (startView) {
        case 0: // Back
          normal.set(0, 0, 1);
          break;
        case 1: // Left
          normal.set(1, 0, 0);
          break;
        case 2: // Front
          normal.set(0, 0, -1);
          break;
        case 3: // Right
          normal.set(-1, 0, 0);
          break;
        case 4: // Top
          normal.set(0, -1, 0);
          break;
        case 5: // Bottom
          normal.set(0, 1, 0);
          break;
        default:
          break;
      }

      viewer.clipper.createFromNormalAndCoplanarPoint(
        normal,
        position,
        false,
        true
      );
    }
  };

  const handleResetSections = () => {
    const viewer = viewerRef.current;
    if (viewer) {
      deleteAllCuttingPlanes(viewer);
    }
  };

  const handleChangeSection = (plane) => {
    section.data.visible = false;
    plane.data.visible = true;
    setSection(plane);
  };

  const handleRemoveSection = () => {
    const viewer = viewerRef.current;
    if (section && viewer) {
      viewer.clipper.deletePlane(section.data);
      const item = sections.length ? sections[0] : undefined;
      setSection(item);
    }
  };

  const handleMeasure = () => {
    if (isMeasure) {
      setIsMeasuring(false);
    }
    setIsMeasure((prev) => !prev);
  };

  const handleViewMark = () => {
    setIsViewMark((prev) => !prev);
  };

  const handleRemoveMeasurement = () => {
    if (measurement) {
      viewerRef.current.dimensions.deleteDimension(measurement.data);
      const values = [...measurements];
      const index = values.indexOf(measurement);
      if (index > -1) {
        values.splice(index, 1);
      }

      setMeasurements(values);
      setMeasurement(values.length ? values[0] : undefined);
    }
  };

  const handleResetMeasurements = () => {
    if (isMeasuring) {
      setIsMeasuring(false);
    }

    const viewer = viewerRef.current;
    if (viewer) {
      viewer.dimensions.deleteAll();
    }

    setMeasurements([]);
    setMeasurement(undefined);
  };

  const handleAddMeasurement = () => {
    if (!isMeasuring) {
      setIsMeasuring(true);
    }
  };

  const handleChangeMeasurement = (selected) => {
    setMeasurement(selected);
  };

  const setCameraPosition = (pos) => {
    viewerRef.current?.context.ifcCamera.setPosition(pos);
  };

  const handleBackEditIssue = () => {
    setIssueAddMode(MODE_ADD_NONE);
  };

  const handleRemoveComponent = (component) => {
    viewerRef.current?.IFC.selector.removeComponentFromSelection(component);
  };

  const handleAddComponents = () => {
    setIsModifiedIssueComponents(true);
    setAddIssueComponents(addedComponents);
    setAddedComponents([]);
    setIssueAddMode(MODE_ADD_NONE);
  };

  const handleBubbleState = () => {
    if (bubbleState === BubbleState.BUBBLE_NONE) {
      setBubbleState(BubbleState.BUBBLE_CREATING);
    } else {
      setBubbleState(BubbleState.BUBBLE_NONE);
    }
  };

  const resetBubbleState = () => {
    setBubbleState(BubbleState.BUBBLE_NONE);
  };

  const insertMetaData = (file) => {
    const discipline = categories.find(
      (d) => d.id === file.disciplineMetaDataId
    );
    const building = buildings.find((b) => b.id === file.buildingMetaDataId);
    const floor = floors.find((f) => f.id === file.floorMetaDataId);
    const data = { ...metaDataRef.current };
    if (discipline && !data.disciplines.find((d) => d.id === discipline.id))
      data.disciplines = [...data.disciplines, discipline];
    if (building && !data.buildings.find((b) => b.id === building.id))
      data.buildings = [...data.buildings, building];
    if (floor && !data.floors.find((f) => f.id === floor.id))
      data.floors = [...data.floors, floor];

    setMetaData(data);
  };

  const createViewpoint = () => {
    setIssueAddMode(MODE_ADD_NONE);
    viewerRef.current
      ?.createViewpoint(currentProject.id, token)
      .then((viewpoint) => {
        setAddIssueViewpoints((prev) =>
          prev
            ? [
                ...prev,
                {
                  persistViewpointDto: getPersistViewpointDto(viewpoint),
                  image: viewpoint.image,
                },
              ]
            : [
                {
                  persistViewpointDto: getPersistViewpointDto(viewpoint),
                  image: viewpoint.image,
                },
              ]
        );
      })
      .catch((err) => {
        console.log("Can't create viewpoint.", err);
      });

    viewerRef.current?.IFC.hideBubble();
  };

  const getPersistViewpointDto = (viewpoint) => {
    const { camera, sections, visualState, bubble, imageId } = viewpoint;

    const persistDto = {};

    // General
    persistDto.ProjectId = { value: currentProject.id };
    persistDto.ImageId = { value: imageId };
    persistDto.ImageName = { value: `${imageId}.png` };
    persistDto.Index = { value: 0 };
    persistDto.CreatingTool = { value: "Visoplan-Web" };

    // Camera Setting
    persistDto.CameraType = {
      value: camera.isPerspective ? 0 : 1,
    };
    persistDto.Location = { value: getApiVector3(camera.location) };
    persistDto.Direction = { value: getApiVector3(camera.direction) };
    persistDto.Up = { value: getApiVector3(camera.up) };
    persistDto.CameraFactor = { value: camera.factor };

    // Visibility
    persistDto.Visibiity = { value: visualState.visibility };

    // Visual State Container
    const visualStates = {};
    visualStates.SelectedGlobalIds = visualState.selectedIDs;
    visualStates.TransparentGlobalIds = visualState.transparentIDs;
    visualStates.VisbilityExceptionGlobalIds = visualState.exceptedIDs;
    visualStates.ColorGroups = visualState.colorGroups.map((grp) => {
      return {
        Color: grp.color,
        ComponentGlobalIds: grp.componentIDs,
      };
    });

    persistDto.VisualStates = { value: visualStates };

    // Section
    persistDto.Sections = {
      value: sections.map((plane) => {
        return {
          Location: getApiVector3(plane.location),
          Normal: getApiVector3(plane.normal),
        };
      }),
    };

    // Bubble
    persistDto.Lines = {
      value: bubble
        ? [{ Start: getApiVector3(bubble), End: getApiVector3(bubble) }]
        : [],
    };

    return persistDto;

    function getApiVector3(vector) {
      return {
        x: vector.x,
        y: vector.y,
        z: vector.z,
      };
    }
  };

  const handleComponentTooltip = async (e) => {
    const tooltipInfo = tooltip;
    if (hoveredComRef.current?.globalId) {
      const history = await ModelService.getModelComponentHistory(
        currentProject?.id,
        hoveredComRef.current.globalId
      );
      if (!history || !history.history) return;

      const element = history.history[0].propertyGroups.find(
        (p) => p.name === "Element"
      );
      if (!element) return;

      const response = await ModelService.getPropertyGroups(element.id);
      if (!response) return;

      const name = response[0].properties.find((p) => p.name === "Name");
      const type = response[0].properties.find((p) => p.name === "Type");

      tooltipInfo.visible = true;
      tooltipInfo.xPos = e.clientX - VIEWER_OFFSET_X + VIEWER_MARGIN;
      tooltipInfo.yPos = e.clientY - VIEWER_OFFSET_Y + VIEWER_MARGIN;
      tooltipInfo.component = { name: name.value, type: type.value };
    } else {
      tooltipInfo.visible = false;
    }

    setTooltip(tooltipInfo);
  };

  const handleSetViewerState = (isKeptChange, isDontShow) => {
    setViewerState({ isKeptChange, isDontShow });
    setIsOpenVSetting(false);
  };

  const loadModelFiles = () => {
    if (!selectedModels.length) return;

    const viewer = viewerRef.current;
    resetViewer();
    if (!viewerState.isKeptChange) {
      resetIfcModels(viewer);
    }

    handleLoading(true);
    const loadedModelIds = viewer.getLoadedModelIds();
    const removedModelIds = loadedModelIds.filter(
      (id) => !selectedModels.includes(id)
    );
    viewer.IFC.removeModels(removedModelIds);

    const newModelIds = selectedModels.filter(
      (id) => !loadedModelIds.includes(id)
    );
    if (!newModelIds.length) {
      handleLoading(false);
      return;
    }

    viewer.IFC.createNewCancelToken();

    const model_load = newModelIds.map((m) => {
      return viewer.IFC.getModelFile(m, token);
    });

    Promise.all(model_load)
      .then((models) => {
        const parsing_load = [];
        models.forEach((m) => {
          if (m) {
            parsing_load.push(loadModel(m[0]));
          }
        });

        if (parsing_load) {
          Promise.all(parsing_load)
            .then(() => {
              viewer.viewpoint.calculateCenter();
              viewer.grid.handleGrid();
              viewer.clipper.updateMaterials();
              handleLoading(false);
              if (markRef.current) {
                setViewpointBubble();
              }
            })
            .catch((subErr) => {
              viewer.IFC.cancelRequest();
              throw subErr;
            });
        }
      })
      .catch((err) => {
        console.log(err);
        viewer.IFC.cancelRequest();
        handleLoading(false);
      });

    async function loadModel(file) {
      await viewer.IFC.loadVisoModelFile(file, token);
      insertMetaData(file);
    }
  };

  useEffect(() => {
    const viewer = viewerRef.current;
    const storeyLoading = storeyModelIds.map((mId) => {
      return viewer.IFC.getModelFile(mId, token);
    });

    Promise.all(storeyLoading)
      .then(async (models) => {
        const storeyMaps = [];

        for (let i = 0; i < models.length; i++) {
          const file = models[i][0];
          for (let j = 0; j < file.storeyMaps.length; j++) {
            const s = file.storeyMaps[j];
            if (isNaN(s.center.x)) s.center.setX(file.center.x);
            if (isNaN(s.center.y)) s.center.setY(file.center.y);
            if (isNaN(s.size.x)) s.size.setX(file.size.x);
            if (isNaN(s.size.y)) s.size.setY(file.size.y);

            s.modelFileId = file.id;
            if (s.resourceId)
              s.image = await viewer.IFC.loadResource(s.resourceId, token);
            storeyMaps.push(s);
          }
        }

        setStoreys(storeyMaps);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [storeyModelIds]);

  const resetIfcModels = (viewer) => {
    deleteAllCuttingPlanes(viewer);
    viewer.dimensions.deleteAll();
    viewer.IFC.resetManager();
  };

  const resetViewer = () => {
    setTooltip({ visible: false });
    setHoveredComponent();
    //setMetaData(initialMetaData);
  };

  return (
    <>
      <div
        className="models-content-viewer_view"
        id="viewer-container"
        onContextMenu={handleContextMenu}
        style={{ backgroundColor: "#f2f3f5" }}
      />
      {isMenuvisible && (
        <ViewerContextMenu
          menuPos={menuPos}
          selectedCount={selectedComponentInfo.gIds?.length}
          isTransparent={isTransparent}
          selectItem={handleSelectMenuItem}
        />
      )}
      <ViewerToolbar
        resetModel={resetModel}
        containerRect={rect}
        isClipping={isClipping}
        openClipping={handleClipping}
        isMeasure={isMeasure}
        openMeasure={handleMeasure}
        is2D={is2D}
        open2D={setIs2D}
        isViewMark={isViewMark}
        openViewMark={setIsViewMark}
      />
      {isClipping && (
        <ClippingPanel
          containerRect={rect}
          closePanel={handleClipping}
          section={section}
          sections={sections}
          addSection={handleAddSection}
          resetSections={handleResetSections}
          changeSection={handleChangeSection}
          removeSection={handleRemoveSection}
        />
      )}
      {isMeasure && (
        <MeasurePanel
          containerRect={rect}
          closePanel={handleMeasure}
          addMeasurement={handleAddMeasurement}
          measurements={measurements}
          measurement={measurement}
          changeMeasurement={handleChangeMeasurement}
          removeMeasurement={handleRemoveMeasurement}
          resetMeasurements={handleResetMeasurements}
        />
      )}
      {is2D && (
        <PlanPanel
          containerRect={rect}
          storeys={storeys}
          cameraInfo={cameraInfo}
          setCameraPosition={setCameraPosition}
        />
      )}
      {isViewMark && (
        <ViewpointSettingPanel
          containerRect={rect}
          closePanel={handleViewMark}
          isVisible={visibleMark}
          setVisible={setVisibleMark}
          filterSetting={filterItem}
          setFilterSetting={setFilterItem}
          statuses={getValuesAddedAll(statuses)}
          types={getValuesAddedAll(types)}
          priorities={getValuesAddedAll(priorities)}
          users={getValuesAddedAll(users)}
          buildings={getValuesAddedAll(buildings)}
          floors={getValuesAddedAll(floors)}
          disciplines={getValuesAddedAll(categories)}
        />
      )}
      {issueAddMode === MODE_ADD_COMPONENT && (
        <AddingComponentPanel
          containerRect={rect}
          components={addedComponents}
          removeComponent={handleRemoveComponent}
          addComponents={handleAddComponents}
          goBack={handleBackEditIssue}
        />
      )}
      {issueAddMode === MODE_ADD_VIEWPOINT && (
        <ViewpointDetailPanel
          containerRect={rect}
          selectedComponents={selectedComponentInfo.gIds}
          sections={sections}
          colorComponents={colorIds}
          metaData={metaData}
          bubbleState={bubbleState}
          setBubbleState={handleBubbleState}
          resetBubbleState={resetBubbleState}
          createViewpoint={createViewpoint}
          goBack={handleBackEditIssue}
        />
      )}
      {isOpenVSetting && (
        <VisualStateModal setViewerState={handleSetViewerState} />
      )}
      <ComponentTooltip tooltipInfo={tooltip} />
    </>
  );
};

export default IFCViewer;
