import React, { useMemo } from 'react';
import { Box, Button } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import Icon from '@mdi/react';
import { mdiCheck, mdiRewind } from '@mdi/js';
import useInReviewIssuesStatus from 'issues/hooks/useInReviewIssueStatus';

interface ReviewActionButtonsProps extends ISxProps {
  issueId: string,
  onClickRejectReview: () => void,
  onClickApproveReview: () => void,
}

export default function ReviewActionButtons({
  sx,
  issueId,
  onClickRejectReview,
  onClickApproveReview,
}: ReviewActionButtonsProps) {
  const { t } = useTranslation('issues');
  const { data: currentUser } = useCurrentUserQuery();
  const { data: issue, isLoading: isLoadingIssue } = useIssueQuery(issueId);
  const isCurrentUserReviewer = useMemo(() => (issue?.reviewerId && currentUser && issue.reviewerId === currentUser.id), [currentUser, issue]);
  const inReviewIssueStatus = useInReviewIssuesStatus();

  const canDoReviewActions = useMemo(() => {
    if (!issue?.issueStatus || !inReviewIssueStatus) return undefined;
    return issue.issueStatus.id === inReviewIssueStatus.id && isCurrentUserReviewer;
  }, [isCurrentUserReviewer, issue, inReviewIssueStatus]);

  if (!issueId || !canDoReviewActions) return null;
  return (
    <Box id="ReviewActionButtons" sx={{ ...sx, display: 'flex', gap: 1 }}>
      <Button
        variant="contained"
        color="secondary"
        onClick={onClickRejectReview}
        disabled={isLoadingIssue}
        sx={{ gap: 1 }}
        size="small"
      >
        <Icon path={mdiRewind} size={0.5} />
        {t('review-action-buttons_reject-review', 'Reopen')}
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={onClickApproveReview}
        disabled={isLoadingIssue}
        sx={{ gap: 1 }}
        size="small"
      >
        <Icon path={mdiCheck} size={0.5} />
        {t('review-action-buttons_accept-review', 'Complete')}
      </Button>
    </Box>
  );
}
