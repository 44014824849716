import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Autocomplete,
  Box, FormControl, InputAdornment, InputLabel, Link, SelectChangeEvent, SxProps, TextField, Theme, Typography, useTheme,
} from '@mui/material';
import ValidateTable from 'upload/components/DocumentUploadWizard/ValidateTable';
import FileType from 'documents/types/FileType';
import Folder from 'documents-folders/types/Folder';
import StagedEmailAttachment from 'emails/types/StagedEmailAttachment';
import { useTranslation } from 'react-i18next';
import { DocumentService } from 'Services';
import FolderSelect from 'documents-folders/components/FolderSelect';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';
import useFolderTreeQuery from 'documents-folders/hooks/useFolderTreeQuery';

interface UploadEmailAttachmentItemProps {
  stagedAttachment: StagedEmailAttachment,
  onDiscard: () => void,
  onChangeFolderId: (attachmentId: string, folderId: string) => void,
  onChangeNewName: (attachmentId: string, newName: string) => void,
  sx?: SxProps<Theme>,
}

export default function UploadEmailAttachmentItem({
  stagedAttachment,
  onDiscard,
  onChangeFolderId,
  onChangeNewName,
  sx,
}: UploadEmailAttachmentItemProps) {
  const { t } = useTranslation('emails');
  const theme = useTheme();
  const projectId = useCurrentProjectId();

  const [targetFolder, setTargetFolder] = useState<Folder | null>(null);
  const [newFileBaseName, setNewFileBaseName] = useState(stagedAttachment.name.split('.').slice(0, -1).join());
  const [fileNameOptions, setFileNameOptions] = useState<string[]>([]);

  const { data: folderTree } = useFolderTreeQuery();
  const fileExtension = useMemo(() => stagedAttachment.name.split('.').pop(), [stagedAttachment.name]);

  useEffect(() => {
    if (!folderTree) return;
    const initialTargetFolder = Array.from(folderTree.foldersById.values()).find((f) => f.isDefault && f.name === 'Shared') ?? null;
    setTargetFolder(initialTargetFolder);
    if (initialTargetFolder) onChangeFolderId(stagedAttachment.id, initialTargetFolder.id);
  }, [stagedAttachment.id, folderTree, onChangeFolderId, t]);

  useEffect(() => {
    if (!targetFolder) {
      setFileNameOptions([]);
      return;
    }
    const filter = {
      folderId: { eq: targetFolder.id },
      fileType: { eq: FileType[stagedAttachment.type] },
    };
    const verionsPromise: Promise<any> = DocumentService.getDocumentVersionsByProjectId(projectId, null, null, filter);
    verionsPromise.then((bufferDocs: { originalFileName: string }[]) => {
      setFileNameOptions(Array.from(new Set(bufferDocs.map((doc) => doc.originalFileName.split('.').slice(0, -1).join()))));
    });
  }, [stagedAttachment.type, projectId, targetFolder]);

  const onChangeTargetFolder = useCallback((event: SelectChangeEvent<string>) => {
    if (!folderTree) return;
    const selectedFolderId = event.target.value;
    const selectedFolder = selectedFolderId ? folderTree.foldersById.get(event.target.value) ?? null : null;
    if (selectedFolder?.id === targetFolder?.id) return;
    setTargetFolder(selectedFolder);
    if (selectedFolder) onChangeFolderId(stagedAttachment.id, selectedFolder.id);
  }, [folderTree, onChangeFolderId, stagedAttachment.id, targetFolder?.id]);

  const handleNewFileNameBaseChanged = useCallback((value: string) => {
    if (value === newFileBaseName) return;
    setNewFileBaseName(value);
    onChangeNewName(stagedAttachment.id, `${value}.${fileExtension}`);
  }, [stagedAttachment.id, fileExtension, newFileBaseName, onChangeNewName]);

  return (
    <Box sx={{ ...sx, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <Box>
          <svg style={{ width: '24px', height: '28px' }}>
            <use xlinkHref="/img/sprite.svg#attachment" />
          </svg>
        </Box>
        <Box sx={{
          display: 'flex', flexDirection: 'column', ml: 1, justifyContent: 'center', rowGap: '4px',
        }}
        >
          <Typography sx={{ fontWeight: 600, lineHeight: 1 }}>{stagedAttachment.name}</Typography>
          <Typography sx={{ color: theme.palette.text.disabled, lineHeight: 1 }}>{`${Math.round(stagedAttachment.contentLength / 1000)} KB`}</Typography>
        </Box>
        <Link onClick={onDiscard} sx={{ ml: 'auto' }}>{t('upload-attachments_item-discard', 'Discard Upload')}</Link>
      </Box>
      <Box sx={{ display: 'flex', mt: 2 }}>
        <FormControl
          size="small"
          sx={{ width: '180px' }}
        >
          <InputLabel id="upload-attachments_item-folder">
            {t('upload-attachments_item-folder-label', 'Folder')}
          </InputLabel>
          <FolderSelect
            id="upload-attachments_item-folder"
            labelId="upload-attachments_item-folder-label"
            value={targetFolder?.id ?? ''}
            onChange={onChangeTargetFolder}
            label={t('upload-attachments_item-folder-label', 'Folder')}
          />
        </FormControl>
        <Autocomplete
          disablePortal
          id="upload-attachments_item-new-filename"
          options={[...fileNameOptions]}
          sx={{ flexGrow: 1, ml: 1 }}
          disableClearable
          renderInput={(params) => (
            <TextField
              // justification for eslint-disable: this is how this 3rd party component is meant to be used as per the docs
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label={t('upload-attachments_item-new-filename-label', 'Filename')}
              InputProps={{
                ...params.InputProps,
                endAdornment: <InputAdornment position="end">{`.${fileExtension}`}</InputAdornment>,
              }}
            />
          )}
          onInputChange={(_, value) => handleNewFileNameBaseChanged(value)}
          value={newFileBaseName}
          freeSolo
        />
      </Box>
      <Box>
        {!!stagedAttachment.validationApiResponseBody
        && <ValidateTable validationApiResponseBody={stagedAttachment.validationApiResponseBody} />}
      </Box>
    </Box>
  );
}

UploadEmailAttachmentItem.defaultProps = {
  sx: undefined,
};
