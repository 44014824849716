import * as Request from "../Helpers/Request";
import buildQuery from "odata-query";

import { uploadFiles } from "../Helpers/utils";

export const defaultEmailColumns = [
  { name: "Subject", width: 447 },
  { name: "Creation Date", width: 162 },
  { name: "Sent by", width: 184 },
  { name: "Recipient", width: 244 },
  { name: "Attachment", width: 275 },
  { name: "Tags", width: 219 },
];

export const AsideVisibleStatus = {
  Hidden: 0,
  FilterVisible: 1,
  DetailVisible: 2,
};

export async function getInbox() {
  const result = await Request.GET(`api/inbox-email`);
  return result.status === 200 ? result.body : [];
}

export async function assignTagsToEmail(emailId, tagIds) {
  const updateInboxEmailDto = {
    id: emailId,
    tagIds,
  };
  await Request.PUT(`api/inbox-email`, updateInboxEmailDto);
}

export async function getEmailsByProjectId(projectId, top, skip, filter) {
  const query = buildQuery({ filter, top, skip, orderBy: "creationDate desc" });
  const result = await Request.GET(`api/inbox-email/${query}`);
  return result.status === 200 ? result.body : [];
}

export async function updateProjectEmail(params, setApiError) {
  const result = await Request.PATCH("api/inbox", params);
  if (result.status === 200) return true;
  setApiError(result?.body.errors[0].errorMessage);
  return false;
}

export async function uploadEmails(file, projectId, token, setProgress) {
  const url = `${Request.API_URL}api/inbox-email/upload`;
  return uploadFiles(file, token, url, setProgress, null);
}
