import useCurrentProjectQuery from "projects/hooks/useCurrentProjectQuery";
import React, { useState, useEffect } from "react";

import { IconBackColors } from "../../Helpers/Common";

const AssignItem = ({ issue }) => {
  const { data: currentProject } = useCurrentProjectQuery();
  const [roleColors, setRoleColors] = useState({});

  useEffect(() => {
    if (!currentProject || !issue) return;

    const tmpRoles = {};
    issue.assignedUsers.forEach((user) => {
      const role = currentProject.collaboratorRoles.find(
        (r) => r.collaboratorId === user.id
      );
      if (role) {
        const i = currentProject.collaboratorRoleDefinitions.findIndex(
          (d) => d.id === role.roleDefinitionId
        );
        tmpRoles[user.id] =
          IconBackColors.length <= i ? IconBackColors[0] : IconBackColors[i];
      }
    });
    setRoleColors(tmpRoles);
  }, [currentProject, issue]);

  return (
    <div className="accordion-content">
      <div className="width--30">
        {!!issue.assignedUsers &&
          issue.assignedUsers.map((user, index) => (
            <div key={`assigned-${index}`} className="account-item">
              <div
                className="account-item-abbre"
                style={{ background: roleColors[user.id] }}
              >
                <p>
                  {`${user.firstName ? user.firstName[0].toUpperCase() : ""}${
                    user.lastName ? user.lastName[0].toUpperCase() : ""
                  }`}
                </p>
              </div>
              <p className="account-item-name">
                {`${user.firstName || ""} ${user.lastName || ""}`}
              </p>
            </div>
          ))}
      </div>
      <div className="width--60">
        {!!issue.reviewer && (
          <div className="account-item">
            <div className="account-item-abbre">
              <p>{`${
                issue.reviewer.firstName
                  ? issue.reviewer.firstName[0].toUpperCase()
                  : ""
              }${
                issue.reviewer.lastName
                  ? issue.reviewer.lastName[0].toUpperCase()
                  : ""
              }`}</p>
            </div>
            <p className="account-item-name">
              {`${issue.reviewer.firstName || ""} ${
                issue.reviewer.lastName || ""
              }`}
            </p>
          </div>
        )}
      </div>
      <div className="rest-space" />
    </div>
  );
};

export default AssignItem;
