import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, ButtonBase, Link, Typography, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Trans, useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import ProjectDto from 'projects/types/ProjectDto';
import DeleteProjectDialog from 'projects/components/DeleteProjectDialog';
import HorizontalDivider from 'common/styled/HorizontalDivider';
import CollaboratorDto from 'collaborators/types/CollaboratorDto';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';

import projectThumbnailCampusGlashuette from 'img/customer_images/Architektenwettbewerb-Campus-Glashuette-ANSICHT.png';
import projectThumbnailZentrum from 'img/customer_images/zentrum.png';
import moment from 'moment';

interface ProjectListItemProps extends ISxProps {
  project: ProjectDto,
  creator: CollaboratorDto | undefined,
  onClick: (project: ProjectDto) => void,
}

const projectThumbnailMap = new Map([
  ['618a4d0a731ec49823766ea0', projectThumbnailCampusGlashuette],
  ['62135a20cb4743f7ed62e62e', projectThumbnailCampusGlashuette],
  ['634d3b38140d91a81bf80271', projectThumbnailZentrum],
]);

export default function ProjectListItem({
  sx,
  project,
  creator,
  onClick,
}: ProjectListItemProps) {
  const { t } = useTranslation('projects');
  const theme = useTheme();
  const { data: currentUser } = useCurrentUserQuery();
  const [deleteProjectDialogOpen, setDeleteProjectDialogOpen] = useState(false);
  const creatorName = useMemo(() => (creator ? `${creator.firstName} ${creator.lastName}` : undefined), [creator]);
  const creationDate = useMemo(() => new Date(project.creationDate).toLocaleDateString('de-DE'), [project.creationDate]);
  const trialEndDate = useMemo(() => (project.trialEndDate ? new Date(project.trialEndDate).toLocaleDateString('de-DE') : undefined), [project.trialEndDate]);
  const remainingTrialDays = useMemo(() => {
    if (project.trialExpired || !project.trialEndDate) return undefined;
    return moment(project.trialEndDate).diff(moment(), 'days');
  }, [project.trialEndDate, project.trialExpired]);
  const canDelete = useMemo(() => {
    if (!currentUser) return undefined;
    if (creator?.id === currentUser.id) return true;
    if (creator === undefined) {
      const currentUserRoleId = project.collaboratorRoles.find((r) => r.collaboratorId === currentUser.id)?.roleDefinitionId;
      if (!currentUserRoleId) return false;
      const currentUserRole = project.collaboratorRoleDefinitions.find((d) => d.id === currentUserRoleId);
      if (!currentUserRole) return false;
      return currentUserRole?.name === 'Admin';
    }
    return false;
  }, [creator, currentUser, project]);
  const thumbnail = useMemo(() => projectThumbnailMap.get(project.id), [project.id]);
  const onCloseDeleteProjectDialog = useCallback(() => setDeleteProjectDialogOpen(false), []);
  const onClickButton = useCallback(() => {
    onClick(project);
  }, [onClick, project]);
  const onClickDelete = useCallback(() => {
    setDeleteProjectDialogOpen(true);
  }, []);
  return (
    <Box
      id="ProjectListItem"
      sx={{
        ...sx,
        backgroundColor: theme.palette.background.default,
        borderRadius: '4px',
        boxShadow: '0px 2px 8px -1px rgba(0, 0, 0, 0.3)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ButtonBase
        onClick={onClickButton}
        disabled={project.trialExpired}
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          p: 4,
          gap: 3,
          justifyItems: 'flex-start',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          textAlign: 'left',
          '&:hover': {
            backgroundColor: theme.palette.secondary.light,
          },
        }}
      >
        {!thumbnail && (
          <Typography
            sx={{
              alignSelf: 'flex-start',
              backgroundColor: project.trialExpired ? theme.palette.grey[400] : theme.palette.text.primary,
              borderRadius: '4px',
              color: theme.palette.primary.contrastText,
              p: 1,
              fontSize: 16,
              fontWeight: 700,
            }}
          >
            {project.abbreviation}
          </Typography>
        )}
        {!!thumbnail && <img src={thumbnail} alt={project.name} style={{ height: '46px', borderRadius: '4px' }} />}
        <Typography
          sx={{
            color: project.trialExpired ? theme.palette.grey[500] : undefined,
            fontSize: 20,
            fontWeight: 700,
          }}
        >
          {project.name}
        </Typography>
      </ButtonBase>
      <HorizontalDivider />
      <Box sx={{ px: 4, py: 1, minHeight: 56, display: 'flex', gap: 1, alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyItems: 'center' }}>
          <Typography color="grey">
            {creatorName
              ? t('project-list-item_created-by-on', 'Created by {{creatorName}} on {{creationDate}}', { creatorName, creationDate })
              : t('project-list-item_created-on', 'Created on {{creationDate}}', { creationDate })}
          </Typography>
          {!!trialEndDate && remainingTrialDays !== undefined && (
            <Typography color={theme.palette.warning.main}>{t('project-list-item_trial-active-end-date', 'Trial: {{remainingTrialDays}} days left (ends {{trialEndDate}})', { remainingTrialDays, trialEndDate })}</Typography>
          )}
          {project.trialExpired && (
            <Typography color={theme.palette.error.main}>
              <Trans
                t={t}
                i18nKey="project-list-item_trial-expired-end-date"
                defaults='Trial expired. Contact: <mailtoLink href="mailto:sales@visoplan.de">sales@visoplan.de</mailtoLink>'
                components={{ mailtoLink: <Link /> }}
              />
            </Typography>
          )}
        </Box>
        {!!canDelete && (
          <Button
            onClick={onClickDelete}
            disabled={project.trialExpired}
            variant="outlined"
            size="small"
            sx={{ ml: 'auto', pl: 0.5, gap: 0.5, flexShrink: 0 }}
          >
            <DeleteIcon fontSize="small" />
            {t('project-list-item_delete-button-label', 'Delete')}
          </Button>
        )}
      </Box>
      {!!deleteProjectDialogOpen && <DeleteProjectDialog project={project} onClose={onCloseDeleteProjectDialog} />}
    </Box>
  );
}
