// @ts-ignore
import {
  computeBoundsTree,
  disposeBoundsTree,
  acceleratedRaycast,
} from "three-mesh-bvh";
import { VisoplanLoader } from "../../VisoplanLoader";
import { IFCModel } from "../../IFCModel";
import { IfcComponent } from "../../base-types";
import { IfcUnits } from "./units";
import { IfcSelector } from "./selection/selector";
import { IfcContext } from "../context";
import { VisoModel, VisoNode, VisoObjectMap } from "../../../Types/BaseDefinitions";
import { VisoModelFile } from "../../VisoModelFile";
import { VisoDataType } from "../../viso-types";
import { VisoplanService } from "../../visoplan-service";
import { HistoryNode } from "./HistoryNode";
import { Bubble } from "./bubble/visoBubble";
import { RightToLeftHand } from "../../utils/ThreeUtils";
import { Color } from "three";

export class IfcManager extends IfcComponent {
  visoLoader: VisoplanLoader;
  selector: IfcSelector;
  units: IfcUnits;
  bubble: Bubble;
  private readonly context: IfcContext;
  private readonly service: VisoplanService;
  private historyNode: HistoryNode;

  constructor(context: IfcContext, service: VisoplanService) {
    super(context);
    this.context = context;
    this.visoLoader = new VisoplanLoader();
    this.setupThreeMeshBVH();
    this.selector = new IfcSelector(context, this);
    this.units = new IfcUnits(this);
    this.service = service;
    this.historyNode = new HistoryNode(this.context);
    this.bubble = new Bubble(this.context, this);
  }

  async loadVisoModel(model: VisoModel) {
    try {
      const ifcModel = await this.visoLoader.load(model);
      this.addIfcModel(ifcModel);
      if (!this.context.isDirty)
        this.context.fitToFrame();

      this.context.isDirty = true;

      return ifcModel;
    } catch (err) {
      console.error("Error loading IFC.");
      console.error(err);
      return null;
    }
  }

  async loadResource(id:string, token:any){
    const response = await this.service.getResourceStream(id, token);
    return response.status === 200? this.convertImage(response.data):undefined;
  }

  async loadVisoModelFile(modelFile: VisoModelFile, token: any) {
    try {
      let shapeIds: string[] = [];
      let styleIds: string[] = [];
      let nodelInfo: any = {};
      let storey: any = undefined;

      // modelFile.storeyMaps.forEach(async (map) => {
      //   if (map.resourceId) {
      //     map.image= await this.loadResource(map.resourceId, token);
      //   }
      // });

      Object.keys(modelFile.nodes).forEach((id) => {
        const n = modelFile.nodes[id];

        if (n.shapeId) {
          shapeIds.push(n.shapeId);
          styleIds = styleIds.concat(n.styleIds);
          if (n.shapeId in nodelInfo) {
            nodelInfo[n.shapeId].push(n);
          } else {
            nodelInfo[n.shapeId] = [n];
          }
        }

        if (n.elementType == "IfcBuildingStorey") {
          if (!storey) {
            storey = {};
          }

          const name = n.elementName ? n.elementName : "NoNameStorey";
          const nIdsInStorey = n.containsNodeIds;
          nIdsInStorey.concat(n.decomposedByNodeIds);

          const gIdsInStorey: string[] = [];

          // @ts-ignore
          nIdsInStorey.forEach((nId) => {
            const storeyNode = modelFile.nodes[nId];

            gIdsInStorey.push(storeyNode.globalId);

            // @ts-ignore
            storeyNode.isDecomposedByNodeIds.forEach((subId) => {
              gIdsInStorey.push(modelFile.nodes[subId].globalId);
            });
          });

          storey[name] = [...new Set(gIdsInStorey)];
        }
      });

      shapeIds = [...new Set(shapeIds)];
      styleIds = [...new Set(styleIds)];

      const shape = await this.getObjectMap(
        VisoDataType.shape,
        shapeIds,
        token
      );
      const style = await this.getObjectMap(
        VisoDataType.style,
        styleIds,
        token
      );

      //@ts-ignore
      await this.loadVisoModel({
        model: nodelInfo,
        shapes: shape,
        styles: style,
        center: modelFile.center,
        size: modelFile.size,
        storeys: storey,
        id: modelFile.id,
        nodeData: Object.values(modelFile.nodes),
      });
    } catch (err) {
      console.error("Error loading IFC.");
      console.error(err);
      this.service.cancelRequest();
      return null;
    }
  }

  convertImage(data: any) {
    let binary = "";
    const bytes = new Uint8Array(data);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return `data:image/png;base64,${btoa(binary)}`;
  }

  async getModelFile(id: string, token: any) {
    try {
      return await this.service.getMsgpack(VisoDataType.modelfile, [id], token);
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  createNewCancelToken() {
    this.service.createNewCancelToken();
  }

  cancelRequest() {
    this.service.cancelRequest();
  }

  /**
   * Gets the ID of the model pointed by the cursor.
   */
  getModelID() {
    const found = this.context.castRayIfc();
    if (!found) return null;
    const mesh = found.object as IFCModel;
    if (!mesh || !mesh.modelFileId)
      return null;
    return mesh.modelFileId;
  }

  closeModel = (modelID: string) => {
    this.visoLoader.ifcManager.close(modelID, this.context.getScene());
    let index = this.context.items.ifcModels.findIndex(
      (m) => m.modelFileId === modelID
    );
    this.context.items.ifcModels.splice(index, 1);
    index = this.context.items.pickableIfcModels.findIndex(
      (p) => p.modelId === modelID
    );
    this.context.items.pickableIfcModels.splice(index, 1);
  };

  handleWireframe(visible: boolean) {
    this.context.items.ifcModels.forEach((model) => {
      if (model.wireframe) {
        model.wireframe.visible = visible;
      }
    });
  }

  resetManager() {
    this.visoLoader.ifcManager.resetModel();
    this.selector.resetSelection();
    this.historyNode.removeHistoryNode();
  }

  closeManager() {
    this.visoLoader.ifcManager.closeManager(this.context.getScene());
    this.selector.resetSelection();
    this.closeModels();
  }

  removeModels(modelIds: string[]) {
    modelIds.forEach((modelId) => this.closeModel(modelId));
    this.context.removeModels(modelIds);
    this.visoLoader.ifcManager.removeModels(modelIds, this.context.getScene());
  }

  zoomSelectedComponent() {
    this.selector.zoomToSelectedComponent();
  }

  hideSelectedComponent() {
    this.selector.hideSelectedComponent();
  }

  transparentComponent(isOn: boolean) {
    this.selector.transparentComponent(isOn);
  }

  setColorComponent(color: string) {
    this.selector.setColorComponent(color);
  }

  resetColorComponent() {
    this.selector.resetColorComponent();
  }

  isolateComponent() {
    this.visoLoader.ifcManager.subsets.isolateElements();
  }

  isolateAndTranslateComponent() {
    this.selector.isolateAndTranslateElements();
  }

  resetComponent() {
    this.visoLoader.ifcManager.subsets.resetElements();
    this.selector.resetSelection();
    this.historyNode.removeHistoryNode();
  }

  resetModel() {
    this.visoLoader.ifcManager.resetModel();
    this.selector.resetSelection();
    this.historyNode.removeHistoryNode();
    this.context.ifcCamera.fitModelToFrame();
  }

  setVisibleSetting(spaces: boolean, openings: boolean, sectionColor?: Color) {
    this.visoLoader.ifcManager.setVisibleSetting(openings, spaces, sectionColor);
  }

  hideOtherType(components: VisoNode[]) {
    this.visoLoader.ifcManager.subsets.hideOtherType(components);
  }

  isolateStoreys(components: VisoNode[]) {
    if (this.visoLoader.ifcManager.subsets.isolateStoreys(components)) {
      this.selector.resetSelection();
    }
  }

  async showHistoryNode(node: any, token: any) {
    if (node) {
      const shape = await this.getObjectMap(
        VisoDataType.shape,
        [node.shapeId],
        token
      );
      this.historyNode.showHistoryNode(shape[node.shapeId], node.transform);
    } else {
      this.historyNode.removeHistoryNode();
    }
  }

  hideBubble() {
    this.bubble.dispose();
  }

  getBubblePoint() {
    return this.bubble.position
      ? RightToLeftHand(this.bubble.position)
      : undefined;
  }

  getComponentInfos(globalIds: string[]) {
    return this.visoLoader.ifcManager.getComponentInfos(globalIds);
  }

  setSectionSpaceColor(color?: Color) {
    this.visoLoader.ifcManager.setSectionSpaceColor(color);
  }

  private closeModels() {
    this.context.items.ifcModels = [];
    this.context.items.pickableIfcModels = [];
  }

  private addIfcModel(ifcMesh: IFCModel) {
    this.context.items.ifcModels.push(ifcMesh);
    this.context.calculateBoundingBox();
    if (ifcMesh.solid != null) {
      this.context.items.pickableIfcModels.push(ifcMesh.solid);
    }
    this.context.getScene().add(ifcMesh);
  }

  private setupThreeMeshBVH() {
    this.visoLoader.ifcManager.setupThreeMeshBVH(
      computeBoundsTree,
      disposeBoundsTree,
      acceleratedRaycast
    );
  }

  private async getObjectMap(type: VisoDataType, ids: string[], token: any) {
    let response = await this.service.getMsgpack(type, ids, token);

    let objectMap: VisoObjectMap = {};
    response.forEach((s) => {
      objectMap[s.id] = s;
    });

    return objectMap;
  }
}
