import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import useCurrentUserQueryKey from 'users/hooks/useCurrentUserQueryKey';
import User from 'users/types/User';

export default function useCurrentUserQueryData() {
  const queryClient = useQueryClient();
  const queryKey = useCurrentUserQueryKey();
  return useCallback(async () => await queryClient.fetchQuery<User | undefined>({ queryKey }), [queryClient, queryKey]);
}
