import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import chroma from "chroma-js";
import { TagService } from "../../Services";
import { SketchPicker } from "react-color";
import { ClickAwayListener } from "@mui/base";

const { MenuList } = components;

const CustomMenuList = ({ selectProps, ...props }) => {
  const {
    onInputChange,
    inputValue,
    onMenuInputFocus,
    onChange,
    value,
    options,
  } = selectProps;

  // Copied from source
  const ariaAttributes = {
    "aria-autocomplete": "list",
    "aria-label": selectProps["aria-label"],
    "aria-labelledby": selectProps["aria-labelledby"],
  };

  const searchSectionStyle = {
    padding: "10px",
    borderBottom: "1px solid #EDEDED",
  };

  const { t } = useTranslation();
  const [isInTags, setIsInTags] = useState(false);
  const { projectId } = useParams();
  const [tags, setTags] = useState(
    options.map((option) => ({
      ...option,
      id: option.tagId,
    }))
  );
  const [newTagName, setNewTagName] = useState("");
  const defaultColorForNewTag =
    tags.length >= TagService.TagColors.length
      ? TagService.TagColors[
          tags.length -
            parseInt(tags.length / TagService.TagColors.length) *
              TagService.TagColors.length
        ]
      : TagService.TagColors[tags.length];
  const [colorForNewTag, setColorForNewTag] = useState(defaultColorForNewTag);
  const [changedColor, setChangedColor] = useState(false);

  const handleRemoveValue = useCallback(
    (e, removeIndex, addedValue) => {
      e.stopPropagation();
      addedValue.splice(removeIndex, 1);
      onChange(addedValue);
    },
    [onChange]
  );

  useEffect(() => {
    const canBeAdded = props.options.some(
      (item) => item.label.toLowerCase() === inputValue.toLowerCase()
    );
    setIsInTags(canBeAdded);
  }, [inputValue]);

  const onAddMore = useCallback(
    async (e) => {
      e.stopPropagation();

      //create new tags
      const creationParam = {
        projectId: projectId,
        name: { value: newTagName },
        color: { value: colorForNewTag },
      };

      const response = await TagService.createNewTag(creationParam);
      if (response?.length > 0) {
        const buffer = tags.filter((tag) => !!tag.id);
        buffer.push(...response);
        setTags(buffer);
      }
    },
    [tags, newTagName, colorForNewTag, projectId]
  );

  const [isVisibleColorPicker, setVisibleColorPicker] = useState(false);
  const [pickedColor, setPickedColor] = useState({
    index: 0,
    color: defaultColorForNewTag,
  });
  const handleChangeColor = useCallback(
    (color) => {
      setPickedColor({ ...pickedColor, color: color.hex });
    },
    [pickedColor, defaultColorForNewTag]
  );

  const handleShowPicker = useCallback((index, defaultColorForNewTag) => {
    setVisibleColorPicker(true);
    setPickedColor({ index, defaultColorForNewTag });
  }, []);

  useEffect(() => {
    setColorForNewTag(defaultColorForNewTag);
    pickedColor.color = defaultColorForNewTag;
  }, [defaultColorForNewTag]);

  return (
    <>
      <div className="search_section" style={searchSectionStyle}>
        <input
          className="input input--shadow"
          autoCorrect="off"
          autoComplete="off"
          spellCheck="false"
          type="text"
          value={inputValue}
          onChange={(e) => {
            onInputChange(e.currentTarget.value, {
              action: "input-change",
            });
            setNewTagName(e.currentTarget.value);
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.target.focus();
          }}
          onTouchEnd={(e) => {
            e.stopPropagation();
            e.target.focus();
          }}
          onFocus={onMenuInputFocus}
          placeholder={t("search_tags", "Search Tags")}
          {...ariaAttributes}
        />

        <div className="tag-list custom-scrollbar">
          {value?.map((item, index) => (
            <div key={`tag_list_item_${index}`}>
              <div
                className="tag-list-item"
                style={{
                  display: "inline-block",
                  color: item.color === "#ffffff" ? "#434B59" : item.color,
                  backgroundColor: chroma(item.color).alpha(0.2).css(),
                  padding: "5px 10px",
                  marginBottom: "8px",
                  fontSize: "10px",
                  cursor: "pointer",
                }}
                onClick={(e) => handleRemoveValue(e, index, [...value])}
              >
                <span style={{ marginRight: "10px" }} className="text-ellipsis">
                  {item.label}
                </span>
                <span>X</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      {!isInTags ? (
        inputValue === "" ? null : (
          <div>
            <div className="add-tag-from-dropdown">
              <div className="tag_text">{inputValue}</div>
              <div className="tags_create_btns">
                <div
                  className="tags-content-item-picker"
                  style={{
                    backgroundColor: !changedColor
                      ? `${defaultColorForNewTag}33`
                      : `${colorForNewTag}33`,
                  }}
                  onClick={() => {
                    handleShowPicker(defaultColorForNewTag);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <path
                      d="M9.52986 0.44835C8.93221 -0.149331 7.96318 -0.149504 7.36512 0.448153L6.95198 0.861295C6.73005 0.639389 6.37059 0.639389 6.14868 0.861295C5.92673 1.08303 5.92673 1.44289 6.14868 1.66462L6.28253 1.79854L1.8195 6.2619H1.8191L0.269912 7.81124C0.146787 7.93436 0.0742024 8.09906 0.0663928 8.27299L0.0626858 8.35564C0.0626858 8.35603 0.0626858 8.35638 0.0626858 8.35677L0.000184356 9.75815C-0.00238589 9.82322 0.0220067 9.88629 0.0678757 9.93233C0.11115 9.97581 0.170315 10 0.231729 10C0.235065 10 0.238377 10 0.242109 9.9998L0.985107 9.96691H0.985503L1.43821 9.94677L1.64378 9.93772C1.87122 9.92754 2.08725 9.83246 2.24851 9.6714L8.20204 3.71805L8.3134 3.82938C8.42436 3.9403 8.56973 3.99578 8.71505 3.99578C8.86039 3.99578 9.00578 3.9403 9.11672 3.82938C9.33865 3.60763 9.33865 3.24777 9.11672 3.02606L9.52984 2.61289C10.1275 2.01524 10.1275 1.0462 9.52986 0.44835ZM5.23066 5.61818L3.13986 6.01261L6.55033 2.60199L7.39881 3.45008L5.23066 5.61818Z"
                      style={{
                        fill: !changedColor
                          ? defaultColorForNewTag
                          : colorForNewTag,
                      }}
                    />
                  </svg>
                </div>
                <div
                  className="btn_add_new_tag"
                  onClick={(e) => {
                    onAddMore(e);
                    setChangedColor(false);
                  }}
                >
                  {t("add_plus", "+")}
                </div>
              </div>
            </div>
            {isVisibleColorPicker && (
              <ClickAwayListener
                onClickAway={() => setVisibleColorPicker(false)}
              >
                <SketchPicker
                  color={
                    changedColor ? pickedColor.color : defaultColorForNewTag
                  }
                  onChange={(color) => {
                    handleChangeColor(color);
                    setChangedColor(true);
                  }}
                  onChangeComplete={(color) => {
                    setColorForNewTag(color.hex);
                    setChangedColor(true);
                  }}
                />
              </ClickAwayListener>
            )}
          </div>
        )
      ) : null}
      <MenuList {...props} selectProps={selectProps} />
    </>
  );
};

const Option = (props) => {
  const tag = props.options.filter(
    (item) => props.value === item.value && item.tagId
  );
  const fileType = props.options.filter(
    (item) => props.value === item.value && item.fileType
  );

  return (
    <components.Option {...props}>
      {tag.length || fileType.length ? (
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
      ) : (
        ""
      )}
      <label className="text-ellipsis">{props.label}</label>
    </components.Option>
  );
};

const tagStyles = {
  option: (styles) => {
    return {
      ...styles,
      ...{
        display: "flex",
        padding: "0 10px",
        margin: "10px 0",
        height: "24px",
        justifyContent: "flex-start",
        alignItems: "center",
        input: {
          width: "15px",
          height: "15px",
          margin: "0 15px 0 0",
        },
        label: {
          fontSize: "14px !important",
          lineHeight: "20px",
          fontWeight: "normal",
          padding: "0",
          margin: "0",
        },
      },
    };
  },
  menu: ({ styles }) => {
    return {
      ...styles,
      position: "absolute",
      width: "100%",
    };
  },
  menuList: ({ styles }) => {
    return {
      ...styles,
      minWidth: "110px",
      overflow: "auto",
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: `${color.alpha(0.2).css()} !important`,
      fontSize: "14px !important",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color === "#ffffff" ? "#434B59" : data.color,
    fontSize: "10px !important",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color === "#ffffff" ? "#434B59" : data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  valueContainer: (styles) => {
    return {
      ...styles,
      ...{
        height: "32px",
        display: "flex",
        flexWrap: "nowrap",
        "> div": {
          height: "20px",
        },
        ".num-wrapper": {
          borderRadius: "6px",
          padding: "0 3px",
          lineHeight: "20px",
        },
      },
    };
  },
};

const TagSelect = ({
  id,
  values,
  onChange,
  value,
  isBackgroundVisible = true,
  visibleValueCount = 1,
  isGrey = false,
  menuPlacement = "auto",
  isDisabled = false,
  isCreationHide = false,
  classNames = "",
  isDisableAddTag = false,
}) => {
  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const onDomClick = (e) => {
    let menu = containerRef.current.querySelector(".select__menu");
    if (
      !containerRef.current.contains(e.target) ||
      !menu ||
      !menu.contains(e.target)
    ) {
      setIsFocused(false);
      setInputValue("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onDomClick);

    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);

  const handleDropdownSelect = useCallback(
    (item) => {
      onChange(item);
      setIsFocused(false);
    },
    [onChange]
  );

  const tagClassName = useMemo(() => {
    let name =
      "tag-select custom-select select--light select-width-full select--multi";
    if (isCreationHide) name += " select--creation-hide";
    if (!isBackgroundVisible) name += " select--no-background";
    if (isGrey) name += " select--grey";
    else name += " select--model";
    if (visibleValueCount === 2 && value?.length > 1)
      name += " tag-select-item-half tags-second-option";
    if (isDisableAddTag) name += " disable-add-tag";
    if (visibleValueCount > 1 && value?.length > 1) {
      name += ` tags-${visibleValueCount}-option`;
    }
    return name;
  }, [
    isCreationHide,
    isBackgroundVisible,
    isGrey,
    visibleValueCount,
    value?.length,
    isDisableAddTag,
  ]);
  const { t } = useTranslation();
  return (
    <div ref={containerRef} className={tagClassName} id={id}>
      <Select
        className={`select__input ${classNames}`}
        classNamePrefix="select"
        options={values}
        components={{
          MenuList: CustomMenuList,
          Option,
        }}
        placeholder={<div>{t("select_ellipsis", "select...")}</div>}
        styles={tagStyles}
        value={value}
        inputValue={inputValue}
        isSearchable={false}
        isClearable={false}
        closeMenuOnSelect={false}
        menuPlacement={menuPlacement}
        onMenuInputFocus={() => setIsFocused(true)}
        onChange={handleDropdownSelect}
        onInputChange={(val) => setInputValue(val)}
        {...{
          menuIsOpen: isFocused || undefined,
          isFocused: isFocused || undefined,
        }}
        isMulti
        isDisabled={isDisabled}
      />
      {Array.isArray(value) && value.length > visibleValueCount ? (
        <span className="values-more">{`+ ${
          value.length - visibleValueCount
        }`}</span>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TagSelect;
