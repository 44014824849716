import { useQueryClient } from '@tanstack/react-query';
import LabelDto from 'labels/types/LabelDto';
import useLabelsOdataQueryKey from 'labels/hooks/useLabelsOdataQueryKey';
import { QueryOptions } from 'odata-query';
import { useCallback } from 'react';

export default function useLabelsOdataQueryData() {
  const queryClient = useQueryClient();
  const { getQueryKey } = useLabelsOdataQueryKey();
  return useCallback(async (odataQuery: Partial<QueryOptions<LabelDto>>) => {
    const queryKey = getQueryKey(odataQuery);
    return await queryClient.fetchQuery<LabelDto[]>({ queryKey });
  }, [getQueryKey, queryClient]);
}
