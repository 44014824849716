import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';
import useIssuesOdataQuery from 'issues/hooks/useIssuesOdataQuery';
import LinkedIssueItem from 'issues/components/IssueItemCard';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useDocumentScopeContext from 'documents/hooks/useDocumentScopeContext';
import AnnotationItem from 'documents-annotations/types/AnnotationItem';
import LinkIssueMenuButton from 'documents-details/components/LinkIssueMenuButton';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import IssueQuickFilterBar from 'issues/components/IssueQuickFilterBar';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import useDocumentVersionQuery from 'documents/hooks/useDocumentVersionQuery';

interface LinkedIssuesTabProps extends ISxProps {
}

export default function LinkedIssuesTab({
  sx,
}: LinkedIssuesTabProps) {
  const theme = useTheme();
  const { t } = useTranslation('documents-details');
  const { setIssueId, documentVersionId } = useDocumentScopeContext();
  const { data: documentVersion } = useDocumentVersionQuery(documentVersionId);
  const { odataQuery, setDocumentId } = useIssuesFilterContext();
  const { annotationItems, setSelectedAnnotationNames } = useDocumentViewerContext();

  useEffect(() => {
    setDocumentId(documentVersion?.documentId);
  }, [documentVersion?.documentId, setDocumentId]);

  const annotationItemsByIssueId = useMemo(() => {
    const resultMap = new Map<string, AnnotationItem[]>();
    if (!annotationItems) return resultMap;
    annotationItems.forEach((annotationItem) => {
      if (!annotationItem.visoplanAnnotation?.linkedIssueId) return;
      const prevAnnotations = resultMap.get(annotationItem.visoplanAnnotation.linkedIssueId) ?? [];
      resultMap.set(annotationItem.visoplanAnnotation.linkedIssueId, prevAnnotations.concat(annotationItem));
    });
    return resultMap;
  }, [annotationItems]);

  const { data: issues } = useIssuesOdataQuery(odataQuery);

  const onClickIssue = useCallback((issueId: string) => {
    setIssueId(issueId);
    const items = annotationItemsByIssueId.get(issueId);
    if (!items) return;
    const names = items.map((item) => item.viewerAnnotation.Id);
    setSelectedAnnotationNames((prev) => (isEqual(prev, names) ? prev : names));
  }, [annotationItemsByIssueId, setIssueId, setSelectedAnnotationNames]);

  return (
    <Box id="LinkedIssuesTab" sx={{ ...sx, display: 'flex', flexDirection: 'column', overflow: 'hidden', pt: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4" sx={{ flexGrow: 1 }}>{t('linked-issues-tab_tab-header', 'Linked Issues')}</Typography>
          <LinkIssueMenuButton />
        </Box>
        <IssueQuickFilterBar />
      </Box>
      <Box sx={{ flex: '1 1 0', display: 'flex', background: theme.palette.grey[200], flexDirection: 'column', overflow: 'auto', p: 2, gap: 2, boxShadow: 'inset 0px 24px 24px -24px rgba(0,0,0,0.1)' }}>
        {!!issues && issues.map((issue) => <LinkedIssueItem key={issue.id} issue={issue} onClick={onClickIssue} />)}
        {!issues && <CenteredCircularProgress />}
      </Box>
    </Box>
  );
}
