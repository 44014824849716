import HardcodedIssueFilter from 'issues/types/HardcodedIssueFilter';
import { closedIssueStatusFilterPredicate } from 'issues/hooks/useClosedIssueStatus';
import { draftIssueStatusFilterPredicate } from 'issues/hooks/useDraftIssueStatus';
import { inReviewIssueStatusFilterPredicate } from 'issues/hooks/useInReviewIssueStatus';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import useProjectIssueStatusesQueryData from 'issues/hooks/useProjectIssueStatusesQueryData';
import LabelDto from 'labels/types/LabelDto';
import { Filter, ITEM_ROOT } from 'odata-query';
import { useCallback, useMemo } from 'react';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import useCurrentUserQueryData from 'users/hooks/useCurrentUserQueryData';
import { DateInterval } from 'issues/contexts/IssuesFilterContextProvider';

function buildOdataQueryFilter(
  quickFilter: string | undefined,
  closedIssueStatus: LabelDto,
  draftIssueStatus: LabelDto,
  inReviewIssueStatus: LabelDto,
  currentUserId: string,
  hardcodedFilter: HardcodedIssueFilter,
  documentId?: string | undefined,
  dateInterval?: DateInterval,
) {
  const filter: Filter = [];

  if (hardcodedFilter === HardcodedIssueFilter.AllActiveIssues) {
    filter.push({ not: { issueStatus: { id: { in: [closedIssueStatus.id, draftIssueStatus.id] } } } });
  }
  if (hardcodedFilter === HardcodedIssueFilter.AssignedToMe) {
    filter.push({ not: { issueStatus: { id: { in: [closedIssueStatus.id, draftIssueStatus.id] } } } });
    filter.push({ assignedUserIds: { any: { [ITEM_ROOT]: currentUserId } } });
  }
  if (hardcodedFilter === HardcodedIssueFilter.ForReview) {
    filter.push({ issueStatus: { id: inReviewIssueStatus.id } });
    filter.push({ reviewerId: currentUserId });
  }
  if (hardcodedFilter === HardcodedIssueFilter.CreatedByMe) {
    filter.push({ not: { issueStatus: { id: { in: [closedIssueStatus.id, draftIssueStatus.id] } } } });
    filter.push({ createAuthorId: currentUserId });
  }
  if (hardcodedFilter === HardcodedIssueFilter.MyDrafts) {
    filter.push({ issueStatus: { id: draftIssueStatus.id } });
    filter.push({ createAuthorId: currentUserId });
  }
  if (hardcodedFilter === HardcodedIssueFilter.Closed) {
    filter.push({ issueStatus: { id: closedIssueStatus.id } });
  }

  if (documentId) {
    filter.push({ linkedDocumentIds: { any: { [ITEM_ROOT]: documentId } } });
  }

  if (dateInterval) {
    filter.push({
      or: [
        {
          // issues starting within the interval
          startingDate: {
            ge: dateInterval.start,
            le: dateInterval.end,
          },
        },
        {
          // issues due within the interval
          dueDate: {
            ge: dateInterval.start,
            le: dateInterval.end,
          },
        },
        {
          // issues starting before and ending after the interval
          startingDate: { le: dateInterval.end },
          dueDate: { ge: dateInterval.start },
        },
      ],
    });
  }

  if (quickFilter?.trim().length) {
    const quickFilterTrimmedLowerCase = quickFilter.trim().toLocaleLowerCase();
    const numericQuickFilter = /^\d+$/.test(quickFilterTrimmedLowerCase) ? parseInt(quickFilterTrimmedLowerCase, 10) : undefined;
    filter.push({ or: [
      { id: quickFilterTrimmedLowerCase },
      { 'tolower(title)': { contains: quickFilterTrimmedLowerCase } },
      { issueNumber: !Number.isNaN(numericQuickFilter) ? numericQuickFilter : undefined },
      // TODO: also search in assignee and reviewer names
    ] });
  }

  return filter;
}

export default function useIssueOdataQueryFilterBuilder() {
  const { data: issueStatusesData } = useProjectIssueStatusesQuery();
  const getIssueStatuses = useProjectIssueStatusesQueryData();
  const { data: currentUserData } = useCurrentUserQuery();
  const getCurrentUser = useCurrentUserQueryData();

  const closedIssueStatusData = useMemo(() => issueStatusesData?.find(closedIssueStatusFilterPredicate), [issueStatusesData]);
  const draftIssueStatusData = useMemo(() => issueStatusesData?.find(draftIssueStatusFilterPredicate), [issueStatusesData]);
  const inReviewIssueStatusData = useMemo(() => issueStatusesData?.find(inReviewIssueStatusFilterPredicate), [issueStatusesData]);
  const buildIssueOdataFilterQuery = useCallback((hardcodedFilter: HardcodedIssueFilter, quickFilter?: string | undefined, documentId?: string | undefined, dateInterval?: DateInterval) => {
    if (!closedIssueStatusData || !draftIssueStatusData || !inReviewIssueStatusData || !currentUserData) return undefined;
    return buildOdataQueryFilter(quickFilter, closedIssueStatusData, draftIssueStatusData, inReviewIssueStatusData, currentUserData.id, hardcodedFilter, documentId, dateInterval);
  }, [closedIssueStatusData, currentUserData, draftIssueStatusData, inReviewIssueStatusData]);

  const buildIssueOdataQueryFilterAsync = useCallback(async (hardcodedFilter: HardcodedIssueFilter, quickFilter?: string | undefined, documentId?: string | undefined, dateInterval?: DateInterval) => {
    const issueStatusesAsync = await getIssueStatuses();
    const closedIssueStatusAsync = issueStatusesAsync.find(closedIssueStatusFilterPredicate);
    const draftIssueStatusAsync = issueStatusesAsync.find(draftIssueStatusFilterPredicate);
    const inReviewIssueStatusAsync = issueStatusesAsync.find(inReviewIssueStatusFilterPredicate);
    if (!closedIssueStatusAsync || !draftIssueStatusAsync || !inReviewIssueStatusAsync) throw new Error('Missing a default issue status');
    const currentUserAsync = await getCurrentUser();
    if (!currentUserAsync) throw new Error('Failed to request current user');
    return buildOdataQueryFilter(quickFilter, closedIssueStatusAsync, draftIssueStatusAsync, inReviewIssueStatusAsync, currentUserAsync.id, hardcodedFilter, documentId, dateInterval);
  }, [getCurrentUser, getIssueStatuses]);

  return useMemo(() => ({
    buildIssueOdataFilterQuery,
    buildIssueOdataQueryFilterAsync,
  }), [buildIssueOdataFilterQuery, buildIssueOdataQueryFilterAsync]);
}
