import React from "react";
import { useTranslation } from "react-i18next";

//components
import CustomSelect from "../CustomSelect";
import TagSelect from "../TagSelect";

const FilterItems = {
  Assigned: "assignedTo",
  Reviewer: "reviewer",
  Priority: "priority",
  Type: "type",
  Tags: "tags",
  WorkPhase: "workphase",
  Status: "status",
};

const FilterSection = ({
  filterValue,
  tagOptions,
  issueFilterItems,
  resetFilters,
  handleFilterChange,
}) => {
  const { t } = useTranslation();
  const { statuses, types, priorities, workphases, users } = issueFilterItems;

  return (
    <div className="content__group-top content__calendar-filter">
      <div className="content__calendar-filter-label-group">
        <label className="content__calendar-filter-title">
          {t("filter", "Filter")}
        </label>
        <label
          id="ResetFilterTimelineButton"
          className="content__calendar-filter-label"
          onClick={(e) => resetFilters(e)}
        >
          {t("reset_filters", "Reset filters")}
        </label>
      </div>
      <div className="content__calendar-filter-control">
        <div className="content__calendar-filter-control-item">
          <label>{t("assigned", "Assigned")}</label>
          <CustomSelect
            id="AssignedTimelineFilterDropdown"
            values={users}
            onChange={(value) =>
              handleFilterChange(FilterItems.Assigned, value)
            }
            value={filterValue.assignedTo}
            isOptional={true}
            isBackgroundVisible={false}
            isMulti={true}
          />
        </div>
        <div className="content__calendar-filter-control-item">
          <label>{t("reviewer", "Reviewer")}</label>
          <CustomSelect
            id="ReviewerTimelineFilterButton"
            values={users}
            onChange={(value) =>
              handleFilterChange(FilterItems.Reviewer, value)
            }
            value={filterValue.reviewer}
            isOptional={true}
            isBackgroundVisible={false}
            isClearable={true}
          />
        </div>
        <div className="content__calendar-filter-control-item">
          <label>{t("priority", "Priority")}</label>
          <CustomSelect
            id="PriorityTimelineFilterButton"
            values={priorities}
            onChange={(value) =>
              handleFilterChange(FilterItems.Priority, value)
            }
            value={filterValue.priority}
            isOptional={true}
            isBackgroundVisible={false}
            isClearable={true}
          />
        </div>
        <div className="content__calendar-filter-control-item">
          <label>{t("type", "Type")}</label>
          <CustomSelect
          id="TypeTimelineFilterButton"
            values={types}
            onChange={(value) => handleFilterChange(FilterItems.Type, value)}
            value={filterValue.type}
            isOptional={true}
            isBackgroundVisible={false}
            isClearable={true}
          />
        </div>
        <div className="content__calendar-filter-control-item">
          <label>{t("tags", "Tags")}</label>
          <TagSelect
            id="TagsTimelineFilterButton"
            values={tagOptions}
            onChange={(value) => handleFilterChange(FilterItems.Tags, value)}
            value={filterValue.tags}
            isBackgroundVisible={false}
            isOptional={true}
            isDisableAddTag={true}
          />
        </div>
        <div className="content__calendar-filter-control-item">
          <label>{t("workphase", "Workphase")}</label>
          <CustomSelect
            id="WorkphaseTimelineFilterButton"
            values={workphases}
            onChange={(value) =>
              handleFilterChange(FilterItems.WorkPhase, value)
            }
            value={filterValue.workphase}
            isOptional={true}
            isBackgroundVisible={false}
            isClearable={true}
          />
        </div>
        <div className="content__calendar-filter-control-item">
          <label>{t("status", "Status")}</label>
          <CustomSelect
            id="StatusTimelineFilerButton"
            values={statuses}
            onChange={(value) => handleFilterChange(FilterItems.Status, value)}
            value={filterValue.status}
            isOptional={true}
            isBackgroundVisible={false}
            isClearable={true}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
