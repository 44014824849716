import IssueOld from 'issues/types/IssueOld';

export default function useAllNestedChildrenIds() {
  const getNestedChildrenIds = (id: string, issues: IssueOld[], destination: string[]) => {
    const issue = issues.find((item) => item.id === id);
    if (!issue) return;
    destination.push(issue.id);
    issue.childrenIds.forEach((childId: string) => getNestedChildrenIds(childId, issues, destination));
  };

  const getAllNestedChildrenIds = (issue: IssueOld, issues: IssueOld[]) => {
    const childrenIssueIds: string[] = [];
    childrenIssueIds.push(issue.id);
    issue.childrenIds.forEach((id: string) => getNestedChildrenIds(id, issues, childrenIssueIds));
    return childrenIssueIds;
  };

  return getAllNestedChildrenIds;
}
