import React, { useCallback, useMemo, useState } from 'react';
import IChildren from 'common/types/IChildren';
import IssuesSelectionContext, { IssuesSelectionContextState } from 'issues/contexts/IssuesSelectionContext';

export default function IssuesSelectionContextProvider({
  children,
}: IChildren) {
  const [selectedIssueIds, setSelectedIssueIds] = useState<string[]>([]);
  const selectedIssueIdsSet = useMemo(() => new Set(selectedIssueIds), [selectedIssueIds]);
  const toggleSelection = useCallback((issueId: string) => {
    if (selectedIssueIdsSet.has(issueId)) {
      setSelectedIssueIds(selectedIssueIds.filter((id) => id !== issueId));
    } else {
      setSelectedIssueIds([...selectedIssueIds, issueId]);
    }
  }, [selectedIssueIds, selectedIssueIdsSet]);
  const value = useMemo<IssuesSelectionContextState>(() => ({
    selectedIssueIds,
    setSelectedIssueIds,
    selectedIssueIdsSet,
    toggleSelection,
  }), [selectedIssueIds, selectedIssueIdsSet, toggleSelection]);
  return <IssuesSelectionContext.Provider value={value}>{children}</IssuesSelectionContext.Provider>;
}
