import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import DocumentListDto from 'documents-lists/types/DocumentListDto';
import DocumentListUpdateAccessDto from 'documents-lists/types/DocumentListUpdateAccessDto';
import { useCallback } from 'react';

export default function useDocumentListUpdateAccessMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { baseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentList);
  const mutationFn = useCallback(async (dto: DocumentListUpdateAccessDto) => {
    const { data } = await axiosInstance.patch<DocumentListDto>(`${ApiEndpoint.DocumentList}/access`, dto);
    return data;
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: baseQueryKey });
  }, [baseQueryKey, queryClient]);
  return useMutation<DocumentListDto | undefined, unknown, DocumentListUpdateAccessDto>({
    mutationFn,
    onSuccess,
  });
}
