import React, { useMemo } from 'react';
import { Box, Button } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import useDraftIssueStatus from 'issues/hooks/useDraftIssueStatus';
import { mdiSend } from '@mdi/js';
import Icon from '@mdi/react';

interface PublishActionButtonsProps extends ISxProps {
  issueId: string,
  onClickPublish: () => void,
}

export default function PublishActionButtons({
  sx,
  issueId,
  onClickPublish,
}: PublishActionButtonsProps) {
  const { t } = useTranslation('issues');
  const { data: currentUser } = useCurrentUserQuery();
  const { data: issue, isLoading: isLoadingIssue } = useIssueQuery(issueId);
  const isCurrentUserCreator = useMemo(() => (issue?.createAuthorId && currentUser && issue.createAuthorId === currentUser.id), [currentUser, issue]);
  const draftIssueStatus = useDraftIssueStatus();

  const canDoPublishActions = useMemo(() => {
    if (!issue?.issueStatus || !draftIssueStatus) return undefined;
    return issue.issueStatus.id === draftIssueStatus.id && isCurrentUserCreator;
  }, [isCurrentUserCreator, issue, draftIssueStatus]);

  if (!issueId || !canDoPublishActions) return null;
  return (
    <Box id="PublishActionButtons" sx={{ ...sx }}>
      <Button
        variant="contained"
        color="secondary"
        onClick={onClickPublish}
        disabled={!canDoPublishActions || isLoadingIssue}
        sx={{ gap: 1 }}
        size="small"
      >
        <Icon path={mdiSend} size={0.5} />
        {t('publish-action-buttons_publish', 'Assign and publish')}
      </Button>
    </Box>
  );
}
