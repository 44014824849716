import React from 'react';
import DocumentsPrintModal from 'Components/DocumentsPrintModal';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';

interface PrintWizardProps {
  documentVersionIds: string[],
  open: boolean,
  onClose: () => void,
}

export default function PrintWizard({
  documentVersionIds,
  open,
  onClose,
}: PrintWizardProps) {
  const { data: documentVersions } = useDocumentVersionsQuery(documentVersionIds ? { filter: { id: { in: documentVersionIds } } } : undefined);
  const { data: currentUser } = useCurrentUserQuery();
  const { data: currentProject } = useCurrentProjectQuery();
  if (!open || !documentVersions || !currentUser || !currentProject) return null;
  return (
    <DocumentsPrintModal
      documents={documentVersions}
      currentUser={currentUser}
      users={currentProject?.userPreviews}
      onClose={onClose}
    />
  );
}
