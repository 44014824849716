import { Dispatch, SetStateAction, createContext } from 'react';

export interface IssuesSelectionContextState {
  selectedIssueIds: string[],
  setSelectedIssueIds: Dispatch<SetStateAction<string[]>>,
  toggleSelection: (issueId: string) => void,
  selectedIssueIdsSet: ReadonlySet<string>,
}

const defaultState: IssuesSelectionContextState = {
  selectedIssueIds: [],
  setSelectedIssueIds: () => {},
  toggleSelection: () => {},
  selectedIssueIdsSet: new Set(),
};

const IssuesSelectionContext = createContext<IssuesSelectionContextState>(defaultState);
export default IssuesSelectionContext;
