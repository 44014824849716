import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { orderBy } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { SketchPicker } from "react-color";
import { Switch } from '@mui/material';
import { ClickAwayListener } from '@mui/base';

import { AddMoreButton } from "Components/Buttons";
import TagCreationItem from "Components/TagCreationItem";
import ChangeStatusFeedback from "Components/ChangeStatusFeedback";
import NotificationModal from "Components/NotificationModal";
import Loader from "Components/Loader";

import * as Utils from "Helpers/utils";
import {
  DocumentStatusService,
  EncodingService,
  ProjectService,
  TagService,
} from "Services";
import CustomSelect from 'Components/CustomSelect';
import StampExample from 'Components/ProjectSettingConfig/StampExample';
import { updateProjectDocumentStampSettings } from 'Services/ProjectService';
import i18next from "i18next";
import useCurrentProjectQuery from "projects/hooks/useCurrentProjectQuery";
import { useQueryClient } from "@tanstack/react-query";
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useCurrentUserRole from "users/hooks/useCurrentUserRole";
import RoleAction from "projects/types/RoleAction";
import useLegacySettingsNotificationDialogContext from 'settings/hooks/useLegacySettingsNotificationDialogContext';

const DocumentSetting = () => {
  const { saveChangesNotification, setSaveChangesNotification, setHasUnsavedChanges } = useLegacySettingsNotificationDialogContext();
  const { t } = useTranslation("settings");
  const { data: currentProject } = useCurrentProjectQuery();
  const currentUserRole = useCurrentUserRole();
  const POSITION_OPTIONS = useMemo(() => ([
    { value: 0, label: t('top_left', 'Top left') },
    { value: 1, label: t('top_right', 'Top right') },
    { value: 2, label: t('bottom_left', 'Bottom left') },
    { value: 3, label: t('bottom_right', 'Bottom right') }
  ]), [t]);
  const dispatch = useDispatch();
  const [initialDocStatuses, setInitialDocStatuses] = useState([]);
  const [docStatuses, setDocStatuses] = useState([]);
  const [uploadStatusIndex, setUploadStatusIndex] = useState("");
  const [isEnableSave, setIsEnableSave] = useState(false);
  const [isVisibleColorPicker, setVisibleColorPicker] = useState(false);
  const [pickedColor, setPickedColor] = useState({
    id: "",
    color: "",
  });
  const [errorMsgs, setErrorMsgs] = useState([]);
  const [isVisibleSavedFeedback, setVisibleSavedFeedback] = useState(false);
  const [notification, setNotification] = useState(null);
  const [selectedColorPickerRef, setSelectedColorPickerRef] = useState(null);
  const [otherStatusId, setOtherStatusId] = useState(null);
  const [isUseLetterVersioning, setIsUseLetterVersioning] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [apiError, setApiError] = useState("");
  const [stampSettings, setStampSettings] = useState(null);

  const bEnableActions = useMemo(() => {
    if (!currentUserRole) return undefined;
    return {
      DocStatus_Create_Edit: currentUserRole.allowedActions.has(RoleAction.DocStatus_Create_Edit),
      DocStatus_Delete: currentUserRole.allowedActions.has(RoleAction.DocStatus_Delete),
      DocStamp_Edit: currentUserRole.allowedActions.has(RoleAction.DocStamp_Edit),
    };
  }, [currentUserRole]);

  const isExistError = useMemo(
    () => errorMsgs.some((error) => !!error),
    [errorMsgs]
  );

  const fetchData = async () => {
    if (!currentProject) return;

    const buffer = await DocumentStatusService.getDocumentStatus({
      projectId: currentProject.id,
    });
    if (!buffer.length) return;
    const otherStatusIndex = buffer.findIndex(
      (item) => item.originalName === "Other"
    );
    setOtherStatusId(buffer[otherStatusIndex]?.id);
    //remove Other status
    buffer.splice(otherStatusIndex, 1);

    setUploadStatusIndex(
      buffer.findIndex(
        (status) => status.id === currentProject.defaultDocStatusId
      )
    );
    setDocStatuses(buffer);
    setErrorMsgs(buffer.map(() => ""));
    setInitialDocStatuses(JSON.parse(JSON.stringify(buffer)));
    setIsUseLetterVersioning(
      currentProject.namingSchemeSettings?.useLetterVersioning
    );
  };

  useEffect(() => {
    fetchData();
    setStampSettings(currentProject.stampSettings);
  }, [currentProject]);

  useEffect(() => {
    setHasUnsavedChanges(isEnableSave);
  }, [isEnableSave]);

  const queryClient = useQueryClient();
  const { baseQueryKey: projectBaseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Project);

  const onSave = useCallback(
    async (e) => {
      e?.preventDefault();
      setIsSaving(true);

      const creationStatuses = [],
        updateStatuses = [];
      docStatuses.forEach((status) => {
        if (!status.id) creationStatuses.push(status);
        else {
          const index = initialDocStatuses.findIndex(
            (item) => item.id === status.id
          );
          if (
            index >= 0 &&
            (initialDocStatuses[index].name !== status.name ||
              initialDocStatuses[index].color !== status.color)
          )
            updateStatuses.push(status);
        }
      });

      let result = false,
        createdBuffer = [...docStatuses];
      //create new statuses
      const createParams = creationStatuses.map((item) => ({
        projectId: item.projectId,
        name: item.name,
        color: item.color,
      }));
      if (createParams.length > 0) {
        const response = await DocumentStatusService.createDocumentStatus(
          createParams,
          setApiError
        );
        if (response?.length) {
          createdBuffer = createdBuffer.map((status) => {
            if (status.id) {
              return status;
            } else {
              const createdStatus = response.find(
                (item) => item.name === status.name
              );
              return {
                ...createdStatus,
                order: status.order,
              };
            }
          });
          setDocStatuses(createdBuffer);
          result = true;
        } else result = false;
      }

      //update document status order
      const descriptorIds = createdBuffer.map((status) => status.id);
      //put Other status order at the end
      descriptorIds.push(otherStatusId);

      result = DocumentStatusService.updateDocumentStatusOrder(
        {
          projectId: currentProject.id,
          descriptorIds,
        },
        setApiError
      );

      //update the statuses
      const updateParams = updateStatuses.map((item) => ({
        id: item.id,
        name: item.name,
        color: item.color,
      }));
      if (updateParams.length > 0)
        result = await DocumentStatusService.updateDocumentStatus(updateParams, setApiError);

      const uploadStatusId = createdBuffer.find(
        (item) => item.order - 1 === uploadStatusIndex
      )?.id;

      if (uploadStatusId !== currentProject.defaultDocStatusId) {
        result = await ProjectService.updateProject([
          {
            id: currentProject.id,
            defaultDocStatusId: {
              value: createdBuffer[uploadStatusIndex]?.id,
            },
          },
        ]);
      }

      if (
        isUseLetterVersioning ===
        currentProject.namingSchemeSettings?.useLetterVersioning
      )
        result = true;
      else {
        result = await EncodingService.updateNamingSettings(
          currentProject.id,
          {
            useLetterVersioning: isUseLetterVersioning,
          },
          setApiError
        );
      }

      if (!currentProject.stampSettings
        || stampSettings?.isStampInsertionOnDownloadEnabled != currentProject.stampSettings?.isStampInsertionOnDownloadEnabled
        || stampSettings?.position != currentProject.stampSettings?.position
        || stampSettings?.locale != currentProject.stampSettings?.locale) {

        const updateStampSettingsResult = await updateProjectDocumentStampSettings(currentProject.id, stampSettings);
      }

      if (result) {
        setVisibleSavedFeedback(true);
        setTimeout(() => {
          setVisibleSavedFeedback(false);
        }, 3000);
        setIsEnableSave(false);
      }
      queryClient.invalidateQueries(projectBaseQueryKey);
      setIsSaving(false);
    },
    [
      initialDocStatuses,
      docStatuses,
      uploadStatusIndex,
      currentProject,
      isUseLetterVersioning,
      stampSettings,
    ]
  );

  const getAddItemColor = () => {
    let index = docStatuses.length + 1;
    if (index >= TagService.TagColors.length) {
      index =
        index -
        parseInt(index / TagService.TagColors.length) *
          TagService.TagColors.length;
      return TagService.TagColors[index];
    } else {
      return TagService.TagColors[index];
    }
  };

  const onAddMore = useCallback(() => {
    setIsEnableSave(true);
    setDocStatuses(
      Utils.addArrayItem(docStatuses, {
        projectId: currentProject.id,
        name: "",
        color: getAddItemColor(),
        order: docStatuses.length + 1,
      })
    );
    setErrorMsgs(Utils.addArrayItem(errorMsgs, ""));
  }, [docStatuses, currentProject, errorMsgs]);

  const onRemoveStatus = useCallback(
    async (order) => {
      let isRemove = true;
      const index = docStatuses.findIndex((status) => status.order === order);
      if (docStatuses[index].id) {
        isRemove = await DocumentStatusService.deleteDocumentStatus(
          docStatuses[index].id
        );
      }
      if (isRemove) {
        setIsEnableSave(true);
        setVisibleSavedFeedback(true);
        setTimeout(() => {
          setVisibleSavedFeedback(false);
        }, 3000);
        setDocStatuses(Utils.removeArrayItem(docStatuses, index));
        setErrorMsgs(Utils.removeArrayItem(errorMsgs, index));
      } else {
        setNotification({
          title: t("doc_state_in_use", "Document state is in use"),
          description: "",
          doneText: "",
        });
      }
    },
    [docStatuses, errorMsgs]
  );

  const handleStatusChange = useCallback(
    (e, order) => {
      setIsEnableSave(true);
      const value = e.target.value;
      const index = docStatuses.findIndex((status) => status.order === order);
      const bufferStatuses = Utils.replaceArrayItem(
        docStatuses,
        { ...docStatuses[index], name: value },
        index
      );
      let bufferErrorMsgs = [...errorMsgs];
      bufferStatuses.forEach((item, statusIndex) => {
        if (
          bufferStatuses.findIndex(
            (innerItem, innerStatusIndex) =>
              item.name &&
              statusIndex !== innerStatusIndex &&
              innerItem.name.toLowerCase() === item.name.toLowerCase()
          ) >= 0
        )
          bufferErrorMsgs = Utils.replaceArrayItem(
            bufferErrorMsgs,
            t("choose_unique_name", "*Please choose a unique name"),
            statusIndex
          );
        else if (value.length > 25)
          bufferErrorMsgs = Utils.replaceArrayItem(
            bufferErrorMsgs,
            "too long name",
            bufferStatuses.length - 1
          );
        else
          bufferErrorMsgs = Utils.replaceArrayItem(
            bufferErrorMsgs,
            "",
            statusIndex
          );
      });
      setErrorMsgs(bufferErrorMsgs);
      setDocStatuses(bufferStatuses);
    },
    [docStatuses, errorMsgs]
  );

  const handleShowPicker = useCallback((order, color, colorPickerRef) => {
    setVisibleColorPicker(true);
    setPickedColor({ order, color });
    setSelectedColorPickerRef(colorPickerRef);
  }, []);

  const handleChangeColor = useCallback(
    (color) => {
      setPickedColor({ ...pickedColor, color: color.hex });
    },
    [pickedColor]
  );

  const handleChangeColorComplete = useCallback(
    (color) => {
      setIsEnableSave(true);
      setDocStatuses((buffer) => {
        const result = buffer.map((item) => {
          if (item.order === pickedColor.order) item.color = color.hex;
          return item;
        });
        return result;
      });
    },
    [pickedColor]
  );

  useEffect(() => {
    if (currentProject && saveChangesNotification === "discard") {
      fetchData();
      setIsEnableSave(false);
      setSaveChangesNotification("");
    }
    if (currentProject && saveChangesNotification === "save") {
      onSave();
      setSaveChangesNotification("");
    }
  }, [currentProject, saveChangesNotification]);

  const onNextOrder = useCallback(
    (status, index) => {
      if (status.order === docStatuses.length || errorMsgs.some((msg) => !!msg))
        return;
      setIsEnableSave(true);
      const { order } = status;
      const nextOrder = order + 1;
      const buffer = docStatuses.map((item) => {
        if (item.order === order) item.order = nextOrder;
        else if (item.order === nextOrder) item.order = order;
        return item;
      });
      setDocStatuses(orderBy(buffer, ["order"], ["asc"]));

      if (index === uploadStatusIndex) setUploadStatusIndex(index + 1);
      else if (index + 1 === uploadStatusIndex) setUploadStatusIndex(index);
    },
    [docStatuses, uploadStatusIndex, errorMsgs]
  );

  const onPrevOrder = useCallback(
    (status, index) => {
      if (status.order === 1 || errorMsgs.some((msg) => !!msg)) return;
      setIsEnableSave(true);
      const { order } = status;
      const prevOrder = order - 1;
      const buffer = docStatuses.map((item) => {
        if (item.order === prevOrder) item.order = order;
        else if (item.order === order) item.order = prevOrder;
        return item;
      });
      setDocStatuses(orderBy(buffer, ["order"], ["asc"]));

      if (index === uploadStatusIndex) setUploadStatusIndex(index - 1);
      else if (index - 1 === uploadStatusIndex) setUploadStatusIndex(index);
    },
    [docStatuses, uploadStatusIndex, errorMsgs]
  );

  const changeLetterVersioning = (state) => {
    setIsUseLetterVersioning(state);
    setIsEnableSave(true);
  };

  return (
    <div id="DocumentSetting" className="setting-view document-setting">
      <form onSubmit={onSave}>
        <div className="project-tags-header p-relative">
          {isVisibleSavedFeedback && (
            <ChangeStatusFeedback
              description={t(
                "document_status_saved_successfully",
                "Document status saved successfully"
              )}
            />
          )}
          {
            !!apiError &&
            <p className="error-label">
              { apiError }
            </p>
          }
          <input
            type="submit"
            className="btn btn--primary btn-save"
            value={t("save", { ns: "common", defaultValue: "Save" })}
            disabled={!isEnableSave || isExistError}
          />
          <Loader
            isSmall={true}
            isLoading={isSaving}
          />
        </div>
        <div className="project-tags-content">
          <div className="project-tags-content-left">
            <h3 className="project-tags-content-left-title">
              {t("documents", "Documents")}
            </h3>
            {(bEnableActions?.DocStatus_Create_Edit || bEnableActions?.DocStatus_Delete) && (
              <>
              <p className="project-tags-content-left-description">
                {t(
                  "document_which_status_upload",
                  "Which states your documents should have? Which state your document should have after the upload?"
                )}
              </p>
              {bEnableActions?.DocStatus_Create_Edit &&
              <AddMoreButton onClick={onAddMore} />
              }
              </>
            )}
          </div>
          <div className="project-tags-content-right">
            {/* Document Status Settings Group */}
            {(bEnableActions?.DocStatus_Create_Edit || bEnableActions?.DocStatus_Delete) && (
            <div className="content-wrapper">
              <div className="states-wrapper">
                <div className="title-group">
                  <div className="item">
                    <div className="status-item">
                      <h3 className="status-item-title">
                        {t("states", "States")}
                      </h3>
                    </div>
                    <div className="upload-status-item">
                      <label className="upload-status-item-title">
                        {t("upload_state", "Upload State")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="content-group">
                  {orderBy(docStatuses, ["order"], ["asc"]).map(
                    (status, index) => (
                      <div className="item" key={`item-${index}`}>
                        <div className="status-item">
                          <TagCreationItem
                            item={status}
                            errorMsg={errorMsgs[index]}
                            isEnableRemove={
                              bEnableActions &&
                              bEnableActions.DocStatus_Delete &&
                              !status.isDefault &&
                              index !== uploadStatusIndex
                            }
                            isEnableEdit={
                              bEnableActions &&
                              bEnableActions.DocStatus_Create_Edit &&
                              !status.isDefault
                            }
                            onChangeInput={(e) =>
                              handleStatusChange(e, status.order)
                            }
                            onClickPicker={(colorPickerRef) =>
                              handleShowPicker(
                                status.order,
                                status.color,
                                colorPickerRef
                              )
                            }
                            onRemoveItem={() => onRemoveStatus(status.order)}
                            onNextOrder={() => onNextOrder(status, index)}
                            onPrevOrder={() => onPrevOrder(status, index)}
                          />
                        </div>
                        <div className="upload-status-item">
                          {
                            // Archived state doesn't have Upload state
                            status.originalName !== "Archived" &&
                            <input
                              type="radio"
                              checked={uploadStatusIndex === index}
                              onChange={() => {
                                setIsEnableSave(true);
                                setUploadStatusIndex(index);
                              }}
                            />
                          }
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            )}
            <div className="content-wrapper">
              {/* Document Version Settings Group */}
              <div className="version-wrapper">
                <h3 className="version-content-title">
                  {t("versions", "Versions")}
                </h3>
                <div className="two-column-form-item">
                  <div className="two-column-form-item-caption">
                    {t('document_version_setting_caption', 'Define Document Version')}
                  </div>
                  <div className="two-column-form-item-controls">
                    <label>
                      <input
                        type="radio"
                        checked={!isUseLetterVersioning}
                        onChange={() => changeLetterVersioning(false)}
                      />
                      {t("numbers", "Numbers")}
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={isUseLetterVersioning}
                        onChange={() => changeLetterVersioning(true)}
                      />
                      {t('letters', 'Letters')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {(bEnableActions?.DocStamp_Edit) && (
            <div className="content-wrapper">
              {/* Document Stamp Settings Group */}
              <div className='stamp-wrapper'>
                <div className='stamp-wrapper-left'>
                  <h3 className='stamp-title'>
                    {t('document_stamp', 'Document Stamp')}
                  </h3>
                  <div className='stamp-size-info'>
                    {`${t('width', 'Width')}: 180mm, ${t('height', 'Height')}: 64mm, ${t('margin', 'Margin')}: 30mm`}
                  </div>
                  <div className='two-column-form-item'>
                    <div className='two-column-form-item-caption'>
                      {t('add_export_stamp', 'Add an export stamp')}
                    </div>
                    <div className='two-column-form-item-controls'>
                      <label>
                        <Switch
                          checked={stampSettings?.isStampInsertionOnDownloadEnabled}
                          onChange={(e) => {
                            setStampSettings((settings) => {
                              if (!settings && e.target.checked) {
                                // enabled for the first time => initialize the other stamp setting variables
                                return {
                                  isStampInsertionOnDownloadEnabled: e.target.checked,
                                  position: 3,
                                  locale: i18next.language,
                                }
                              }
                              else {
                                return { ...settings, isStampInsertionOnDownloadEnabled: e.target.checked };
                              }
                            });
                            setIsEnableSave(true);
                          }}
                          sx={{ mr: 1 }}
                        />
                        {stampSettings?.isStampInsertionOnDownloadEnabled ? t('yes', 'Yes') : t('no', 'No')}
                      </label>
                    </div>            
                  </div>
                  <div className='two-column-form-item'>
                    <div className='two-column-form-item-caption'>
                      {t('select_position', 'Select Position')}
                    </div>
                    <div className='two-column-form-item-controls'>
                      <CustomSelect
                        id="stamp_position_select"
                        isDisabled={!stampSettings?.isStampInsertionOnDownloadEnabled}
                        isOptional={!stampSettings?.isStampInsertionOnDownloadEnabled}
                        values={POSITION_OPTIONS}
                        value={stampSettings ? POSITION_OPTIONS[stampSettings.position] : undefined}
                        onChange={(value) => {
                          setStampSettings((settings) => ({ ...settings, position: value.value }));
                          setIsEnableSave(true);
                        }}
                        isModel={true}
                      />
                    </div>
                  </div>
                  <div className='two-column-form-item'>
                    <div className='two-column-form-item-caption'>
                      {t('select_language', 'Select Language')}
                    </div>
                    <div className='two-column-form-item-controls'>
                      <label>
                        {<input
                          type='radio'
                          disabled={!stampSettings?.isStampInsertionOnDownloadEnabled}
                          checked={stampSettings?.locale === 'de-DE'}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setStampSettings((settings) => ({ ...settings, locale: 'de-DE' }));
                              setIsEnableSave(true);
                            }
                          }}
                        />}
                        {t('german', 'German')}
                      </label>
                      <label>
                        {<input
                          type='radio'
                          disabled={!stampSettings?.isStampInsertionOnDownloadEnabled}
                          checked={stampSettings?.locale === 'en-US'}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setStampSettings((settings) => ({ ...settings, locale: 'en-US' }));
                              setIsEnableSave(true);
                            }
                          }}
                        />}
                        {t('english', 'English')}
                      </label>
                    </div>
                  </div>
                </div>
                <div className='stamp-wrapper-right'>
                  <h4 className='stamp-subtitle' style={{ marginTop: '18px' }}>
                    {t('document_stamp_example', 'Example')}
                  </h4>
                  <StampExample stampSettings={stampSettings} />
                </div>
              </div>
            </div>
            )}
          </div>
        </div>
      </form>
      {isVisibleColorPicker && (
        <ClickAwayListener
          onClickAway={() => setVisibleColorPicker(false)}
        >
          <div
            style={{
              position: "absolute",
              top: selectedColorPickerRef.current?.offsetTop - 270,
              left:
                selectedColorPickerRef.current?.offsetLeft +
                selectedColorPickerRef.current?.clientWidth -
                30,
            }}
          >
            <SketchPicker
              color={pickedColor.color}
              onChange={(color) => handleChangeColor(color)}
              onChangeComplete={(color) => handleChangeColorComplete(color)}
            />
          </div>
        </ClickAwayListener>
      )}
      {!!notification && (
        <NotificationModal
          onDone={() => setNotification(null)}
          notification={notification}
          isAutomationClose={true}
        />
      )}
    </div>
  );
};

export default DocumentSetting;
