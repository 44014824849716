import { useEffect, useState } from "react";
import { sortBy } from "lodash";
import { IssueLogType } from "../../Services/IssueService";

export default function useDocumentLogs(props) {
  const { allIssueLogs, allDocuments } = props;

  const [issueLogs, setIssueLogs] = useState([]);

  useEffect(() => {
    if (!allIssueLogs?.length) return;

    const uploadedDoc = allIssueLogs.find((log) => log.issueDocumentLinkId);
    const doc = allDocuments.find(
      (d) => d.id === uploadedDoc.documentVersionId
    );
    const newUploadedDocuments = allDocuments.filter(
      (d) =>
        d.uploadDate >= uploadedDoc.creationDate &&
        doc.documentId === d.documentId
    );
    const firstIndex = allIssueLogs.findIndex((log) => !!log.documentVersionId);
    const updatedlogs = sortBy(
      allIssueLogs
        .slice(firstIndex)
        .filter(
          (log) =>
            log.entryType === IssueLogType.DocumentFirstLinked ||
            log.entryType === IssueLogType.StatusIdUpdated
        )
        .concat(
          newUploadedDocuments.map((doc) => {
            const findLog = allIssueLogs.filter(
              (log) => log.creationDate <= doc.uploadDate
            );
            return {
              creationDate: doc.uploadDate,
              document: doc,
              documentVersionId: doc.id,
              statusId: findLog[findLog.length - 1].newId
                ? findLog[findLog.length - 1].newId
                : findLog[findLog.length - 1].statusId,
            };
          })
        ),
      "creationDate"
    );

    const logs = updatedlogs.map((log) => {
      if (log.entryType === IssueLogType.DocumentFirstLinked) {
        return {
          ...log,
          creationDate: doc.uploadDate,
        };
      } else {
        return {
          ...log,
          document: log.document ?? undefined,
          documentVersionId: log.documentVersionId ?? undefined,
          status: log.status ?? "",
        };
      }
    });

    logs.forEach((log, index) => {
      if (log.documentVersionId && !log.document) {
        log.document = allDocuments.find((d) => d.id === log.documentVersionId);
      }
      if (!log.entryType) {
        log.status = logs[index - 1].newValue || logs[index - 1].status;
      }
      if (index > 0) {
        if (!log.document) {
          log.document = logs[index - 1].document;
        }
        if (log.entryType === IssueLogType.StatusIdUpdated) {
          log.status = log.newValue;
        }
        if (!log.documentVersionId) {
          log.documentVersionId = logs[index - 1].documentVersionId;
        }
      }
    });
    setIssueLogs(logs);
  }, [allIssueLogs, allDocuments]);

  return {
    issueLogs,
    setIssueLogs,
  };
}
