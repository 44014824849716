import { decode, DecodeError } from "@msgpack/msgpack";
import { Vector3 } from "three";
import { VisoNode } from "./BaseDefinitions";
import { VisoModelFile } from "./VisoModelFile";
import { VisoShape } from "./VisoShape";
import { VisoStoreyMap } from "./VisoStoreyMap";
import { VisoStyle } from "./VisoStyle";
import { VisoDataType } from "./viso-types";

const UINT8_MAX = 255;
const UINT16_MAX = 65535;

const EMPTY_VIEW = new DataView(new ArrayBuffer(0));
//https://github.com/msgpack/msgpack-javascript/blob/797d00e8c9d835aea1a5906500ca1711ece9d6c5/src/Decoder.ts
export class ModelFileDecoder {
  private pos = 0;
  private total = 0;
  private view: DataView = EMPTY_VIEW;

  decode(buffer: ArrayBuffer, type: VisoDataType, isMsgpack: boolean) {
    switch (type) {
      case VisoDataType.shape:
        return this.decodeShape(buffer);
      case VisoDataType.style:
        return this.decodeStyle(buffer);
      case VisoDataType.modelfile:
        return isMsgpack
          ? this.decodeModelFile(buffer)
          : this.decodeModelFileJson(buffer);
      default:
        return decode(buffer);
    }
  }

  private decodeModelFileJson(buffer: ArrayBuffer) {
    const data = JSON.parse(new TextDecoder().decode(buffer));
    return data.map((json: any) => {
      const modelFile = new VisoModelFile();
      modelFile.id = json.id;
      json.nodes.forEach((n: any) => {
        const node = new VisoNode();
        node.globalId = n.globalId;
        node.id = n.id;
        node.elementName = n.elementName;
        node.elementType = n.elementType;
        node.shapeId = n.shapeId ? n.shapeId : undefined;
        node.styleIds = n.styleIds;
        node.containsNodeIds = n.containsNodeIds;
        node.isDecomposedByNodeIds = n.isDecomposedByNodeIds;
        node.decomposesNodeIds = n.decomposesNodeIds;
        node.transform = n.transform;
        node.modelFileId = n.modelFileId;

        modelFile.nodes[node.id]=node;
      });

      modelFile.center = new Vector3(
        json.bounds.center.x,
        json.bounds.center.z,
        -json.bounds.center.y
      );
      modelFile.size = new Vector3(
        json.bounds.size.x,
        json.bounds.size.z,
        json.bounds.size.y
      );

      modelFile.storeyMaps = json.storeyMaps.map((s: any) => {
        const storey = new VisoStoreyMap();
        storey.resourceId = s.resourceId;
        storey.name = s.storeyName;
        storey.center.set(
          s.storeyBounds.center.x,
          s.storeyBounds.center.y,
          s.storeyBounds.center.z
        );
        storey.size.set(
          s.storeyBounds.size.x,
          s.storeyBounds.size.y,
          s.storeyBounds.size.z
        );

        return storey;
      });

      modelFile.disciplineMetaDataId = json.disciplineMetaDataId;
      modelFile.buildingMetaDataId = json.buildingMetaDataId;
      modelFile.floorMetaDataId = json.floorMetaDataId;

      return modelFile;
    });
  }

  private decodeModelFile(buffer: ArrayBuffer) {
    const object = decode(buffer) as Array<any>;
    return object.map((res) => {
      const modelFile = new VisoModelFile();
      modelFile.id = res[0];
      modelFile.disciplineMetaDataId = res[5];
      modelFile.buildingMetaDataId = res[6];
      modelFile.floorMetaDataId = res[7];
      res[12].forEach((n: any) => {
        const node = new VisoNode();
        node.globalId = n[0];
        node.id = n[1];
        node.elementName = n[2];
        node.elementType = n[3];
        node.shapeId = n[4];
        node.styleIds = n[5];
        node.containsNodeIds = n[6];
        node.isDecomposedByNodeIds = n[8];
        node.decomposesNodeIds = n[9];
        node.transform = n[11];
        node.modelFileId = n[17];
        
        modelFile.nodes[node.id]=node;
      });

      modelFile.center = new Vector3(
        res[13][0][0],
        res[13][0][2],
        -res[13][0][1]
      );
      modelFile.size = new Vector3(res[13][1][0], res[13][1][2], res[13][1][1]);

      modelFile.storeyMaps = res[14].map((s: any) => {
        const storey = new VisoStoreyMap();
        storey.resourceId = s[0];
        storey.name = s[1];
        storey.center.set(s[2][0][0], s[2][0][1], s[2][0][2]);
        storey.size.set(s[2][1][0], s[2][1][1], s[2][1][2]);

        return storey;
      });

      return modelFile;
    });
  }

  private decodeStyle(buffer: ArrayLike<number> | BufferSource) {
    const object = decode(buffer) as Array<any>;
    return object.map((res) => {
      return new VisoStyle(res);
    });
  }

  private decodeShape(buffer: ArrayBuffer) {
    this.setBuffer(buffer);
    this.total = this.getArraySize();
    if (this.total <= 0) throw new DecodeError("Wrong data size.");

    return this.readData();
  }

  private readData() {
    const data = [];
    let current = 0;
    while (current < this.total) {
      const shape = new VisoShape();
      this.readU8(); // Skip to read unnecessary field
      shape.id = this.readId();
      this.skipTimeStamp();
      this.readMeshDto(shape);
      data.push(shape);
      current++;
    }

    return data;
  }

  private readMeshDto(shape: VisoShape) {
    const extHead = this.readU8();
    const veriHead = this.readU8();
    const typeHead = this.readU8();
    if (extHead != 0xc7 || veriHead != 0 || typeHead != 40) {
      throw new DecodeError("Undecode the mesh dto");
    }

    const isLittleEndian = this.readBoolean();
    shape.vertices = this.readFloatArray(true);
    shape.normals = this.readFloatArray(true);
    shape.triangles = this.readTriangles(
      isLittleEndian,
      shape.vertices.length / 3
    );
    shape.wireframe = this.readIntArray(
      isLittleEndian,
      shape.vertices.length / 3
    );
  }

  private readTriangles(endian: boolean, ptCount: number) {
    const size = this.readNumber();
    const data = [];
    for (let i = 0; i < size; i++) {
      data.push(this.readIntArray(endian, ptCount));
    }

    return data;
  }

  private readIntArray(endian: boolean, ptCount: number) {
    const size = this.getArraySize();
    const array = new Uint32Array(size);
    for (let i = 0; i < size; i++) {
      if (ptCount <= UINT8_MAX) {
        array[i] = this.readU8();
      } else if (ptCount <= UINT16_MAX) {
        array[i] = this.readU16(endian);
      } else {
        array[i] = this.readNumber();
      }
    }

    return array;
  }

  private readFloatArray(endian: boolean) {
    const size = this.readNumber() / 4;
    const array = new Float32Array(size);
    for (let i = 0; i < size; i += 3) {
      array[i] = this.readF32(endian);
      array[i + 1] = this.readF32(endian);
      array[i + 2] = -this.readF32(endian);
    }

    return array;
  }

  private readNumber(endian = false) {
    const head = this.readU8();
    if (head < 0x80) {
      return head;
    } else if (head >= 0xe0) {
      return head - 0x100;
    } else if (head === 0xca) {
      return this.readF32(endian);
    } else if (head === 0xcb) {
      return this.readF64(endian);
    } else if (head === 0xcc) {
      return this.readU8();
    } else if (head === 0xcd) {
      return this.readU16(endian);
    } else if (head === 0xce) {
      return this.readU32(endian);
    } else if (head === 0xcf) {
      return this.readU64();
    } else if (head === 0xd0) {
      return this.readI8();
    } else if (head === 0xd1) {
      return this.readI16(endian);
    } else if (head === 0xd2) {
      return this.readI32(endian);
    } else if (head === 0xd3) {
      return this.readI64();
    }

    throw new DecodeError(`Uncaught error read number. pos:${this.pos - 1}`);
  }

  private readBoolean() {
    const head = this.readU8();
    if (head == 0xc2) {
      return false;
    } else if (head == 0xc3) {
      return true;
    }

    throw new DecodeError("Uncaugt error: read boolean");
  }

  private skipTimeStamp() {
    const head = this.readU8();
    if (head == 0xd6 && this.isTimeType()) {
      this.pos += 4;
      return;
    } else if (head == 0xd7 && this.isTimeType()) {
      this.pos += 8;
      return;
    } else if (head == 0xc7) {
      const prehead = this.readU8();
      if (prehead == 12 && this.isTimeType()) {
        this.pos += 4;
        return;
      }
    }

    throw new DecodeError("Can't get timestamp");
  }

  private isTimeType() {
    const timeHead = this.readU8();
    return timeHead == 0xff;
  }

  private readId() {
    const byteLength = this.readU8() - 0xa0;
    if (byteLength != 24) {
      throw new DecodeError("Can't read id.");
    }

    const units: Array<number> = [];
    for (let i = 0; i < byteLength; i++) {
      units.push(this.readU8());
    }

    return String.fromCharCode(...units);
  }

  private getArraySize() {
    const head = this.readU8();
    switch (head) {
      case 0xdc:
        return this.readU16();
      case 0xdd:
        return this.readU32();
      default: {
        if (head < 0xa0) {
          return head - 0x90;
        } else {
          throw new DecodeError(
            `Unrecognized array type byte: ${this.pos - 1}`
          );
        }
      }
    }
  }

  private setBuffer(buffer: ArrayBuffer) {
    this.pos = 0;
    this.view = new DataView(buffer);
  }

  private readU8() {
    const value = this.view.getUint8(this.pos);
    this.pos++;
    return value;
  }

  private readI8(): number {
    const value = this.view.getInt8(this.pos);
    this.pos++;
    return value;
  }

  private readU16(endian = false): number {
    const value = this.view.getUint16(this.pos, endian);
    this.pos += 2;
    return value;
  }

  private readI16(endian = false): number {
    const value = this.view.getInt16(this.pos, endian);
    this.pos += 2;
    return value;
  }

  private readU32(endian = false): number {
    const value = this.view.getUint32(this.pos, endian);
    this.pos += 4;
    return value;
  }
  private readI32(endian = false): number {
    const value = this.view.getInt32(this.pos, endian);
    this.pos += 4;
    return value;
  }

  private readU64(): number {
    const high = this.view.getUint32(this.pos);
    const low = this.view.getUint32(this.pos + 4);
    this.pos += 8;
    return high * 0x1_0000_0000 + low;
  }

  private readI64(): number {
    const high = this.view.getInt32(this.pos);
    const low = this.view.getUint32(this.pos + 4);
    this.pos += 8;
    return high * 0x1_0000_0000 + low;
  }

  private readF32(endian = false) {
    const value = this.view.getFloat32(this.pos, endian);
    this.pos += 4;
    return value;
  }

  private readF64(endian = false) {
    const value = this.view.getFloat64(this.pos, endian);
    this.pos += 8;
    return value;
  }
}
