import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { groupBy, uniqBy } from "lodash";

import { IssuesTree } from "issues/components/IssuesTree";
import { ColumnsOrder } from "Components/Buttons/ColumnsOrder";
import { ChangePositionLine } from "emails/ChangePositionLine";
import { getGeneralSvg } from "Components/SVGs";

import * as Request from "Helpers/Request";
import useWindowDimensions from "Helpers/Hooks/useWindowDimensions";
import useCurrentProjectQuery from "projects/hooks/useCurrentProjectQuery";

export const Table = ({
  isLinkIssueView = false,
  title = "",
  columns,
  data,
  columnsWidth,
  row_events,
  columnsData,
  hideColumns,
  setHideColumns,
  checked,
  checkedValues,
  resized,
  setResized,
  setColumnsForDrag,
  setIsDraggable,
  columnsDataHeader,
  setChangedColumns,
  selectedIssueTree,
  issueView,
  show_aside_filter,
  isDefault,
  allIssues = [],
  statuses = [],
  isAdjustColumns = false,
  isSearchView,
  isFilterView,
}) => {
  const { t } = useTranslation();
  const { data: currentProject } = useCurrentProjectQuery();
  const [droppedColumns, setDroppedColumns] = useState({});
  const [savedNewChanges, setSavedNewChanges] = useState(false);
  const [canChangePosition, setCanChangePosition] = useState(false);
  const [isChangedPositions, setIsChangedPositions] = useState(false);
  const [canChangeWidth, setCanChangeWidth] = useState(false);
  const [issueList, setIssueList] = useState("");
  const [groupedSubFolders, setGroupedSubFolders] = useState({});
  const [groupedFolders, setGroupedFolders] = useState([]);
  const { width } = useWindowDimensions();

  const isTableBiggerThanScreen = useMemo(() => {
    const totalWidth =
      columnsWidth.reduce((total, item) => total + item.width, 0) + 50;
    return totalWidth > width;
  }, [columnsWidth, width]);

  useEffect(() => {
    issueView
      ? setIssueList("DEFAULT_ISSUE_LAYOUT")
      : setIssueList("DEFAULT_MAIL_LAYOUT");
  }, [columns]);

  const saveNewView = async (newColumns) => {
    let columns = [];
    let newDataColumns = [];

    if (newColumns) columns = newColumns;
    else {
      const beforeColumns = columnsWidth.map((i) => i.name);

      const checkboxes = document.querySelectorAll(
        'input[name="columnsCheck"]:checked'
      );
      checkboxes.forEach((checkbox) => {
        newDataColumns.push(checkbox.value);
      });
      const was = beforeColumns.filter((column) =>
        newDataColumns.includes(column)
      );
      const newAdded = newDataColumns.filter(
        (column) => !beforeColumns.includes(column)
      );
      const fullArray = was.concat(newAdded);
      fullArray.forEach((column) => {
        if (document.getElementById(column.replace(/\s/g, ""))) {
          const width = document.getElementById(
            column.replace(/\s/g, "")
          ).offsetWidth;
          columns.push({ name: column, width });
        } else {
          columns.push({ name: column, width: 90 });
        }
      });
    }

    await Request.PUT(`api/dynamic-layout`, {
      projectId: `${currentProject.id}`,
      key: issueList,
      columns,
    });
    setSavedNewChanges(!savedNewChanges);
    setHideColumns(false);
    setCanChangePosition(false);
    setIsChangedPositions(false);
    setIsDraggable(false);
    setCanChangeWidth(false);
    setChangedColumns(true);
  };

  const cancel = () => {
    setColumnsForDrag(columnsDataHeader);
    setCanChangePosition(false);
    setIsChangedPositions(false);
    setIsDraggable(false);
  };

  const getItemStyle = (mobile, column, draggableStyle) => ({
    width: issueView && width < 577 ? mobile.width : `${column?.width}px`,
    resize: canChangeWidth && "horizontal",
    overflow: canChangeWidth && "hidden",
    ...draggableStyle,
  });

  const clickChangeWidth = () => {
    setCanChangeWidth(true);
    setCanChangePosition(false);
    setHideColumns(false);
    setIsChangedPositions(false);
    setIsDraggable(false);
  };

  const clickChangeOrder = () => {
    setIsDraggable(true);
    setCanChangePosition(true);
    setHideColumns(false);
    setCanChangeWidth(false);
  };

  const handleOnDragEnd = (result) => {
    if (
      !result?.destination ||
      (issueView &&
        result.destination.index <=
          1) /* the orders of id and type can't be changed in issues list */
    )
      return;

    const items = Array.from(columns);
    const [reorderedItems] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItems);
    const droppedColumnsRate = [];
    items.forEach((item) => {
      if (document.getElementById(item.value.replace(/\s/g, ""))) {
        const width = document.getElementById(
          item.value.replace(/\s/g, "")
        ).offsetWidth;
        droppedColumnsRate.push({ name: item.value, width });
      } else {
        droppedColumnsRate.push({ name: item.value, width: 90 });
      }
    });
    setDroppedColumns(droppedColumnsRate);
    setColumnsForDrag(items);
    setIsChangedPositions(true);
  };

  const handleResizeWidth = () => {
    const items = Array.from(columns);
    let droppedColumnsRate = [];
    items.forEach((item) => {
      if (document.getElementById(item.value.replace(/\s/g, ""))) {
        const width = document.getElementById(
          item.value.replace(/\s/g, "")
        ).offsetWidth;
        droppedColumnsRate.push({ name: item.value, width });
      } else {
        droppedColumnsRate.push({ name: item.value, width: 90 });
      }
    });
    setDroppedColumns(droppedColumnsRate);
  };

  useEffect(() => {
    if (!issueView) return;

    const aloneIssues = [];
    const groupIssues = groupBy(data, "parentId");
    const accessibleSubIssues = {};
    Object.keys(groupIssues).forEach((key) => {
      const issue = allIssues.find((item) => item.id === key);
      if (key === "undefined" || !issue) aloneIssues.push(...groupIssues[key]);
      else {
        accessibleSubIssues[key] = groupIssues[key];
        if (statuses.find((status) => status.id === issue.issueStatus?.id))
          aloneIssues.push(issue);
      }
    });
    const firstLineRow = aloneIssues.filter((item) => {
      return !item.parentId;
    });
    setGroupedSubFolders(accessibleSubIssues);
    setGroupedFolders(uniqBy(firstLineRow, "id"));
  }, [data, statuses, issueView]);

  const getFirstColumnMinWidth = (widths) => {
    const sum = widths.slice(1).reduce((total, item) => total + item.width, 0);
    return `calc(100% - ${sum + (title === "issues" ? 60 : 0)}px)`;
  };

  const getColumnMinWidth = (id, index, isAdjustColumns, columnsWidth) => {
    if (id === "Tags") return "145px";
    else {
      if (index === 0 && isAdjustColumns)
        return getFirstColumnMinWidth(columnsWidth);
      else if (id === "Type") return "72px";
      else return "unset";
    }
  };

  return (
    <div className={`table-data ${title}-table custom-scrollbar`}>
      {!isDefault && (
        <ChangePositionLine
          canChangePosition={canChangePosition}
          canChangeWidth={canChangeWidth}
          droppedColumns={droppedColumns}
          resized={resized}
          isChangedPositions={isChangedPositions}
          saveNewView={saveNewView}
          setResized={setResized}
          cancel={cancel}
        />
      )}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId={"columnsForEmailTable"} direction="horizontal">
          {(provided) => (
            <div
              className={`table-row header-table ${
                issueView &&
                !show_aside_filter &&
                !isFilterView &&
                !isSearchView &&
                "pl-10"
              }`}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {columns?.map((column, index) => {
                if (!column) return;
                const dataColumn = columnsWidth?.find(
                  (i) => i.name === column?.value
                );
                const id = `${
                  isLinkIssueView ? "link" : ""
                }${column?.value?.replace(/\s/g, "")}`;
                return (
                  <Draggable
                    key={column.dataField}
                    draggableId={column.dataField}
                    index={index}
                    isDragDisabled={column.isDraggable}
                  >
                    {(provided) => (
                      <div
                        className={`header-row ${column?.dataField} ${
                          id !== "ID" ? " border-right-tables" : ""
                        } ${canChangeWidth ? "text-ellipsis" : "ellipsis"}`}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        id={id}
                        style={{
                          ...getItemStyle(
                            column,
                            dataColumn,
                            provided.draggableProps.style
                          ),
                          minWidth: getColumnMinWidth(
                            id,
                            index,
                            isAdjustColumns,
                            columnsWidth
                          ),
                        }}
                        onClick={() => {
                          canChangeWidth ? handleResizeWidth() : null;
                        }}
                      >
                        {id !== "ID" && id !== "Type" && canChangePosition
                          ? getGeneralSvg("drag-handler")
                          : null}
                        {column.text ? column.text : column?.headerFormatter()}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {!isDefault && (
                <>
                  <div
                    className="columns-order columns-options mobile--hide"
                    style={
                      isTableBiggerThanScreen ? { right: 0 } : { left: "100vw" }
                    }
                  >
                    <ColumnsOrder
                      columnsData={columnsData}
                      setHideColumns={setHideColumns}
                      hideColumns={hideColumns}
                      checked={checked}
                      checkedValues={checkedValues}
                      resized={resized}
                      setResized={setResized}
                      saveNewView={saveNewView}
                      clickChangeWidth={clickChangeWidth}
                      clickChangeOrder={clickChangeOrder}
                    />
                  </div>
                  {provided.placeholder}
                </>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!issueView || show_aside_filter || isFilterView || isSearchView ? (
        data.map((item, itemId) => (
          <div key={`email-${itemId}`} className={`table-row`}>
            {columns.map((column, columnIdx) => {
              const headerColumn = document.getElementById(
                column?.value?.replace(/\s/g, "")
              );
              const width = headerColumn?.style?.width;
              const id = `id${column?.value?.replace(/\s/g, "")}`;
              return (
                <div
                  className={`table-row__part ${column?.dataField} ${
                    id !== "idID" && id !== "idType"
                      ? " border-right-tables"
                      : ""
                  }`}
                  key={`column-${columnIdx}`}
                  style={{
                    width,
                    minWidth:
                      columnIdx === 0 && isAdjustColumns
                        ? getFirstColumnMinWidth(columnsWidth)
                        : id === "Type"
                        ? "72px"
                        : "unset",
                  }}
                  id={id}
                  onClick={(e) => row_events(e, item, itemId)}
                  onMouseOver={
                    !setResized ? () => {} : () => setResized(!resized)
                  }
                  onMouseLeave={
                    !setResized ? () => {} : () => setResized(!resized)
                  }
                >
                  {column.formatter(item[column.dataField], item)}
                </div>
              );
            })}
            {!!item.label && (
              <div className={"table-btn"}>
                <p
                  className={`label absolute-label-email ${
                    item.label === ""
                      ? "hide"
                      : item.label === t("new", "New")
                      ? "bg-light-blue"
                      : "bg-red"
                  }`}
                >
                  {item.label}
                </p>
              </div>
            )}
          </div>
        ))
      ) : (
        <IssuesTree
          prefix={isLinkIssueView ? "link" : ""}
          loadedIssues={data}
          groupedSubFolders={groupedSubFolders}
          groupedFolders={groupedFolders}
          columns={columns}
          row_events={row_events}
          selectedIssueTree={selectedIssueTree}
          setResized={setResized}
          resized={resized}
        />
      )}
    </div>
  );
};
