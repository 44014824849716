import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDynamicLayoutEntityFactory from 'dynamic-layout/hooks/useDynamicLayoutEntityFactory';
import DynamicLayout from 'dynamic-layout/types/DynamicLayout';
import DynamicLayoutKey from 'dynamic-layout/types/DynamicLayoutKey';
import DynamicLayoutDto from 'dynamic-layout/types/DynamicLayoutDto';

export default function useDynamicLayoutQuery(dynamicLayoutKey: DynamicLayoutKey) {
  const entityFactory = useDynamicLayoutEntityFactory();
  const endpoint = ApiEndpoint.DynamicLayout;

  const axiosInstance = useAxiosInstance();
  const { listsByCurrentProjectQueryKey } = useDefaultEntityQueryKeys(endpoint);
  const queryKey = useMemo(() => [listsByCurrentProjectQueryKey, dynamicLayoutKey], [dynamicLayoutKey, listsByCurrentProjectQueryKey]);
  return useQuery<DynamicLayout | undefined>(queryKey, async () => {
    const response = await axiosInstance.get<DynamicLayoutDto>(`/${endpoint}/${dynamicLayoutKey}`);
    return entityFactory(response.data);
  });
}
