enum SettingsTab {
  Project = 'project',
  ModelMetadata = 'model-metadata',
  Roles = 'roles',
  UserGroups = 'user-groups',
  Tags = 'tags',
  Encoding = 'encoding',
  Documents = 'documents',
  Issues = 'issues',
  BimQ = 'bim-q',
}
export default SettingsTab;
