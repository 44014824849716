import React, { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import OverviewCountLabel from 'Components/Dashboard/OverviewCountLabel';
import { IssueService } from 'Services';
import CountDataPoint from 'Components/Dashboard/types/CountDataPoint';
import Item from 'Components/Dashboard/types/Item';

export interface OverviewItemProps {
  items: Item[],
  selectedState: {
    value: number;
    label: string;
  },
  chartOptions: object;
  header: string,
  data: ReadonlyArray<CountDataPoint>
}

function OverviewItem({
  items,
  selectedState,
  chartOptions,
  data,
  header,
}: OverviewItemProps) {
  const getColor = (item: Item, index: number) => {
    if (item.color !== undefined) return item.color;
    return IssueService.GraphColors[index];
  };

  const countMap = useMemo(() => {
    const map: Record<string, number> = {};
    items?.forEach(
      (item) => {
        map[item.id] = data.find((d) => d.id === item.id)?.count ?? 0;
      },
    );
    return map;
  }, [items, data]);

  const chartData = useMemo(() => {
    if (!items || Object.keys(countMap).length !== items.length) {
      return { datasets: [], labels: [] };
    }

    return {
      labels: items.map((item) => item.name),
      datasets: [
        {
          data: items.map((item) => countMap[item.id]),
          backgroundColor: items.map((item, index) => getColor(item, index)),
          borderColor: items.map((item, index) => getColor(item, index)),
          borderWidth: 1,
        },
      ],
    };
  }, [items, countMap]);

  return (
    <div
      className={`dashboard__chart${selectedState.value !== 0 ? ' mobile--hide' : ''}`}
    >
      <div className="dashboard__chart__wrapper">
        <Doughnut data={chartData} options={chartOptions} key="doughnut" />
      </div>
      <div className="dashboard__chart__explanation_wrapper" key="list">
        <div className="dashboard__chart__title">
          {header}
        </div>
        <div className="dashboard__chart__labels">
          {items.map((item, index) => (
            <OverviewCountLabel
              item={item}
              count={countMap[item.id]}
              key={item.id}
              color={getColor(item, index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
export default OverviewItem;
