import {
  QueryKey, useQuery, UseQueryOptions,
} from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';

export default function useDefaultEntityQuery<
  TDto,
  TCachedEntity extends TDto,
  TSelectedData extends TCachedEntity | TCachedEntity | undefined = TCachedEntity | undefined>(
  endpoint: ApiEndpoint,
  entityFactory: (dto: TDto) => Readonly<TCachedEntity>,
  options?: Omit<UseQueryOptions<TCachedEntity | undefined, unknown, TSelectedData, QueryKey>, 'queryKey' | 'queryFn'> | undefined,
) {
  const axiosInstance = useAxiosInstance();
  const { baseQueryKey: queryKey } = useDefaultEntityQueryKeys(endpoint);
  return useQuery<TCachedEntity | undefined, unknown, TSelectedData>(queryKey, async () => {
    const response = await axiosInstance.get<TDto>(`/${endpoint}`);
    return entityFactory(response.data);
  }, {
    retry: 1, retryDelay: 1000, ...options,
  });
}
