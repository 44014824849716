import useLetterNumbering from 'documents/hooks/useLetterNumbering';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import { useCallback } from 'react';

export default function useDocumentVersionNumberString() {
  const { data: currentProject } = useCurrentProjectQuery();
  const { numberToLetter } = useLetterNumbering();
  return useCallback((versionNumber: number | undefined) => {
    if (!currentProject || versionNumber === undefined) return undefined;
    if (currentProject.namingSchemeSettings?.useLetterVersioning) {
      return numberToLetter(versionNumber);
    }
    return versionNumber;
  }, [currentProject, numberToLetter]);
}
