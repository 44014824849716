import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span />
    </components.DropdownIndicator>
  );
};

const MultiValue = ({ children, ...props }) => {
  const { t } = useTranslation();
  const { getValue, hasValue, options } = props;
  const values = getValue();
  const isAllSelected = values.find((item) => item.value === null);
  const nbValues = isAllSelected
    ? options.filter((item) => item.value !== null).length
    : values.length;
  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }
  return (
    <components.ValueContainer {...props}>
      <div
        style={{
          whiteSpace: "nowrap",
          marginLeft: "-8px",
          fontSize: "9px",
          fontWeight: 600,
        }}
      >
        {`${nbValues} ${t("selected", "Selected")}`}
      </div>
    </components.ValueContainer>
  );
};

const MultiOption = (props) => {
  return (
    <div>
      <components.Option
        {...props}
        className={"option-checkbox-" + props.value}
      >
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label className="text-ellipsis">{props.label}</label>
      </components.Option>
    </div>
  );
};

const ModelSelect = (props) => {
  const { options, value, onChange, ...rest } = props;
  const { t } = useTranslation();
  // isOptionSelected sees previous value after onChange
  const valueRef = useRef(value);
  valueRef.current = value;

  const selectAllOption = {
    value: null,
    label: t("all", "All"),
  };

  const isSelectAllSelected = () => valueRef.current.length === options.length;

  const isOptionSelected = (option) =>
    valueRef.current.some(({ value }) => value === option.value) ||
    isSelectAllSelected();

  const getOptions = () => [selectAllOption, ...options];

  const getValue = () => (isSelectAllSelected() ? [selectAllOption] : value);

  const onChangeValue = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;
    if (action === "select-option" && option.value === selectAllOption.value) {
      onChange(options, actionMeta);
    } else if (
      (action === "deselect-option" &&
        option.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      onChange([], actionMeta);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      onChange(
        options.filter(({ value }) => value !== option.value),
        actionMeta
      );
    } else {
      onChange(newValue || [], actionMeta);
    }
  };

  return (
    <div className="model-select select--light select--multi select-width-full select--light select--no-background select--clearable">
      <Select
        classNamePrefix="select"
        components={{ DropdownIndicator, Option: MultiOption, MultiValue }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: '14px !important',
          }),
        }}
        isMulti={true}
        isSearchable={false}
        isClearable={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isOptionSelected={isOptionSelected}
        options={getOptions()}
        value={getValue()}
        onChange={onChangeValue}
        placeholder={t("select", "Select")}
        {...rest}
      />
    </div>
  );
};

export default ModelSelect;
