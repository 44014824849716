import React from 'react';
import { Input } from '@mui/material';
import TagCreateSelectInputComponent from 'tags/components/TagCreateSelectInputComponent';
import ISxProps from 'common/types/ISxProps';

interface TagCreateSelectProps extends ISxProps {
  id?: string,
  value: string[] | undefined,
  onChange: (tagIds: string[]) => void,
  disabled?: boolean,
}

export default function TagCreateSelect({
  id,
  sx,
  value,
  onChange,
  disabled,
}: TagCreateSelectProps) {
  return (
    <Input
      id={id}
      value={value}
      onChange={onChange as any} // MUI does not expect us to use a non HTML-input component here so we have to sneak around the event handler type check
      disabled={disabled}
      sx={sx}
      slots={{
        input: TagCreateSelectInputComponent,
      }}
    />
  );
}
