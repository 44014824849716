import { useCallback, useMemo } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import DocumentListDto from 'documents-lists/types/DocumentListDto';
import useDocumentListQueryKey, { DocumentListQueryKey } from 'documents-lists/hooks/useDocumentListQueryKey';
import useDocumentListsOdataQueryKey from 'documents-lists/hooks/useDocumentListsOdataQueryKey';

type DocumentListUseQueryOptions = UseQueryOptions<DocumentListDto | undefined, unknown, DocumentListDto | undefined, DocumentListQueryKey | readonly []>;

export default function useDocumentListQuery(id: string | undefined, options?: Omit<DocumentListUseQueryOptions, 'intitialData' | 'queryKey' | 'queryFn'> | undefined) {
  const { getQueryKey } = useDocumentListQueryKey();
  const queryKey = useMemo(() => (id ? getQueryKey(id) : ([] as const)), [getQueryKey, id]);
  const { queryKeyBase } = useDocumentListsOdataQueryKey();
  const queryClient = useQueryClient();
  const initialData = useCallback(() => {
    const dto = queryClient
      .getQueriesData<DocumentListDto[] | undefined>({ queryKey: queryKeyBase })
      .flatMap(([, cachedDtos]) => cachedDtos)
      .find((cachedDto) => cachedDto?.id === id);
    return dto;
  }, [id, queryClient, queryKeyBase]);
  const queryOptions = useMemo<DocumentListUseQueryOptions>(() => ({
    ...options,
    queryKey,
    initialData,
    enabled: (options?.enabled !== false) && (queryKey.length > 0),
  }), [initialData, options, queryKey]);
  return useQuery(queryOptions);
}
