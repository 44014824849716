import React, { useCallback, useState } from 'react';
import { Alert, Box, Button, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import MutateLabelDialog, { DialogResult } from 'settings/components/MutateLabelDialog';
import LabelType from 'labels/types/LabelType';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';

export default function ModelSettingsDisciplinesPanel() {
  const { t } = useTranslation('settings');
  const { data: currentProject } = useCurrentProjectQuery();
  const { data: disciplines } = useProjectDisciplinesQuery();
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const onCloseSuccessMessage = useCallback(() => setSuccessMessage(undefined), []);
  const [disciplineIdCurrentlyEditing, setDisciplineIdCurrentlyEditing] = useState<string | undefined>(undefined);
  const [isCreatingDiscipline, setIsCreatingDiscipline] = useState<boolean>(false);
  const onClickAdd = useCallback(() => setIsCreatingDiscipline(true), []);
  const onCloseDialog = useCallback((result: DialogResult) => {
    setIsCreatingDiscipline(false);
    setDisciplineIdCurrentlyEditing(undefined);
    if (result === DialogResult.Created) {
      setSuccessMessage(t('model-settings-discipline-panel_create-discipline-success-message', 'New discipline created'));
    } else if (result === DialogResult.Updated) {
      setSuccessMessage(t('model-settings-discipline-panel_edit-discipline-success-message', 'The changes to the discipline have been saved'));
    } else if (result === DialogResult.Deleted) {
      setSuccessMessage(t('model-settings-discipline-panel_delete-discipline-success-message', 'Discipline deleted'));
    }
  }, [t]);
  return (
    <Paper id="ModelSettingsDisciplinesPanel" elevation={1} square sx={{ p: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Typography variant="h4">{t('model-settings-discipline-panel_title', 'Disciplines')}</Typography>
        <Button variant="contained" color="primary" onClick={onClickAdd} sx={{ ml: 'auto', pl: 1 }}>
          <AddIcon sx={{ mr: 1 }} />
          {t('model-settings-discipline-panel_add-button-label', 'Add')}
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('model-settings-discipline-panel_name-column-header', 'Name')}</TableCell>
            <TableCell>{t('model-settings-discipline-panel_abbreviation-column-header', 'Abbr.')}</TableCell>
            <TableCell sx={{ textAlign: 'center', width: 90 }}>{t('model-settings-discipline-panel_2d-map-column-header', '2D Ansicht')}</TableCell>
            <TableCell sx={{ width: 35 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!!disciplines && !!currentProject && disciplines.map((discipline) => (
            <TableRow key={discipline.id}>
              <TableCell>{discipline.name}</TableCell>
              <TableCell>{discipline.abbreviation}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                {discipline.id === currentProject.architectureLabelId && <CheckIcon />}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => setDisciplineIdCurrentlyEditing(discipline.id)} size="small">
                  <EditIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!!disciplineIdCurrentlyEditing && <MutateLabelDialog labelType={LabelType.Discipline} id={disciplineIdCurrentlyEditing} onClose={onCloseDialog} />}
      {isCreatingDiscipline && <MutateLabelDialog labelType={LabelType.Discipline} onClose={onCloseDialog} />}
      {!!successMessage && (
      <Snackbar open onClose={onCloseSuccessMessage} autoHideDuration={6000}>
        <Alert severity="success" onClose={onCloseSuccessMessage}>{successMessage}</Alert>
      </Snackbar>
      )}
    </Paper>
  );
}
