import { useMemo } from 'react';
import useDefaultEntityQueryKeys, { VisoOdataQueryKey } from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import LabelDto from 'labels/types/LabelDto';

export type LabelsOdataQueryKey = VisoOdataQueryKey<ApiEndpoint.Label, LabelDto>;

export default function useLabelsOdataQueryKey() {
  const {
    odataQueryQueryKey: queryKeyBase,
    getOdataQueryQueryKey: getQueryKey,
  } = useDefaultEntityQueryKeys(ApiEndpoint.Label);
  return useMemo(() => ({
    queryKeyBase,
    getQueryKey: getQueryKey<LabelDto>,
  }), [getQueryKey, queryKeyBase]);
}
