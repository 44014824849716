import {
  Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography,
} from '@mui/material';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import Collaborator from 'collaborators/types/Collaborator';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useCollaboratorRoleMapUpdateMutation from 'collaborators/hooks/useCollaboratorRoleMapUpdateMutation';
import UpdateCollaboratorRoleMapDto from 'collaborators/types/UpdateCollaboratorRoleMapDto';

interface ConfirmRemoveCollaboratorDialogProps {
  collaborator: Collaborator,
  onClose: () => void,
}

export default function ConfirmRemoveCollaboratorDialog({
  collaborator,
  onClose,
}: ConfirmRemoveCollaboratorDialogProps) {
  const { t } = useTranslation('collaborators');
  const { data: currentProject } = useCurrentProjectQuery();
  const { mutateAsync, isLoading: isLoadingMutation } = useCollaboratorRoleMapUpdateMutation();
  const name = useMemo(() => `${collaborator.firstName} ${collaborator.lastName}`, [collaborator]);
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onConfirm = useCallback(async () => {
    if (!currentProject) return;
    const persistDto: UpdateCollaboratorRoleMapDto = {
      collaboratorRoles: currentProject.collaboratorRoles.filter((map) => map.collaboratorId !== collaborator.id),
    };
    try {
      await mutateAsync(persistDto);
      onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [collaborator.id, currentProject, getRequestErrorMessage, mutateAsync, onClose]);

  return (
    <Dialog id="ConfirmRemoveCollaboratorDialog" open>
      <DialogTitle>{t('confirm-remove-collaborator-dialog_title', 'Remove {{collaborator}} from Project?', { collaborator: name })}</DialogTitle>
      <DialogContent>
        <Typography sx={{ py: 2 }}>
          <Trans
            t={t}
            i18nKey="confirm-remove-collaborator-dialog_content"
            components={{ n: <strong /> }}
            defaults="Are you sure that you want to remove the collaborator <n>{{name}}</n> from this project?"
            values={{ name }}
          />
        </Typography>
        {!!errorMessage && <Alert severity="error" sx={{ mt: 2 }}>{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onConfirm} disabled={isLoadingMutation}>{t('confirm-remove-collaborator-dialog_confirm', 'Yes, Remove')}</Button>
        <Button variant="contained" color="primary" onClick={onClose} sx={{ ml: 4, minWidth: '120px' }}>{t('confirm-remove-collaborator-dialog_cancel', 'Cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
}
