import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../HOC';
import { ViewerState } from './ViewerState';

export default function VisualStateModal({ setViewerState }: ViewerState) {
  const { t } = useTranslation();
  const [isDontShow, setIsDontShow] = useState(false);

  const handleChangeDontShow = (e:React.ChangeEvent<HTMLInputElement>) => {
    setIsDontShow(e.target.checked);
  };

  const handleDiscardChange = () => {
    setViewerState(false, isDontShow);
  };

  const handleKeepChange = () => {
    setViewerState(true, isDontShow);
  };

  return (
    <Modal style={{ overflow: 'visible' }}>
      <div className="visual-state-modal">
        <div className="visual-state-modal_content">
          <h2>{t('keep_changes', 'Keep changes')}</h2>
          <p>
            {
              t(
                'keep_change_text',
                'You are about to load a new model. You want to keep the changes you made?',
              )
            }
          </p>
        </div>
        <div className="visual-state-modal_check">
          <input
            type="checkbox"
            id="dontshow"
            style={{ width: '16px', margin: '0 5px' }}
            onChange={handleChangeDontShow}
          />
          <label htmlFor="dontshow">{ t('dont_show_again', "Don't show again") }</label>
        </div>
        <div className="visual-state-modal_button">
          <button
            id="DiscardChangesLoadNewModelButton"
            type="button"
            className="btn btn--secondary"
            onClick={handleDiscardChange}
          >
            {t('discard_change', 'Discard Changes')}
          </button>
          <button
            id="KeepChangesLoadNewModelButton"
            type="button"
            className="btn btn--primary"
            onClick={handleKeepChange}
          >
            {t('keep_change', 'Keep Changes')}
          </button>
        </div>
      </div>
    </Modal>
  );
}
