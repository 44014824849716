import React from 'react';
import { useTranslation } from 'react-i18next';

import OverviewIssues from 'Components/Dashboard/OverviewIssues';
import UpcomingDeadlines from 'Components/Dashboard/UpcomingDeadlines';
import IssuesPerFlow from 'Components/Dashboard/IssuesPerFloor';
import { useIssueFilterItems } from 'Helpers/Hooks';
import OpenWindowsClientLink from 'Components/OpenWindowsClientLink/OpenWindowsClientLink';
import SuspenseWithErrorBoundary from 'error/SuspenseWithErrorBoundary';

import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import RoleAction from 'projects/types/RoleAction';
import NotAllowed from 'error/NotAllowed';

export default function Dashboard() {
  const { t } = useTranslation();
  const currentUserRole = useCurrentUserRole();
  const { data: currentProject } = useCurrentProjectQuery();
  const { issues, issueFilterItems } = useIssueFilterItems();

  if (!currentUserRole?.allowedActions?.has(RoleAction.MainMenu_Dashboard)) return <NotAllowed />;

  const {
    floors, categories, statuses, types, priorities,
  } = issueFilterItems;

  if (!currentProject || !issues) return null;

  return (
    <main className="main dashboard__page custom-scrollbar">
      <div className="main__panel">
        <p className="title">{t('dashboard', 'Dashboard')}</p>
        <div className="model_count">
          {`${currentProject.modelIds?.length || 0} ${t('models', 'Models')}`}
        </div>
        {!!currentUserRole?.allowedActions?.has(RoleAction.MainMenu_LiveView) && (
          <OpenWindowsClientLink view="live">
            {/* eslint-disable-next-line react/button-has-type */}
            <button id="Launch3DView" className="btn btn--primary mobile--hide">
              {t('launch_3d_view', 'Launch 3D-View')}
            </button>
          </OpenWindowsClientLink>
        )}
      </div>
      <div className="main__content dashboard__content">
        <SuspenseWithErrorBoundary suspenseFallback={<div />}>
          <OverviewIssues
            statuses={statuses}
            types={types}
            priorities={priorities}
          />
        </SuspenseWithErrorBoundary>
        <UpcomingDeadlines
          issues={issues}
          statuses={statuses}
        />
        <IssuesPerFlow
          issues={issues}
          floors={floors}
          categories={categories}
        />
      </div>
    </main>
  );
}
