import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import ClickAwayListener from '@mui/base/ClickAwayListener';

const LinkIssueDropdown = ({
  setShowExistingIssueView,
  setShowCreateIssueView,
}) => {
  const { t } = useTranslation();
  const [isDropdown, toggleDropdown] = useState(false);

  return (
    <div id="LinkIssueDropdown" className="link-issue-dropdown mobile--hide">
      <button
        className="link-issue-dropdown_btn"
        onClick={() => toggleDropdown(!isDropdown)}
      >
        {t("link_issue", "Link Issue")}
        <i className={`arrow-line ${isDropdown ? "up" : "down"}`}></i>
      </button>
      {!!isDropdown && (
        <ClickAwayListener onClickAway={() => toggleDropdown(false)}>
          <div className="link-issue-dropdown_content">
            <p
              className="link-issue-dropdown_content-item"
              onClick={setShowExistingIssueView}
            >
              {t("link_existing_issue", "Link existing issue")}
            </p>
            <p
              className="link-issue-dropdown_content-item"
              onClick={setShowCreateIssueView}
            >
              {t("create_new_issue", "Create new issue")}
            </p>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default LinkIssueDropdown;
