import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Alert,
  AlertTitle,
  Box, Button, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import RightUtilityDrawer from 'documents/components/RightUtilityDrawer';
import RoundIconButton from 'common/components/RoundIconButton';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import useProjectWorkPhasesQuery from 'labels/hooks/useProjectWorkPhasesQuery';
import MassEditSelectFormControl from 'documents/components/MassEditSelectFormControl';
import MassEditMultiSelectFormControl from 'documents/components/MassEditMultiSelectFormControl';
import useProjectDocumentStatusesQuery from 'documents/hooks/useProjectDocumentStatusesQuery';
import useProjectTagsQuery from 'tags/hooks/useProjectTagsQuery';
import MassEditFolderSelectFormControl from 'documents/components/MassEditFolderSelectFormControl';
import DocumentSelectionContext, { DocumentSelectionContextState } from 'documents/contexts/DocumentSelectionContext';
import useDocumentVersionMassEditMutation from 'documents/hooks/useDocumentVersionMassEditMutation';
import UpdateManyDocumentVersionsDto from 'documents/types/UpdateManyDocumentVersionsDto';
import axios from 'axios';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';

interface MassEditDrawerProps {
  isOpen: boolean,
  onClose: () => void,
}

export default function MassEditDrawer({
  isOpen,
  onClose,
}: MassEditDrawerProps) {
  const { t } = useTranslation('documents');
  const { selectedDocumentVersionIds } = useContext<DocumentSelectionContextState>(DocumentSelectionContext);
  const { data: currentProject } = useCurrentProjectQuery();
  const { data: floors } = useProjectFloorsQuery();
  const { data: disciplines } = useProjectDisciplinesQuery();
  const { data: buildings } = useProjectBuildingsQuery();
  const { data: workPhases } = useProjectWorkPhasesQuery();
  const { data: documentStatuses } = useProjectDocumentStatusesQuery();
  const currentUserRole = useCurrentUserRole();
  const canSetStatusToPublished = useMemo(() => currentUserRole?.allowedActions?.has(RoleAction.DocStatus_ChangeToPublished), [currentUserRole?.allowedActions]);
  const visibleDocumentStatuses = useMemo(() => documentStatuses?.filter((s) => !(s.isDefault && (s.originalName === 'Archived' || s.originalName === 'Other' || (!canSetStatusToPublished && s.originalName === 'Published')))), [canSetStatusToPublished, documentStatuses]);
  const { data: tags } = useProjectTagsQuery();
  const [selectedFloorMetaDataId, setSelectedFloorMetaDataId] = useState<string | undefined>(undefined);
  const [selectedDisciplineMetaDataIds, setSelectedDisciplineMetaDataIds] = useState<string[] | undefined>(undefined);
  const [selectedBuildingMetaDataId, setSelectedBuildingMetaDataId] = useState<string | undefined>(undefined);
  const [selectedWorkPhaseId, setSelectedWorkPhaseId] = useState<string | undefined>(undefined);
  const [selectedDocumentStatusId, setSelectedDocumentStatusId] = useState<string | undefined>(undefined);
  const [selectedTagIds, setSelectedTagIds] = useState<string[] | undefined>(undefined);
  const [selectedAddTags, setSelectedAddTags] = useState<boolean>(false);
  const [selectedFolderId, setSelectedFolderId] = useState<string | undefined>(undefined);
  const { mutateAsync, isLoading } = useDocumentVersionMassEditMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (selectedTagIds && selectedTagIds.length === 0) {
      setSelectedAddTags(false);
    }
  }, [selectedTagIds]);

  const resetForm = useCallback(() => {
    setSelectedFloorMetaDataId(undefined);
    setSelectedDisciplineMetaDataIds(undefined);
    setSelectedBuildingMetaDataId(undefined);
    setSelectedWorkPhaseId(undefined);
    setSelectedDocumentStatusId(undefined);
    setSelectedTagIds(undefined);
    setSelectedAddTags(false);
    setSelectedFolderId(undefined);
  }, []);

  const onConfirm = useCallback(async () => {
    setSuccessMessage(undefined);
    setErrorMessage(undefined);
    const dto: UpdateManyDocumentVersionsDto = {
      ids: selectedDocumentVersionIds,
    };
    if (selectedDisciplineMetaDataIds !== undefined) dto.disciplineIds = { value: selectedDisciplineMetaDataIds };
    if (selectedBuildingMetaDataId !== undefined) dto.buildingId = { value: selectedBuildingMetaDataId };
    if (selectedWorkPhaseId !== undefined) dto.workPhaseId = { value: selectedWorkPhaseId };
    if (selectedTagIds !== undefined) {
      dto.tagIds = { value: selectedTagIds };
      dto.addTags = selectedAddTags;
    }
    if (selectedDocumentStatusId !== undefined) dto.documentStatusId = { value: selectedDocumentStatusId };
    if (selectedFloorMetaDataId !== undefined) dto.floorMetaDataId = { value: selectedFloorMetaDataId };
    if (selectedFolderId !== undefined) dto.folderId = { value: selectedFolderId };
    try {
      const result = await mutateAsync(dto);
      if (result) {
        setSuccessMessage(t('mass-edit-drawer_apply-success-message', 'Edits have been applied to {{count}} documents.', { count: selectedDocumentVersionIds.length }));
        resetForm();
      }
    } catch (error) {
      setErrorMessage(
        (axios.isAxiosError(error)
          ? error.response?.data?.message
            ? error.response?.data?.message
            : error.message
          : t('mass-edit-drawer_apply-unknown-error-message', 'Editing failed due to an unknown problem.')),
      );
    }
  }, [selectedDocumentVersionIds, selectedDisciplineMetaDataIds, selectedBuildingMetaDataId, selectedWorkPhaseId, selectedTagIds, selectedDocumentStatusId, selectedFloorMetaDataId, selectedFolderId, selectedAddTags, mutateAsync, t, resetForm]);

  return (
    <RightUtilityDrawer id="MassEditDrawer" isOpen={isOpen} openWidth={340} sx={{ py: 3, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ px: 2, pb: 1, display: 'flex' }}>
        <Box>
          <Typography variant="h3">{t('mass-edit-drawer_header', 'Edit Documents')}</Typography>
          <Typography>{t('mass-edit-drawer_subheader', '{{count}} documents selected', { count: selectedDocumentVersionIds.length })}</Typography>
        </Box>
        <RoundIconButton Icon={CloseIcon} onClick={onClose} sx={{ ml: 'auto' }} />
      </Box>
      <Box sx={{ flex: '1 1 0', overflowY: 'auto', px: 2 }} className="custom-scrollbar">
        <MassEditSelectFormControl
          sx={{ mt: 2 }}
          availableEntities={floors}
          label={t('mass-edit-drawer_floors-label', 'Floor')}
          selectedId={selectedFloorMetaDataId}
          onChange={(id) => setSelectedFloorMetaDataId(id)}
          disabled={!floors?.length || isLoading}
        />
        <MassEditMultiSelectFormControl
          sx={{ mt: 2 }}
          availableEntities={disciplines}
          label={t('mass-edit-drawer_disciplines-label', 'Disciplines')}
          selectedIds={selectedDisciplineMetaDataIds}
          onChange={(ids) => setSelectedDisciplineMetaDataIds(ids)}
          disabled={!disciplines?.length || isLoading}
        />
        <MassEditSelectFormControl
          sx={{ mt: 2 }}
          availableEntities={buildings}
          label={t('mass-edit-drawer_buildings-label', 'Building')}
          selectedId={selectedBuildingMetaDataId}
          onChange={(id) => setSelectedBuildingMetaDataId(id)}
          disabled={!buildings?.length || isLoading}
        />
        <MassEditSelectFormControl
          sx={{ mt: 2 }}
          availableEntities={workPhases}
          label={t('mass-edit-drawer_workphase-label', 'Workphase')}
          selectedId={selectedWorkPhaseId}
          onChange={(id) => setSelectedWorkPhaseId(id)}
          disabled={!workPhases?.length || isLoading}
        />
        <MassEditSelectFormControl
          sx={{ mt: 2 }}
          availableEntities={visibleDocumentStatuses}
          label={t('mass-edit-drawer_status-label', 'Status')}
          selectedId={selectedDocumentStatusId}
          onChange={(id) => setSelectedDocumentStatusId(id)}
          disabled={!visibleDocumentStatuses?.length || isLoading}
          renderValuesAsChips
        />
        <MassEditMultiSelectFormControl
          sx={{ mt: 4 }}
          availableEntities={tags}
          label={t('mass-edit-drawer_tags-label', 'Tags')}
          selectedIds={selectedTagIds}
          onChange={(ids) => setSelectedTagIds(ids)}
          disabled={!tags?.length || isLoading}
        />
        <FormControl disabled={selectedTagIds === undefined || isLoading} sx={{ mt: 1 }}>
          <RadioGroup
            aria-label={t('mass-edit-drawer_tags-edit-mode-label', 'Tag edit mode')}
            value={selectedAddTags ? 'add' : 'replace'}
            onChange={(e) => setSelectedAddTags(e.target.value === 'add')}
            row
          >
            <FormControlLabel value="replace" control={<Radio />} label={t('mass-edit-drawer_tags-edit-mode-replace', 'Replace Tags')} />
            <FormControlLabel value="add" control={<Radio />} label={t('mass-edit-drawer_tags-edit-mode-add', 'Add Tags')} disabled={!selectedTagIds?.length || undefined} />
          </RadioGroup>
        </FormControl>
        <MassEditFolderSelectFormControl
          sx={{ mt: 4 }}
          label={t('mass-edit-drawer_folder-label', 'Folder')}
          selectedId={selectedFolderId}
          onChange={(id) => setSelectedFolderId(id)}
          disabled={!currentProject || isLoading}
        />
      </Box>
      <Box sx={{ px: 2, pt: 2 }}>
        {!!errorMessage && (
          <Alert severity="error" sx={{ my: 2 }} onClose={() => setErrorMessage(undefined)} title={t('mass-edit-drawer_apply-error-title', 'Error')}>{errorMessage}</Alert>
        )}
        {!!successMessage && (
          <Alert severity="success" sx={{ my: 2 }} onClose={() => setSuccessMessage(undefined)}>
            <AlertTitle>{t('mass-edit-drawer_apply-success-title', 'Success')}</AlertTitle>
            {successMessage}
          </Alert>
        )}
        <Button fullWidth variant="contained" color="primary" onClick={onConfirm} disabled={!selectedDocumentVersionIds?.length || isLoading} sx={{ mt: 1 }}>
          {t('mass-edit-drawer_apply-changes', 'Apply changes')}
          {isLoading && <CircularProgress size={12} sx={{ ml: 1 }} />}
        </Button>
      </Box>
    </RightUtilityDrawer>
  );
}
