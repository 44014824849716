import { useQueryClient } from '@tanstack/react-query';
import DocumentListDto from 'documents-lists/types/DocumentListDto';
import useDocumentListQueryKey from 'documents-lists/hooks/useDocumentListQueryKey';
import { useCallback } from 'react';

export default function useDocumentListQueryData() {
  const queryClient = useQueryClient();
  const { getQueryKey } = useDocumentListQueryKey();
  return useCallback(async (id: string) => {
    const queryKey = getQueryKey(id);
    return await queryClient.fetchQuery<DocumentListDto>({ queryKey });
  }, [getQueryKey, queryClient]);
}
