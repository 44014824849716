import {
  IfcContext,
  IfcManager,
  ViewerOptions,
  IfcGrid,
  IfcAxes,
  IfcClipper,
  DropboxAPI,
  IfcStats,
  Edges,
  SectionFillManager,
  IfcDimensions,
} from "./components";
import { GLTFManager } from "./components/import-export/glTF";
import { ShadowDropper } from "./components/display/shadow-dropper";
import { DXFWriter } from "./components/import-export/dxf";
import { PDFWriter } from "./components/import-export/pdf";
import { EdgesVectorizer } from "./components/import-export/edges-vectorizer";
import { ViewerSetting } from "./base-types";
import { VisoViewpoint } from "./viso-types";
import { Viewpoint } from "./viewpoint";
import { VisoplanService } from "./visoplan-service";
import { IfcStorey } from "./components/display/storey";
import { NavigationCube } from "./components/context/nav-cube/NavCube";
import { Color } from "three";

export class IfcViewerAPI {
  context: IfcContext;
  IFC: IfcManager;
  clipper: IfcClipper;
  filler: SectionFillManager;
  dimensions: IfcDimensions;
  edges: Edges;
  shadowDropper: ShadowDropper;
  dxf: DXFWriter;
  pdf: PDFWriter;
  edgesVectorizer: EdgesVectorizer;
  gltf: GLTFManager;
  grid: IfcGrid;
  axes: IfcAxes;
  stats?: IfcStats;
  dropbox?: DropboxAPI;
  viewpoint: Viewpoint;
  storey: IfcStorey;
  service: VisoplanService;
  navCube?: NavigationCube;

  constructor(options: ViewerOptions, baseURL: string) {
    if (!options.container) throw new Error("Could not get container element!");

    this.service = new VisoplanService(baseURL);
    this.context = new IfcContext(options);
    this.IFC = new IfcManager(this.context, this.service);
    this.grid = new IfcGrid(this.context);
    this.axes = new IfcAxes(this.context);
    this.clipper = new IfcClipper(this.context, this.IFC);
    this.filler = new SectionFillManager(this.IFC, this.context);
    this.dimensions = new IfcDimensions(this.context);
    this.edges = new Edges(this.context);
    this.shadowDropper = new ShadowDropper(this.context, this.IFC);
    this.edgesVectorizer = new EdgesVectorizer(
      this.context,
      this.clipper,
      this.grid,
      this.axes
    );
    this.dxf = new DXFWriter();
    this.pdf = new PDFWriter();
    this.gltf = new GLTFManager(this.context);
    this.viewpoint = new Viewpoint(this.context, this.IFC, this.clipper);
    this.storey = new IfcStorey(this.context, this.service);
  }

  /**
   * Adds [stats](https://github.com/mrdoob/stats.js/) to the scene for testing purposes. For example:
   * ```js
   *     this.loader.addStats('position:fixed;top:6rem;right:0px;z-index:1;');
   * ```
   * @css The css text to control where to locate the stats.
   * @stats The stats.js API object
   */
  addStats(css = "", stats?: any) {
    // @ts-ignore
    this.stats = new IfcStats(this.context);
    this.stats?.initializeStats(stats);
    this.stats?.addStats(css);
  }

  removeStats() {
    this.stats?.removeStats();
    this.stats?.dispose();
  }

  toggleStats() {
    this.stats?.toggleStats();
  }

  /**
   * Adds a clipping plane on the face pointed to by the cursor.
   */
  addClippingPlane = (isControllerVisible: boolean) => {
    return this.clipper.createPlane(true, isControllerVisible);
  };

  /**
   * Turns on / off all clipping planes.
   */
  toggleClippingPlanes = () => {
    this.clipper.active = !this.clipper.active;
  };

  /**
   * Opens a dropbox window where the user can select their IFC models.
   */
  openDropboxWindow() {
    if (!this.dropbox) this.dropbox = new DropboxAPI(this.context, this.IFC);
    this.dropbox?.loadDropboxIfc();
  }

  dispose() {
    this.context.dispose();
    this.storey.dispose();
    this.navCube?.dispose();
  }

  setViewerSetting(setting: ViewerSetting) {
    this.context.setCameraProjection(setting.camera);
    this.context.setNavigationMode(setting.control);
    if (!!setting.background) this.context.setBackground(setting.background);

    const sectionColor = new Color(setting.sectionSpace);
    this.IFC.setVisibleSetting(setting.spaces, setting.openings, this.context.getClippingPlanes().length ? sectionColor : undefined);
    this.context.updateSectionColor(sectionColor);
    this.context.sectionLineColor = new Color(setting.sectionOutline);
    this.clipper.planes.forEach((p) => p.updateLineColor());
    this.grid.setVisible(setting.grid);
    if (!!this.navCube)
      this.navCube.visible = setting.navCube;
  }

  isModelLoaded() {
    return this.context.isModelLoaded();
  }

  addNavCube(style: string) {
    this.navCube = new NavigationCube(this.context, style);
  }

  enableNavCube(active: boolean) {
    if (!!this.navCube)
      this.navCube.visible = active;
  }

  async createViewpoint(projId: string, token: any) {
    const camera = this.context.ifcCamera.getCameraInfo();
    const sections = this.clipper.getIfcSections();
    const visualState = this.IFC.visoLoader.ifcManager.subsets.getVisualState();
    const bubble = this.IFC.getBubblePoint();
    const image = this.context.renderer.newScreenshot();
    const imageId = await this.getImageId(projId, image, token);

    return {
      camera,
      sections,
      visualState,
      bubble,
      image,
      imageId,
    } as VisoViewpoint;
  }

  getLoadedModelIds() {
    return this.context.items.ifcModels.map((m) => m.modelFileId);
  }

  updateSectionSpaces() {
    this.clipper.updateSectionSpaces();

    const spaceColor = this.context.getClippingPlanes().length ? this.context.sectionSpaceColor : undefined;
    this.IFC.setSectionSpaceColor(spaceColor);
  }

  resetViewState() {
    this.context.isDirty = false;
  }

  private async getImageId(projId: string, image: string, token: any) {
    const fetchImage = await fetch(image);
    const blob = await fetchImage.blob();
    const formData = new FormData();
    formData.append("file", blob, "snapshot.png");
    const response = await this.service.postImageAsync(projId, formData, token);

    return response.data;
  }
}
