import CreateQueryFilterDto from 'documents-filter/types/CreateQueryFilterDto';
import UpdateQueryFilterDto from 'documents-filter/types/UpdateQueryFilterDto';
import * as Request from 'Helpers/Request';

export const filterTypes = {
  document: 0,
  issue: 1,
  email: 2,
};

export const getProjectFilters = async () => {
  const result = await Request.GET('api/query-filter');
  return result.status === 200 ? result.body : [];
};

export const createNewFilter = async (dto: CreateQueryFilterDto) => {
  const result = await Request.POST('api/query-filter', dto);
  return result.status === 200 ? result.body : [];
};

export const updateFilter = async (dto: UpdateQueryFilterDto) => {
  const result = await Request.PATCH('api/query-filter', dto);
  return result.status === 200;
};

export const deleteFilter = async (id: string) => {
  const result = await Request.DELETE('api/query-filter', id);
  return result.status === 200;
};
