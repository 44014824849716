import { WebViewerInstance } from '@pdftron/webviewer';
import AnnotationItem from 'documents-annotations/types/AnnotationItem';
import CompareOptions from 'documents-details/types/CompareOptions';
import DocumentDetailsTab from 'documents-details/types/DocumentDetailsTab';
import { Dispatch, SetStateAction, createContext } from 'react';

export interface DocumentViewerContextState {
  webViewerInstance: WebViewerInstance | undefined,
  setWebViewerInstance: Dispatch<SetStateAction<WebViewerInstance | undefined>>,
  annotationItems: AnnotationItem[] | undefined,
  setAnnotationItems: Dispatch<SetStateAction<AnnotationItem[] | undefined>>,
  selectedAnnotationNames: string[] | undefined,
  setSelectedAnnotationNames: Dispatch<SetStateAction<string[] | undefined>>,
  activeTab: DocumentDetailsTab,
  setActiveTab: Dispatch<SetStateAction<DocumentDetailsTab>>,
  issueIdCurrentlyLinking: string | undefined,
  setIssueIdCurrentlyLinking: Dispatch<SetStateAction<string | undefined>>,
  compareOptions: CompareOptions,
  setCompareOptions: Dispatch<SetStateAction<CompareOptions>>,
}

const defaultState: DocumentViewerContextState = {
  webViewerInstance: undefined,
  setWebViewerInstance: undefined!,
  annotationItems: undefined,
  setAnnotationItems: undefined!,
  selectedAnnotationNames: undefined,
  setSelectedAnnotationNames: undefined!,
  activeTab: undefined!,
  setActiveTab: undefined!,
  issueIdCurrentlyLinking: undefined!,
  setIssueIdCurrentlyLinking: undefined!,
  compareOptions: undefined!,
  setCompareOptions: undefined!,
};

const DocumentViewerContext = createContext<DocumentViewerContextState>(defaultState);
export default DocumentViewerContext;
