import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import _ from 'lodash';
import { createPortal } from 'react-dom';
import Color from 'color';
import WebViewer, { Core } from '@pdftron/webviewer';
import ISxProps from 'common/types/ISxProps';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';
import useDocumentVersionQuery from 'documents/hooks/useDocumentVersionQuery';
import useApiConfig from 'api/hooks/useApiConfig';
import FileType from 'documents/types/FileType';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';
import PdfAnnotation from 'documents-annotations/types/PdfAnnotation';
import usePdfAnnotationsQuery from 'documents-annotations/hooks/usePdfAnnotationsQuery';
import CompareOptions from 'documents-details/types/CompareOptions';
import { useTranslation } from 'react-i18next';
import useStampInsertionDownloadFunction from 'Components/DocumentPreviewModal/hooks/useStampInsertionDownloadFunction';
import useDocumentVersionNumberString from 'documents/hooks/useDocumentVersionNumberString';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import LabelDto from 'labels/types/LabelDto';
import buildQuery from 'odata-query';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import useIssuesOdataQuery from 'issues/hooks/useIssuesOdataQuery';
import IssueDto from 'issues/types/IssueDto';

const rectRegex = /([^,]+),([^,]+),([^,]+),([^,]+)/;
const ANNOTATION_SIZE = 48;
const serializer = new XMLSerializer();
const parser = new DOMParser();

export enum CompareMode {
  Text = 'text',
  Image = 'image',
}

interface DocumentViewerProps extends ISxProps {
  documentVersionId: string | undefined,
  selectedAnnotationNames: string[] | undefined,
  setSelectedAnnotationNames: Dispatch<SetStateAction<string[] | undefined>>,
  annotationModeActive: boolean,
  compareOptions: CompareOptions,
}

export default function DocumentViewer({
  sx,
  documentVersionId,
  selectedAnnotationNames,
  setSelectedAnnotationNames,
  annotationModeActive,
  compareOptions,
}: DocumentViewerProps) {
  const { t, i18n } = useTranslation('document-details');
  const theme = useTheme();
  const viewerRef = useRef<HTMLDivElement>(null);
  const { authProjectToken } = useVisoplanApiContext();
  const initialized = useRef<boolean>(false);
  const { data: documentVersion } = useDocumentVersionQuery(documentVersionId);
  const { data: compareDocumentVersion } = useDocumentVersionQuery(compareOptions.enabled ? compareOptions.compareDocumentVersionId : undefined);
  const { odataQuery } = useIssuesFilterContext();
  const { webViewerInstance, setWebViewerInstance, setAnnotationItems, issueIdCurrentlyLinking } = useDocumentViewerContext();
  const { data: issues } = useIssuesOdataQuery(odataQuery);
  const pdfAnnotationsOdataQuery = useMemo(() => {
    if (!documentVersionId) return undefined;
    return { filter: [
      { documentVersionId: { eq: documentVersionId } },
      ...(issueIdCurrentlyLinking ? [{ linkedIssueId: issueIdCurrentlyLinking }] : []),
    ] };
  }, [documentVersionId, issueIdCurrentlyLinking]);
  const { data: allLinkedPdfAnnotations } = usePdfAnnotationsQuery(pdfAnnotationsOdataQuery);
  const pdfAnnotations = useMemo(() => {
    if (!issues || !allLinkedPdfAnnotations) return undefined;
    const issueIdsSet = new Set(issues.map((i) => i.id));
    return allLinkedPdfAnnotations.filter((a) => !a.linkedIssueId || issueIdsSet.has(a.linkedIssueId));
  }, [allLinkedPdfAnnotations, issues]);
  const issuesById = useMemo(() => {
    if (!issues) return undefined;
    return new Map<string, IssueDto>(issues.map((issue) => [issue.id, issue]));
  }, [issues]);

  const { apiUrl } = useApiConfig();
  const [internalAnnotationsLoaded, setInternalAnnotationsLoaded] = useState<boolean>(false);
  const [annotationsByName, setAnnotationsByName] = useState<Map<string, Core.Annotations.Annotation> | undefined>(undefined);
  const [viewerSelectedAnnotationNames, setViewerSelectedAnnotationNames] = useState<string[] | undefined>();
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [loadingFailed, setLoadingFailed] = useState(false);

  const customHeaderItemsRef = useRef<HTMLElement>(document.createElement('div'));

  useEffect(() => {
    if (initialized.current) return;
    WebViewer(
      {
        fullAPI: true, // required for the compare feature
        path: '/webviewer/lib',
        licenseKey: 'Visoplan GmbH (visoplan.de):OEM:Visoplan::B+:AMS(20240229):89A5D22D04B7280A0360B13AC9A2537860612FCDE9267A15AB12ACF2EF3C208E54AA31F5C7',
        disabledElements: [
          'linkButton',
          'annotationCommentButton',
          'contextMenuPopup',
          'textPopup',
          'toolbarGroup-Shapes',
          'toolbarGroup-Annotate',
          'toolbarGroup-Insert',
          'toolbarGroup-Edit',
          'toolbarGroup-FillAndSign',
          'toolbarGroup-Forms',
          'toggleNotesButton',
          'downloadButton',
          'saveAsButton',
          'printButton',
          'thumbnailControl',
        ],
        css: '/webviewer.css',
        enableMeasurement: true,
      },
      viewerRef.current!,
    ).then((instance) => {
      const { documentViewer, annotationManager } = instance.Core;
      instance.UI.disableReplyForAnnotations(() => true);
      instance.UI.setToolbarGroup('toolbarGroup-View');
      instance.UI.setLanguage(i18n.language.substring(0, 2));

      instance.UI.setHeaderItems((header) => {
        const headerItems = header.getHeader('HeaderItems');
        headerItems.push({
          type: 'divider',
        });
        headerItems.push({
          type: 'customElement',
          render: () => customHeaderItemsRef.current,
        });

        const annotateHeader = header.getHeader('toolbarGroup-Annotate');
        annotateHeader.delete('shapeToolGroupButton'); // rectangle tool is added again at the end
        annotateHeader.delete('freeTextToolGroupButton');
        annotateHeader.delete('stickyToolGroupButton');
        annotateHeader.delete('markInsertTextGroupButton');
        annotateHeader.delete('markReplaceTextGroupButton');

        annotateHeader.push({
          type: 'toolGroupButton',
          toolGroup: 'arrowTools',
          dataElement: 'arrowToolGroupButton',
          title: 'annotation.arrow',
        });
        annotateHeader.push({
          type: 'toolGroupButton',
          toolGroup: 'rectangleTools',
          dataElement: 'shapeToolGroupButton',
          title: 'annotation.rectangle',
        });
        annotateHeader.push({
          type: 'toolGroupButton',
          toolGroup: 'ellipseTools',
          dataElement: 'ellipseToolGroupButton',
          title: 'annotation.ellipse',
        });
        annotateHeader.push({
          type: 'toolGroupButton',
          toolGroup: 'cloudTools',
          dataElement: 'polygonCloudToolGroupButton',
          title: 'annotation.polygonCloud',
        });
        annotateHeader.push({
          type: 'toolGroupButton',
          toolGroup: 'polygonTools',
          dataElement: 'polygonToolGroupButton',
          title: 'annotation.polygon',
        });
      });
      instance.UI.enableElements(['ribbons']);

      documentViewer.addEventListener('documentUnloaded', () => {
        setAnnotationItems(undefined);
        setInternalAnnotationsLoaded(false);
        setIsLoadingDocument(false);
      });
      documentViewer.addEventListener('documentLoaded', () => {
        const docId = instance.Core.documentViewer.getDocument()?.getDocumentId();
        if (!docId) return;
        instance.UI.setLayoutMode(docId.startsWith('compare_') ? instance.UI.LayoutMode.FacingContinuous : instance.UI.LayoutMode.Single);
        setIsLoadingDocument(false);
      });

      const makeAnnotationSlightlyTransparent = (annotation: Core.Annotations.Annotation) => {
        // eslint-disable-next-line no-param-reassign
        annotation.Color = new instance.Core.Annotations.Color(annotation.Color.R, annotation.Color.G, annotation.Color.B, 0.35);
      };
      const resetAnnotationColor = (annotation: Core.Annotations.Annotation) => {
        const originalColorJson = annotation.getCustomData('originalColor');
        if (!originalColorJson) return;
        const originalColor = JSON.parse(originalColorJson) as { R: number, G: number, B: number, A: number };
        // eslint-disable-next-line no-param-reassign
        annotation.Color = new instance.Core.Annotations.Color(originalColor.R, originalColor.G, originalColor.B, originalColor.A);
      };
      const updateViewerSelection = () => {
        const selectedNames = annotationManager.getSelectedAnnotations().map((a) => a.Id);
        setViewerSelectedAnnotationNames(selectedNames);

        if (selectedNames.length > 0 && !issueIdCurrentlyLinking) {
          const selectedNamesSet = new Set(selectedNames);
          const annotationsToHide = instance.Core.annotationManager.getAnnotationsList().filter((a) => !selectedNamesSet.has(a.Id) && a.getCustomData('annotationKind') !== 'temp');
          annotationsToHide.forEach(makeAnnotationSlightlyTransparent);
          const annotationsToShow = instance.Core.annotationManager.getAnnotationsList().filter((a) => selectedNamesSet.has(a.Id));
          annotationsToShow.forEach(resetAnnotationColor);
        } else {
          instance.Core.annotationManager.getAnnotationsList().forEach(resetAnnotationColor);
        }
        instance.Core.annotationManager.drawAnnotationsFromList(instance.Core.annotationManager.getAnnotationsList());
      };
      const updateViewerSelectionDebounced = _.debounce(updateViewerSelection, 350);
      annotationManager.addEventListener('annotationSelected', (annotations: Core.Annotations.Annotation[], action: string) => {
        if (action === 'deselected') {
          // When an annotation is selected and we select another annotation, a 'deselected' and a 'selected' event are fired.
          // Without debouncing, this would noticably and annoyingly close the previous issue and then open the next issue.
          // With debouncing we transition directly from the previously annotation's issue to the next annotation's issue,
          // because leaving the issue due to the deselection is delayed until after the 'selected' event and the actual selection state is settled.
          updateViewerSelectionDebounced();
        } else {
          updateViewerSelection();
        }
      });
      documentViewer.addEventListener('annotationsLoaded', () => {
        const annotations = annotationManager.getAnnotationsList();
        annotations.forEach((a) => {
          /* eslint-disable no-param-reassign */
          if (a.DateModified) {
            a.setCustomData('originalDateModified', a.DateModified.toISOString());
          }
          a.setCustomData('originalColor', JSON.stringify({ ...a.Color }));
          a.LockedContents = true;
          a.Locked = true;
          a.ReadOnly = true;
          a.setCustomData('annotationKind', 'internal');
          /* eslint-enable no-param-reassign */
        });
        setInternalAnnotationsLoaded(true);
      });

      const toolMap = documentViewer.getToolModeMap();
      // @ts-ignore
      // eslint-disable-next-line no-restricted-syntax
      for (const tool of Object.values(toolMap)) {
        if (tool instanceof instance.Core.Tools.FreeHandCreateTool) {
          // @ts-ignore
          tool.setCreateDelay(0);
        }
      }

      setWebViewerInstance(instance);
    });
    initialized.current = true;
  }, [i18n, issueIdCurrentlyLinking, setAnnotationItems, setWebViewerInstance]);

  const compareDocumentVersions = useCallback(async () => {
    if (!webViewerInstance || !authProjectToken || !compareDocumentVersion || !documentVersion) return;

    const docId = `compare_${documentVersionId}_${compareOptions.compareDocumentVersionId}_${compareOptions.mode}`;

    if (webViewerInstance.Core.documentViewer.getDocument()?.getDocumentId() === docId) return;

    webViewerInstance.UI.closeDocument();

    setIsLoadingDocument(true);

    const customHeaders = {
      Authorization: `Bearer ${authProjectToken.bearer}`,
    };
    const [resourceIdA, resourceIdB] = [compareDocumentVersion, documentVersion].map((v) => ((v.fileType === FileType.Pdf || (v.fileType === FileType.Image && !v.originalFileName.endsWith('.svg')))
      ? v.fileId
      : (v.fileType === FileType.DWG)
        ? v.previewFileId
        : undefined));

    const fileUriA = `${apiUrl}/${ApiEndpoint.Resource}/${resourceIdA}`;
    const fileUriB = `${apiUrl}/${ApiEndpoint.Resource}/${resourceIdB}`;
    await webViewerInstance.Core.PDFNet.initialize();
    const docA = await webViewerInstance.Core.PDFNet.PDFDoc.createFromURL(fileUriA, { withCredentials: true, customHeaders });
    const docB = await webViewerInstance.Core.PDFNet.PDFDoc.createFromURL(fileUriB, { withCredentials: true, customHeaders });
    const newDoc = await webViewerInstance.Core.PDFNet.PDFDoc.create();
    await newDoc.lock();
    if (compareOptions.mode === CompareMode.Text) {
      await newDoc.appendTextDiffDoc(docA, docB);
    } else {
      const getPageArray = async (doc: Core.PDFNet.PDFDoc) => {
        const arr = [];
        const itr = await doc.getPageIterator(1);
        /* eslint-disable no-await-in-loop */
        while (await itr.hasNext()) {
          const page = await itr.current();
          arr.push(page);
          itr.next();
        }
        /* eslint-enable no-await-in-loop */
        return arr;
      };
      const pagesA = await getPageArray(docA);
      const pagesB = await getPageArray(docB);
      const diffOptions = new webViewerInstance.Core.PDFNet.PDFDoc.DiffOptions();
      const colorA = Color(theme.palette.success.main);
      const colorB = Color(theme.palette.error.main);
      const rgbaA = { R: colorA.red(), G: colorA.green(), B: colorA.blue(), A: 1 };
      const rgbaB = { R: colorB.red(), G: colorB.green(), B: colorB.blue(), A: 1 };
      diffOptions.setColorA(rgbaA);
      diffOptions.setColorB(rgbaB);
      /* eslint-disable no-restricted-syntax */
      for await (const [pageA, pageB] of pagesA.map((page, index) => [page, pagesB[index]])) {
        if (pageB) {
          await newDoc.appendVisualDiff(pageA, pageB, diffOptions);
        }
      }
      /* eslint-enable no-await-in-loop */
    }
    await newDoc.unlock();
    webViewerInstance.UI.loadDocument(newDoc, {
      filename: `${docId}.pdf`,
      docId,
      onError: () => { setIsLoadingDocument(false); },
    });
  }, [apiUrl, authProjectToken, compareDocumentVersion, compareOptions, documentVersion, documentVersionId, webViewerInstance, theme]);

  const loadSingleDocumentVersion = useCallback(() => {
    if (!webViewerInstance || !authProjectToken || !documentVersion) return;

    if (webViewerInstance.Core.documentViewer.getDocument()?.getDocumentId() === documentVersionId) return;

    const resourceId = (documentVersion.fileType === FileType.Pdf || (documentVersion.fileType === FileType.Image && !documentVersion.originalFileName.endsWith('.svg')))
      ? documentVersion.fileId
      : (documentVersion.fileType === FileType.DWG)
        ? documentVersion.previewFileId
        : undefined;

    if (!resourceId) return;

    setIsLoadingDocument(true);

    const fileName = documentVersion.fileType === FileType.DWG ? `${documentVersion.originalFileName}.pdf` : documentVersion.originalFileName;

    const fileUri = `${apiUrl}/${ApiEndpoint.Resource}/${resourceId}`;
    webViewerInstance.UI.loadDocument(fileUri, {
      customHeaders: {
        Authorization: `Bearer ${authProjectToken.bearer}`,
      },
      filename: fileName,
      docId: documentVersionId,
      onError: () => {
        setIsLoadingDocument(false);
        setLoadingFailed(true);
      },
    });
  }, [apiUrl, authProjectToken, documentVersion, documentVersionId, setLoadingFailed, webViewerInstance]);

  useEffect(() => {
    if (!webViewerInstance || !authProjectToken || !documentVersion || isLoadingDocument || loadingFailed) return;

    if (documentVersionId) {
      if (compareOptions?.enabled) {
        // load two document versions for comparison
        if (!compareDocumentVersion) return;
        compareDocumentVersions();
      } else {
        // load single document version
        loadSingleDocumentVersion();
      }
    } else {
      // unload document version if no document version id is set
      webViewerInstance.UI.closeDocument();
      setIsLoadingDocument(false);
    }
  }, [authProjectToken, compareDocumentVersion, compareDocumentVersions, compareOptions, isLoadingDocument, documentVersion, documentVersionId, webViewerInstance, loadSingleDocumentVersion, loadingFailed]);

  const importVisoplanAnnotations = useCallback(async () => {
    if (!documentVersionId || !webViewerInstance || !pdfAnnotations || !issuesById) return;
    const resultXfdfDocument = parser.parseFromString('<?xml version="1.0" encoding="UTF-8"?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><annots></annots></xfdf>', 'application/xml');
    const resultRoot = resultXfdfDocument.documentElement.children[0];
    const visoplanAnnotationsByName = new Map<string, PdfAnnotation>();

    pdfAnnotations
      .forEach((pdfAnnotation) => {
        const issue = pdfAnnotation.linkedIssueId ? issuesById.get(pdfAnnotation.linkedIssueId) : undefined;
        const xfdfDocument = parser.parseFromString(pdfAnnotation.xfdfString, 'application/xml');
        const root = xfdfDocument.documentElement;
        const annots = root.children[0];
        if (!annots || annots.nodeName !== 'annots' || !annots.hasChildNodes()) {
          return;
        }
        const namedChildElements = Array.from(annots.children).filter((child) => child.hasAttribute('name'));
        const processedElements = namedChildElements.map((element) => {
          if (element.getAttribute('title') === 'Visoplan Location' && !!issue) {
            const name = element.getAttribute('name') as string;
            const page = element.getAttribute('page') as string;
            const rect = element.getAttribute('rect') as string;
            const color = (issue.issueStatus ? Color(issue.issueStatus.color) : Color('#4b6ffc')).mix(Color('#ffffff'), 0.3).hex();

            const rectMatch = rect.match(rectRegex)!;
            const [, ...coords] = rectMatch;
            const [x1, , , y2] = coords.map(parseFloat);

            const vertices = `${x1},${y2};${x1 + (ANNOTATION_SIZE / 4)},${y2 - ANNOTATION_SIZE};${x1 + (ANNOTATION_SIZE / 2)},${y2 - (ANNOTATION_SIZE / 2)};${x1 + ANNOTATION_SIZE},${y2 - (ANNOTATION_SIZE / 4)};${x1},${y2}`;
            const polygonString = `<polygon
            interior-color="${color}"
            width="0"
            opacity="0.8"
            creationdate="D:20231031091835+01'00'"
            flags="print,nozoom"
            date="D:20231031091835+01'00'"
            name="${name}"
            page="${page}"
            rect="${rect}"
            subject="Polygon"
            title="Visoplan Location">
            <vertices>${vertices}</vertices>
            </polygon>`;
            const xmlDoc = parser.parseFromString(polygonString, 'text/xml') as XMLDocument;
            return xmlDoc.firstElementChild;
          }
          return element;
        }).filter(Boolean).map((e) => e!);
        resultRoot.append(...processedElements);
        const annotationNames = namedChildElements.map((element) => element.getAttribute('name')!);
        annotationNames.forEach((name) => visoplanAnnotationsByName.set(name, pdfAnnotation));
      });

    const resultXfdfString = serializer.serializeToString(resultXfdfDocument);

    const previousVisoplanAnnotations = webViewerInstance.Core.annotationManager.getAnnotationsList().filter((a) => a.getCustomData('annotationKind') === 'visoplan');
    webViewerInstance.Core.annotationManager.deleteAnnotations(previousVisoplanAnnotations, { force: true });

    if (!compareOptions.enabled) {
      const importedAnnotations = await webViewerInstance.Core.annotationManager.importAnnotations(resultXfdfString) as Core.Annotations.Annotation[];

      // console.log(JSON.stringify({ importedAnnotations }));

      importedAnnotations.forEach((a) => {
        /* eslint-disable no-param-reassign */
        a.LockedContents = true;
        a.Locked = true;
        a.ReadOnly = true;
        a.Author = `${a.Author}`;
        a.setCustomData('originalColor', JSON.stringify({ ...a.Color }));
        if (a.Subject === 'Visoplan Location') {
          a.setCustomData('isLocation', 'true');
        }
        a.setCustomData('annotationKind', 'visoplan');
        /* eslint-enable no-param-reassign */
      });
    }

    const annotations = webViewerInstance.Core.annotationManager.getAnnotationsList();
    setAnnotationsByName(new Map(annotations.map((annotation) => [annotation.Id, annotation])));

    const annotationItems = annotations.map((annotation) => ({
      viewerAnnotation: annotation,
      visoplanAnnotation: visoplanAnnotationsByName.get(annotation.Id),
    }), []);
    setAnnotationItems(annotationItems);
  }, [compareOptions.enabled, documentVersionId, webViewerInstance, issuesById, pdfAnnotations, setAnnotationItems]);

  useEffect(() => {
    if (internalAnnotationsLoaded && pdfAnnotations) {
      importVisoplanAnnotations();
    }
  }, [compareOptions.enabled, importVisoplanAnnotations, internalAnnotationsLoaded, pdfAnnotations]);

  useEffect(() => {
    if (!webViewerInstance || !annotationsByName || !selectedAnnotationNames) return;
    const currentlySelectedNames = webViewerInstance.Core.annotationManager.getSelectedAnnotations().map((a) => a.Id);
    if (_.isEqual(currentlySelectedNames, selectedAnnotationNames)) return;

    webViewerInstance.Core.annotationManager.deselectAllAnnotations();
    if (!selectedAnnotationNames) return;
    const annotationsToSelect = selectedAnnotationNames.filter((name) => annotationsByName.has(name)).map((name) => annotationsByName.get(name)!);
    webViewerInstance.Core.annotationManager.selectAnnotations(annotationsToSelect);
  }, [annotationsByName, webViewerInstance, selectedAnnotationNames]);

  useEffect(() => {
    if (!viewerSelectedAnnotationNames) return;
    setSelectedAnnotationNames((prev) => (_.isEqual(prev, viewerSelectedAnnotationNames) ? prev : viewerSelectedAnnotationNames));
  }, [setSelectedAnnotationNames, viewerSelectedAnnotationNames]);

  useEffect(() => {
    if (!webViewerInstance) return;
    if (compareOptions?.enabled) {
      webViewerInstance.UI.disableElements(['ribbons']);
    } else if (annotationModeActive) {
      webViewerInstance.UI.disableElements(['ribbons']);
      webViewerInstance.UI.setToolbarGroup('toolbarGroup-Annotate');
      webViewerInstance.Core.documentViewer.getAnnotationHistoryManager().clear(); // reset undo buffer
    } else {
      webViewerInstance.UI.enableElements(['ribbons']);
      webViewerInstance.UI.setToolbarGroup('toolbarGroup-View');
    }
  }, [webViewerInstance, annotationModeActive, compareOptions?.enabled]);

  const [includeLocationsInDownload, setIncludeLocationsInDownload] = useState<boolean>(false);
  const getVersionString = useDocumentVersionNumberString();

  const { data: currentProject } = useCurrentProjectQuery();
  const axiosInstance = useAxiosInstance();
  const getStampInsertionDownloadFunction = useStampInsertionDownloadFunction();
  const onClickDownload = useCallback(async () => {
    if (!webViewerInstance || !documentVersion || !currentProject) return;
    const doc = webViewerInstance.Core.documentViewer.getDocument();

    // if enabled, insert a document stamp on the downloaded document by setting a custom doc.getFileData
    if (currentProject.stampSettings?.isStampInsertionOnDownloadEnabled) {
      const { locale } = currentProject.stampSettings;
      const versionString = `${getVersionString(documentVersion.versionNumber)}`;
      let statusString = '';
      if (documentVersion.status) {
        if (locale === i18n.language) {
          statusString = documentVersion.status.name;
        } else {
          // download status in the language that was configured in document settings.
          const odataQueryString = buildQuery<LabelDto>({ filter: { id: documentVersion.status.id } });
          const { data } = await axiosInstance.get<LabelDto[]>(`/${ApiEndpoint.Label}${odataQueryString}`, { headers: { 'Content-Language': locale } });
          statusString = data[0].name;
        }
      }
      doc.getFileData = getStampInsertionDownloadFunction(webViewerInstance, statusString, versionString);
    }

    let xfdfString: string | undefined;
    // If the user selected to NOT include visoplan anntations in the download, we generate an XFDF override with only the original annotations.
    if (!includeLocationsInDownload) {
      const hasInternalAnnotations = webViewerInstance.Core.annotationManager.getAnnotationsList().some((a) => a.isInternal());
      if (hasInternalAnnotations) {
        // This extracts a XFDF string for the doc's internal annotations (i.e. those annotations that were in it when it was uploaded, without visoplan's issue locations)
        const xfdfInfo = await doc.extractXFDF();
        xfdfString = xfdfInfo.xfdfString;
      } else {
        xfdfString = '<?xml version="1.0" encoding="UTF-8"?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><annots></annots></xfdf>';
      }
    }
    webViewerInstance.UI.downloadPdf({ xfdfString });
  }, [axiosInstance, currentProject, documentVersion, getStampInsertionDownloadFunction, getVersionString, i18n.language, includeLocationsInDownload, webViewerInstance]);

  const onChangeIncludeLocationsInDownload = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIncludeLocationsInDownload(event.target.checked);
  }, [setIncludeLocationsInDownload]);

  useEffect(() => {
    if (!webViewerInstance) return () => {};
    webViewerInstance.Core.annotationManager.addEventListener('annotationChanged.onWebViewerAnnotationChanged', (annotations: Core.Annotations.Annotation[], action: string) => {
      if (!annotationModeActive) return;
      if (action === 'add') {
        annotations.forEach((a) => {
          // eslint-disable no-param-reassign
          a.setCustomData('annotationKind', 'temp');
        });
      }
    });
    return () => {
      webViewerInstance.Core.annotationManager.removeEventListener('annotationChanged.onWebViewerAnnotationChanged');
    };
  }, [webViewerInstance, annotationModeActive]);

  return (
    <>
      <Box
        id="DocumentViewer"
        sx={{
          ...sx,
          ...(annotationModeActive ? { borderWidth: 4, borderStyle: 'solid', borderColor: theme.palette.info.main } : {}),
        }}
        ref={viewerRef}
      />
      {(!!isLoadingDocument || !webViewerInstance) && <CenteredCircularProgress />}
      {!!customHeaderItemsRef.current && createPortal(
        !annotationModeActive && (
        <div
          id="DocumentViewerDownloadButton"
          style={{
            padding: '4px 16px',
            display: 'flex',
            gap: '16px',
          }}
        >
          <button
            type="button"
            onClick={onClickDownload}
            className="viso-webviewer-button secondary"
          >
            {t('document-viewer_download-button-label', 'Download')}
          </button>
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <input
              type="checkbox"
              id="include-locations-in-download-checkbox"
              checked={!compareOptions?.enabled && includeLocationsInDownload}
              onChange={onChangeIncludeLocationsInDownload}
              style={compareOptions?.enabled ? {} : { cursor: 'pointer' }}
              disabled={!!compareOptions?.enabled}
            />
            <label
              htmlFor="include-locations-in-download-checkbox"
              style={compareOptions?.enabled ? { color: theme.palette.text.disabled } : { cursor: 'pointer' }}
            >
              {t('document-viewer_include-locations-in-download-checkbox-label', 'with locations')}
            </label>
          </div>
        </div>
        ),
        customHeaderItemsRef.current,
      )}
    </>
  );
}
