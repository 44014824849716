import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import CollaboratorDto from 'collaborators/types/CollaboratorDto';
import useCollaboratorEntityFactory from 'collaborators/hooks/useCollaboratorEntityFactory';
import Collaborator from 'collaborators/types/Collaborator';

export default function useUserByEmailQuery(email: string | undefined, options?: Omit<UseQueryOptions<Collaborator | undefined, unknown, Collaborator | undefined, QueryKey>, 'queryKey' | 'queryFn'> | undefined) {
  const axiosInstance = useAxiosInstance();
  const entityFactory = useCollaboratorEntityFactory();
  return useQuery<Collaborator | undefined>(['collaborator', 'by-email', email], async () => {
    if (!email) return undefined;
    const response = await axiosInstance.get<CollaboratorDto>(`/user/${email}`);
    return entityFactory(response.data);
  }, { ...options, enabled: (options?.enabled ?? true) && !!email });
}
