import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import FolderTreeDataGrid from 'documents-folders/components/FolderTreeDataGrid';
import FolderFilterContextProvider from 'documents-folders/contexts/FolderFilterContextProvider';
import FolderFilterToolbar from 'documents-folders/components/FolderFilterToolbar';
import ISxProps from 'common/types/ISxProps';
import useDocumentUiConfigContext from 'documents/hooks/useDocumentUiConfigContext';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import CreateFolderMenuButton from 'documents-folders/components/CreateFolderMenuButton';
import EditFolderDialogButton from 'documents-folders/components/EditFolderDialogButton';
import DeleteFolderDialogButton from 'documents-folders/components/DeleteFolderDialogButton';

interface FolderTreePanelProps extends ISxProps {
}

export default function FolderTreePanel({
  sx,
}: FolderTreePanelProps) {
  const { t } = useTranslation('documents-folders');
  const currentUserRole = useCurrentUserRole();
  const { suppressFolderManipulation } = useDocumentUiConfigContext();
  return (
    <Box id="FolderTreePanel" sx={{ ...sx, display: 'flex', flexDirection: 'column' }}>
      <FolderFilterContextProvider>
        <Box sx={{ px: 3, mb: 1 }}>
          <Box sx={{
            display: 'flex', alignItems: 'center', pt: 2, pb: 1,
          }}
          >
            <Typography variant="h4">{t('folder-tree-panel_header', 'Folders')}</Typography>
            {!suppressFolderManipulation && (
            <Box sx={{
              ml: 'auto', display: 'flex', columnGap: 1,
            }}
            >
              {!!currentUserRole?.allowedActions?.has(RoleAction.Folder_Create) && <CreateFolderMenuButton />}
              {!!currentUserRole?.allowedActions?.has(RoleAction.Folder_Edit) && <EditFolderDialogButton />}
              {!!currentUserRole?.allowedActions?.has(RoleAction.Folder_Delete) && <DeleteFolderDialogButton />}
            </Box>
            )}
          </Box>
          <FolderFilterToolbar />
        </Box>
        <FolderTreeDataGrid sx={{ flexGrow: 1, pr: 1 }} />
      </FolderFilterContextProvider>
    </Box>
  );
}
