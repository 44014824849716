import React, { useState, useCallback, useEffect, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty, uniq } from "lodash";

import * as moment from "moment";

import * as Common from "../Helpers/Common";
import * as EmailHelper from "../Helpers/Email";
import { calcBusinessDays } from "../Helpers/Date";
import {
  useFileUpload,
  useIssueFilterItems,
  useTags,
  usePreviewVersions,
} from "../Helpers/Hooks";
import {
  EmailService,
  FilterService,
  IssueService,
  DocumentService,
} from "../Services";
import * as Request from "../Helpers/Request";
import { getChangedIds } from "../Helpers/utils";

import IconButton from "../Components/Buttons/IconButton";
import AsideEmailFilter from "../Components/Aside/AsideEmailFilter";
import AsideEmailDetail from "../Components/Aside/AsideEmailDetail";
import ChangeStatusFeedback from "../Components/ChangeStatusFeedback";
import SearchInput from "../Components/SearchInput";
import NotificationModal from "../Components/NotificationModal";
import { SaveFilterModal } from "../Components/FilterOptions";
import LinkEmailIssues from "../Components/IssueModal/LinkEmailIssues";
import { Table } from "./Table";
import IssueOverviewModalOld from "../Components/IssueBoard/IssueOverviewModalOld";
import { Modal } from "../HOC";
import IssueModal from "../Components/IssueModal";
import { getColumnsForEmail } from "../Helpers/Common";
import UploadZone from "../Components/Upload/UploadZone";
import UploadProgressModal from "../Components/Upload/UploadProgress";
import DocumentPreviewModal from "../Components/DocumentPreviewModal";
import { formatIssue } from "../Services/IssueService";
import useCurrentProjectQuery from "projects/hooks/useCurrentProjectQuery";
import useCurrentUserQuery from "users/hooks/useCurrentUserQuery";
import useCurrentUserRole from "users/hooks/useCurrentUserRole";
import RoleAction from "projects/types/RoleAction";
import SuspenseWithErrorBoundary from 'error/SuspenseWithErrorBoundary';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';
import ProjectEmailInfoPanel from 'emails/components/ProjectEmailInfoPanel';

const PER_PAGE = 20;

export default function Emails() {
  const { t } = useTranslation();

  const default_select = { value: "", label: t("select_ellipsis") };
  const initialEmailFilterValue = {
    from: null,
    to: null,
    createdBy: default_select,
    recipient: [],
    tags: [],
    linkedIssueIds: default_select,
  };

  const navigate = useNavigate();
  const { data: currentProject } = useCurrentProjectQuery();
  const { data: disciplines } = useProjectDisciplinesQuery();
  const { data: floors } = useProjectFloorsQuery();
  const { data: buildings } = useProjectBuildingsQuery();
  const { data: user } = useCurrentUserQuery();
  const { sync_data } = useSelector((state) => state.project);
  const [columns, setColumns] = useState([]);
  const [allProjectEmails, setAllProjectEmails] = useState([]);
  const [allEmails, setAllEmails] = useState([]);
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedEmailByIssue, setSelectedEmailByIssue] = useState(null);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [users, setUsers] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [emailFilter, setEmailFilter] = useState(initialEmailFilterValue);
  const [loading, setLoading] = useState(true);
  const [asideVisibleStatus, setAsideVisibleStatus] = useState(0);
  const [isVisibleFeedback, setVisibleFeedback] = useState(false);
  const [premissionError, setPremissionError] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filters, setFilters] = useState([]);
  const [newFilters, setNewFilters] = useState({});
  const [currentFilter, setCurrentFilter] = useState({});
  const [columnsWidth, setColumnsWidth] = useState([]);
  const [columnsData, setColumnsData] = useState([]);
  const [hideColumns, setHideColumns] = useState(false);
  const [keysOfColumns, setKeysOfColumns] = useState([]);
  const [checkedValues, setCheckedValues] = useState([]);
  const [columnsDataHeader, setColumnsDataHeader] = useState([]);
  const [columnsForDrag, setColumnsForDrag] = useState([]);
  const [resized, setResized] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);
  const [isChangedColumns, setChangedColumns] = useState(true);
  const [showExistingIssueView, setShowExistingIssueView] = useState(false);
  const [showCreateIssueView, setShowCreateIssueView] = useState(false);
  const { issues, issueFilterItems } = useIssueFilterItems();
  const { tagOptions } = useTags(currentProject?.id, currentProject?.tagIds);
  const [statusFilter, setStatusFilter] = useState([]);
  const [allIssues, setAllIssues] = useState([]);
  const [selectedPreviewDoc, setSelectedPreviewDoc] = useState(null);
  const [allDocuments, setAllDocuments] = useState([]);
  const columnsLoading = EmailHelper.getColumnsLoading(t);
  const currentUserRole = useCurrentUserRole();
  const { emailId } = useParams();
  const baseEmailsUrl = useMemo(
    () => `/projects/${currentProject?.id}/emails/`,
    [currentProject]
  );
  const previewVersions = usePreviewVersions({
    allDocs: allDocuments,
    previewDoc: selectedPreviewDoc
  });

  const updateTag = useDebouncedCallback(async (tags, issueId) => {
    const tagIds = tags.map((tag) => tag.value);
    const params = [
      {
        id: issueId,
        tagIds: { value: tagIds },
      },
    ];
    await IssueService.editIssue(params);
  }, 500);

  const {
    notification,
    dragging,
    progress,
    // uploadFileSize,
    // uploadHandles,
    showUploadProgress,
    uploadFileProgress,
    getRootProps,
    getInputProps,
    initNotification,
    hideProgressModal,
  } = useFileUpload(2); //0: issue, 1: model, 2: email

  useEffect(() => {
    const effect = async () => {
      if (!isChangedColumns) return;

      let result = await Request.GET(`api/dynamic-layout`);
      const layout = result.body.find(
        (item) => item.key === "DEFAULT_MAIL_LAYOUT"
      );
      setChangedColumns(false);
      setKeysOfColumns(layout.columns.map((column) => column.name));
      setColumnsWidth(layout.columns);
      setColumnsData(getColumnsForEmail());
    };
    effect();
  }, [isChangedColumns]);

  useEffect(() => {
    const res = columnsData.filter((i) => keysOfColumns.includes(i.value));
    setCheckedValues(res);
  }, [keysOfColumns, columnsData]);

  useEffect(() => {
    if (columnsData.length > 0 && keysOfColumns.length > 0) {
      let columnsVisible = [];
      keysOfColumns.map((i) => {
        columns.filter((item) => {
          if (!columnsVisible.includes(item) && item.value === i)
            columnsVisible.push(item);
        });
      });
      setColumnsDataHeader(columnsVisible);
      setColumnsForDrag(columnsVisible);
    }
  }, [columnsData, keysOfColumns, columns]);

  const emails_for_loading = useMemo(() => {
    const loadingEmails = [];
    for (let i = 0; i < 9; i++)
      loadingEmails.push({
        id: i,
        subject: 0,
        creationDate: 0,
        from: 0,
        to: 0,
        attachments: 0,
      });
    return loadingEmails;
  }, []);

  const assignTag = useCallback((emailId, selectedTags) => {
    const tagIds = selectedTags.map((item) => item.value);
    updateTagIdsOfAllEmails(emailId, tagIds);
    debouncedAssignTag(emailId, tagIds);
  }, [allEmails]);

  //initialize all issues
  useEffect(() => {
    const effect = async () => {
      if (!issues) return;
      let buffer = issues.map((item) => formatIssue(item, disciplines, buildings, floors));
      buffer = await IssueService.getIssuesWithLogViewpoints(buffer);

      setAllIssues(buffer);
    };
    effect();
  }, [issues]);

  useEffect(() => {
    setStatusFilter(issueFilterItems.statuses);
  }, [issueFilterItems.statuses]);

  const updateIssuesBySync = async (changedIds) => {
    const changedIssues = await IssueService.getIssuesByIds(changedIds);
    if (changedIssues) {
      let buffer = [...allIssues];
      changedIssues.forEach(async (issue) => {
        let formattedIssue = formatIssue(issue, disciplines, buildings, floors);
        formattedIssue = (
          await IssueService.getIssuesWithLogViewpoints([formattedIssue])
        )[0];

        const findIndex = buffer.findIndex(
          (item) => item.id === formattedIssue.id
        );
        if (findIndex >= 0)
          //if edited issue
          buffer[findIndex] = formattedIssue;
        //if created issue
        else buffer = [formattedIssue, ...buffer];

        //update a selected issue
        if (selectedIssue?.id === formattedIssue?.id) {
          setSelectedIssue(formattedIssue);
        }
      });
      setAllIssues(buffer);
    }
  };

  const updateEmailsBySync = async () => {
    const projectId = currentProject.id;
    const syncEmails = await EmailService.getInbox(projectId);
    if (syncEmails.length) {
      setAllEmails(syncEmails.map(formatBuffer));
      if (selectedEmail) {
        setSelectedEmail(
          syncEmails.find((email) => email.id === selectedEmail.id)
        );
      }
      setVisibleFeedback(true);
      setTimeout(() => {
        setVisibleFeedback(false);
      }, [3000]);
    }
  };

  const handleSynchronize = async (syncData) => {
    if (!syncData?.dataSummaryEntries) return;

    syncData.dataSummaryEntries.forEach(async (entry) => {
      if (entry.dataType === Common.SyncDataType.InboxEmail) {
        updateEmailsBySync();
      } else if (entry.dataType === Common.SyncDataType.Issue) {
        updateIssuesBySync(getChangedIds(entry));
      }
    });
  };

  const updateTagIdsOfAllEmails = (emailId, tagIds) => {
    const buffer = allEmails.map((email) => {
      if (email.id === emailId) email.tagIds = tagIds;
      return email;
    });
    setAllEmails(buffer);
    setSelectedEmail({
      ...allEmails.find((email) => email.id === emailId),
      tagIds,
    });
  };

  const debouncedAssignTag = useDebouncedCallback(async (emailId, tagIds) => {
    EmailService.assignTagsToEmail(emailId, tagIds);
  }, 500);

  const row_events = (e, row) => {
    const doc = document.getElementById("idTags")
      ? document.getElementById("idTags").getBoundingClientRect()
      : null;
    const issue = document.getElementById("idIssue")
      ? document.getElementById("idIssue").getBoundingClientRect()
      : null;
    const issueDropdown = document.getElementById("count_dropdown")
      ? document.getElementById("count_dropdown").getBoundingClientRect()
      : null;
    //If tagselect is clicked, row non-clickable
    if (
      (doc && e.clientX < doc.left + doc.width && e.clientX > doc.left) ||
      (issue &&
        e.clientX < issue.left + issue.width &&
        e.clientX > issue.left) ||
      (issueDropdown &&
        e.clientX < issueDropdown.left + issueDropdown.width &&
        e.clientX > issueDropdown.left)
    )
      return;
    navigate(`${baseEmailsUrl}${row.id}`);
    setAsideVisibleStatus(EmailService.AsideVisibleStatus.DetailVisible);
    setSelectedEmail(row);
  };

  useEffect(() => {
    setColumns(
      EmailHelper.getColumns(
        t,
        tagOptions,
        currentUserRole?.allowedActions?.has(RoleAction.Tag_Create_Update),
        allIssues,
        issueFilterItems.statuses,
        statusFilter,
        selectedEmailByIssue,
        setSelectedEmailByIssue,
        setSelectedIssue,
        setStatusFilter,
        assignTag,
        isDraggable
      )
    );
  }, [
    tagOptions,
    currentUserRole,
    allIssues,
    issueFilterItems.statuses,
    statusFilter,
    selectedEmailByIssue,
    isDraggable,
    assignTag
  ]);

  useEffect(() => {
    if (!sync_data) return;
    handleSynchronize(sync_data);
  }, [sync_data]);

  const handleFilter = useCallback(
    async (isLoadMore = false) => {
      if (!currentProject) {
        return;
      }

      let createdByValues = [];
      if (emailFilter.createdBy.value === "###") {
        //Extern
        createdByValues = uniq(
          allProjectEmails
            .filter((email) => !users.some((user) => user.email === email.from))
            .map((email) => email.from)
        );
      } else if (emailFilter.createdBy.value) {
        createdByValues.push(emailFilter.createdBy.email);
      }

      const filter = {
        from: createdByValues.length
          ? {
              in: createdByValues,
            }
          : null,
        to:
          emailFilter.recipient && emailFilter.recipient.length
            ? {
                any: {
                  "": {
                    in: (emailFilter.recipient || []).map(
                      (recipient) => recipient.email
                    ),
                  },
                },
              }
            : null,
        tagIds:
          emailFilter.tags && emailFilter.tags.length
            ? {
                any: {
                  "": {
                    in: (emailFilter.tags || []).map((tag) => tag.value),
                  },
                },
              }
            : null,
        creationDate: {},
      };

      if (searchKey) {
        filter.and = {
          or: [
            { "tolower(subject)": { contains: searchKey.toLowerCase() } },
            { "tolower(from)": { contains: searchKey.toLowerCase() } },
          ],
        };
      }

      if (emailFilter.from) {
        filter.creationDate.ge = moment(new Date(emailFilter.from))
          .startOf("day")
          .toDate();
      }
      if (emailFilter.to) {
        filter.creationDate.le = moment(new Date(emailFilter.to))
          .add(1, "day")
          .startOf("day")
          .toDate();
      }

      Object.keys(filter).forEach((key) => {
        if (!filter[key] || isEmpty(filter[key])) {
          delete filter[key];
        }
      });

      const bufferEmails = await EmailService.getEmailsByProjectId(
        currentProject.id,
        PER_PAGE,
        isLoadMore ? allEmails.length : 0,
        filter
      );
      const formatEmails = bufferEmails.map(formatBuffer);
      setEmails(isLoadMore ? allEmails.concat(formatEmails) : formatEmails);
    },
    [
      user,
      users,
      allProjectEmails,
      allEmails,
      searchKey,
      emailFilter,
      currentProject,
    ]
  );

  const loadMore = useDebouncedCallback((isLoadMore = true) => {
    handleFilter(isLoadMore);
  }, 500);

  const handleScroll = (event) => {
    if (
      Math.abs(
        event.target.scrollHeight -
          event.target.scrollTop -
          event.target.clientHeight
      ) < 10
    ) {
      loadMore();
    }
  };

  //filtering
  useEffect(() => {
    loadMore(false);
  }, [
    user,
    users,
    allProjectEmails,
    allEmails,
    searchKey,
    emailFilter,
    currentProject,
  ]);

  //format status
  const formatBuffer = useCallback((item) => {
    let label = "";
    const createdDaysAgo = calcBusinessDays(
      new Date(item.creationDate),
      new Date()
    );
    const notUpdated = !item.editDate || item.creationDate === item.editDate;
    if (notUpdated && createdDaysAgo < 2) label = t("new", "New");

    if (item.editDate) {
      const editedDaysAgo = calcBusinessDays(
        new Date(item.editDate),
        new Date()
      );
      if (!notUpdated && editedDaysAgo < 2) label = t("update", "Updated");
    }

    item.label = label;
    return item;
  }, []);

  const onSearchChange = useCallback((event) => {
    const value = (event && event.target.value) || "";
    setSearchKey(value);
  }, []);

  const openEmailDetails = useCallback(
    (emailsList) => {
      const findEmail = emailsList?.find((email) => email.id === emailId);

      if (currentUserRole?.allowedActions?.has(RoleAction.Email_View) === false) {
        return setPremissionError(true), navigate(`${baseEmailsUrl}`);
      }

      if (findEmail) {
        setSelectedEmail(findEmail);
        setAsideVisibleStatus(EmailService.AsideVisibleStatus.DetailVisible);
      }

      if (emailId && !findEmail) {
        navigate(`${baseEmailsUrl}`);
      }
    },
    [currentUserRole]
  );

  //loading
  useEffect(() => {
    const initLoading = async () => {
      if (!currentProject?.id || !currentProject?.userPreviews) return;
  
      const bufferUsers = currentProject.userPreviews.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
        email: item.email,
        abbreviation:
          (item.firstName.length > 0 ? item.firstName[0] : "") +
          (item.lastName.length > 0 ? item.lastName[0] : ""),
      }));
      setUsers(bufferUsers);
  
      const bufferEmails = await EmailService.getEmailsByProjectId(
        currentProject.id,
        PER_PAGE,
        0,
        {}
      );
      const formatEmails = bufferEmails.map(formatBuffer);
      const bufferProjectEmails = await EmailService.getEmailsByProjectId(
        currentProject.id
      );
      const formatProjectEmails = bufferProjectEmails.map(formatBuffer);
  
      const docs = await DocumentService.getDocumentVersionsByProjectId(
        currentProject.id
      );
  
      setAllDocuments(docs);
      setAllEmails(formatEmails);
      setEmails(formatEmails);
      setLoading(false);
      setAllProjectEmails(bufferProjectEmails);
      openEmailDetails(formatProjectEmails);
    }
    initLoading();
  }, [currentProject?.id, currentProject?.userPreviews]);

  const issuesForFilter = issues?.map((item) => ({
    ...item,
    label: item.title,
    value: item.id,
  }));

  useEffect(() => {
    if (Object.keys(currentFilter).length) {
      const filter = currentFilter.uiFilterSettings?.emailFilter;
      const tags = tagOptions.filter((tag) => {
        const findTag = filter.tagIds.find((id) => tag.value === id);
        if (findTag) return tag;
      });

      const getCreatAutor = () => {
        if (filter.createdBy[0] === "###") {
          return { label: "Extern", value: "###" };
        }
        if (filter.createdBy[0]) {
          return users.find((user) => user.value === filter.createdBy[0]);
        }
        return { value: "", label: "select..." };
      };

      setEmailFilter({
        ...emailFilter,
        from: filter.from ? new Date(filter.from) : null,
        to: filter.to[0] ? new Date(filter.to[0]) : null,
        createdBy: getCreatAutor(),
        recipient: users.filter((user) => {
          const findUser = filter.recipient.find((id) => user.value === id);
          if (findUser) return user;
        }),
        linkedIssueIds: issuesForFilter?.find(
          (item) => item.value === filter.linkedIssueIds
        ) || { value: "", label: "select..." },
        tags,
      });
    }
  }, [currentFilter]);

  const handleSaveFilter = async (isUpdate = false) => {
    const tagIds = emailFilter.tags.map((item) => item.value);

    const filterParams = {
      ...emailFilter,
      from: emailFilter.from || null,
      to: [emailFilter.to || null],
      createdBy: [emailFilter.createdBy.value],
      recipient: emailFilter.recipient.map((item) => item.value),
      tagIds,
    };
    const params = {
      type: FilterService.filterTypes.email,
      isPrivate: newFilters.isPrivate,
      uiFilterSettings: { emailFilter: filterParams },
    };

    if (isUpdate) {
      const updateQueryFilterDto = {
        id: currentFilter.id,
        name: currentFilter.name,
        ...params,
      };
      const response = await FilterService.updateFilter(updateQueryFilterDto);
      response && getFilters();
    } else {
      const createQueryFilterDto = {
        name: newFilters.name,
        ...params,
      };
      const response = await FilterService.createNewFilter(createQueryFilterDto);
      if (!response.errorMessage) {
        setShowFilterModal(false);
        getFilters();
      }
    }
  };

  const getFilters = async () => {
    const filterList = await FilterService.getProjectFilters(
      currentProject.id
    );

    const filtredFilterList = filterList
      .filter((filter) => filter.type === FilterService.filterTypes.email)
      .filter((filter) => {
        if (filter.isPrivate) {
          return user.id === filter.creatorId;
        } else {
          return filter;
        }
      })
      .map((filter) => {
        return {
          ...filter,
          value: filter.id,
          label: filter.name,
        };
      });

    setFilters([...filtredFilterList]);
  };

  useEffect(() => {
    if (currentProject) {
      getFilters();
    }
  }, [currentProject]);

  const handeleDeleteFilter = async (filterId) => {
    await FilterService.deleteFilter(filterId);
    getFilters();
  };

  const changeVersion = (documentId, version) => {
    const filterDoc = allDocuments.find(
      (item) => item.documentId === documentId && item.versionNumber === version
    );

    setSelectedPreviewDoc(filterDoc);
  };

  if (!currentUserRole?.allowedActions?.has(RoleAction.Email_View)) return null;

  return (
    <main className="main">
      <SuspenseWithErrorBoundary suspenseFallback={<CenteredCircularProgress />}>
        <div className="main__panel emails__panel">
          <div className="emails__panel-group">
            <div className="title">
              {/* <svg className="icon mobile--show">
                <use xlinkHref="/img/sprite.svg#emails-active" />
              </svg> */}
              {t("emails", "Emails")}
            </div>
            <div className="main__search mobile--hide">
              <SearchInput
                id="SearchEmailInput"
                placeholder={t("search_email", "Search email")}
                value={searchKey}
                onChange={onSearchChange}
              />
            </div>
            <ProjectEmailInfoPanel />
            {!!currentUserRole?.allowedActions?.has(RoleAction.Inbox_AllowUploadEmail) ?
              <UploadZone
                id="UploadEmailUploadZone"
                uploadLabel={t(
                  "drag_and_drop_email",
                  "<b>Drag & Drop</b> an Email-file to "
                )}
                uploadLinkLabel={t("upload_emails", "upload Email")}
                uploadFileProgress={uploadFileProgress}
                acceptedFilesFormats={".msg,.eml"}
                done={() => {}}
              />
              : <div style={{ width: '40%' }} />
            }
            {/* <div className="main__search-mobile mobile--show">
              <button type="button" className="btn btn--secondary btn-search">
                <svg className="icon">
                  <use xlinkHref="/img/sprite.svg#search" />
                </svg>
              </button>
              <SearchInput
                placeholder={t("search_email", "Search email")}
                value={searchKey}
                onChange={onSearchChange}
              />
            </div> */}
          </div>
          {/* {projectEmail && (
            <div className="main__search mobile--show">
              <p className="main__email text-ellipsis">{projectEmail}</p>
            </div>
          )} */}
          {isVisibleFeedback && (
            <ChangeStatusFeedback
              description={t(
                "email_edited_successfully",
                "Email was edited successfully"
              )}
            />
          )}
          <IconButton
            id="FilterEmailButton"
            className="btn btn--secondary btn-filter mobile--hide"
            iconId="filter"
            title={t("filter", "Filter")}
            onClick={() =>
              setAsideVisibleStatus(
                asideVisibleStatus === EmailService.AsideVisibleStatus.Hidden
                  ? EmailService.AsideVisibleStatus.FilterVisible
                  : EmailService.AsideVisibleStatus.Hidden
              )
            }
          />
        </div>
        <div className="main__content emails-content" {...getRootProps()}>
          <div className="content">
            <input {...getInputProps()} />
            <div className={"issues-dropzone" + (dragging ? " dragging" : "")}>
              <div className="issues-dropzone--wrapper">
                <svg className="icon" viewBox="0 0 61 47">
                  <use xlinkHref="/img/sprite.svg#cloud" />
                </svg>
                <div className="issues-dropzone--title">
                  {t("drag_and_drop_files", "Drag and drop files")}
                </div>
                <div className="issues-dropzone--text">
                  {t(
                    "email_uploading_description",
                    "Your emails will get added to the email list"
                  )}
                </div>
              </div>
            </div>
            <div
              id="emails-table"
              className={
                "emails-table content__table custom-scrollbar" +
                (loading ? " content__table--preloader" : "")
              }
              onScroll={handleScroll}
            >
              {showFilterModal && (
                <SaveFilterModal
                  hideFilterModal={() => setShowFilterModal(false)}
                  setNewFilters={setNewFilters}
                  newFilters={newFilters}
                  handleSaveFilter={handleSaveFilter}
                />
              )}
              <Table
                columns={columnsForDrag}
                columnsWidth={columnsWidth}
                data={emails}
                row_events={row_events}
                columnsData={columnsData}
                setHideColumns={setHideColumns}
                hideColumns={hideColumns}
                checkedValues={checkedValues}
                resized={resized}
                setResized={setResized}
                setColumnsForDrag={setColumnsForDrag}
                isDraggable={isDraggable}
                setIsDraggable={setIsDraggable}
                columnsDataHeader={columnsDataHeader}
                loadEmail={emails_for_loading}
                loadCol={columnsLoading}
                setChangedColumns={setChangedColumns}
              />
            </div>
            <AsideEmailFilter
              users={users}
              tagOptions={tagOptions}
              emailFilter={emailFilter}
              initialEmailFilterValue={initialEmailFilterValue}
              show_aside={
                asideVisibleStatus ===
                EmailService.AsideVisibleStatus.FilterVisible
              }
              setAsideVisibleStatus={setAsideVisibleStatus}
              setEmailFilter={setEmailFilter}
              setShowFilterModal={setShowFilterModal}
              filters={filters}
              setCurrentFilter={setCurrentFilter}
              currentFilter={currentFilter}
              handeleDeleteFilter={handeleDeleteFilter}
              handleSaveFilter={handleSaveFilter}
              issues={issuesForFilter}
            />
            <AsideEmailDetail
              email={selectedEmail}
              issues={allIssues}
              tagOptions={tagOptions}
              show_aside={
                asideVisibleStatus ===
                EmailService.AsideVisibleStatus.DetailVisible
              }
              isAllowTagUpdate={currentUserRole?.allowedActions?.has(RoleAction.Tag_Create_Update)}
              baseEmailsUrl={baseEmailsUrl}
              statuses={issueFilterItems.statuses}
              types={issueFilterItems.types}
              assignTag={assignTag}
              setAsideVisibleStatus={setAsideVisibleStatus}
              setShowExistingIssueView={setShowExistingIssueView}
              setShowCreateIssueView={setShowCreateIssueView}
              setSelectedIssue={setSelectedIssue}
            />
          </div>
        </div>
        {!!showExistingIssueView && (
          <LinkEmailIssues
            email={selectedEmail}
            issueModalProps={{
              statuses: issueFilterItems.statuses,
              types: issueFilterItems.types,
              priorities: issueFilterItems.priorities,
              workphases: issueFilterItems.workphases,
              users: issueFilterItems.users,
              tagOptions,
            }}
            setShowExistingIssueView={setShowExistingIssueView}
            updateEmail={updateEmailsBySync}
            allIssues={allIssues}
          />
        )}
        <IssueOverviewModalOld
          show_aside={!!selectedIssue}
          {...issueFilterItems}
          issue={selectedIssue}
          tagOptions={tagOptions}
          toggleAside={() => setSelectedIssue(null)}
          allDocuments={allDocuments}
          allIssues={allIssues}
          updateTag={updateTag}
          setSelectedPreviewDoc={setSelectedPreviewDoc}
          setCurrentIssue={setSelectedIssue}
        />
        {selectedPreviewDoc && (
          <Modal>
            <DocumentPreviewModal
              versions={previewVersions}
              setSelectedPreviewDoc={setSelectedPreviewDoc}
              previewDoc={selectedPreviewDoc}
              changeVersion={changeVersion}
              issueDocument={true}
              tagOptions={tagOptions}
              {...issueFilterItems}
            />
          </Modal>
        )}
        {showCreateIssueView && (
          <Modal className="issue-modal--wrapper">
            <IssueModal
              {...issueFilterItems}
              tagOptions={tagOptions}
              toggleIssueModal={() =>
                setShowCreateIssueView(!showCreateIssueView)
              }
              onSubmitCallback={() => {}}
              editIssue={null}
              mode={Common.IssueModalMode.CREATE}
              selected={[]}
              setIssueSavedModalVisible={() => {}}
              isTimeline={true}
              linkEmail={selectedEmail}
              canEditIssue={
                currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_Editing)
              }
              setIsLinkedCreatIssue={() => {}}
              setEditIssue={() => {}}
              updateEmail={updateEmailsBySync}
              allIssues={allIssues}
              setSelectedPreviewDoc={setSelectedPreviewDoc}
            />
          </Modal>
        )}
        {!!notification && (
          <NotificationModal
            onDone={initNotification}
            notification={notification}
            isRenderContent={true}
          />
        )}
        {!!showUploadProgress && (
          <UploadProgressModal onDone={hideProgressModal} progress={progress} />
        )}
        {premissionError && (
          <div className="premission-modal">
            <NotificationModal
              notification={{
                title: t("no_permission", "No Permission"),
                description: `${t(
                  "not_permitted_view",
                  " You are not permitted to view that"
                )} ${t("email").toLowerCase()}.`
              }}
              onDone={() => setPremissionError(false)}
            />
          </div>
        )}
      </SuspenseWithErrorBoundary>
    </main>
  );
}
