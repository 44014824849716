import React from "react";
import CustomSelect from "../CustomSelect";

export const CompareView = ({
  setComparing,
  setCompare,
  version,
  versions,
  previewDoc,
  selectedVersionDoc,
  setSelectVersion,
  view,
  setView,
  t,
}) => {
  return (
    <div className="wrapper-compare">
      <div className="header-compare">
        <div className="title"> {t("compare", "Compare")}</div>
        <div
          className="close-button"
          onClick={() => {
            setComparing(false);
            setCompare(false);
          }}
        >
          {t("close", "Close")}
        </div>
      </div>
      <div className="compare-style">
        <div
          className={view === "semantic" ? "active" : "compare-style__by-text"}
          onClick={() => setView("semantic")}
        >
          {t("semantic_text_compare", "Semantic text compare")}
        </div>
        <div
          className={view === "image" ? "active" : "compare-style__by-img"}
          onClick={() => {
            setView("image");
          }}
        >
          {t("compare_by_image", "Compare by image")}
        </div>
      </div>
      <div className={"compare-versions"}>
        <label>{t("current_version", "Current Version")}</label>
        <div className={"compare-versions__version"}>
          {`V.${previewDoc.versionNumber}`}
        </div>
      </div>
      <div className={"compare-versions"}>
        <label>{t("version_to_compare", "Version to compare")}</label>
        <CustomSelect
          values={versions}
          isDisabled={false}
          onChange={(value) => {
            selectedVersionDoc(previewDoc?.documentId, value.value);
            setSelectVersion(value);
          }}
          value={version}
          isModel={true}
          menuPlacement="top"
        />
      </div>
      <div className="difference">
        <div className={"removed"}>{t("removed", "Removed")}</div>
        <div className={"added"}>{t("added", "Added")}</div>
      </div>
    </div>
  );
};
