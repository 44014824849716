import React from "react";
import { useTranslation } from "react-i18next";

const IssueVisibility = ({ visibility, isShowOnlyIcon }) => {
  const { t } = useTranslation();
  return (
    <div className="issue-visibility">
      {visibility === 1 ? (
        <>
          <svg className="icon-visibility icon">
            <use xlinkHref={"/img/sprite.svg#private"} />
          </svg>
          {!isShowOnlyIcon && t("private", "Private")}
        </>
      ) : visibility === 2 ? (
        <>
          <svg className="icon-visibility icon">
            <use xlinkHref={"/img/sprite.svg#restricted"} />
          </svg>
          {!isShowOnlyIcon && t("restricted access", "Restricted")}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default IssueVisibility;
