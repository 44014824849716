import React from 'react';
import { useTranslation } from 'react-i18next';
import { getGeneralSvg } from 'Components/SVGs';
import StampSettingsDto from 'documents/types/StampSettingsDto';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';

interface StampExampleProps {
  stampSettings: StampSettingsDto | null,
}

export default function StampExample({
  stampSettings,
}: StampExampleProps) {
  const { t } = useTranslation('settings');
  const { data: currentProject } = useCurrentProjectQuery();
  if (!currentProject) throw new Error('currentProject is missing');
  const now = new Date();
  const timeZoneName = new Intl.DateTimeFormat(stampSettings?.locale, { timeZoneName: 'short' }).formatToParts(now).find((part) => part.type === 'timeZoneName')?.value || '';
  return (
    <div className="stamp-example">
      <div className="stamp-example-projectname">
        {getGeneralSvg('logo')}
        {currentProject.name}
      </div>
      <div className="stamp-example-attribute">
        <div className="stamp-example-attribute-name">
          {t('stamplabel_download', { defaultValue: 'Download', ns: 'pdfPreview', lng: stampSettings?.locale })}
        </div>
        <div className="stamp-example-attribute-value">
          {`${now.toLocaleString(stampSettings?.locale, { dateStyle: 'medium', timeStyle: 'short' })} ${timeZoneName}`}
        </div>
      </div>
      <div className="stamp-example-attribute">
        <div className="stamp-example-attribute-name">
          {t('stamplabel_status', { defaultValue: 'Status', ns: 'pdfPreview', lng: stampSettings?.locale })}
        </div>
        <div className="stamp-example-attribute-value">
          {t('stamp_example_status', { defaultValue: 'Shared', lng: stampSettings?.locale })}
        </div>
      </div>
      <div className="stamp-example-attribute">
        <div className="stamp-example-attribute-name">
          {t('stamplabel_version', { defaultValue: 'Version', ns: 'pdfPreview', lng: stampSettings?.locale })}
        </div>
        <div className="stamp-example-attribute-value">12</div>
      </div>
    </div>
  );
}
