import React, {
  ReactNode, useMemo, useState,
} from 'react';
import DocumentDragAndDropContext, { DocumentDragAndDropContextState } from 'documents/contexts/DocumentDragAndDropContext';
import { RowDropZoneParams } from '@ag-grid-community/core';

interface DocumentDragAndDropContextProviderProps {
  children?: ReactNode,
}

export default function DocumentDragAndDropContextProvider({
  children,
}: DocumentDragAndDropContextProviderProps) {
  const [folderTreeDropZoneParams, setFolderTreeDropZoneParams] = useState<RowDropZoneParams | undefined>(undefined);
  const [planlistDataGridDropZoneParams, setPlanlistDataGridDropZoneParams] = useState<RowDropZoneParams | undefined>(undefined);
  const [planlistCreateNewDropZoneParams, setPlanlistCreateNewDropZoneParams] = useState<RowDropZoneParams | undefined>(undefined);
  const [isDragOnPlanlistInProgress, setIsDragOnPlanlistInProgress] = useState<boolean>(false);
  const [draggedOverFolderId, setDraggedOverFolderId] = useState<string | undefined>();
  const [draggedOverDocumentListId, setDraggedOverDocumentListId] = useState<string | undefined>();
  const [documentVersionIdsDroppedOnCreateNewDropzone, setDocumentVersionIdsDroppedOnCreateNewDropzone] = useState<string[]>([]);

  const state = useMemo<DocumentDragAndDropContextState>(() => ({
    folderTreeDropZoneParams,
    setFolderTreeDropZoneParams,
    planlistDataGridDropZoneParams,
    setPlanlistDataGridDropZoneParams,
    planlistCreateNewDropZoneParams,
    setPlanlistCreateNewDropZoneParams,
    isDragOnPlanlistInProgress,
    setIsDragOnPlanlistInProgress,
    draggedOverFolderId,
    setDraggedOverFolderId,
    draggedOverDocumentListId,
    setDraggedOverDocumentListId,
    documentVersionIdsDroppedOnCreateNewDropzone,
    setDocumentVersionIdsDroppedOnCreateNewDropzone,
  }), [folderTreeDropZoneParams, planlistDataGridDropZoneParams, planlistCreateNewDropZoneParams, isDragOnPlanlistInProgress, draggedOverFolderId, draggedOverDocumentListId, documentVersionIdsDroppedOnCreateNewDropzone]);

  return (
    <DocumentDragAndDropContext.Provider value={state}>
      {children}
    </DocumentDragAndDropContext.Provider>
  );
}
