import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { isAllowImagePreview, isAllowPDFPreview } from "../../Helpers/utils";
import { DocumentService } from "../../Services";
import { IssueLogType } from "../../Services/IssueService";
import { getGeneralSvg } from "../SVGs";
import { useAnnotationCheck } from "../../Helpers/Hooks";
import useDocumentScopeContext from 'documents/hooks/useDocumentScopeContext';

const LinkedDocumentItem = ({
  issue,
  document,
  setAllIssueLogs,
  isDocumentPreview,
  pdfAnnotations,
}) => {
  const { t } = useTranslation();
  const { isVisibleAnnotation } = useAnnotationCheck({
    pdfAnnotations,
    pdfAnnotationIds: document.pdfAnnotationIds,
  });
  const isAllowPreview = useMemo(
    () => isAllowImagePreview(document) || isAllowPDFPreview(document),
    [document]
  );

  const downloadDocument = useCallback(() => {
    if (!document) return;
    DocumentService.downloadDocument([document.id], document.name);
  }, [document]);

  const showIssueLog = useCallback(() => {
    if (document && issue.issueLog && issue.issueLog.issueLogEntries) {
      const logs = issue.issueLog.issueLogEntries
        .filter(
          (log) =>
            log.documentVersionId === document.id ||
            log.entryType === IssueLogType.StatusIdUpdated
        )
        .map((item) => ({ ...item }));
      setAllIssueLogs(logs);
    }
  }, [document, issue, setAllIssueLogs]);

  const { setDocumentVersionAndIssueId } = useDocumentScopeContext();
  const selectPreviewDocument = () => {
    if (!isAllowPreview || document.isArchived) return;
    console.log(setDocumentVersionAndIssueId);
    setDocumentVersionAndIssueId(document.id, issue.id);
  };

  return (
    <div className="linked-document-item">
      <div className="linked-document-item_name-wrapper">
        {!!isAllowPreview && !document.isArchived && !isDocumentPreview ? (
          <div className="icon-eye" onClick={selectPreviewDocument}>
            {getGeneralSvg("preview-eye1")}
          </div>
        ) : (
          <div className="icon-eye bg-transparent"></div>
        )}
        <p
          className={`linked-document-item_name ellipsis ${
            isAllowPreview && !document.isArchived
              ? "preview-doc cursor-pointer"
              : "cursor-initial"
          }`}
          onClick={selectPreviewDocument}
        >
          {document?.name || ""}
        </p>
      </div>

      <p className="linked-document-item_annotation">
        {isVisibleAnnotation ? t("annotations", "Annotations") : ""}
      </p>

      <p
        className="linked-document-item_log text-underline"
        onClick={() => showIssueLog()}
      >
        {t("show_log", "Show Log")}
      </p>
      {document.isArchived ? (
        <a className="linked-document-item_download-empty" />
      ) : (
        <a className="linked-document-item_download" onClick={downloadDocument}>
          <svg className="icon">
            <use xlinkHref="/img/sprite.svg#download-1" />
          </svg>
        </a>
      )}
    </div>
  );
};

export default LinkedDocumentItem;
