import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box3, MathUtils, Vector3 } from "three";
import {
  VIEWER_MARGIN,
  VIEWER_OFFSET_X,
  VIEWER_OFFSET_Y,
} from "../../../Helpers/Common";
import CustomSelect from "../../CustomSelect";
import { Direction, Resizer } from "../../Resizer";
import { getGeneralSvg } from "../../SVGs";

const EYE_HEIGHT = 1.7;
const MIN_WIDTH = 365;
const MIN_HEIGHT = 260;

const PlanPanel = ({
  containerRect,
  storeys,
  cameraInfo,
  setCameraPosition,
}) => {
  const { t } = useTranslation();
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [isMoving, setIsMoving] = useState(false);
  const [plan, setPlan] = useState();
  const [plans, setPlans] = useState([]);
  const [poseInfo, setPoseInfo] = useState({
    camPos: { x: 0, y: 0 },
    imagePos: { x: 0, y: 0, w: 0, h: 0 },
    conePos: { x: 0, y: 0, angle: 0 },
    arrowPos: { x: 0, y: 0, angle: 0 },
  });
  const movingRef = useRef(isMoving);
  const rectRef = useRef(containerRect);
  const panelRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousemove", onDocMouseMove);
    document.addEventListener("mouseup", onDocMouseUp);

    return () => {
      document.removeEventListener("mousemove", onDocMouseMove);
      document.removeEventListener("mouseup", onDocMouseUp);
    };

    function onDocMouseMove(e) {
      if (movingRef.current) {
        calculatePos(e.clientX - VIEWER_OFFSET_X, e.clientY - VIEWER_OFFSET_Y);
      }
    }

    function onDocMouseUp(e) {
      if (movingRef.current && e.button === 0) {
        setIsMoving(false);
      }
    }
  }, []);

  useEffect(() => {
    movingRef.current = isMoving;
  });

  useEffect(() => {
    document.documentElement.style.cursor = isMoving ? "move" : "default";
  }, [isMoving]);

  useEffect(() => {
    rectRef.current = containerRect;

    if (pos.x === 0 && pos.y === 0) {
      calculatePos(window.innerWidth, window.innerHeight);
    } else {
      calculatePos(pos.x, pos.y);
    }
  }, [containerRect]);

  useEffect(() => {
    const maps = [];
    storeys.forEach((m) => {
      if (!isNaN(m.center.z)) {
        const min = m.center.clone().sub(m.size.clone().multiplyScalar(0.5));
        const max = m.center.clone().add(m.size.clone().multiplyScalar(0.5));
        m.box = new Box3(min, max);

        maps.push({ value: m, label: m.name });
      }
    });

    maps.sort((a, b) => {
      return a.value.center.z - b.value.center.z;
    });
    setPlans(maps);
  }, [storeys]);

  useEffect(() => {
    handleContainerSize();
  }, [cameraInfo, plans]);

  const handleContainerSize = (isResized = false) => {
    if (!cameraInfo || !plans.length) {
      setPlan(undefined);
      return;
    }

    // set appropriate floor
    const containedFloor = isResized ? plan : getContainedPlan();
    if (!isResized) setPlan(containedFloor);

    const imageHtml = document.getElementById("plan-image");
    const canvasW = imageHtml.clientWidth - 20;
    const canvasH = imageHtml.clientHeight - 20;

    setAssetPose();

    function getContainedPlan() {
      const elevation = cameraInfo.position.y;

      let floor = plans.find((p) => {
        const box = p.value.box;
        return box.min.z <= elevation && elevation < box.max.z;
      });

      if (floor) return floor;

      // If camera is below building then return the lowest floor.
      if (elevation < plans[0].value.box.min.z) return plans[0];

      // If camera is above building then return the highest floor.
      if (elevation > plans[plans.length - 1].value.box.max.z)
        return plans[plans.length - 1];
    }

    function setAssetPose() {
      const floor = containedFloor.value;
      const imageAspect = floor.size.x / floor.size.y;
      const canvasAspect = canvasW / canvasH;
      const imagePos = {};
      let worldToCanvasScale = 1;

      // resize and center map image
      if (imageAspect > canvasAspect) {
        imagePos.w = canvasW;
        imagePos.h = canvasH * (canvasAspect / imageAspect);
        imagePos.x = 0;
        imagePos.y = 0.5 * (canvasH - imagePos.h);
        worldToCanvasScale = canvasW / floor.size.x;
      } else {
        imagePos.w = canvasW * (imageAspect / canvasAspect);
        imagePos.h = canvasH;
        imagePos.x = 0.5 * (canvasW - imagePos.w);
        imagePos.y = 0;
        worldToCanvasScale = canvasH / floor.size.y;
      }

      // calculate camera position in panel-space
      const camPos = {
        x:
          imagePos.x +
          (cameraInfo.position.x - floor.box.min.x) * worldToCanvasScale,
        y:
          imagePos.y +
          (cameraInfo.position.z + floor.box.max.y) * worldToCanvasScale,
      };

      camPos.visible =
        camPos.x > 0 &&
        camPos.x < canvasW &&
        camPos.y > 0 &&
        camPos.y < canvasH;

      // calculate viewfield cone position and orientation
      const radius = -10;
      const rad = Math.atan2(cameraInfo.direction.x, -cameraInfo.direction.z);
      const conePos = {
        x: camPos.x - 4 + Math.cos(rad + Math.PI / 2) * radius,
        y: camPos.y - 9 + Math.sin(rad + Math.PI / 2) * radius,
        angle: rad * MathUtils.RAD2DEG,
        visible: camPos.visible,
      };

      // calculate out-of-bounds arrow position and orientation
      const canvasCenter = {
        x: canvasW / 2,
        y: canvasH / 2,
      };
      const delta = {
        x: camPos.x - canvasCenter.x,
        y: camPos.y - canvasCenter.y,
      };
      const arrowPos = {
        visible: !camPos.visible,
        angle: 180 - Math.atan2(delta.x, delta.y) * MathUtils.RAD2DEG,
      };
      if (Math.abs(delta.x / delta.y) < canvasAspect) {
        arrowPos.x =
          canvasCenter.x + (0.5 * canvasH * delta.x) / Math.abs(delta.y);
        arrowPos.y = canvasCenter.y + 0.5 * canvasH * Math.sign(delta.y);
      } else {
        arrowPos.x = canvasCenter.x + 0.5 * canvasW * Math.sign(delta.x);
        arrowPos.y =
          canvasCenter.y + (0.5 * canvasW * delta.y) / Math.abs(delta.x);
      }

      setPoseInfo({ camPos, imagePos, conePos, arrowPos });
    }
  };

  const handleChangePlan = (map) => {
    setPlan(map);
  };

  const calculatePos = (posX, posY) => {
    if (rectRef.current) {
      const planHtml = document.getElementById("plan-panel");

      const x = Math.min(
        Math.max(posX, rectRef.current.left - VIEWER_OFFSET_X + VIEWER_MARGIN),
        rectRef.current.right -
          planHtml.clientWidth -
          VIEWER_OFFSET_X -
          VIEWER_MARGIN
      );

      const y = Math.min(
        Math.max(posY, rectRef.current.top - VIEWER_OFFSET_Y + VIEWER_MARGIN),
        rectRef.current.bottom -
          VIEWER_OFFSET_Y -
          planHtml.clientHeight -
          VIEWER_MARGIN
      );

      setPos({ x, y });
    }
  };

  const onMouseDown = (e) => {
    if (e.button === 0) {
      setIsMoving(true);
    }
  };

  const onMouseUp = (e) => {
    if (isMoving && e.button === 0) {
      setIsMoving(false);
    }
  };

  const handleDoubleClick = (e) => {
    if (!plan) return;
    if (e.button === 0) {
      const rect = document
        .getElementById("plan-image")
        .querySelector("img")
        .getBoundingClientRect();
      const x =
        ((e.clientX - rect.x) * (plan.value.box.max.x - plan.value.box.min.x)) /
          rect.width +
        plan.value.box.min.x;
      const z =
        ((e.clientY - rect.y) * (plan.value.box.max.y - plan.value.box.min.y)) /
          rect.height -
        plan.value.box.max.y;

      const y = plan.value.box.min.z + EYE_HEIGHT;

      setCameraPosition(new Vector3(x, y, z));
    }
  };

  const handleResize = (direction, movementX, movementY) => {
    const panel = panelRef.current;
    if (!panel) return;

    const { width, height } = panel.getBoundingClientRect();
    const { x, y } = pos;

    const resizeTop = () => {
      const mH = height - movementY;
      if (mH < MIN_HEIGHT) return;
      panel.style.height = `${mH}px`;
      calculatePos(x, y + movementY);
    };

    const resizeRight = () => {
      panel.style.width = `${width + movementX}px`;
    };

    const resizeBottom = () => {
      panel.style.height = `${height + movementY}px`;
    };

    const resizeLeft = () => {
      const mW = width - movementX;
      if (mW < MIN_WIDTH) return;
      panel.style.width = `${mW}px`;
      calculatePos(x + movementX, y);
    };

    switch (direction) {
      case Direction.TopLeft:
        resizeTop();
        resizeLeft();
        break;

      case Direction.Top:
        resizeTop();
        break;

      case Direction.TopRight:
        resizeTop();
        resizeRight();
        break;

      case Direction.Right:
        resizeRight();
        break;

      case Direction.BottomRight:
        resizeBottom();
        resizeRight();
        break;

      case Direction.Bottom:
        resizeBottom();
        break;

      case Direction.BottomLeft:
        resizeBottom();
        resizeLeft();
        break;

      case Direction.Left:
        resizeLeft();
        break;

      default:
        break;
    }

    handleContainerSize(true);
  };

  return (
    <div
      id="plan-panel"
      className="plan-panel"
      style={{ left: `${pos.x}px`, top: `${pos.y}px` }}
      onMouseUp={onMouseUp}
      ref={panelRef}
    >
      <Resizer onResize={handleResize} />
      <div className="plan-panel_header">
        <div className="moving" onMouseDown={onMouseDown}>
          {getGeneralSvg("moving-label")}
        </div>
        <div className="map_list">
          <label>{t("architecture", "Architecture")}</label>
          <CustomSelect
            values={plans}
            value={plan}
            onChange={handleChangePlan}
            isModel={true}
            isDisabled={plans.length === 1}
          />
        </div>
      </div>
      <div
        id="plan-image"
        className="plan-panel_map_content"
        onDoubleClick={handleDoubleClick}
      >
        {!!plan && (
          <>
            {/* plan image */}
            <img
              src={plan?.value.image}
              width={`${poseInfo.imagePos?.w}px`}
              height={`${poseInfo.imagePos?.h}px`}
              style={{
                marginLeft: `${poseInfo.imagePos?.x}px`,
                marginTop: `${poseInfo.imagePos?.y}px`,
              }}
            />

            {/* pivot figurine */}
            <div
              style={{
                marginLeft: `${poseInfo.camPos.x - 6}px`,
                marginTop: `${poseInfo.camPos.y - 4}px`,
                display: poseInfo.camPos.visible ? "revert" : "none",
              }}
            >
              {getGeneralSvg("observer")}
            </div>

            {/* pivot field of view cone */}
            <div
              style={{
                marginLeft: `${poseInfo.conePos.x - 6}px`,
                marginTop: `${poseInfo.conePos.y - 4}px`,
                transform: `rotate(${poseInfo.conePos.angle}deg)`,
                display: poseInfo.conePos.visible ? null : "none",
              }}
            >
              {getGeneralSvg("viewfield")}
            </div>

            {/* out-of-bounds indicator arrow */}
            <div
              style={{
                width: "15px",
                marginLeft: `${poseInfo.arrowPos.x - 6}px`,
                marginTop: `${poseInfo.arrowPos.y - 8}px`,
                transform: `rotate(${poseInfo.arrowPos.angle}deg)`,
                display: poseInfo.arrowPos.visible ? null : "none",
              }}
            >
              {getGeneralSvg("bounds_arrow")}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PlanPanel;
