import { useCallback, useMemo, useContext } from 'react';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';
import DocumentScopeContext, { DocumentScopeContextState } from 'documents/contexts/DocumentScopeContext';
import useDynamicLayoutQuery from 'dynamic-layout/hooks/useDynamicLayoutQuery';
import useDynamicLayoutUpsertMutation from 'dynamic-layout/hooks/useDynamicLayoutUpsertMutation';
import DynamicLayoutKey from 'dynamic-layout/types/DynamicLayoutKey';
import UpsertDynamicLayoutDto from 'dynamic-layout/types/UpsertDynamicLayoutDto';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';

export default function useDynamicLayout(key: DynamicLayoutKey) {
  const projectId = useCurrentProjectId();
  const { documentScope } = useContext<DocumentScopeContextState>(DocumentScopeContext);
  const { data: dynamicLayout, isLoading: isLoadingQuery } = useDynamicLayoutQuery(key);
  const columnNamesForcedHidden = useMemo(() => new Set(documentScope.key === DocumentScopeKey.Folder ? ['folderId'] : []), [documentScope.key]);
  const columnNamesForcedVisible = useMemo(() => new Set(['name']), []);
  const columnWidthsByColumnName = useMemo(() => (dynamicLayout ? new Map<string, number>(dynamicLayout?.columns.map((c) => [c.name, c.width])) : undefined), [dynamicLayout]);
  const orderedVisibleColumnNames = useMemo(() => dynamicLayout?.columns?.map((c) => c?.name).filter((name) => name && !columnNamesForcedHidden.has(name)), [columnNamesForcedHidden, dynamicLayout?.columns]);

  const { mutateAsync, isLoading: isLoadingMutation } = useDynamicLayoutUpsertMutation(key);
  const persistAsync = useCallback(async (columns: { fieldName: string, width: number }[]) => {
    if (!projectId) return;
    const upsertDto: UpsertDynamicLayoutDto = {
      key,
      columns: columns.map((c) => ({ name: c.fieldName, width: c.width })),
    };
    await mutateAsync(upsertDto);
  }, [key, mutateAsync, projectId]);

  return {
    orderedVisibleColumnNames,
    columnWidthsByColumnName,
    persistAsync,
    isLoadingQuery,
    isLoadingMutation,
    columnNamesForcedHidden,
    columnNamesForcedVisible,
  };
}
