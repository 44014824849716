import { Buffer } from "buffer";
const CLIENT_NAME = "Visoplan";
const VERIFIER_KEY = "code_verifier";

export async function bimqOAuth(redirect_uri) {
  var crypto_array = new Uint8Array(20);
  self.crypto.getRandomValues(crypto_array);
  let code_verifier = Buffer.from(crypto_array).toString("base64");
  window.localStorage.setItem(VERIFIER_KEY, code_verifier);

  let code_challange = await window.crypto.subtle.digest(
    "SHA-256",
    Buffer.from(code_verifier)
  );
  let code_challange_b64 = Buffer.from(code_challange, "hex").toString(
    "base64"
  );
  code_challange_b64 = code_challange_b64
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");

  var urlParams = new URLSearchParams();
  urlParams.append("response_type", "code");
  urlParams.append("client_id", CLIENT_NAME);
  urlParams.append("redirect_uri", `${redirect_uri}/Callback`);
  urlParams.append("code_challenge", code_challange_b64);
  urlParams.append("code_challenge_method", "S256");
  window.location.href = `${BIMQ_API_URL}/oauth/authorize?${urlParams.toString()}`;
}

export async function createBimQToken(code, redirect_uri) {
  let code_verifier = localStorage.getItem(VERIFIER_KEY);
  localStorage.removeItem(VERIFIER_KEY);

  let response = await fetch(`${BIMQ_API_URL}/oauth/token`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
    mode: "cors",
    body: new URLSearchParams({
      grant_type: "authorization_code",
      code: code,
      code_verifier: code_verifier,
      client_id: CLIENT_NAME,
      redirect_uri: redirect_uri,
    }),
  });
  let result = await response.json();

  //Add expires in hours
  result["tokenExpireDate"] = new Date(
    Date.now() + result.expires_in * 60 * 60 * 1000
  );
  return result;
}

export async function getBimQTokenViaRefreshToken(refresh_token) {
  let response = await fetch(`${BIMQ_API_URL}/oauth/token`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
    body: new URLSearchParams({
      grant_type: "refresh_token",
      client_id: CLIENT_NAME,
      refresh_token: refresh_token,
    }),
  });
  let result = await response.json();
  result["refresh_token"] = refresh_token;
  result["tokenExpireDate"] = new Date(
    Date.now() + result.expires_in * 60 * 60 * 1000
  );
  return result;
}

export async function getCurrentBimQUser(token) {
  return await getRequest("user.json", token);
}

export async function getBimQProjects(token) {
  return await getRequest("contexts.json", token);
}

export async function getBimQActors(token, projectId) {
  return await getRequest(`contexts/${projectId}/actors.json`, token);
}

export async function getBimQDataStructures(token, projectId) {
  return await getRequest(`contexts/${projectId}/data_structures.json`, token);
}

export async function getBimQModels(token, projectId, actorId) {
  return await getRequest(
    `contexts/${projectId}/actors/${actorId}/get_models.json`,
    token
  );
}

export async function getBimQPhases(token, projectId) {
  return await getRequest(`contexts//${projectId}/phases.json`, token);
}

async function getRequest(path, token) {
  var response = await fetch(`${BIMQ_API_URL}/${path}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
  });
  return await response.json();
}
