import React, { useContext } from 'react';
import { Box, Button, styled } from '@mui/material';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import DocumentScopeContext, { DocumentScopeContextState } from 'documents/contexts/DocumentScopeContext';
import PersonIcon from '@mui/icons-material/Person';
import InventoryIcon from '@mui/icons-material/Inventory';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { useTranslation } from 'react-i18next';

const StyledButtonWithIcon = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  columnGap: '8px',
  borderRadius: '4px',
  fontWeight: 400,
  color: theme.palette.text.primary,
  '&.active': {
    fontWeight: 600,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function DocumentsNavigationMenu() {
  const { t } = useTranslation('documents');
  const { setDocumentScope, documentScope } = useContext<DocumentScopeContextState>(DocumentScopeContext);
  return (
    <Box
      id="DocumentsNavigationMenu"
      sx={{
        display: 'flex', flexDirection: 'column', p: 2,
      }}
    >
      <StyledButtonWithIcon
        onClick={() => setDocumentScope({ key: DocumentScopeKey.ProjectDocuments })}
        className={documentScope.key === DocumentScopeKey.ProjectDocuments ? 'active' : undefined}
      >
        <AutoAwesomeMotionIcon fontSize="small" />
        {t('folder-explorer_special-folder-all-documents', 'All Documents')}
      </StyledButtonWithIcon>
      <StyledButtonWithIcon
        onClick={() => setDocumentScope({ key: DocumentScopeKey.MyUploads })}
        className={documentScope.key === DocumentScopeKey.MyUploads ? 'active' : undefined}
      >
        <PersonIcon fontSize="small" />
        {t('folder-explorer_special-folder-my-uploads', 'My Uploads')}
      </StyledButtonWithIcon>
      <StyledButtonWithIcon
        onClick={() => setDocumentScope({ key: DocumentScopeKey.Archive })}
        className={documentScope.key === DocumentScopeKey.Archive ? 'active' : undefined}
      >
        <InventoryIcon fontSize="small" />
        {t('folder-explorer_special-folder-archive', 'Archive')}
      </StyledButtonWithIcon>
    </Box>
  );
}
