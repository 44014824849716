import React, { useCallback, useMemo, useState } from 'react';
import { Box, Link, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Color from 'color';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RoundIconButton from 'common/components/RoundIconButton';
import IssueDetailsGeneralPanel from 'issues/components/IssueDetailsGeneralPanel';
import IssueResponsibilityPanel from 'issues/components/IssueResponsibilityPanel';
import IssueCompleteAssignmentPanel from 'issues/components/IssueCompleteAssignmentPanel';
import IssueRejectAssignmentPanel from 'issues/components/IssueRejectAssignmentPanel';
import IssueRejectReviewPanel from 'issues/components/IssueRejectReviewPanel';
import ApproveReviewPanel from 'issues/components/ApproveReviewPanel';
import IssuePublishPanel from 'issues/components/IssuePublishPanel';
import IssueCommentsPanel from 'issues/components/IssueCommentsPanel';
import IssueLinkedDocumentsPanel from 'issues/components/IssueLinkedDocumentsPanel';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import IssueLinkedImagesPanel from 'issues/components/IssueLinkedImagesPanel';
import { useNavigate } from 'react-router-dom';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import IssueManageAnnotationsPanel from 'issues/components/IssueManageAnnotationsPanel';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';

interface IssueDetailsPanelProps {
  issueId: string | undefined,
  onClickDocumentVersionItem?: ((documentVersionId: string) => void) | undefined,
  onClose?: (() => void) | undefined,
  nonInteractive?: boolean | undefined,
  title?: string | undefined,
}

export default function IssueDetailsPanel({
  issueId,
  onClickDocumentVersionItem,
  onClose,
  nonInteractive,
  title,
}: IssueDetailsPanelProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { data: currentProject } = useCurrentProjectQuery();
  const { data: issue } = useIssueQuery(issueId);
  const { issueIdCurrentlyLinking } = useDocumentViewerContext();

  const [completingAssignment, setCompletingAssignment] = useState(false);
  const onClickCompleteAssignment = useCallback(() => setCompletingAssignment(true), []);
  const onCloseCompleteAssignmentPanel = useCallback(() => setCompletingAssignment(false), []);

  const [rejectingAssignment, setRejectingAssignment] = useState(false);
  const onClickRejectAssignment = useCallback(() => setRejectingAssignment(true), []);
  const onCloseRejectAssignmentPanel = useCallback(() => setRejectingAssignment(false), []);

  const [rejectingReview, setRejectingReview] = useState(false);
  const onClickRejectReview = useCallback(() => setRejectingReview(true), []);
  const onCloseRejectReviewPanel = useCallback(() => setRejectingReview(false), []);

  const [approvingReview, setApprovingReview] = useState(false);
  const onClickApproveReview = useCallback(() => setApprovingReview(true), []);
  const onCloseApproveReviewPanel = useCallback(() => setApprovingReview(false), []);

  const [publishing, setPublishing] = useState(false);
  const onClickPublish = useCallback(() => setPublishing(true), []);
  const onClosePublishPanel = useCallback(() => setPublishing(false), []);

  const isIssueActionInProgress = useMemo(
    () => completingAssignment
    || rejectingAssignment
    || rejectingReview
    || approvingReview
    || publishing,
    [approvingReview, completingAssignment, publishing, rejectingAssignment, rejectingReview],
  );

  const statusBackgroundColor = useMemo(() => {
    if (!issue?.issueStatus) return theme.palette.secondary.light;
    return Color(issue.issueStatus.color).lightness(97).hex();
  }, [issue, theme]);

  const navigate = useNavigate();
  const onClickViewInIssuesView = useCallback(() => {
    if (!currentProject || !issue) return;
    navigate(`/projects/${currentProject.id}/issues/${issue.id}`);
  }, [currentProject, issue, navigate]);

  if (!issueId) return null;
  if (!issue) return <CenteredCircularProgress />;
  return (
    <Box
      id="IssueDetailsPanel"
      sx={{
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!issueIdCurrentlyLinking && (
        <Box sx={{ flex: '0 0 0', height: 73, display: 'flex', gap: 2, justifyItems: 'flexStart', alignItems: 'center', p: 3 }}>
          {!!onClose && <RoundIconButton Icon={ArrowBackIcon} onClick={onClose} sx={{ flexShrink: 0 }} />}
          <Typography variant="h5" sx={{ pt: '1px' }}>{title ?? t('issue-details-panel_default-title', 'Issue Details')}</Typography>
          {!nonInteractive && <Link onClick={onClickViewInIssuesView} sx={{ ml: 'auto', textAlign: 'right', pt: '1px' }}>{t('issue-details-panel_view-in-issues-view-link-text', 'Open in issues view')}</Link>}
        </Box>
      )}
      <IssueManageAnnotationsPanel />
      <Box sx={{ backgroundColor: statusBackgroundColor, overflow: 'auto', flexGrow: 1, display: 'flex', flexDirection: 'column', pb: 2, px: 2, gap: 2 }}>
        <IssueDetailsGeneralPanel issueId={issueId} />
        {!isIssueActionInProgress && (
          <IssueResponsibilityPanel
            issueId={issueId}
            onClickCompleteAssignment={onClickCompleteAssignment}
            onClickRejectAssignment={onClickRejectAssignment}
            onClickRejectReview={onClickRejectReview}
            onClickApproveReview={onClickApproveReview}
            onClickPublish={onClickPublish}
            nonInteractive={nonInteractive}
          />
        )}
        {!!completingAssignment && <IssueCompleteAssignmentPanel issueId={issueId} onClose={onCloseCompleteAssignmentPanel} />}
        {!!rejectingAssignment && <IssueRejectAssignmentPanel issueId={issueId} onClose={onCloseRejectAssignmentPanel} />}
        {!!rejectingReview && <IssueRejectReviewPanel issueId={issueId} onClose={onCloseRejectReviewPanel} />}
        {!!approvingReview && <ApproveReviewPanel issueId={issueId} onClose={onCloseApproveReviewPanel} />}
        {!!publishing && <IssuePublishPanel issueId={issueId} onClose={onClosePublishPanel} />}
        <IssueLinkedDocumentsPanel issueId={issueId} onClickDocumentVersionItem={onClickDocumentVersionItem} />
        <IssueLinkedImagesPanel issueId={issueId} />
        <IssueCommentsPanel issueId={issueId} nonInteractive={nonInteractive} />
      </Box>
    </Box>
  );
}
