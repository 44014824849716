import { useState, useEffect } from "react";

import { TagService } from "../../Services";

export default function useTags(projectId, tagIds) {
  const [projectTags, setProjectTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);

  useEffect(() => {
    const effect = async () => {
      if (!projectId) return;

      const tags = await TagService.getProjectTags(projectId);
      setTagOptions(
        tags.map((tag) => ({
          value: tag.id,
          label: tag.name,
          color: tag.color,
          abbreviation: tag.name,
          tagId: tag.id,
        }))
      );
      setProjectTags(tags);
    };
    effect();
  }, [projectId, tagIds]);

  return { projectTags, tagOptions };
}
