import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import PersistCollaboratorRoleDefinitionDto from 'projects/types/PersistCollaboratorRoleDefinitionDto';
import CollaboratorRoleDefinitionDto from 'projects/types/CollaboratorRoleDefinitionDto';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';

export default function useCollaboratorRoleDefinitionCreateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { data: currentProject } = useCurrentProjectQuery();
  const { listQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Project); // role definitions are part of the project dto, so we need to invalidate that
  return useMutation<CollaboratorRoleDefinitionDto, unknown, PersistCollaboratorRoleDefinitionDto>(async (dto: PersistCollaboratorRoleDefinitionDto) => {
    if (!currentProject) throw new Error('current project is undefined');
    const { data } = await axiosInstance.post<CollaboratorRoleDefinitionDto[]>(`${ApiEndpoint.RoleDefinitions}`, [dto]);
    return data[0];
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(listQueryKey);
    },
  });
}
