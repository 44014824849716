import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import DocumentVersionsRowRangeData from 'documents/types/DocumentVersionsRowRangeData';
import useCachedEntityListPartialReplace from 'api/hooks/useCachedEntityListPartialReplace';
import DocumentVersionImportDto from 'documents/types/DocumentVersionImportDto';

export default function useDocumentVersionIfcImportMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { listQueryKey, odataQueryQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const partiallyUpdateCachedEntityList = useCachedEntityListPartialReplace();

  return useMutation<DocumentVersionDto | undefined, unknown, DocumentVersionImportDto>(async (documentVersionImportDto: DocumentVersionImportDto) => {
    const { data } = await axiosInstance.patch<DocumentVersionDto>(`${ApiEndpoint.DocumentVersion}/import`, documentVersionImportDto);
    return data;
  }, {
    onSuccess: (documentVersion) => {
      if (!documentVersion) return;
      // update id entpoint cache
      queryClient.setQueriesData<DocumentVersionDto[] | undefined>({ queryKey: odataQueryQueryKey }, ((cachedData: DocumentVersionDto[] | undefined) => {
        if (!cachedData) return undefined;
        return partiallyUpdateCachedEntityList(cachedData, [documentVersion]);
      }));
      // update row range (data grid) cache
      queryClient.setQueriesData<DocumentVersionsRowRangeData | undefined>({ queryKey: [...listQueryKey, 'rowRange'] }, ((cachedData: DocumentVersionsRowRangeData | undefined) => {
        if (!cachedData) return undefined;
        const updatedEntities = partiallyUpdateCachedEntityList(cachedData.entities, [documentVersion]);
        return {
          ...cachedData,
          entities: updatedEntities,
        };
      }));
    },
  });
}
