import React from 'react';
import { Box, Modal } from '@mui/material';
import DocumentViewerContextProvider from 'documents-details/contexts/DocumentViewerContextProvider';
import DocumentDetails from 'documents-details/components/DocumentDetails';
import SuspenseWithErrorBoundary from 'error/SuspenseWithErrorBoundary';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import IssuesFilterContextProvider from 'issues/contexts/IssuesFilterContextProvider';

export default function DocumentDetailsModal() {
  return (
    <Modal id="DocumentDetailsModal" open disablePortal>
      <Box sx={{ height: '100%' }}>
        <SuspenseWithErrorBoundary suspenseFallback={<CenteredCircularProgress />}>
          <IssuesFilterContextProvider>
            <DocumentViewerContextProvider>
              <DocumentDetails />
            </DocumentViewerContextProvider>
          </IssuesFilterContextProvider>
        </SuspenseWithErrorBoundary>
      </Box>
    </Modal>
  );
}
