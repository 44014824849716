import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Alert, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, MenuItem, Divider, Chip } from '@mui/material';
import useDocumentListsOdataQuery from 'documents-lists/hooks/useDocumentListsOdataQuery';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import { useTranslation } from 'react-i18next';
import DocumentListUpdateDocumentsDto from 'documents-lists/types/DocumentListUpdateDocumentsDto';
import useDocumentListUpdateDocumentsMutation from 'documents-lists/hooks/useDocumentListUpdateDocumentsMutation';
import CreatePlanlistDialog from 'documents-lists/components/CreatePlanlistDialog';

interface AddToPlanlistDialogProps {
  onClose: (planlistIdAddedTo: string | undefined) => void,
  documentVersionIds: string[],
}

export default function AddToPlanlistDialog({
  onClose,
  documentVersionIds,
}: AddToPlanlistDialogProps) {
  const { t } = useTranslation('documents-lists');
  const { data: planlists, isLoading: isLoadingPlanlists } = useDocumentListsOdataQuery({});
  const planlistsById = useMemo(() => (planlists ? new Map(planlists.map((p) => [p.id, p])) : undefined), [planlists]);
  const { mutateAsync: mutateUpdateAsync, isLoading: isLoadingUpdate } = useDocumentListUpdateDocumentsMutation();
  const isLoading = useMemo(() => isLoadingUpdate || isLoadingPlanlists, [isLoadingPlanlists, isLoadingUpdate]);
  const [targetPlanlistId, setTargetPlanlistId] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const [requestErrorMessage, setRequestErrorMessage] = useState<string | undefined>(undefined);
  const clearErrorMessage = useCallback(() => setRequestErrorMessage(undefined), []);
  const [createPlanlistDialogOpen, setCreatePlanlistDialogOpen] = useState(false);
  const onClickCreatePlanlist = useCallback(() => setCreatePlanlistDialogOpen(true), []);
  const onCloseCreatePlanlistDialog = useCallback((createdPlanlistId: string | undefined) => {
    setCreatePlanlistDialogOpen(false);
    onClose(createdPlanlistId);
  }, [onClose]);

  useEffect(() => {
    setTargetPlanlistId((prev) => {
      if (prev || !planlists?.length) return prev;
      return planlists[0].id;
    });
  }, [planlists]);

  const onConfirm = useCallback(async () => {
    if (!documentVersionIds.length || !targetPlanlistId) return;
    const targetPlanlist = planlistsById?.get(targetPlanlistId);
    if (!targetPlanlist) return;
    const alreadyAssignedDocumentVersionIdsSet = new Set(targetPlanlist.documentVersionIds);
    const newDocumentVersionIds = documentVersionIds.filter((id) => !alreadyAssignedDocumentVersionIdsSet.has(id));
    if (newDocumentVersionIds.length > 0) {
      const updateDto: DocumentListUpdateDocumentsDto = {
        id: targetPlanlist.id,
        documentVersionIds: [...targetPlanlist.documentVersionIds, ...newDocumentVersionIds],
      };
      try {
        await mutateUpdateAsync(updateDto);
        let successFeedbackMessage = t('add-to-planlist-dialog_success-message', '{{count}} documents have been added to the planlist {{planlist}}.', { count: newDocumentVersionIds.length, planlist: targetPlanlist.name });
        if (newDocumentVersionIds.length < documentVersionIds.length) successFeedbackMessage += ` ${t('add-to-planlist-dialog_already-on-list-message', 'The remaining {{count}} documents already are on this planlist.', { count: documentVersionIds.length - newDocumentVersionIds.length })}`;
        setSuccessMessage(successFeedbackMessage);
      } catch (error) {
        setRequestErrorMessage(getRequestErrorMessage(error));
      }
    } else {
      setRequestErrorMessage(t('add-to-planlist-dialog_documents-already-on-list', 'All dropped documents are already on the planlist.'));
    }
  }, [documentVersionIds, targetPlanlistId, planlistsById, mutateUpdateAsync, t, getRequestErrorMessage]);

  const onCloseSuccessDialog = useCallback(() => onClose(targetPlanlistId), [onClose, targetPlanlistId]);

  const onCancel = useCallback(() => onClose(undefined), [onClose]);

  return (
    <Dialog
      id="AddToPlanlistDialog"
      open
      scroll="paper"
      PaperProps={{ style: { width: '400px', minHeight: '240px' } }}
    >
      <DialogTitle>{t('add-to-planlist-dialog_title', 'Add {{count}} documents to a planlist', { count: documentVersionIds.length })}</DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, py: 3 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <FormControl size="small" sx={{ flexGrow: 1 }}>
              <InputLabel id="add-to-planlist-dialog_existing-planlist-select-label">
                {t('add-to-planlist-dialog_existing-planlist-select-label', 'Existing Plan List')}
              </InputLabel>
              <Select
                id="add-to-planlist-dialog_existing-planlist-select"
                labelId="add-to-planlist-dialog_existing-planlist-select-label"
                value={targetPlanlistId ?? ''}
                onChange={(e) => setTargetPlanlistId(e.target.value)}
                label={t('add-to-planlist-dialog_existing-planlist-select-label', 'Existing Plan List')}
                disabled={isLoading}
                sx={{ flexGrow: 1 }}
              >
                {planlists?.map((p) => <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>)}
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={onConfirm} disabled={isLoading || !targetPlanlistId} sx={{ gap: 1 }}>
              {isLoadingUpdate && <CircularProgress size={12} />}
              {t('add-to-planlist-dialog_confirm-button-label', 'Add')}
            </Button>
          </Box>
          <Divider><Chip label={t('add-to-planlist-dialog_divider-label', 'Or')} /></Divider>
          <Button variant="contained" color="primary" onClick={onClickCreatePlanlist} disabled={isLoading}>
            {t('add-to-planlist-dialog_create-new-planlist-button-label', 'Create New Planlist')}
          </Button>
          {!!requestErrorMessage && <Alert severity="error" onClose={clearErrorMessage}>{requestErrorMessage}</Alert>}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between', pt: 3 }}>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          {t('add-to-planlist-dialog_cancel-button-label', 'Cancel')}
        </Button>
      </DialogActions>
      <CreatePlanlistDialog open={createPlanlistDialogOpen} documentVersionIds={documentVersionIds} onClose={onCloseCreatePlanlistDialog} canGoBack />
      {successMessage && (
        <Dialog open>
          <DialogTitle>{t('add-to-planlist-dialog_success-dialog-title', 'Documents Added to Planlist')}</DialogTitle>
          <DialogContent>
            <Alert severity="success">{successMessage}</Alert>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={onCloseSuccessDialog}>{t('add-to-planlist-dialog_success-dialog-close-button-label', 'Close')}</Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
}
