import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import CreateLabelDto from 'labels/types/CreateLabelDto';
import LabelDto from 'labels/types/LabelDto';
import { useCallback, useMemo } from 'react';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';
import LabelType from 'labels/types/LabelType';
import useLabelsOdataQueryKey from 'labels/hooks/useLabelsOdataQueryKey';
import Project from 'projects/types/Project';
import useCachedEntityListPartialUpdate from 'api/hooks/useCachedEntityListPartialUpdate';

export default function useLabelCreateMutation() {
  const currentProjectId = useCurrentProjectId();
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { getQueryKey, queryKeyBase } = useLabelsOdataQueryKey();
  const { baseQueryKey: tagsBaseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Tag);
  const { getListsByIdsQueryKey: getProjectsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Project);
  const projectQueryKey = useMemo(() => (currentProjectId ? getProjectsQueryKey([currentProjectId]) : undefined), [currentProjectId, getProjectsQueryKey]);
  const partiallyUpdateCachedEntityList = useCachedEntityListPartialUpdate();
  const mutationFn = useCallback(async (dto: CreateLabelDto) => {
    const { data: label } = await axiosInstance.post<LabelDto>(`${ApiEndpoint.Label}`, dto);
    return label;
  }, [axiosInstance]);
  const onMutate = useCallback(async (dto: CreateLabelDto) => {
    if (!currentProjectId) throw new Error('dependency error');
    // optimistic update. this is primarily for the create-select-dropdown in the DMS to feel snappy
    const odataQueryKeys = [
      getQueryKey({ filter: { type: LabelType[dto.type], isDeleted: false }, orderBy: 'order asc' }),
      getQueryKey({ filter: { type: LabelType[dto.type], isDeleted: undefined }, orderBy: 'order asc' }),
      getQueryKey({ filter: { type: LabelType[dto.type], isDeleted: false }, orderBy: 'name asc' }),
      getQueryKey({ filter: { type: LabelType[dto.type], isDeleted: undefined }, orderBy: 'name asc' }),
    ];
    await Promise.all(odataQueryKeys.map(async (odataQueryKey) => {
      await queryClient.cancelQueries({ queryKey: odataQueryKey });
      const now = new Date().toISOString();
      const optimisticEntity: LabelDto = {
        ...dto,
        id: '',
        projectId: currentProjectId,
        originalName: dto.name,
        isDefault: false,
        order: Number.POSITIVE_INFINITY,
        parentId: dto.parentId,
        isDeleted: false,
        creationDate: now,
        editDate: now,
      };
      queryClient.setQueryData<LabelDto[] | undefined>(odataQueryKey, (existingTags) => {
        if (!Array.isArray(existingTags)) return [optimisticEntity];
        return [...existingTags, optimisticEntity];
      });
    }));
  }, [currentProjectId, getQueryKey, queryClient]);
  const onSettled = useCallback((data: LabelDto | undefined) => {
    if (!currentProjectId) throw new Error('currentProjectId is undefined');
    if (!data) throw new Error('data is undefined');
    queryClient.invalidateQueries({ queryKey: tagsBaseQueryKey });
    queryClient.invalidateQueries({ queryKey: queryKeyBase });

    queryClient.setQueriesData<Project[] | undefined>({ queryKey: projectQueryKey }, ((cachedData: Project[] | undefined) => {
      if (!cachedData) return undefined;
      return partiallyUpdateCachedEntityList(cachedData, [currentProjectId], (e: Project) => ({ ...e, tagIds: [...e.tagIds, data.id] }));
    }));
  }, [queryClient, tagsBaseQueryKey, queryKeyBase, projectQueryKey, partiallyUpdateCachedEntityList, currentProjectId]);
  return useMutation<LabelDto, unknown, CreateLabelDto>({
    mutationFn,
    onMutate,
    onSettled,
  });
}
