import { useEffect, useState } from 'react';
import { FileTypeIndexes } from '../../../Helpers/Common';
import { GET, POST } from '../../../Helpers/Request';

export default function useFileUrl(resourceId?: string, resourceType?: number) {
  const [fileUrl, setFileUrl] = useState('');

  useEffect(() => {
    (async () => {
      let result = null;
      if (resourceType === FileTypeIndexes.DWG) {
        result = await GET(`api/resource/${resourceId}`);
      } else {
        result = await POST(
          'api/documentVersion/download?isPreview=true',
          [resourceId],
        );
      }
      if (result?.status === 200) {
        // @ts-ignore
        setFileUrl(window.URL.createObjectURL(result.body));
      } else setFileUrl('');
    })();
  }, [resourceId, resourceType]);

  return fileUrl;
}
