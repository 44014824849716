import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import ColorDescriptorDto from 'common/types/ColorDescriptorDto';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import useProjectDocumentStatusesQuery from 'documents/hooks/useProjectDocumentStatusesQuery';
import { MenuItem, Select } from '@mui/material';
import ColoredChip from 'common/components/ColoredChip';
import useDocumentVersionStatusUpdateMutation from 'documents/hooks/useDocumentVersionStatusUpdateMutation';
import DocumentVersionStatusUpdateDto from 'documents/types/DocumentVersionStatusUpdateDto';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import useCurrentUserFolderAccessLevel from 'documents-folders/hooks/useCurrentUserFolderAccessLevel';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';

export default function StatusCell({
  data,
  value,
}: ICellRendererParams<DocumentsDataGridRow, ColorDescriptorDto | undefined>) {
  const { data: documentStatuses } = useProjectDocumentStatusesQuery();
  const currentUserRole = useCurrentUserRole();
  const [displayValue, setDisplayValue] = useState<string>(value?.id ?? '');
  const { mutateAsync, isLoading } = useDocumentVersionStatusUpdateMutation();
  const canSetStatusToPublished = useMemo(() => currentUserRole?.allowedActions?.has(RoleAction.DocStatus_ChangeToPublished), [currentUserRole?.allowedActions]);
  const isLockedAtPublished = useMemo(() => {
    const canChangeFromPublished = currentUserRole?.allowedActions?.has(RoleAction.DocStatus_ChangeFromPublished);
    if (canChangeFromPublished) return false;
    return (!!value?.id && !!documentStatuses && documentStatuses.find((status) => status.originalName === 'Published')?.id === value.id) ?? true;
  }, [currentUserRole?.allowedActions, documentStatuses, value?.id]);
  const hiddenStatusIds = useMemo(() => new Set<string>(documentStatuses?.filter((s) => s.isDefault && (s.originalName === 'Archived' || s.originalName === 'Other' || (!canSetStatusToPublished && s.originalName === 'Published'))).map((s) => s.id)), [canSetStatusToPublished, documentStatuses]);
  const accessLevel = useCurrentUserFolderAccessLevel(data?.folderId);

  const onChange = useCallback(async (selectedStatusId: string) => {
    if (!documentStatuses || !data || isLoading) return;
    setDisplayValue(selectedStatusId);
    const persistDto: DocumentVersionStatusUpdateDto = {
      id: data.id,
      documentStatusId: selectedStatusId,
    };
    await mutateAsync(persistDto);
  }, [data, documentStatuses, isLoading, mutateAsync]);

  useEffect(() => setDisplayValue(value?.id ?? ''), [value]);

  return (
    <Select
      value={displayValue}
      disabled={!documentStatuses?.length || isLoading || data?.isArchived || !currentUserRole?.allowedActions?.has(RoleAction.Document_Editing) || accessLevel !== CollaboratorFolderAccessLevel.ReadWrite || isLockedAtPublished}
      onChange={(e) => onChange(e.target.value)}
      variant="standard"
      sx={{
        width: '100%', height: '24px', pb: '2px', position: 'relative', top: '-1px',
      }}
    >
      {documentStatuses?.map((documentStatus) => (
        <MenuItem value={documentStatus.id} key={documentStatus.id} sx={{ display: hiddenStatusIds.has(documentStatus.id) ? 'none' : undefined }}>
          <ColoredChip entity={documentStatus} />
        </MenuItem>
      ))}
    </Select>
  );
}
