import React from "react";
import { withTranslation } from "react-i18next";

const ModalFooter = ({
  t,
  uploadCategory /*0: issue, 1: model */,
  currentSetupModelIndex,
  handleButtonClick,
  isEnableFinish,
  onDone,
  doneText,
  uploadFileSize,
}) => (
  <div className="upload-progress-modal--action">
    {uploadCategory === 1 && currentSetupModelIndex >= 0 ? (
      <button
        className="btn btn--secondary"
        name="back"
        onClick={handleButtonClick}
      >
        {t("back", "Back")}
      </button>
    ) : (
      <div />
    )}
    {uploadCategory === 1 ? (
      currentSetupModelIndex === Object.keys(uploadFileSize).length - 1 ? (
        <input
          type="button"
          className="btn btn--primary"
          name="finish"
          value={t("finish_model_import", "Finish Model Import")}
          onClick={handleButtonClick}
          disabled={!isEnableFinish}
        />
      ) : (
        <input
          type="button"
          className="btn btn--secondary"
          name="next"
          value={
            currentSetupModelIndex < 0
              ? t("setup_models", "Set up Models")
              : t("next", "Next")
          }
          onClick={handleButtonClick}
        />
      )
    ) : (
      <button className="btn btn--secondary" onClick={onDone}>
        {doneText || t("close", "Close")}
      </button>
    )}
  </div>
);

export default withTranslation()(ModalFooter);
