import useAxiosInstance from 'api/hooks/useAxiosInstance';
import { OdataEndpointOptions } from 'api/hooks/useDefaultOdataEndpointQuery';
import ApiEndpoint from 'api/types/ApiEndpoint';
import buildQuery, { QueryOptions } from 'odata-query';
import { useCallback } from 'react';

export default function useDefaultOdataEndpointFetchFn<
  TDto,
  TQueryDto = TDto,
  TCachedEntity extends TDto = TDto,
  >(
  endpoint: ApiEndpoint,
  entityFactory?: ((dto: TDto) => Readonly<TCachedEntity>) | undefined,
  endpointOptions?: OdataEndpointOptions,
) {
  const axiosInstance = useAxiosInstance();
  return useCallback(async (odataQuery: Partial<QueryOptions<TQueryDto>> | undefined) => {
    if (!odataQuery) return undefined;
    const odataQueryString = buildQuery<TQueryDto>(odataQuery);
    const queryArgsString = endpointOptions?.queryArgs ? `&${endpointOptions.queryArgs.map((a) => `${a.name}=${a.value}`).join('&')}` : '';
    const url = `/${endpoint}${odataQueryString}${queryArgsString}`;
    const { data: dtos } = await axiosInstance.get<TDto[]>(url);
    if (entityFactory) return dtos.map(entityFactory);
    return dtos as TCachedEntity[];
  }, [axiosInstance, endpoint, endpointOptions, entityFactory]);
}
