import React from 'react';

interface CloseButtonProps {
  onClick: () => void;
  id?: string;
  topPosition?: string;
  rightPosition?: string;
  classNames?: string;
}

export default function CloseButton({
  id,
  onClick,
  topPosition,
  rightPosition,
  classNames,
}: CloseButtonProps) {
  return (
    <svg
      id={id ?? 'CloseButton'}
      className={`icon-close ${classNames}`}
      style={{ top: topPosition, right: rightPosition }}
      onClick={onClick}
    >
      <use xlinkHref="/img/sprite.svg#full-close-btn" />
    </svg>
  );
}

CloseButton.defaultProps = {
  topPosition: undefined,
  rightPosition: undefined,
  classNames: '',
};
