import i18n from 'i18next';

export const groupTypes = {
  disciplines: 0,
  building: 1,
  floor: 2,
  workPhases: 3,
  creationDate: 4,
  version: 5,
  freeText: 6,
  project: 7,
  custom: 8,
  docStatus: 9,
  number: 10,
  chars: 11,
};

export const versionTypes = () => [
  { label: i18n.t('numbers', 'Numbers'), value: 'numbers' },
  { label: i18n.t('letters', 'Letters'), value: 'letters' },
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const File_Types = [
  {
    label: 'pdf', value: '.pdf', color: '#ffffff', fileType: true,
  },
  {
    label: 'csv', value: '.csv', color: '#ffffff', fileType: true,
  },
  {
    label: 'abs', value: '.abs', color: '#ffffff', fileType: true,
  },
  {
    label: 'ifc', value: '.ifc', color: '#ffffff', fileType: true,
  },
  {
    label: 'docx', value: '.docx', color: '#ffffff', fileType: true,
  },
  {
    label: 'docm', value: '.docm', color: '#ffffff', fileType: true,
  },
  {
    label: 'doc', value: '.doc', color: '#ffffff', fileType: true,
  },
  {
    label: 'dotx', value: '.dotx', color: '#ffffff', fileType: true,
  },
  {
    label: 'dotm', value: '.dotm', color: '#ffffff', fileType: true,
  },
  {
    label: 'dot', value: '.dot', color: '#ffffff', fileType: true,
  },
  {
    label: 'xps', value: '.xps', color: '#ffffff', fileType: true,
  },
  {
    label: 'mht', value: '.mht', color: '#ffffff', fileType: true,
  },
  {
    label: 'mhtml', value: '.mhtml', color: '#ffffff', fileType: true,
  },
  {
    label: 'htm', value: '.htm', color: '#ffffff', fileType: true,
  },
  {
    label: 'html', value: '.html', color: '#ffffff', fileType: true,
  },
  {
    label: 'rtf', value: '.rtf', color: '#ffffff', fileType: true,
  },
  {
    label: 'txt', value: '.txt', color: '#ffffff', fileType: true,
  },
  {
    label: 'xml', value: '.xml', color: '#ffffff', fileType: true,
  },
  {
    label: 'odt', value: '.odt', color: '#ffffff', fileType: true,
  },
  {
    label: 'xlsx', value: '.xlsx', color: '#ffffff', fileType: true,
  },
  {
    label: 'xls', value: '.xls', color: '#ffffff', fileType: true,
  },
  {
    label: 'ppsx', value: '.ppsx', color: '#ffffff', fileType: true,
  },
  {
    label: 'ppsm', value: '.ppsm', color: '#ffffff', fileType: true,
  },
  {
    label: 'pps', value: '.pps', color: '#ffffff', fileType: true,
  },
  {
    label: 'ppa', value: '.ppa', color: '#ffffff', fileType: true,
  },
  {
    label: 'pptx', value: '.pptx', color: '#ffffff', fileType: true,
  },
  {
    label: 'pptm', value: '.pptm', color: '#ffffff', fileType: true,
  },
  {
    label: 'ppt', value: '.ppt', color: '#ffffff', fileType: true,
  },
  {
    label: 'potx', value: '.potx', color: '#ffffff', fileType: true,
  },
  {
    label: 'potm', value: '.potm', color: '#ffffff', fileType: true,
  },
  {
    label: 'pot', value: '.pot', color: '#ffffff', fileType: true,
  },
  {
    label: 'thmx', value: '.thmx', color: '#ffffff', fileType: true,
  },
  {
    label: 'mp4', value: '.mp4', color: '#ffffff', fileType: true,
  },
  {
    label: 'wmv', value: '.wmv', color: '#ffffff', fileType: true,
  },
  {
    label: 'dwg', value: '.dwg', color: '#ffffff', fileType: true,
  },
  {
    label: 'dxf', value: '.dxf', color: '#ffffff', fileType: true,
  },
  {
    label: 'pln', value: '.pln', color: '#ffffff', fileType: true,
  },
  {
    label: 'pla', value: '.pla', color: '#ffffff', fileType: true,
  },
  {
    label: 'rvt', value: '.rvt', color: '#ffffff', fileType: true,
  },
  {
    label: 'smc', value: '.smc', color: '#ffffff', fileType: true,
  },
  {
    label: 'nwf', value: '.nwf', color: '#ffffff', fileType: true,
  },
  {
    label: 'nwd', value: '.nwd', color: '#ffffff', fileType: true,
  },
  {
    label: 'bmp', value: '.bmp', color: '#ffffff', fileType: true,
  },
  {
    label: 'gif', value: '.gif', color: '#ffffff', fileType: true,
  },
  {
    label: 'ico', value: '.ico', color: '#ffffff', fileType: true,
  },
  {
    label: 'jpeg', value: '.jpeg', color: '#ffffff', fileType: true,
  },
  {
    label: 'jpg', value: '.jpg', color: '#ffffff', fileType: true,
  },
  {
    label: 'png', value: '.png', color: '#ffffff', fileType: true,
  },
  {
    label: 'ps', value: '.ps', color: '#ffffff', fileType: true,
  },
  {
    label: 'psd', value: '.psd', color: '#ffffff', fileType: true,
  },
  {
    label: 'svg', value: '.svg', color: '#ffffff', fileType: true,
  },
  {
    label: 'tif', value: '.tif', color: '#ffffff', fileType: true,
  },
  {
    label: 'tiff', value: '.tiff', color: '#ffffff', fileType: true,
  },
];

export const getEncodingTitle = (name: string) => {
  switch (name) {
    case 'plan_encoding':
      return i18n.t('plan_encoding', 'Plan Encoding');
    case 'document_encoding':
      return i18n.t('document_encoding', 'Document Encoding');
    case 'model_encoding':
      return i18n.t('model_encoding', 'Model Encoding');
    case 'custom_encoding':
      return i18n.t('custom_encoding', 'Model Encoding');
    case 'settings':
      return i18n.t('settings', 'Settings');
    default:
      return '';
  }
};
