import React, {
  Suspense, useCallback, useMemo, useState,
} from 'react';
import {
  Alert,
  Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography,
} from '@mui/material';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import ShareDocumentsFeedbackDialog from 'share/components/ShareDocumentsFeedbackDialog';
import { Translation } from 'react-i18next';
import useDocumentsShareLinkApi from 'share/hooks/useDocumentsShareLinkApi';
import ShareLinkMessage from 'share/types/ShareLinkMessage';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import CreateShareLinkMessageControl from 'share/components/CreateShareLinkMessageControl';

interface ShareDocumentsDialogProps {
  open: boolean,
  onClose: () => void,
  documentVersionIds: string[],
}

export default function ShareDocumentsDialog({
  open,
  onClose,
  documentVersionIds,
}: ShareDocumentsDialogProps) {
  const { sendShareLinkMessage } = useDocumentsShareLinkApi();
  const getRequestErrorMessage = useRequestErrorMessage();
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isRequestInFlight, setIsRequestInFlight] = useState(false);
  const [shareLinkMessage, setShareLinkMessage] = useState<ShareLinkMessage | undefined>({
    collaboratorIds: [],
    userGroupIds: [],
    externalEmailAddresses: [],
    comment: '',
    expirationDurationDays: 1,
  });

  const onCloseFeedbackDialog = useCallback(() => {
    setIsFeedbackDialogOpen(false);
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(async () => {
    if (!documentVersionIds.length) return;
    if (!shareLinkMessage) return;
    setIsRequestInFlight(true);
    try {
      await sendShareLinkMessage(shareLinkMessage, documentVersionIds, 'sharelink');
      setIsFeedbackDialogOpen(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    } finally {
      setIsRequestInFlight(false);
    }
  }, [documentVersionIds, getRequestErrorMessage, sendShareLinkMessage, shareLinkMessage]);

  const canConfirm = useMemo(() => {
    if (!shareLinkMessage || isRequestInFlight) return false;
    return shareLinkMessage.collaboratorIds.length > 0 || shareLinkMessage.externalEmailAddresses.length > 0 || shareLinkMessage.userGroupIds.length > 0;
  }, [isRequestInFlight, shareLinkMessage]);

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{ style: { maxWidth: 'unset', width: '718px', height: '720px' } }}
        id="ShareDocumentsDialog"
      >
        <Suspense fallback={<CenteredCircularProgress />}>
          <Translation ns="share">
            {(t) => (
              <>
                <DialogTitle>
                  {t('share-documents-dialog_title', 'Share Documents', { count: documentVersionIds.length })}
                  <Typography>{t('share-documents-dialog_subtitle', '{{count}} Documents', { count: documentVersionIds.length })}</Typography>
                </DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CreateShareLinkMessageControl value={shareLinkMessage} onChange={setShareLinkMessage} sx={{ flexGrow: 1 }} />
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 3 }}>
                  <Button variant="contained" color="secondary" onClick={onClose} disabled={isRequestInFlight}>{t('share-documents-dialog_cancel', 'Cancel')}</Button>
                  <Box sx={{ flexGrow: 1, px: 2 }}>
                    {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                  </Box>
                  <Button
                    variant="contained"
                    onClick={onSubmit}
                    disabled={!canConfirm}
                  >
                    {t('share-documents-dialog_confirm', 'Share')}
                    {isRequestInFlight && <CircularProgress size={12} sx={{ ml: 1 }} />}
                  </Button>
                </DialogActions>
              </>
            )}
          </Translation>
        </Suspense>
      </Dialog>
      {isFeedbackDialogOpen && <ShareDocumentsFeedbackDialog onClose={onCloseFeedbackDialog} documentCount={documentVersionIds.length} />}
    </>
  );
}
