import React, { useEffect, useRef, useState } from "react";
import { VIEWER_MARGIN, VIEWER_OFFSET_X, VIEWER_OFFSET_Y } from "../../../Helpers/Common";
import { getGeneralSvg } from "../../SVGs";

export const TOOLBAR_POS = "toolbar-pos";
const OVER_COLOR = "#EBF2FF";
const BACK_COLOR = "white";

const ViewerToolbar = ({
  resetModel,
  containerRect,
  isClipping,
  openClipping,
  isMeasure,
  openMeasure,
  is2D,
  open2D,
  isViewMark,
  openViewMark
}) => {
  const [pos, setPos] = useState({ x: 0, y: window.innerHeight });
  const [isMoving, setIsMoving] = useState(false);
  const movingRef = useRef(isMoving);
  const rectRef = useRef(containerRect);

  useEffect(() => {
    const domString = localStorage.getItem(TOOLBAR_POS);
    if (domString) {
      const { x, y } = JSON.parse(domString);
      setPos({ x: x + VIEWER_OFFSET_X, y: y + VIEWER_OFFSET_Y });
    }

    document.addEventListener("mousemove", onDocMouseMove);
    document.addEventListener("mouseup", onDocMouseUp);

    return () => {
      document.removeEventListener("mousemove", onDocMouseMove);
      document.removeEventListener("mouseup", onDocMouseUp);
    };

    function onDocMouseMove(e) {
      if (movingRef.current) {
        calculatePos(e.clientX, e.clientY);
      }
    }

    function onDocMouseUp(e) {
      if (movingRef.current && e.button === 0) {
        setIsMoving(false);
      }
    }
  }, []);

  useEffect(() => {
    rectRef.current = containerRect;
    calculatePos(pos.x, pos.y);
  }, [containerRect]);

  useEffect(() => {
    movingRef.current = isMoving;
  });

  useEffect(() => {
    document.documentElement.style.cursor = isMoving ? "move" : "default";
  }, [isMoving]);

  const onMouseEnter = (e) => {
    e.target.style.background = OVER_COLOR;
  };

  const onMouseLeave = (e) => {
    let repainting = true;
    const dom = e.target;
    switch (dom.id) {
      case "clipping":
        repainting = !isClipping;
        break;
      case "measure":
        repainting = !isMeasure;
        break;
      case "2D":
        repainting = !is2D;
        break;
      case "viewpoint-mark":
        repainting = !isViewMark;
        break;
      case "reset":
        break;
    }

    if (repainting) dom.style.background = BACK_COLOR;
  };

  const onMouseDown = (e) => {
    if (e.button === 0) {
      setIsMoving(true);
    }
  };

  const onMouseUp = (e) => {
    if (isMoving && e.button === 0) {
      setIsMoving(false);
    }
  };

  const calculatePos = (posX, posY) => {
    if (rectRef.current) {
      const htmlElement = document.getElementById("viewer-toolbar");
      const width = htmlElement ? htmlElement.clientWidth : 0;
      const height = htmlElement ? htmlElement.clientHeight : 0;

      const x = Math.min(
        Math.max(posX - VIEWER_OFFSET_X, rectRef.current.left - VIEWER_OFFSET_X + VIEWER_MARGIN),
        rectRef.current.right - width - VIEWER_OFFSET_X - VIEWER_MARGIN
      );
      const y = Math.min(
        Math.max(posY - VIEWER_OFFSET_Y, rectRef.current.top - VIEWER_OFFSET_Y + VIEWER_MARGIN),
        rectRef.current.bottom - VIEWER_OFFSET_Y - height - VIEWER_MARGIN
      );

      setPos({ x, y });
      localStorage.setItem(TOOLBAR_POS, JSON.stringify({ x, y }));
    }
  };

  const handleOpen2D = () => {
    open2D(!is2D);
  };

  const handleViewMark = () => {
    openViewMark(!isViewMark);
  };

  return (
    <div
      id="viewer-toolbar"
      className="viewer-toolbar"
      style={{ left: `${pos.x}px`, top: `${pos.y}px`, background: BACK_COLOR }}
      onMouseUp={onMouseUp}
    >
      <div className="viewer-toolbar_moving" onMouseDown={onMouseDown}>
        {getGeneralSvg("moving-label")}
      </div>
      <div
        id="clipping"
        style={{ background: isClipping ? OVER_COLOR : BACK_COLOR }}
        onClick={openClipping}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {getGeneralSvg(`model-clipping${isClipping ? "-act" : ""}`)}
      </div>
      <div
        id="measure"
        className="viewer-toolbar_measure"
        style={{ background: isMeasure ? OVER_COLOR : BACK_COLOR }}
        onClick={openMeasure}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {getGeneralSvg(`model-measure${isMeasure ? "-act" : ""}`)}
      </div>
      <div
        id="2D"
        className="viewer-toolbar_2D"
        style={{
          background: is2D ? OVER_COLOR : BACK_COLOR,
          color: is2D ? "#486aff" : "#918C8C",
        }}
        onClick={handleOpen2D}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {"2D"}
      </div>
      <div
        id="viewpoint-mark"
        className="viewer-toolbar_2D"
        style={{ background: isViewMark ? OVER_COLOR : BACK_COLOR }}
        onClick={handleViewMark}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {getGeneralSvg(`model-viewpoint-mark${isViewMark ? "-act" : ""}`)}
      </div>
      <div
        id="reset"
        onClick={resetModel}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {getGeneralSvg("model-reset")}
      </div>
    </div>
  );
};

export default ViewerToolbar;
