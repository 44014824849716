import React from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import * as Common from "../../Helpers/Common";

const IssueModalButton = ({
  t,
  handleClick,
  baseIssuesUrl,
  mode,
  fromDocuments,
  handleSubmit,
  onDiscardChanges,
  modalCount,
  setModalCount,
  setIsLinkedCreatIssue,
  setCurrentIssue,
}) => {
  const navigate = useNavigate();
  const onCancel = (e) => {
    handleClick(e);
    if (setCurrentIssue) setCurrentIssue(null);
    if (baseIssuesUrl && modalCount !== 2) {
      navigate(`${baseIssuesUrl}`);
    }
    if (modalCount) {
      setModalCount(modalCount - 1);
      setIsLinkedCreatIssue(false);
    }
  };

  const onSave = (e) => {
    handleSubmit(e);
  };

  return (
    <div className="issue-modal-btn-group">
      <input
        id="CancelEditIssueButton"
        type="button"
        className="btn btn--secondary btn-cancel"
        name="btn-cancel"
        value={t("cancel", "Cancel")}
        onClick={onCancel}
      />
      {mode === Common.IssueModalMode.CREATE ? (
        <div className="issue-modal-btn-group-inner">
          <input
            id="SaveEditIssueButton"
            type="button"
            className="btn btn--primary"
            name="btn-save-issue"
            value={t("save_issue", "Save Issue")}
            onClick={onSave}
          />
        </div>
      ) : (
        <div className="issue-modal-btn-group-inner inner-edit">
          <input
            id="DiscardChangesEditIssueButton"
            type="button"
            className="btn btn--secondary"
            name="btn-discard-issue"
            value={t("discard_changes", "Discard Changes")}
            onClick={onDiscardChanges}
          />
          <input
            id="SaveEditIssueButton"
            type="button"
            className="btn btn--primary"
            name="btn-edit-issue"
            value={t("edit_issue", "Save")}
            onClick={handleSubmit}
          />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(IssueModalButton);
