import React, {
  useMemo, useState, useCallback, /*  Suspense, */
} from 'react';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { sortBy } from 'lodash';

// Components
import IssueBoardPriorityColumn from 'Components/IssueBoard/IssueBoardPriorityColumn';
import IssueBoardStatusColumn from 'Components/IssueBoard/IssueBoardStatusColumn';

import * as IssueService from 'Services/IssueService';
import IssueStatus from 'issues/types/IssueStatus';
import IssuePriority from 'issues/types/IssuePriority';
import $TSFixMe from 'common/types/FixMeAny';
import $TSFixMeFunction from 'common/types/FixMeAnyFunction';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';

interface IssuesBoardProps {
  sortByGroup: {
    value: number;
    label: string;
    name: string;
  },
  statuses: IssueStatus[],
  priorities: IssuePriority[],
  filteredStatuses: IssueStatus[],
  filteredPriorities: IssuePriority[],
  setCurrentIssue: ((issue: { id: string }) => void),
  setEditIssue: ((issue: { id: string }) => void),
  filter: $TSFixMe
  toggleAside: $TSFixMeFunction,
  baseIssuesUrl: string,

}

function IssuesBoard({
  sortByGroup,
  statuses,
  filteredStatuses,
  filteredPriorities,
  priorities,
  setCurrentIssue,
  setEditIssue,
  filter,
  toggleAside,
  baseIssuesUrl,
}: IssuesBoardProps) {
  const { data: disciplines } = useProjectDisciplinesQuery();
  const { data: floors } = useProjectFloorsQuery();
  const { data: buildings } = useProjectBuildingsQuery();
  const [updateObj, setUpdateObj] = useState<{ result: DropResult } | undefined>(undefined);
  const [dragInProgres, setDragInProgres] = useState(false);
  const navigate = useNavigate();

  const groups = useMemo(
    () => (sortByGroup.name === 'issueStatus'
      ? sortBy(statuses, 'order')
      : sortBy(priorities, 'order')),
    [sortByGroup, statuses, priorities],
  );

  const onDragStart = () => {
    setDragInProgres(true);
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result?.destination) return;

    const draggableId = result.draggableId.split('-')[0];
    await IssueService.updateIssue(draggableId, {
      [sortByGroup.name === 'issueStatus' ? 'statusId' : 'priorityId']: {
        value: result.destination.droppableId,
      },
    });

    setUpdateObj({ result });
    setDragInProgres(false);
  };

  const viewIssue = useCallback(
    async (issue) => {
      if (dragInProgres) return;
      let [fullIssue] = (await IssueService.getIssuesByIds([issue.id]));
      fullIssue = IssueService.formatIssue(fullIssue, disciplines, buildings, floors);
      [fullIssue] = (
        await IssueService.getIssuesWithLogViewpoints([fullIssue])
      );
      setCurrentIssue(fullIssue);
      setEditIssue(fullIssue);
      toggleAside('aside', true, false);
      navigate(`${baseIssuesUrl}${issue.id}`);
    },
    [navigate, dragInProgres, disciplines, buildings, floors, baseIssuesUrl, setCurrentIssue, setEditIssue, toggleAside],
  );

  return (
    <div className="issue-board custom-scrollbar">
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        {groups.map((g) => (sortByGroup.name === 'issueStatus' ? (
          <IssueBoardStatusColumn
            key={`group-${g.id}`}
            group={g}
            isVisible={filteredStatuses.find((item) => item.id === g.id)}
            viewIssue={viewIssue}
            issueFilter={filter}
            updateObj={updateObj}
            setUpdateObj={setUpdateObj}
          />
        ) : (
          <IssueBoardPriorityColumn
            key={`group-${g.id}`}
            group={g}
            isVisible={filteredPriorities.find((item) => item.id === g.id)}
            viewIssue={viewIssue}
            issueFilter={filter}
            updateObj={updateObj}
            setUpdateObj={setUpdateObj}
          />
        )))}
      </DragDropContext>
    </div>
  );
}

export default IssuesBoard;
