import buildQuery from 'odata-query';
import * as Request from '../Helpers/Request';
import {
  PDFAnnotation,
  PostPDFAnnotationDto,
} from '../Entities/RestEntities/PDFAnnotation';

export async function getAnnotationsForDocumentVersion(
  projectId: string,
  documentVersionId: string,
): Promise<PDFAnnotation[] | null> {
  const query = buildQuery({
    filter: { documentVersionId: { eq: documentVersionId } },
  });
  const result = await Request.GET(
    `api/pdf-annotation/${query}`,
  );
  return result?.status === 200 ? result.body : null;
}

export async function getAnnotationsById(
  projectId: string,
  annotationIds: [string],
): Promise<PDFAnnotation[] | null> {
  const query = buildQuery({ filter: { id: { in: annotationIds } } });
  const result = await Request.GET(
    `api/pdf-annotation/${query}`,
  );
  return result?.status === 200 ? result.body : null;
}

export async function getAnnotationsByIssueId(
  projectId: string,
  issueId: string,
): Promise<PDFAnnotation[] | null> {
  const query = buildQuery({
    filter: { linkedIssueId: { eq: issueId } },
  });
  const result = await Request.GET(
    `api/pdf-annotation/${query}`,
  );
  return result?.status === 200 ? result.body : null;
}

export async function createAnnotation(
  dto: PostPDFAnnotationDto,
): Promise<PDFAnnotation | null> {
  const result = await Request.POST('api/pdf-annotation', dto);
  return result?.status === 200 ? result.body : null;
}

export async function editAnnotation(annotation: PDFAnnotation) {
  const result = await Request.PUT('api/pdf-annotation', annotation);
  return result?.status === 200;
}

export async function deleteAnnotation(id: string) {
  const result = await Request.DELETE(`api/pdf-annotation/${id}`);
  return result?.status === 200;
}
