import { QueryObserverOptions, WithRequired } from '@tanstack/react-query';
import { useMemo } from 'react';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import useDefaultOdataQueryFn from 'api/hooks/useDefaultOdataQueryFn';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { DocumentVersionsOdataQueryKey } from 'documents/hooks/useDocumentVersionsOdataQueryKey';

export type DocumentVersionsOdataQueryQueryFnData = DocumentVersionDto[] | undefined;
export type DocumentVersionsOdataQueryOptions = WithRequired<Partial<Omit<QueryObserverOptions<DocumentVersionsOdataQueryQueryFnData, unknown, DocumentVersionsOdataQueryQueryFnData, DocumentVersionsOdataQueryQueryFnData, DocumentVersionsOdataQueryKey>, 'queryKey'>>, 'queryFn'>;

export default function useDocumentVersionsOdataQueryOptions() {
  const queryFn = useDefaultOdataQueryFn<DocumentVersionDto, DocumentVersionDto, ApiEndpoint.DocumentVersion>();
  return useMemo<DocumentVersionsOdataQueryOptions>(() => ({
    queryFn,
  }), [queryFn]);
}
