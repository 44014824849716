import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BubbleState } from "../Types/viso-types";
import {
  VIEWER_MARGIN,
  VIEWER_OFFSET_X,
  VIEWER_OFFSET_Y,
} from "../../../Helpers/Common";
import { getDisciplineColor } from "../../../Services/IssueService";
import { getGeneralSvg } from "../../SVGs";

const VISIBLE_LIMIT_COUNT = 2;

const ViewpointDetailPanel = ({
  containerRect,
  selectedComponents,
  sections,
  colorComponents,
  metaData,
  bubbleState,
  setBubbleState,
  resetBubbleState,
  createViewpoint,
  goBack,
}) => {
  const { t } = useTranslation();
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [isMoving, setIsMoving] = useState(false);
  const [disciplineColors, setDisciplineColors] = useState([]);
  const movingRef = useRef(isMoving);
  const rectRef = useRef(containerRect);

  useEffect(() => {
    document.addEventListener("mousemove", onDocMouseMove);
    document.addEventListener("mouseup", onDocMouseUp);
    setDisciplineColors(
      metaData.disciplines.map((item, index) => {
        const color = getDisciplineColor(index);
        return {
          color,
          background: `${color}33`,
        };
      })
    );

    return () => {
      document.removeEventListener("mousemove", onDocMouseMove);
      document.removeEventListener("mouseup", onDocMouseUp);

      resetBubbleState();
    };

    function onDocMouseMove(e) {
      if (movingRef.current) {
        calculatePos(e.clientX - VIEWER_OFFSET_X, e.clientY - VIEWER_OFFSET_Y);
      }
    }

    function onDocMouseUp(e) {
      if (movingRef.current && e.button === 0) {
        setIsMoving(false);
      }
    }
  }, []);

  useEffect(() => {
    movingRef.current = isMoving;
  });

  useEffect(() => {
    document.documentElement.style.cursor = isMoving ? "move" : "default";
  }, [isMoving]);

  useEffect(() => {
    rectRef.current = containerRect;
    calculatePos(pos.x, pos.y);
  }, [containerRect]);

  useEffect(() => {
    if (bubbleState === BubbleState.BUBBLE_CREATING) {
      document.addEventListener("keydown", onKeyPress);
    } else {
      document.removeEventListener("keydown", onKeyPress);
    }

    function onKeyPress(e) {
      if (e.key === "Escape") {
        resetBubbleState();
      }
    }
  }, [bubbleState]);

  const calculatePos = (posX, posY) => {
    if (rectRef.current) {
      const panelHtml = document.getElementById("viewpoint_detail-panel");
      const x = Math.min(
        Math.max(posX, rectRef.current.left - VIEWER_OFFSET_X + VIEWER_MARGIN),
        rectRef.current.right -
          panelHtml.clientWidth -
          VIEWER_OFFSET_X -
          VIEWER_MARGIN
      );

      const y = Math.min(
        Math.max(posY, rectRef.current.top - VIEWER_OFFSET_Y + VIEWER_MARGIN),
        rectRef.current.bottom -
          VIEWER_OFFSET_Y -
          panelHtml.clientHeight -
          VIEWER_MARGIN
      );

      setPos({ x, y });
    }
  };

  const onMouseDown = (e) => {
    if (e.button === 0) {
      setIsMoving(true);
    }
  };

  const onMouseUp = (e) => {
    if (isMoving && e.button === 0) {
      setIsMoving(false);
    }
  };

  return (
    <div
      id="viewpoint_detail-panel"
      className="viewpoint_detail-panel"
      style={{ left: `${pos.x}px`, top: `${pos.y}px` }}
      onMouseUp={onMouseUp}
    >
      <div className="viewpoint_detail-panel_header">
        <div className="moving" onMouseDown={onMouseDown}>
          {getGeneralSvg("moving-label")}
        </div>
        <div className="title">
          <label>{t("viewpoint_detail", "Viewpoint Detail")}</label>
          {selectedComponents.length > 0 && (
            <div className="title_elements">
              {`${selectedComponents.length} ${
                selectedComponents.length > 1
                  ? t("elements", "Elements")
                  : t("element", "Element")
              }`}
            </div>
          )}
        </div>
      </div>
      <div className="viewpoint_detail-panel_model">
        <div className="viewpoint_detail-panel_model_header">
          <label>{t("model", "Model")}</label>
          <div className="bubble-pencil">
            {getGeneralSvg("edit-pencil")}
            <div
              className="bubble-pencil_mark"
              style={{
                textDecoration: `${
                  bubbleState === BubbleState.BUBBLE_CREATING ? "underline" : ""
                }`,
              }}
              onClick={setBubbleState}
            >
              {`${
                bubbleState === BubbleState.BUBBLE_NONE
                  ? t("mark_viewpoint", "Mark Viewpoint")
                  : bubbleState === BubbleState.BUBBLE_CREATING
                  ? t("cancel_with_esc", "Cancel with ESC")
                  : t("delete_mark", "Delete Mark")
              }`}
            </div>
          </div>
        </div>
        <div className="viewpoint_detail-panel_model_metadata">
          <div className="width--30">
            <p className="metadata-header">{t("discipline", "Discipline")}</p>
            <div className="metadata-items">
              {metaData.disciplines.map(
                (discipline, index) =>
                  index < VISIBLE_LIMIT_COUNT && (
                    <div
                      key={`discipline-${index}`}
                      className="metadata-label"
                      style={{
                        backgroundColor: disciplineColors[index]?.background,
                        color: disciplineColors[index]?.color,
                      }}
                    >
                      {discipline.abbreviation || ""}
                    </div>
                  )
              )}
            </div>
            {metaData.disciplines.length > VISIBLE_LIMIT_COUNT && (
              <p className="metatdata-more-items">
                {`+${metaData.disciplines.length - VISIBLE_LIMIT_COUNT}more`}
              </p>
            )}
          </div>
          <div className="width--30">
            <p className="metadata-header">{t("building", "Building")}</p>
            <div className="metadata-items">
              {metaData.buildings.map(
                (building, index) =>
                  index < VISIBLE_LIMIT_COUNT && (
                    <div key={`building-${index}`} className="metadata-label">
                      {building.abbreviation || ""}
                    </div>
                  )
              )}
            </div>
            {metaData.buildings.length > VISIBLE_LIMIT_COUNT && (
              <p className="metatdata-more-items">
                {`+${metaData.buildings.length - VISIBLE_LIMIT_COUNT}more`}
              </p>
            )}
          </div>
          <div className="width--30">
            <p className="metadata-header">{t("floor", "Floor")}</p>
            <div className="metadata-items">
              {metaData.floors.map(
                (floor, index) =>
                  index < VISIBLE_LIMIT_COUNT && (
                    <div key={`floor-${index}`} className="metadata-label">
                      {floor.abbreviation || ""}
                    </div>
                  )
              )}
            </div>
            {metaData.floors.length > VISIBLE_LIMIT_COUNT && (
              <p className="metatdata-more-items">
                {`+${metaData.floors.length - VISIBLE_LIMIT_COUNT}more`}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="viewpoint_detail-panel_setting">
        <div className="model-info">
          <label>{t("model_settings", "Model Settings")}</label>
          {sections.length > 0 && (
            <p>
              <span
                style={{ fontWeight: "bolder" }}
              >{`${sections.length} `}</span>
              {`${
                sections.length > 1
                  ? t("sectional_planes", "Sectional Planes")
                  : t("sectional_plane", "Sectional Plane")
              }`}
            </p>
          )}
          {colorComponents.length > 0 && (
            <p>
              <span
                style={{ fontWeight: "bolder" }}
              >{`${colorComponents.length} `}</span>
              {`${
                colorComponents.length > 1
                  ? t("colored_components", "Colored Components")
                  : t("colored_component", "Colored Component")
              }`}
            </p>
          )}
        </div>
        <div className="button-area">
          <button className="btn btn--secondary" onClick={goBack}>
            {t("cancel", "Cancel")}
          </button>
          <button className="btn btn--primary" onClick={createViewpoint}>
            {t("add_viewpoint", "Add Viewpoint")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewpointDetailPanel;
