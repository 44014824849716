import { QueryObserverOptions, WithRequired } from '@tanstack/react-query';
import { useMemo } from 'react';
import LabelDto from 'labels/types/LabelDto';
import useDefaultOdataQueryFn from 'api/hooks/useDefaultOdataQueryFn';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { LabelsOdataQueryKey } from 'labels/hooks/useLabelsOdataQueryKey';

export type LabelsOdataQueryQueryFnData = LabelDto[] | undefined;
export type LabelsOdataQueryOptions = WithRequired<Partial<Omit<QueryObserverOptions<LabelsOdataQueryQueryFnData, unknown, LabelsOdataQueryQueryFnData, LabelsOdataQueryQueryFnData, LabelsOdataQueryKey>, 'queryKey'>>, 'queryFn'>;

export default function useLabelsOdataQueryOptions() {
  const queryFn = useDefaultOdataQueryFn<LabelDto, LabelDto, ApiEndpoint.Label>();
  return useMemo<LabelsOdataQueryOptions>(() => ({
    queryFn,
  }), [queryFn]);
}
