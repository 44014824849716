function partiallyUpdateCachedEntityList<T extends ({ id: string } | undefined)>(cachedEntities: T[], ids: string[], updater: (e: T) => T) {
  const updatedCachedEntities = [...cachedEntities];
  const idsSet = new Set(ids);
  cachedEntities.forEach((e, i) => {
    if (!e || !idsSet.has(e.id)) return;
    const updatedEntity = updater(e);
    if (!updatedEntity) return;
    updatedCachedEntities[i] = updatedEntity;
  });
  return updatedCachedEntities;
}

export default function useCachedEntityListPartialUpdate() {
  return partiallyUpdateCachedEntityList;
}
