import React, { useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import LinkExistingIssues from "./LinkExistingIssues";
import { Modal } from "../../HOC";

import { useLinkIssues } from "../../Helpers/Hooks";
import { IssueService } from "../../Services";

const BtnGroup = ({ t, onCancel, onSave }) => (
  <div className="issue-modal-btn-group">
    <input
      type="button"
      className="btn btn--secondary btn-cancel"
      name="btn-cancel"
      value={t("cancel", "Cancel")}
      onClick={onCancel}
    />
    <div className="issue-modal-btn-group-inner">
      <input
        type="button"
        className="btn btn--primary"
        name="btn-save-issue"
        value={`${t("save_link", "Save & Link")}`}
        onClick={onSave}
      />
    </div>
  </div>
);

const LinkEmailIssues = ({
  email,
  issueModalProps,
  setShowExistingIssueView,
  updateEmail,
  allIssues,
}) => {
  const { t } = useTranslation();
  const scrollRef = useRef();
  const [selected, setSelected] = useState([]);
  const [apiError, setApiError] = useState("");
  const {
    filteredIssues,
    loading,
    filterValue,
    searchKey,
    columns,
    isFiltering,
    handleFilterChange,
    onSearchChange,
    handleScrollIssuesTable,
    setFilteredIssues,
  } = useLinkIssues({
    isParent: true,
    issueModalProps,
    showCreateModal: false,
    selected,
    modalCount: 1,
    scrollRef,
    hideIssueIds: email?.linkedIssueIds,
    setSelected,
  });

  const onCancel = useCallback(() => {
    setShowExistingIssueView(false);
    setSelected([]);
  }, []);

  const onSave = useCallback(async () => {
    if (!selected?.length || !email) return;
    const selectedIssue = allIssues.find((issue) => issue.id === selected[0]);
    const selectedIssueLinkedEmailIds = selectedIssue?.linkedEmailIds || [];
    const params = [
      {
        id: selected[0],
        linkedEmailIds: {
          value: [email.id, ...selectedIssueLinkedEmailIds],
        },
      },
    ];
    const response = await IssueService.editIssue(params, setApiError);
    if (response) {
      setShowExistingIssueView(false);
      updateEmail();
    }
  }, [selected, email]);

  return (
    <Modal>
      <LinkExistingIssues
        BtnGroup={() =>
          BtnGroup({
            t,
            onCancel,
            onSave,
          })
        }
        loading={loading}
        searchKey={searchKey}
        modalCount={1}
        scrollRef={scrollRef}
        columns={columns}
        filterValue={filterValue}
        isFiltering={isFiltering}
        issueFilterItems={{
          statuses: issueModalProps.statuses,
          types: issueModalProps.types,
          priorities: issueModalProps.priorities,
          workphases: issueModalProps.workphases,
          users: issueModalProps.users,
          tagOptions: issueModalProps.tagOptions,
        }}
        filteredIssues={filteredIssues}
        setFilteredIssues={setFilteredIssues}
        handleFilterChange={handleFilterChange}
        handleScrollIssuesTable={handleScrollIssuesTable}
        onSearchChange={onSearchChange}
        allIssues={allIssues}
        apiError={apiError}
      />
    </Modal>
  );
};

export default LinkEmailIssues;
