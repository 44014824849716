import React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import IssuesDataGridRow from 'issues/types/IssuesDataGridRow';
import LabelChip from 'labels/components/LabelChip';
import LabelDto from 'labels/types/LabelDto';

export default function LabelChipCell({
  value,
}: ICellRendererParams<IssuesDataGridRow, LabelDto>) {
  if (!value) return null;
  return (
    <LabelChip id="LabelChipCell" sx={{ }} label={value} />
  );
}
