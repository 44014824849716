import React from "react";
import { useTranslation } from "react-i18next";
import { sprintf } from "sprintf-js";

import ProgressBar from "../../ProgressBar";
import ModalFooter from "./ModalFooter";

const UploadProgress = ({
  uploadCategory,
  uploadFileSize,
  header,
  progress,
  doneText,
  currentSetupModelIndex,
  handleButtonClick,
  onDone,
  apiError,
}) => {
  const { t } = useTranslation();
  const getApiError = (name) => {
    if (!apiError) return "";
    const error = apiError.find(
      (error) =>
        error?.errors?.length && error.errors[0].errorMessage.includes(name)
    );
    if (!error) return "";
    return error.errors[0].errorMessage;
  };

  const getTranslatedErrorMsg = (msg) => {
    if (msg && msg.includes("No any naming schemes found for file name")) {
      msg.replace(
        "No any naming schemes found for file name",
        t("no_any_naming_schemes", "No any naming schemes found for file name")
      );
      return msg;
    }
    return msg;
  };

  return (
    <>
      <div className="upload-progress-modal--header">
        <h2>
          {uploadCategory === 1
            ? sprintf(
                t("upload_models", "Upload %1$s Model(s)"),
                Object.keys(uploadFileSize).length
              )
            : header}
        </h2>
      </div>
      <div className="upload-progress-modal--body">
        <div className="upload-progress-modal--body--row upload-progress-modal--body--title">
          <div>{t("name", "Name")}</div>
          <div>{t("progress", "Progress")}</div>
        </div>
        <div className="upload-progress-modal--body_content custom-scrollbar">
          {Object.keys(progress).map((file, index) => (
            <div key={`progress-${index}`}>
              <div className="upload-progress-modal--body--row" key={file}>
                <div className="row-file-name text-ellipsis">{file}</div>
                <ProgressBar state={progress[file]} />
              </div>
              {!!getApiError(file) && (
                <div className="error-message">
                  {getTranslatedErrorMsg(getApiError(file))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <ModalFooter
        uploadCategory={uploadCategory}
        currentSetupModelIndex={currentSetupModelIndex}
        handleButtonClick={handleButtonClick}
        onDone={onDone}
        doneText={doneText}
        uploadFileSize={uploadFileSize}
      />
    </>
  );
};

export default UploadProgress;
