import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function useInitialFilterValues() {
  const { t } = useTranslation();
  const defaultSelect = useMemo(() => ({ value: null, label: t('all', 'All') }), [t]);

  const [initialFilterValues, setInitialFilterValues] = useState({
    createdDateFrom: '',
    createdDateTo: '',
    startDateTo: '',
    startDateFrom: '',
    dueDateFrom: '',
    dueDateTo: '',
    editDateFrom: '',
    editDateTo: '',
    priority: defaultSelect,
    assignedTo: defaultSelect,
    workphase: defaultSelect,
    reviewer: defaultSelect,
    status: [],
    creator: defaultSelect,
    source: '',
    type: defaultSelect,
    category: defaultSelect,
    floor: defaultSelect,
    building: defaultSelect,
    tags: [],
    visibility: defaultSelect,
    lastEditor: defaultSelect,
    document: defaultSelect,
    email: defaultSelect,
  });

  return {
    default_select: defaultSelect,
    initialFilterValues,
    setInitialFilterValues,
  };
}
