import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import axios from 'axios';
import CreateQueryFilterDto from 'documents-filter/types/CreateQueryFilterDto';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import QueryFilterDto from 'documents-filter/types/QueryFilterDto';
import useQueryFilterEntityFactory from 'documents-filter/hooks/useQueryFilterEntityFactory';
import QueryFilter from 'documents-filter/types/QueryFilter';

export default function useQueryFilterCreateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { listsByCurrentProjectQueryKey: queryKey } = useDefaultEntityQueryKeys(ApiEndpoint.QueryFilter);
  const entityFactory = useQueryFilterEntityFactory();
  return useMutation<QueryFilter | undefined, unknown, CreateQueryFilterDto>(
    async (dto: CreateQueryFilterDto) => {
      try {
        // create new entity
        const { data: responseDto } = await axiosInstance.post<QueryFilterDto>(`${ApiEndpoint.QueryFilter}`, dto);
        const entity = entityFactory(responseDto);
        // cache update
        queryClient.setQueryData<QueryFilter[] | undefined>(queryKey, ((oldData) => {
          if (!oldData) return [entity];
          return [...oldData, entity];
        }));
        return entity;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 400) return error.response.data?.errorMessages[0]?.errorMessage;
        return undefined;
      }
    },
  );
}
