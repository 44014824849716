/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useCallback, useMemo, useState,
} from 'react';
import IssueLinkDocumentsDialog from 'issues/components/IssueLinkDocumentsDialog';
import { useTranslation } from 'react-i18next';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import * as Common from 'Helpers/Common';
import DocumentSelectionContext, { DocumentSelectionContextState } from 'documents/contexts/DocumentSelectionContext';
import useDocumentsQuery from 'documents/hooks/useDocumentsQuery';

interface IssueModalColumnProps {
  editIssue: any,
  mode: any,
  stagedDocumentVersionIds: string[] | undefined,
  setStagedDocumentVersionIds: (ids: string[]) => void,
  handleDelete: any,
  mainTask: any[],
  subTasks: any[],
  canEditIssue: any,
  linkedEmails: any[],
  setIsParent: any,
  setShowLinkIssues: any,
  isAllowedToSave: any,
  isMassEdit: any,
}

export default function IssueModalColumn({
  editIssue,
  mode,
  stagedDocumentVersionIds,
  setStagedDocumentVersionIds,
  handleDelete,
  mainTask,
  subTasks,
  canEditIssue,
  linkedEmails,
  setIsParent,
  setShowLinkIssues,
  isAllowedToSave,
  isMassEdit,
}: IssueModalColumnProps) {
  const { t } = useTranslation();
  const serverDocumentVersionIdsSet = useMemo(() => new Set<string>(editIssue?.linkedDocumentVersionIds), [editIssue?.linkedDocumentVersionIds]);
  const [isIssueLinkDocumentsDialogOpen, setIsIssueLinkDocumentsDialogOpen] = useState(false);
  const [selectedDocumentVersionIds, setSelectedDocumentVersionIds] = useState<string[]>([]);
  const { data: stagedDocumentVersions } = useDocumentVersionsQuery(stagedDocumentVersionIds ? { filter: { id: { in: stagedDocumentVersionIds } } } : undefined);
  const { data: selectedDocumentVersions } = useDocumentVersionsQuery(selectedDocumentVersionIds ? { filter: { id: { in: selectedDocumentVersionIds } } } : undefined);
  const { data: linkedDocumentVersions } = useDocumentVersionsQuery(editIssue?.linkedDocumentVersionIds ? { filter: { id: { in: editIssue?.linkedDocumentVersionIds } } } : undefined);
  const nonSelectableDocumentIds = useMemo(() => {
    if (editIssue && !linkedDocumentVersions) return undefined;
    if (!selectedDocumentVersions) return undefined;
    return Array.from(new Set((linkedDocumentVersions ?? [])?.concat(selectedDocumentVersions).map((v) => v.documentId)));
  }, [editIssue, linkedDocumentVersions, selectedDocumentVersions]);
  const { data: nonSelectableDocuments } = useDocumentsQuery(nonSelectableDocumentIds);
  const nonSelectableVersionIds = useMemo(() => {
    if (!nonSelectableDocuments) return undefined;
    const versionIds = nonSelectableDocuments.flatMap((d) => d.documentVersionIds);
    const selectedDocumentVersionIdsSet = new Set(selectedDocumentVersionIds);
    return new Set<string>(versionIds.filter((id) => !selectedDocumentVersionIdsSet.has(id)));
  }, [nonSelectableDocuments, selectedDocumentVersionIds]);
  const nonDeselectableVersionIds = useMemo(() => (editIssue?.linkedDocumentVersionIds ? new Set<string>(editIssue.linkedDocumentVersionIds) : new Set<string>()), [editIssue?.linkedDocumentVersionIds]);

  const onClickAddDocumentLinks = useCallback(() => {
    setSelectedDocumentVersionIds(Array.from(new Set((stagedDocumentVersionIds ?? []).concat(editIssue?.linkedDocumentVersionIds ?? []))));
    setIsIssueLinkDocumentsDialogOpen(true);
  }, [editIssue?.linkedDocumentVersionIds, stagedDocumentVersionIds]);

  const onCloseIssueLinkDocumentsDialog = useCallback((confirmed: boolean) => {
    if (confirmed) {
      const nextStagedIds = Array.from(new Set((stagedDocumentVersionIds ?? []).concat(editIssue?.linkedDocumentVersionIds ?? []).concat(selectedDocumentVersionIds)));
      setStagedDocumentVersionIds(nextStagedIds);
    }
    setIsIssueLinkDocumentsDialogOpen(false);
  }, [editIssue?.linkedDocumentVersionIds, selectedDocumentVersionIds, setStagedDocumentVersionIds, stagedDocumentVersionIds]);

  const onClickDeleteDocumentLink = useCallback((documentVersionId: string) => {
    setStagedDocumentVersionIds(stagedDocumentVersionIds?.filter((id) => id !== documentVersionId) ?? []);
  }, [setStagedDocumentVersionIds, stagedDocumentVersionIds]);

  const resetSelection = useCallback(() => {
    setSelectedDocumentVersionIds(editIssue?.linkedDocumentVersionIds ?? []);
  }, [editIssue?.linkedDocumentVersionIds]);

  const documentSelectionContextValue = useMemo<DocumentSelectionContextState>(() => ({
    selectedDocumentVersionIds,
    setSelectedDocumentVersionIds,
    nonDeselectableVersionIds,
    nonSelectableVersionIds,
    resetSelection,
  }), [nonDeselectableVersionIds, nonSelectableVersionIds, selectedDocumentVersionIds, resetSelection]);

  return (
    <>
      {isIssueLinkDocumentsDialogOpen && (
      <DocumentSelectionContext.Provider value={documentSelectionContextValue}>
        <IssueLinkDocumentsDialog
          open
          onClose={onCloseIssueLinkDocumentsDialog}
        />
      </DocumentSelectionContext.Provider>
      )}
      <div className="issue-modal-content--column block mobile-links">
        {(!!editIssue || mode === Common.IssueModalMode.CREATE) && !isMassEdit ? (
          <>
            <div className="content-item link-documents">
              <h2 className="subtitle">{t('link_documents', 'Link Documents')}</h2>
              <div className="add-document">
                <label>{t('documents', 'Documents')}</label>
                <button
                  id="AddDocumentIssueButton"
                  type="button"
                  className="btn btn--secondary btn-add-doc"
                  onClick={onClickAddDocumentLinks}
                >
                  {selectedDocumentVersions?.length
                    ? t('edit_link_documents', 'Edit Documents')
                    : t('add_link_documents', 'Add Documents')}
                </button>
              </div>
              <div className="documents-list custom-scrollbar">
                {!!stagedDocumentVersions?.length
                  && stagedDocumentVersions.map((doc: any) => (
                    <div
                      className="content-item-count block"
                      key={'document-' + doc.id}
                    >
                      <p>{doc.name}</p>
                      {!serverDocumentVersionIdsSet.has(doc.id) ? (
                        <button
                          id="DeleteDocumentIssueButton"
                          className="btn-delete btn "
                          type="button"
                          onClick={() => onClickDeleteDocumentLink(doc.id)}
                        >
                          <svg className="icon">
                            <use xlinkHref="/img/sprite.svg#close" />
                          </svg>
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <div className="content-item link-documents link-email">
              <h2 className="subtitle">{t('link_emails', 'Link Emails')}</h2>
              <div className="add-document add-email">
                <label>{t('emails', 'Emails')}</label>
              </div>
              <div className="documents-list custom-scrollbar">
                {linkedEmails.map((email, index) => (
                  <div
                    className="content-item-count block"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`link-email-${index}`}
                  >
                    <p>{email.subject}</p>
                    <button
                      id="DeleteEmailIssueButton"
                      className="btn-delete btn "
                      type="button"
                      onClick={() => handleDelete(email.id, 'email')}
                    >
                      <svg className="icon">
                        <use xlinkHref="/img/sprite.svg#close" />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="content-item link-documents link-issues block">
              <div className={isAllowedToSave ? 'error-message' : ''}>
                <h2 className="subtitle">{t('link_issues', 'Link Issues')}</h2>
                <div className="add-document">
                  <label>{t('main_task', 'Main Task')}</label>
                  <button
                    id="AddMainTaskIssueButton"
                    type="button"
                    disabled={mainTask.length > 0 || !canEditIssue}
                    className="btn btn--secondary btn-add-doc"
                    onClick={() => {
                      setIsParent(true);
                      setShowLinkIssues(true);
                    }}
                  >
                    {`${t('add_main_task', 'Add Main Task')}`}
                  </button>
                </div>
                <div className="documents-list link-issue">
                  {mainTask.length
                    ? mainTask.map((task) => (
                      <div
                        key={'main-task-' + task.id}
                        className="content-item-count block"
                      >
                        <p>{task.title}</p>
                        <button
                          id="DeleteMainTaskIssueButton"
                          className="btn-delete btn "
                          type="button"
                          onClick={() => handleDelete(task.id, 'mainTask')}
                        >
                          <svg className="icon">
                            <use xlinkHref="/img/sprite.svg#close" />
                          </svg>
                        </button>
                      </div>
                    ))
                    : ''}
                </div>
              </div>
              <div
                className={isAllowedToSave
                  ? 'error-message'
                  : linkedEmails
                    ? 'ml-30'
                    : 'ml-50'}
              >
                {!!isAllowedToSave && (
                  <p>
                    {`*${t('allowed_to_save_task', 'Its not allowed to save a main issue as a sub issue. Please correct your selection')}`}
                  </p>
                )}
                <div className="add-document mt-35">
                  <label>{t('sub_tasks', 'Sub Tasks')}</label>
                  <button
                    id="AddSubTaskIssueButton"
                    type="button"
                    disabled={!canEditIssue}
                    className="btn btn--secondary btn-add-doc"
                    onClick={() => {
                      setShowLinkIssues(true);
                    }}
                  >
                    {`${t('add_sub_tasks', 'Add Sub Tasks')}`}
                  </button>
                </div>
                <div className="documents-list link-issue custom-scrollbar">
                  {subTasks.length
                    ? subTasks.map((task) => (
                      <div
                        className="content-item-count block"
                        key={'sub-task-' + task.id}
                      >
                        <p>{task.title}</p>
                        <button
                          id="DeleteSubTasksIssueButton"
                          className="btn-delete btn "
                          type="button"
                          onClick={() => handleDelete(task.id, 'subTasks')}
                        >
                          <svg className="icon">
                            <use xlinkHref="/img/sprite.svg#close" />
                          </svg>
                        </button>
                      </div>
                    ))
                    : ''}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="content-item link-documents link-issues block">
            <div className={isAllowedToSave ? 'error-message' : ''}>
              <h2 className="subtitle">{t('link_issues', 'Link Issues')}</h2>
              <div className="add-document">
                <label>{t('main_task', 'Main Task')}</label>
                <button
                  id="AddMainTaskIssueButton2"
                  type="button"
                  disabled={mainTask.length > 0}
                  className="btn btn--secondary btn-add-doc"
                  onClick={() => {
                    setIsParent(true);
                    setShowLinkIssues(true);
                  }}
                >
                  {`${t('add_main_task', 'Add Main Task')}`}
                </button>
              </div>
              <div className="documents-list link-issue">
                {mainTask.length
                  ? mainTask.map((task) => (
                    <div
                      key={'main-task-' + task.id}
                      className="content-item-count block"
                    >
                      <p>{task.title}</p>
                      <button
                        id="DeleteMainTaskIssueButton2"
                        className="btn-delete btn "
                        type="button"
                        onClick={() => handleDelete(task.id, 'mainTask')}
                      >
                        <svg className="icon">
                          <use xlinkHref="/img/sprite.svg#close" />
                        </svg>
                      </button>
                    </div>
                  ))
                  : ''}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
