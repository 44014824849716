import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ProjectCreateDto from 'projects/types/ProjectCreateDto';
import ProjectDto from 'projects/types/ProjectDto';
import useCurrentUserQueryKey from 'users/hooks/useCurrentUserQueryKey';

export default function useProjectCreateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { baseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Project);
  const currentUserQueryKey = useCurrentUserQueryKey();
  const mutationFn = useCallback(async (projectCreateDto: ProjectCreateDto) => {
    await axiosInstance.post<ProjectDto>(`${ApiEndpoint.Project}`, projectCreateDto);
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries(baseQueryKey);
    queryClient.invalidateQueries(currentUserQueryKey);
  }, [baseQueryKey, currentUserQueryKey, queryClient]);
  return useMutation<void, unknown, ProjectCreateDto>({
    mutationFn,
    onSuccess,
  });
}
