import { Core } from '@pdftron/webviewer';
import { useCallback, useEffect, useState } from 'react';
import {
  AnnotAction,
  AnnotActionType,
} from '../../Components/DocumentPreviewModal/types';
import { PostPDFAnnotationDto } from '../../Entities/RestEntities/PDFAnnotation';
import {
  createAnnotation,
  deleteAnnotation,
  editAnnotation,
} from '../../Services/PdfAnnotationService';
import { useAnnotationActionHandlerProps } from './useAnnotationChangedHandlerTypes';

export default function useAnnotationChangedHandler(
  {
    setAnnotationMap,
    annotationMap,
    pdfAnnotationManager,
    projectId,
    documentVersionId,
  }: useAnnotationActionHandlerProps,
) {
  const [annotAction, setAnnotAction] = useState<AnnotAction | null>(null);

  const handleAnnotationAdded = useCallback(
    (
      addedAction: AnnotAction,
      annotManager: Core.AnnotationManager,
    ) => {
      if (projectId === undefined) { throw new Error('ProjectId must be set.'); }

      if (addedAction.annotations?.length === 1) {
        const pdfTronAnnotation = addedAction.annotations[0];

        annotManager
          .exportAnnotations({ annotList: [pdfTronAnnotation] })
          .then(async (xfdfString) => {
            const postAnnotationDto: PostPDFAnnotationDto = {
              projectId,
              documentVersionId,
              xfdfString,
            };
            const result = await createAnnotation(postAnnotationDto);
            if (!result) { throw new Error('Error during Annotationcreation.'); }
            setAnnotationMap((prevState) => ({
              ...prevState,
              [pdfTronAnnotation.Id]: result,
            }));
          });
      }
    },
    [documentVersionId, projectId, setAnnotationMap],
  );

  const handleAnnotationsModified = useCallback(
    (modifyAction: AnnotAction, annotManager: Core.AnnotationManager) => {
      if (!modifyAction?.annotations || modifyAction.annotations.length < 1) { throw new Error('At least one annotation is required for modification.'); }
      const annotationsToBeModified = modifyAction.annotations;
      Promise.all(
        annotationsToBeModified.map(async (pdfTronAnnotation) => {
          const currentAnnotation = annotationMap[pdfTronAnnotation.Id];
          if (currentAnnotation === undefined) { throw new Error('Cannot modify non-existent annotation.'); }

          const newXFDFString = await annotManager.exportAnnotations({
            annotList: [pdfTronAnnotation],
          });
          return { ...currentAnnotation, xfdfString: newXFDFString };
        }),
      ).then(async (editDtos) => {
        editDtos.forEach(async (editDto) => {
          const success = await editAnnotation(editDto);
          if (!success) return;
          setAnnotationMap((prevState) => {
            const newState = { ...prevState };
            annotationsToBeModified.forEach((pdfTronAnnotation, index) => {
              newState[pdfTronAnnotation.Id] = editDtos[index];
            });
            return newState;
          });
        });
      });
    },
    [annotationMap, setAnnotationMap],
  );

  const handleAnnotationsDeleted = useCallback(
    (deleteAction: AnnotAction) => {
      if (!deleteAction?.annotations || deleteAction.annotations.length < 1) { throw new Error('At least one annotation is required for deletion.'); }
      const annotationsToBeDeleted = deleteAction.annotations.map(
        (a) => annotationMap[a.Id],
      );
      if (annotationsToBeDeleted.includes(undefined)) { throw new Error('Annotation to be deleted, doesn\'t exist.'); }
      annotationsToBeDeleted.forEach((a) => deleteAnnotation(a!.id).then(
        (succeded) => {
          if (!succeded) return;

          setAnnotationMap((prevState) => {
            const newState = { ...prevState };
            annotationsToBeDeleted.forEach((annotation) => {
              delete newState[annotation!.id];
            });
            return newState;
          });
        },
      ));
    },
    [annotationMap, setAnnotationMap],
  );

  useEffect(() => {
    if (!pdfAnnotationManager || !annotAction) return;
    setAnnotAction(null);
    switch (annotAction.action) {
      case AnnotActionType.ADD:
        handleAnnotationAdded(
          annotAction,
          pdfAnnotationManager,
        );
        break;
      case AnnotActionType.MODIFY:
        handleAnnotationsModified(annotAction, pdfAnnotationManager);
        break;
      case AnnotActionType.DELETE:
        handleAnnotationsDeleted(annotAction);
        break;
      default:
    }
  }, [pdfAnnotationManager, annotAction, handleAnnotationAdded,
    handleAnnotationsModified, handleAnnotationsDeleted]);

  return { setAnnotAction, annotAction };
}
