/**
 * @param {String} form_id
 * @param {String} error_classname
 * @return void
 */
const reset = (form_id, error_classname = "is-error") => {
  Array.prototype.slice
    .call(document.querySelectorAll("#" + form_id + " ." + error_classname))
    .map((item) => item.remove());
};

/**
 * @param {*} errors
 * @param {String} form_id
 * @param {String} error_classname
 * @param {String} error_tag
 * @return void
 */
const showErrors = (
  errors,
  form_id,
  error_classname = "input__message is-error",
  error_tag = "div"
) => {
  if (typeof errors === "string") {
    errors = { errors };
  }

  let i = 0;

  Object.keys(errors).map((key) => {
    let element = document.querySelectorAll(
      "#" + form_id + ' [name="' + key + '"]'
    );

    // If no element with such name has been found - add error to the last form input
    if (!element[0] || element[0].type === "hidden") {
      element = document.querySelectorAll(
        "#" + form_id + " input:not([type=hidden])"
      );
    }

    if (element[0]) {
      let error = document.createElement(error_tag);

      error.className = error_classname;
      error.innerText = errors[key];

      element[0].parentElement.append(error);

      i++ > 0 ||
        element[0].scrollIntoView({ block: "center", behavior: "smooth" });
    }
  });
};

export { reset, showErrors };
