import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { useCallback } from 'react';

export default function usePdfAnnotationDeleteMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { baseQueryKey: annotationsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.PdfAnnotation);
  const { baseQueryKey: issuesQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Issue);
  const { baseQueryKey: documentVersionsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const mutationFn = useCallback(async (id: string) => {
    await axiosInstance.delete<void>(`${ApiEndpoint.PdfAnnotation}/${id}`);
    queryClient.invalidateQueries({ queryKey: annotationsQueryKey });
    queryClient.invalidateQueries({ queryKey: issuesQueryKey });
    queryClient.invalidateQueries({ queryKey: documentVersionsQueryKey });
  }, [annotationsQueryKey, axiosInstance, documentVersionsQueryKey, issuesQueryKey, queryClient]);
  return useMutation<void, unknown, string>({ mutationFn });
}
