import React, { useCallback, useState } from 'react';
import {
  Alert,
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';
import AddCollaboratorDialog from 'collaborators/components/AddCollaboratorDialog';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';

interface AddCollaboratorDialogButtonProps extends ISxProps {
}

export default function AddCollaboratorDialogButton({
  sx,
}: AddCollaboratorDialogButtonProps) {
  const { t } = useTranslation('collaborators');
  const currentUserRole = useCurrentUserRole();
  const [isAddCollaboratorDialogOpen, setIsAddCollaboratorDialogOpen] = useState(false);
  const [successFeedbackDialogMessage, setSuccessFeedbackDialogMessage] = useState<string | undefined>(undefined);
  const onClose = useCallback((addedCollaboratorsCount: number | undefined) => {
    setIsAddCollaboratorDialogOpen(false);
    if (addedCollaboratorsCount) {
      setSuccessFeedbackDialogMessage(t('add-collaborator-success-feedback-dialog_message', 'You have added {{count}} collaborators to the project.', { count: addedCollaboratorsCount }));
    }
  }, [t]);
  if (!currentUserRole?.allowedActions?.has(RoleAction.Project_EditMembers)) return null;
  return (
    <>
      <Button
        id="AddCollaboratorDialogButton"
        variant="contained"
        color="primary"
        sx={{ ...sx }}
        onClick={() => setIsAddCollaboratorDialogOpen(true)}
      >
        {t('add-collaborator-dialog-button_label', 'New Collaborator')}
      </Button>
      {isAddCollaboratorDialogOpen && <AddCollaboratorDialog onClose={onClose} />}
      {!!successFeedbackDialogMessage && (
      <Dialog open>
        <DialogTitle>{t('add-collaborator-success-feedback-dialog_title', 'Success')}</DialogTitle>
        <DialogContent>
          <Alert severity="success">{successFeedbackDialogMessage}</Alert>
        </DialogContent>
        <DialogActions><Button variant="contained" color="primary" onClick={() => setSuccessFeedbackDialogMessage(undefined)}>{t('add-collaborator-success-feedback-dialog_close-button-label', 'Close')}</Button></DialogActions>
      </Dialog>
      )}
    </>
  );
}
