import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import RoleAction from 'projects/types/RoleAction';
import { useMemo } from 'react';
import SettingsTab from 'settings/types/SettingsTab';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';

export default function useVisibleSettingsTabs() {
  const currentUserRole = useCurrentUserRole();
  const { data: currentUser } = useCurrentUserQuery();
  const { data: currentProject } = useCurrentProjectQuery();
  return useMemo(() => {
    if (!currentUserRole || !currentProject || !currentUser) return undefined;
    const visibleTabs: SettingsTab[] = [];
    if (currentUser.id === currentProject.creatorId
        || currentUserRole.allowedActions?.has(RoleAction.Inbox_Edit)) {
      visibleTabs.push(SettingsTab.Project);
    }
    if (currentUserRole.isAdmin) {
      visibleTabs.push(SettingsTab.ModelMetadata);
    }
    if (currentUserRole.isAdmin) {
      visibleTabs.push(SettingsTab.Roles);
    }
    if (currentUserRole.allowedActions?.has(RoleAction.Project_CreateGroups)
        || currentUserRole.allowedActions?.has(RoleAction.Project_EditGroups)
        || currentUserRole.allowedActions?.has(RoleAction.Project_DeleteGroups)) {
      visibleTabs.push(SettingsTab.UserGroups);
    }
    if (currentUserRole.allowedActions?.has(RoleAction.Tag_Create_Update)
        || currentUserRole.allowedActions?.has(RoleAction.Tag_Delete)) {
      visibleTabs.push(SettingsTab.Tags);
    }
    if (currentUserRole.allowedActions?.has(RoleAction.NamingScheme_Create_Edit)
        || currentUserRole.allowedActions?.has(RoleAction.NamingScheme_Delete)) {
      visibleTabs.push(SettingsTab.Encoding);
    }
    if (currentUserRole.allowedActions?.has(RoleAction.DocStatus_Create_Edit)
        || currentUserRole.allowedActions?.has(RoleAction.DocStatus_Delete)
        || currentUserRole.allowedActions?.has(RoleAction.DocStamp_Edit)) {
      visibleTabs.push(SettingsTab.Documents);
    }
    if (currentUserRole.allowedActions?.has(RoleAction.IssueType_CreateEdit)
      || currentUserRole.allowedActions?.has(RoleAction.IssueType_Delete)
      || currentUserRole.allowedActions?.has(RoleAction.IssuePriority_CreateEdit)
      || currentUserRole.allowedActions?.has(RoleAction.IssuePriority_Delete)
      || currentUserRole.allowedActions?.has(RoleAction.IssueStatus_Create_Edit)
      || currentUserRole.allowedActions?.has(RoleAction.IssueStatus_Delete)) {
      visibleTabs.push(SettingsTab.Issues);
    }
    if (currentUserRole.allowedActions?.has(RoleAction.BimqIntegration)) {
      visibleTabs.push(SettingsTab.BimQ);
    }

    return new Set<SettingsTab>(visibleTabs);
  }, [currentProject, currentUser, currentUserRole]);
}
