import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import { getTypeSvg } from "../SVGs";
import IconPopup from "./IconPopup";

const TagCreationItem = ({
  item,
  errorMsg,
  index,
  selectedIconPopup,
  isEnableRemove,
  isEnableEdit,
  onChangeInput,
  onClickPicker,
  onRemoveItem,
  onNextOrder,
  onPrevOrder,
  setSelectedIconPopup,
  onChangeTypeIcon,
  ...rest
}) => {
  const colorPickerRef = useRef();
  const { t } = useTranslation();

  return (
    <div className="tags-creation-item-wrapper" ref={colorPickerRef} {...rest}>
      <div className="tags-creation-item">
        {!!onNextOrder && (
          <div className="order-group">
            <>
              <p className="order-group-index">{index}</p>
              <div>
                <div className="order order-up" onClick={onPrevOrder} />
                <div className="order order-down" onClick={onNextOrder} />
              </div>
            </>
          </div>
        )}
        {!!setSelectedIconPopup && (
          <div className="icon-group">
            <div
              className="icon-group-item-wrapper"
              onClick={() =>
                !item.isDefault &&
                setSelectedIconPopup({
                  order: item.order,
                  isDefault: item.isDefault,
                })
              }
            >
              <div
                className={`icon-group-item${
                  item.isDefault ? " cursor-initial" : ""
                }`}
                style={{ backgroundColor: `${item.color}33` }}
              >
                {!!item.icon && getTypeSvg(item.icon, item.color)}
              </div>
              <div className="arrow-down" />
            </div>
            {item.order === selectedIconPopup?.order && (
              <IconPopup
                setSelectedIconPopup={setSelectedIconPopup}
                onChangeTypeIcon={onChangeTypeIcon}
              />
            )}
          </div>
        )}
        <input
          type="text"
          className={`input tags-creation-item-input${
            errorMsg ? " error" : ""
          }`}
          value={item.name}
          onChange={onChangeInput}
          required
          disabled={!isEnableEdit}
        />
        {!!onClickPicker && (
          <div
            className={`tags-creation-item-picker${
              item.isDefault
                ? " cursor-initial"
                : !onNextOrder
                ? " picker-pos"
                : ""
            }`}
            style={{ backgroundColor: `${item.color}33` }}
            onClick={
              item.isDefault ? () => {} : () => onClickPicker(colorPickerRef)
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path
                d="M9.52986 0.44835C8.93221 -0.149331 7.96318 -0.149504 7.36512 0.448153L6.95198 0.861295C6.73005 0.639389 6.37059 0.639389 6.14868 0.861295C5.92673 1.08303 5.92673 1.44289 6.14868 1.66462L6.28253 1.79854L1.8195 6.2619H1.8191L0.269912 7.81124C0.146787 7.93436 0.0742024 8.09906 0.0663928 8.27299L0.0626858 8.35564C0.0626858 8.35603 0.0626858 8.35638 0.0626858 8.35677L0.000184356 9.75815C-0.00238589 9.82322 0.0220067 9.88629 0.0678757 9.93233C0.11115 9.97581 0.170315 10 0.231729 10C0.235065 10 0.238377 10 0.242109 9.9998L0.985107 9.96691H0.985503L1.43821 9.94677L1.64378 9.93772C1.87122 9.92754 2.08725 9.83246 2.24851 9.6714L8.20204 3.71805L8.3134 3.82938C8.42436 3.9403 8.56973 3.99578 8.71505 3.99578C8.86039 3.99578 9.00578 3.9403 9.11672 3.82938C9.33865 3.60763 9.33865 3.24777 9.11672 3.02606L9.52984 2.61289C10.1275 2.01524 10.1275 1.0462 9.52986 0.44835ZM5.23066 5.61818L3.13986 6.01261L6.55033 2.60199L7.39881 3.45008L5.23066 5.61818Z"
                fill="#002FE9"
                style={{ fill: item.color }}
              />
            </svg>
          </div>
        )}
        {!!isEnableRemove && (
          <a className="btn-close" onClick={onRemoveItem}>
            {t("X", "X")}
          </a>
        )}
      </div>
      <p className="error-label">{errorMsg}</p>
    </div>
  );
};

export default TagCreationItem;
