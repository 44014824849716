import DateRangeKind from 'common/types/DateRangeKind';
import DocumentFilterSettings from 'documents-filter/types/DocumentFilterSettings';
import DocumentFilterState from 'documents-filter/types/DocumentFilterState';
import UpdateQueryFilterDto from 'documents-filter/types/UpdateQueryFilterDto';
import CreateQueryFilterDto from 'documents-filter/types/CreateQueryFilterDto';
import QueryFilterType from 'documents-filter/types/QueryFilterType';
import FolderSearchScope from 'documents-folders/types/FolderSearchScope';
import { useMemo } from 'react';

function getFilterStateFromDocumentFilterSettings(documentFilterSettings: DocumentFilterSettings | undefined): DocumentFilterState {
  if (!documentFilterSettings) {
    return {
      disciplineMetaDataIds: [],
      buildingMetaDataIds: [],
      floorMetaDataIds: [],
      workPhaseIds: [],
      creatorCollaboratorIds: [],
      editorCollaboratorIds: [],
      fileTypes: [],
      tagIds: [],
      documentStatusIds: [],
    };
  }
  return {
    uploadDateRange: documentFilterSettings.uploadDateTimeFrame !== undefined
      ? {
        dateRangeKind: DateRangeKind.Relative,
        timeFrame: documentFilterSettings.uploadDateTimeFrame,
      }
      : documentFilterSettings.uploadFrom !== undefined || documentFilterSettings.uploadTo !== undefined
        ? {
          dateRangeKind: DateRangeKind.Custom,
          from: documentFilterSettings.uploadFromParsed,
          to: documentFilterSettings.uploadToParsed,
        }
        : undefined,
    creationDateRange: documentFilterSettings.creationDateTimeFrame !== undefined
      ? {
        dateRangeKind: DateRangeKind.Relative,
        timeFrame: documentFilterSettings.creationDateTimeFrame,
      }
      : documentFilterSettings.createdFrom !== undefined || documentFilterSettings.createdTo !== undefined
        ? {
          dateRangeKind: DateRangeKind.Custom,
          from: documentFilterSettings.createdFromParsed,
          to: documentFilterSettings.createdToParsed,
        }
        : undefined,
    disciplineMetaDataIds: documentFilterSettings.disciplineMetaDataId,
    buildingMetaDataIds: documentFilterSettings.buildingMetaDataId,
    floorMetaDataIds: documentFilterSettings.floorMetaId,
    workPhaseIds: documentFilterSettings.workPhaseId,
    creatorCollaboratorIds: documentFilterSettings.creatorId,
    editorCollaboratorIds: documentFilterSettings.editorId,
    fileTypes: documentFilterSettings.fileType,
    versionNumber: documentFilterSettings.versionNumber,
    tagIds: documentFilterSettings.tagIds,
    documentStatusIds: documentFilterSettings.documentStatusId,
    linkedIssuesScope: documentFilterSettings.linkedIssuesScope,
    folderId: documentFilterSettings.folderSearchScope !== FolderSearchScope.AllFolders ? documentFilterSettings.folderId : undefined,
    includeSubfolders: documentFilterSettings.folderSearchScope === FolderSearchScope.SelectedAndSubFolders,
    tagComparisonMode: documentFilterSettings.tagComparisonMode,
  };
}

function getUpdateDtoFromFilterState(id: string, filterState: DocumentFilterState, projectId: string, name: string, isPrivate: boolean): UpdateQueryFilterDto {
  return {
    id,
    isPrivate,
    name,
    uiFilterSettings: {
      documentFilter: {
        projectId,
        creatorId: filterState.creatorCollaboratorIds?.length ? filterState.creatorCollaboratorIds : undefined,
        editorId: filterState.editorCollaboratorIds?.length ? filterState.editorCollaboratorIds : undefined,
        disciplineMetaDataId: filterState.disciplineMetaDataIds?.length ? filterState.disciplineMetaDataIds : undefined,
        buildingMetaDataId: filterState.buildingMetaDataIds?.length ? filterState.buildingMetaDataIds : undefined,
        workPhaseId: filterState.workPhaseIds?.length ? filterState.workPhaseIds : undefined,
        versionNumber: filterState.versionNumber,
        folderId: filterState.folderId,
        tagIds: filterState.tagIds?.length ? filterState.tagIds : undefined,
        floorMetaId: filterState.floorMetaDataIds?.length ? filterState.floorMetaDataIds : undefined,
        uploadFrom: filterState.uploadDateRange?.dateRangeKind === DateRangeKind.Custom ? filterState.uploadDateRange.from?.toISOString() : undefined,
        uploadTo: filterState.uploadDateRange?.dateRangeKind === DateRangeKind.Custom ? filterState.uploadDateRange.to?.toISOString() : undefined,
        fileType: filterState.fileTypes?.length ? filterState.fileTypes : undefined,
        createdFrom: filterState.creationDateRange?.dateRangeKind === DateRangeKind.Custom ? filterState.creationDateRange.from?.toISOString() : undefined,
        createdTo: filterState.creationDateRange?.dateRangeKind === DateRangeKind.Custom ? filterState.creationDateRange.to?.toISOString() : undefined,
        documentStatusId: filterState.documentStatusIds?.length ? filterState.documentStatusIds : undefined,
        folderSearchScope: filterState.folderId ? filterState.includeSubfolders ? FolderSearchScope.SelectedAndSubFolders : FolderSearchScope.SelectedFolder : FolderSearchScope.AllFolders,
        creationDateTimeFrame: filterState.creationDateRange?.dateRangeKind === DateRangeKind.Relative ? filterState.creationDateRange?.timeFrame : undefined,
        uploadDateTimeFrame: filterState.uploadDateRange?.dateRangeKind === DateRangeKind.Relative ? filterState.uploadDateRange?.timeFrame : undefined,
        linkedIssuesScope: filterState.linkedIssuesScope,
        tagComparisonMode: filterState.tagComparisonMode,
      },
    },
  };
}

function getCreateDtoFromFilterState(filterState: DocumentFilterState, projectId: string, name: string, isPrivate: boolean): CreateQueryFilterDto {
  return {
    isPrivate,
    type: QueryFilterType.Document,
    name,
    uiFilterSettings: {
      documentFilter: {
        projectId,
        creatorId: filterState.creatorCollaboratorIds?.length ? filterState.creatorCollaboratorIds : undefined,
        editorId: filterState.editorCollaboratorIds?.length ? filterState.editorCollaboratorIds : undefined,
        disciplineMetaDataId: filterState.disciplineMetaDataIds?.length ? filterState.disciplineMetaDataIds : undefined,
        buildingMetaDataId: filterState.buildingMetaDataIds?.length ? filterState.buildingMetaDataIds : undefined,
        workPhaseId: filterState.workPhaseIds?.length ? filterState.workPhaseIds : undefined,
        versionNumber: filterState.versionNumber,
        folderId: filterState.folderId,
        tagIds: filterState.tagIds?.length ? filterState.tagIds : undefined,
        floorMetaId: filterState.floorMetaDataIds?.length ? filterState.floorMetaDataIds : undefined,
        uploadFrom: filterState.uploadDateRange?.dateRangeKind === DateRangeKind.Custom ? filterState.uploadDateRange.from?.toISOString() : undefined,
        uploadTo: filterState.uploadDateRange?.dateRangeKind === DateRangeKind.Custom ? filterState.uploadDateRange.to?.toISOString() : undefined,
        fileType: filterState.fileTypes?.length ? filterState.fileTypes : undefined,
        createdFrom: filterState.creationDateRange?.dateRangeKind === DateRangeKind.Custom ? filterState.creationDateRange.from?.toISOString() : undefined,
        createdTo: filterState.creationDateRange?.dateRangeKind === DateRangeKind.Custom ? filterState.creationDateRange.to?.toISOString() : undefined,
        documentStatusId: filterState.documentStatusIds?.length ? filterState.documentStatusIds : undefined,
        folderSearchScope: filterState.folderId ? filterState.includeSubfolders ? FolderSearchScope.SelectedAndSubFolders : FolderSearchScope.SelectedFolder : FolderSearchScope.AllFolders,
        creationDateTimeFrame: filterState.creationDateRange?.dateRangeKind === DateRangeKind.Relative ? filterState.creationDateRange?.timeFrame : undefined,
        uploadDateTimeFrame: filterState.uploadDateRange?.dateRangeKind === DateRangeKind.Relative ? filterState.uploadDateRange?.timeFrame : undefined,
        linkedIssuesScope: filterState.linkedIssuesScope,
        tagComparisonMode: filterState.tagComparisonMode,
      },
    },
  };
}

export default function useDocumentFilterConverter() {
  return useMemo(() => ({ getCreateDtoFromFilterState, getUpdateDtoFromFilterState, getFilterStateFromDocumentFilterSettings }), []);
}
