import React from 'react';
import { CircularProgress } from '@mui/material';

interface LabelLoaderProps {
  label: string;
  isLabelPrior?: boolean;
}

export default function LabelLoader({
  label,
  isLabelPrior,
}: LabelLoaderProps) {
  return (
    <div className="label-loader">
      { isLabelPrior && label }
      <CircularProgress />
      { !isLabelPrior && label }
    </div>
  );
}

LabelLoader.defaultProps = {
  isLabelPrior: true,
};
