import { RowDropZoneParams } from '@ag-grid-community/core';
import { Dispatch, SetStateAction, createContext } from 'react';

export interface DocumentDragAndDropContextState {
  folderTreeDropZoneParams: RowDropZoneParams | undefined,
  setFolderTreeDropZoneParams: Dispatch<SetStateAction<RowDropZoneParams | undefined>>,
  planlistDataGridDropZoneParams: RowDropZoneParams | undefined,
  setPlanlistDataGridDropZoneParams: Dispatch<SetStateAction<RowDropZoneParams | undefined>>,
  planlistCreateNewDropZoneParams: RowDropZoneParams | undefined,
  setPlanlistCreateNewDropZoneParams: Dispatch<SetStateAction<RowDropZoneParams | undefined>>,
  isDragOnPlanlistInProgress: boolean,
  setIsDragOnPlanlistInProgress: Dispatch<SetStateAction<boolean>>,
  draggedOverFolderId: string | undefined,
  setDraggedOverFolderId: Dispatch<SetStateAction<string | undefined>>,
  draggedOverDocumentListId: string | undefined,
  setDraggedOverDocumentListId: Dispatch<SetStateAction<string | undefined>>,
  documentVersionIdsDroppedOnCreateNewDropzone: string[],
  setDocumentVersionIdsDroppedOnCreateNewDropzone: Dispatch<SetStateAction<string[]>>,
}

const defaultValue: DocumentDragAndDropContextState = {
  folderTreeDropZoneParams: undefined,
  setFolderTreeDropZoneParams: () => {},
  planlistDataGridDropZoneParams: undefined,
  setPlanlistDataGridDropZoneParams: () => {},
  planlistCreateNewDropZoneParams: undefined,
  setPlanlistCreateNewDropZoneParams: () => {},
  isDragOnPlanlistInProgress: false,
  setIsDragOnPlanlistInProgress: () => {},
  draggedOverFolderId: undefined,
  setDraggedOverFolderId: () => {},
  draggedOverDocumentListId: undefined,
  setDraggedOverDocumentListId: () => {},
  documentVersionIdsDroppedOnCreateNewDropzone: [],
  setDocumentVersionIdsDroppedOnCreateNewDropzone: () => {},
};

const DocumentDragAndDropContext = createContext<DocumentDragAndDropContextState>(defaultValue);
export default DocumentDragAndDropContext;
