import React, { useCallback, useContext, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useDocumentVersionsDownload from 'documents/hooks/useDocumentVersionsDownload';
import DocumentSelectionContext, { DocumentSelectionContextState } from 'documents/contexts/DocumentSelectionContext';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import { t } from 'i18next';
import { mdiDownload } from '@mdi/js';
import Icon from '@mdi/react';

interface DocumentVersionsDownloadButtonProps extends ISxProps {
  onError?: (message: string) => void,
}

export default function DocumentVersionsDownloadButton({
  sx,
  onError,
}: DocumentVersionsDownloadButtonProps) {
  const { selectedDocumentVersionIds } = useContext<DocumentSelectionContextState>(DocumentSelectionContext);
  const download = useDocumentVersionsDownload(selectedDocumentVersionIds);
  const [isWaitingForDownloadToStart, setIsWaitingForDownloadToStart] = useState(false);
  const getRequestErrorMessage = useRequestErrorMessage();
  const onClickDownload = useCallback(async () => {
    setIsWaitingForDownloadToStart(true);
    try {
      await download();
    } catch (error: any) {
      const reason = getRequestErrorMessage(error);
      onError?.(t('documents-footer-drawer_download-error-message', 'Downloading documents failed. Reason: {{reason}}', { reason }));
    } finally {
      setIsWaitingForDownloadToStart(false);
    }
  }, [download, getRequestErrorMessage, onError]);

  return (
    <Button
      id="DocumentVersionsDownloadButton"
      onClick={onClickDownload}
      variant="contained"
      color="primary"
      sx={{ ...sx, pl: 1, gap: 1 }}
      disabled={isWaitingForDownloadToStart}
    >
      {!isWaitingForDownloadToStart && <Icon path={mdiDownload} size={1} />}
      {isWaitingForDownloadToStart && <CircularProgress size={12} />}
      {t('footer-drawer_download-button-label', 'Download Files')}
    </Button>
  );
}
