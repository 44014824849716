import * as Request from "../Helpers/Request";

export async function getDocumentStatus(params, headerOverrides) {
  const result = await Request.GET("api/document-status", params, undefined, undefined, headerOverrides);
  return result.status === 200 ? result.body : [];
}

export async function createDocumentStatus(params, setApiError) {
  const result = await Request.POST("api/document-status", params);
  if (setApiError && result.body?.errors?.length)
    setApiError(result.body?.errors[0].errorMessage || "");
  return result.status === 200 ? result.body : [];
}

export async function updateDocumentStatus(params, setApiError) {
  const result = await Request.PATCH("api/document-status", params);
  if (setApiError && result.body?.errors?.length)
    setApiError(result.body?.errors[0].errorMessage || "");
  return result.status === 200 ? result.body : [];
}

export async function deleteDocumentStatus(ids) {
  const result = await Request.DELETE(`api/document-status/${ids}`);
  return result.status === 200 ? result.body : false;
}

export async function updateDocumentStatusOrder(params, setApiError) {
  const result = await Request.PUT("api/document-status/order", params);
  if (setApiError && result.body?.errors?.length)
    setApiError(result.body?.errors[0].errorMessage || "");
  return result.status === 200 ? result.body : false;
}
