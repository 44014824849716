import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Button, Box } from '@mui/material';
import DocumentsBrowser from 'documents/components/DocumentsBrowser';
import DocumentFilterContextProvider from 'documents-filter/contexts/DocumentFilterContextProvider';
import DocumentScopeContextMemoryProvider from 'documents/contexts/DocumentScopeContextMemoryProvider';
import DocumentSelectionContext, { DocumentSelectionContextState } from 'documents/contexts/DocumentSelectionContext';
import DocumentUiConfigContext, { DocumentUiConfigContextState } from 'documents/contexts/DocumentUiConfigContext';
import IssueLinkDocumentsDialogHeader from 'issues/components/IssueLinkDocumentsDialogHeader';

interface IssueLinkDocumentsDialogProps {
  open: boolean,
  onClose: (confirmed: boolean) => void,
}

export default function IssueLinkDocumentsDialog({
  open,
  onClose,
}: IssueLinkDocumentsDialogProps) {
  const { t } = useTranslation('issues');
  const { selectedDocumentVersionIds, nonDeselectableVersionIds } = useContext<DocumentSelectionContextState>(DocumentSelectionContext);
  const addCount = useMemo(() => {
    const idsToAdd = selectedDocumentVersionIds.filter((id) => !nonDeselectableVersionIds?.has(id));
    return idsToAdd.length;
  }, [nonDeselectableVersionIds, selectedDocumentVersionIds]);
  const documentsUiConfig = useMemo<DocumentUiConfigContextState>(() => ({
    suppressFolderManipulation: true,
    suppressFooterDrawer: true,
  }), []);
  return (
    <Dialog
      id="IssueLinkDocumentsDialog"
      open={open}
      PaperProps={{
        sx: {
          maxHeight: 'unset', height: 'calc(100% - 96px)', maxWidth: 'unset', width: 'calc(100% - 128px)',
        },
      }}
    >
      <DocumentUiConfigContext.Provider value={documentsUiConfig}>
        <DocumentScopeContextMemoryProvider>
          <DocumentFilterContextProvider>
            <Box sx={{ height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
              <IssueLinkDocumentsDialogHeader />
              <DocumentsBrowser />
            </Box>
          </DocumentFilterContextProvider>
        </DocumentScopeContextMemoryProvider>
      </DocumentUiConfigContext.Provider>
      <Button onClick={() => onClose(false)} color="secondary" variant="contained">{t('link-documents-dialog-cancel', 'Cancel')}</Button>
      <Button onClick={() => onClose(true)} color="primary" variant="contained">{t('link-documents-dialog-confirm', 'Add {{count}} documents', { count: addCount })}</Button>
    </Dialog>
  );
}
