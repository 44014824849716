import * as Request from "../Helpers/Request";
import { uploadFiles } from "../Helpers/utils";

export async function getModels(ids) {
  const result = await Request.GET(`api/model/${ids}`);
  return result.status === 200 ? result.body : [];
}

export async function uploadModelFiles(
  file,
  projectId,
  token,
  setProgress,
  uploadHandle
) {
  const url = Request.API_URL + "api/upload/" + projectId + "/" + uploadHandle;
  return uploadFiles(file, token, url, setProgress);
}

export async function uploadModelMetaData(projectId, params) {
  const result = await Request.POST(`api/upload/${projectId}/metadata`, params);
  return result.status === 200 ? true : false;
}

export async function getModelComponentHistory(projectId, guid) {
  const result = await Request.GET(`api/node/history/${guid}`);
  return result.status === 200 ? result.body : null;
}

export async function getPropertyGroups(ids) {
  const result = await Request.GET(`api/propertyGroup/${ids}`);
  return result.status === 200 ? result.body : null;
}
