import { useState, useMemo, useEffect } from 'react';
import { uniq } from 'lodash';

import { DocumentVersionOld } from 'Entities/RestEntities/DocumentVersion';
import { convertVersionNumber2Letter } from 'Helpers/utils';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';

export interface UsePreviewVersionsProps {
  allDocs: DocumentVersionOld[],
  previewDoc: DocumentVersionOld
}

export interface PreviewVersions {
  label: string;
  value: number;
}

const usePreviewVersions = ({
  allDocs,
  previewDoc,
}: UsePreviewVersionsProps) => {
  const { data: currentProject } = useCurrentProjectQuery();
  const isUseLetterVersion = useMemo(
    () => currentProject?.namingSchemeSettings?.useLetterVersioning,
    [currentProject?.namingSchemeSettings?.useLetterVersioning],
  );
  const [previewVersions, setPreviewVersions] = useState<PreviewVersions[]>([]);

  useEffect(() => {
    if (!previewDoc) return;
    const versions = uniq(
      allDocs.filter((doc) => doc?.documentId === previewDoc.documentId),
    )
      .map((item) => ({
        label: isUseLetterVersion
          ? convertVersionNumber2Letter(item.versionNumber)
          : `${item.versionNumber}`,
        value: item.versionNumber,
      }))
      .sort((a, b) => a.value - b.value);
    setPreviewVersions(versions);
  }, [allDocs, previewDoc, isUseLetterVersion]);

  return previewVersions;
};

export default usePreviewVersions;
