import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";

import useInitialFilterValues from 'issues/hooks/useInitialFilterValues';
import { FilterService } from "Services";

export default function useIssueFilters(props) {
  const { user, statuses } = props;
  const { default_select, initialFilterValues, setInitialFilterValues } = useInitialFilterValues();
  const { projectId } = useParams();
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const [filters, setFilters] = useState([]);
  const [newFilters, setNewFilters] = useState({});
  const [currentFilter, setCurrentFilter] = useState({});
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [graphQLFilter, setGraphQLFilter] = useState(null);

  const updateFilterValues = (key, value) => {
    setFilterValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const resetFilterValues = () => {
    setFilterValues({
      ...initialFilterValues,
      status: statuses.filter((item) => item.originalName !== "Closed"),
    });
  };

  useEffect(() => {
    const status = statuses.filter((item) => item.originalName !== "Closed");
    setInitialFilterValues((prev) => ({
      ...prev,
      status,
    }));
    setFilterValues((prev) => ({
      ...prev,
      status,
    }));
  }, [statuses]);

  const getFilterQuery = (data = {}, searchKey = "") => {
    const statuses =
      data.status === undefined ? filterValues.status : data.status;
    const payload = {
      priorityId: filterValues.priority?.id,
      assignedUserIds: {
        any: filterValues.assignedTo?.value,
      },
      reviewerId: filterValues.reviewer?.value,
      createAuthorId: filterValues.creator?.value,
      source: filterValues.source
        ? { "tolower()": { contains: filterValues.source.toLowerCase() } }
        : null,
      typeId: filterValues.type?.id,
      workPhaseId: (data.workphase || filterValues.workphase)?.value,
      disciplineMetaDataIds: {
        any: filterValues.category?.value,
      },
      floorMetaDataIds: {
        any: filterValues.floor?.value,
      },
      buildingMetaDataIds: {
        any: filterValues.building?.value,
      },
      tagIds: filterValues.tags?.length
        ? {
            any: {
              "": {
                in: (filterValues.tags || []).map((tag) => tag.value),
              },
            },
          }
        : null,
      creationDate: {},
      dueDate: {},
      editDate: {},
      startingDate: {},
      visibility: filterValues.visibility?.value,
      editAuthorId: filterValues.lastEditor?.value,
      linkedDocumentVersionIds: filterValues.document?.value,
      linkedEmailIds: filterValues.email?.value,
    };
    if (statuses?.length) {
      payload.statusId = {
        and: {
          or: (statuses || []).map((status) => ({
            "": status.id,
          })),
        },
      };
    } else {
      payload.statusId = {
        "": null,
      };
    }
    const creationDateFrom = data.createdDateFrom || filterValues.createdDateFrom
    if (creationDateFrom) payload.creationDate.ge = creationDateFrom;
    
    const creationDateTo = data.createdDateTo || filterValues.createdDateTo;
    if (creationDateTo) payload.creationDate.le = creationDateTo;
    
    const dueDateFrom = data.dueDateFrom || filterValues.dueDateFrom;
    if (dueDateFrom) payload.dueDate.ge = dueDateFrom;
    
    const dueDateTo = data.dueDateTo || filterValues.dueDateTo;
    if (dueDateTo) payload.dueDate.le = dueDateTo;
    
    const startDateFrom = data.startDateFrom || filterValues.startDateFrom;
    if (startDateFrom) payload.startingDate.ge = startDateFrom;
    
    const startDateTo = data.startDateTo || filterValues.startDateTo;
    if (startDateTo) payload.startingDate.le = startDateTo;

    const editDateFrom = data.editDateFrom || filterValues.editDateFrom;
    if (editDateFrom) payload.editDate.ge = editDateFrom;

    const editDateTo = data.editDateTo || filterValues.editDateTo;
    if (editDateTo) payload.editDate.le = editDateTo;

    const key = Object.prototype.hasOwnProperty.call(data, "search_key")
      ? data.search_key
      : searchKey;
    if (key) {
      payload.and = {
        or: [
          { "tolower(title)": { contains: key.toLowerCase() } },
          { "tolower(description)": { contains: key.toLowerCase() } },
          { issueNumber: { eq: parseInt(key) } },
        ],
      };
    }
    Object.keys(payload).forEach((key) => {
      if (!payload[key]) {
        delete payload[key];
      }
    });

    return payload;
  };
  const getFilters = async () => {
    const filterList = await FilterService.getProjectFilters(projectId);
    const filtredFilterList = filterList
      .filter((filter) => filter.type === FilterService.filterTypes.issue)
      .filter((filter) => {
        if (filter.isPrivate) {
          return user.id === filter.creatorId;
        } else {
          return filter;
        }
      })
      .map((filter) => {
        return {
          ...filter,
          value: filter.id,
          label: filter.name,
        };
      });

    setFilters([...filtredFilterList]);
  };

  const handleSaveFilter = async (isUpdate = false) => {
    const tagIds = filterValues.tags.map((item) => item.value);
    const statusValues = filterValues.status.map((item) => item.value);
    const issueFilter = {
      creationDateAfter: filterValues.createdDateFrom || null,
      creationDateBefore: filterValues.createdDateTo || null,
      startDateAfter: filterValues.startDateFrom || null,
      startDateBefore: filterValues.startDateTo || null,
      dueDateAfter: filterValues.dueDateFrom || null,
      dueDateBefore: filterValues.dueDateTo || null,
      statusIds: [...statusValues],
      priorityId: filterValues.priority?.value,
      reviewerId: filterValues.reviewer?.value,
      assignedUserIds: [filterValues.assignedTo?.value],
      createAuthorId: filterValues.creator?.value,
      source: filterValues.source,
      type: filterValues.type?.value,
      tagIds: [...tagIds],
      disciplineMetaDataIds: [filterValues.category?.value],
      buildingMetaDataIds: [filterValues.building?.value],
      floorMetaDataIds: [filterValues.floor?.value],
      workPhaseId: filterValues.workphase?.value,
      visibility: filterValues.visibility?.value,
      editAuthor: filterValues.lastEditor?.value,
      editDateAfter: filterValues.editDateFrom || null,
      editDateBefore: filterValues.editDateTo || null,
      linkedDocumentVersionIds: filterValues.document?.value,
      linkedEmailIds: filterValues.email?.value,
    };

    const params = {
      type: FilterService.filterTypes.issue,
      uiFilterSettings: { issueFilter: { ...issueFilter } },
    };

    if (isUpdate) {
      const response = await FilterService.updateFilter({
        id: currentFilter.id,
        name: currentFilter.name,
        isPrivate: currentFilter.isPrivate,
        ...params,
      });
      response && getFilters();
    } else {
      const response = await FilterService.createNewFilter({
        name: newFilters.name,
        isPrivate: newFilters.isPrivate,
        ...params,
      });
      if (!response.errorMessage) {
        setShowFilterModal(false);
        getFilters();
      }
    }
    setNewFilters({});
  };

  const handeleDeleteFilter = async (filterId) => {
    await FilterService.deleteFilter(filterId);
    getFilters();
    setCurrentFilter({});
  };

  const updateGraphQLFilter = useCallback(
    (searchKey, ignoreStatus, ignorePriority, filter) => {
      const andList = [];

      if (searchKey?.length) {
        const orList = [
          { title: { search: searchKey } },
          { description: { search: searchKey } },
        ];
        const issueNum = parseInt(searchKey);
        if (!isNaN(issueNum)) orList.push({ issueNumber: { eq: issueNum } });
        andList.push({ or: orList });
      }

      if (!ignoreStatus && filter.status?.length)
        andList.push({ statusId: { in: filter.status.map((s) => s.id) } });

      if (!ignorePriority && !!filter.priority?.value)
        andList.push({ priorityId: { eq: filter.priority.value } });

      if (filter?.createdDateFrom)
        andList.push({ creationDate: { gte: filter.createdDateFrom } });

      if (filter?.createdDateTo)
        andList.push({ creationDate: { lte: filter.createdDateTo } });

      if (filter?.dueDateFrom)
        andList.push({ dueDate: { gte: filter.dueDateFrom } });

      if (filter?.dueDateTo)
        andList.push({ dueDate: { lte: filter.dueDateTo } });

      if (filter?.startDateFrom)
        andList.push({ startingDate: { gte: filter.startDateFrom } });

      if (filter?.startDateTo)
        andList.push({ startingDate: { lte: filter.startDateTo } });

      if (filter?.editDateFrom)
        andList.push({ editDate: { gte: filter.editDateFrom } });

      if (filter?.editDateTo)
        andList.push({ editDate: { gte: filter.editDateTo } });

      if (filter?.assignedTo?.value)
        andList.push({
          assignedUserIds: { some: { eq: filter.assignedTo.value } },
        });

      if (filter.reviewer?.value)
        andList.push({ reviewerId: { eq: filter.reviewer.value } });

      if (filter.creator?.value)
        andList.push({ createAuthorId: { eq: filter.creator.value } });

      if (filter.source) andList.push({ source: { contains: filter.source } });

      if (filter.category?.value)
        andList.push({
          disciplineMetaDataIds: { some: { eq: filter.category.value } },
        });

      if (filter.building?.value)
        andList.push({
          buildingMetaDataIds: { some: { eq: filter.building.value } },
        });

      if (filter.floor?.value)
        andList.push({
          floorMetaDataIds: { some: { eq: filter.floor.value } },
        });

      if (filter.workphase?.value)
        andList.push({ workPhaseId: { eq: filter.workphase.value } });

      if (filter.tags?.length) {
        andList.push({
          tagIds: { some: { in: filter.tags.map((s) => s.value) } },
        });
      }

      if (filter.type?.value) {
        andList.push({ typeId: { eq: filter.type.value } });
      }

      if (filter.editAuthor?.value)
        andList.push({ editAuthorId: { eq: filter.editAuthor.value } });

      if (andList.length === 0) setGraphQLFilter(null);
      else setGraphQLFilter({ and: andList });
    },
    [filterValues, initialFilterValues]
  );

  return {
    default_select,
    initialFilterValues,
    filterValues,
    filters,
    newFilters,
    currentFilter,
    showFilterModal,
    graphQLFilter,
    setFilterValues,
    setFilters,
    setNewFilters,
    setCurrentFilter,
    getFilters,
    handleSaveFilter,
    handeleDeleteFilter,
    updateFilterValues,
    setShowFilterModal,
    getFilterQuery,
    resetFilterValues,
    updateGraphQLFilter,
  };
}
