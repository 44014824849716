import React, { useEffect, useState, useMemo } from "react";
import { sortBy } from "lodash";

import ModelVersionSelect from "./ModelVersionSelect";

import { getGeneralSvg } from "../SVGs";
import { getDisciplineColor } from "../../Services/IssueService";
import { useTranslation } from "react-i18next";

const ModelFileItem = (props) => {
  const { t } = useTranslation();
  const {
    disciplineId,
    buildingId,
    floor,
    files,
    selectedModelMetaDatas,
    handleSelectFileVersion,
    isParentSelected,
    selectModelList,
  } = props;

  const [modelFiles, setModelFiles] = useState({
    modelFileOptions: [],
    selectedFile: undefined,
  });

  useEffect(() => {
    const fileOptions = sortBy(files, "version").map((f) => ({
      ...f,
      value: f.id,
      label: t("date_only", {
        val: new Date(f.creationDate || ""),
        defaultValue: "{{val, datetime}}",
        formatParams: {
          val: {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          },
        },
      }),
    }));
    const selectedFile = fileOptions[fileOptions.length - 1];
    setModelFiles({
      modelFileOptions: fileOptions,
      selectedFile,
    });
    handleSelectFileVersion(
      selectedFile.disciplineMetaDataId,
      selectedFile.buildingMetaDataId,
      selectedFile.floorMetaDataId,
      selectedFile.version
    );
  }, [files]);

  const isSelected = useMemo(() => {
    return (
      isParentSelected ||
      !!selectedModelMetaDatas.floor.find(
        (item) =>
          item.discipline === disciplineId &&
          item.building === buildingId &&
          item.floor === floor.id
      )
    );
  }, [floor, disciplineId, buildingId, selectedModelMetaDatas]);

  const handleChangeVersion = (selectedFile) => {
    setModelFiles({ ...modelFiles, selectedFile });
    handleSelectFileVersion(
      selectedFile.disciplineMetaDataId,
      selectedFile.buildingMetaDataId,
      selectedFile.floorMetaDataId,
      selectedFile.version
    );
  };

  return (
    <div
      className={`models-content-list_items_item_model-subitem_select-item ${
        isSelected ? "selected" : ""
      }`}
    >
      <div className="file-title">
        <button
          id="PlusSelectModel"
          className={`btn ${isSelected ? "btn--primary" : "btn--secondary"}`}
          onClick={() =>
            selectModelList("floor", {
              discipline: disciplineId,
              building: buildingId,
              floor: floor.id,
            })
          }
        >
          {`${isSelected ? "-" : "+"}`}
        </button>
        <div
          id="TitleSelectModel"
          className="metadata-group cursor-pointer"
          onClick={() =>
            selectModelList("floor", {
              discipline: disciplineId,
              building: buildingId,
              floor: floor.id,
            })
          }
        >
          <div className="abbreviation-label text-ellipsis">{floor?.label || ""}</div>
          <div
            className="metadata-name text-ellipsis"
            style={{
              width: "220px",
              maxWidth: "220px",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            title={modelFiles.selectedFile?.name}
          >
            {modelFiles.selectedFile?.name}
          </div>
        </div>
      </div>
      <ModelVersionSelect
        id="SelectVersionModelDropdown"
        options={modelFiles.modelFileOptions}
        value={modelFiles.selectedFile}
        onChange={handleChangeVersion}
      />
    </div>
  );
};

const ModelSubItem = (props) => {
  const {
    building,
    floors,
    disciplineId,
    modelFiles,
    selectedModelMetaDatas,
    collapsedModelMetaDatas,
    handleSelectFileVersion,
    isParentSelected,
    selectModelList,
    collapseModelList,
  } = props;

  const [filteredModels, setFilteredModels] = useState([]);

  const isCollapse = useMemo(
    () =>
      isParentSelected ||
      !!collapsedModelMetaDatas.building.find(
        (item) =>
          item.discipline === disciplineId && item.building === building.id
      ),
    [building, disciplineId, collapsedModelMetaDatas]
  );

  const isSelected = useMemo(
    () =>
      isParentSelected ||
      !!selectedModelMetaDatas.building.find(
        (item) =>
          item.discipline === disciplineId && item.building === building.id
      ),
    [building, disciplineId, selectedModelMetaDatas]
  );

  useEffect(() => {
    const modelInfos = [];
    floors.forEach((floor) => {
      let files = modelFiles.filter(
        (m) =>
          m.disciplineMetaDataId === disciplineId &&
          m.buildingMetaDataId === building.id &&
          m.floorMetaDataId === floor.id
      );

      if (files.length) {
        files = files.sort((a, b) => {
          return b.version - a.version;
        });
        const model = {};
        model.floor = floor;
        model.files = files;

        modelInfos.push(model);
      }
    });

    setFilteredModels(modelInfos);
  }, [disciplineId, building, floors, modelFiles]);

  return (
    <div className="models-content-list_items_item_model-subitem">
      <div className="building-header">
        <button
          id="PlusSelectEntireBuildingModel"
          className={`btn ${isSelected ? "btn--primary" : "btn--secondary"}`}
          onClick={() =>
            selectModelList("building", {
              discipline: disciplineId,
              building: building.id,
            })
          }
        >
          {`${isSelected ? "-" : "+"}`}
        </button>
        <div
          id="PlusSelectBuildingModel"
          className="collase-icon"
          onClick={() =>
            collapseModelList("building", {
              discipline: disciplineId,
              building: building.id,
            })
          }
        >
          {isCollapse
            ? getGeneralSvg("collapsed-icon")
            : getGeneralSvg("opened-icon")}
        </div>
        <div
          id="NameBuildingSelectModel"
          className="metadata-group cursor-pointer"
          onClick={() =>
            selectModelList("building", {
              discipline: disciplineId,
              building: building.id,
            })
          }
        >
          <div className="metadata-name text-ellipsis">{building.name}</div>
        </div>
      </div>
      <div className={isCollapse ? "collapse-item" : ""}>
        {filteredModels.map((model, index) => (
          <ModelFileItem
            key={`model-select-item_${index}`}
            disciplineId={disciplineId}
            buildingId={building.id}
            floor={model.floor}
            files={model.files}
            selectedModelMetaDatas={selectedModelMetaDatas}
            handleSelectFileVersion={handleSelectFileVersion}
            isParentSelected={isSelected}
            selectModelList={selectModelList}
          />
        ))}
      </div>
    </div>
  );
};

const ModelItem = (props) => {
  const {
    index,
    discipline,
    modelFiles,
    buildings,
    floors,
    selectedModelMetaDatas,
    collapsedModelMetaDatas,
    handleSelectFileVersion,
    selectModelList,
    collapseModelList,
  } = props;
  const isCollapse = useMemo(() => {
    return !!collapsedModelMetaDatas.discipline.find(
      (item) => item.discipline === discipline.id
    );
  }, [discipline, collapsedModelMetaDatas]);

  const isSelected = useMemo(() => {
    return !!selectedModelMetaDatas.discipline.find(
      (item) => item.discipline === discipline.id
    );
  }, [discipline, selectedModelMetaDatas]);

  return (
    <div className="models-content-list_items_item">
      <div className="discipline-header">
        <button
          id="PlusSelectDisciplineModel"
          className={`btn ${isSelected ? "btn--primary" : "btn--secondary"}`}
          onClick={() =>
            selectModelList("discipline", { discipline: discipline.id })
          }
        >
          {`${isSelected ? "-" : "+"}`}
        </button>
        <div
          className="collase-icon cursor-pointer"
          onClick={() =>
            collapseModelList("discipline", { discipline: discipline.id })
          }
        >
          {isCollapse
            ? getGeneralSvg("collapsed-icon")
            : getGeneralSvg("opened-icon")}
        </div>
        <div
          className="metadata-group cursor-pointer"
          onClick={() =>
            selectModelList("discipline", { discipline: discipline.id })
          }
        >
          <div
            id="NameDisciplineSelectModel"
            className="abbreviation-label text-ellipsis"
            style={{
              backgroundColor: `${getDisciplineColor(index)}33`,
              color: getDisciplineColor(index),
            }}
          >
            {discipline.label}
          </div>
          <div className="metadata-name text-ellipsis">{discipline.name}</div>
        </div>
      </div>
      <div className={isCollapse ? "collapse-item" : ""}>
        {buildings.map((building, index) => (
          <ModelSubItem
            key={`model-sub-item ${index}`}
            building={building}
            floors={floors}
            selectedModelMetaDatas={selectedModelMetaDatas}
            collapsedModelMetaDatas={collapsedModelMetaDatas}
            modelFiles={modelFiles}
            disciplineId={discipline.id}
            handleSelectFileVersion={handleSelectFileVersion}
            isParentSelected={isSelected}
            selectModelList={selectModelList}
            collapseModelList={collapseModelList}
          />
        ))}
      </div>
    </div>
  );
};

export default ModelItem;
