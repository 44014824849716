import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';

import OverviewItem from 'Components/Dashboard/OverviewItem';
import { GetOverviewIssues } from 'Components/Dashboard/__generated__/GetOverviewIssues';

import LabelDto from 'labels/types/LabelDto';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';

export const GET_OVERVIEW_ISSUES = gql`
query GetOverviewIssues {
  groupIssuesByStatus {
    id
    count          
  }
  groupIssuesByPriority {
    id
    count
  }
  groupIssuesByType {
    id
    count
  }
}
`;

export interface OverviewIssuesProps {
  priorities: LabelDto[],
  statuses: LabelDto[],
  types: LabelDto[],
}

function OverviewIssues({
  priorities,
  statuses,
  types,
}: OverviewIssuesProps) {
  const { t } = useTranslation();
  const { graphQlClient } = useVisoplanApiContext();
  const [selectedState] = useState({
    value: 0,
    label: t('overview', 'Overview'),
  });

  const { data, error, isFetching } = useQuery(['issue', 'graphql', 'GetOverviewIssues'], () => graphQlClient.request<GetOverviewIssues>(GET_OVERVIEW_ISSUES));

  const chartOptions = useMemo(
    () => ({
      plugins: {
        legend: {
          display: false,
        },
      },
      cutout: '80%',
    }),
    [],
  );

  if (isFetching) return <p>Loading...</p>;
  if (error) return <p>{`Error : ${error}`}</p>;

  return (
    <div className="content">
      <div className="dashboard__content__title">
        <h2>{t('overview_issues', 'Overview Issues')}</h2>
      </div>
      <div className="dashboard__content__chart">
        <OverviewItem
          items={statuses}
          selectedState={selectedState}
          chartOptions={chartOptions}
          data={data?.groupIssuesByStatus ?? []}
          header={t('overview', 'Overview')}
        />
        <OverviewItem
          items={priorities}
          selectedState={selectedState}
          chartOptions={chartOptions}
          data={data?.groupIssuesByPriority ?? []}
          header={t('priorities', 'Priorities')}
        />
        <OverviewItem
          items={types}
          selectedState={selectedState}
          chartOptions={chartOptions}
          data={data?.groupIssuesByType ?? []}
          header={t('types', 'Types')}
        />
      </div>
    </div>
  );
}

export default OverviewIssues;
