import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  IconButton, Link, TableCell, TableRow, styled, useTheme,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InlineTypography from 'common/components/InlineTypography';
import PrecheckResult from 'upload/types/PrecheckResult';
import NamingSchemeResult from 'upload/types/NamingSchemeResult';

const EllipsisCell = styled(TableCell)(() => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: 0,
}));

interface ValidateTableRowProps {
  precheckResult: PrecheckResult,
  isFolder?: boolean,
  onDiscard?: () => void,
}

export default function ValidateTableRow({
  precheckResult,
  isFolder,
  onDiscard,
}: ValidateTableRowProps) {
  const { t, i18n } = useTranslation('upload');
  const theme = useTheme();
  const [isPrecheckSubtableExpanded, setIsPrecheckSubtableExpanded] = useState<boolean>();

  const capitalizeFirstLetter = useCallback((value: string) => value.substring(0, 1).toLocaleUpperCase(i18n.language) + value.substring(1), [i18n.language]);
  const getSuccessMessage = useCallback((values: { namingSchemeName?: string, fileName: string, versionNumber: number }) => (
    <>
      {values.namingSchemeName ? `${values.namingSchemeName}: ` : ''}
      <Trans
        t={t}
        i18nKey="upload-wizard_validate-success"
        components={{ v: <InlineTypography sx={{ fontWeight: 700 }} /> }}
        defaults="<v>V.{{versionNumber}}</v> of {{fileName}}"
        values={values}
      />
    </>
  ), [t]);

  const precheckResultMessage = useMemo(() => {
    if (precheckResult.success) {
      const namingSchemeDisplayName = precheckResult.predictedNamingScheme ? capitalizeFirstLetter(t(`upload-wizard_naming-scheme-name_${precheckResult.predictedNamingScheme}`, precheckResult.predictedNamingScheme)) : undefined;
      return getSuccessMessage({ namingSchemeName: namingSchemeDisplayName, fileName: precheckResult.fileName, versionNumber: precheckResult.predictedVersion });
    }
    return t('upload-wizard_validate-error-type_NoNamingScheme', 'Does not correspond to any encoding settings.');
  }, [t, getSuccessMessage, capitalizeFirstLetter, precheckResult]);

  const getNamingSchemeResultMessage = useCallback((namingSchemeResult: NamingSchemeResult) => {
    if (namingSchemeResult.success) {
      const namingSchemeDisplayName = capitalizeFirstLetter(t(`upload-wizard_naming-scheme-name_${namingSchemeResult.namingSchemeName}`, namingSchemeResult.namingSchemeName));
      return getSuccessMessage({ namingSchemeName: namingSchemeDisplayName, fileName: precheckResult.fileName, versionNumber: namingSchemeResult.predictedVersion });
    }
    if (namingSchemeResult.errorType === 'NamingSchemaValidationFailed') {
      return t(
        `upload-wizard_validate-error-type_${namingSchemeResult.errorType}`,
        `{{group}}: ${namingSchemeResult.errorType}`,
        { group: namingSchemeResult.failedGroupName, namingSchema: t(`upload-wizard_naming-scheme-name_${namingSchemeResult.namingSchemeName}`) },
      );
    }
    return `${capitalizeFirstLetter(t(`upload-wizard_naming-scheme-name_${namingSchemeResult.namingSchemeName}`, namingSchemeResult.namingSchemeName))}: ${t(`upload-wizard_validate-error-type_${namingSchemeResult.errorType}`, `Validation error (${namingSchemeResult.errorType})`)}`;
  }, [capitalizeFirstLetter, t, getSuccessMessage, precheckResult]);

  return (
    <>
      <TableRow>
        <EllipsisCell component="th" scope="row" sx={{ lineHeight: '1.2', pr: 1 }} title={precheckResult.fileName}>
          { !!precheckResult.namingSchemeResults?.length && (
          <IconButton
            onClick={() => setIsPrecheckSubtableExpanded((isExpanded) => !isExpanded)}
            size="small"
            sx={{ p: 0 }}
          >
            { isPrecheckSubtableExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
          </IconButton>
          )}
          {precheckResult.fileName}
        </EllipsisCell>
        <EllipsisCell sx={{ color: (!precheckResult?.success || isFolder) ? theme.palette.error.main : undefined }}>
          {!!isFolder && t('upload-wizard_folder-item-invalid-payload-error-message', 'Error! Folder upload is not supported.')}
          {!isFolder && precheckResultMessage}
        </EllipsisCell>
        <TableCell align="right" sx={{ pr: 1 }}>
          {!!onDiscard && <Link onClick={onDiscard}>{t('upload-wizard_validate-discard-upload', 'Discard Upload')}</Link>}
        </TableCell>
      </TableRow>
      { isPrecheckSubtableExpanded && precheckResult.namingSchemeResults?.length
      && precheckResult.namingSchemeResults.map((namingSchemeResult) => (
        <TableRow key={`${precheckResult.fileName}_${namingSchemeResult.namingSchemeName}`}>
          <TableCell sx={{ pl: 3, pr: 1, lineHeight: '1.2' }}>
            {namingSchemeResult.errorLength
              ? (
                <>
                  { precheckResult.fileName.slice(0, namingSchemeResult.errorPosition) }
                  <InlineTypography sx={{ color: theme.palette.error.main, fontWeight: 700 }}>
                    { precheckResult.fileName.slice(namingSchemeResult.errorPosition, namingSchemeResult.errorPosition + namingSchemeResult.errorLength) }
                  </InlineTypography>
                  { precheckResult.fileName.slice(namingSchemeResult.errorPosition + namingSchemeResult.errorLength, precheckResult.fileName.length) }
                </>
              )
              : precheckResult.fileName}
          </TableCell>
          <TableCell
            colSpan={2}
            sx={{ color: !namingSchemeResult?.success ? theme.palette.error.main : undefined }}
          >
            {getNamingSchemeResultMessage(namingSchemeResult)}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}

ValidateTableRow.defaultProps = {
  onDiscard: undefined,
};
