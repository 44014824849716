import { useQueryClient } from '@tanstack/react-query';
import IssueDto from 'issues/types/IssueDto';
import useIssuesOdataQueryKey from 'issues/hooks/useIssuesOdataQueryKey';
import { QueryOptions } from 'odata-query';
import { useCallback } from 'react';

export default function useIssuesOdataQueryData() {
  const queryClient = useQueryClient();
  const { getQueryKey } = useIssuesOdataQueryKey();
  return useCallback(async (odataQuery: Partial<QueryOptions<IssueDto>>) => {
    const queryKey = getQueryKey(odataQuery);
    return await queryClient.fetchQuery<IssueDto[]>({ queryKey });
  }, [getQueryKey, queryClient]);
}
