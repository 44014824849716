import React from 'react';
import { useTranslation } from 'react-i18next';

interface FilterActiveProps {
  isFiltering: boolean;
  onClick: () => void;
}

function FilterActive({
  isFiltering,
  onClick,
}: FilterActiveProps) {
  const { t } = useTranslation();
  if (!isFiltering) return null;

  return (
    <button
      id="FilterActiveButton"
      type="button"
      className="active_filter_label"
      onClick={onClick}
    >
      {`${t('filter_active', 'Filter Active')} X`}
    </button>
  );
}

export default FilterActive;
