import React, {
  Dispatch,
  SetStateAction,
  useCallback, useContext,
} from 'react';
import RichTextField from 'rich-text/components/RichTextField';
import { useTranslation } from 'react-i18next';
import RichTextEditorContextProvider from 'rich-text/contexts/RichTextEditorContextProvider';
import { Box, Button } from '@mui/material';
import axios from 'axios';
import useDocumentCommentCreateMutation from 'documents/hooks/useDocumentCommentCreateMutation';
import CreateDocumentCommentDto from 'documents/types/CreateDocumentCommentDto';
import RichTextEditorContext, { RichTextEditorContextState } from 'rich-text/contexts/RichTextEditorContext';
import ISxProps from 'common/types/ISxProps';
import useDocumentScopeContext from 'documents/hooks/useDocumentScopeContext';

interface DocumentCommentCreationPanelProps extends ISxProps {
  suppressSubmit?: boolean,
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>,
}

function DocumentCommentCreationPanel({
  sx,
  suppressSubmit,
  setErrorMessage,
}: DocumentCommentCreationPanelProps) {
  const { t } = useTranslation('documents');
  const { editor, text, setIsDisabled } = useContext<RichTextEditorContextState>(RichTextEditorContext);
  const { documentVersionId } = useDocumentScopeContext();
  const { mutateAsync: createComment, isLoading } = useDocumentCommentCreateMutation();

  const onClickSendComment = useCallback(async () => {
    if (!editor || !documentVersionId) return;
    setErrorMessage(undefined);
    setIsDisabled(true);
    const dto: CreateDocumentCommentDto = {
      documentVersionId,
      text,
    };
    try {
      await createComment(dto);
      editor.commands.clearContent();
      setIsDisabled(false);
    } catch (error: any) {
      setIsDisabled(false);
      setErrorMessage(axios.isAxiosError(error) && error.response?.data?.message
        ? error.response?.data?.message
        : error?.message ?? t('document-comment-drawer_send-unknown-error-message', 'Sending the comment failed due to an unknown problem.'));
    }
  }, [createComment, documentVersionId, editor, setErrorMessage, setIsDisabled, t, text]);

  return (
    <Box sx={{ ...sx, display: 'flex', flexDirection: 'column' }}>
      <RichTextField
        sx={{ flexGrow: 1, flexBasis: 0, minHeight: 0 }}
        label={t('document-comments_create-textfield-label', 'Leave a comment')}
      />
      <Button fullWidth variant="contained" color="primary" onClick={onClickSendComment} disabled={isLoading || suppressSubmit || text.trim().length === 0} sx={{ mt: 1 }}>
        {t('document-comments-drawer_send-comment', 'Send')}
      </Button>
    </Box>
  );
}

export default function DocumentCommentCreationPanelWrapper(props: DocumentCommentCreationPanelProps) {
  return (
    <RichTextEditorContextProvider>
      <DocumentCommentCreationPanel {...props} />
    </RichTextEditorContextProvider>
  );
}
