import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';

export default function useProjectDeleteMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { baseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Project);
  const { baseQueryKey: usersBaseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.User);
  return useMutation<void, unknown, string>(async (id: string) => {
    await axiosInstance.delete<boolean>(`${ApiEndpoint.Project}/${id}`);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(baseQueryKey);
      queryClient.invalidateQueries(usersBaseQueryKey);
    },
  });
}
