import React from 'react';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';
import classNames from 'classnames';

import { Modal } from 'HOC';
import AnnotationCell from 'documents/components/AnnotationCell';
import IssueStatusItem from 'Components/IssueStatusItem';

import * as DateHelper from 'Helpers/Date';
import { DocumentService } from 'Services';
import { isAllowImagePreview, isAllowPDFPreview } from 'Helpers/utils';
import { useAnnotation } from 'Helpers/Hooks';
import DocumentOld from 'documents/types/DocumentOld';
import { DocumentLog } from 'documents/types/DocumentLog';
import useDocumentScopeContext from 'documents/hooks/useDocumentScopeContext';

interface DocumentLogDialogProps {
  logs: DocumentLog[];
  issue: any;
  statuses: any[];
  isDocumentPreview: boolean;
  setIsDocLog: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
}

export default function DocumentLogDialog({
  logs,
  issue,
  statuses,
  onClose,
  setIsDocLog,
  isDocumentPreview,
}: DocumentLogDialogProps) {
  const { t } = useTranslation();
  const { pdfAnnotations } = useAnnotation({ issueId: issue.id });

  const isAllowPreview = (doc: DocumentOld, log?: any) => (
    (isAllowImagePreview(doc) || isAllowPDFPreview(doc)) && (!log || (log && !log.oldValue && !log.document?.isArchived))
  );

  const { setDocumentVersionAndIssueId } = useDocumentScopeContext();
  const selectDocument = (doc: DocumentOld) => {
    const allowPreview = isAllowPreview(doc);
    if (allowPreview) {
      setDocumentVersionAndIssueId(doc.id, issue.id);
    }
  };

  const openPreview = (log: any) => {
    if (isDocumentPreview || !isAllowPreview(log.document, log)) return;
    selectDocument(log.document);
    if (setIsDocLog) setIsDocLog(true);
  };

  const getNameCell = (index: number) => {
    const log = logs[index];
    const { originalFileName } = log.document;

    if (index > 0 && logs[index - 1].document?.originalFileName === originalFileName) return '';
    return originalFileName;
  };

  const getVersionCell = (index: number) => {
    const log = logs[index];
    const { versionNumber } = log.document;

    if (!versionNumber || (index > 0 && logs[index - 1].document?.versionNumber === versionNumber)) return '';
    return `V ${versionNumber}`;
  };

  const getStatusCell = (log: any) => (
    statuses.find((item) => (
      item.value === log.statusId
      || item.value === log.newId
      || item.label === log.newValue
    ))
  );

  const checkAllowDownload = (index: number) => {
    const log = logs[index];

    const { versionNumber, hasFolderAccess } = log.document;
    return versionNumber && (index === 0
      || (logs[index - 1].document?.versionNumber !== versionNumber && hasFolderAccess));
  };

  return (
    // @ts-ignore
    <Modal>
      <div className="documents-history--wrapper">
        <div className="documents-history--header">
          <span>{logs[0].document?.name || ''}</span>
          <svg className="icon mobile--hide" onClick={onClose}>
            <use xlinkHref="/img/sprite.svg#close" />
          </svg>
        </div>
        <div className="documents-history--issue-name mobile--hide">
          {issue.title}
          <button
            type="button"
            className="btn btn--primary btn--sm btn-download-all"
            onClick={() => {
              DocumentService.downloadDocument(
                uniq(logs.map((l) => l.documentVersionId)),
                logs[0].document?.originalFileName || logs[0].document?.name,
              );
            }}
          >
            {t('download', 'Download')}
          </button>
        </div>
        <div className="documents-history--table custom-scrollbar">
          <div className="documents-history--table--row documents-history--table--header">
            <div className="documents-history--table--cell documents-history--table--cell--name">
              {t('document_name', 'Document name')}
            </div>
            <div className="documents-history--table--cell documents-history--table--cell--version">
              {t('version', 'Version')}
            </div>
            <div className="documents-history--table--cell documents-history--table--cell--date-changed">
              {t('date_changed', 'Change Date')}
            </div>
            <div className="documents-history--table--cell documents-history--table--cell--status">
              {t('status', 'Status')}
            </div>
            <div className="documents-history--table--cell documents-history--table--cell--annotations">
              {t('annotations', 'Annotations')}
            </div>
            <div className="documents-history--table--cell documents-history--table--cell--download">
              {t('download', 'Download')}
            </div>
          </div>
          <div className="documents-history--table--body">
            {logs.map((log, index) => (
              <div key={log.documentVersionId} className="documents-history--table--row">
                {isAllowPreview(log.document, log) && (
                  <svg className={`icon ${isDocumentPreview ? 'hide' : ''}`}>
                    <use xlinkHref="/img/sprite.svg#preview-eye" />
                  </svg>
                )}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <a
                  className={classNames(
                    'documents-history--table--cell documents-history--table--cell--name',
                    isAllowPreview(log.document, log) && 'allow',
                  )}
                  role="button"
                  tabIndex={0}
                  onClick={() => openPreview(log)}
                >
                  { getNameCell(index) }
                </a>
                <div className="documents-history--table--cell documents-history--table--cell--version">
                  { getVersionCell(index) }
                </div>
                <div className="documents-history--table--cell documents-history--table--cell--date-changed mobile--hide">
                  {DateHelper.formatDate(new Date(log.creationDate))}
                </div>
                <div className="documents-history--table--cell documents-history--table--cell--status">
                  <IssueStatusItem
                    status={getStatusCell(log)}
                    name={undefined}
                    onClick={undefined}
                  />
                </div>
                <AnnotationCell
                  pdfAnnotations={pdfAnnotations}
                  pdfAnnotationIds={log.document?.pdfAnnotationIds}
                />
                <div
                  className="documents-history--table--cell documents-history--table--cell--download"
                >
                  {checkAllowDownload(index) && (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <a
                      className="btn-download"
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        DocumentService.downloadDocument([log.documentVersionId], log.document?.name);
                      }}
                    >
                      <svg className="icon">
                        <use xlinkHref="/img/sprite.svg#arrow-download" />
                      </svg>
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}
