import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Euler, MathUtils, Quaternion, Vector3 } from "three";
import { VIEWER_MARGIN, VIEWER_OFFSET_X, VIEWER_OFFSET_Y } from "../../../Helpers/Common";
import CustomSelect from "../../CustomSelect";
import { getGeneralSvg } from "../../SVGs";
import { TOOLBAR_POS } from "./ViewerToolbar";

const _euler = new Euler();
const _vector = new Vector3();
const _quaternion = new Quaternion();

const ClippingPanel = ({
  containerRect,
  closePanel,
  section,
  sections,
  addSection,
  resetSections,
  changeSection,
  removeSection,
}) => {
  const { t } = useTranslation();
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [xEuler, setXEuler] = useState(0);
  const [yEuler, setYEuler] = useState(0);
  const [zEuler, setZEuler] = useState(0);
  const [isMoving, setIsMoving] = useState(false);
  const movingRef = useRef(isMoving);
  const rectRef = useRef(containerRect);

  const sectionViews = [
    { value: 0, label: t("back_view", "Back") },
    { value: 1, label: t("left_view", "Left") },
    { value: 2, label: t("front_view", "Front") },
    { value: 3, label: t("right_view", "Right") },
    { value: 4, label: t("top_down_view", "Top Down") },
    { value: 5, label: t("bottom_up_view", "Bottom Up") },
  ];
  const [startView, setStartView] = useState(sectionViews[0]);

  useEffect(() => {
    document.addEventListener("mousemove", onDocMouseMove);
    document.addEventListener("mouseup", onDocMouseUp);

    return () => {
      document.removeEventListener("mousemove", onDocMouseMove);
      document.removeEventListener("mouseup", onDocMouseUp);
    };

    function onDocMouseMove(e) {
      if (movingRef.current) {
        calculatePos(e.clientX - VIEWER_OFFSET_X, e.clientY - VIEWER_OFFSET_Y);
      }
    }

    function onDocMouseUp(e) {
      if (movingRef.current && e.button === 0) {
        setIsMoving(false);
      }
    }
  }, []);

  useEffect(() => {
    movingRef.current = isMoving;
  });

  useEffect(() => {
    rectRef.current = containerRect;

    if (pos.x === 0 && pos.y === 0) {
      const domString = localStorage.getItem(TOOLBAR_POS);
      if (domString) {
        const toolbarHtml = document.getElementById("viewer-toolbar");
        const toolbarPos = JSON.parse(domString);
        calculatePos(
          toolbarPos.x + toolbarHtml.clientWidth + VIEWER_MARGIN,
          toolbarPos.y
        );
      }
    } else {
      calculatePos(pos.x, pos.y);
    }
  }, [containerRect]);

  useEffect(() => {
    document.documentElement.style.cursor = isMoving ? "move" : "default";
  }, [isMoving]);

  useEffect(() => {
    if (section) {
      calculateEuler();
      const plane = section.data;
      plane.controls.addEventListener("change", () => {
        calculateEuler();
      });
    } else {
      setXEuler(0);
      setYEuler(0);
      setZEuler(0);
    }
  }, [section]);

  const calculateEuler = () => {
    const plane = section.data;
    _euler.setFromQuaternion(plane.helper.quaternion);
    _vector.set(_euler.x, _euler.y, _euler.z).multiplyScalar(MathUtils.RAD2DEG);

    setXEuler(_vector.x);
    setYEuler(_vector.y);
    setZEuler(_vector.z);
  };

  const calculatePos = (posX, posY) => {
    if (rectRef.current) {
      const htmlElement = document.getElementById("clipping-panel");
      const width = htmlElement ? htmlElement.clientWidth : 0;
      const height = htmlElement ? htmlElement.clientHeight : 0;
      const x = Math.min(
        Math.max(posX, rectRef.current.left - VIEWER_OFFSET_X + VIEWER_MARGIN),
        rectRef.current.right - width - VIEWER_OFFSET_X - VIEWER_MARGIN
      );
      const y = Math.min(
        Math.max(posY, rectRef.current.top - VIEWER_OFFSET_Y + VIEWER_MARGIN),
        rectRef.current.bottom - VIEWER_OFFSET_Y - height - VIEWER_MARGIN
      );

      setPos({ x, y });
    }
  };

  const onMouseDown = (e) => {
    if (e.button === 0) {
      setIsMoving(true);
    }
  };

  const onMouseUp = (e) => {
    if (isMoving && e.button === 0) {
      setIsMoving(false);
    }
  };

  const handleChangeView = (view) => {
    setStartView(view);
  };

  const handleAddSection = () => {
    addSection(startView.value);
  };

  const handleChangeEuler = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      switch (e.target.id) {
        case "x":
          controlSection(value % 360, yEuler, zEuler);
          break;
        case "y":
          controlSection(xEuler, value % 360, zEuler);
          break;
        case "z":
          controlSection(xEuler, yEuler, value % 360);
          break;
        default:
          break;
      }
    }
  };

  const controlSection = (x, y, z) => {
    if (section) {
      _vector.set(x, y, z).multiplyScalar(MathUtils.DEG2RAD);
      _euler.setFromVector3(_vector);
      _quaternion.setFromEuler(_euler);
      section.data.updatePlane(_quaternion);
    }
  };

  return (
    <div
      id="clipping-panel"
      className="clipping-panel"
      style={{ left: `${pos.x}px`, top: `${pos.y}px` }}
      onMouseUp={onMouseUp}
    >
      <div
        className="clipping-panel_header"
        style={{ borderBottom: "1px solid #E4E3E3" }}
      >
        <div className="moving" onMouseDown={onMouseDown}>
          {getGeneralSvg("moving-label")}
        </div>
        <div className="section-manager">
          <div style={{ maxWidth: "120px" }}>
            <p>{t("start_point", "Start Point")}</p>
            <CustomSelect
              values={sectionViews}
              value={startView}
              onChange={handleChangeView}
              isModel={true}
            />
          </div>
          <button className="btn btn--primary" onClick={handleAddSection}>
            {t("add_sectional_plane", "Add Sectional Plane")}
          </button>
          <div style={{ cursor: "pointer" }} onClick={closePanel}>
            {getGeneralSvg("clipping-close")}
          </div>
        </div>
      </div>
      <div
        className="clipping-panel_middle"
        style={{ visibility: sections.length ? "visible" : "hidden" }}
      >
        <div style={{ maxWidth: "120px" }}>
          <p>{t("sectional_plane", "Sectional Plane")}</p>
          <CustomSelect
            values={sections}
            value={section}
            onChange={changeSection}
            isModel={true}
          />
        </div>
        <div className="orientation">
          <div>
            <label>X</label>
            <input
              id="x"
              type={"number"}
              value={xEuler}
              min={-180}
              max={180}
              onChange={handleChangeEuler}
            />
          </div>
          <div>
            <label>Y</label>
            <input
              id="y"
              type={"number"}
              value={yEuler}
              min={-90}
              max={90}
              onChange={handleChangeEuler}
            />
          </div>
          <div>
            <label>Z</label>
            <input
              id="z"
              type={"number"}
              value={zEuler}
              min={-180}
              max={180}
              onChange={handleChangeEuler}
            />
          </div>
        </div>
      </div>
      <div
        className="clipping-panel_bottom"
        style={{ borderTop: "1px solid #E4E3E3" }}
      >
        <label onClick={removeSection}>
          {t("remove_selection", "Remove Selection")}
        </label>
        <label onClick={resetSections}>{t("reset_all", "Reset All")}</label>
      </div>
    </div>
  );
};

export default ClippingPanel;
