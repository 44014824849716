import React from 'react';
import Item from 'Components/Dashboard/types/Item';

export interface OverviewCountLabelProps {
  item: Item,
  count: number,
  color: string
}

function OverviewCountLabel({ item, count, color }: OverviewCountLabelProps) {
  return (
    <div className="d-flex align-items-center mt-3" key={item.id}>
      <span
        className="issue-roundel"
        style={{
          backgroundColor: color,
        }}
      />
      {' '}
      <div className="ml-2 issue-title" key="title">
        {count}
        {' '}
        {item.name}
      </div>
    </div>
  );
}
export default OverviewCountLabel;
