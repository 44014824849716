import React, { useMemo } from 'react';
import { MenuItem, Select } from '@mui/material';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import ISxProps from 'common/types/ISxProps';

interface CollaboratorRoleSelectProps extends ISxProps {
  value: string | undefined,
  onChange: (nextValue: string) => void,
  disabled?: boolean,
}

export default function CollaboratorRoleEditSelect({
  sx,
  value,
  onChange,
  disabled,
}: CollaboratorRoleSelectProps) {
  const { data: currentProject, isLoading } = useCurrentProjectQuery();
  const roles = useMemo(() => currentProject?.collaboratorRoleDefinitions.filter((r) => !r.isExternal), [currentProject]);
  return (
    <Select
      id="CollaboratorRoleEditSelect"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled || isLoading}
      variant="standard"
      sx={sx}
    >
      {roles?.map((r) => <MenuItem value={r.id} key={r.id}>{r.name}</MenuItem>)}
    </Select>
  );
}
