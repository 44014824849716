import useDefaultOdataEndpointFetchFn from 'api/hooks/useDefaultOdataEndpointFetchFn';
import { OdataEndpointOptions } from 'api/hooks/useDefaultOdataEndpointQuery';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { QueryOptions } from 'odata-query';
import { useCallback } from 'react';

export default function useDefaultOdataEndpointQueryFn<
  TDto,
  TQueryDto = TDto,
  TCachedEntity extends TDto = TDto,
  >(
  endpoint: ApiEndpoint,
  odataQuery: Partial<QueryOptions<TQueryDto>> | undefined,
  entityFactory?: ((dto: TDto) => Readonly<TCachedEntity>) | undefined,
  endpointOptions?: OdataEndpointOptions,
) {
  const fetchFn = useDefaultOdataEndpointFetchFn<TDto, TQueryDto, TCachedEntity>(endpoint, entityFactory, endpointOptions);
  return useCallback(async () => (odataQuery ? fetchFn(odataQuery) : undefined), [fetchFn, odataQuery]);
}
