import { useMemo } from 'react';
import useDefaultEntityQueryKeys, { VisoOdataQueryKey } from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';

export type DocumentVersionsOdataQueryKey = VisoOdataQueryKey<ApiEndpoint.DocumentVersion, DocumentVersionDto>;

export default function useDocumentVersionsOdataQueryKey() {
  const {
    odataQueryQueryKey: queryKeyBase,
    getOdataQueryQueryKey: getQueryKey,
  } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  return useMemo(() => ({
    queryKeyBase,
    getQueryKey: getQueryKey<DocumentVersionDto>,
  }), [getQueryKey, queryKeyBase]);
}
