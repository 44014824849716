import React, { useCallback, useContext, useState } from 'react';
import {
  Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BottomUtilityDrawer from 'documents/components/BottomUtilityDrawer';
import DocumentsActionOptionsButtonMenu from 'documents/components/DocumentsActionOptionsButtonMenu';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import RoleAction from 'projects/types/RoleAction';
import useDocumentVersionArchiveOperationMutation from 'documents/hooks/useDocumentVersionArchiveOperationMutation';
import DocumentScopeContext, { DocumentScopeContextState } from 'documents/contexts/DocumentScopeContext';
import ArchiveOperation from 'documents/types/ArchiveOperation';
import DocumentSelectionContext, { DocumentSelectionContextState } from 'documents/contexts/DocumentSelectionContext';
import { useTranslation } from 'react-i18next';
import RoundIconButton from 'common/components/RoundIconButton';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import DocumentVersionsCsvExportButton from 'documents/components/DocumentVersionsCsvExportButton';
import DocumentVersionsDownloadButton from 'documents/components/DocumentVersionsDownloadButton';

interface DocumentsFooterDrawerProps {
  isOpen : boolean,
  onClickMassEdit: () => void,
}

export default function DocumentsFooterDrawer({
  isOpen,
  onClickMassEdit,
}: DocumentsFooterDrawerProps) {
  const { t } = useTranslation('documents');
  const currentUserRole = useCurrentUserRole();
  const { mutateAsync: mutateRestore, isLoading: isLoadingArchiveOperation } = useDocumentVersionArchiveOperationMutation(ArchiveOperation.Restore);
  const { documentScope } = useContext<DocumentScopeContextState>(DocumentScopeContext);
  const { selectedDocumentVersionIds, resetSelection } = useContext<DocumentSelectionContextState>(DocumentSelectionContext);
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const onDownloadError = useCallback((message: string) => setErrorMessage(message), []);

  const onClickRestore = useCallback(async () => {
    try {
      await mutateRestore(selectedDocumentVersionIds);
      setSuccessMessage(t('documents-footer-drawer_restore-success-message', 'Successfully restored {{count}} documents.', { count: selectedDocumentVersionIds.length }));
      resetSelection();
    } catch (error) {
      const reason = getRequestErrorMessage(error) ?? 'Unknown';
      setErrorMessage(t('documents-footer-drawer_restore-error-message', 'Restoring documents failed. Reason: {{reason}}', { reason }));
    }
  }, [getRequestErrorMessage, mutateRestore, resetSelection, selectedDocumentVersionIds, t]);

  return (
    <>
      <BottomUtilityDrawer
        isOpen={isOpen}
        openHeight={61}
      >
        {isOpen && (
        <Box sx={{
          display: 'flex', alignItems: 'center', px: 3, height: '100%', gap: 1,
        }}
        >
          <Typography sx={{ flexShrink: 0 }}>
            {t('footer-drawer_subheader', '{{count}} documents selected', { count: selectedDocumentVersionIds.length })}
          </Typography>
          <RoundIconButton Icon={CloseIcon} onClick={resetSelection} sx={{ ml: 1, mr: 2 }} />
          {documentScope.key !== DocumentScopeKey.Archive && (
            <>
              {!!currentUserRole?.allowedActions?.has(RoleAction.Document_Download) && (
                <DocumentVersionsDownloadButton sx={{ ml: 2, flexShrink: 0 }} onError={onDownloadError} />
              )}
              <DocumentVersionsCsvExportButton sx={{ flexShrink: 0 }} />
              {!!currentUserRole?.allowedActions?.has(RoleAction.Document_Editing) && (
              <Button
                onClick={onClickMassEdit}
                variant="contained"
                color="secondary"
                sx={{ flexShrink: 0 }}
              >
                {t('footer-drawer_edit-button-label', 'Edit')}
              </Button>
              )}
              <DocumentsActionOptionsButtonMenu sx={{ ml: 'auto', flexShrink: 0 }} />
            </>
          )}
          {documentScope.key === DocumentScopeKey.Archive && !!currentUserRole?.allowedActions?.has(RoleAction.Document_RestoreArchived) && (
            <Button onClick={onClickRestore} disabled={isLoadingArchiveOperation}>Restore</Button>
          )}
          {!!errorMessage && (
          <Alert
            severity="error"
            sx={{ maxHeight: '45px', ml: 2, overflowY: 'auto' }}
            onClose={() => setErrorMessage(undefined)}
          >
            {errorMessage}
          </Alert>
          )}
        </Box>
        )}
      </BottomUtilityDrawer>
      {!!successMessage && (
      <Dialog open>
        <DialogTitle>{t('footer-drawer_success-dialog-title', 'Success')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="success">{successMessage}</Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setSuccessMessage(undefined)}>{t('footer-drawer_success-dialog-close-button-label', 'Close')}</Button>
        </DialogActions>
      </Dialog>
      )}
    </>
  );
}
