import DocumentScope from 'documents/types/DocumentScope';
import React from 'react';

export interface DocumentScopeContextState {
  documentScope: DocumentScope,
  setDocumentScope: (value: DocumentScope) => void,
  documentVersionId: string | undefined,
  setDocumentVersionId: (id: string | undefined) => void,
  issueId: string | undefined,
  setIssueId: (id: string | undefined) => void,
  setDocumentVersionAndIssueId: (nextDocumentVersionId: string | undefined, nextIssueId: string | undefined) => void,
  highlightedDocumentCommentId: string | undefined,
  setHighlightedDocumentCommentId: (nextDocumentCommentId: string | undefined) => void,
}

const defaultValue: DocumentScopeContextState = {
  documentScope: undefined!,
  setDocumentScope: () => {},
  documentVersionId: undefined,
  setDocumentVersionId: () => {},
  issueId: undefined,
  setIssueId: () => {},
  setDocumentVersionAndIssueId: () => {},
  highlightedDocumentCommentId: undefined,
  setHighlightedDocumentCommentId: () => {},
};

const DocumentScopeContext = React.createContext(defaultValue);

export default DocumentScopeContext;
