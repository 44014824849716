import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ReactTimeAgo from "react-time-ago";

import IssueVisibility from "../IssueVisibility";
import { getGeneralSvg, getTypeSvg } from "../SVGs";

// new one is called IssueDetailHeader
const IssueBoardColumnHeaderOld = ({
  issue,
  priorities,
  types,
  workphases,
  isIssueDetail,
  isModelIssues,
}) => {
  const { t, i18n } = useTranslation();
  const issuePriority = useMemo(
    () => priorities.find((item) => item.id === issue?.issuePriority?.id),
    [priorities, issue?.issuePriority]
  );
  const issueType = useMemo(
    () => types.find((item) => item.id === issue?.issueType?.id),
    [types, issue?.issueType]
  );
  const issueWorkPhase = useMemo(
    () => workphases?.find((item) => item.id === issue?.workPhaseId),
    [workphases, issue?.workPhaseId]
  );

  return (
    <div className="issues-overview-modal--header">
      <div className="issues-overview-modal--header_top">
        <div
          className="issues-overview-modal--header_top-type"
          style={{
            background: `${issueType?.color}33`,
            color: issueType?.color,
          }}
        >
          {getTypeSvg(issueType?.icon, issueType?.color)}
          <p>{issueType?.label || ""}</p>
        </div>
        <div className="issues-overview-modal--header_top-date">
          <IssueVisibility
            visibility={issue?.visibility}
            isShowOnlyIcon={true}
          />
          <div
            className={`label label-starting-date ${
              !issue?.startingDate ? "bg-transparent" : "bg-light-grey"
            }`}
          >
            {!!issue?.startingDate && (
              <>
                {`${t("start", "Start")} `}
                <span>
                  {t("intlDateTime", {
                    formatParams: { val: { month: "short", day: "numeric" } },
                    defaultValue: "{{val, datetime}}",
                    val: new Date(issue?.startingDate),
                  })}
                </span>
              </>
            )}
          </div>
          <div
            className={`label label-due-date ${
              !issue?.dueDate
                ? "bg-transparent"
                : issue.issueStatus?.originalName !== 'Resolved' && issue.issueStatus?.originalName !== 'Closed' && new Date(issue.dueDate).getTime() <= new Date().getTime()
                ? "bg-red"
                : "bg-light-grey"
            }`}
          >
            {!!issue?.dueDate && (
              <>
                {`${t("due", "Due")} `}
                <span>
                  {t("intlDateTime", {
                    formatParams: { val: { month: "short", day: "numeric" } },
                    defaultValue: "{{val, datetime}}",
                    val: new Date(issue?.dueDate),
                  })}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      <h2 className="issue-title">
        <span className="issue-number">{issue.issueNumber}</span>
        {issue?.title || ""}
      </h2>
      <div className="issues-overview-modal--header_bottom">
        <div className="issues-overview-modal--header_bottom-left">
          {!!issueWorkPhase && (
            <p className="issue-workphase">
              <span>{`${t("workphase", "Workphase")} `}</span>
              {issueWorkPhase?.label || ""}
            </p>
          )}
          {!!issuePriority && (
            <p className="issue-workphase">
              <span>{`${t("priority", "Priority")} `}</span>
              {issuePriority?.label || ""}
            </p>
          )}
        </div>
        <div className="issues-overview-modal--header_bottom-right">
          {!isIssueDetail ? (
            <>
              <div className="issue-creator">
                {getGeneralSvg("clock")}
                <p className="issue-creator_label">
                  {`${t("created_by", "Created by")} ${
                    issue.createAuthor?.firstName || ""
                  } ${issue.createAuthor?.lastName || ""} - ${moment(
                    new Date(issue.creationDate)
                  ).format("DD.MM.YYYY")}`}
                </p>
              </div>
              {!!issue.editDate && (
                <div className="issue-creator">
                  {getGeneralSvg("clock")}
                  <p className="issue-creator_label">
                    {`${t("last_edited_by", "Last Edited by")} ${
                      issue.editAuthor?.firstName || ""
                    } ${issue.editAuthor?.lastName || ""} - ${moment(
                      new Date(issue.editDate)
                    ).format("DD.MM.YYYY")}`}
                  </p>
                </div>
              )}
            </>
          ) : !isModelIssues ? (
            <div className="issue-creator">
              {!!issue.editDate && (
                <>
                  {getGeneralSvg("clock")}
                  <span>{`${t("update", "Updated")}`}</span>
                  <ReactTimeAgo
                    className="issue-creator_label"
                    date={new Date(issue.editDate)}
                    locale={i18n.language}
                  />
                  <span>{`${t("by", "by")} ${
                    issue.editAuthor?.firstName || ""
                  } ${issue.editAuthor?.lastName || ""}`}</span>
                </>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default IssueBoardColumnHeaderOld;
