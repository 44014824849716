import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// Helpers
import TagSelect from "../../TagSelect";

import CustomSelect from "../../CustomSelect";
import { FilterOptions } from "../../FilterOptions";
import { DatePicker } from "./DatePicker";
import { CloseButton } from "../../Buttons";

const AsideIssueFilter = ({
  show_aside,
  priorities,
  types,
  statuses,
  categories,
  floors,
  buildings,
  workphases,
  tagOptions,
  // visibilities,
  // documents,
  // emails,
  users,
  toggleAside,
  onSubmit,
  onReset,
  setShowFilterModal,
  filters,
  setCurrentFilter,
  currentFilter,
  handeleDeleteFilter,
  handleSaveFilter,
  filterValues,
  updateFilterValues,
}) => {
  const { t } = useTranslation();
  const [show_created_date_from, setShowCreatedDateFrom] = useState(false);
  const [show_created_date_to, setShowCreatedDateTo] = useState(false);
  const [show_due_date_from, setShowDueDateFrom] = useState(false);
  const [show_due_date_to, setShowDueDateTo] = useState(false);
  const [show_start_date_to, setShowStartDateTo] = useState(false);
  const [show_start_date_from, setShowStartDateFrom] = useState(false);
  const [show_edit_date_from, setShowEditDateFrom] = useState(false);
  const [show_edit_date_to, setShowEditDateTo] = useState(false);

  return (
    <aside className={"main__aside aside" + (show_aside ? " is-open" : "")}>
      <CloseButton onClick={() => toggleAside("aside_filter")} />

      <form id="filter-issue" className="aside__block is-visible">
        <div className="aside__title">{t("filter", "Filter")}</div>

        <div className="filter-otions">
          <FilterOptions
            id="FilterOptionsIssuesDropdown"
            showSaveFilter={setShowFilterModal}
            handeleDeleteFilter={handeleDeleteFilter}
            currentFilter={currentFilter}
            filters={filters}
            handleSaveFilter={handleSaveFilter}
          />

          <CustomSelect
            id="LoadFilterIssuesDropdown"
            values={filters}
            onChange={({ value }) => {
              setCurrentFilter({
                ...filters.find((filter) => filter.id === value),
              });
            }}
            value={
              Object.keys(currentFilter).length
                ? currentFilter
                : { value: null, label: t("load_filter", "Load Filter") }
            }
            isModel={true}
          />
        </div>

        <div className="aside__form custom-scrollbar">
          <div>
            <div className="aside__subtitle mb-0">{t("date", "Date")}</div>
            <div className="aside__subtitle aside__subtitle--small">
              <p>{t("date_created", "Creation date")}</p>
            </div>
          </div>

          <div className="aside__grid mt-0">
            <DatePicker
              id="CreatedAfterIssuesFilter"
              setShowDateFromTo={setShowCreatedDateFrom}
              date_from_to={filterValues.createdDateFrom}
              setDateFromTo={(value) =>
                updateFilterValues("createdDateFrom", value)
              }
              showDateFromTo={show_created_date_from}
              phrase={"created_after"}
              definition={"Created after"}
            />
            <DatePicker
              id="CreatedBeforeIssuesFilter"
              setShowDateFromTo={setShowCreatedDateTo}
              date_from_to={filterValues.createdDateTo}
              setDateFromTo={(value) =>
                updateFilterValues("createdDateTo", value)
              }
              showDateFromTo={show_created_date_to}
              phrase={"created_before"}
              definition={"Created before"}
            />
          </div>
          <div className="aside__subtitle aside__subtitle--small">
            <p>{t("start_date", "Start date")}</p>
          </div>
          <div className="aside__grid mt-0">
            <DatePicker
              id="StartDateAfterIssuesFilter"
              setShowDateFromTo={setShowStartDateFrom}
              date_from_to={filterValues.startDateFrom}
              setDateFromTo={(value) =>
                updateFilterValues("startDateFrom", value)
              }
              showDateFromTo={show_start_date_from}
              phrase={"start_after"}
              definition={"Start after"}
            />
            <DatePicker
              id="StartDateBeforeIssueFilter"
              setShowDateFromTo={setShowStartDateTo}
              date_from_to={filterValues.startDateTo}
              setDateFromTo={(value) =>
                updateFilterValues("startDateTo", value)
              }
              showDateFromTo={show_start_date_to}
              phrase={"start_before"}
              definition={"Start before"}
            />
          </div>

          <div className="aside__subtitle aside__subtitle--small">
            <p>{t("due_date", "Due date")}</p>
          </div>
          <div className="aside__grid mt-0">
            <DatePicker
              id="DueDateAfterIssueFilter"
              setShowDateFromTo={setShowDueDateFrom}
              date_from_to={filterValues.dueDateFrom}
              setDateFromTo={(value) =>
                updateFilterValues("dueDateFrom", value)
              }
              showDateFromTo={show_due_date_from}
              phrase={"due_after"}
              definition={"Due after"}
            />
            <DatePicker
              id="DueDateBeforeIssueFilter"
              setShowDateFromTo={setShowDueDateTo}
              date_from_to={filterValues.dueDateTo}
              setDateFromTo={(value) => updateFilterValues("dueDateTo", value)}
              showDateFromTo={show_due_date_to}
              phrase={"due_before"}
              definition={"Due before"}
            />
          </div>

          <div className="aside__group">
            <div className="aside__subtitle mb-0">{t("issue", "Issue")}</div>
          </div>

          <div className="aside__grid mt-4">
            <div>
              <label className="label label--bold" htmlFor="issue-status">
                {t("status", "Status")}
              </label>
              <div className="select--grey select--light">
                <CustomSelect
                  id="StatusIssueFilterDropdown"
                  name="status"
                  values={statuses}
                  onChange={(value) => updateFilterValues("status", value)}
                  value={filterValues.status}
                  isMulti={true}
                  isOptional={true}
                  isModel={true}
                />
              </div>
            </div>
            <div>
              <label className="label label--bold" htmlFor="issue-priority">
                {t("priority", "Priority")}
              </label>
              <CustomSelect
                id="issue-priority"
                values={priorities}
                value={filterValues.priority}
                onChange={(value) => updateFilterValues("priority", value)}
                isGrey={true}
              />
            </div>
          </div>
          <div className="aside__grid mt-4">
            <div>
              <label className="label label--bold" htmlFor="issue-assigned-to">
                {t("assigned_to", "Assigned to")}
              </label>
              <CustomSelect
                id="issue-assigned-to"
                values={users}
                value={filterValues.assignedTo}
                onChange={(value) => updateFilterValues("assignedTo", value)}
                isGrey={true}
                isUser={true}
              />
            </div>
            <div>
              <label className="label label--bold" htmlFor="issue-reviewer">
                {t("reviewer", "Reviewer")}
              </label>
              <CustomSelect
                id="issue-reviewer"
                values={users}
                value={filterValues.reviewer}
                onChange={(value) => updateFilterValues("reviewer", value)}
                isGrey={true}
                isUser={true}
              />
            </div>
          </div>

          <div className="aside__grid mt-4">
            <div>
              <label className="label label--bold" htmlFor="issue-creator">
                {t("creator", "Creator")}
              </label>
              <CustomSelect
                id="issue-creator"
                values={users}
                value={filterValues.creator}
                onChange={(value) => updateFilterValues("creator", value)}
                isGrey={true}
                isUser={true}
              />
            </div>

            <div>
              <label className="label label--bold" htmlFor="issue-source">
                {t("source", "Source")}
              </label>
              <input
                id="issue-source"
                value={filterValues.source}
                onChange={(e) => updateFilterValues("source", e.target.value)}
              />
            </div>
          </div>
          <div className="aside__group">
            <div className="aside__subtitle mb-0">{t("detail", "Detail")}</div>
          </div>
          <div className="aside__grid mt-4">
            <div>
              <label className="label label--bold" htmlFor="issue-category">
                {t("discipline", "Discipline")}
              </label>
              <CustomSelect
                id="issue-discipline"
                values={categories}
                value={filterValues.category}
                onChange={(value) => updateFilterValues("category", value)}
                isGrey={true}
                menuPlacement="top"
              />
            </div>
            <div>
              <label className="label label--bold" htmlFor="issue-floor">
                {t("floor", "Storey")}
              </label>
              <CustomSelect
                id="issue-floor"
                values={floors}
                value={filterValues.floor}
                onChange={(value) => updateFilterValues("floor", value)}
                isGrey={true}
                menuPlacement="top"
              />
            </div>
          </div>
          <div className="aside__grid mt-4">
            <div>
              <label className="label label--bold" htmlFor="issue-building">
                {t("building", "Building")}
              </label>
              <CustomSelect
                id="issue-building"
                values={buildings}
                value={filterValues.building}
                onChange={(value) => updateFilterValues("building", value)}
                isGrey={true}
                menuPlacement="top"
              />
            </div>
            {!!workphases.length && (
              <div>
                <label className="label label--bold" htmlFor="issue-workphase">
                  {t("work_stage", "Work Stage")}
                </label>
                <CustomSelect
                  id="issue-workphase"
                  values={workphases}
                  value={filterValues.workphase}
                  onChange={(value) => updateFilterValues("workphase", value)}
                  isGrey={true}
                  menuPlacement="top"
                />
              </div>
            )}
          </div>
          <div className="aside__grid mt-4">
            <div>
              <label className="label label--bold" htmlFor="issue-type">
                {t("tags", "Tags")}
              </label>
              <TagSelect
                id="TagsIssueFilterDropdown"
                values={tagOptions}
                onChange={(item) => updateFilterValues("tags", item)}
                value={filterValues.tags}
                isBackgroundVisible={
                  filterValues.tags.length > 0 ? false : true
                }
                isGrey={true}
                isDisableAddTag={true}
              />
            </div>
            {/*<div>*/}
            {/*  <label className="label label--bold" htmlFor="issue-type">*/}
            {/*    {t("visibility", "Visibility")}*/}
            {/*  </label>*/}
            {/*  <CustomSelect*/}
            {/*      id="issue-type"*/}
            {/*      values={visibilities}*/}
            {/*      value={filterValues.visibility}*/}
            {/*      onChange={(value) => updateFilterValues("visibility", value)}*/}
            {/*      isGrey={true}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>

          <div className="aside__grid mt-4">
            <div>
              <label className="label label--bold" htmlFor="issue-type">
                {t("type", "Type")}
              </label>
              <CustomSelect
                id="issue-type"
                values={types}
                value={filterValues.type}
                onChange={(value) => updateFilterValues("type", value)}
                isGrey={true}
              />
            </div>
          </div>
          <div className={"aside__group"}>
            <div>
              <div className="aside__subtitle mb-0">{t("edit", "Edit")}</div>
              <div className="aside__subtitle aside__subtitle--small mt-2">
                <p>{t("edit_date", "Edit Date")}</p>
              </div>
            </div>

            <div className="aside__grid mt-0">
              <DatePicker
                id="EditedAfterIssueFilter"
                setShowDateFromTo={setShowEditDateFrom}
                date_from_to={filterValues.editDateFrom}
                setDateFromTo={(value) =>
                  updateFilterValues("editDateFrom", value)
                }
                showDateFromTo={show_edit_date_from}
                phrase={"edited_after"}
                definition={"Edited after"}
              />
              <DatePicker
                id="EditedBeforeIssueFilter"
                setShowDateFromTo={setShowEditDateTo}
                date_from_to={filterValues.editDateTo}
                setDateFromTo={(value) =>
                  updateFilterValues("editDateTo", value)
                }
                showDateFromTo={show_edit_date_to}
                phrase={"edited_before"}
                definition={"Edited before"}
              />
            </div>
          </div>
          <div className="aside__grid mt-4">
            <div>
              <label className="label" htmlFor="issue-creator">
                {t("last_edited_by", "Last edited by")}
              </label>
              <CustomSelect
                id="issue-creator"
                values={users}
                value={filterValues.lastEditor}
                onChange={(value) => updateFilterValues("lastEditor", value)}
                isGrey={true}
                isUser={true}
              />
            </div>
          </div>
          {/*commented code wait for api's maybe to next release (need a lot of changes on api)*/}
          {/*<div className="aside__group">*/}
          {/*  <div className="aside__subtitle mb-0">{t("define_search", "Define Search")}</div>*/}
          {/*</div>*/}

          {/*<div className="aside__grid mt-4">*/}
          {/*  <div>*/}
          {/*    <label className="label label--bold" htmlFor="issue-priority">*/}
          {/*      {t("viewpoints", "Viewpoints")}*/}
          {/*    </label>*/}
          {/*    <CustomSelect*/}
          {/*        id="issue-priority"*/}
          {/*        values={priorities}*/}
          {/*        value={filterValues.priority}*/}
          {/*        onChange={(value) => updateFilterValues("priority", value)}*/}
          {/*        isGrey={true}*/}
          {/*        isDisabled={isPriorityDisabled}*/}
          {/*    />*/}
          {/*  </div>*/}

          {/*  <div>*/}
          {/*    <label className="label label--bold" htmlFor="issue-creator">*/}
          {/*      {t("attached_documents", "Attached Documents")}*/}
          {/*    </label>*/}
          {/*    <CustomSelect*/}
          {/*        id="issue-creator"*/}
          {/*        values={documents}*/}
          {/*        value={filterValues.document}*/}
          {/*        onChange={(value) => updateFilterValues("document", value)}*/}
          {/*        isGrey={true}*/}
          {/*        isUser={true}*/}
          {/*        menuPlacement={'top'}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="aside__grid mt-4">*/}
          {/*  <div>*/}
          {/*    <label className="label label--bold" htmlFor="issue-assigned-to">*/}
          {/*      {t("attached_e-mails", "Attached E-Mails")}*/}
          {/*    </label>*/}
          {/*    <CustomSelect*/}
          {/*        id="issue-assigned-to"*/}
          {/*        values={emails}*/}
          {/*        value={filterValues.email}*/}
          {/*        onChange={(value) => updateFilterValues("email", value)}*/}
          {/*        isGrey={true}*/}
          {/*        isUser={true}*/}
          {/*        menuPlacement={'top'}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>

        <div className="aside__bottom">
          <button id="ApplyFilterIssueFilter" className="btn btn--primary" type="button" onClick={onSubmit}>
            {t("apply_filters", "Apply filters")}
          </button>
          <button id="ResetFilterIssueFilter" className="btn btn--outline" type="button" onClick={onReset}>
            {t("reset_filters", "Reset filters")}
          </button>
        </div>
      </form>
    </aside>
  );
};

AsideIssueFilter.propTypes = {
  show_aside: PropTypes.bool,
  priorities: PropTypes.array,
  types: PropTypes.array,
  statuses: PropTypes.array,
  categories: PropTypes.array,
  floors: PropTypes.array,
  buildings: PropTypes.array,
  visibilities: PropTypes.array,
  toggleAside: PropTypes.func,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
};

export default AsideIssueFilter;
