import { useCallback, useMemo } from 'react';
import useProjectsQuery from 'projects/hooks/useProjectsQuery';
import Project from 'projects/types/Project';
import { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';

export default function useCurrentProjectQuery(options?: Omit<UseQueryOptions<Project[] | undefined, unknown, Project | undefined, QueryKey>, 'queryKey' | 'queryFn'> | undefined) {
  const { openedProjectId } = useVisoplanApiContext();
  const select = useCallback((data: Project[] | undefined) => (data?.length ? data[0] : undefined), []);
  const enabled = useMemo(() => options?.enabled !== false && !!openedProjectId, [options?.enabled, openedProjectId]);
  return useProjectsQuery<Project | undefined>(openedProjectId ? [openedProjectId] : undefined, { ...options, enabled, select });
}
