import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import WebViewer from "@pdftron/webviewer";
import useApiConfig from "api/hooks/useApiConfig";

export const licenseKey =
  "Visoplan GmbH (visoplan.de):OEM:Visoplan::B+:AMS(20240229):89A5D22D04B7280A0360B13AC9A2537860612FCDE9267A15AB12ACF2EF3C208E54AA31F5C7";

const CompareFeature = ({ optionsToken, data, data2, setComparing }) => {
  const { apiUrl, pdfTronUrl } = useApiConfig();
  const docView = useRef(null);
  const urlForComapre = `${apiUrl}/resource/`;

  useEffect(() => {
    setComparing(false);
    WebViewer(
      {
        licenseKey: licenseKey,
        path: "/webviewer",
        enableMeasurement: true,
        fullAPI: true,
        webviewerServerURL: pdfTronUrl,
      },
      docView.current
    ).then(async (instance) => {
      const { documentViewer, PDFNet, Annotations } = instance.Core;
      const { Color } = Annotations;

      instance.UI.openElements(["notesPanel"]);
      instance.UI.disableElements([
        "notesPanelResizeBar",
        "toolbarGroup-Insert",
        "toolbarGroup-FillAndSign",
        "toolbarGroup-Forms",
      ]);
      await PDFNet.initialize();

      const options = await PDFNet.PDFDoc.createTextDiffOptions();
      options.setColorA(new Color(233, 62, 62));
      options.setColorB(new Color(0, 143, 47));

      const newDoc = await PDFNet.PDFDoc.create();
      await newDoc.lock();

      const doc1 = await PDFNet.PDFDoc.createFromURL(
        `${urlForComapre}${data2}`,
        optionsToken
      );
      const doc2 = await PDFNet.PDFDoc.createFromURL(
        `${urlForComapre}${data}`,
        optionsToken
      );
      await newDoc.appendTextDiffDoc(doc1, doc2, options);

      await newDoc.unlock();

      instance.UI.loadDocument(newDoc);

      // wait until the document has been loaded
      documentViewer.addEventListener("documentLoaded", () => {
        instance.UI.setLayoutMode(instance.UI.LayoutMode.FacingContinuous);
      });
    });
  }, []);

  return <div className="preview-section-content" ref={docView}></div>;
};

CompareFeature.propTypes = {
  useFileUrl: PropTypes.func,
  setFileUrl: PropTypes.func,
  resourceId: PropTypes.string,
  setLoading: PropTypes.func,
};

export default CompareFeature;
