import { QueryFunction, QueryObserverOptions } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import IssueDto from 'issues/types/IssueDto';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { VisoDetailsQueryKey } from 'api/hooks/useDefaultEntityQueryKeys';
import useDefaultOdataQueryFn from 'api/hooks/useDefaultOdataQueryFn';
import useIssuesOdataQueryKey from 'issues/hooks/useIssuesOdataQueryKey';

export type IssueQueryKey = VisoDetailsQueryKey<ApiEndpoint.Issue>;
export type IssueQueryFnData = IssueDto | undefined;
export type IssueData = IssueDto | undefined;
export type IssueQueryOptions = Partial<Omit<QueryObserverOptions<IssueQueryFnData, unknown, IssueData, IssueQueryFnData, IssueQueryKey>, 'queryKey'>>;

export default function useIssueQueryOptions() {
  const odataQueryFn = useDefaultOdataQueryFn<IssueDto, IssueDto, ApiEndpoint.Issue>();
  const { getQueryKey } = useIssuesOdataQueryKey();
  const queryFn = useCallback<QueryFunction<IssueDto | undefined, VisoDetailsQueryKey<ApiEndpoint.Issue>>>(async ({ queryKey }) => {
    const [, , , id] = queryKey;
    const odataQueryKey = getQueryKey({ filter: { id: { eq: id } } });
    const odataResult = await odataQueryFn({ queryKey: odataQueryKey });
    if (!odataResult.length) throw new Error('Requested issue not part of API response');
    return odataResult[0];
  }, [getQueryKey, odataQueryFn]);
  return useMemo<IssueQueryOptions>(() => ({
    queryFn,
  }), [queryFn]);
}
