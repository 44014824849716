import React from "react";
import { useTranslation } from "react-i18next";

const ComponentTooltip = ({ tooltipInfo }) => {
  const { t } = useTranslation();

  if (!tooltipInfo.visible) return <></>;

  return (
    <div
      className="component-tooltip"
      style={{ left: `${tooltipInfo.xPos}px`, top: `${tooltipInfo.yPos}px` }}
    >
      <div>
        {t("Name", "Name")}
        <span>{tooltipInfo.component.name}</span>
      </div>
      <div>
        {t("Type", "Type")}
        <span>{tooltipInfo.component.type}</span>
      </div>
    </div>
  );
};

export default ComponentTooltip;
