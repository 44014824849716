import React, { useCallback, useMemo, useState } from 'react';
import { Box, ButtonBase, CircularProgress, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import FileType from 'documents/types/FileType';
import ImageGalleryDialog from 'resources/components/ImageGalleryDialog';
import useResourceQuery from 'resources/hooks/useResourceQuery';

interface IssueLinkedImagesPanelProps {
  issueId: string,
}

export default function IssueLinkedImagesPanel({
  issueId,
}: IssueLinkedImagesPanelProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { data: issue } = useIssueQuery(issueId);
  const odataQuery = useMemo(() => (issue ? { filter: { id: { in: issue.linkedDocumentVersionIds } } } : undefined), [issue]);
  const { data: linkedDocumentVersions } = useDocumentVersionsQuery(odataQuery);
  const imageResourceIds = useMemo(() => linkedDocumentVersions?.filter((v) => v.fileType === FileType.Image && !v.originalFileName.endsWith('.svg')).map((v) => v.fileId), [linkedDocumentVersions]);
  const [imageGalleryDialogOpen, setImageGalleryDialogOpen] = useState<boolean>(false);
  const onClickOpenImageGallery = useCallback(() => setImageGalleryDialogOpen(true), []);
  const onCloseImageGalleryDialog = useCallback(() => setImageGalleryDialogOpen(false), []);
  const thumnailImageId = useMemo(() => {
    if (!issue || !imageResourceIds) return undefined;
    return issue.thumbnailImageId || imageResourceIds[0];
  }, [imageResourceIds, issue]);
  const { data: thumbnailDataUrl } = useResourceQuery(thumnailImageId);
  return (
    <Box id="IssueLinkedImagesPanel">
      <Box sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.3)',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
      >
        <Typography variant="h4">
          {t('issue-linked-images-panel_title', 'Linked images')}
          {!!imageResourceIds && ` (${imageResourceIds.length})`}
          {!imageResourceIds && <CircularProgress size={12} sx={{ ml: 1 }} />}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
          {!!imageResourceIds?.length && (
            <>
              {!!thumbnailDataUrl && (
                <ButtonBase onClick={onClickOpenImageGallery} sx={{ width: '96px', height: '96px', borderRadius: '8px' }}>
                  <img src={thumbnailDataUrl} style={{ width: '96px', height: '96px', borderRadius: '8px', objectFit: 'cover' }} alt="Thumbnail" />
                </ButtonBase>
              )}
              {imageResourceIds.length !== 1 && (
                <ButtonBase
                  disabled={imageResourceIds.length === 0}
                  onClick={onClickOpenImageGallery}
                  sx={{ width: '96px', height: '96px', borderRadius: '8px', backgroundColor: theme.palette.grey[300], fontSize: 14, fontWeight: 700 }}
                >
                  {imageResourceIds.length === 0 ? '0' : `+${imageResourceIds.length - 1}`}
                </ButtonBase>
              )}
            </>
          )}
        </Box>
      </Box>
      {!!imageGalleryDialogOpen && <ImageGalleryDialog resourceIds={imageResourceIds} onClose={onCloseImageGalleryDialog} />}
    </Box>
  );
}
