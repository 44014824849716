import React from 'react';

interface CheckboxProps {
  className: string;
  label: string;
  name?: string;
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

function Checkbox({
  className = '',
  label,
  name,
  checked,
  onChange,
  disabled,
}: CheckboxProps) {
  return (
    <label className={className} htmlFor={name}>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      { label }
    </label>
  );
}

Checkbox.defaultProps = {
  name: '',
  disabled: false,
};

export default Checkbox;
