import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import RenameInboxDto from 'emails/types/RenameInboxDto';

export default function useInboxEmailUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { baseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Project);
  return useMutation<void, unknown, RenameInboxDto>(async (dto: RenameInboxDto) => {
    const { data } = await axiosInstance.patch<void>(`${ApiEndpoint.Inbox}`, dto);
    return data;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(baseQueryKey);
    },
  });
}
