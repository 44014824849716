import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import UpdateCollaboratorRoleMapDto from 'collaborators/types/UpdateCollaboratorRoleMapDto';
import CollaboratorRoleMapDto from 'collaborators/types/CollaboratorRoleMapDto';

export default function useCollaboratorRoleMapUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { listQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Collaborators);
  return useMutation<void, unknown, UpdateCollaboratorRoleMapDto>(async (dto: UpdateCollaboratorRoleMapDto) => {
    await axiosInstance.put<CollaboratorRoleMapDto>(`${ApiEndpoint.CollaboratorRoleMap}`, dto);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(listQueryKey);
    },
  });
}
