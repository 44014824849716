import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import UserGroupDto from 'users-groups/types/UserGroupDto';
import UpdateUserGroupDto from 'users-groups/types/UpdateUserGroupDto';
import { useCallback } from 'react';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';

interface UserGroupUpdateMutationContext {
  cachedEntities: UserGroupDto[] | undefined,
}

export default function useUserGroupUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { baseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.UserGroup);
  const mutationFn = useCallback(async (dto: UpdateUserGroupDto) => {
    const { data } = await axiosInstance.put<UserGroupDto>(`${ApiEndpoint.UserGroup}`, dto);
    return data;
  }, [axiosInstance]);
  const onSettled = useCallback(() => queryClient.invalidateQueries({ queryKey: baseQueryKey }), [queryClient, baseQueryKey]);
  return useMutation<UserGroupDto, unknown, UpdateUserGroupDto, UserGroupUpdateMutationContext>({
    mutationFn,
    onSettled,
  });
}
