import classNames from 'classnames';
import React from 'react';

interface CheckboxOnlyProps {
  id: string;
  name: string;
  value?: number | string;
  checked: boolean;
  disabled?: boolean;
  label?: string;
  isRadio?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function CheckboxOnly({
  id,
  name,
  value,
  checked,
  disabled,
  label,
  isRadio,
  onChange,
}: CheckboxOnlyProps) {
  const className = classNames(
    'checkbox checkbox--only',
    isRadio && 'checkbox--circle',
  );

  return (
    <div className={className}>
      <input
        type={isRadio ? 'radio' : 'checkbox'}
        id={id}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <span />
      <label htmlFor={id}>
        { label }
      </label>
    </div>
  );
}

CheckboxOnly.defaultProps = {
  value: -1,
  disabled: false,
  label: '',
  isRadio: false,
};
