import React, {
  useCallback, useMemo, useRef,
} from 'react';
import { Box } from '@mui/material';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, ColGroupDef, IServerSideDatasource, IServerSideGetRowsParams, RowClassRules, RowClickedEvent, ValueFormatterFunc } from '@ag-grid-community/core';
import { useTranslation } from 'react-i18next';
import AG_GRID_LOCALE_DE from 'setup/agGrid.locale.de';
import AG_GRID_LOCALE_EN from 'setup/agGrid.locale.en';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import IssuesDataGridRow from 'issues/types/IssuesDataGridRow';
import useIssuesOdataQueryData from 'issues/hooks/useIssuesOdataQueryData';
import useIssuesSelectionContext from 'issues/hooks/useIssuesSelectionContext';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import IssueDto from 'issues/types/IssueDto';
import LabelChipCell from 'issues/components/cell-renderers/LabelChipCell';
import LabelIdsChipsCell from 'issues/components/cell-renderers/LabelIdsChipsCell';
import useCollaboratorsQuery from 'collaborators/hooks/useCollaboratorsQuery';

interface IssuesDataGridProps {
  disabledIssueIds?: Set<string> | undefined,
}

export default function IssuesDataGrid({
  disabledIssueIds,
}: IssuesDataGridProps) {
  const gridRef = useRef<AgGridReact<IssuesDataGridRow>>(null);
  const { selectedIssueIds, setSelectedIssueIds } = useIssuesSelectionContext();
  const { data: collaborators } = useCollaboratorsQuery();
  const { odataQuery } = useIssuesFilterContext();
  const { t } = useTranslation('issues');

  const onRowClicked = useCallback((event: RowClickedEvent<IssuesDataGridRow>) => {
    if (!event.data) return;
    if (!!disabledIssueIds && disabledIssueIds.has(event.data?.id)) return;
    setSelectedIssueIds([event.data.id]);
  }, [disabledIssueIds, setSelectedIssueIds]);

  const defaultColDef = useMemo<ColDef<IssuesDataGridRow>>(() => ({
    resizable: true,
    sortable: false,
    lockPosition: true,
    headerClass: 'no-hover',
  }), []);

  const dateValueFormatter = useCallback<ValueFormatterFunc<IssueDto, string | undefined>>(({ value }) => (value ? new Date(value).toLocaleDateString('de-DE', { timeZone: 'Europe/Berlin' }) : ''), []);
  const assignedUserIdsValueFormatter = useMemo<ValueFormatterFunc<IssueDto, string[] | undefined>>(() => {
    if (!collaborators) return () => '';
    const map = new Map(collaborators.map((c) => [c.id, c]));
    return ({ value }) => value?.filter((id) => map.has(id))
      .map((id) => {
        const c = map.get(id)!;
        return `${c.firstName} ${c.lastName}`;
      }).join(', ') ?? '';
  }, [collaborators]);
  const dateTimeValueFormatter = useCallback<ValueFormatterFunc<IssueDto, string | undefined>>(({ value }) => (value ? new Date(value).toLocaleString('de-DE', { timeZone: 'Europe/Berlin' }) : ''), []);
  const columnDefs = useMemo<(ColDef<IssuesDataGridRow> | ColGroupDef<IssuesDataGridRow>)[]>(() => [
    // {
    //   colId: 'selected',
    //   resizable: false,
    //   sortable: false,
    //   width: 54,
    //   minWidth: 24,
    //   headerClass: 'no-hover no-border no-padding',
    //   cellClass: 'no-separator',
    //   cellStyle: { paddingLeft: 0, paddingRight: 0 },
    //   cellRenderer: CheckboxCell,
    //   headerComponent: CheckboxHeaderCell,
    // },
    {
      field: 'issueNumber',
      headerName: t('issues-data-grid_issue-number-column-header', 'No.'),
      initialWidth: 80,
      cellClass: 'issue-number-cell',
    }, {
      field: 'title',
      headerName: t('issues-data-grid_issue-title-column-header', 'Title'),
      initialWidth: 300,
    }, {
      field: 'description',
      headerName: t('issues-data-grid_issue-description-column-header', 'Description'),
      initialWidth: 300,
    }, {
      field: 'assignedUserIds',
      initialWidth: 100,
      headerName: t('issues-data-grid_issue-assigned-users-column-header', 'Assigned'),
      valueFormatter: assignedUserIdsValueFormatter,
    }, {
      field: 'issueStatus',
      initialWidth: 150,
      headerName: t('issues-data-grid_issue-status-column-header', 'Status'),
      cellRenderer: LabelChipCell,
    }, {
      field: 'dueDate',
      headerName: t('issues-data-grid_due-date-column-header', 'Due Date'),
      valueFormatter: dateValueFormatter,
      initialWidth: 95,
    }, {
      field: 'editDate',
      headerName: t('issues-data-grid_edit-date-column-header', 'Edit Date'),
      valueFormatter: dateTimeValueFormatter,
      initialWidth: 145,
    }, {
      field: 'tagIds',
      headerName: t('issues-data-grid_tags-column-header', 'Tags'),
      cellRenderer: LabelIdsChipsCell,
    }, {
      field: 'disciplineMetaDataIds',
      headerName: t('issues-data-grid_discipline-column-header', 'Disciplines'),
      cellRenderer: LabelIdsChipsCell,
    }, {
      field: 'buildingMetaDataIds',
      headerName: t('issues-data-grid_buildings-column-header', 'Buildings'),
      cellRenderer: LabelIdsChipsCell,
    }, {
      field: 'floorMetaDataIds',
      headerName: t('issues-data-grid_floors-column-header', 'Floors'),
      cellRenderer: LabelIdsChipsCell,
    }, {
      flex: 1,
      cellClass: 'no-separator',
      sortable: false,
      headerClass: 'no-hover no-border no-padding',
      resizable: false,
    },
  ], [assignedUserIdsValueFormatter, dateTimeValueFormatter, dateValueFormatter, t]);

  const getIssuesOdataQueryData = useIssuesOdataQueryData();
  const serverSideDatasource = useMemo<IServerSideDatasource | undefined>(() => (odataQuery ? {
    getRows: async (params: IServerSideGetRowsParams<IssuesDataGridRow>) => {
      const { request, success, fail } = params;
      const topSkip = request.startRow !== undefined && request.endRow !== undefined ? { top: request.endRow - request.startRow, skip: request.startRow } : {};
      try {
        const rowData = await getIssuesOdataQueryData({ ...odataQuery, ...topSkip });
        success({ rowData });
      } catch (error) {
        fail();
        throw error;
      }
    },
  } : undefined), [getIssuesOdataQueryData, odataQuery]);

  const { i18n } = useTranslation();
  const localeText = useMemo<{ [key: string]: string; }>(() => (i18n.language.toLocaleLowerCase().startsWith('de') ? AG_GRID_LOCALE_DE : AG_GRID_LOCALE_EN), [i18n.language]);

  const rowStyle = useMemo(() => ({ cursor: 'pointer' }), []);
  const rowClassRules = useMemo<RowClassRules<IssuesDataGridRow>>(() => ({
    'viso-selected': ({ data }) => selectedIssueIds.length === 1 && data?.id === selectedIssueIds[0],
    ...(disabledIssueIds ? { 'viso-disabled': ({ data }) => ((data?.id ? disabledIssueIds.has(data.id) : undefined) ?? false) } : {}),
  }), [disabledIssueIds, selectedIssueIds]);

  return (
    <Box id="IssuesDataGrid" sx={{ height: '100%', backgroundColor: '#F7F8FB' }} className="ag-theme-material ag-theme-visoplan table-view-data-grid">
      <AgGridReact<IssuesDataGridRow>
        localeText={localeText}
        rowHeight={54}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        ref={gridRef}
        maxConcurrentDatasourceRequests={1}
        rowSelection="multiple"
        rowModelType="serverSide"
        serverSideDatasource={serverSideDatasource}
        suppressCellFocus
        suppressRowHoverHighlight
        suppressRowClickSelection
        enableCellTextSelection
        onRowClicked={onRowClicked}
        rowStyle={rowStyle}
        rowClassRules={rowClassRules}
        // @ts-ignore
        modules={[ServerSideRowModelModule]}
        // quickFilterText={quickFilterString}
      />
    </Box>
  );
}
