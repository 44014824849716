import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ProjectCopyDto from 'projects/types/ProjectCopyDto';
import ProjectDto from 'projects/types/ProjectDto';
import useCurrentUserQueryKey from 'users/hooks/useCurrentUserQueryKey';
import { useCallback } from 'react';

export default function useProjectCopyMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { listQueryKey: projectsListQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Project);
  const currentUserQueryKey = useCurrentUserQueryKey();
  const mutationFn = useCallback(async (projectCopyDto: ProjectCopyDto) => {
    await axiosInstance.post<ProjectDto>(`${ApiEndpoint.ProjectCopy}`, projectCopyDto);
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries(projectsListQueryKey);
    queryClient.invalidateQueries(currentUserQueryKey);
  }, [currentUserQueryKey, projectsListQueryKey, queryClient]);
  return useMutation<void, unknown, ProjectCopyDto>({
    mutationFn,
    onSuccess,
  });
}
