import React, { useMemo } from 'react';
import { Box, Button } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import useOpenIssueStatus from 'issues/hooks/useOpenIssueStatus';
import Icon from '@mdi/react';
import { mdiCheck, mdiRewind } from '@mdi/js';

interface AssignmentActionButtonsProps extends ISxProps {
  issueId: string,
  onClickCompleteAssignment: () => void,
  onClickRejectAssignment: () => void,
}

export default function IssueAssignmentActionButtons({
  sx,
  issueId,
  onClickCompleteAssignment,
  onClickRejectAssignment,
}: AssignmentActionButtonsProps) {
  const { t } = useTranslation('issues');
  const { data: currentUser } = useCurrentUserQuery();
  const { data: issue, isLoading: isLoadingIssue } = useIssueQuery(issueId);
  const isAssignedToCurrentUser = useMemo(() => (!!issue?.assignedUserIds?.length && !!currentUser && issue.assignedUserIds.some((id) => id === currentUser.id)), [currentUser, issue]);
  const issueHasReviewer = useMemo(() => (issue ? Boolean(issue.reviewer) : undefined), [issue]);
  const openIssueStatus = useOpenIssueStatus();

  const canDoAssignmentActions = useMemo(() => {
    if (!issue?.issueStatus || !openIssueStatus) return undefined;
    return issue.issueStatus.id === openIssueStatus.id && isAssignedToCurrentUser;
  }, [isAssignedToCurrentUser, issue, openIssueStatus]);

  const canRejectAssignment = useMemo(() => canDoAssignmentActions && issue?.createAuthorId, [canDoAssignmentActions, issue?.createAuthorId]);

  if (!issueId || !canDoAssignmentActions) return null;
  return (
    <Box id="IssueActionButtons" sx={{ ...sx, display: 'flex', gap: 1 }}>
      <Button
        variant="contained"
        color="secondary"
        onClick={onClickRejectAssignment}
        disabled={!canRejectAssignment || isLoadingIssue}
        sx={{ gap: 1 }}
        size="small"
      >
        <Icon path={mdiRewind} size={0.5} />
        {t('assignment-action-buttons_reject-assignment', 'Reject')}
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={onClickCompleteAssignment}
        sx={{ gap: 1 }}
        size="small"
      >
        <Icon path={mdiCheck} size={0.5} />
        {issueHasReviewer ? t('assignment-action-buttons_complete-assignment-for-review', 'Submit for review') : t('assignment-action-buttons_complete-assignment', 'Complete')}
      </Button>
    </Box>
  );
}
