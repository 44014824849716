import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import ConfirmModal from "../ConfirmModal";
import * as EncodingHelper from "../../Helpers/Encoding";

import GroupElement from "./GroupElement";
import GroupName from "./GroupName";
import RoleAction from "projects/types/RoleAction";
import useCurrentUserRole from "users/hooks/useCurrentUserRole";
import { getGeneralSvg } from "../SVGs";

export default function Group({
  schema,
  visoplanGroups,
  setGroups,
  dateTypes,
  separatorsTypes,
  documentStatuses,
  categories,
  buildings,
  floors,
  workphasesType,
  tagOptions,
  setApiError,
  projectElement,
  setIsEnableSave,
}) {
  const { t } = useTranslation();
  const currentUserRole = useCurrentUserRole();

  const defaultValue = {
    value: -1,
    label: t("select_ellipsis", "select..."),
    color: "#ffffff",
  };
  const [targetId, setTargetId] = useState(-1);
  const [groupValue, setGroupValue] = useState([defaultValue]);
  const [showModal, setShowModal] = useState(false);
  const [groupIndex, setGroupIndex] = useState("");
  const [separationValue, setSeparationValue] = useState(defaultValue);
  const [error, setError] = useState("");
  const category = [
    ...buildings,
    ...categories,
    ...floors,
    ...workphasesType,
    ...tagOptions,
    ...documentStatuses,
  ];
  const versionLenght = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
  ];

  const getValue = (metaId, tagId) => {
    if (!metaId && !tagId && !tagId.length) return [];
    const obj = category.find((item) => (item.id ? item.id === metaId : item.tagId === metaId));

    let arr = [];

    if (tagId && tagId.length) {
      arr = tagId.map((tag) => {
        const foundItem = category.find((item) => item.tagId === tag);
        if (foundItem) return foundItem;
        return undefined;
      });
    }

    if (obj && arr.length) {
      return [
        {
          value: obj?.value ? obj?.value : obj?.name,
          label: obj?.label ? obj?.label : obj?.name,
          color: obj?.color ? obj?.color : "#ffffff",
        },
        ...arr,
      ];
    }
    if (obj && !arr.length) {
      return [
        {
          value: obj?.value ? obj?.value : obj?.name,
          label: obj?.label ? obj?.label : obj?.name,
          color: obj?.color ? obj?.color : "#ffffff",
        },
      ];
    }

    if (arr.length && !obj) {
      return arr;
    }
    return undefined;
  };

  const setOptionsType = (type) => {
    switch (type) {
      case 0:
        return [...categories, ...tagOptions];
      case 1:
        return [...buildings, ...tagOptions];
      case 2:
        return [...floors, ...tagOptions];
      case 3:
        return [...workphasesType, ...tagOptions];
      case 7:
      case 8:
        return tagOptions;
      case 9:
        return [...documentStatuses, ...tagOptions];
      default:
        return [];
    }
  };

  const onDrop = (originalIndex) => {
    if (schema.groups[originalIndex] && schema.groups[targetId]) {
      setGroups(schema.groups);
    }
  };

  const findCard = (id) => schema.groups.findIndex((group, index) => group[index] === id);

  const customElement = [
    {
      abbreviation: "",
      label: "",
      name: "",
      value: "",
    },
  ];

  const handleChangeGroup = ({
    type,
    group,
    groupName,
    currentIndex,
    name,
    element,
    elemIndex,
    elemValue,
    date,
    separator,
    metaId,
    value,
    isOptional,
    excludeFromCleanName,
    length,
  }) => {
    const objIndex = schema.groups.findIndex((g, i) => g && i === currentIndex);
    let groupType = [];

    const groupNum = Number(group);

    switch (groupNum) {
      case EncodingHelper.groupTypes.disciplines:
        groupType = categories;
        break;
      case EncodingHelper.groupTypes.building:
        groupType = buildings;
        break;
      case EncodingHelper.groupTypes.floor:
        groupType = floors;
        break;
      case EncodingHelper.groupTypes.workPhases:
        groupType = workphasesType;
        break;
      case EncodingHelper.groupTypes.project:
        groupType = projectElement;
        break;
      case EncodingHelper.groupTypes.custom:
        groupType = customElement;
        break;
      case EncodingHelper.groupTypes.docStatus:
        groupType = documentStatuses;
        break;
      default:
        break;
    }

    groupType = groupType.map((gt) => ({ metaId: gt.id, ...gt }));

    const updatedObjName = {
      ...schema.groups[objIndex],
      name: groupName || "",
      id: currentIndex,
      separator: schema.groups[objIndex].separator >= 0
        ? schema.groups[objIndex].separator
        : Number(separationValue.value),
    };

    const updatedSeparator = {
      ...schema.groups[objIndex],
      separator:
        separator !== undefined
          ? Number(separator)
          : schema.groups[objIndex].separator,
      id: currentIndex,
    };

    const updatedDate = {
      [name]: Number(elemValue),
      abbreviation: String(date),
    };

    const updateExcludeFromCleanName = {
      ...schema.groups[objIndex],
      excludeFromCleanName:
      excludeFromCleanName !== undefined
        ? excludeFromCleanName
        : schema.groups[objIndex].excludeFromCleanName,
      id: currentIndex,
    };

    const updateLength = {
      ...schema.groups[objIndex],
      length: length !== undefined ? length : schema.groups[objIndex].length,
      id: currentIndex,
    };

    const groupElement = schema.groups[objIndex].groupElements[elemIndex];
    const updatedVersionNumber = {
      ...groupElement,
      [name]: elemValue,
      abbreviation: (groupElement?.versionAsLetter ? "A" : "0").repeat(Number(elemValue)),
    };

    const updatedVersionLetters = {
      ...groupElement,
      versionAsLetter: name === "letters",
      abbreviation:
        name === "letters"
          ? (groupElement?.abbreviation || "").replaceAll("0", "A")
          : (groupElement?.abbreviation || "").replaceAll("A", "0"),
    };

    let meta = [];
    let tag = [];

    if (Array.isArray(value)) {
      meta = value.find((item) => item.metaId)?.metaId;
      tag = value
        .filter((item) => !item.metaId && item.tagId)
        .map((item) => item.value);
    } else {
      tag = tagOptions.find((item) => item.value === value?.tagId)?.tagId;
      meta = value?.metaId;
    }

    const updatedMetaId = {
      ...schema.groups[objIndex].groupElements[elemIndex],
      [name]: elemValue,
      metaId: tag?.length !== schema.groups[objIndex].groupElements[elemIndex]?.tagIds?.length
        ? schema.groups[objIndex].groupElements[elemIndex]?.metaId
        : meta,
      tagIds: Array.isArray(tag) ? [...tag] : [],
    };

    const forceIsOptionalFalse = groupNum !== EncodingHelper.groupTypes.version && groupNum !== EncodingHelper.groupTypes.creationDate;
    const updatedObj = {
      ...schema.groups[objIndex],
      type: type ? Number(type) : Number(schema.groups[objIndex].type),
      separator: separator ? Number(separator) : Number(schema.groups[objIndex].separator),
      id: currentIndex,
      isOptional: forceIsOptionalFalse
        ? false
        : schema.groups[objIndex].isOptional,
      groupElements: groupType.length ? [...groupType] : [],
    };

    // actually there is no API limit for the abbreviation, but the client layout gets
    // destroyed when the abbreviation gets to big
    if (name === "abbreviation" && elemValue.length > 30) {
      return setApiError(t("scheme_abbreviation_to_long", "abbreviation length cannot be bigger than 30 character "));
    }

    const getObject = () => {
      if (date) return updatedDate;
      if (name === "length") return updatedVersionNumber;
      if (name === "letters" || name === "numbers") return updatedVersionLetters;
      if (metaId) return updatedMetaId;
      return {
        ...schema.groups[objIndex].groupElements[elemIndex],
        [name]: elemValue,
        id: currentIndex,
      };
    };

    const updatedIsOptional = {
      ...schema.groups[objIndex],
      isOptional:
        name === "optional" ? isOptional : schema.groups[objIndex].isOptional,
    };

    let updatedElement = {
      ...schema.groups[objIndex],
      groupElements: [
        ...schema.groups[objIndex].groupElements.slice(0, elemIndex),
        getObject(),
        ...schema.groups[objIndex].groupElements.slice(elemIndex + 1),
      ],
      isOptional:
        name === "optional" ? isOptional : schema.groups[objIndex].isOptional,
    };

    if (name === "abbreviation" && elemValue.length <= 5) {
      setError({ ...error, [objIndex]: "" });

      updatedElement = {
        ...schema.groups[objIndex],
        groupElements: [...updatedElement.groupElements],
      };
    }

    const addElement = {
      ...schema.groups[objIndex],
      groupElements: [...schema.groups[objIndex].groupElements, element],
    };

    const action = () => {
      if (element) return addElement;
      if (type) return updatedObj;
      if (name === "optional") return updatedIsOptional;
      if (name) return updatedElement;
      if (separator) return updatedSeparator;
      if (excludeFromCleanName !== undefined) return updateExcludeFromCleanName;
      if (length) return updateLength;
      if (groupName !== undefined) return updatedObjName;
      return undefined;
    };

    setGroups({
      ...schema,
      groups: [
        ...schema.groups.slice(0, objIndex),
        action(),
        ...schema.groups.slice(objIndex + 1),
      ],
    });
    setIsEnableSave(true);
  };

  const handeleChangeType = ({ currentIndex, valueGroup }) => {
    setGroupValue([
      ...groupValue.slice(0, currentIndex),
      {
        ...valueGroup,
      },
      ...groupValue.slice(currentIndex + 1),
    ]);
  };

  const handleDelete = ({ elemIndex, currentIndex }) => {
    if (elemIndex === undefined) {
      const filtredGroups = schema.groups.filter((group, index) => group && index !== groupIndex);
      const filterGroupValue = groupValue.filter((group, index) => group && index !== groupIndex);

      return (
        setIsEnableSave(true),
        setShowModal(false),
        setGroupValue([...filterGroupValue]),
        setGroups({ ...schema, groups: [...filtredGroups] })
      );
    }

    const objIndex = schema.groups.findIndex((group, index) => group && index === currentIndex);

    const filter = schema.groups[objIndex].groupElements.filter((elem, index) => elem && index !== elemIndex);

    const deleteElement = {
      ...schema.groups[objIndex],
      groupElements: [...filter],
    };

    return (
      setGroups({
        ...schema,
        groups: [
          ...schema.groups.slice(0, objIndex),
          deleteElement,
          ...schema.groups.slice(objIndex + 1),
        ],
      }),
      setIsEnableSave(true)
    );
  };

  const filtredVisoplanGroups = () => {
    const groupsTypes = schema.groups
      .filter((group) => String(group.type))
      .map((group) => group.type);

    return visoplanGroups.filter((group) => {
      if (Number(group.value) === EncodingHelper.groupTypes.custom) return group;
      return !groupsTypes.includes(Number(group.value));
    });
  };

  const addSeparator = (group, index) => {
    if (schema.groups.length !== index + 1) {
      if (Number(group.type) === EncodingHelper.groupTypes.freeText) {
        return "B01"
          ?.split("")
          .concat(separatorsTypes[Number(group.separator)]?.label);
      }
      return group.groupElements[0].abbreviation
        ?.split("")
        .concat(separatorsTypes[Number(group.separator)]?.label);
    }
    if (Number(group.type) === EncodingHelper.groupTypes.freeText) {
      return "B01"
        ?.split("")
        .concat(separatorsTypes[Number(group.separator)]?.label);
    }
    return group.groupElements[0].abbreviation?.split("");
  };

  //save reference for dragItem and dragOverItem
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  //handle drag sort
  const handleSort = () => {
    // duplicate items
    let _schemaGroups = [...schema.groups];

    // remove and saved dragged items content
    const draggedItemContent = _schemaGroups.splice(dragItem.current, 1)[0];

    // switch the position
    _schemaGroups.splice(dragOverItem.current, 0, draggedItemContent);

    // reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;

    //update the actual array
    setGroups({ ...schema, groups: [..._schemaGroups] });
    setIsEnableSave(true);
  }

  return (
    <div className="project-encoding__groups">
      {schema.groups?.map((group, index) => (
        <div
          className="table"
          key={`group-${index}`}
          style={{
            pointerEvents: !currentUserRole?.allowedActions?.has(RoleAction.NamingScheme_Create_Edit) ? "none" : "",
          }}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ cursor: 'move', marginTop: '30px' }}
              className="documents-table-drag-handler"
              draggable
              onDragStart={() => dragItem.current = index}
              onDragEnter={() => dragOverItem.current = index}
              onDragEnd={handleSort}
              onDragOver={(e) => e.preventDefault()}
              >
              {getGeneralSvg("drag-handler")}
            </div>
            <div>
              <div className="group-title">
                <span className="column__title ml-20">{`${t("group", "Group")} ${index + 1}`}</span>
                <span className="column__title ml-55">
                  {t("visoplan_group", "Visoplan Group")}
                </span>
                <span className="column__title ml-45">
                  {" "}
                  {t("separation", "Separation")}
                </span>
              </div>
              <GroupName
                handleChangeGroup={handleChangeGroup}
                index={index}
                group={group}
                visoplanGroups={visoplanGroups}
                defaultValue={defaultValue}
                handeleChangeType={handeleChangeType}
                separatorsTypes={separatorsTypes}
                setGroupIndex={setGroupIndex}
                setShowModal={setShowModal}
                setGroupValue={setGroupValue}
                groupValue={groupValue}
                setTargetId={setTargetId}
                onDrop={onDrop}
                findCard={findCard}
                separationValue={separationValue}
                setSeparationValue={setSeparationValue}
                filtredVisoplanGroups={filtredVisoplanGroups}
              />
              {group.name && String(group.separator) ? (
                <>
                  <div className="group-example">
                    {group.groupElements.length ||
                      Number(group.type) === EncodingHelper.groupTypes.freeText
                      ? addSeparator(group, index)?.map((elem, index) => (
                        <div key={`group-example-${index}`} className="example">
                          <span>{index + 1}</span>
                          <h3>{elem}</h3>
                        </div>
                      ))
                      : ""}
                  </div>
                  <GroupElement
                    handleChangeGroup={handleChangeGroup}
                    index={index}
                    group={group}
                    visoplanGroups={visoplanGroups}
                    defaultValue={defaultValue}
                    dateTypes={dateTypes}
                    versionLenght={versionLenght}
                    setOptionsType={setOptionsType}
                    groupValue={groupValue}
                    getValue={getValue}
                    handleDelete={handleDelete}
                    category={category}
                    error={error}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ))}
      {showModal ? (
        <ConfirmModal
          title={`${t("delete")} ${t("group")}`}
          confirmText={t(
            "confirm_delete_group",
            "Are you sure you want to delete this group?"
          )}
          onYes={(e) => handleDelete(groupIndex ? groupIndex : e)}
          onNo={() => setShowModal(false)}
          yesText={t("yes", "Yes")}
          noText={t("no", "No")}
        />
      ) : (
        ""
      )}
    </div>
  );
};
