import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import UserUpdateDto from 'users/types/UserUpdateDto';
import ErrorResponseData from 'api/types/ErrorResponseData';
import User from 'users/types/User';

export default function useCurrentUserMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (dto: UserUpdateDto) => {
      await axiosInstance.put<undefined | ErrorResponseData>('/user/current', dto);
    },
    {
      onMutate: async (dto: UserUpdateDto) => {
        const previousCurrentUser = queryClient.getQueryData<User>(['user', 'current']);
        const optimisticallyUpdatedUser = { ...previousCurrentUser };
        optimisticallyUpdatedUser.langType = dto.langType;
        queryClient.setQueryData(['user', 'current'], optimisticallyUpdatedUser);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['user', 'current']);
      },
    },
  );
}
