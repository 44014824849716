import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import IssuesSortButtonMenu from 'issues/components/IssuesSortButtonMenu';
import { useTranslation } from 'react-i18next';
import useHardcodedIssueFilterName from 'issues/hooks/useHardcodedIssueFilterName';
import HardcodedIssueFilter from 'issues/types/HardcodedIssueFilter';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import { debounce } from 'lodash';

interface IssueQuickFilterBarProps extends ISxProps {
}

export default function IssueQuickFilterBar({
  sx,
}: IssueQuickFilterBarProps) {
  const { t } = useTranslation('issues');
  const { setHardcodedFilter, quickFilter, setQuickFilter, hardcodedFilter } = useIssuesFilterContext();
  const getHardcodedIssueFilterName = useHardcodedIssueFilterName();
  const [quickFilterDisplayValue, setQuickFilterDisplayValue] = useState<string>(quickFilter);
  const debouncedsSetTitleFilter = useMemo(() => debounce((value: string) => {
    setQuickFilter(value);
  }, 600), [setQuickFilter]);
  const onChangeTitleFilter = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setQuickFilterDisplayValue(event.target.value);
    debouncedsSetTitleFilter(event.target.value);
  }, [debouncedsSetTitleFilter]);
  const onChangeIssueFilterSelect = useCallback((event: SelectChangeEvent<HardcodedIssueFilter>) => {
    setHardcodedFilter(event.target.value as HardcodedIssueFilter);
  }, [setHardcodedFilter]);
  return (
    <Box id="IssueQuickFilterBar" sx={{ ...sx, display: 'flex', gap: 1 }}>
      <TextField
        label={t('linked-issues-tab_title-filter-textfield-label', 'Filter')}
        value={quickFilterDisplayValue}
        onChange={onChangeTitleFilter}
        sx={{ flex: '1 1 0', minWidth: 100 }}
      />
      <FormControl sx={{ flex: '1 1 0', minWidth: 100, maxWidth: 180 }}>
        <InputLabel id="linked-issues-tab_filter-select-label">
          {t('linked-issues-tab_filter-select-label', 'Filter')}
        </InputLabel>
        <Select
          id="linked-issues-tab_filter-select"
          value={hardcodedFilter}
          onChange={onChangeIssueFilterSelect}
          label={t('linked-issues-tab_filter-select-label', 'Filter')}
        >
          {Object.values(HardcodedIssueFilter).map((v) => <MenuItem key={v} value={v}>{getHardcodedIssueFilterName(v)}</MenuItem>)}
        </Select>
      </FormControl>
      <IssuesSortButtonMenu sx={{ flex: '0 0 0' }} />
    </Box>
  );
}
