import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Alert, CircularProgress, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import useInboxEmailUpdateMutation from 'emails/hooks/useInboxEmailUpdateMutation';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import RenameInboxDto from 'emails/types/RenameInboxDto';

interface ChangeProjectInboxEmailDialogProps {
  onClose: () => void,
}

const INBOX_EMAIL_ADDRESS_DOMAIN = 'inbox.visoplan.de';

export default function ChangeProjectInboxEmailDialog({
  onClose,
}: ChangeProjectInboxEmailDialogProps) {
  const { t } = useTranslation('settings');
  const { data: currentProject } = useCurrentProjectQuery();
  const [newInboxName, setNewInboxName] = useState<string>('');
  const onChangeNewEmailAddress = useCallback((e: ChangeEvent<HTMLInputElement>) => setNewInboxName(e.target.value), []);
  const { mutateAsync, isLoading } = useInboxEmailUpdateMutation();
  const canConfirm = useMemo(() => !!currentProject && newInboxName?.length && !isLoading, [currentProject, isLoading, newInboxName?.length]);
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);

  const onCloseSuccessMessage = useCallback(() => onClose(), [onClose]);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const onClickConfirm = useCallback(async () => {
    if (!currentProject || !newInboxName) return;
    const renameInbox: RenameInboxDto = {
      name: newInboxName,
    };
    try {
      await mutateAsync(renameInbox);
      setIsSuccessMessageVisible(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [currentProject, getRequestErrorMessage, mutateAsync, newInboxName]);
  const onClickCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    setNewInboxName((prev) => {
      if (!currentProject || prev) return prev;
      return currentProject.inboxEmailAddress?.split('@')?.[0] ?? '';
    });
  }, [currentProject]);

  return (
    <>
      <Dialog open id="ChangeProjectInboxEmailDialog">
        <DialogTitle>{t('change-project-inbox-email-dialog_header', 'Change Project Email Address')}</DialogTitle>
        <DialogContent sx={{ display: 'flex', gap: 2, minWidth: 600, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <TextField value={newInboxName} onChange={onChangeNewEmailAddress} sx={{ mt: 1, flexGrow: 1 }} label={t('change-project-inbox-email-dialog_new-inbox-name-text-field-label', 'New Project Email Address')} />
            <Typography sx={{ fontWeight: 600 }}>{`@${INBOX_EMAIL_ADDRESS_DOMAIN}`}</Typography>
          </Box>
          {!!errorMessage && (
            <Alert severity="error" sx={{ mt: 2 }} onClose={onCloseErrorMessage}>
              {errorMessage}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickCancel} variant="contained" color="secondary" sx={{ mr: 'auto' }}>
            {t('change-project-inbox-email-dialog_cancel-button-label', 'Cancel')}
          </Button>
          <Button disabled={!canConfirm} onClick={onClickConfirm} variant="contained" color="primary">
            {isLoading && <CircularProgress sx={{ mr: 1 }} size={12} />}
            {t('change-project-inbox-email-dialog_confirm-button-label', 'Save')}
          </Button>
        </DialogActions>
      </Dialog>
      {!!isSuccessMessageVisible && (
      <Dialog open onClose={onCloseSuccessMessage}>
        <DialogTitle>{t('change-project-inbox-email-dialog_success-dialog-title', 'Project Email Address Changed')}</DialogTitle>
        <DialogContent>
          <Alert severity="success">
            {t('change-project-inbox-email-dialog_success-dialog-text', 'The project email address was changed')}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onCloseSuccessMessage}>{t('change-project-inbox-email-dialog_success-dialog-confirm-button-label', 'Close')}</Button>
        </DialogActions>
      </Dialog>
      )}
    </>
  );
}
