import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { sortBy, uniq } from "lodash";

// Helpers
import * as Request from "../../Helpers/Request";
import * as Form from "../../Helpers/Form/Form";
import { RestActions, ESCAPE_KEY_CODE } from "../../Helpers/Common";
import { MetadataService, EmailService, ViewpointService } from "../../Services";
import { getDisciplineColor, getIssueLogs } from "../../Services/IssueService";
import { getNameLinkMapParams } from "../../Helpers/utils";
import { useAnnotation, useDocumentLogs } from "../../Helpers/Hooks";

// Components
import AsideIssueViewpoint from "../Aside/AsideIssue/AsideIssueViewpoint";
import OpenWindowsClientLink from "../OpenWindowsClientLink/OpenWindowsClientLink";
import LinkedDocumentItem from "./LinkedDocumentItem";
import DocumentLogDialog from "documents/components/DocumentLogDialog";
import { Modal } from "../../HOC";
import TagSelect from "../TagSelect";
import CustomSelect from "../CustomSelect";
import { CopyLink, CloseButton } from "../Buttons";
import LinkedTasks from "./LinkedTasks";
import IssueComments from "./IssueComments";
import IssueBoardColumnHeaderOld from "./IssueBoardColumnHeaderOld";
import AssignItem from "./AssignItem";
import AsideEmailDetail from "../Aside/AsideEmailDetail";
import MentionView from "../MentionView";
import useCurrentUserQuery from "users/hooks/useCurrentUserQuery";
import useCurrentUserRole from "users/hooks/useCurrentUserRole";
import RoleAction from "projects/types/RoleAction";

import { Portal } from '@mui/material';
import DocumentScopeContextMemoryProvider from 'documents/contexts/DocumentScopeContextMemoryProvider';
import useDocumentScopeContext from 'documents/hooks/useDocumentScopeContext';
import DocumentDetailsModal from 'documents-details/components/DocumentDetailsModal';

// New Version is called IssueDetailsModal
const IssueOverviewModalOld = ({
  canEditIssue,
  show_aside,
  users,
  categories: disciplines,
  buildings,
  floors,
  statuses,
  types,
  priorities,
  workphases,
  issue,
  tagOptions,
  toggleAside,
  updateStatusCallback,
  updateTag,
  baseIssuesUrl,
  allDocuments,
  allIssues,
  setSelectedPreviewDoc,
  setCurrentIssue,
  setIsDocLog,
  onCloseModalOverride,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUserRole = useCurrentUserRole();
  const { data: user } = useCurrentUserQuery();
  const [comments, setComments] = useState([]);
  const [viewpoints, setViewpoints] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [linkedEmails, setLinkedEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [emailPreviewModal, setEmailPreviewModal] = useState(false);
  const [status, setStatus] = useState(0);
  const [isExpanded, setExpanded] = useState({
    explanation: true,
    assigned: true,
    model: true,
    viewpoint: true,
    bimModel: false,
    elements: false,
  });
  const [isVisibleHistory, setToggleHistory] = useState(false);
  const [allIssueLogs, setAllIssueLogs] = useState([]);
  const { issueLogs, setIssueLogs } = useDocumentLogs({
    allIssueLogs,
    allDocuments,
  });
  const [wholeIssueLog, setWholeIssueLog] = useState({});
  const [tags, setTags] = useState([]);
  const [issueDisciplines, setIssueDisciplines] = useState([]);
  const [issueBuildings, setIssueBuildings] = useState([]);
  const [issueFloors, setIssueFloors] = useState([]);
  const [inputComment, setInputComment] = useState("");
  const { projectId } = useParams();
  const { pdfAnnotations } = useAnnotation({ issueId: issue?.id });

  const canEditTag = useMemo(() => {
    if (issue) {
      if (issue?.issueStatus?.originalName === "Closed") return false;
      return currentUserRole?.allowedActions?.has(RoleAction.Tag_Create_Update);
    }
  }, [currentUserRole, issue]);

  const handleChangeExpand = useCallback(
    (key) => {
      setExpanded({
        ...isExpanded,
        [key]: !isExpanded[key],
      });
    },
    [isExpanded]
  );

  const getMetaData = useCallback(async (localIssue) => {
    let ids = uniq(
      (localIssue.disciplineMetaDataIds || []).concat(
        localIssue.customDisciplineMetaDataIds || []
      )
    );
    const displineResponses =
      ids.length > 0 ? await MetadataService.getMetadataDiscipline(ids) : [];

    ids = uniq(
      (localIssue.buildingMetaDataIds || []).concat(
        localIssue.customBuildingMetaDataIds || []
      )
    );
    const buildingResponses =
      ids.length > 0 ? await MetadataService.getMetadataBuilding(ids) : [];

    ids = uniq(
      (localIssue.floorMetaDataIds || []).concat(
        localIssue.customFloorMetaDataIds || []
      )
    );
    const floorResponses =
      ids.length > 0 ? await MetadataService.getMetadataFloor(ids) : [];

    setIssueDisciplines(displineResponses);
    setIssueBuildings(buildingResponses);
    setIssueFloors(floorResponses);
  }, []);

  useEffect(() => {
    if (!tagOptions || !issue) return setTags([]);
    getMetaData(issue);
    setTags(tagOptions.filter((item) => issue.tagIds.includes(item.value)));
  }, [tagOptions, issue]);

  const handleSelectTags = useCallback(
    (item, issueId) => {
      updateTag(item, issueId);
      setTags(item);
    },
    [updateTag]
  );

  const toggleHistory = (event, show) => {
    event.stopPropagation();
    setToggleHistory(show);
  };

  useEffect(() => {
    const effect = async () => {
      if (!issue?.linkedEmailIds?.length || !projectId) {
        setLinkedEmails([]);
        return;
      }
      const allEmails = await EmailService.getEmailsByProjectId(projectId);
      if (allEmails) {
        setLinkedEmails(
          allEmails.filter((item) => issue.linkedEmailIds.includes(item.id))
        );
      }
    };
    effect();
  }, [issue, projectId]);

  useEffect(() => {
    const effect = async () => {
      if (!issue) return;
      setDocuments(
        sortBy(
          issue.linkedDocumentVersionIds
            .map((id) => allDocuments.find((d) => d.id === id))
            .filter((d) => !!d),
          "name"
        )
      );
      setAllIssueLogs([]);
      setIssueLogs([]);
    };
    effect();
  }, [issue, allDocuments]);

  const canEditIssueStatus = useMemo(() => {
    if (issue) {
      if (issue?.issueStatus?.originalName === "Closed")
        return currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_StatusEditing_UnsetClosed);
      if (status?.originalName === "Closed")
        return currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_StatusEditing_SetClosed);
      if (currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_StatusEditing)) return true;
      if (
        issue["createAuthorId"] == user["id"] &&
        currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_Creator_StatusEditing)
      )
        return true;
      if (
        issue["reviewerId"] == user["id"] &&
        currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_Reviewer_StatusEditing)
      )
        return true;
      if (
        issue["assignedUserIds"].includes(user["id"]) &&
        currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_Assigned_StatusEditing)
      )
        return true;
    }
    return false;
  }, [currentUserRole, status, issue]);

  useEffect(() => {
    const initViewpoints = async () => {
      const { viewpointIds } = issue;

      if (!viewpointIds?.length) {
        setViewpoints([]);
      } else {
        const viewpointBuffer = viewpointIds?.length
          ? await ViewpointService.getViewpointsByIds(viewpointIds)
          : [];
  
        getViewpoints(viewpointBuffer);
      }
    };
    const initComments = async () => {
      const { commentIds } = issue;

      if (!commentIds?.length) {
        setComments([]);
      } else {
        Request.GET(`api/comment/${commentIds}`).then(
          (result) => result.status !== 200 || setComments(result.body)
        );
      }
    };

    const initIssueLogs = async () => {
      if (!issue.issueLogId) return;

      const logs = await getIssueLogs([issue.issueLogId]);
      if (logs?.length)
        setWholeIssueLog(logs[0]);
    }

    if (!issue) return;

    initViewpoints();
    initComments();
    initIssueLogs();
  }, [issue]);

  useEffect(() => {
    if (!statuses || !issue?.issueStatus) return;
    setStatus(statuses.find((item) => item.value === issue.issueStatus?.id));
  }, [statuses, issue?.issueStatus]);

  const getViewpoints = (viewpoints) => {
    let array_to_load = [],
      blob_images = [];

    viewpoints.map((item) => {
      if (item?.imageName) {
        array_to_load.push(
          Request.GET(
            `api/resource/${item?.thumbnailImageId || item?.imageId}`
          ).then(
            (result) => result.status !== 200 || blob_images.push(result.body)
          )
        );
      }
    });

    return Promise.all(array_to_load).then(() => {
      let loaded_images = [];

      blob_images.map((image) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onloadend = () => {
          loaded_images.push(reader.result);

          if (loaded_images.length === blob_images.length) {
            setViewpoints(loaded_images);
          }
        };
      });
    });
  };

  const updateStatus = (status) => {
    if (
      status.id !== issue?.issueStatus?.id &&
      (currentUserRole.isAdmin || status.originalName !== "Closed")
    ) {
      return Request.PUT(`api/issue`, [
        {
          id: issue.id,
          statusId: { value: status.value },
        },
      ]).then((result) => {
        if (result.status === 200) {
          setStatus(status);
          if (updateStatusCallback) updateStatusCallback();
        }
      });
    }
  };

  const addComment = (e) => {
    e.preventDefault();

    Request.POST(`api/comment`, [
      {
        timeStamp: new Date(),
        issueId: { value: issue["id"] },
        text: { value: inputComment },
        nameLinkMap: getNameLinkMapParams(inputComment, users),
      },
    ]).then((result) => {
      if (result.status === 200) {
        let buffer = Object.assign([], comments);
        buffer.push(result.body[0]);
        setComments(buffer);

        setInputComment("");
        return;
      }

      Form.showErrors(
        {
          comment: t(
            "internal_error",
            "An error occurred, please try again later"
          ),
        },
        e.target.id
      );
    });
  };

  const handleCommentChange = (e) => {
    setInputComment(e.target.value);
  };

  const canOpenLiveView = useMemo(() => {
    return (
      currentUserRole?.allowedActions?.has(RoleAction.MainMenu_LiveView) &&
      (!!viewpoints?.length || !!issue?.linkedComponentsGlobalIds?.length)
    );
  }, [viewpoints, issue, currentUserRole]);

  const onCloseModal = () => {
    toggleAside("aside");
    if (onCloseModalOverride) {
      onCloseModalOverride();
    } else {
      if (baseIssuesUrl) navigate(`${baseIssuesUrl}`);
    }
  }

  const handleKeyDown = (event) => {
    switch(event.keyCode) {
      case ESCAPE_KEY_CODE:
        onCloseModal();
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
  }, [show_aside]);
  
  if (!show_aside || !issue) {
    return <></>;
  }

  return (
    <DocumentScopeContextMemoryProvider>
      <DocumentDetailsModalWrapper />
        <Modal className="issues-overview-modal">
          <div className="issues-overview-modal--wrapper">
            <div className="issues-overview-modal--top-header">
              <div className="issues-overview-modal--top-header_left">
                {canEditIssue && (
                  <button
                    className="btn btn--secondary"
                    onClick={() => {
                      toggleAside("aside_edit", true);
                      navigate(`${baseIssuesUrl}${issue.id}/edit`);
                    }}
                  >
                    {t("edit_settings", "Edit Issue")}
                  </button>
                )}
                <div className="issues-overview-modal--tags mobile--hide">
                  <TagSelect
                    values={tagOptions}
                    onChange={(item) => handleSelectTags(item, issue.id)}
                    value={tags}
                    isBackgroundVisible={tags.length > 0 ? false : true}
                    visibleValueCount={2}
                    isDisabled={!canEditTag}
                  />
                </div>
                {canOpenLiveView && (
                  <OpenWindowsClientLink issueId={issue?.id} view="live">
                    <button className="btn btn--primary mobile--hide">
                      {t("launch_3d_view", "Launch 3D-View")}
                    </button>
                  </OpenWindowsClientLink>
                )}
              </div>
              <div className="issues-overview-modal--top-header_right">
                <p className="issue-source">
                  <span>{`${t("source", "Source")}: `}</span>
                  {issue.source}
                </p>
                <CustomSelect
                  values={statuses}
                  onChange={updateStatus}
                  value={status}
                  isStatus={true}
                  isDisabled={!canEditIssueStatus}
                  isModel={true}
                />
                <CopyLink issueId={issue?.id} />
                <CloseButton
                  onClick={onCloseModal}
                  topPosition={"10px"}
                  rightPosition={"20px"}
                />
              </div>
            </div>
            <IssueBoardColumnHeaderOld
              issue={issue}
              priorities={priorities}
              types={types}
              workphases={workphases}
            />
            <div className="issues-overview-modal--content">
              <div className="issues-overview-modal--content_left custom-scrollbar">
                <div className="issue-explanation issue-content-column">
                  <div
                    className="accordion-title"
                    onClick={() => handleChangeExpand("explanation")}
                  >
                    <h3>{t("explanation", "Explanation")}</h3>
                    <i
                      className={`arrow ${
                        isExpanded.explanation ? "arrow-up" : "arrow-down"
                      }`}
                    />
                  </div>
                  {isExpanded.explanation && !!issue.description && (
                    <MentionView value={issue.description} isRichText={true} />
                  )}
                </div>
                <div className="issue-model issue-content-column">
                  <div
                    className="accordion-title"
                    onClick={() => handleChangeExpand("model")}
                  >
                    <h3>{t("model", "Model")}</h3>
                    <i
                      className={`arrow ${
                        isExpanded.model ? "arrow-up" : "arrow-down"
                      }`}
                    />
                  </div>
                  {isExpanded.model && (
                    <div className="accordion-content issue-model-content">
                      <div className="width--30">
                        {!!issueDisciplines?.length && (
                          <>
                            <p className="accordion-content_title">
                              {t("discipline", "Discipline")}
                            </p>
                            {issueDisciplines.map((item, index) => (
                              <div
                                key={`discipline-${index}`}
                                className="label"
                                style={{
                                  backgroundColor: `${getDisciplineColor(
                                    index
                                  )}33`,
                                  color: getDisciplineColor(index),
                                }}
                              >
                                {item.abbreviation || ""}
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      <div className="width--30">
                        {!!issueBuildings?.length && (
                          <>
                            <p className="accordion-content_title">
                              {t("building", "Building")}
                            </p>
                            {issueBuildings.map((item, index) => (
                              <div
                                key={`building-${index}`}
                                className="label bg-light-grey"
                              >
                                {item.abbreviation || ""}
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      <div className="width--30">
                        {!!issueFloors?.length && (
                          <>
                            <p className="accordion-content_title">
                              {t("floor", "Storey")}
                            </p>
                            {issueFloors.map((item, index) => (
                              <div
                                key={`floor-${index}`}
                                className="label bg-light-grey"
                              >
                                {item.abbreviation || ""}
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="issue-assigned issue-content-column">
                  <div
                    className="accordion-title"
                    onClick={() => handleChangeExpand("assigned")}
                  >
                    <h3 className="width--30">
                      {t("assigned_to", "Assigned to")}
                    </h3>
                    <p className="width--60 accordion-title_label">{`${
                      issue.assignedUsers?.length || 0
                    } ${t("collaborators", "Collaborators")}`}</p>
                    <i
                      className={`arrow ${
                        isExpanded.assigned ? "arrow-up" : "arrow-down"
                      }`}
                    />
                  </div>
                  {isExpanded.assigned && (
                    <>
                      <div className="accordion-inner-title">
                        <p className="width--30">{t("responsible_worker")}</p>
                        <p className="width--60">{t("reviewer", "Reviewer")}</p>
                        <div className="rest-space" />
                      </div>
                      <AssignItem issue={issue} />
                    </>
                  )}
                </div>
                <div className="issue-viewpoint issue-content-column">
                  <div
                    className="accordion-title"
                    onClick={() => handleChangeExpand("viewpoint")}
                  >
                    <h3 className="width--30">{t("viewpoint", "Viewpoint")}</h3>
                    <p className="width--60 accordion-title_label">
                      {`${viewpoints?.length || 0} ${t(
                        "viewpoints",
                        "Viewpoints"
                      )}`}
                    </p>
                    <i
                      className={`arrow ${
                        isExpanded.viewpoint ? "arrow-up" : "arrow-down"
                      }`}
                    />
                  </div>
                  {isExpanded.viewpoint && !!viewpoints?.length && (
                    <div className="accordion-content issue-viewpoint-content">
                      {viewpoints.map((item, index) => (
                        <AsideIssueViewpoint
                          key={`viewpoint-${index}`}
                          image={item}
                        />
                      ))}
                    </div>
                  )}
                </div>
                <div className="issue-bim-model issue-content-column">
                  <div
                    className="accordion-title"
                    onClick={() => handleChangeExpand("bimModel")}
                  >
                    <h3 className="width--30">{t("bim_models", "BIM-Models")}</h3>
                    <p className="width--60 accordion-title_label">
                      {`${issue.models?.length || 0} ${t(
                        "bim_models",
                        "BIM-Models"
                      )}`}
                    </p>
                    <i
                      className={`arrow ${
                        isExpanded.bimModel ? "arrow-up" : "arrow-down"
                      }`}
                    />
                  </div>
                  {isExpanded.bimModel && !!issue?.models?.length && (
                    <div className="accordion-content issue-bim-model-content">
                      {issue.models.map((item, index) => (
                        <p key={`bim-model-${index}`}>{item}</p>
                      ))}
                    </div>
                  )}
                </div>
                <div className="issue-elements issue-content-column">
                  <div
                    className="accordion-title"
                    onClick={() => handleChangeExpand("elements")}
                  >
                    <h3 className="width--30">{t("elements", "Elements")}</h3>
                    <p className="width--60 accordion-title_label">
                      {`${issue.linkedComponentsGlobalIds?.length || 0} ${t(
                        "elements",
                        "Elements"
                      )}`}
                    </p>
                    <i
                      className={`arrow ${
                        isExpanded.elements ? "arrow-up" : "arrow-down"
                      }`}
                    />
                  </div>
                  {isExpanded.elements &&
                    !!issue?.linkedComponentsGlobalIds?.length && (
                      <div className="accordion-content issue-bim-model-content">
                        {issue.linkedComponentsGlobalIds.map((item, index) => (
                          <p key={`element-${index}`}>{item}</p>
                        ))}
                      </div>
                    )}
                </div>
              </div>
              <div className="issues-overview-modal--content_right">
                <div className="issue-links">
                  <div className="issue-links-item">
                    <div className="accordion-title">
                      <h3 className="width--60">
                        {t("linked_documents", "Linked Documents")}
                      </h3>
                      <p className="width--30 accordion-title_label">
                        {`${documents?.length || 0} ${t(
                          "documents",
                          "Documents"
                        )}`}
                      </p>
                    </div>
                    <div className="accordion-content link-documents-content custom-scrollbar">
                      {documents.map((item, index) => (
                        <LinkedDocumentItem
                          key={`doc-${index}`}
                          issue={{ ...issue, issueLog: wholeIssueLog }}
                          document={item}
                          setAllIssueLogs={setAllIssueLogs}
                          setSelectedPreviewDoc={setSelectedPreviewDoc}
                          pdfAnnotations={pdfAnnotations}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="issue-links-item">
                    <div className="accordion-title">
                      <h3 className="width--60">
                        {t("linked_emails", "Linked Emails")}
                      </h3>
                      <p className="width--30 accordion-title_label">
                        {`${linkedEmails?.length || 0} ${t("emails", "Emails")}`}
                      </p>
                    </div>
                    <div className="accordion-content link-documents-content custom-scrollbar">
                      {linkedEmails.map((item, index) => (
                        <div className="link-email-item" key={`email-${index}`}>
                          <p
                            className="link-email-item_name text-ellipsis"
                            onClick={() => {
                              setEmailPreviewModal(
                                EmailService.AsideVisibleStatus.DetailVisible
                              );
                              setSelectedEmail(item);
                            }}
                          >
                            {item.subject}
                          </p>
                          <p className="link-email-item_sender text-ellipsis">
                            {`${t("send_by", "Send by")} ${item.from}`}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <LinkedTasks
                  issue={issue}
                  statuses={statuses}
                  types={types}
                  allIssues={allIssues}
                  setCurrentIssue={setCurrentIssue}
                />
                <IssueComments
                  issue={issue}
                  inputComment={inputComment}
                  comments={comments}
                  users={users}
                  disciplines={disciplines}
                  floors={floors}
                  buildings={buildings}
                  workphases={workphases}
                  allDocuments={allDocuments}
                  tagOptions={tagOptions}
                  isVisibleHistory={isVisibleHistory}
                  statuses={statuses}
                  priorities={priorities}
                  types={types}
                  allIssues={allIssues}
                  toggleHistory={toggleHistory}
                  handleCommentChange={handleCommentChange}
                  addComment={addComment}
                />
              </div>
            </div>
          </div>
        </Modal>
        {!!emailPreviewModal && (
          <div className="emails-content modal-tags">
            <div className="content">
              <Modal>
                <AsideEmailDetail
                  email={selectedEmail}
                  issues={allIssues}
                  show_aside={
                    emailPreviewModal ===
                    EmailService.AsideVisibleStatus.DetailVisible
                  }
                  statuses={statuses}
                  types={types}
                  setAsideVisibleStatus={setEmailPreviewModal}
                  isTagsSearch={true}
                  setSelectedIssue={setCurrentIssue}
                />
              </Modal>
            </div>
          </div>
        )}
        {!!issueLogs?.length && (
          <DocumentLogDialog
            logs={issueLogs}
            issue={issue}
            statuses={statuses}
            onClose={() => setIssueLogs([])}
            setSelectedPreviewDoc={setSelectedPreviewDoc}
            setIsDocLog={setIsDocLog}
          />
        )}
      </DocumentScopeContextMemoryProvider>
  );
};

IssueOverviewModalOld.propTypes = {
  show_aside: PropTypes.bool,
  issue: PropTypes.object,
  toggleAside: PropTypes.func,
  updateStatusCallback: PropTypes.func,
};

export default IssueOverviewModalOld;

function DocumentDetailsModalWrapper() {
  const { documentVersionId } = useDocumentScopeContext();
  return documentVersionId ? <Portal><DocumentDetailsModal /></Portal> : null;
}
