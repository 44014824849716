import React from "react";
import {withTranslation} from "react-i18next";

import * as Common from "../../Helpers/Common";

import LocalizedDatePicker from 'common/components/LocalizedDatePicker';
import CustomSelect from "../CustomSelect";
import TagOption from "../TagOption";
import TagSelect from "../TagSelect";
import TypeSelect from "../TypeSelect";
import SymbolTextInput from "../Inputs/SymbolTextInput";
import { formatDate } from "../../Helpers/format-date";

const IssueModalContent = ({
  t,
  editIssue,
  mode,
  editMarkStates,
  handleChange,
  issueTypes,
  handleSelectChange,
  type,
  name,
  explain,
  startingDate,
  dueDate,
  setStartingDate,
  changeEditMarkState,
  setDueDate,
  statusOptions,
  status,
  users = [],
  workers,
  reviewer,
  visibility,
  visibilityOptions,
  allowedUserGroups,
  priorities,
  priority,
  workphases = [],
  workphase,
  disciplines = [],
  discipline,
  buildings = [],
  building,
  floors = [],
  floor,
  isOverwriteTag,
  setIsOverwriteTag,
  tagOptions = [],
  tags,
  apiError,
  isMassEdit,
}) => {
  return (
    <div className={`issue-modal-content ${isMassEdit ? "mass-edit" : ""}`}>
      <div className="issue-modal-content--column">
        <>
          <h2 className="subtitle">
            {t("general_settings", "General Settings")}
          </h2>
          <div className="block">
            {!isMassEdit ? (
              <div className="content-item">
                <label className="required-field">
                  <p>{t("name", "Name")}</p>
                  {editMarkStates["name"] && (
                    <span className="edit-sign">{t("edit", "Edit")}</span>
                  )}
                </label>
                <input
                  id="IssueNameInput"
                  type="text"
                  name="input-name"
                  value={name}
                  onChange={handleChange}
                  placeholder={t("name_the_issue", "Name the issue")}
                  required
                />
              </div>
            ) : (
              <div className="content-item">
                <label>
                  {t("status", "Status")}
                  {editMarkStates["status"] && (
                    <span className="edit-sign">{t("edit", "Edit")}</span>
                  )}
                </label>
                <CustomSelect
                  id="IssueStatusDropdown"
                  values={statusOptions}
                  onChange={(value) => handleSelectChange(value, "status")}
                  isDisabled={false}
                  isOptional={true}
                  isModel={true}
                  value={status}
                  isMulti={false}
                />
              </div>
            )}
            <div className="content-item general-settings-type">
              <label className="required-field">
                <p>{t("type", "Type")}</p>
                {editMarkStates["type"] && (
                  <span className="edit-sign">{t("edit", "Edit")}</span>
                )}
              </label>
              <TypeSelect
                id="IssueTypeDropdown"
                values={issueTypes}
                isDisabled={false}
                onChange={(value) => handleSelectChange(value, "type")}
                value={type}
                isModel={true}
              />
            </div>
          </div>
          {!isMassEdit ? (
            <div
              className={`content-item ${
                !explain && apiError && mode !== Common.IssueModalMode.EDIT
                  ? "error-message"
                  : ""
              }`}
            >
              <label>
                <p>{t("explain", "Explain")}</p>
                {editMarkStates["explain"] && (
                  <span className="edit-sign">{t("edit", "Edit")}</span>
                )}
              </label>
              <SymbolTextInput
                id="IssueDescriptionInput"
                value={explain}
                onChange={handleChange}
                placeholder={t("explain_issue", "Description")}
              />
            </div>
          ) : (
            ""
          )}
          <h2 className="subtitle">{t("set_up_date", "Set up date")}</h2>
          <div className="content-item content-item--due-date block">
            <div className="content-item--start-date">
              <div className="date-picker-wrapper">
                <LocalizedDatePicker
                  id="IssueStartingDatePicker"
                  value={startingDate}
                  maxDate={dueDate}
                  onChange={(date) => {
                    setStartingDate(date);
                    if (mode === Common.IssueModalMode.EDIT && editIssue)
                      changeEditMarkState(date, "startingDate");
                  }}
                  label={t("start_date", "Start Date")}
                  sx={{ mt: 2 }}
                />
              </div>
            </div>
            <div className="content-item--end-date">
              <div className="date-picker-wrapper">
                <LocalizedDatePicker
                  id="IssueDueDatePicker"
                  value={dueDate}
                  minDate={startingDate}
                  onChange={(date) => {
                    setDueDate(formatDate(date));
                    if (mode === Common.IssueModalMode.EDIT && editIssue)
                      changeEditMarkState(date, "dueDate");
                  }}
                  label={t("due_date", "Due date")}
                  sx={{ mt: 2 }}
                />
              </div>
            </div>
          </div>
        </>
        <h2 className="subtitle">{t("assign_issue", "Assign Issue")}</h2>
        <div className="block assign_issue">
          <div className="content-item">
            <label>
              {t(
                "assign_to_responsible_worker",
                "Assigned to responsible worker"
              )}
              {editMarkStates["workers"] && (
                <span className="edit-sign">{t("edit", "Edit")}</span>
              )}
            </label>
            <CustomSelect
              id="IssueAssignToDropdown"
              values={users}
              isDisabled={false}
              onChange={(value) => handleSelectChange(value, "worker")}
              value={workers}
              isMulti={true}
              isOptional={true}
              isModel={true}
              isMassEdit={isMassEdit}
            />
          </div>
          <div className="content-item">
            <label>
              {t("assign_to_reviewer", "Assigned to reviewer")}
              {editMarkStates["reviewer"] && (
                <span className="edit-sign">{t("edit", "Edit")}</span>
              )}
            </label>
            <CustomSelect
              id="IssueReviewerDropdown"
              values={users}
              isDisabled={false}
              onChange={(value) => handleSelectChange(value, "reviewer")}
              value={reviewer}
              isOptional={true}
              isModel={true}
              isClearable={true}
              isSearchable={false}
              isMassEdit={isMassEdit}
            />
          </div>
        </div>
      </div>

      <div className="issue-modal-content--column ml-20 mobile-visibility">
        <div className="issue-modal-visibility">
          <h2 className="subtitle">{t("visibility", "Visibility")}</h2>
          <div className="block">
            <div
              id="public"
              className={`radio-button ${
                visibility === "public" ? "active" : ""
              }`}
              onClick={(e) => handleSelectChange(e.target.id, "visibility")}
            >
              <div className="block" id="public">
                <input
                  id="public"
                  type="radio"
                  checked={visibility === "public"}
                  onChange={() => {}}
                />
                <label id="public" htmlFor="public">
                  {t("public", "Public")}
                </label>
              </div>
              <p id="public">
                {t(
                  "issue_visible_public",
                  "The issue is visible for every member in the project."
                )}
              </p>
            </div>
            {!isMassEdit ? (
              <div
                id="private"
                className={`radio-button ${
                  visibility === "private" ? "active" : ""
                }`}
                onClick={(e) => handleSelectChange(e.target.id, "visibility")}
              >
                <div className="block" id="private">
                  <input
                    id="private"
                    type="radio"
                    checked={visibility === "private"}
                    onChange={() => {
                    }}
                  />
                  <label id="private" htmlFor="private">
                    {t("private", "Private")}
                  </label>
                </div>
                <p id="private">
                  {t(
                    "issue_visible_private",
                    "This issue is just visible for you."
                  )}
                </p>
              </div>
            ) : (
              ""
            )}
            <div
              id="restricted"
              className={`radio-button ${
                visibility === "restricted" ? "active" : ""
              }`}
              onClick={(e) => handleSelectChange(e.target.id, "visibility")}
            >
              <div className="block" id="restricted">
                <input
                  id="restricted"
                  type="radio"
                  checked={visibility === "restricted"}
                  onChange={() => {}}
                />
                <label id="restricted" htmlFor="restricted">
                  {t("restricted", "Restricted")}
                </label>
              </div>
              <p id="restricted">
                {t(
                  "issue_visible_restricted",
                  "The issue is just visible for selected user roles."
                )}
              </p>
            </div>
            {visibility === "restricted" ? (
              <div className="content-item visibility-option-select">
                <label>
                  {editMarkStates["visibility"] && (
                    <span className="edit-sign">{t("edit", "Edit")}</span>
                  )}
                </label>
                <CustomSelect
                  id="RoleVisibilityIssueDropdown"
                  values={visibilityOptions}
                  isDisabled={false}
                  onChange={(value) => handleSelectChange(value, "roles")}
                  value={allowedUserGroups}
                  isMulti={true}
                  isOptional={true}
                  isModel={true}
                  menuPlacement="top"
                  isMassEdit={isMassEdit}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="block issue-settings">
          <div className="issue-modal-details">
            <h2 className="subtitle">{t("details", "Details")}</h2>
            <div className="content-item content-item--priority">
              <label>
                {t("priority", "Priority")}
                {editMarkStates["priority"] && (
                  <span className="edit-sign">{t("edit", "Edit")}</span>
                )}
              </label>
              <CustomSelect
                id="IssuePriorityDropdown"
                values={priorities}
                isDisabled={false}
                onChange={(value) => handleSelectChange(value, "priority")}
                value={priority}
                isModel={true}
                isClearable={true}
                isSearchable={false}
                isMassEdit={isMassEdit}
              />
            </div>

            {workphases.length > 1 ? (
              <div className="content-item">
                <label>
                  {t("work_stage", "Work Stage")}
                  {editMarkStates["workphase"] && (
                    <span className="edit-sign">{t("edit", "Edit")}</span>
                  )}
                </label>
                <CustomSelect
                  id="IssueWorkphaseDropdown"
                  values={workphases}
                  isDisabled={false}
                  onChange={(value) => handleSelectChange(value, "workphase")}
                  value={workphase}
                  isMulti={false}
                  isOptional={true}
                  isModel={true}
                  isClearable={true}
                  isSearchable={false}
                  menuPlacement={"top"}
                  isMassEdit={isMassEdit}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="issue-modal-settings">
            <h2 className="subtitle">
              {t("model_settings", "Model Settings")}
            </h2>
            <div className="content-item">
              <label>
                {t("discipline", "Discipline")}
                {editMarkStates["discipline"] && (
                  <span className="edit-sign">{t("edit", "Edit")}</span>
                )}
              </label>
              <CustomSelect
                id="IssueDisciplineDropdown"
                values={disciplines}
                isDisabled={false}
                onChange={(value) => handleSelectChange(value, "discipline")}
                value={discipline}
                isMulti={true}
                isOptional={true}
                isModel={true}
                isMassEdit={isMassEdit}
              />
            </div>
            <div className="content-item">
              <label>
                {t("building", "Building")}
                {editMarkStates["building"] && (
                  <span className="edit-sign">{t("edit", "Edit")}</span>
                )}
              </label>
              <CustomSelect
                id="IssueBuildingDropdown"
                values={buildings}
                isDisabled={false}
                onChange={(value) => handleSelectChange(value, "building")}
                value={building}
                isMulti={true}
                isOptional={true}
                isModel={true}
                isMassEdit={isMassEdit}
              />
            </div>
            <div className="content-item">
              <label>
                {t("floor", "Storey")}
                {editMarkStates["floor"] && (
                  <span className="edit-sign">{t("edit", "Edit")}</span>
                )}
              </label>
              <CustomSelect
                id="IssueFloorDropdown"
                values={floors}
                isDisabled={false}
                onChange={(value) => handleSelectChange(value, "floor")}
                value={floor}
                isMulti={true}
                isOptional={true}
                isModel={true}
                isMassEdit={isMassEdit}
              />
            </div>
          </div>
          <div className="issue-modal-tags">
            <h2 className="subtitle">{t("other", "Other")}</h2>
            <div className="content-item content-item--tags">
              <label>
                {t("tags", "Tags")}
                {editMarkStates["tags"] && (
                  <span className="edit-sign">{t("edit", "Edit")}</span>
                )}
              </label>
              {!editIssue && mode !== Common.IssueModalMode.CREATE && (
                <TagOption
                  isOverwriteTag={isOverwriteTag}
                  setIsOverwriteTag={setIsOverwriteTag}
                />
              )}
              <TagSelect
                id="IssueTagsDropdown"
                values={tagOptions}
                onChange={(value) => handleSelectChange(value, "tags")}
                value={tags}
                isBackgroundVisible={tags.length > 0 ? false : true}
                menuPlacement={"top"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(IssueModalContent);
