import React from 'react';
import PageWrapper from 'common/components/PageWrapper';
import { Box } from '@mui/material';
import TimelinePageHeader from 'timeline/components/TimelinePageHeader';
import TimelinePanel from 'timeline/components/TimelinePanel';
import IssuesSelectionContextProvider from 'issues/contexts/IssuesSelectionContextProvider';

export default function TimelinePage() {
  return (
    <PageWrapper id="TimelinePage">
      <Box sx={{
        display: 'grid',
        gridTemplateRows: '60px 1fr',
        height: '100%',
      }}
      >
        <IssuesSelectionContextProvider>
          <TimelinePageHeader />
          <TimelinePanel />
        </IssuesSelectionContextProvider>
      </Box>
    </PageWrapper>
  );
}
