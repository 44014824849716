import { useCallback } from 'react';
import DocumentFilterSettings from 'documents-filter/types/DocumentFilterSettings';
import DocumentFilterSettingsDto from 'documents-filter/types/DocumentFilterSettingsDto';
import FolderSearchScope from 'documents-folders/types/FolderSearchScope';

type Factory = (dto: DocumentFilterSettingsDto) => Readonly<DocumentFilterSettings>;

export default function useDocumentFilterSettingsEntityFactory() {
  return useCallback<Factory>((dto: DocumentFilterSettingsDto) => ({
    ...dto,
    editDateParsed: dto.editDate ? new Date(dto.editDate) : undefined,
    uploadDateParsed: dto.uploadDate ? new Date(dto.uploadDate) : undefined,
    creationDateParsed: dto.creationDate ? new Date(dto.creationDate) : undefined,
    uploadFromParsed: dto.uploadFrom ? new Date(dto.uploadFrom) : undefined,
    uploadToParsed: dto.uploadTo ? new Date(dto.uploadTo) : undefined,
    createdFromParsed: dto.createdFrom ? new Date(dto.createdFrom) : undefined,
    createdToParsed: dto.createdTo ? new Date(dto.createdTo) : undefined,
    creatorId: dto.creatorId ?? [],
    editorId: dto.editorId ?? [],
    disciplineMetaDataId: dto.disciplineMetaDataId ?? [],
    buildingMetaDataId: dto.buildingMetaDataId ?? [],
    workPhaseId: dto.workPhaseId ?? [],
    tagIds: dto.tagIds ?? [],
    floorMetaId: dto.floorMetaId ?? [],
    fileType: dto.fileType ?? [],
    documentStatusId: dto.documentStatusId ?? [],
    folderSearchScope: dto.folderSearchScope ?? FolderSearchScope.AllFolders,
  } as Readonly<DocumentFilterSettings>), []);
}
