import React, { useContext, useMemo } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import DocumentListDto from 'documents-lists/types/DocumentListDto';
import DocumentDragAndDropContext, { DocumentDragAndDropContextState } from 'documents/contexts/DocumentDragAndDropContext';
import Color from 'color';

export default function PlanlistTreeCellRenderer({
  data,
  node,
}: ICellRendererParams<DocumentListDto, string | undefined>) {
  const { t } = useTranslation('documents-lists');
  const theme = useTheme();
  const { draggedOverDocumentListId } = useContext<DocumentDragAndDropContextState>(DocumentDragAndDropContext);
  const isPlanlistCurrentlyDraggedOver = useMemo(() => draggedOverDocumentListId === node.id, [draggedOverDocumentListId, node.id]);
  return (
    <Box
      id="PlanlistTreeCellRenderer"
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        pr: 1,
        pl: 2,
        backgroundColor: isPlanlistCurrentlyDraggedOver ? Color(theme.palette.secondary.light).lightness(98).hex() : 'unset',
      }}
    >
      {!!data && (
        <Box sx={{ minWidth: 0, flexGrow: 1 }}>
          <Typography
            title={data?.name}
            sx={{
              textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
            }}
          >
            {data?.name}
          </Typography>
          <Typography sx={{ fontSize: 12, color: theme.palette.text.secondary }}>
            {t('planlist-item_document-count', '{{count}} documents', { count: data.documentVersionIds.length })}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
