import { gql } from 'graphql-request';
import React from 'react';
import { useTranslation } from 'react-i18next';
import $TSFixMe from 'common/types/FixMeAny';
import useIssueQuery from 'issues/hooks/useIssueQuery';

import MentionView from '../MentionView';

import { getGeneralSvg } from '../SVGs';
import LinkedSubTasks, { LINKED_SUB_TASKS_ISSUE_FRAGMENT } from './LinkedSubTasks';
import { IssueItemContent_issue } from './__generated__/IssueItemContent_issue';

export const ISSUE_ITEM_CONTENT_ISSUE_FRAGMENT = gql`
${LINKED_SUB_TASKS_ISSUE_FRAGMENT}
fragment IssueItemContent_issue on Issue {
  ...LinkedSubTasks_issue
  dueDate
  description
  title
  assignedUsers {
    firstName
    lastName
  }
  issueNumber
  issueType {
    color
    name
  }
}
`;

export interface IssueItemContentProps {
  issueId: string | undefined,
  issue: IssueItemContent_issue,
  imageUrl: string,
  imageName: string,
  viewIssue: (issue: $TSFixMe) => Promise<void>
}

function IssueItemContent({
  issueId, issue, imageUrl, imageName, viewIssue,
}: IssueItemContentProps) {
  const { t } = useTranslation();
  const { data: issueData } = useIssueQuery(issueId);

  if (!issueData) return null;

  return (
    <>
      <div className="issue-item__header">
        <div className="issue-item__header-flex">
          <div
            className="issue-item__type"
            style={{
              background: `${issue.issueType?.color}33`,
              color: issue.issueType?.color,
            }}
          >
            <p className="issue-item__type-label">
              {issue.issueType?.name || ''}
            </p>
          </div>
          {!!issue.dueDate && (
            <div className={`issue-item__date-created label${issueData.issueStatus?.originalName !== 'Resolved' && issueData.issueStatus?.originalName !== 'Closed' && new Date(issue.dueDate) < new Date() ? ' bg-red' : ' bg-dark-grey'}`}>
              {getGeneralSvg('clock')}
              <p>
                {t('issue_due_date_new', 'Due on {{dateString}}', { dateString: new Date(issue.dueDate).toLocaleDateString('de-DE') })}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="issue-item__body">
        <h3 className="issue-item__title text-ellipsis">
          <span className="issue-number">{issue.issueNumber}</span>
          {issue.title}
        </h3>
        {!!issue.description && (
          <MentionView
            className="issue-item__description txt-maxline-2"
            value={issue.description}
            isRichText={undefined}
          />
        )}
        {!!imageUrl && (
          <div className="issue-item__image">
            <img
              className={imageName === 'visoplan.png' ? 'image-logo' : ''}
              src={imageUrl}
              alt={t('image_error', 'Image Error')}
            />
          </div>
        )}
      </div>
      <div className="issue-item__footer">
        <LinkedSubTasks issue={issue} viewIssue={viewIssue} />
        {issue.assignedUsers?.length > 0 && (
          <p className="issue-item__assigned">
            <strong>{`${t('assigned_to', 'Assigned to')}: `}</strong>
            <span
              className={`issue-item__assigned-name text-ellipsis ${issue.assignedUsers.length - 1 > 0 ? '' : 'w-fit'
              }`}
            >
              {`${issue.assignedUsers[0].firstName} ${issue.assignedUsers[0].lastName}`}
            </span>
            {issue.assignedUsers.length - 1 > 0 && (
              <span className="text-underline">
                {`+${issue.assignedUsers.length - 1
                } more`}
              </span>
            )}
          </p>
        )}
      </div>
    </>
  );
}
export default IssueItemContent;
