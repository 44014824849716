import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components-n/checkbox';
import Radio from 'components-n/radio';
import CustomSelect from 'Components/CustomSelect';

import { File_Types, getEncodingTitle } from 'Helpers/Encoding';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';

interface SettingsProps {
  encodings: any;
  handleCheckChange: any;
  namingSettings: any;
  setEncodings: React.Dispatch<React.SetStateAction<any>>;
  setIsEnableSave: React.Dispatch<React.SetStateAction<boolean>>;
}

function Settings({
  encodings,
  handleCheckChange,
  namingSettings,
  setEncodings,
  setIsEnableSave,
}: SettingsProps) {
  const currentUserRole = useCurrentUserRole();
  const { bypassRestrictionForPrivateDocs, forceUploadRestriction } = namingSettings;
  const isEmptyEncoding = useMemo(() => (
    Object.values(encodings).every((group: any) => !group.groups.length)
  ), [encodings]);
  const { t } = useTranslation();

  const [encodingAllowedFileTypes, setEncodingAllowedFileTypes] = useState<any>({
    plan_encoding: [],
    document_encoding: [],
    model_encoding: [],
    custom_encoding: [],
  });

  const [encodingUseFullFileName, setEncodingUseFullFileName] = useState<any>({
    plan_encoding: [],
    document_encoding: [],
    model_encoding: [],
    custom_encoding: [],
  });

  const handleChangeFileType = (key: string, value: any) => {
    setEncodingAllowedFileTypes({
      ...encodingAllowedFileTypes,
      [key]: value,
    });

    setEncodings({
      ...encodings,
      [key]: {
        ...encodings[key],
        applyFileTypes: value.map((item: any) => item.value),
      },
    });
    setIsEnableSave(true);
  };

  const handleChangeUseFullName = (key: string, value: any) => {
    setEncodingUseFullFileName({
      ...encodingUseFullFileName,
      [key]: value,
    });

    setEncodings({
      ...encodings,
      [key]: {
        ...encodings[key],
        useFullFileName: value,
      },
    });
    setIsEnableSave(true);
  };

  const isAllowOtherFileTypes = useMemo(() => (
    !!Object.values(encodings).find((encoding: any) => !!encoding.applyFileTypes?.length)
  ), [encodings]);

  useEffect(() => {
    const initAllowedFileTypes = () => {
      setEncodingAllowedFileTypes((prevAllowedFileTypes: any) => {
        let allowedFileTypes = { ...prevAllowedFileTypes };

        Object.keys(encodings).forEach((key: string) => {
          const fileTypes = File_Types.filter((type: any) => encodings[key].applyFileTypes.includes(type.value));
          allowedFileTypes = {
            ...allowedFileTypes,
            // The empty fileTypes means that all file types are allowed
            [key]: fileTypes,
          };
        });
        return allowedFileTypes;
      });
    };
    initAllowedFileTypes();
    const initUseFullFileName = () => {
      setEncodingUseFullFileName((prevEncodingUseFullFileName: any) => {
        const nextEncodingUseFullFileName = { ...prevEncodingUseFullFileName };
        Object.keys(encodings).forEach((key: string) => {
          nextEncodingUseFullFileName[key] = encodings[key].useFullFileName;
        });
        return nextEncodingUseFullFileName;
      });
    };
    initUseFullFileName();
  }, [encodings]);

  const canUpdateAcceptUploadCheckbox = useMemo(() => (
    Object.keys(encodings).every((key) => (encodings[key].groups.length && encodingAllowedFileTypes[key]?.length) || !encodings[key].groups.length)
  ), [encodings, encodingAllowedFileTypes]);

  return (
    <div
      className="project-encoding-content__settings"
      style={{
        pointerEvents: !currentUserRole?.allowedActions?.has(RoleAction.NamingScheme_Create_Edit) ? 'none' : undefined,
      }}
    >
      <h3 className="title">{t('encoding_settings', 'Encoding Settings')}</h3>
      <div className="settings-general">
        {isEmptyEncoding ? (
          <h4 className="error-message">
            {t('scheme_settings_error', 'Need to add at least one scheme')}
          </h4>
        ) : (
          <>
            <Checkbox
              className="settings-checkbox"
              label={t('forbid_uploads', 'Forbid uploads with a wrong file structure')}
              name="forceUploadRestriction"
              checked={forceUploadRestriction}
              onChange={handleCheckChange}
            />
            <div className="settings-radio_group">
              <Radio
                className="settings-radio"
                name="allDocs"
                checked={forceUploadRestriction && !bypassRestrictionForPrivateDocs}
                onChange={handleCheckChange}
                label={t('all_folders', 'All Folders')}
              />
              <Radio
                className="settings-radio"
                name="privateDocs"
                checked={forceUploadRestriction && bypassRestrictionForPrivateDocs}
                onChange={handleCheckChange}
                label={t('encoding_forbid-upload_except-private', 'All Folders except private folders')}
              />
            </div>
            <p className="settings-label">
              {t('define_file_types', 'Define file types')}
            </p>
            <div className="settings-select-group">
              {
                Object.keys(encodings).map((key: string) => (
                  !!encodings[key].groups.length && (
                    <div className="settings-select" key={`select-${key}`} style={{ marginBottom: '12px' }}>
                      <p className="settings-select_label">
                        <span>{getEncodingTitle(key)}</span>
                        {`${t('allow_file_types', 'allows file types')}:`}
                      </p>
                      <div>
                        <CustomSelect
                          values={File_Types}
                          value={encodingAllowedFileTypes[key]}
                          onChange={(value: any) => handleChangeFileType(key, value)}
                          isModel
                          isMulti
                          isOptional
                        />
                        <Checkbox
                          className="settings-checkbox"
                          label={t('encoding-settings_use-full-file-name', 'Use full file name')}
                          name="useFullFileName"
                          checked={encodingUseFullFileName[key]}
                          onChange={() => handleChangeUseFullName(key, !encodingUseFullFileName[key])}
                        />
                      </div>
                    </div>
                  )
                ))
              }
            </div>
            {
              isAllowOtherFileTypes && (
                <Checkbox
                  className="settings-checkbox mb-50"
                  label={t('accept_other_file', 'Accept other file types')}
                  name="allowUploadFiles"
                  checked={namingSettings.allowUploadFilesWhenSchemaByTypeNotFound}
                  onChange={handleCheckChange}
                  disabled={!canUpdateAcceptUploadCheckbox}
                />
              )
            }
            <p className="settings-label">
              {t('transfer_meta_data', 'Transfer Meta-Data')}
            </p>
            {Object.keys(encodings).map((key: string) => (
              !!encodings[key].groups.length && (
                <div key={`${key}`} className="sub-group">
                  <Checkbox
                    className="settings-checkbox"
                    label={key}
                    name={key}
                    checked={encodings[key].allowMetaMapping}
                    onChange={handleCheckChange}
                  />
                </div>
              )
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default Settings;
