import { useMemo } from 'react';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import PdfAnnotationDto from 'documents-annotations/types/PdfAnnotationDto';

export default function usePdfAnnotationsQueryKey() {
  const {
    odataQueryQueryKey: queryKeyBase,
    getOdataQueryQueryKey: getQueryKey,
  } = useDefaultEntityQueryKeys(ApiEndpoint.PdfAnnotation);
  return useMemo(() => ({
    queryKeyBase,
    getQueryKey: getQueryKey<PdfAnnotationDto>,
  }), [getQueryKey, queryKeyBase]);
}
