import { QueryObserverOptions } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useUserEntityFactory from 'users/hooks/useUserEntityFactory';
import { UserDto } from 'users/types/UserDto';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';
import { useCallback, useMemo } from 'react';
import { CurrentUserQueryKey } from 'users/hooks/useCurrentUserQueryKey';
import User from 'users/types/User';

export type CurrentUserQueryQueryFnData = User | undefined;
export type CurrentUserQueryOptions = Partial<Omit<QueryObserverOptions<CurrentUserQueryQueryFnData, unknown, CurrentUserQueryQueryFnData, CurrentUserQueryQueryFnData, CurrentUserQueryKey>, 'queryKey'>>;

export default function useCurrentUserQueryOptions() {
  const { isSignedIn } = useVisoplanApiContext();
  const axiosInstance = useAxiosInstance();
  const entityFactory = useUserEntityFactory();
  const queryFn = useCallback(async () => {
    const response = await axiosInstance.get<UserDto>('/user/current');
    return entityFactory(response.data);
  }, [axiosInstance, entityFactory]);
  return useMemo<CurrentUserQueryOptions>(() => ({
    queryFn,
    enabled: Boolean(isSignedIn),
  }), [isSignedIn, queryFn]);
}
