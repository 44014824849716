import { DateInterval } from 'issues/contexts/IssuesFilterContextProvider';
import HardcodedIssueFilter from 'issues/types/HardcodedIssueFilter';
import IssueDto from 'issues/types/IssueDto';
import IssuesSortMode from 'issues/types/IssuesSortMode';
import { QueryOptions } from 'odata-query';
import { Dispatch, SetStateAction, createContext } from 'react';

export interface IssuesFilterContextState {
  documentId: string | undefined,
  setDocumentId: Dispatch<SetStateAction<string | undefined>>,
  quickFilter: string,
  setQuickFilter: Dispatch<SetStateAction<string>>,
  hardcodedFilter: HardcodedIssueFilter,
  setHardcodedFilter: Dispatch<SetStateAction<HardcodedIssueFilter>>,
  sortMode: IssuesSortMode,
  setSortMode: Dispatch<SetStateAction<IssuesSortMode>>,
  dateInterval: DateInterval | undefined,
  setDateInterval: Dispatch<SetStateAction<DateInterval | undefined>>,
  odataQuery: Partial<QueryOptions<IssueDto>> | undefined,
}

const defaultState: IssuesFilterContextState = {
  documentId: undefined!,
  setDocumentId: undefined!,
  quickFilter: undefined!,
  setQuickFilter: () => {},
  hardcodedFilter: undefined!,
  setHardcodedFilter: () => {},
  sortMode: undefined!,
  setSortMode: () => {},
  dateInterval: undefined,
  setDateInterval: () => {},
  odataQuery: undefined,
};

const IssuesFilterContext = createContext<IssuesFilterContextState>(defaultState);
export default IssuesFilterContext;
