import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { IssueService } from "../../Services";

const LinkedSubTasksOld = ({
  issue,
  baseIssuesUrl,
  viewIssue,
  isModelIssues,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);
  const [linkedSubTasks, setLinkedSubTasks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const effect = async () => {
      if (!issue.childrenIds?.length) setLinkedSubTasks([]);
      else {
        const tasks = await IssueService.getIssuesByIds(issue.childrenIds);
        setLinkedSubTasks(tasks);
      }
    };
    effect();
  }, [issue]);

  return (
    <div className="issue-item__subtask">
      {!!linkedSubTasks?.length && (
        <>
          <div
            className="issue-item__subtask-label cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(!isExpanded);
            }}
          >
            <i
              className={`arrow ${!isExpanded ? "arrow-right" : "arrow-down"}`}
            />
            <svg className="icon">
              <use xlinkHref="/img/sprite.svg#task" />
            </svg>
            <p>{`${linkedSubTasks?.length} ${t("sub_tasks", "Sub Tasks")}`}</p>
          </div>
          {isExpanded && (
            <div className="issue-item__subtask-dropdown">
              {linkedSubTasks.map((task, index) => (
                <p
                  key={`sub-task-${index}`}
                  className="issue-item__subtask-dropdown-label text-ellipsis"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isModelIssues)
                      navigate(`${baseIssuesUrl}${task.id}`);
                    viewIssue(task);
                  }}
                >
                  {task.title}
                </p>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LinkedSubTasksOld;
