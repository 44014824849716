import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import * as EncodingHelper from "../../Helpers/Encoding";

import GroupCheckbox from "Components/Encoding/GroupCheckbox";
import CustomSelect from "../CustomSelect";
import TagSelect from "../TagSelect";
import Checkbox from "../../components-n/checkbox";

const GroupElement = ({
  handleChangeGroup,
  index,
  group,
  visoplanGroups,
  defaultValue,
  dateTypes,
  versionLenght,
  setOptionsType,
  groupValue,
  getValue,
  handleDelete,
  error,
}) => {
  const { t } = useTranslation();
  const [versionType, setVersionType] = useState(defaultValue);

  const getType = () => {
    return dateTypes?.find(
      (type) => Number(type.value) === group.groupElements[0].dateType
    )?.label;
  };

  const getVersionType = () => {
    let type = {};

    if (group.groupElements[0]?.length) {
      type = EncodingHelper.versionTypes().find((type) => {
        if (group.groupElements[0]?.versionAsLetter)
          return type.value === "letters";
        else return type.value === "numbers";
      });
    } else {
      type = versionType;
    }

    return type;
  };

  const dateFormat = (date) => {
    switch (date) {
      case "JJMMTT":
        return moment(new Date()).format("YYMMDD");
      case "JJJJMMTT":
        return moment(new Date()).format("YYYYMMDD");
      case "TTMMJJ":
        return moment(new Date()).format("DDMMYY");
      case "TTMMJJJJ":
        return moment(new Date()).format("DDMMYYYY");
      case "JJ-MM-TT":
        return moment(new Date()).format("YY-MM-DD");
      case "JJJJ-MM-TT":
        return moment(new Date()).format("YYYY-MM-DD");
      case "TT-MM-JJ":
        return moment(new Date()).format("DD-MM-YY");
      case "TT-MM-JJJJ":
        return moment(new Date()).format("DD-MM-YYYY");
      default:
        return moment(new Date()).format(date);
    }
  };

  return (
    <div className="group-element">
      {group.type === EncodingHelper.groupTypes.creationDate && (
        <>
          <div className="group-element--date">
            <span className="column__title">
              {t("date_format", "Date Format")}
            </span>
            <CustomSelect
              values={dateTypes}
              onChange={({ value, label }) =>
                handleChangeGroup({
                  name: "dateType",
                  elemValue: value,
                  date: dateFormat(t(label)),
                  currentIndex: index,
                  elemIndex: 0,
                })
              }
              value={
                group.groupElements.length
                  ? {
                      value: group.groupElements[0].dateType,

                      label: t(getType()),
                    }
                  : defaultValue
              }
              isModel={true}
              classNames="slect-menu"
            />
          </div>
          <GroupCheckbox
            group={group}
            currentIndex={index}
            handleChangeGroup={handleChangeGroup}
          />
        </>
      )}
      {group.type === EncodingHelper.groupTypes.version && (
        <>
          <span className="column__title">{t("group_name", "Group Name")}</span>
          <span className="column__title ml-75">{t("digits", "Digits")}</span>
          <div className="group-element--version">
            <div className="version-type">
              <CustomSelect
                values={EncodingHelper.versionTypes()}
                onChange={({ value, label }) => {
                  setVersionType({ value, label });
                  handleChangeGroup({
                    name: value,
                    currentIndex: index,
                    elemIndex: 0,
                  });
                }}
                value={getVersionType()}
                isModel={true}
              />
            </div>
            <div className="version-number">
              <CustomSelect
                values={versionLenght}
                onChange={({ value }) =>
                  handleChangeGroup({
                    name: "length",
                    elemValue: value,
                    currentIndex: index,
                    elemIndex: 0,
                  })
                }
                value={
                  group.groupElements[0]?.length
                    ? {
                        value: group.groupElements[0].length,
                        label:
                          versionLenght[group.groupElements[0].length - 1]
                            ?.label,
                      }
                    : defaultValue
                }
                isModel={true}
              />
            </div>
          </div>
          <GroupCheckbox
            group={group}
            currentIndex={index}
            handleChangeGroup={handleChangeGroup}
          />
        </>
      )}
      {group.type === EncodingHelper.groupTypes.freeText && (
        <div className="column__free-text">
          <div className="notice">
          {t(
            "free_text",
            "The free text is not allowed to include the separation sign."
          )}
          </div>
          <GroupCheckbox
            group={group}
            currentIndex={index}
            handleChangeGroup={handleChangeGroup}
          />
        </div>
      )}     
      {group.type === EncodingHelper.groupTypes.chars && (
         <div className="column__chars">
            <span className="column__title">{t("digits", "Digits")}</span>
            <div className="digit-count">
               <CustomSelect
                  values={versionLenght}
                  onChange={({ value }) =>
                     handleChangeGroup({
                        currentIndex: index,
                        length: value
                     })
                  }
                  value={
                     group.length
                       ? {
                           value: group.length,
                           label: versionLenght[group.length - 1]?.label,
                         }
                       : defaultValue
                   }
                  isModel={true}
               />
            </div>
            <GroupCheckbox
              group={group}
              currentIndex={index}
              handleChangeGroup={handleChangeGroup}
            />
         </div>
      )}
      {group.type === EncodingHelper.groupTypes.number && (
         <div className="column__number">
            <span className="column__title">{t("digits", "Digits")}</span>
            <div className="digit-count">
               <CustomSelect
                  values={versionLenght}
                  onChange={({ value }) =>
                     handleChangeGroup({
                        currentIndex: index,
                        length: value
                     })
                  }
                  value={
                     group.length
                       ? {
                           value: group.length,
                           label: versionLenght[group.length - 1]?.label,
                         }
                       : defaultValue
                   }
                  isModel={true}
               />
            </div>
            <GroupCheckbox
              group={group}
              currentIndex={index}
              handleChangeGroup={handleChangeGroup}
            />            
         </div>
      )}
      {group.type !== EncodingHelper.groupTypes.creationDate &&
      group.type !== EncodingHelper.groupTypes.version &&
      group.type !== EncodingHelper.groupTypes.freeText &&
      group.type !== EncodingHelper.groupTypes.chars &&
      group.type !== EncodingHelper.groupTypes.number &&
      group.name &&
      String(group.type) &&
      group.separator !== undefined && (
        <>
          <div className="group-element--title">
            <span className="column__title">{t("name", "Name")}</span>
            <span className="column__title ml-55">{t("abbr.", "Abbr.")}</span>
            <span className="column__title ml-25">
              {t("group_name", "Group Name")}
            </span>
          </div>
          {group.groupElements.length
            ? group.groupElements.map((element, elemIndex) => (
                <div
                  className="group-element--row"
                  key={`group-element-${elemIndex}`}
                >
                  <input
                    type="text"
                    className={"input input--shadow input-row"}
                    onChange={(e) =>
                      handleChangeGroup({
                        name: "name",
                        elemValue: e.target.value,
                        currentIndex: index,
                        elemIndex,
                      })
                    }
                    value={element.name || element.odataKey || ""}
                  />
                  <input
                    type="text"
                    className={`input input--shadow input-row-abbr ${
                      element.error ? "error" : ""
                    }`}
                    onChange={(e) =>
                      handleChangeGroup({
                        name: "abbreviation",
                        elemValue: e.target.value,
                        currentIndex: index,
                        elemIndex,
                      })
                    }
                    value={element.abbreviation}
                  />
                  <TagSelect
                    values={setOptionsType(group.type) || []}
                    isMulti={element.tagIds?.length || element?.metaId || ""}
                    onChange={(value) => {
                      handleChangeGroup({
                        name: "label",
                        metaId: true,
                        value,
                        currentIndex: index,
                        elemIndex,
                        group:
                          group.type !== undefined
                            ? visoplanGroups[group.type].value
                            : groupValue[index].value,
                      });
                    }}
                    value={
                      element.metaId || element.tagIds?.length
                        ? getValue(element?.metaId, element?.tagIds)
                        : [defaultValue]
                    }
                    classNames="slect-menu"
                  />
                  <button
                    className="btn-delete btn "
                    type="button"
                    onClick={() =>
                      handleDelete({
                        currentIndex: index,
                        elemIndex: elemIndex,
                      })
                    }
                  >
                    <svg className="icon">
                      <use xlinkHref="/img/sprite.svg#delete" />
                    </svg>
                  </button>
                </div>
              ))
            : ""}
          {group.type !== EncodingHelper.groupTypes.project ? (
            <a
              className="btn btn-setting-item"
              onClick={() =>
                handleChangeGroup({
                  currentIndex: index,
                  element: {},
                })
              }
            >
              <span>+</span>
              <p className="subtitle">{t("add_element", "Add Element")}</p>
            </a>
          ) : (
            ""
          )}
          {error[index] && (
            <span className="error-message">{error[index]}</span>
          )}
          <GroupCheckbox
            group={group}
            currentIndex={index}
            handleChangeGroup={handleChangeGroup}
          />
        </>
      )}
    </div>
  );
};

export default GroupElement;
