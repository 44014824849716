import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import CommentDto from 'issues/types/CommentDto';
import PersistIssueCommentDto from 'issues/types/PersistIssueCommentDto';
import { useCallback } from 'react';

export default function useCommentCreateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { baseQueryKey: issueCommentsBaseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Comment);
  const { baseQueryKey: issuesBaseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Issue);
  const mutationFn = useCallback(async (dto: PersistIssueCommentDto) => {
    const { data: commentDtos } = await axiosInstance.post<CommentDto[]>(`${ApiEndpoint.Comment}`, [dto]);
    return commentDtos[0];
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: issueCommentsBaseQueryKey });
    queryClient.invalidateQueries({ queryKey: issuesBaseQueryKey });
  }, [issueCommentsBaseQueryKey, issuesBaseQueryKey, queryClient]);
  return useMutation<CommentDto, unknown, PersistIssueCommentDto>({
    mutationFn,
    onSuccess,
  });
}
