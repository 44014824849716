import { QueryFunction, QueryObserverOptions } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import DocumentListDto from 'documents-lists/types/DocumentListDto';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { VisoDetailsQueryKey } from 'api/hooks/useDefaultEntityQueryKeys';
import useDefaultOdataQueryFn from 'api/hooks/useDefaultOdataQueryFn';
import useDocumentListsOdataQueryKey from 'documents-lists/hooks/useDocumentListsOdataQueryKey';
import { DocumentListQueryKey } from 'documents-lists/hooks/useDocumentListQueryKey';

export type DocumentListQueryFnData = DocumentListDto | undefined;
export type DocumentListData = DocumentListDto | undefined;
export type DocumentListQueryOptions = Partial<Omit<QueryObserverOptions<DocumentListQueryFnData, unknown, DocumentListData, DocumentListQueryFnData, DocumentListQueryKey>, 'queryKey'>>;

export default function useDocumentListQueryOptions() {
  const odataQueryFn = useDefaultOdataQueryFn<DocumentListDto, DocumentListDto, ApiEndpoint.DocumentList>();
  const { getQueryKey } = useDocumentListsOdataQueryKey();
  const queryFn = useCallback<QueryFunction<DocumentListDto | undefined, VisoDetailsQueryKey<ApiEndpoint.DocumentList>>>(async ({ queryKey }) => {
    const [, , , id] = queryKey;
    const odataQueryKey = getQueryKey({ filter: { id: { eq: id } } });
    const odataResult = await odataQueryFn({ queryKey: odataQueryKey });
    if (!odataResult.length) throw new Error('Requested issue not part of API response');
    return odataResult[0];
  }, [getQueryKey, odataQueryFn]);
  return useMemo<DocumentListQueryOptions>(() => ({
    queryFn,
  }), [queryFn]);
}
