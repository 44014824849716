import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";

import {
  IssueModalMode,
  MODE_ADD_NONE,
  PER_PAGE_RECORDS,
  SyncDataType,
} from "../../Helpers/Common";
import {
  useIssueFilters,
  useFilterOptions,
} from "../../Helpers/Hooks";
import {
  getIssuesByProjectId,
  getValuesAddedAll,
  getIssuesWithLogViewpoints,
  formatIssue,
  editIssue,
  getIssuesByIds,
} from "../../Services/IssueService";
import { getChangedIds } from "../../Helpers/utils";

import IssueBoardColumnOld from "../IssueBoard/IssueBoardColumnOld";
import AsideIssueFilter from "../Aside/AsideIssueFilter/AsideIssueFilter";
import AsideIssue from "../Aside/AsideIssue/AsideIssue";
import Loader from "../Loader";
import { SaveFilterModal } from "../FilterOptions";
import useCurrentUserQuery from "users/hooks/useCurrentUserQuery";
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';

const NONE_VIEW = 100;
const FILTER_VIEW = 101;
const DETAIL_VIEW = 102;

const ModelIssueHeader = ({ showFilterView, onCreateIssue }) => {
  const { t } = useTranslation();
  return (
    <div className="model-issue_header">
      <strong>{t("issues", "Issues")}</strong>
      <button className="btn btn--secondary" onClick={showFilterView}>
        {t("filter", "Filter")}
      </button>
      <button className="btn btn--primary" onClick={onCreateIssue}>
        {t("create_issue", "Create Issue")}
      </button>
    </div>
  );
};

const ProtectPanel = () => {
  return <div className="protect-panel" />;
};

export default function ModelIssues(props) {
  const {
    isVisible,
    allIssues,
    allDocuments,
    allEmails,
    tagOptions,
    issueFilterItems,
    issueAddMode,
    addIssueComponents,
    selectedIssue,
    setSelectedPreviewDoc,
    setIssueModalProps,
    setIsIssueModal,
    setSelectedIssue,
    setSelectedViewpoint,
    bubbleIssue,
    createIssue,
  } = props;
  const { projectId } = useParams();
  const { data: user } = useCurrentUserQuery();
  const { current_user_role } = useSelector((state) => state.projects);
  const { data: disciplinesData } = useProjectDisciplinesQuery();
  const { data: floorsData } = useProjectFloorsQuery();
  const { data: buildingsData } = useProjectBuildingsQuery();
  const { sync_data } = useSelector((state) => state.project);
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [curEditIssue, setCurEditIssue] = useState(null);
  const [filterQuery, setFilterQuery] = useState(null);
  const [view, setView] = useState(NONE_VIEW);
  const [isReset, setIsReset] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [, setApiError] = useState("");
  const {
    statuses,
    priorities,
    types,
    users,
    categories,
    buildings,
    floors,
    workphases,
  } = issueFilterItems;
  const {
    default_select,
    initialFilterValues,
    filterValues,
    filters,
    newFilters,
    currentFilter,
    showFilterModal,
    setFilterValues,
    setNewFilters,
    setCurrentFilter,
    getFilters,
    handleSaveFilter,
    handeleDeleteFilter,
    updateFilterValues,
    setShowFilterModal,
    getFilterQuery,
    resetFilterValues,
  } = useIssueFilters({
    user,
    statuses,
  });
  useFilterOptions({
    default_select,
    ...issueFilterItems,
    allDocuments,
    allEmails,
    tagOptions,
    currentFilter,
    setFilterValues,
  });

  const updateIssueTag = useDebouncedCallback(async (tags, issueId) => {
    const tagIds = tags.map((tag) => tag.value);
    const params = [
      {
        id: issueId,
        tagIds: { value: tagIds },
      },
    ];
    await curEditIssue(params);
  }, 500);

  useEffect(() => {
    const refreshLoading = () => {
      if (!filterQuery) return;
      loadMore(filterQuery, false);
    };
    refreshLoading();
  }, [filterQuery]);

  const applyFilters = useCallback(() => {
    setFilterQuery(getFilterQuery({}, searchText));
  }, [searchText, filterValues]);

  useEffect(() => {
    const inputSearchText = () => {
      setFilterQuery(getFilterQuery({}, searchText));
    };
    inputSearchText();
  }, [searchText, initialFilterValues]);

  const loadMore = useDebouncedCallback(
    async (filterQuery = {}, isLoadMore = false) => {
      if (!isLoadMore && !isReset) setIsLoading(true);

      let buffer = await getIssuesByProjectId(
        projectId,
        isLoadMore ? filteredIssues.length : PER_PAGE_RECORDS,
        isLoadMore ? filteredIssues.length : 0,
        filterQuery
      );
      buffer = buffer.map((issue) => formatIssue(issue, disciplinesData, buildingsData, floorsData));
      buffer = await getIssuesWithLogViewpoints(buffer);
      if (isLoadMore) setFilteredIssues((prev) => [...prev, ...buffer]);
      else setFilteredIssues(buffer);
      setIsLoading(false);
    },
    500
  );

  const updateStatus = useCallback(
    async (issue, status) => {
      if (
        status.id !== issue?.issueStatus?.id &&
        (current_user_role !== 2 || status.originalName !== "Closed")
      ) {
        await editIssue(
          {
            id: issue.id,
            statusId: { value: status.value },
          },
          setApiError
        );
      }
    },
    [current_user_role]
  );

  const updateIssuesBySyncService = useCallback(
    async (changedIssues) => {
      let buffer = [...filteredIssues];

      changedIssues.forEach((issue) => {
        const index = buffer.findIndex((item) => item.id === issue.id);
        if (index >= 0) buffer[index] = issue;
        else buffer = [issue, ...filteredIssues];
      });

      let issues = await getIssuesWithLogViewpoints(changedIssues);
      issues.forEach((issue) => {
        const changedIssue = formatIssue(issue, disciplinesData, buildingsData, floorsData);
        buffer = buffer.map((issue) =>
          issue.id === changedIssue.id ? changedIssue : issue
        );
      });
      setFilteredIssues(buffer);

      if (
        selectedIssue &&
        changedIssues.find((issue) => issue.id === selectedIssue.id)
      )
        setSelectedIssue(buffer.find((issue) => issue.id === selectedIssue.id));
      else
        setSelectedIssue(
          buffer.find((issue) => issue.id === changedIssues[0].id)
        );
      setView(DETAIL_VIEW);
    },
    [filteredIssues, selectedIssue, disciplinesData, buildingsData, floorsData]
  );

  useEffect(() => {
    const effect = async () => {
      if (!sync_data?.dataSummaryEntries) return;

      sync_data.dataSummaryEntries.forEach(async (entry) => {
        if (entry.dataType === SyncDataType.Issue) {
          updateIssuesBySyncService(await getIssuesByIds(getChangedIds(entry)));
        }
      });
    };
    effect();
  }, [sync_data]);

  useEffect(() => {
    const initIssueModalProps = () => {
      setIssueModalProps({
        projectId: projectId,
        ...issueFilterItems,
        users,
        tagOptions,
        editIssue: curEditIssue,
        mode: curEditIssue ? IssueModalMode.EDIT : IssueModalMode.CREATE,
        selected: [],
        issues: allIssues,
        setEditIssue: setCurEditIssue,
      });
    };
    initIssueModalProps();
  }, [
    projectId,
    users,
    issueFilterItems,
    tagOptions,
    allIssues?.length,
    curEditIssue,
  ]);

  useEffect(() => {
    if (!projectId) return;
    getFilters();
  }, [projectId]);

  useEffect(() => {
    if (bubbleIssue) {
      const issue = filteredIssues.find((i) => i.id === bubbleIssue.id);
      if (issue) {
        setSelectedIssue(issue);
        setView(DETAIL_VIEW);
      }
    }
  }, [bubbleIssue]);

  useEffect(() => {
    if (!isReset) return;
    applyFilters();
  }, [isReset]);

  const onCreateIssue = () => {
    createIssue();
    setCurEditIssue(null);
  };

  return (
    <div className={`model-issues ${isVisible ? "" : "hide"}`}>
      {issueAddMode !== MODE_ADD_NONE && <ProtectPanel />}
      <Loader isLoading={isLoading} />
      <ModelIssueHeader
        showFilterView={() => setView(FILTER_VIEW)}
        onCreateIssue={onCreateIssue}
      />
      {view === NONE_VIEW && (
        <IssueBoardColumnOld
          name="issues"
          group={{}}
          tagOptions={tagOptions}
          types={types}
          priorities={priorities}
          data={filteredIssues}
          isModelIssues={true}
          viewIssue={(issue) => {
            setSelectedIssue(issue);
            setView(DETAIL_VIEW);
          }}
          selectedIssue={selectedIssue}
          setSelectedIssue={setSelectedIssue}
          loadMore={loadMore}
          filterQuery={filterQuery}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      )}
      {
        <AsideIssueFilter
          show_aside={view === FILTER_VIEW}
          priorities={getValuesAddedAll(priorities)}
          types={getValuesAddedAll(types)}
          statuses={statuses}
          categories={getValuesAddedAll(categories)}
          floors={getValuesAddedAll(floors)}
          buildings={getValuesAddedAll(buildings)}
          workphases={getValuesAddedAll(workphases)}
          users={getValuesAddedAll(users)}
          tagOptions={tagOptions}
          filters={filters}
          currentFilter={currentFilter}
          toggleAside={() => setView(NONE_VIEW)}
          onSubmit={() => {
            applyFilters();
            setView(NONE_VIEW);
            setIsReset(false);
          }}
          onReset={() => {
            resetFilterValues();
            setCurrentFilter({});
            setIsReset(true);
          }}
          setShowFilterModal={setShowFilterModal}
          setCurrentFilter={setCurrentFilter}
          handeleDeleteFilter={handeleDeleteFilter}
          handleSaveFilter={handleSaveFilter}
          filterValues={filterValues}
          updateFilterValues={updateFilterValues}
        />
      }
      {showFilterModal && (
        <SaveFilterModal
          hideFilterModal={() => setShowFilterModal(false)}
          setNewFilters={setNewFilters}
          newFilters={newFilters}
          handleSaveFilter={handleSaveFilter}
        />
      )}
      {!!selectedIssue && view === DETAIL_VIEW && (
        <AsideIssue
          canEditIssue={true}
          show_aside={true}
          statuses={statuses}
          users={users}
          disciplines={categories}
          buildings={buildings}
          floors={floors}
          workphases={workphases}
          tagOptions={tagOptions}
          types={types}
          priorities={priorities}
          issue={selectedIssue}
          toggleAside={() => {
            setSelectedIssue(null);
            setView(NONE_VIEW);
          }}
          updateStatus={updateStatus}
          updateTag={updateIssueTag}
          allEmails={allEmails}
          allDocuments={allDocuments}
          allIssues={allIssues}
          setSelectedPreviewDoc={setSelectedPreviewDoc}
          setCurrentIssue={setSelectedIssue}
          setEditIssue={setCurEditIssue}
          isDocumentPreview={false}
          isModelIssues={true}
          setIsIssueModal={setIsIssueModal}
          components={addIssueComponents}
          openViewpointIn3D={setSelectedViewpoint}
        />
      )}
    </div>
  );
}
