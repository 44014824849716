import { Camera, Color, Material, Mesh, MOUSE, Vector3 } from "three";
import { MouseControl } from "./components/context/camera/controls/orbit-control";
import { StandardControl } from "./components/context/camera/controls/first-person-control";
import { LiteEvent } from "./utils/LiteEvent";
import { IfcContext } from "./components";
import { IFCModel } from "./IFCModel";
import { VisoSolidMesh } from "./VisoSolideMesh";

export interface MouseButtons {
  left: MOUSE;
  middle: MOUSE;
  right: MOUSE;
}

export enum NavigationModes {
  Standard,
  Mouse,
}

export enum CameraProjections {
  Perspective,
  Orthographic,
}

export interface NavigationMode {
  mode: NavigationModes;
  toggle: (active: boolean, options?: any) => void;
  enabled: boolean;
  onChange: LiteEvent<any>;
  onChangeProjection: LiteEvent<Camera>;
  isLocked: () => boolean;
}

export interface NavModeManager {
  [NavigationModes.Mouse]: MouseControl;
  [NavigationModes.Standard]: StandardControl;
}

export interface ViewerOptions {
  container: HTMLElement;
  preselectMaterial?: Material;
  selectMaterial?: Material;
  backgroundColor?: Color;
}

interface Component {
  update: (_delta: number) => void;
  dispose: () => void;
}

export interface Items {
  components: Component[];
  ifcModels: IFCModel[];
  pickableIfcModels: VisoSolidMesh[];
  hoverMesh: Mesh | null;
}

export abstract class IfcComponent implements Component {
  protected constructor(context: IfcContext) {
    context.addComponent(this);
  }

  update(_delta: number) { }

  dispose() { }
}

export interface fpsControl {
  active: boolean;
  keys: string[];
}

export interface fpsControls {
  forward: fpsControl;
  back: fpsControl;
  right: fpsControl;
  left: fpsControl;
  up: fpsControl;
  down: fpsControl;
}

export enum dimension {
  x = "x",
  y = "y",
  z = "z",
}

export interface ViewerSetting {
  control: NavigationModes;
  camera: CameraProjections;
  spaces: boolean;
  openings: boolean;
  grid: boolean;
  background?: string;
  sectionOutline?: string;
  sectionSpace?: string;
  navCube: boolean;
}

export interface CameraPose {
  position: Vector3;
  direction: Vector3;
}
