import React from "react";
import { useTranslation } from "react-i18next";

const PluginItem = ({ item, onItemSelect, selectedItems }) => {
  const { t } = useTranslation();
  const { img, category, title, capacity, versions } = item;

  /* category = 0 : software, category = 1: plugin */
  return (
    <div
      className={`plugin-item ${
        selectedItems[title] && !item.versions ? "input-pass" : ""
      }`}
      onClick={(event) => onItemSelect(event, title, false)}
    >
      <svg className="icon">
        <use xlinkHref={img} />
      </svg>
      <p className={`category ${category === 0 ? "software" : "plugin"}`}>
        {category === 0 ? "software" : "plugin"}
      </p>
      <h4>{title}</h4>
      {capacity ? (
        <p>{`${capacity}MB`}</p>
      ) : (
        <div className="version-dropdown">
          <div className="version-dropdown-title">
            <p>{`${versions.length} ${t(
              "versions_available",
              "versions available"
            )}`}</p>
            <i
              className={`${selectedItems[title] ? "arrow-up" : "arrow-down"}`}
            ></i>
          </div>
          <div
            className={`version-dropdown-content custom-scrollbar${
              selectedItems[title] ? "" : " hide"
            }`}
          >
            {versions.map((item, index) => (
              <div
                key={`version-item_${index}`}
                className={`version-item ${
                  selectedItems[item.title] ? "input-pass" : ""
                }`}
                onClick={(event) => onItemSelect(event, item.title, true)}
              >
                <h5>{item.title}</h5>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PluginItem;
