import {
  Box3,
  Camera,
  MathUtils,
  Mesh,
  OrthographicCamera,
  PerspectiveCamera,
  Vector2,
  Vector3,
} from "three";
import {
  CameraProjections,
  IfcComponent,
  NavigationModes,
} from "../../../base-types";
import { LiteEvent } from "../../../utils/LiteEvent";
import { ProjectionManager } from "./projection-manager";
import { IfcContext } from "../context";
import { CameraPose, NavigationMode, NavModeManager } from "../..";
import { StandardControl } from "./controls/first-person-control";
import { MouseControl } from "./controls/orbit-control";
import { CameraInfo } from "../../../viso-types";
import { RightToLeftHand } from "../../../utils/ThreeUtils";
import * as TWEEN from "@tweenjs/tween.js";

const frustumSize = 50;
const INITCAMERAPOS = new Vector3(0, 0.2, 1);
const X_AXIS = new Vector3(1, 0, 0);
const Y_AXIS = new Vector3(0, 1, 0);

export class IfcCamera extends IfcComponent {
  readonly perspectiveCamera: PerspectiveCamera;
  readonly orthographicCamera: OrthographicCamera;

  navMode: NavModeManager;
  currentNavMode: NavigationMode;

  public readonly onChange = new LiteEvent<any>();
  public readonly onChangeProjection = new LiteEvent<Camera>();
  private readonly context: IfcContext;
  private readonly projectionManager: ProjectionManager;

  private cameraDir = new Vector3();

  constructor(context: IfcContext) {
    super(context);
    this.context = context;

    const dims = this.context.getDimensions();
    const aspect = dims.x / dims.y;
    this.perspectiveCamera = new PerspectiveCamera(45, aspect, 0.1, 1000);

    this.orthographicCamera = new OrthographicCamera(
      (frustumSize * aspect) / -2,
      (frustumSize * aspect) / 2,
      frustumSize / 2,
      frustumSize / -2,
      0.1,
      1000
    );
    this.setupCameras();
    this.projectionManager = new ProjectionManager(context, this);

    this.navMode = {
      [NavigationModes.Standard]: new StandardControl(this.context, this),
      [NavigationModes.Mouse]: new MouseControl(this.context, this),
    };

    this.currentNavMode = this.navMode[NavigationModes.Standard];

    Object.values(this.navMode).forEach((mode) => {
      mode.onChange?.on(() => this.triggerEvent());
      mode.onChangeProjection.on(this.onChangeProjection.trigger);
    });
  }

  get projection() {
    return this.projectionManager.projection;
  }

  set projection(projection: CameraProjections) {
    this.projectionManager.projection = projection;
    this.navMode[NavigationModes.Mouse].setProjection(projection);
  }

  get activeCamera() {
    return this.projectionManager.activeCamera;
  }

  get isLocked() {
    return this.currentNavMode.isLocked();
  }

  update(_delta: number) {
    super.update(_delta);
    this.navMode[NavigationModes.Mouse].update(_delta);
    TWEEN.update();
  }

  updateAspect(dims?: Vector2) {
    if (!dims) dims = this.context.getDimensions();
    this.perspectiveCamera.aspect = dims.x / dims.y;
    this.perspectiveCamera.updateProjectionMatrix();
    this.setOrthoCameraAspect(dims);
  }

  setNavigationMode(mode: NavigationModes) {
    if (this.currentNavMode.mode === mode) return;
    this.currentNavMode.toggle(false);
    this.currentNavMode = this.navMode[mode];
    this.currentNavMode.toggle(true);
  }

  toggleProjection() {
    const isOrto = this.projection === CameraProjections.Orthographic;
    this.projection = isOrto
      ? CameraProjections.Perspective
      : CameraProjections.Orthographic;

    this.onChangeProjection.trigger(this.activeCamera);
  }

  fitModelToFrame() {
    this.setCameraDirection(INITCAMERAPOS.clone());
  }

  setCameraDirection(direction: Vector3) {
    if (this.context.items.ifcModels.length == 0) return;
    this.context.calculateBoundingBox();
    const boxSize = this.context.boundingBox.getSize(new Vector3()).length();
    const boxCenter = this.context.boundingBox.getCenter(new Vector3());

    const halfSizeToFitOnScreen = boxSize * 0.5;
    const halfFovY = MathUtils.degToRad(this.perspectiveCamera.fov * 0.5);
    const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);

    direction.multiplyScalar(distance).add(boxCenter);
    new TWEEN.Tween(this.activeCamera.position)
      .to(direction, 1000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(() => {
        if (this.projection === CameraProjections.Perspective) {
          this.perspectiveCamera.updateProjectionMatrix();
        } else {
          const dims = this.context.getDimensions();
          const aspect = dims.x/dims.y;
          const width = halfSizeToFitOnScreen*aspect;
          this.orthographicCamera.zoom =1;
          this.orthographicCamera.left=-width;
          this.orthographicCamera.right=width;
          this.orthographicCamera.top=halfSizeToFitOnScreen;
          this.orthographicCamera.bottom=-halfSizeToFitOnScreen;

          this.orthographicCamera.updateProjectionMatrix();
        }

        this.activeCamera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z);

        if (this.currentNavMode.mode == NavigationModes.Mouse) {
          this.navMode[NavigationModes.Mouse].setLookAt(
            this.activeCamera.position,
            boxCenter
          );
        }

        this.triggerEvent();
      })
      .start();
  }

  rotateCamera(yaw: number, pitch: number) {
    if (this.context.items.ifcModels.length == 0) return;

    this.context.calculateBoundingBox();
    const target = this.context.boundingBox.getCenter(new Vector3());

    const pos = this.activeCamera.position.clone().sub(target);
    pos.applyAxisAngle(this.activeCamera.up, yaw);

    this.activeCamera.getWorldDirection(this.cameraDir);
    const axis = this.cameraDir.clone().cross(this.activeCamera.up).normalize();
    const nextAxis = this.cameraDir.clone().applyAxisAngle(axis, pitch).normalize();
    nextAxis.cross(this.activeCamera.up).normalize();

    if (nextAxis.dot(axis) > 0) {
      pos.applyAxisAngle(axis, pitch);
    }

    pos.add(target);

    this.activeCamera.position.copy(pos);
    this.activeCamera.lookAt(target.x, target.y, target.z);

    if (this.projection === CameraProjections.Perspective) {
      this.perspectiveCamera.updateProjectionMatrix();
    } else {
      this.orthographicCamera.updateProjectionMatrix();
    }

    if (this.currentNavMode.mode == NavigationModes.Mouse) {
      this.navMode[NavigationModes.Mouse].setLookAt(
        this.activeCamera.position,
        target
      );
    }

    this.triggerEvent();
  }

  toggleCameraControls(active: boolean) {
    if (this.currentNavMode.mode == NavigationModes.Standard) return;
    this.navMode[NavigationModes.Mouse].toggle(active);
  }

  targetItem(mesh: Mesh) {
    const modelBox = this.context.boundingBox.clone();
    const modelCenter = modelBox.getCenter(new Vector3());

    mesh.geometry.computeBoundingBox();
    const meshBox = new Box3().setFromObject(mesh);
    const meshCenter = meshBox.getCenter(new Vector3());

    let targetToBoundDir = meshCenter.clone().sub(modelCenter);
    if (targetToBoundDir.lengthSq() > 0.001) {
      targetToBoundDir.normalize();
    } else {
      this.activeCamera.getWorldDirection(targetToBoundDir);
    }

    const center2d = new Vector2(meshCenter.x, meshCenter.z);
    const rayDirection2d = new Vector2(
      targetToBoundDir.x,
      targetToBoundDir.z
    ).normalize();

    const min = new Vector2(modelBox.min.x, modelBox.min.z);
    const max = new Vector2(modelBox.max.x, modelBox.max.z);

    const hitResult = this.intersectRayAABB(center2d, rayDirection2d, min, max);
    if (hitResult.result) {
      rayDirection2d.multiplyScalar(1.1 * hitResult.hitDistance);
    } else {
      const boxSize = modelBox.getSize(new Vector3());
      rayDirection2d.multiplyScalar(
        Math.max(Math.max(boxSize.x, boxSize.y), boxSize.z)
      );
    }

    const cameraOffsetFromTarget = new Vector3(
      rayDirection2d.x,
      rayDirection2d.length(),
      rayDirection2d.y
    );

    const halfFovY = MathUtils.degToRad(this.perspectiveCamera.fov * 0.5);
    const meshSize = meshBox.getSize(new Vector3());
    const maxTargetSizeComponent = Math.max(
      Math.max(meshSize.x, meshSize.y),
      meshSize.z
    );
    const frameDistanceMultiplier = 0.9 / Math.tan(halfFovY);
    const frameDistanceFromTarget =
      maxTargetSizeComponent * frameDistanceMultiplier;
    if (
      cameraOffsetFromTarget.lengthSq() <
      frameDistanceFromTarget * frameDistanceFromTarget
    ) {
      cameraOffsetFromTarget
        .normalize()
        .multiplyScalar(frameDistanceFromTarget);
    }

    cameraOffsetFromTarget.add(meshCenter);
    this.activeCamera.position.copy(cameraOffsetFromTarget);

    if (this.projection === CameraProjections.Perspective) {
      this.perspectiveCamera.updateProjectionMatrix();
    } else {
      this.orthographicCamera.updateProjectionMatrix();
    }

    this.activeCamera.lookAt(meshCenter);
    if (this.currentNavMode.mode == NavigationModes.Mouse) {
      this.navMode[NavigationModes.Mouse].setLookAt(
        this.activeCamera.position,
        meshCenter
      );
    }

    this.triggerEvent();
  }

  dispose(): void {
    super.dispose();
    this.navMode[NavigationModes.Standard].dispose();
  }

  getCameraPose() {
    this.activeCamera.getWorldDirection(this.cameraDir);
    return {
      position: this.activeCamera.position,
      direction: this.cameraDir.clone(),
    } as CameraPose;
  }

  setPosition(position: Vector3) {
    this.activeCamera.getWorldDirection(this.cameraDir);
    this.activeCamera.position.copy(position);

    if (this.projection === CameraProjections.Perspective) {
      this.perspectiveCamera.updateProjectionMatrix();
    } else {
      this.orthographicCamera.updateProjectionMatrix();
    }

    if (this.currentNavMode.mode == NavigationModes.Mouse) {
      const target = position.clone().add(this.cameraDir);
      this.navMode[NavigationModes.Mouse].setLookAt(
        this.activeCamera.position,
        target
      );
    }

    this.triggerEvent();
  }

  getCameraInfo() {
    const isPerspective = this.projection == CameraProjections.Perspective;
    const camPos = this.getCameraPose();
    const location = RightToLeftHand(camPos.position);
    const direction = RightToLeftHand(camPos.direction);
    const factor = isPerspective
      ? this.perspectiveCamera.fov
      : this.orthographicCamera.zoom;
    const up = this.getCameraUpVector(direction);

    return { isPerspective, location, direction, up, factor } as CameraInfo;
  }

  private getCameraUpVector(direction: Vector3) {
    const up = new Vector3(0, 0, 1);
    const right = new Vector3().crossVectors(up, direction).normalize();
    up.crossVectors(direction, right).normalize();
    return up;
  }

  private triggerEvent() {
    this.onChange.trigger(this.getCameraPose());
  }

  private intersectRayAABB(
    rayOrigin: Vector2,
    rayDirection: Vector2,
    min: Vector2,
    max: Vector2
  ) {
    const dirInv = new Vector2(1 / rayDirection.x, 1 / rayDirection.y);
    const tx1 = (min.x - rayOrigin.x) * dirInv.x;
    const tx2 = (max.x - rayOrigin.x) * dirInv.x;

    let tmin = Math.min(tx1, tx2);
    let tmax = Math.max(tx1, tx2);

    const ty1 = (min.y - rayOrigin.y) * dirInv.y;
    const ty2 = (max.y - rayOrigin.y) * dirInv.y;

    tmin = Math.max(tmin, Math.min(ty1, ty2));
    tmax = Math.min(tmax, Math.max(ty1, ty2));

    const t = tmin < 0 ? tmax : tmin;

    return { result: tmax >= tmin, hitDistance: t };
  }

  private setOrthoCameraAspect(dims: Vector2) {
    const aspect = dims.x / dims.y;
    this.orthographicCamera.left = (-frustumSize * aspect) / 2;
    this.orthographicCamera.right = (frustumSize * aspect) / 2;
    this.orthographicCamera.top = frustumSize / 2;
    this.orthographicCamera.bottom = -frustumSize / 2;
    this.orthographicCamera.updateProjectionMatrix();
  }

  private setupCameras() {
    this.setCameraPositionAndTarget(this.perspectiveCamera);
    this.setCameraPositionAndTarget(this.orthographicCamera);
  }

  private setCameraPositionAndTarget(camera: Camera) {
    camera.position.z = 1.7;
    camera.position.y = 10;
    camera.position.x = 10;
    camera.lookAt(new Vector3(0, 0, 0));
  }
}
