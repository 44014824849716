import React from 'react';

interface RadioProps {
  className: string;
  label: string;
  name: string;
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

function Radio({
  className = '',
  label,
  name,
  checked,
  onChange,
  disabled,
}: RadioProps) {
  return (
    <label className={className} htmlFor={name}>
      <input
        type="radio"
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      { label }
    </label>
  );
}

Radio.defaultProps = {
  disabled: false,
};

export default Radio;
