import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import IFCViewer from "./IFCViewer";

import { setToIFCFolder } from "../../Containers/ProjectContainer/Actions";
import { getGeneralSvg } from "../SVGs";

const NoModelUpoad = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const toIFCFolder = () => {
    navigate(`/projects/${projectId}/documents`);
    dispatch(setToIFCFolder(true));
  };

  return (
    <div className="models-content-viewer_empty">
      {getGeneralSvg("flag")}
      <h3 className="models-content-viewer_empty-title">
        {t("no_models_uploaded", "No models uploaded")}
      </h3>
      <p
        className="models-content-viewer_empty-description"
        dangerouslySetInnerHTML={{
          __html: t(
            "no_models_uploaded_description",
            'You didn’t upload any model in your project so far. To start using the IFC Viewer <span style="color: #4b6ffc">direct into the DMS</span> upload your model files and import them in the viewer'
          ),
        }}
      />
      {getGeneralSvg("model_back")}
      <button className="btn btn--secondary" onClick={toIFCFolder}>
        {t("open_ifc_folder", "Open IFC Folder")}
      </button>
    </div>
  );
};

const ModelView = (props) => {
  const {
    issues,
    issueFilterItems,
    isNoModelUploaded,
    isOpenComponentDetail,
    selectModels,
    viewerSettings,
    handleLoading,
    registerStoreys,
    floorMapSetting,
    onShowComponentDetails,
    selectedComponentInfo,
    setSelectedComponentInfo,
    historyNode,
    setIsModifiedIssueComponents,
    setAddIssueComponents,
    setAddIssueViewpoints,
    issueAddMode,
    setIssueAddMode,
    linkedComponentsGlobalIds,
    setIsIssueModal,
    isIssueModal,
    selectedViewpoint,
    resetViewpoint,
    setBubbleIssue,
    issueComponents,
    selectedIssue,
    isCreatingIssue,
    storeyModelIds,
  } = props;

  return (
    <div className="models-content-viewer">
      {isNoModelUploaded ? (
        <div className="models-content-viewer_inner">
          <NoModelUpoad />
        </div>
      ) : (
        <IFCViewer
          issues={issues}
          issueFilterItems={issueFilterItems}
          isOpenComponentDetail={isOpenComponentDetail}
          selectedModels={selectModels}
          handleLoading={handleLoading}
          settings={viewerSettings}
          viewpoint={selectedViewpoint}
          registerStoreys={registerStoreys}
          floorMapSetting={floorMapSetting}
          onShowComponentDetails={onShowComponentDetails}
          selectedComponentInfo={selectedComponentInfo}
          setSelectedComponentInfo={setSelectedComponentInfo}
          historyNode={historyNode}
          setIsModifiedIssueComponents={setIsModifiedIssueComponents}
          setAddIssueComponents={setAddIssueComponents}
          setAddIssueViewpoints={setAddIssueViewpoints}
          issueAddMode={issueAddMode}
          setIssueAddMode={setIssueAddMode}
          linkedComponentsGlobalIds={linkedComponentsGlobalIds}
          setIsIssueModal={setIsIssueModal}
          isIssueModal={isIssueModal}
          resetViewpoint={resetViewpoint}
          setBubbleIssue={setBubbleIssue}
          issueComponents={issueComponents}
          selectedIssue={selectedIssue}
          isCreatingIssue={isCreatingIssue}
          storeyModelIds={storeyModelIds}
        />
      )}
    </div>
  );
};

export default ModelView;
