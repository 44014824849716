/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function DashboardIcon({
  htmlColor,
  fontSize,
}: Pick<SvgIconProps, 'htmlColor' | 'fontSize'>) {
  return (
    <SvgIcon htmlColor={htmlColor} fontSize={fontSize} viewBox="0 0 16 16">
      <rect x="0" y="0" width="7" height="7" rx="1" strokeWidth="1.3" />
      <rect x="0" y="9" width="7" height="7" rx="1" strokeWidth="1.3" />
      <rect x="9" y="0" width="7" height="16" rx="1" strokeWidth="1.3" />
    </SvgIcon>
  );
}
