import React, { useCallback, useState } from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import useProjectDeleteMutation from 'projects/hooks/useProjectDeleteMutation';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import { Trans, useTranslation } from 'react-i18next';
import ProjectDto from 'projects/types/ProjectDto';

interface DeleteProjectDialogProps {
  project: ProjectDto,
  onClose: () => void,
}

export default function DeleteProjectDialog({
  project,
  onClose,
}: DeleteProjectDialogProps) {
  const { t } = useTranslation('projects');
  const { mutateAsync: deleteProject } = useProjectDeleteMutation();
  const getRequestErrorMessage = useRequestErrorMessage();
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const onClickConfirm = useCallback(async () => {
    try {
      await deleteProject(project.id);
      setSuccessDialogOpen(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [deleteProject, getRequestErrorMessage, project]);

  const onCloseSuccessDialog = useCallback(() => {
    onClose();
  }, [onClose]);

  const onClickCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog id="DeleteProjectDialog" open>
      <DialogTitle>{t('delete-project-dialog_title', 'Delete Project')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography>
          <Trans
            t={t}
            i18nKey="delete-project-dialog_text"
            defaults="Are you sure that you want to permanently delete the project <bold>&quot;{{projectName}}&quot;</bold>?"
            values={{ projectName: project.name }}
            components={{ bold: <strong /> }}
          />
        </Typography>
        <Alert severity="warning">{t('delete-project-dialog_warning', 'This action can not be undone.')}</Alert>
        {!!errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Dialog id="DeleteProjectSuccessDialog" open={successDialogOpen}>
          <DialogTitle>{t('delete-project-success-dialog_title', 'Project Deleted')}</DialogTitle>
          <DialogContent>
            <Alert severity="info">{t('delete-project-success-dialog_text', 'The project "{{projectName}}" was permanently deleted.', { projectName: project.name })}</Alert>
          </DialogContent>
          <DialogActions sx={{ gap: 2 }}>
            <Button variant="contained" color="primary" onClick={onCloseSuccessDialog} sx={{ minWidth: 200 }}>{t('delete-project-success-dialog_confirm-button-label', 'Close')}</Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
      <DialogActions sx={{ gap: 2 }}>
        <Button variant="contained" color="secondary" onClick={onClickCancel}>{t('delete-project-dialog_cancel-button-label', 'Cancel')}</Button>
        <Button variant="contained" color="primary" onClick={onClickConfirm}>{t('delete-project-dialog_confirm-button-label', 'Delete Project')}</Button>
      </DialogActions>
    </Dialog>
  );
}
