import React, { useState } from "react";
import PropTypes from "prop-types";
import Viewer from "react-viewer";

const AsideIssueViewpoint = ({
  image,
  isDisableFullScreen,
  data,
  openViewpoint,
}) => {
  const [is_visible, setIsVisible] = useState(false);

  const onClick = () => {
    if (isDisableFullScreen) {
      openViewpoint(data);
    } else {
      setIsVisible(true);
    }
  };

  return (
    <div className="viewpoints__item">
      <img src={"data:image/jpeg;" + image} onClick={onClick} alt="" />
      {!isDisableFullScreen && (
        <Viewer
          visible={is_visible}
          onClose={() => setIsVisible(false)}
          images={[{ src: `data:image/jpeg;${image}`, alt: "" }]}
        />
      )}
    </div>
  );
};

AsideIssueViewpoint.propTypes = {
  image: PropTypes.string,
};

export default AsideIssueViewpoint;
