import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import WebViewer from "@pdftron/webviewer";
import useApiConfig from 'api/hooks/useApiConfig';
import { licenseKey } from "./CompareFeature";

const CompareFeature = ({ optionsToken, data, data2, currentLanguage }) => {
  const docViewImage = useRef(null);
  const { apiUrl, pdfTronUrl } = useApiConfig();
  const urlForComapre = `${apiUrl}/resource/`;

  useEffect(() => {
    WebViewer(
      {
        licenseKey: licenseKey,
        path: "/webviewer",
        enableMeasurement: true,
        fullAPI: true,
        webviewerServerURL: pdfTronUrl,
      },
      docViewImage.current
    ).then(async (instance) => {
      const { PDFNet } = instance.Core;
      await PDFNet.initialize();

      instance.UI.setLanguage(currentLanguage);
      instance.UI.disableElements([
        "toggleNotesButton",
        "notesPanelResizeBar",
        "toolbarGroup-Insert",
        "toolbarGroup-FillAndSign",
        "toolbarGroup-Forms",
      ]);

      let Annotations = null;

      const getDiffOptions = async () => {
        const redColor = new Annotations.Color(233, 62, 62);
        const blueColor = new Annotations.Color(0, 143, 47);

        const options = await PDFNet.createDiffOptions();
        // instead of Annotations.Color, we can pass in an objects in the form {R: 200, G: 0, B: 0, A: 1}
        options.setColorA(redColor);
        options.setColorB(blueColor);
        options.setBlendMode(PDFNet.GState.BlendMode.e_bl_difference);
        return options;
      };

      const compareDoc = async (doc1, doc2) => {
        const newDoc = await PDFNet.PDFDoc.create();
        await newDoc.lock();
        const options = await getDiffOptions();

        const pages = [];
        const itr = await doc1.getPageIterator(1);
        const itr2 = await doc2.getPageIterator(1);

        let i = 0;
        for (itr; await itr.hasNext(); itr.next()) {
          const page = await itr.current();
          pages[i] = [page];
          i++;
        }
        i = 0;
        for (itr2; await itr2.hasNext(); itr2.next()) {
          const page = await itr2.current();
          (pages[i] || (pages[i] = [null])).push(page);
          i++;
        }

        for (let [p1, p2] of pages) {
          if (!p1) {
            p1 = new PDFNet.Page(0);
          }
          if (!p2) {
            p2 = new PDFNet.Page(0);
          }

          await newDoc.appendVisualDiff(p1, p2, options);
        }

        await newDoc.unlock();
        instance.UI.loadDocument(newDoc);
      };

      const init = async () => {
        Annotations = instance.Core.Annotations;
        await PDFNet.initialize();
      };

      await init();
      const doc1 = await PDFNet.PDFDoc.createFromURL(
        `${urlForComapre}${data2}`,
        optionsToken
      );
      const doc2 = await PDFNet.PDFDoc.createFromURL(
        `${urlForComapre}${data}`,
        optionsToken
      );
      await compareDoc(doc1, doc2);
    });
  }, []);

  return <div className="preview-section-content" ref={docViewImage}></div>;
};

CompareFeature.propTypes = {
  useFileUrl: PropTypes.func,
  setFileUrl: PropTypes.func,
  resourceId: PropTypes.string,
  setLoading: PropTypes.func,
};

export default CompareFeature;
