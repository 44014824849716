import { QueryFunction, QueryObserverOptions } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import LabelDto from 'labels/types/LabelDto';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { VisoDetailsQueryKey } from 'api/hooks/useDefaultEntityQueryKeys';
import useDefaultOdataQueryFn from 'api/hooks/useDefaultOdataQueryFn';
import useLabelsOdataQueryKey from 'labels/hooks/useLabelsOdataQueryKey';

export type LabelQueryKey = VisoDetailsQueryKey<ApiEndpoint.Label>;
export type LabelQueryFnData = LabelDto | undefined;
export type LabelData = LabelDto | undefined;
export type LabelQueryOptions = Partial<Omit<QueryObserverOptions<LabelQueryFnData, unknown, LabelData, LabelQueryFnData, LabelQueryKey>, 'queryKey'>>;

export default function useLabelQueryOptions() {
  const odataQueryFn = useDefaultOdataQueryFn<LabelDto, LabelDto, ApiEndpoint.Label>();
  const { getQueryKey } = useLabelsOdataQueryKey();
  const queryFn = useCallback<QueryFunction<LabelDto | undefined, VisoDetailsQueryKey<ApiEndpoint.Label>>>(async ({ queryKey }) => {
    const [, , , id] = queryKey;
    const odataQueryKey = getQueryKey({ filter: { id: { eq: id } } });
    const odataResult = await odataQueryFn({ queryKey: odataQueryKey });
    if (!odataResult.length) throw new Error('Requested label not part of API response');
    return odataResult[0];
  }, [getQueryKey, odataQueryFn]);
  return useMemo<LabelQueryOptions>(() => ({
    queryFn,
  }), [queryFn]);
}
