import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import IssueDto from 'issues/types/IssueDto';
import UpdateIssueDto from 'issues/types/UpdateIssueDto';
import { useCallback } from 'react';

export default function useIssueUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { baseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Issue);
  const mutationFn = useCallback(async (dto: UpdateIssueDto) => {
    const { data: issueDtos } = await axiosInstance.put<IssueDto[]>(`${ApiEndpoint.Issue}`, [dto]);
    return issueDtos[0];
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: baseQueryKey });
  }, [baseQueryKey, queryClient]);
  return useMutation<IssueDto, unknown, UpdateIssueDto>({
    mutationFn,
    onSuccess,
  });
}
