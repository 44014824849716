import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ResourceQueryKey } from 'resources/hooks/useResourceQueryOptions';
import useResourceQueryKey from 'resources/hooks/useResourceQueryKey';

type ResourceUseQueryOptions = Omit<UseQueryOptions<string | undefined, unknown, string | undefined, ResourceQueryKey | readonly []>, 'queryFn'>;

export default function useResourceQuery(id: string | undefined, options?: Partial<ResourceUseQueryOptions>) {
  const { getQueryKey } = useResourceQueryKey();
  const queryKey = useMemo(() => (id ? getQueryKey(id) : ([] as const)), [getQueryKey, id]);
  const queryOptions = useMemo<ResourceUseQueryOptions>(() => ({
    ...options,
    queryKey,
    enabled: (options?.enabled !== false) && (queryKey.length > 0),
  }), [queryKey, options]);
  return useQuery(queryOptions);
}
