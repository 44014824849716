import React, { useCallback, useState } from 'react';
import { Alert, Box, Button, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import MutateLabelDialog, { DialogResult } from 'settings/components/MutateLabelDialog';
import LabelType from 'labels/types/LabelType';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';

export default function ModelSettingsFloorsPanel() {
  const { t } = useTranslation('settings');
  const { data: floors } = useProjectFloorsQuery();
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const onCloseSuccessMessage = useCallback(() => setSuccessMessage(undefined), []);
  const [floorIdCurrentlyEditing, setFloorIdCurrentlyEditing] = useState<string | undefined>(undefined);
  const [isCreatingFloor, setIsCreatingFloor] = useState<boolean>(false);
  const onClickAdd = useCallback(() => setIsCreatingFloor(true), []);
  const onCloseDialog = useCallback((result: DialogResult) => {
    setIsCreatingFloor(false);
    setFloorIdCurrentlyEditing(undefined);
    if (result === DialogResult.Created) {
      setSuccessMessage(t('model-settings-floor-panel_create-floor-success-message', 'New floor created'));
    } else if (result === DialogResult.Updated) {
      setSuccessMessage(t('model-settings-floor-panel_edit-floor-success-message', 'The changes to the floor have been saved'));
    } else if (result === DialogResult.Deleted) {
      setSuccessMessage(t('model-settings-floor-panel_delete-floor-success-message', 'Floor deleted'));
    }
  }, [t]);
  return (
    <Paper id="ModelSettingsFloorsPanel" elevation={1} square sx={{ p: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Typography variant="h4">{t('model-settings-floor-panel_title', 'Floors')}</Typography>
        <Button variant="contained" color="primary" onClick={onClickAdd} sx={{ ml: 'auto', pl: 1 }}>
          <AddIcon sx={{ mr: 1 }} />
          {t('model-settings-floor-panel_add-button-label', 'Add')}
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('model-settings-floor-panel_name-column-header', 'Name')}</TableCell>
            <TableCell>{t('model-settings-floor-panel_abbreviation-column-header', 'Abbr.')}</TableCell>
            <TableCell sx={{ width: 35 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {floors?.map((floor) => (
            <TableRow key={floor.id}>
              <TableCell>{floor.name}</TableCell>
              <TableCell>{floor.abbreviation}</TableCell>
              <TableCell>
                {floor.name !== 'All storeys' && (
                <IconButton onClick={() => setFloorIdCurrentlyEditing(floor.id)} size="small">
                  <EditIcon fontSize="small" />
                </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!!floorIdCurrentlyEditing && <MutateLabelDialog labelType={LabelType.Floor} id={floorIdCurrentlyEditing} onClose={onCloseDialog} />}
      {isCreatingFloor && <MutateLabelDialog labelType={LabelType.Floor} onClose={onCloseDialog} />}
      {!!successMessage && (
      <Snackbar open onClose={onCloseSuccessMessage} autoHideDuration={6000}>
        <Alert severity="success" onClose={onCloseSuccessMessage}>{successMessage}</Alert>
      </Snackbar>
      )}
    </Paper>
  );
}
