import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import _ from 'lodash';
import QueryArgs from 'api/types/QueryArgs';

export default function useDefaultEntitiesByCurrentProjectQuery<
  TDto,
  TCachedEntity extends TDto,
  TSelectedData extends TCachedEntity[] | TCachedEntity | undefined = TCachedEntity[] | undefined>(
  endpoint: ApiEndpoint,
  entityFactory?: ((dto: TDto) => Readonly<TCachedEntity>) | undefined,
  options?: Omit<UseQueryOptions<TCachedEntity[] | undefined, unknown, TSelectedData, QueryKey>, 'queryKey' | 'queryFn'> | undefined,
  queryArgs?: QueryArgs,
) {
  const axiosInstance = useAxiosInstance();
  const { listsByCurrentProjectQueryKey: queryKey } = useDefaultEntityQueryKeys(endpoint);
  return useQuery<TCachedEntity[] | undefined, unknown, TSelectedData>(queryKey, async () => {
    const queryString = _.map(queryArgs, (value, key) => `${key}=${value}`).join('&');
    const url = queryString.length ? `/${endpoint}?${queryString}` : `/${endpoint}`;
    const { data } = await axiosInstance.get<TDto[]>(url);
    if (entityFactory) return data.map(entityFactory);
    return (data as TCachedEntity[]);
  }, {
    retry: 1, retryDelay: 1000, ...options, enabled: (options?.enabled ?? true),
  });
}
