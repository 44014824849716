import React from "react";
import { useDropzone } from "react-dropzone";

const UploadZone = ({
  uploadLabel,
  uploadLinkLabel,
  uploadHint,
  isBackgroundGray = false,
  acceptedFilesFormats,
  uploadFileProgress,
  done,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFilesFormats,
    onDrop: async (acceptedFiles) => {
      await uploadFileProgress(acceptedFiles);
      if (done !== undefined) {
        done();
      }
    },
  });

  return (
    <div
      className={`upload__area mobile--hide ${
        isBackgroundGray ? "upload__area_gray" : " upload__area_standard"
      }`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className={`${isBackgroundGray ? "upload__area_gray_inner" : ""}`}>
        <div className="upload__description">
          <span
            dangerouslySetInnerHTML={{
              __html: sprintf(uploadLabel),
            }}
          ></span>
          <a className="text-underline">{uploadLinkLabel}</a>
        </div>
        {!!uploadHint && <p className="upload__area_hint">{uploadHint}</p>}
      </div>
    </div>
  );
};

export default UploadZone;
