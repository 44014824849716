import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { IssueService } from "../../Services";
import { getTypeSvg } from "../SVGs";
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';

const LinkedTaskItem = ({
  linkedIssue,
  types,
  statuses,
  setCurrentIssue,
  isVisibleDetail,
}) => {
  const { t } = useTranslation();
  const { data: disciplines } = useProjectDisciplinesQuery();
  const { data: floors } = useProjectFloorsQuery();
  const { data: buildings } = useProjectBuildingsQuery();

  const linkedIssueType = useMemo(() => {
    if (!linkedIssue) return null;
    return types.find((item) => item.value === linkedIssue.issueType?.id);
  }, [linkedIssue]);

  const linkedIssueStatus = useMemo(() => {
    if (!linkedIssue) return null;
    return linkedIssue.issueStatus?.id
      ? statuses.find((item) => item.id === linkedIssue.issueStatus?.id)
      : null;
  }, [statuses, linkedIssue]);

  const selectLinkedIssue = async () => {
    const formattedLinkedIssue = IssueService.formatIssue(linkedIssue, disciplines, buildings, floors);
    const issues = await IssueService.getIssuesWithLogViewpoints([formattedLinkedIssue]);
    if (!issues?.length) return;
    setCurrentIssue(issues[0]);
  }

  return (
    <div className="issue-link-tasks_item item-content">
      {linkedIssue ? (
        <>
          <div className="issue-link-tasks_item-title">
            <div className="column-item">
              {!!linkedIssueType && (
                <div
                  className="column-item-icon"
                  style={{
                    background: `${linkedIssueType.color}33`,
                    color: linkedIssueType.color,
                  }}
                >
                  {getTypeSvg(linkedIssueType.icon, linkedIssueType.color)}
                </div>
              )}
              <p
                className="column-item-name ellipsis"
                onClick={selectLinkedIssue}
              >
                {linkedIssue.title}
              </p>
            </div>
          </div>
          <div className="issue-link-tasks_item-content mobile--hide">
            {isVisibleDetail ? (
              <>
                <div
                  className={`item-content_column${
                    linkedIssue.dueDate ? " label bg-light-grey" : ""
                  }`}
                >
                  <p className="ellipsis">
                    {linkedIssue.dueDate
                      ? moment(new Date(linkedIssue.dueDate)).format(
                          "MM/DD/YYYY"
                        )
                      : ""}
                  </p>
                </div>
                <div className="item-content_column">
                  <p className="ellipsis">
                    {linkedIssue.assignedUsers
                      .map((item) => `${item.firstName} ${item.lastName}`)
                      .join(", ")}
                  </p>
                </div>
                <div className="item-content_column">
                  <p className="ellipsis">
                    {linkedIssue.reviewer
                      ? `${linkedIssue.reviewer.firstName} ${linkedIssue.reviewer.lastName}`
                      : ""}
                  </p>
                </div>
                <div
                  className="item-content_column"
                  style={{
                    background: `${linkedIssueStatus?.color}33`,
                    color: linkedIssueStatus?.color,
                  }}
                >
                  <p className="ellipsis">{linkedIssueStatus?.label || ""}</p>
                </div>
              </>
            ) : (
              <>
                <div className="item-content_column width--55">
                  <p className="ellipsis">
                    {linkedIssue.dueDate
                      ? `${t("due_to", "Due to")} ${moment(new Date()).format(
                          "MM.DD.YYYY"
                        )}`
                      : ""}
                  </p>
                </div>
                <div
                  className="item-content_column width--35"
                  style={{
                    background: `${linkedIssueStatus?.color}33`,
                    color: linkedIssueStatus?.color,
                  }}
                >
                  <p className="ellipsis">{linkedIssueStatus?.label || ""}</p>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="issue-link-tasks_item-title">
          <div className="column-item">
            <p className="column-item-name no-access">
              {t("user_no_access", "User has no access")}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LinkedTaskItem;
