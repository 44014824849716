import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SketchPicker } from "react-color";
import { Dialog } from "@mui/material";

import TagCreationItem from "../TagCreationItem";
import ChangeStatusFeedback from "../ChangeStatusFeedback";
import NotificationModal from "../NotificationModal";
import Loader from "../Loader";

import * as Utils from "../../Helpers/utils";
import { TagService } from "../../Services";
import useCurrentProjectQuery from "projects/hooks/useCurrentProjectQuery";
import useCurrentUserRole from "users/hooks/useCurrentUserRole";
import RoleAction from 'projects/types/RoleAction';
import useLegacySettingsNotificationDialogContext from 'settings/hooks/useLegacySettingsNotificationDialogContext';
import useLabelsOdataQueryData from 'labels/hooks/useLabelsOdataQueryData';
import LabelType from 'labels/types/LabelType';
import useLabelCreateMutation from 'labels/hooks/useLabelCreateMutation';
import useLabelUpdateMutation from 'labels/hooks/useLabelUpdateMutation';

export default function ProjectTags() {
  const { saveChangesNotification, setSaveChangesNotification, setHasUnsavedChanges } = useLegacySettingsNotificationDialogContext();
  const { t } = useTranslation("settings");
  const { data: currentProject } = useCurrentProjectQuery();
  const { mutateAsync: createLabelAsync } = useLabelCreateMutation();
  const { mutateAsync: updateLabelAsync } = useLabelUpdateMutation();
  const getLabelsOdataQueryData = useLabelsOdataQueryData();
  const [initialTags, setInitialTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [isEnableSave, setIsEnableSave] = useState(false);
  const [isVisibleColorPicker, setVisibleColorPicker] = useState(false);
  const [pickedColor, setPickedColor] = useState({
    index: 0,
    color: "",
  });
  const [errorMsgs, setErrorMsgs] = useState([]);
  const [isVisibleSavedFeedback, setVisibleSavedFeedback] = useState(false);
  const [notification, setNotification] = useState(null);
  const [selectedColorPickerRef, setSelectedColorPickerRef] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const currentUserRole = useCurrentUserRole();

  const isExistError = useMemo(
    () => errorMsgs.some((error) => !!error),
    [errorMsgs]
  );

  const fetchData = async () => {
    if (!currentProject) return;
    // const buffer = await TagService.getProjectTags(currentProject.id);
    const buffer = await getLabelsOdataQueryData({ filter: { type: LabelType[LabelType.Tag], isDeleted: false }, orderBy: 'order asc' });
    setTags(buffer);
    setErrorMsgs(buffer.map(() => ""));
    setInitialTags(JSON.parse(JSON.stringify(buffer)));
  };

  useEffect(() => {
    fetchData();
  }, [currentProject]);

  useEffect(() => {
    setHasUnsavedChanges(isEnableSave);
  }, [isEnableSave]);

  const onSave = useCallback(
    async (e) => {
      e?.preventDefault();
      setIsSaving(true);

      const creationTags = [],
        updateTags = [];

      tags.forEach((tag) => {
        if (!tag.id) creationTags.push(tag);
        else {
          const index = initialTags.findIndex((item) => item.id === tag.id);
          if (
            index >= 0 &&
            (initialTags[index].name !== tag.name ||
              initialTags[index].color !== tag.color)
          )
            updateTags.push(tag);
        }
      });

      let result = true;
      //create new tags
      const createParams = creationTags.map((item) => ({
        type: LabelType.Tag,
        name: item.name,
        color: item.color,
      }));
      if (createParams.length > 0) {
        const response = await Promise.all(createParams.map((dto) => createLabelAsync(dto)));
        if (response.length > 0) {
          const buffer = tags.filter((tag) => !!tag.id);
          buffer.push(...response);
          setTags(buffer);
          result = true;
        } else result = false;
      }

      //update the tags
      const updateParams = updateTags.map((item) => ({
        id: item.id,
        name: item.name,
        color: item.color,
      }));
      if (updateParams.length > 0)
        result = await Promise.all(updateParams.map((dto) => updateLabelAsync(dto)));

      if (result) {
        setVisibleSavedFeedback(true);
        setTimeout(() => {
          setVisibleSavedFeedback(false);
        }, 3000);
        setIsEnableSave(false);
      }
      setIsSaving(false);
    },
    [initialTags, tags]
  );

  const onAddMore = useCallback(() => {
    setIsEnableSave(true);
    setTags(
      Utils.addArrayItem(tags, {
        projectId: currentProject.id,
        name: "",
        color:
          tags.length >= TagService.TagColors.length
            ? TagService.TagColors[
                tags.length -
                  parseInt(tags.length / TagService.TagColors.length) *
                    TagService.TagColors.length
              ]
            : TagService.TagColors[tags.length],
      })
    );
    setErrorMsgs(Utils.addArrayItem(errorMsgs, ""));
  }, [tags, currentProject, errorMsgs]);

  const onRemoveTag = useCallback(
    async (index) => {
      let isRemove = true;
      if (tags[index].id) {
        isRemove = await TagService.deleteTag(tags[index].id);
      }
      if (isRemove) {
        setIsEnableSave(true);
        setTags(Utils.removeArrayItem(tags, index));
        setErrorMsgs(Utils.removeArrayItem(errorMsgs, index));
      } else {
        setNotification({
          title: t("tag_in_use", "Tag is in use"),
          description: "",
          doneText: "",
        });
      }
    },
    [tags, errorMsgs]
  );

  const handleTagsChange = useCallback(
    (e, index) => {
      setIsEnableSave(true);
      const value = e.target.value;
      const bufferTags = Utils.replaceArrayItem(
        tags,
        { ...tags[index], name: value },
        index
      );
      let bufferErrorMsgs = [...errorMsgs];
      bufferTags.forEach((item, tagIndex) => {
        if (
          bufferTags.findIndex(
            (innerItem, innerTagIndex) =>
              item.name &&
              tagIndex !== innerTagIndex &&
              innerItem.name.toLowerCase() === item.name.toLowerCase()
          ) >= 0
        )
          bufferErrorMsgs = Utils.replaceArrayItem(
            bufferErrorMsgs,
            t("duplicate_tag", "Tag is duplicated."),
            tagIndex
          );
          else if (value.length > 200)
            bufferErrorMsgs = Utils.replaceArrayItem(
              bufferErrorMsgs,
              "too long name",
              bufferTags.length - 1
            );
        else
          bufferErrorMsgs = Utils.replaceArrayItem(
            bufferErrorMsgs,
            "",
            tagIndex
          );
      });
      setErrorMsgs(bufferErrorMsgs);
      setTags(bufferTags);
    },
    [tags, errorMsgs]
  );

  const handleShowPicker = useCallback((index, color, colorPickerRef) => {
    setIsEnableSave(true);
    setVisibleColorPicker(true);
    setPickedColor({ index, color });
    setSelectedColorPickerRef(colorPickerRef);
  }, []);

  const handleChangeColor = useCallback(
    (color) => {
      setPickedColor({ ...pickedColor, color: color.hex });
    },
    [pickedColor]
  );

  const handleChangeColorComplete = useCallback(
    (color) => {
      const buffer = [...tags];
      buffer[pickedColor.index].color = color.hex;
      setTags(buffer);
    },
    [pickedColor, tags]
  );

  useEffect(() => {
    if (currentProject && saveChangesNotification === "discard") {
      fetchData();
      setIsEnableSave(false);
      setSaveChangesNotification("");
    }
    if (currentProject && saveChangesNotification === "save") {
      onSave();
      setSaveChangesNotification("");
    }
  }, [currentProject, saveChangesNotification]);

  return (
    <div id="ProjectTags" className="setting-view project-tags">
      <form onSubmit={onSave}>
        <div className="project-tags-header p-relative">
          {isVisibleSavedFeedback && (
            <ChangeStatusFeedback
              description={t(
                "tags_saved_successfully",
                "Tags saved successfully"
              )}
            />
          )}
          <input
            type="submit"
            className="btn btn--primary btn-save"
            value={t("save", { ns: "common", defaultValue: "Save" })}
            disabled={!isEnableSave || isExistError}
          />
          <Loader
            isSmall={true}
            isLoading={isSaving}
          />
        </div>
        <div className="project-tags-content">
          <div className="project-tags-content-left">
            <h3 className="project-tags-content-left-title">
              {t("tags", "Tags")}
            </h3>
            <p className="project-tags-content-left-description">
              {t(
                "define_tags",
                "Which tags your project should have? Define your tags on the right."
              )}
            </p>
            <a className="btn btn-setting-item" onClick={onAddMore}>
              <span>+</span>
              {t("add_more", "Add more")}
            </a>
          </div>
          <div className="project-tags-content-right">
            <div className="tags-wrapper">
              <h3 className="tags-title">{t("tags", "Tags")}</h3>
              <div className="tags-content-wrapper custom-scrollbar">
                <div className="tags-content">
                  {tags.map((tag, index) => (
                    <TagCreationItem
                      key={`creation-item-${index}`}
                      id={`${index}-tag-item`}
                      item={tag}
                      errorMsg={errorMsgs[index]}
                      isEnableRemove={currentUserRole?.allowedActions?.has(RoleAction.Tag_Delete)}
                      isEnableEdit={currentUserRole?.allowedActions?.has(RoleAction.Tag_Create_Update)}
                      onChangeInput={(e) => handleTagsChange(e, index)}
                      onClickPicker={(colorPickerRef) =>
                        handleShowPicker(index, tag.color, colorPickerRef)
                      }
                      onRemoveItem={() => onRemoveTag(index)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Dialog open={isVisibleColorPicker} onClose={() => setVisibleColorPicker(false)}>
        <div>
          <SketchPicker
            color={pickedColor.color}
            onChange={(color) => handleChangeColor(color)}
            onChangeComplete={(color) => handleChangeColorComplete(color)}
          />
        </div>
      </Dialog>
      {!!notification && (
        <NotificationModal
          onDone={() => setNotification(null)}
          notification={notification}
          isAutomationClose={true}
        />
      )}
    </div>
  );
}
