import React, {
  useCallback, useContext, useMemo,
} from 'react';
import { Chip } from '@mui/material';
import useDocumentFilterContext from 'documents/hooks/useDocumentFilterContext';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import DocumentScopeContext, { DocumentScopeContextState } from 'documents/contexts/DocumentScopeContext';

interface FilterActiveInidicatorProps extends ISxProps {
}

export default function FilterActiveInidicator({
  sx,
}: FilterActiveInidicatorProps) {
  const { t } = useTranslation('documents-filter');
  const { documentScope, setDocumentScope } = useContext<DocumentScopeContextState>(DocumentScopeContext);
  const {
    searchString,
    setSearchString,
    resetFilterState,
    isAppliedFilterStateEmpty,
    lastDocumentScopeBeforeFilterBecameActive,
  } = useDocumentFilterContext();

  const isFilterActive = useMemo(() => documentScope.key === DocumentScopeKey.Filter && (searchString?.length || !isAppliedFilterStateEmpty), [documentScope, isAppliedFilterStateEmpty, searchString?.length]);

  const onDeleteChip = useCallback(() => {
    setSearchString(undefined);
    resetFilterState();
    setDocumentScope(lastDocumentScopeBeforeFilterBecameActive ?? { key: DocumentScopeKey.ProjectDocuments });
  }, [lastDocumentScopeBeforeFilterBecameActive, resetFilterState, setDocumentScope, setSearchString]);

  return isFilterActive ? <Chip id="FilterActiveIndicator" sx={sx} label={t('is-filter-active-chip-label', 'Filter active')} variant="filled" color="primary" onDelete={onDeleteChip} /> : null;
}
