import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@mui/material';

interface IssueSaveNotificationDialogProps {
  open: boolean;
}

export default function IssueSaveNotificationDialog({
  open,
}: IssueSaveNotificationDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <div className="issue-saved-modal">
        <div className="tick-sign-circle">
          <div className="tick-sign" />
        </div>
        <p className="description">
          {t('issue_saved_successfully', 'Issue saved successfully')}
        </p>
      </div>
    </Dialog>
  );
}
