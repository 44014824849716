import React, { useMemo } from 'react';
import { Box, ButtonBase, useTheme } from '@mui/material';
import Icon from '@mdi/react';
import { mdiFileOutline, mdiCube, mdiFileDocument, mdiFloorPlan } from '@mdi/js';
import ISxProps from 'common/types/ISxProps';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import FileType from 'documents/types/FileType';

interface DocumentVersionItemProps extends ISxProps {
  documentVersion: DocumentVersionDto,
  onClick?: ((documentVersionId: string) => void) | undefined,
}

export default function DocumentVersionItem({
  sx,
  documentVersion,
  onClick,
}: DocumentVersionItemProps) {
  const theme = useTheme();
  const onClickButton = useMemo(() => (onClick ? () => onClick(documentVersion.id) : undefined), [documentVersion, onClick]);
  const fileIcon = useMemo(() => {
    if (documentVersion.fileType === FileType.Ifc) return mdiCube;
    if (documentVersion.fileType === FileType.Pdf) return mdiFileDocument;
    if (documentVersion.fileType === FileType.DWG) return mdiFloorPlan;
    return mdiFileOutline;
  }, [documentVersion.fileType]);
  return (
    <ButtonBase
      id="DocumentVersionItem"
      sx={{
        ...sx,
        display: 'flex',
        alignItems: 'stretch',
        textAlign: 'left',
        p: 1,
        gap: 1,
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.divider,
        '&:hover': {
          backgroundColor: theme.palette.grey[200],
        },
      }}
      onClick={onClickButton}
      disabled={!onClickButton}
      title={documentVersion.originalFileName}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Icon path={fileIcon} size={1} color={theme.palette.grey[700]} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: 1,
          flexGrow: 1,
          pointerEvents: 'none',
          fontSize: 14,
          overflow: 'hidden',
        }}
      >
        <Box sx={{ whiteSpace: 'nowrap', flexGrow: 0, flexShrink: 0, textOverflow: 'ellipsis', overflow: 'hidden' }}>{documentVersion.originalFileName}</Box>
      </Box>
    </ButtonBase>
  );
}
