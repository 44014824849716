import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import ModelSelect from "../ModelSelect";

import { getDisciplineColor } from "../../Services/IssueService";

const VISIBLE_LIMIT_COUNT = 4;

const ModelListFilter = (props) => {
  const { selectOptions, filterMetaData, handleSelectChange } = props;
  const { t } = useTranslation();
  const isExistAllInDiscipline = useMemo(
    () => filterMetaData.disciplines.find((item) => item.value === null),
    [filterMetaData.disciplines]
  );
  const isExistAllInBuilding = useMemo(
    () => filterMetaData.buildings.find((item) => item.value === null),
    [filterMetaData.buildings]
  );
  const isExistAllInFloor = useMemo(
    () => filterMetaData.floors.find((item) => item.value === null),
    [filterMetaData.floors]
  );

  return (
    <div className="models-content-list_filter">
      <label className="filter-title">
        {t("filter_model_list", "Filter Model List")}
      </label>
      <div className="filter-content">
        <div className="filter-content_select">
          <div className="filter-content_item filter-content_select-item">
            <label className="item-label">
              {t("discipline", "Discipline")}
            </label>
            <ModelSelect
              id="DisciplineFilterModelDropdown"
              options={selectOptions.discipline}
              value={filterMetaData.disciplines}
              onChange={(value) => handleSelectChange(value, "disciplines")}
            />
          </div>
          <div className="filter-content_item filter-content_select-item">
            <label className="item-label">{t("building", "Building")}</label>
            <ModelSelect
              id="BuildingFilterModelDropdown"
              options={selectOptions.building}
              value={filterMetaData.buildings}
              onChange={(value) => handleSelectChange(value, "buildings")}
            />
          </div>
          <div className="filter-content_item filter-content_select-item">
            <label className="item-label">{t("floor", "Floor")}</label>
            <ModelSelect
              id="FloorFilterModelDropdown"
              options={selectOptions.floor}
              value={filterMetaData.floors}
              onChange={(value) => handleSelectChange(value, "floors")}
            />
          </div>
        </div>
        <div className="filter-content_items">
          <div className="filter-content_item filter-content_items-field">
            {filterMetaData.disciplines.map(
              (discipline, index) =>
                (isExistAllInDiscipline
                  ? index < VISIBLE_LIMIT_COUNT + 1
                  : index < VISIBLE_LIMIT_COUNT) &&
                discipline.value !== null && (
                  <div
                    key={`discipline-${index}`}
                    className="filter-content_items-field-label text-ellipsis"
                    style={{
                      backgroundColor: `${getDisciplineColor(index)}33`,
                      color: getDisciplineColor(index),
                    }}
                  >
                    {discipline.label}
                  </div>
                )
            )}
            {(isExistAllInDiscipline
              ? filterMetaData.disciplines.length > VISIBLE_LIMIT_COUNT + 1
              : filterMetaData.disciplines.length > VISIBLE_LIMIT_COUNT) && (
              <p className="filter-content_items-field-more">{`+${
                isExistAllInDiscipline
                  ? filterMetaData.disciplines.length - VISIBLE_LIMIT_COUNT - 1
                  : filterMetaData.disciplines.length - VISIBLE_LIMIT_COUNT
              } more`}</p>
            )}
          </div>
          <div className="filter-content_item filter-content_items-field">
            {filterMetaData.buildings.map(
              (building, index) =>
                (isExistAllInBuilding
                  ? index < VISIBLE_LIMIT_COUNT + 1
                  : index < VISIBLE_LIMIT_COUNT) &&
                building.value !== null && (
                  <div
                    key={`building-${index}`}
                    className="filter-content_items-field-label text-ellipsis"
                  >
                    {building.label}
                  </div>
                )
            )}
            {(isExistAllInBuilding
              ? filterMetaData.buildings.length > VISIBLE_LIMIT_COUNT + 1
              : filterMetaData.buildings.length > VISIBLE_LIMIT_COUNT) && (
              <p className="filter-content_items-field-more">{`+${
                isExistAllInBuilding
                  ? filterMetaData.buildings.length - VISIBLE_LIMIT_COUNT - 1
                  : filterMetaData.buildings.length - VISIBLE_LIMIT_COUNT
              } more`}</p>
            )}
          </div>
          <div className="filter-content_item filter-content_items-field">
            {filterMetaData.floors.map(
              (floor, index) =>
                (isExistAllInFloor
                  ? index < VISIBLE_LIMIT_COUNT + 1
                  : index < VISIBLE_LIMIT_COUNT) &&
                floor.value !== null && (
                  <div
                    key={`floor-${index}`}
                    className="filter-content_items-field-label text-ellipsis"
                  >
                    {floor.label}
                  </div>
                )
            )}
            {(isExistAllInFloor
              ? filterMetaData.floors.length > VISIBLE_LIMIT_COUNT + 1
              : filterMetaData.floors.length > VISIBLE_LIMIT_COUNT) && (
              <p className="filter-content_items-field-more">{`+${
                isExistAllInFloor
                  ? filterMetaData.floors.length - VISIBLE_LIMIT_COUNT - 1
                  : filterMetaData.floors.length - VISIBLE_LIMIT_COUNT
              } more`}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelListFilter;
