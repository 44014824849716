import { useMemo } from 'react';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import IssueDto from 'issues/types/IssueDto';

export default function useIssuesOdataQueryKey() {
  const {
    odataQueryQueryKey: queryKeyBase,
    getOdataQueryQueryKey: getQueryKey,
  } = useDefaultEntityQueryKeys(ApiEndpoint.Issue);
  return useMemo(() => ({
    queryKeyBase,
    getQueryKey: getQueryKey<IssueDto>,
  }), [getQueryKey, queryKeyBase]);
}
