import React from 'react';
import ISxProps from 'common/types/ISxProps';
import LabelDto from 'labels/types/LabelDto';
import IconChip from 'labels/components/IconChip';
import useLabelTypeTranslation from 'labels/hooks/useLabelTypeTranslation';

interface LabelChipProps extends ISxProps {
  id?: string | undefined,
  label: LabelDto | undefined,
  mdiIconPath?: string | undefined,
  tooltip?: string | undefined,
}

export default function LabelChip({
  sx,
  id,
  label,
  mdiIconPath,
  tooltip,
}: LabelChipProps) {
  const labelTypeString = useLabelTypeTranslation(label?.type);
  if (!label || !labelTypeString) return null;
  return (
    <IconChip
      id={id ?? 'LabelChip'}
      sx={sx}
      text={label.name}
      tooltip={tooltip ?? `${labelTypeString}: ${label.name}`}
      color={label.color}
      mdiIconPath={mdiIconPath}
    />
  );
}
