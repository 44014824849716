import React from "react";

const Switch = ({ handleChange, action = 0, checked, disabled = false }) => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        onChange={() => handleChange(action)}
        checked={checked}
        disabled={disabled}
      />
      <span className="slider round"></span>
    </label>
  );
};

export default Switch;
