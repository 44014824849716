import React from "react";
import CustomSelect from "../CustomSelect";

const GroupName = ({
  handleChangeGroup,
  index,
  group,
  visoplanGroups,
  defaultValue,
  separatorsTypes,
  handeleChangeType,
  setGroupIndex,
  setShowModal,
  setGroupValue,
  groupValue,
  separationValue,
  setSeparationValue,
  filtredVisoplanGroups,
}) => {
  const handleChange = ((key, value) => {
    // when key is "group" or "separation", value is { value, label }
    switch(key) {
      case "name":
        {
          handleChangeGroup({
            groupName: value,
            currentIndex: index,
          });
          break;
        }
      case "group":
        {
          handeleChangeType({
            currentIndex: index,
            valueGroup: value,
            setGroupValue,
          });
          let changedGroup = {
            type: value.value,
            group: value.value,
            currentIndex: index,
          };
          // when the first group is created, separator should have the default value
          if (!group.type) {
            changedGroup = {
              ...changedGroup,
              separator: separatorsTypes[0].value
            }
          }
          handleChangeGroup(changedGroup);
          break;
        }
      case "separation":
        {
          handleChangeGroup({
            separator: value.value,
            currentIndex: index,
          });
          setSeparationValue({
            value: value.value,
            label: value.label === "select..." ? separationValue.label : value.label,
          });
          break;
        }
      default:
        break;
    }
  });

  return (
    <div className="group-name">
      <input
        type="text"
        className={"input input--shadow group-name-input"}
        onChange={(e) => handleChange("name", e.target.value)}
        value={group.name || ""}
      />
      <CustomSelect
        values={filtredVisoplanGroups()}
        onChange={(value) => handleChange("group", value)}
        value={
          String(group.type)
            ? {
                value: group.type,
                label: visoplanGroups[group.type]?.label,
              }
            : groupValue[index]
            ? groupValue[index].value
            : defaultValue
        }
        isModel={true}
        classNames="slect-menu"
      />
      <CustomSelect
        values={separatorsTypes}
        onChange={(value) => handleChange("separation", value)}
        value={
          String(group.separator)
            ? {
                value: group.separator,
                label: separatorsTypes[group.separator]?.label,
              }
            : separationValue
        }
        isModel={true}
      />
      <button
        className="btn-delete btn btn--secondary"
        type="button"
        onClick={() => (setGroupIndex(index), setShowModal(true))}
      >
        <svg className="icon">
          <use xlinkHref="/img/sprite.svg#delete" />
        </svg>
      </button>
    </div>
  );
};

export default GroupName;
