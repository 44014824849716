import { UI } from '@pdftron/webviewer';
import { IssueModalMode } from './Common';

export function initPDFWebviewerUI(
  ui: typeof UI,
  lastItem: any,
  setAddIssue: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLinkExistingIssue: React.Dispatch<React.SetStateAction<boolean>>,
) {
  ui.contextMenuPopup.add(
    [
      {
        dataElement: 'linkExistingIssue',
        type: 'actionButton',
        /* eslint-disable max-len */
        img: `<svg width='12' height='30' viewBox='0 0 12 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M1.30219 0.596825C1.30219 0.267218 1.01067 0 0.651093 0C0.291516 0 0 0.267218 0 0.596825V11.4032C0 11.7328 0.291516 12 0.651093 12C1.01067 12 1.30219 11.7328 1.30219 11.4032V6.67958C1.30223 6.67962 1.30232 6.67962 1.30236 6.67966L1.30219 0.596825Z' fill='#868E96'/>
            <path d='M9.95242 3.26718C9.83905 3.12748 9.84035 2.93972 9.95559 2.81276C10.571 2.13882 11.2042 1.58612 11.802 1.14511C12.3288 0.756418 11.6419 0.472966 11.4878 0.413522C8.35148 -0.796918 5.21798 1.73605 2.0835 0.964869V6.94382C5.22267 7.71552 8.36189 5.17161 11.501 6.39777C11.6542 6.45725 11.7941 6.44261 11.8616 6.3495C11.929 6.25676 11.9122 6.09744 11.8184 5.94135C11.1964 4.91092 10.5744 4.03792 9.95242 3.26718Z' fill='#868E96'/>
            </svg>`,
        /* eslint-enable max-len */
        onClick: () => {
          setAddIssue(true);
          setIsLinkExistingIssue(true);
        },
      },
      {
        dataElement: 'createNewIssue',
        type: 'actionButton',
        /* eslint-disable max-len */
        img: `<svg width='18' height='30' viewBox='0 0 18 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.30219 0.596825C5.30219 0.267218 5.01067 0 4.65109 0C4.29152 0 4 0.267218 4 0.596825V11.4032C4 11.7328 4.29152 12 4.65109 12C5.01067 12 5.30219 11.7328 5.30219 11.4032V6.67958C5.30223 6.67962 5.30232 6.67962 5.30236 6.67966L5.30219 0.596825Z' fill='#868E96'/>
            <path d='M13.9524 3.26718C13.839 3.12748 13.8403 2.93972 13.9556 2.81276C14.571 2.13882 15.2042 1.58612 15.802 1.14511C16.3288 0.756418 15.6419 0.472966 15.4878 0.413522C12.3515 -0.796918 9.21798 1.73605 6.0835 0.964869V6.94382C9.22267 7.71552 12.3619 5.17161 15.501 6.39777C15.6542 6.45725 15.7941 6.44261 15.8616 6.3495C15.929 6.25676 15.9122 6.09744 15.8184 5.94135C15.1964 4.91092 14.5744 4.03792 13.9524 3.26718Z' fill='#868E96'/>
            <rect y='9' width='17' height='8' rx='1' fill='#868E96'/>
            <path d='M6.44727 15H5.72705L3.979 12.1509H3.95703C3.96354 12.2469 3.96924 12.3486 3.97412 12.4561C3.98063 12.5635 3.98551 12.6742 3.98877 12.7881C3.99365 12.902 3.99772 13.0176 4.00098 13.1348V15H3.46875V11.4307H4.18408L5.92969 14.2603H5.94678C5.94352 14.1772 5.93945 14.082 5.93457 13.9746C5.92969 13.8672 5.9248 13.7565 5.91992 13.6426C5.91667 13.527 5.91423 13.4155 5.9126 13.3081V11.4307H6.44727V15ZM9.40625 15H7.38477V11.4307H9.40625V11.9238H7.9707V12.8931H9.31592V13.3838H7.9707V14.5044H9.40625V15ZM14.3989 11.4307L13.4663 15H12.8267L12.231 12.8613C12.2163 12.8092 12.2 12.7474 12.1821 12.6758C12.1642 12.6042 12.1463 12.5309 12.1284 12.4561C12.1121 12.3812 12.0975 12.3128 12.0845 12.251C12.0731 12.1875 12.0649 12.1387 12.0601 12.1045C12.0568 12.1387 12.0495 12.1867 12.0381 12.2485C12.0267 12.3104 12.0129 12.3787 11.9966 12.4536C11.9819 12.5269 11.9657 12.6001 11.9478 12.6733C11.9299 12.745 11.9136 12.8084 11.8989 12.8638L11.313 15H10.6758L9.74561 11.4307H10.334L10.8491 13.5229C10.8654 13.5897 10.8817 13.6621 10.8979 13.7402C10.9159 13.8167 10.9321 13.894 10.9468 13.9722C10.9631 14.0503 10.9777 14.1268 10.9907 14.2017C11.0037 14.2749 11.0143 14.3416 11.0225 14.4019C11.0306 14.34 11.0412 14.2716 11.0542 14.1968C11.0672 14.1203 11.0811 14.043 11.0957 13.9648C11.112 13.8851 11.1283 13.8086 11.1445 13.7354C11.1624 13.6621 11.1803 13.5962 11.1982 13.5376L11.7793 11.4307H12.3506L12.9438 13.5449C12.9618 13.6051 12.9788 13.6727 12.9951 13.7476C13.013 13.8224 13.0301 13.8989 13.0464 13.9771C13.0627 14.0552 13.0773 14.1309 13.0903 14.2041C13.1034 14.2773 13.1139 14.3433 13.1221 14.4019C13.1335 14.3221 13.1481 14.231 13.166 14.1284C13.1839 14.0259 13.2043 13.9217 13.2271 13.8159C13.2498 13.7101 13.2726 13.6125 13.2954 13.5229L13.8081 11.4307H14.3989Z' fill='#ffffff'/>
            </svg>`,
        /* eslint-enable max-len */
        onClick: () => {
          setAddIssue(!!IssueModalMode.CREATE);
        },
      },
    ],
    lastItem.dataElement,
  );
  ui.disableElements([
    'toggleNotesButton',
    'toolbarGroup-Insert',
    'toolbarGroup-FillAndSign',
    'toolbarGroup-Forms',
    'stickyToolGroupButton',
    'annotationCommentButton',
    'linkExistingIssue',
    'createNewIssue',
    'stickyToolButton',
    'printButton',
  ]);

  const iframeDoc = ui.iframeWindow.document;
  const header: any = iframeDoc.querySelector('[data-element=\'header\']');
  if (header?.style) {
    header.style.padding = '7px 0';
  }
  return { header };
}

export function customPDFWebviewerButtons(
  t: any,
  // width: number,
  setAddIssue: (isAddIssue: boolean) => void,
  setIsLinkExistingIssue: (setIsAddIssue: boolean) => void,
) {
  // Create custom button
  let showOptions = false;
  const existing = document.createElement('div');
  existing.textContent = t('old-pdf-preview_link-existing-issue-button-label', 'Link existing Issue');
  existing.style.marginBottom = '10px';
  existing.onclick = () => {
    setAddIssue(true);
    setIsLinkExistingIssue(true);
  };

  const create = document.createElement('div');
  create.textContent = t('old-pdf-preview_create-new-issue-button-label', 'Create new Issue');
  create.onclick = () => {
    setAddIssue(!!IssueModalMode.CREATE);
  };

  const title = document.createElement('span');
  title.textContent = t('old-pdf-preview_link-issue-button-label', 'Link Issue');
  title.style.fontSize = '10px';

  const arrow = document.createElement('div');
  arrow.style.border = 'solid #ffffff';
  arrow.style.borderWidth = '0 1px 1px 0';
  arrow.style.padding = '2px';
  arrow.style.transform = 'rotate(45deg)';
  arrow.style.width = '5px';
  arrow.style.marginLeft = '15px';

  const options = document.createElement('div');
  options.appendChild(existing);
  options.appendChild(create);
  options.style.position = 'absolute';
  options.style.width = '135px';
  options.style.padding = '10px 14px';
  options.style.fontSize = '9px';
  options.style.borderRadius = '3px';
  options.style.backgroundColor = '#ffffff';
  options.style.marginLeft = '10px';
  options.style.cursor = 'pointer';
  options.style.top = /* width > 578 ? */ '85%';
  options.style.visibility = showOptions ? 'visible' : 'hidden';
  options.id = 'options';

  const linkIssue = document.createElement('button');
  linkIssue.appendChild(title);
  linkIssue.appendChild(arrow);
  linkIssue.disabled = true;
  linkIssue.style.width = '135px';
  linkIssue.style.height = '24px';
  linkIssue.style.borderRadius = '3px';
  linkIssue.style.backgroundColor = '#4B6FFC';
  linkIssue.style.border = 'none';
  linkIssue.style.marginLeft = '10px';
  linkIssue.style.cursor = 'pointer';
  linkIssue.style.color = '#ffffff';
  linkIssue.style.display = 'flex';
  linkIssue.style.alignItems = 'center';
  linkIssue.style.justifyContent = 'center';
  linkIssue.style.marginBottom = '1px';
  linkIssue.style.opacity = '0.3';

  const openOptions = () => {
    showOptions = true;
    options.style.visibility = 'visible';
    arrow.style.transform = 'rotate(225deg)';
    arrow.style.marginTop = '5px';
  };
  const closeOptions = () => {
    showOptions = false;
    options.style.visibility = 'hidden';
    arrow.style.transform = 'rotate(45deg)';
    arrow.style.marginTop = '0px';
  };

  linkIssue.addEventListener('click', () => {
    if (showOptions) closeOptions();
    else openOptions();
  });
  options.addEventListener('mouseleave', () => {
    closeOptions();
  });

  return { options, linkIssue };
}
