import React, { useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import PageWrapper from 'common/components/PageWrapper';
import DocumentsPageHeader from 'documents/components/DocumentsPageHeader';
import DocumentFilterContextProvider from 'documents-filter/contexts/DocumentFilterContextProvider';
import DocumentScopeContextUrlProvider from 'documents/contexts/DocumentScopeContextUrlProvider';
import DocumentsBrowser from 'documents/components/DocumentsBrowser';
import DocumentSelectionContext, { DocumentSelectionContextState } from 'documents/contexts/DocumentSelectionContext';
import DocumentOptionsContextProvider from 'documents/contexts/DocumentOptionsContextProvider';

const emptyStringSet = new Set<string>();

export default function DocumentsPage() {
  const [selectedDocumentVersionIds, setSelectedDocumentVersionIds] = useState<string[]>([]);
  const resetSelection = useCallback(() => setSelectedDocumentVersionIds([]), []);
  const documentSelectionContextValue = useMemo<DocumentSelectionContextState>(() => ({
    selectedDocumentVersionIds,
    setSelectedDocumentVersionIds,
    resetSelection,
    nonDeselectableVersionIds: emptyStringSet,
    nonSelectableVersionIds: emptyStringSet,
  }), [resetSelection, selectedDocumentVersionIds]);

  return (
    <PageWrapper>
      <DocumentScopeContextUrlProvider>
        <DocumentSelectionContext.Provider value={documentSelectionContextValue}>
          <DocumentFilterContextProvider>
            <DocumentOptionsContextProvider>
              <Box sx={{
                display: 'grid',
                gridTemplateRows: '60px 1fr',
                height: '100%',
              }}
              >
                <DocumentsPageHeader />
                <DocumentsBrowser />
              </Box>
            </DocumentOptionsContextProvider>
          </DocumentFilterContextProvider>
        </DocumentSelectionContext.Provider>
      </DocumentScopeContextUrlProvider>
    </PageWrapper>
  );
}
