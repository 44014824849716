import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import axios from 'axios';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';

export default function useQueryFilterDeleteMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { baseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.QueryFilter);
  return useMutation(
    async (queryFilterId: string) => {
      try {
        await axiosInstance.delete(`${ApiEndpoint.QueryFilter}/${queryFilterId}`);
        return undefined;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 400) return error.response.data?.errorMessages[0]?.errorMessage;
        return undefined;
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(baseQueryKey);
      },
    },
  );
}
