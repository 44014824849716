import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QueryOptions } from 'odata-query';
import IssueDto from 'issues/types/IssueDto';
import { useMemo } from 'react';
import useIssuesOdataQueryKey from 'issues/hooks/useIssuesOdataQueryKey';
import { IssuesOdataQueryKey } from 'issues/hooks/useIssuesOdataQueryOptions';

type IssuesUseQueryOptions = Omit<UseQueryOptions<IssueDto[] | undefined, unknown, IssueDto[] | undefined, IssuesOdataQueryKey | readonly []>, 'queryFn'>;

export default function useIssuesOdataQuery(odataQuery: Partial<QueryOptions<IssueDto>> | undefined, options?: Partial<IssuesUseQueryOptions>) {
  const { getQueryKey } = useIssuesOdataQueryKey();
  const queryKey = useMemo(() => (odataQuery ? getQueryKey(odataQuery) : ([] as const)), [getQueryKey, odataQuery]);
  const queryOptions = useMemo<IssuesUseQueryOptions>(() => ({
    ...options,
    queryKey,
    enabled: (options?.enabled !== false) && (queryKey.length > 0),
  }), [queryKey, options]);
  return useQuery(queryOptions);
}
