import React, { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import Modal from 'HOC/modal';
import DownloadIcon from '@mui/icons-material/Download';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';
import DownloadPlugin from 'Components/Auth/DownloadPlugin';

interface PluginsDownloadButtonProps extends ISxProps {
}

export default function PluginsDownloadButton({
  sx,
}: PluginsDownloadButtonProps) {
  const { t } = useTranslation('projects');
  const [open, setOpen] = useState(false);
  const onClick = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);
  return (
    <>
      <Button
        id="PluginsDownloadButton"
        onClick={onClick}
        variant="outlined"
        sx={{ ...sx }}
      >
        <DownloadIcon sx={{ mr: 1 }} />
        {t('plugins-download-button-label', 'Plug-Ins')}
      </Button>
      {open && (
        <Modal>
          <DownloadPlugin
            onClose={onClose}
            isLogginedPreview
          />
        </Modal>
      )}
    </>
  );
}
