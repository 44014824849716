enum TimeFrame {
  Today,
  Yesterday,
  ThisWeek,
  LastWeek,
  ThisMonth,
  LastMonth,
}

export default TimeFrame;
