import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Request from "../../Helpers/Request";
import { useSelector } from "react-redux";
import CustomSelect from "../CustomSelect";
import {
  bimqOAuth,
  createBimQToken,
  getBimQActors,
  getBimQDataStructures,
  getBimQModels,
  getBimQPhases,
  getBimQProjects,
  getBimQTokenViaRefreshToken,
  getCurrentBimQUser,
} from "../../Helpers/Request/BimQRequest";
import { useNavigate } from "react-router-dom";
import useCurrentProjectQuery from "projects/hooks/useCurrentProjectQuery";

const CALLBACK_PATH = "/Callback";

const BimQSetting = () => {
  const { t } = useTranslation("settings");
  const navigate = useNavigate();

  const [bimqToken, setBimqToken] = useState(undefined);
  const [bimqIntegrationId, setBimqIntegrationId] = useState(undefined);
  const [bimqMetaData, setBimqMetaData] = useState(undefined);
  const [loadingBimQMetaData, setLoadingMetaData] = useState(false);

  const [bimqProjects, setBimQProjects] = useState([]);
  const [selectedBimqProject, setSelectedBimqProject] = useState(undefined);
  const [bimqDataStructures, setBimQDataStructures] = useState(undefined);
  const [selectedBimqDataStructure, setSelectedBimQDataStructure] =
    useState(undefined);
  const [bimqActors, setBimQActors] = useState([]);
  const [selectedBimQActor, setSelectedBimQActor] = useState(undefined);
  const [bimqModels, setBimQModels] = useState([]);
  const [selectedBimQModel, setSelectedBimQModel] = useState(undefined);
  const [bimqPhases, setBimQPhases] = useState(undefined);
  const [selectedBimQPhase, setSelectedBimQPhase] = useState(undefined);
  const [currentBimQUser, setCurrentBimQUser] = useState(undefined);
  const { data: currentProject } = useCurrentProjectQuery();

  const bimqAccessToken = useMemo(() => bimqToken?.access_token, [bimqToken]);

  useEffect(() => {
    setBimqIntegrationId(currentProject.bimqIntegrationId);
  }, [currentProject]);

  useEffect(() => {
    if (!bimqMetaData) return;

    setLoadingMetaData(true);
    let bimqProjectId = bimqMetaData.bimqProjectId;
    let actorId = bimqMetaData.bimqActorId;
    let projPromise = getBimQProjects(bimqAccessToken).then((res) => {
      let allBimQProjects = prepareBimQObjectsForComboBox(res);
      setBimQProjects(allBimQProjects);
      setSelectedBimqProject(
        allBimQProjects.find((proj) => proj.value === bimqProjectId)
      );
    });
    let actPromise = getBimQActors(bimqAccessToken, bimqProjectId).then(
      (res) => {
        let allBimQActors = prepareBimQObjectsForComboBox(res);
        setBimQActors(allBimQActors);
        setSelectedBimQActor(
          allBimQActors.find((act) => act.value === actorId)
        );
      }
    );
    let dataPromise = getBimQDataStructures(
      bimqAccessToken,
      bimqProjectId
    ).then((res) => {
      let allBimQDataStructures = prepareBimQObjectsForComboBox(res);
      setBimQDataStructures(allBimQDataStructures);
      setSelectedBimQDataStructure(
        allBimQDataStructures.find(
          (data) => data.value === bimqMetaData.bimqDatastructureId
        )
      );
    });
    let phasePromise = getBimQPhases(bimqAccessToken, bimqProjectId).then(
      (res) => {
        let allBimQPhases = prepareBimQObjectsForComboBox(res);
        setBimQPhases(allBimQPhases);
        setSelectedBimQPhase(
          allBimQPhases.find(
            (phase) => phase.value === bimqMetaData.bimqPhaseId
          )
        );
      }
    );
    let modelPromise = getBimQModels(
      bimqAccessToken,
      bimqProjectId,
      actorId
    ).then((res) => {
      let allBimQModels = prepareBimQObjectsForComboBox(res);
      setBimQModels(allBimQModels);
      setSelectedBimQModel(
        allBimQModels.find((model) => model.value === bimqMetaData.bimqModelId)
      );
    });

    Promise.all([
      projPromise,
      actPromise,
      dataPromise,
      phasePromise,
      modelPromise,
    ]).then(() => setLoadingMetaData(false));
  }, [bimqMetaData]);

  useEffect(() => {
    if (!bimqIntegrationId || loadingBimQMetaData || !!bimqMetaData) return;
    setLoadingMetaData(true);
    const currentlyLoadingTokenViaUrlCode =
      window.location.pathname.endsWith(CALLBACK_PATH);

    Request.GET(`api/bimq-integration/${bimqIntegrationId}`).then((result) => {
      if (result.status == 200) {
        let metaData = result.body;
        if (!currentlyLoadingTokenViaUrlCode && !bimqAccessToken) {
          getBimQTokenViaRefreshToken(metaData.token).then((token) => {
            setBimqToken(token);
            setBimqMetaData(metaData);
          });
        } else {
          setBimqMetaData(metaData);
        }
      } else if (result.status == 400) {
        setLoadingMetaData(false);
      }
    });
  }, [bimqIntegrationId, bimqMetaData, bimqAccessToken]);

  useEffect(() => {
    const effect = async () => {
      const path = window.location.pathname;
      if (!path.endsWith(CALLBACK_PATH)) return;
      const search = window.location.search;
      const code = search?.substring(6);
      if (!search?.startsWith("?code=")) {
        console.error("Callback-Code could not be read!", search);
        return;
      }
      const hrefWithoutCode = window.location.href.replace(search, "");
      let token = await createBimQToken(code, hrefWithoutCode);
      navigate(path.substring(0, path.length - CALLBACK_PATH.length));
      setBimqToken(token);
    };
    effect();
  }, []);

  useEffect(() => {
    if (!bimqAccessToken) return;
    getCurrentBimQUser(bimqAccessToken).then((res) => {
      setCurrentBimQUser(res);
    });
    if (bimqIntegrationId) return;
    getBimQProjects(bimqAccessToken).then((res) => {
      setBimQProjects(prepareBimQObjectsForComboBox(res));
    });
  }, [bimqAccessToken, bimqIntegrationId]);

  const prepareBimQObjectsForComboBox = (bimqObjs) =>
    bimqObjs.map((bimqO) => ({
      value: bimqO.id,
      label: bimqO.name ?? bimqO.id,
    }));

  const setBimQProjectAndUpdateOtherComboBoxes = useCallback(
    (project) => {
      if (!bimqAccessToken) return;
      setSelectedBimqProject(project);
      setSelectedBimQActor(null);
      getBimQActors(bimqAccessToken, project.value).then((res) => {
        setBimQActors(prepareBimQObjectsForComboBox(res));
      });
      setSelectedBimQDataStructure(null);
      getBimQDataStructures(bimqAccessToken, project.value).then((res) => {
        setBimQDataStructures(prepareBimQObjectsForComboBox(res));
      });
      setSelectedBimQPhase(null);
      getBimQPhases(bimqAccessToken, project.value).then((res) => {
        setBimQPhases(prepareBimQObjectsForComboBox(res));
      });
    },
    [bimqAccessToken]
  );

  const setBimQActorAndUpdateModelComboBox = useCallback(
    (actor) => {
      if (!bimqAccessToken || !selectedBimqProject) return;

      setSelectedBimQActor(actor);

      setSelectedBimQModel(null);
      getBimQModels(
        bimqAccessToken,
        selectedBimqProject.value,
        actor.value
      ).then((res) => {
        setBimQModels(prepareBimQObjectsForComboBox(res));
      });
    },
    [bimqAccessToken, selectedBimqProject]
  );

  const redirectToBimQ = useCallback(() => {
    bimqOAuth(window.location.href);
  }, []);

  const onSave = useCallback(async () => {
    let requestBody = {
      projectID: currentProject.id,
      token: bimqToken.refresh_token,
      tokenExpireDate: bimqToken.tokenExpireDate,
      bimqProjectName: selectedBimqProject.label,
      bimqProjectId: selectedBimqProject.value,
      bimqDatastructureId: selectedBimqDataStructure.value,
      bimqActorId: selectedBimQActor.value,
      bimqModelId: selectedBimQModel.value,
      bimqPhaseId: selectedBimQPhase.value,
    };
    if (!bimqMetaData?.id) {
      let result = await Request.POST("api/bimq-integration", requestBody);
      if (result.status < 200 || result.status > 299) return;
      let body = result.body;
      setBimqMetaData(body);
    } else {
      requestBody["id"] = bimqMetaData.id;
      let result = await Request.PUT("api/bimq-integration", requestBody);

      if (result.status < 200 || result.status > 299) return;

      setBimqMetaData(result.body);
    }
  }, [
    bimqIntegrationId,
    currentProject,
    bimqToken,
    selectedBimqProject,
    selectedBimqDataStructure,
    selectedBimQActor,
    selectedBimQModel,
    selectedBimQPhase,
  ]);

  const onDelete = useCallback(async () => {
    let result = await Request.DELETE(
      `api/bimq-integration/${bimqMetaData.id}`
    );
    if (result.status < 200 || result.status > 299) return;

    setBimqIntegrationId(undefined);
    setBimqMetaData(undefined);
    setBimqToken(undefined);
  }, [bimqMetaData]);

  const onImport = useCallback(async () => {
    await Request.PATCH(`api/bimq-integration/${bimqMetaData.id}/import`);
  });

  const allFieldsFilled = useMemo(
    () =>
      !!selectedBimqProject?.value &&
      !!selectedBimqDataStructure?.value &&
      !!selectedBimQActor?.value &&
      !!selectedBimQModel?.value &&
      !!selectedBimQPhase?.value,
    [
      selectedBimqProject?.value,
      selectedBimqDataStructure?.value,
      selectedBimQActor?.value,
      selectedBimQModel?.value,
      selectedBimQPhase?.value,
    ]
  );

  return (
    <div className="setting-view bimq">
      <div className="bimq-header">
        {!!bimqAccessToken && (
          <div>
            {" "}
            {!!bimqMetaData?.id && (
              <button
                id="BimqDeleteButton"
                disabled={loadingBimQMetaData}
                className="btn btn--secondary bimq-header-save"
                key="header_delete"
                onClick={onDelete}
              >
                {t("delete", "Delete")}
              </button>
            )}
            <button
              id="BimqSaveButton"
              disabled={!allFieldsFilled || loadingBimQMetaData}
              className="btn btn--primary bimq-header-save"
              key="header_save"
              onClick={onSave}
            >
              {!bimqMetaData?.id
                ? t("create_integration", "Create Integeration")
                : t("save", "Save")}
            </button>
          </div>
        )}
      </div>
      <div className="bimq-content" key="content">
        <div className="bimq-content-left" key="left">
          <h1 className="bimq-content-left-title">{t("bimq", "BIMQ")}</h1>
          <h3 className="bimq-content-left-subtitle">
            {t(
              "lorem_ipsum",
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dol"
            )}
          </h3>
        </div>
        <div className="bimq-content-right" key="right">
          {bimqAccessToken ? (
            loadingBimQMetaData ? (
              <div className="bimq-content-right-details">
                {t("loading_bimQ", "Loading bimQ-Integration")}
              </div>
            ) : (
              <div className="bimq-content-right-details">
                <span>
                  {t("logged_in_as", "Logged in as: ")}
                  <b>{currentBimQUser?.email}</b>
                  <h2>{t("bimq_project_details", "BIMQ - Project Details")}</h2>
                </span>
                <div className="bimq-content-right-details-row">
                  <div className="bimq-content-right-details-column">
                    <p className="bimq-content-right-details-subtitle">
                      {t("select_project", "Select Project")}
                    </p>
                    <CustomSelect
                      id="BimqSelectProjectButton"
                      values={bimqProjects}
                      value={selectedBimqProject}
                      onChange={setBimQProjectAndUpdateOtherComboBoxes}
                    />
                  </div>
                  <div className="bimq-content-right-details-column">
                    <p className="bimq-content-right-details-subtitle">
                      {t("select_data_structre", "Select Data Structure")}
                    </p>
                    <CustomSelect
                      id="BimqSelectDataStructureButton"
                      isDisabled={!selectedBimqProject}
                      values={bimqDataStructures}
                      value={selectedBimqDataStructure}
                      onChange={setSelectedBimQDataStructure}
                    />
                  </div>
                </div>
                <div className="bimq-content-right-details-row">
                  <div className="bimq-content-right-details-column">
                    <p className="bimq-content-right-details-subtitle">
                      {t("select_Actor", "Select Actor")}
                    </p>
                    <CustomSelect
                      id="BimqSelectActorDropdown"
                      isDisabled={!selectedBimqProject}
                      values={bimqActors}
                      value={selectedBimQActor}
                      onChange={setBimQActorAndUpdateModelComboBox}
                    />
                  </div>
                  <div className="bimq-content-right-details-column">
                    <p className="bimq-content-right-details-subtitle">
                      {t("select_model", "Select Model")}
                    </p>
                    <CustomSelect
                      id="BimqSelectModelDropdown"
                      isDisabled={!selectedBimqProject || !selectedBimQActor}
                      values={bimqModels}
                      value={selectedBimQModel}
                      onChange={setSelectedBimQModel}
                      name="Models"
                    />
                  </div>
                </div>
                <div className="bimq-content-right-details-row">
                  <div className="bimq-content-right-details-column">
                    <p className="bimq-content-right-details-subtitle">
                      {t("select_Phase", "Select Phase")}
                    </p>
                    <CustomSelect
                      id="BimqSelectPhaseDropdown"
                      isDisabled={!selectedBimqProject}
                      values={bimqPhases}
                      value={selectedBimQPhase}
                      onChange={setSelectedBimQPhase}
                    />
                  </div>
                </div>

                <div className="bimq-content-right-details-row-footer">
                  <button
                    id="BimqImportPropertyButton"
                    onClick={onImport}
                    disabled={loadingBimQMetaData || !bimqMetaData?.id}
                    className="bimq-content-right-details-row-button btn btn--primary"
                  >
                    {t("import_property", "Import Properties")}
                  </button>
                  <b>
                    {t("last_import_at_with_date", {
                      defaultValue: "Last Import: {{val, datetime}}",
                      val: bimqMetaData?.lastSyncTime ?? t("never", "never"),
                    })}
                  </b>
                </div>
              </div>
            )
          ) : (
            <div className="bimq-content-right-login">
              <h3 className="bimq-content-right-login-explainer">
                {!bimqIntegrationId
                  ? t(
                      "login_bimq_explainer",
                      "You need to grant Visoplan access to your bimq-content-Project. Please click the button below to do so."
                    )
                  : t(
                      "expired_bimq_explainer",
                      "Your BimQ Login exipered. Please renew your access by pressing the button below and loging in with BimQ again."
                    )}
              </h3>
              <button
                id="BimqLoginButton"
                className="bimq-content-right-login-button btn btn--primary"
                onClick={redirectToBimQ}
              >
                {t("login_bimq", "Login with BimQ")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default BimQSetting;
