import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { groupBy, sortBy } from "lodash";

import CustomSelect from "../CustomSelect";
import { IconButton } from "../Buttons";

import { getTypeSvg } from "../SVGs";
import { ModelService } from "../../Services";
import { getIssuesWithLogViewpoints } from "../../Services/IssueService";

function ComponentHistoryItem({ historyItem, selectedHistory, onSelect }) {
  if (!historyItem) return <></>;

  return (
    <div
      className={`component-info_history_item ${
        selectedHistory &&
        selectedHistory.history?.modelFile?.id ===
          historyItem.history?.modelFile?.id
          ? "selected"
          : ""
      }`}
      onClick={() => onSelect(historyItem)}
    >
      <p className="component-info_history_item-name text-ellipsis">
        {historyItem.component_name}
      </p>
      <p className="component-info_history_item-version text-ellipsis">
        {`V.${historyItem.version}`}
      </p>
      <div>
        <p className="component-info_history_item-creation text-ellipsis">
          {`${historyItem.creation_date} by ${historyItem.creator}`}
        </p>
        <p className="component-info_history_item-model-name">
          {`Model: ${historyItem.model_name}`}
        </p>
      </div>
    </div>
  );
}

export default function ModelComponentInfo({
  isVisible,
  allIssues,
  history,
  selectedComponentInfo,
  handleSelectIssue,
  handleSelectHistory,
}) {
  const { t } = useTranslation();
  const [selectedHistory, setSelectedHistory] = useState(null);
  const [selectedPSet, setSelectedPSet] = useState(null);
  const [histories, setHistories] = useState(null);
  const [properties, setProperties] = useState([]);
  const [linkedIssues, setLinkedIssues] = useState([]);

  useEffect(() => {
    const initHistories = () => {
      if (!history) return;

      let arr = {
        curView: null,
        otherView: [],
      };
      history.history.forEach((item) => {
        const history = {
          history: item,
          model_name: item.modelFile.name,
          component_name: item.node.elementName,
          version: item.modelFile.version,
          creator: `${item.modelFile.author.firstName} ${item.modelFile.author.lastName}`,
          creation_date: moment(new Date(item.modelFile.creationDate)).format(
            "DD.MM.YYYY"
          ),
        };
        if (item.modelFile?.id === selectedComponentInfo.mIds[0])
          arr.curView = history;
        else arr.otherView.push(history);
      });

      const sortedItems = Object.values(
        groupBy(arr.otherView, "component_name")
      ).map((group) => sortBy(group, "version"));
      arr.otherView = sortedItems.reduce((arr, item) => arr.concat(item), []);
      setHistories(arr);
      setSelectedHistory(arr.curView);
    };
    initHistories();
  }, [history, selectedComponentInfo]);

  useEffect(() => {
    const initLinkedIssues = async () => {
      if (!history || !history.linkedIssues?.length) {
        setLinkedIssues([]);
        return;
      }

      let issues = allIssues.filter(issue => history.linkedIssues.find(hIssue => hIssue.id === issue.id));
      issues = await getIssuesWithLogViewpoints(issues);

      setLinkedIssues(issues);
    }
    initLinkedIssues();
  }, [allIssues, history]);

  const pSetOptions = useMemo(() => {
    if (!selectedHistory) return [];

    const options = selectedHistory.history.propertyGroups.map((item) => ({
      ...item,
      value: item.id,
      label: item.name,
    }));
    setSelectedPSet(options[0]);
    return options;
  }, [selectedHistory]);

  useEffect(() => {
    const handleSelectPSet = async () => {
      if (!selectedPSet) {
        setProperties([]);
        return;
      }

      const response = await ModelService.getPropertyGroups(selectedPSet.value);
      if (!response) {
        setProperties([]);
      }
      else {
        // (#15070) some properties are defined as { name: string, value: string } and others as { name: string, displayNames: string[] }
        // => in the latter case, join the array elements into a string and store the result in { value }
        const processedProperties = response[0].properties.map((prop) => {
          const { name } = prop;
          let { value } = prop;
          if (!value && Array.isArray(prop.displayNames)) {
            value = prop.displayNames.join(', ');
          }
          return { name, value };
        });
        setProperties(processedProperties);
      }
    };
    handleSelectPSet();
  }, [selectedPSet]);

  useEffect(() => {
    if (selectedHistory?.version !== histories?.curView?.version) {
      handleSelectHistory(selectedHistory);
    } else {
      handleSelectHistory(null);
    }
  }, [selectedHistory]);

  const onSelectHistory = useCallback((value) => {
    setSelectedHistory(value);
  }, []);

  if (!histories?.curView) return <></>;
  return (
    <div className={`component-info ${isVisible ? "" : "hide"}`}>
      <h2 className="component-info_title text-ellipsis">
        {histories.curView.component_name}
      </h2>
      <div className="component-info_item component-info_history">
        <div className="component-info_history-header">
          <h3 className="component-info_item-title">
            {t("history", "History")}
          </h3>
          <div className="component-info_history-header_icons">
            <IconButton
              iconId="history_geometric"
              title={t("geometric", "Geometric")}
              onClick={() => {}}
            />
            <IconButton
              iconId="history_properties"
              title={t("properties", "Properties")}
              onClick={() => {}}
            />
          </div>
        </div>
        <div className="component-info_item-content custom-scrollbar">
          <div>
            <h4 className="component-info_item-sub-title">
              {t("current_view", "Current View")}
            </h4>
            <ComponentHistoryItem
              historyItem={histories.curView}
              onSelect={onSelectHistory}
            />
            <h4 className="component-info_item-sub-title">
              {t("other", "Other")}
            </h4>
            {histories.otherView.map((item, index) => (
              <ComponentHistoryItem
                key={`history-item-${index}`}
                historyItem={item}
                selectedHistory={selectedHistory}
                onSelect={onSelectHistory}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="component-info_item component-info_properties">
        <div className="component-info_properties-title">
          <h3 className="component-info_item-title">
            {t("properties", "Properties")}
          </h3>
          <div className="component-info_properties-title-set">
            <p className="text-ellipsis">
              {selectedHistory?.component_name || ""}
            </p>
            <CustomSelect
              values={pSetOptions}
              onChange={setSelectedPSet}
              value={selectedPSet}
              isModel={true}
            />
          </div>
        </div>
        <div className="component-info_item-content">
          <div className="component-info_properties-table">
            <div className="component-info_properties-table-header">
              <div className="component-info_properties-table-row">
                <div className="column-property">
                  {t("property", "Property")}
                </div>
                <div className="column-value">{t("value", "Value")}</div>
              </div>
            </div>
            <div className="component-info_properties-table-body custom-scrollbar">
              {properties.map((item, index) => (
                <div
                  key={`property-${index}`}
                  className="component-info_properties-table-row"
                >
                  <div className="column-property">
                    <p className="text-ellipsis">{item.name}</p>
                  </div>
                  <div className="column-value text-ellipsis">
                    {item.value || ""}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="component-info_item component-info_linked_issues">
        <h3 className="component-info_item-title">
          {t("linked_issues", "Linked Issues")}
        </h3>
        <div className="component-info_linked_issues-table">
          <div className="component-info_linked_issues-table-header">
            <div className="component-info_linked_issues-table-row">
              <div className="column-type">{t("type", "Type")}</div>
              <div className="column-name">{t("issue_name", "Issue Name")}</div>
              <div className="column-due">{t("due_to", "Due to")}</div>
              <div className="column-status">{t("status", "Status")}</div>
            </div>
          </div>
          <div className="component-info_linked_issues-table-body custom-scrollbar">
            {linkedIssues.map((issue, index) => (
              <div
                key={`link-issue-${index}`}
                className="component-info_linked_issues-table-row"
              >
                <div className="column-type">
                  <div
                    style={{
                      color: issue.issueType?.color,
                      background: `${issue.issueType?.color}33`,
                    }}
                  >
                    {getTypeSvg(issue.issueType?.icon, issue.issueType?.color)}
                  </div>
                </div>
                <div
                  className="column-name"
                  onClick={() => handleSelectIssue(issue)}
                >
                  <p className="text-ellipsis">{issue.title}</p>
                </div>
                <div className="column-due">
                  {!!issue.dueDate && (
                    <p>
                      {moment(new Date(issue.dueDate)).format("DD/MM/YYYY")}
                    </p>
                  )}
                </div>
                <div
                  className="column-status"
                  style={{
                    color: issue.issueStatus?.color,
                    background: `${issue.issueStatus?.color}33`,
                  }}
                >
                  <p>{issue.issueStatus?.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
