import { QueryObserverOptions, WithRequired } from '@tanstack/react-query';
import { useMemo } from 'react';
import DocumentListDto from 'documents-lists/types/DocumentListDto';
import useDefaultOdataQueryFn from 'api/hooks/useDefaultOdataQueryFn';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { DocumentListsOdataQueryKey } from 'documents-lists/hooks/useDocumentListsOdataQueryKey';

export type DocumentListsOdataQueryQueryFnData = DocumentListDto[] | undefined;
export type DocumentListsOdataQueryOptions = WithRequired<Partial<Omit<QueryObserverOptions<DocumentListsOdataQueryQueryFnData, unknown, DocumentListsOdataQueryQueryFnData, DocumentListsOdataQueryQueryFnData, DocumentListsOdataQueryKey>, 'queryKey'>>, 'queryFn'>;

export default function useDocumentListsOdataQueryOptions() {
  const queryFn = useDefaultOdataQueryFn<DocumentListDto, DocumentListDto, ApiEndpoint.DocumentList>();
  return useMemo<DocumentListsOdataQueryOptions>(() => ({
    queryFn,
  }), [queryFn]);
}
