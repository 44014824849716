import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import usePdfAnnotationsQueryKey from 'documents-annotations/hooks/usePdfAnnotationsQueryKey';
import { PdfAnnotationsQueryKey } from 'documents-annotations/hooks/usePdfAnnotationsQueryOptions';
import PdfAnnotation from 'documents-annotations/types/PdfAnnotation';
import PdfAnnotationDto from 'documents-annotations/types/PdfAnnotationDto';
import { QueryOptions } from 'odata-query';
import { useMemo } from 'react';

type PdfAnnotationsUseQueryOptions = Omit<UseQueryOptions<PdfAnnotation[] | undefined, unknown, PdfAnnotation[] | undefined, PdfAnnotationsQueryKey | readonly []>, 'queryFn'>;

export default function usePdfAnnotationsQuery(odataQuery: Partial<QueryOptions<PdfAnnotationDto>> | undefined, options?: PdfAnnotationsUseQueryOptions) {
  const { getQueryKey } = usePdfAnnotationsQueryKey();
  const queryKey = useMemo(() => (odataQuery ? getQueryKey(odataQuery) : [] as const), [getQueryKey, odataQuery]);
  const queryOptions = useMemo<PdfAnnotationsUseQueryOptions>(() => ({
    ...options,
    queryKey,
    enabled: (options?.enabled !== false) && (queryKey.length > 0),
  }), [queryKey, options]);
  return useQuery(queryOptions);
}
