import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { isEmpty, uniqBy } from "lodash";
import { Dialog } from "@mui/material";

// Components
import IssuesBoard from "issues/components/IssuesBoard";
import AsideIssue from "Components/Aside/AsideIssue/AsideIssue";
import AsideIssueFilter from "Components/Aside/AsideIssueFilter/AsideIssueFilter";
import IssueModal from "Components/IssueModal";
import IssueSaveNotificationDialog from "Components/IssueSavedModal";
import LabelLoader from "Components/LabelLoader";
import NotificationModal from "Components/NotificationModal";
import UploadProgressModal from "Components/Upload/UploadProgress";
import CustomSelect from "Components/CustomSelect";
import SearchInput from "Components/SearchInput";
import { FilterActive } from "components-n/buttons";
import IssueLoadingStatus from 'issues/components/IssueLoadingStatus';

// Helpers
import * as Request from "Helpers/Request";
import * as Utils from "Helpers/utils";
import {
  IssueModalMode,
  SyncDataType,
  PER_PAGE_RECORDS,
  getColumnsForIssues,
} from "Helpers/Common";
import * as IssueHelper from "Helpers/Issue";
import { IssueService, DocumentService, EmailService } from "Services";
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import {
  useFileUpload,
  useTags,
  useIssueFilterItems,
  useIssueFilters,
  useFilterOptions,
} from "Helpers/Hooks";

// Others
import { setSyncData } from 'Containers/ProjectContainer/Actions';

// Components
import UploadZone from "Components/Upload/UploadZone";
import IssueOverviewModalOld from "Components/IssueBoard/IssueOverviewModalOld";
import ChangeStatusFeedback from "Components/ChangeStatusFeedback";
import { SaveFilterModal } from "Components/FilterOptions";
import { setFilterValuesFromOtherPage } from "Containers/ProjectContainer/Actions";
import { formatIssue, getValuesAddedAll } from "Services/IssueService";
import DocumentPreviewModalTemplate from "Components/DocumentPreviewModalTemplate";
import { Table } from "emails/Table";
import useCurrentProjectQuery from "projects/hooks/useCurrentProjectQuery";
import useCurrentUserQuery from "users/hooks/useCurrentUserQuery";
import { useQueryClient } from "@tanstack/react-query";
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import RoleAction from "projects/types/RoleAction";
import NotAllowed from "error/NotAllowed";
import SuspenseWithErrorBoundary from 'error/SuspenseWithErrorBoundary';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import DocumentDetailsModal from 'documents-details/components/DocumentDetailsModal';

export default function Issues() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUserRole = useCurrentUserRole();
  const { data: currentProject } = useCurrentProjectQuery();
  const { data: disciplinesData } = useProjectDisciplinesQuery();
  const { data: floorsData } = useProjectFloorsQuery();
  const { data: buildingsData } = useProjectBuildingsQuery();
  const { filter_values_from_other_page } = useSelector(
    (state) => state.project
  );
  const [issues, setIssues] = useState([]);
  const [allIssues, setAllIssues] = useState([]);
  const { data: user } = useCurrentUserQuery();
  const { sync_data } = useSelector((state) => state.project);
  const [issueSavedModalVisible, setIssueSavedModalVisible] = useState(false);
  const [viewMode, setViewMode] = useState(IssueService.getViewMode());
  const { tagOptions } = useTags(currentProject?.id, currentProject?.tagIds);
  const scrollRef = useRef();
  const { issueFilterItems } = useIssueFilterItems();
  const {
    statuses,
    priorities,
    types,
    users,
    categories,
    buildings,
    floors,
    workphases,
    collaboratorRoleDefinitions,
    visibility,
  } = issueFilterItems;
  const [isSelectedAll, setSelectedAll] = useState(false);
  const baseIssuesUrl = useMemo(
    () => `/projects/${currentProject?.id}/issues/`,
    [currentProject]
  );

  const { issueId, mode } = useParams();

  const [searchKey, setSearchKey] = useState("");
  const [issueDropped, setIssueDropped] = useState(false);
  const [isVisibleIssueFeedback, setVisibleIssueFeedback] = useState(false);
  const issues_for_loading = useMemo(() => {
    const loadingIssues = [];
    for (let i = 0; i < 9; i++)
      loadingIssues.push({
        label: 0,
        id: i,
        title: 0,
        creationDate: 0,
        dueDate: 0,
        disciplines: 0,
        models: 0,
        assignedUsers: 0,
        reviewer: 0,
        linkedComponentsLength: 0,
        commentCount: 0,
      });
    return loadingIssues;
  }, []);

  const [loading, setLoading] = useState(true); //state for showing/hiding the loading spinner
  const [isListLoading, setIsListLoading] = useState(false); //state for the list loading progress
  const [current_issue, setCurrentIssue] = useState(null);
  const [edit_issue, setEditIssue] = useState(null);
  const [show_aside, setShowAside] = useState(false);
  const [issueModalVisible, setIssueModalVisible] = useState(
    IssueModalMode.HIDE
  ); //0: hide, 1: create, 2: edit
  const [purgeDocuments, setPurgeDocuments] = useState(false);
  const [show_aside_filter, setShowAsideFilter] = useState(false);
  const [selected, setSelected] = useState([]);
  const [premissionError, setPremissionError] = useState(false);
  const [issueNumbers, setIssueNumbers] = useState(0);
  const [allDocuments, setAllDocuments] = useState([]);
  const [allEmails, setAllEmails] = useState([]);

  const groupOptions = useMemo(
    () => [
      { value: 0, label: t("status", "Status"), name: "issueStatus" },
      { value: 1, label: t("priority", "Priority"), name: "issuePriority" },
    ],
    []
  );
  const [group, setGroup] = useState(groupOptions[0]);
  const [isDownloading, setDownloading] = useState(false);

  const [selectedIssueTree, setSelectedIssueTree] = useState([]);
  const [isTagUpdated, setTagUpdated] = useState(false);
  const [isLinkedCreatIssue, setIsLinkedCreatIssue] = useState(false);
  const [isMassEdit, setIsMassEdit] = useState(false);

  const [columns, setColumns] = useState([]);
  const [columnsWidth, setColumnsWidth] = useState([]);
  const [columnsData, setColumnsData] = useState([]);
  const [hideColumns, setHideColumns] = useState(false);
  const [keysOfColumns, setKeysOfColumns] = useState([]);
  const [checkedValues, setCheckedValues] = useState([]);
  const [columnsDataHeader, setColumnsDataHeader] = useState([]);
  const [columnsForDrag, setColumnsForDrag] = useState([]);
  const [resized, setResized] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);
  const [isChangedColumns, setChangedColumns] = useState(true);
  const [columns_loading, setColumnsLoading] = useState(
    IssueHelper.getColumnsLoading(t, keysOfColumns)
  );
  const [isFilterView, setIsFilterView] = useState(false);
  const [isDocLog, setIsDocLog] = useState(false);
  const {
    default_select,
    filterValues,
    filters,
    newFilters,
    currentFilter,
    showFilterModal,
    graphQLFilter,
    setFilterValues,
    setNewFilters,
    setCurrentFilter,
    getFilters,
    handleSaveFilter,
    handeleDeleteFilter,
    updateFilterValues,
    setShowFilterModal,
    getFilterQuery,
    resetFilterValues,
    updateGraphQLFilter,
  } = useIssueFilters({
    user,
    statuses,
  });
  useFilterOptions({
    default_select,
    ...issueFilterItems,
    allDocuments,
    allEmails,
    tagOptions,
    currentFilter,
    setFilterValues,
  });
  const [isResetFilter, setIsResetFilter] = useState(false);

  useEffect(() => {
    const effect = async () => {
      if (!isChangedColumns) return;

      let result = await Request.GET(`api/dynamic-layout`);
      const layout = result.body.find(
        (item) => item.key === "DEFAULT_ISSUE_LAYOUT"
      );
      setChangedColumns(false);
      setKeysOfColumns(layout.columns.map((column) => column.name));
      setColumnsWidth(layout.columns);
      setColumnsData(getColumnsForIssues());
    };
    effect();
  }, [isChangedColumns]);

  useEffect(() => {
    const res = columnsData.filter((i) => keysOfColumns.includes(i.value));
    setCheckedValues(res);
  }, [keysOfColumns, columnsData]);

  const handleChange = async (issueItem, issueId, isWorkPhase = false) => {
    let requestBody = [];
    if (!issueItem || !issueId) return;

    if (isWorkPhase) {
      requestBody = [
        {
          id: issueId,
          workPhaseId: { value: issueItem },
        },
      ];
    } else {
      const tagIds = issueItem.map((tag) => tag.value);
      //update the tag before requesting API
      const buffer = [...issues];
      const index = buffer.findIndex((item) => item.id === issueId);
      buffer[index].tagIds = tagIds;
      setIssues(buffer);

      requestBody = [
        {
          id: issueId,
          tagIds: { value: tagIds },
        },
      ];
    }
    setTagUpdated(true);
    const result = await IssueService.editIssue(requestBody);
    if (result.length > 0) {
      setVisibleIssueFeedback(true);
      setTimeout(() => setVisibleIssueFeedback(false), 3000);
    }
  };

  const debouncedUpdateTag = useDebouncedCallback((issueTag, issueId) => {
    handleChange(issueTag, issueId);
  }, 100);

  const canAddTagsForIssue = () => {
    selected.every((id) =>
      IssueService.getEditIssueState(
        issues.find((item) => item.id === id),
        currentUserRole?.allowedActions,
        user
      )
    );
  };

  const selectAll = (isSelectAll, isUpdatedIssues) => {
    if (isSelectAll) {
      setSelected(issues.map((issue) => issue.id));
    } else if (isUpdatedIssues) {
      setSelected(selected);
    } else {
      setSelected([]);
    }
  };

  useEffect(() => {
    setColumns(
      IssueHelper.getColumns({
        t,
        selected,
        tagOptions,
        statuses: statuses || [],
        types: types || [],
        workphases: workphases || [],
        priorities: priorities || [],
        setSelected,
        handleChange,
        canAddTagsForIssue,
        isSelectedAll,
        setSelectedAll,
        selectAll,
        isDraggable,
        issues,
      })
    );
  }, [
    selected,
    tagOptions,
    issues,
    statuses,
    types,
    workphases,
    priorities,
    isSelectedAll,
    isDraggable,
  ]);

  useEffect(() => {
    const columns_for_loading = IssueHelper.getColumnsLoading(t, keysOfColumns);
    const visibleColumnsLoading = [];
    keysOfColumns.map((i) => {
      columns_for_loading.filter((item) => {
        if (!visibleColumnsLoading.includes(item) && item.value === i)
          visibleColumnsLoading.push(item);
      });
    });
    if (visibleColumnsLoading.length > 0)
      setColumnsLoading(visibleColumnsLoading);
  }, [keysOfColumns]);

  useEffect(() => {
    if (!columnsData.length || !keysOfColumns.length) return;

    let columnsVisible = [];
    keysOfColumns.unshift("Type");
    keysOfColumns.unshift("ID");
    keysOfColumns.forEach((i) => {
      columns.filter((item) => {
        if (!columnsVisible.includes(item) && item.value === i)
          columnsVisible.push(item);
      });
    });
    setColumnsDataHeader(columnsVisible);
    setColumnsForDrag(columnsVisible);
  }, [columnsData, keysOfColumns, columns]);

  const updateIssueCommentsBySyncService = useCallback(async (entry) => {
    const changedIds = Utils.getChangedIds(entry);

    const comments = await IssueService.getComments(changedIds);
    if (!comments?.length) return;

    //update all issues
    setAllIssues((currAllIssues) => {
      const bufferAll = [...currAllIssues];
      const issueIndexAll = bufferAll.findIndex((issue) => issue.id === comments[0].issueId);
  
      if (issueIndexAll < 0) return bufferAll;

      if (entry.addedIds.length) {
        bufferAll[issueIndexAll] = {
          ...bufferAll[issueIndexAll],
          commentIds: [
            ...bufferAll[issueIndexAll].commentIds,
            ...changedIds
          ]
        }
      }
      return bufferAll;
    });

    //update issues
    setIssues((currIssues) => {
      const buffer = [...currIssues];
      const issueIndex = buffer.findIndex((issue) => issue.id === comments[0].issueId);
  
      if (issueIndex < 0) return currIssues;

      if (entry.addedIds.length) {
        buffer[issueIndex] = {
          ...buffer[issueIndex],
          commentIds: [
            ...buffer[issueIndex].commentIds,
            ...changedIds
          ]
        }
      }

      setCurrentIssue((currIssue) => {
        if (buffer[issueIndex].id === currIssue?.id) return buffer[issueIndex];
        return currIssue;
      })

      return buffer;
    });
  }, []);

  const openIssueDetails = useCallback(
    // eslint-disable-next-line complexity
    async (issuesBuffer, ceIssue = null) => {
      if (issueId === "add" && !mode && !ceIssue) {
        return addNewIssue();
      }

      //check if the issue is created or edited by the current user
      if (ceIssue && ceIssue.editAuthor?.id !== user?.id) return;

      let findIssue = issuesBuffer?.find((issue) => issue.id === issueId);

      if (findIssue) {
        if (!findIssue.issueLog || findIssue.viewpoints === undefined) {
          //because issuesBuffer doesn't contain logs and viewpoints at the initial loading
          findIssue = formatIssue(findIssue, disciplinesData, buildingsData, floorsData);

          const res = await IssueService.getIssuesWithLogViewpoints([
            findIssue,
          ]);
          if (res?.length) findIssue = res[0];
        }
        if (issueId && !mode) {
          setCurrentIssue(findIssue);
          setEditIssue(findIssue);
          setShowAside(true);
        }

        if (issueId && mode === "edit") {
          if (currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_Editing)) {
            setCurrentIssue(findIssue);
            setEditIssue(findIssue);
            toggleAside("aside_edit", true);
          } else {
            navigate(baseIssuesUrl);
            setPremissionError(true);
          }
        }
      }

      if ((issueId && !findIssue) || (mode && mode !== "edit")) {
        setPremissionError(true);
        navigate(`${baseIssuesUrl}`);
      }
    },
    [issueId, disciplinesData, buildingsData, floorsData, user?.id, currentUserRole, navigate]
  );

  const getUpdatedAllIssues = (buffer, updateIndex, allIssuesBuffer) => {
    let prevIssues = [...allIssuesBuffer];
    const isUpdate = prevIssues.find(
      (issue) => issue.id === buffer[updateIndex].id
    );
    //update the parent issue hierarchy
    if (buffer[updateIndex].parentId) {
      const parentIssue = prevIssues.find(
        (item) => item.id === buffer[updateIndex].parentId
      );

      if (
        parentIssue?.childrenIds &&
        !parentIssue.childrenIds.includes(buffer[updateIndex].id)
      ) {
        parentIssue.childrenIds.push(buffer[updateIndex].id);
        //update allIssues
        prevIssues = prevIssues.map((item) =>
          item.id === parentIssue.id ? parentIssue : item
        );
        //update issues
        buffer = buffer.map((item) =>
          item.id === parentIssue.id ? parentIssue : item
        );
      }
    }
    //update the children issue hierarchy
    if (buffer[updateIndex].childrenIds?.length) {
      buffer[updateIndex].childrenIds.forEach((childId) => {
        const childIssue = prevIssues.find((item) => item.id === childId);
        if (!childIssue) return;
        childIssue.parentId = buffer[updateIndex].id;
        //update allIssues
        prevIssues = prevIssues.map((item) =>
          item.id === childIssue.id ? childIssue : item
        );
        //update issues
        buffer = buffer.map((item) =>
          item.id === childIssue.id ? childIssue : item
        );
      });
    }

    if (isUpdate) {
      return prevIssues.map((item) =>
        item.id === buffer[updateIndex].id ? buffer[updateIndex] : item
      );
    }
    return [buffer[updateIndex], ...prevIssues];
  };

  const updateIssuesBySyncService = useCallback(async (entry) => {
    const { addedIds, updatedIds } = entry;

    if (addedIds.length) {
      const addedIssues = await IssueService.getIssuesByIds(addedIds);

      setIssues((currIssues) => {
        let buffer = [...currIssues];
        addedIssues.forEach((issue) => {
          buffer = [
            formatIssue(issue, disciplinesData, buildingsData, floorsData),
            ...buffer,
          ]
        });
        return buffer;
      });
      setAllIssues((currAllIssues) => {
        let buffer = [...currAllIssues];
        addedIssues.forEach((issue) => {
          buffer = [
            formatIssue(issue, disciplinesData, buildingsData, floorsData),
            ...buffer,
          ]
        });
        if (addedIssues.length === 1) {
          openIssueDetails(buffer, addedIssues[0]);
        }
        return buffer;
      });
    } else if (updatedIds.length) {
      const updatedIssues = await IssueService.getIssuesByIds(updatedIds);
      if (!updatedIssues.length) { // at the case which the user can't access to these issues
        setIssues((currIssues) => (
          currIssues.filter((item) => !updatedIds.some((id) => id === item.id))
        ));
        setAllIssues((currAllIssues) => (
          currAllIssues.filter((item) => !updatedIds.some((id) => id === item.id))
        ));
      } else {
        setIssues((currIssues) => {
          let buffer = [...currIssues];
          let curIssue = null;
          updatedIssues.forEach(async (issue) => {
            const updateIndex = buffer.findIndex((item) => item.id === issue.id);
            if (updateIndex < 0) return;

            buffer[updateIndex] = {
              ...buffer[updateIndex],
              ...formatIssue(issue, disciplinesData, buildingsData, floorsData),
            };

            //update allIssues state
            setAllIssues((currAllIssues) => {
              const updatedAllIssues = getUpdatedAllIssues(buffer, updateIndex, currAllIssues);
              // should be opened only when the single issue is edited
              if (updatedIssues.length === 1) {
                openIssueDetails(updatedAllIssues, updatedIssues[0]);
              }
              return updatedAllIssues;
            });

            const isIssueInlist = filterValues.status.find(
              (status) => status.id === buffer[updateIndex].issueStatus.id
            );
            if (!isLinkedCreatIssue && !isMassEdit) {
              if (!isTagUpdated && isIssueInlist) {
                curIssue = buffer[updateIndex];
                selectIssue(
                  curIssue,
                  updateIndex,
                  false,
                  curIssue.editAuthor?.id === user.id
                );
              } else {
                setTagUpdated(false);
              }
            }
            setIsMassEdit(false);
          })
          const issuesFilteredByStatus = buffer.filter((issue) => (
            filterValues.status.some((status) => status.id === issue.issueStatus.id)
          ));
      
          // update the selected status of the issues
          setSelected((currSelected) => (
            currSelected.filter((id) => issuesFilteredByStatus.some((item) => item.id === id))
          ));
      
          return issuesFilteredByStatus;
        });
      }
    }
  }, [isMassEdit, isTagUpdated, isLinkedCreatIssue, getUpdatedAllIssues, openIssueDetails]);

  //synchronize
  useEffect(() => {
    if (
      viewMode === "kanban" ||
      !currentProject ||
      !sync_data?.dataSummaryEntries
    )
      return;

    sync_data.dataSummaryEntries.forEach(async (entry) => {
      switch (entry.dataType) {
        case SyncDataType.Issue: {
          !issueDropped && updateIssuesBySyncService(entry);
          setIssueDropped(false);
          break;
        }
        case SyncDataType.Comment: {
          updateIssueCommentsBySyncService(entry);
          break;
        }
        case SyncDataType.IssueLog:
          break;
        case SyncDataType.DocumentVersion: {
          const response = await DocumentService.getDocumentVersions(
            Utils.getChangedIds(entry)
          );
          if (response)
            setAllDocuments((allDocs) => uniqBy([...response, ...allDocs], "id"));
          break;
        }
      }
    });
    dispatch(setSyncData(null));
  }, [
    sync_data,
    viewMode,
    issueDropped,
    currentProject,
    updateIssuesBySyncService,
    updateIssueCommentsBySyncService
  ]);

  const canEditIssue = useMemo(() => {
    if (!currentUserRole.allowedActions) return undefined;
    if (selected.length > 0)
      return selected?.every((id) =>
        IssueService.getEditIssueState(
          issues.find((item) => item.id === id),
          currentUserRole.allowedActions,
          user
        )
      );
    else
      return IssueService.getEditIssueState(current_issue, currentUserRole.allowedActions, user);
  }, [current_issue, selected, issues]);

  useEffect(() => {
    if (viewMode) {
      IssueService.setViewMode(viewMode);
    }
  }, [viewMode]);

  const ButtonEditIssue = useCallback(
    ({ name }) => (
      <button
        className="btn btn--secondary"
        disabled={selected.length === 0}
        onClick={() => {
          const issue =
            selected.length === 1
              ? issues.filter((item) => item.id === selected[0])[0]
              : null;
          setEditIssue(issue);

          toggleAside("aside_edit", true);
          selected.length > 1 && setIsMassEdit(true);
          navigate(`${baseIssuesUrl}edit`);
        }}
      >
        <svg className="icon">
          <use xlinkHref="/img/sprite.svg#list" />
        </svg>
        {name}
      </button>
    ),
    [selected, issues, navigate]
  );

  const getList = async (
    filter = null,
    showLoading = true,
    more = {},
    curIssue = null,
    isFiltered = undefined,
    allIssuesBuffer = null
  ) => {
    if (
      !currentProject ||
      !statuses?.length ||
      !priorities?.length)
      return;

      if (showLoading) {
      if (scrollRef?.current) scrollRef.current.scrollTop = 0;
    }

    //update parentId filter
    if (
      ((isFiltered === undefined && !isFilterView) || isFiltered === false) &&
      viewMode !== "kanban"
    )
      filter.parentId = null;

    let buffer = [];
    if (viewMode === "table") {
      buffer = await IssueService.getIssuesByProjectId(
        currentProject.id,
        PER_PAGE_RECORDS,
        more?.skip ? more.skip : 0,
        filter
      );
      if (more?.skip) {
        buffer = issues.concat(buffer);
      }
    }

    buffer = buffer.map((issue) => formatIssue(issue, disciplinesData, buildingsData, floorsData));
    setIssues(buffer);
    openIssueDetails(allIssuesBuffer || allIssues);

    if (current_issue || curIssue) {
      curIssue = curIssue || current_issue;
      const index = buffer.findIndex((i) => i.id === curIssue.id);
      selectIssue(buffer[index], index, false, false);
    }
    setLoading(false);
  };

  const {
    notification,
    dragging,
    progress,
    showUploadProgress,
    uploadFileProgress,
    getRootProps,
    getInputProps,
    initNotification,
    hideProgressModal,
  } = useFileUpload(0, issueModalVisible);
  const toggleElements = (flag, selected) => {
    const elements = document.getElementsByName("check");
    Array.prototype.slice.call(elements).map((item) => (item.checked = flag));
    setSelected(selected);
  };

  const exportIssues = (project_id, issue_ids, export_type) => {
    setDownloading(true);
    let filter = {
      issueIds: issue_ids,
    };
    return Request.POST(`api/export/${export_type}`, filter).then(
      (result) => {
        // Download file
        if (result.status === 200) {
          let a = document.createElement("a");
          a.href = window.URL.createObjectURL(result.body);
          a.download = "export." + export_type;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
        setDownloading(false);
      }
    );
  };
  const applyFilters = ({
    data = {},
    showLoading = true,
    more = {},
    curIssue = null,
    isFiltered = undefined,
    allIssuesBuffer = null,
  }) => {
    recalculateGraphQLFilter(isEmpty(data) ? filterValues : data);
    setShowAsideFilter(false);
    const payload = getFilterQuery(data, searchKey);
    getList(
      payload,
      showLoading,
      more,
      curIssue,
      isFiltered,
      allIssuesBuffer
    );
  };

  const resetFilters = (isReset = true) => {
    setIsResetFilter(true);
    if (!isReset) return;

    setIsFilterView(false);
    setCurrentFilter({});
    resetFilterValues();
    toggleElements(false, selected);
    setSelectedIssueTree([]);
    dispatch(setFilterValuesFromOtherPage(null));
  };

  useEffect(() => {
    const loadIssuesAfterResetFilter = () => {
      if (!isResetFilter) return;

      recalculateGraphQLFilter(filterValues);
      const filter = getFilterQuery(filterValues, searchKey); 
      getList(filter, true, {}, null, false);
      setIsResetFilter(false);
    }
    loadIssuesAfterResetFilter();
  }, [isResetFilter, filterValues, searchKey]);

  const selectIssue = async (
    row,
    rowIndex,
    toggle = true,
    force_open = false
  ) => {
    if (!row || rowIndex < 0) return;

    if (current_issue && row.id !== current_issue.id) {
      if (viewMode === "table") {
        const activeElements = document.querySelectorAll(
          "#issues-table .table-data .table-row.active"
        );
        if (activeElements?.length) {
          activeElements.forEach((element) => {
            element.classList.remove("active");
          });
        }
        const doc1 = document.querySelector(
          "#issues-table .table-data .table-row:nth-child(" +
          (rowIndex + 1) +
          ")"
        );
        const doc2 = document.querySelector(
          "#issues-table .table-data .folders-tree--block--list .folders-tree--block--list--item:nth-child(" +
          (rowIndex + 1) +
          ")"
        );
        if (doc1) doc1.classList.add("active");
        else if (doc2) doc2.classList.add("active");
      }
      if (toggle) {
        toggleAside("aside", true, false);
      }
    } else {
      if (viewMode === "table") {
        const domSelector = document.querySelector(
          "#issues-table .table-data .table-row:nth-child(" +
          (rowIndex + 1) +
          ")"
        );
        if (domSelector) {
          const classList = domSelector.classList;
          classList.toggle("active");
        }
      }
      if (toggle || force_open) {
        toggleAside("aside", force_open, false);
      }
    }
    row.dueDate = row.dueDate ? new Date(row.dueDate) : null;

    if (row.viewpoints?.length !== row.viewpointIds?.length) {
      const res = await IssueService.getIssuesWithLogViewpoints([row]);
      if (res?.length) row = res[0];
    }
    setCurrentIssue(row);
    setEditIssue(row);
  };

  const row_events = async (e, row, rowIndex) => {
    if (loading) return;
    if (e.target.className.includes("id")) {
      //if folding a main issue
      if (selectedIssueTree.includes(row.id))
        setSelectedIssueTree(selectedIssueTree.filter((i) => i !== row.id));
      //if unfolding a main issue
      else {
        if (!row.childrenIds?.length || isListLoading) return;
        //get the sub issue ids which hasn't been loaded yet
        const missedIds = [];
        setIsListLoading(true);

        row.childrenIds.forEach((id) => {
          if (!issues.find((issue) => issue.id === id)) missedIds.push(id);
        });
        if (missedIds.length) {
          const missedIssues = await IssueService.getIssuesByIds(missedIds);
          if (missedIssues?.length) {
            const missedBuffer = missedIssues.map((issue) =>
              formatIssue(issue, disciplinesData, buildingsData, floorsData)
            );
            setIssues((prevIssues) =>
              uniqBy([...prevIssues, ...missedBuffer], "id")
            );
          }
        }
        setSelectedIssueTree((prevState) => [row.id, ...prevState]);
        setIsListLoading(false);
      }
    }
    if (
      e.target.localName !== "label" &&
      e.target.localName !== "input" &&
      /* when TagColumn is clicked */
      typeof e.target.className === "string" &&
      !e.target.className.includes("select__multi") &&
      !e.target.className.includes("id") &&
      !e.target.className.includes("values-more") &&
      !e.target.className.includes("select__indicator") &&
      !e.target.className.includes("select__value-container") &&
      !e.target.className.includes("select__option") &&
      !e.target.className.includes("select__single-value") &&
      e.target.className
    ) {
      selectIssue(row, rowIndex);
      navigate(`${baseIssuesUrl}${row.id}`);
    }
  };

  //When referacting this view, rename/refractor this method
  const toggleAside = (aside, force_open = false, remove_active_row = true) => {
    setShowAside(aside === "aside" ? (force_open ? true : !show_aside) : false);
    setIssueModalVisible(
      aside === "aside_create"
        ? force_open
          ? IssueModalMode.CREATE
          : issueModalVisible
            ? IssueModalMode.HIDE
            : IssueModalMode.CREATE
        : aside === "aside_edit"
          ? force_open
            ? IssueModalMode.EDIT
            : issueModalVisible
          : IssueModalMode.HIDE
    );

    if (aside === "aside_filter") {
      setShowAsideFilter(
        aside === "aside_filter"
          ? force_open
            ? true
            : !show_aside_filter
          : false
      );
    }

    if (remove_active_row) {
      const elem = document.querySelector(
        "#issues-table table tbody tr.active"
      );
      !elem || elem.classList.remove("active");
    }
    setPurgeDocuments(false);
  };

  useEffect(() => {
    const initLoading = async () => {
      if (!currentProject?.id || !statuses.length || !priorities.length)
        return;

      setLoading(true);

      //get all issues
      const allIssuesBuffer = await IssueService.getIssuesByProjectId(
        currentProject?.id
      );
      setAllIssues(allIssuesBuffer);

      const docs = await DocumentService.getDocumentVersionsByProjectId(
        currentProject.id
      );
      setAllDocuments(docs);

      const emails = await EmailService.getInbox(currentProject?.id);
      setAllEmails(emails);

      if (filter_values_from_other_page) {
        // If the issues view is opened from timeline/dashboard view
        setFilterValues(filter_values_from_other_page);
        applyFilters({ data: filter_values_from_other_page, isFiltered: true });
        setIsFilterView(true);
      } else {
        resetFilters();
      }
    };
    initLoading();
  }, [currentProject?.id, statuses, priorities, filter_values_from_other_page]);

  useEffect(() => {
    const updateIssueCount = () => {
      setIssueNumbers(allIssues.length);
    };
    updateIssueCount();
  }, [allIssues]);

  useEffect(() => {
    document.title = t("issues", "Issues") + " - Visoplan";
    // return () => {
    //   dispatch(setFilterValuesFromOtherPage(null));
    // };
  }, []);

  const onChangeStatusFilter = useDebouncedCallback((status) => {
    applyFilters({ data: { status } });
    setSelectedIssueTree([]);
  }, 500);

  const onChangeWorkphaseFilter = useDebouncedCallback((workphase) => {
    applyFilters({ data: { workphase } });
    setSelectedIssueTree([]);
  }, 500);

  const debouncedApplyFilters = useDebouncedCallback((value) => {
    applyFilters({ data: { search_key: value } });
  }, 200);

  const onSearchChange = (event) => {
    const value = (event && event.target.value) || "";
    setSearchKey(value);
    debouncedApplyFilters(value);
  };

  const updateStatus = (issue, status) => {
    if (status.id !== issue?.issueStatus?.id && (status.originalName !== "Closed" || currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_StatusEditing_SetClosed))) {
      setLoading(true);

      return Request.PUT(`api/issue`, [
        {
          id: issue.id,
          statusId: { value: status.value },
        },
      ]).then(async () => {
        setLoading(false);
      });
    }
  };

  const handleScrollIssuesTable = (event) => {
    if (
      Math.abs(
        event.target.scrollHeight -
        event.target.scrollTop -
        event.target.clientHeight
      ) < 10 &&
      event.target.scrollTop > 0
    ) {
      loadMore(
        {
          skip: issues.length,
        }
      );
    }
  };

  const loadMore = useDebouncedCallback((params) => {
    applyFilters({
      showLoading: false,
      more: params,
    });
  }, 500);

  useEffect(() => {
    if (currentProject) {
      getFilters();
    }
  }, []);

  useEffect(() => {
    selectAll(false, true);
  }, [issues]);

  const addNewIssue = () => {
    setPurgeDocuments(true);
    setIssueModalVisible(IssueModalMode.CREATE);
    navigate(`${baseIssuesUrl}add`);
  };

  const documentsForFilter = useMemo(
    () =>
      allDocuments.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      })),
    [allDocuments]
  );

  const emailsForFilter = useMemo(
    () =>
      allEmails.map((item) => ({
        ...item,
        label: item.subject ? item.subject : item.body?.slice(0, 20),
        value: item.id,
      })),
    [allEmails]
  );

  const onClickApplyFilters = () => {
    setShowAsideFilter(false);
    setIsFilterView(true);
    applyFilters({
      isFiltered: true,
    });
    setSelectedIssueTree([]);
  };

  const recalculateGraphQLFilter = (filter) => {
    if (viewMode === "kanban" && group.name === "issueStatus")
      updateGraphQLFilter(searchKey, true, false, filter);
    else if (viewMode === "kanban" && group.name === "issuePriority")
      updateGraphQLFilter(searchKey, false, true, filter);
    else updateGraphQLFilter(searchKey, false, false, filter);
  };

  const queryClient = useQueryClient();
  const { baseQueryKey: projectBaseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Project);

  if (!currentUserRole?.allowedActions?.has(RoleAction.MainMenu_Issues)) return <NotAllowed />;

  return (
    <main id="Issues" className="main">
      <SuspenseWithErrorBoundary suspenseFallback={<CenteredCircularProgress />}>
        <div className="main__panel main__panel__issues">
          <div className="title">
            {t("issues", "Issues")}
            <div className="title__count">{issueNumbers}</div>
          </div>

          <div className="main__search mobile--hide">
            <SearchInput
              placeholder={t(
                "search_components",
                "Search nor issue name or explanation"
              )}
              value={searchKey}
              onChange={onSearchChange}
            />
          </div>

          {currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_BcfUpload) ? (
            <div className="issues__upload mobile--hide">
              <UploadZone
                uploadLabel={t("drag_and_drop_bcf", "<b>Drag & Drop</b> BCF to ")}
                uploadLinkLabel={t("upload_issue", "upload Issues")}
                acceptedFilesFormats=".bcf,.bcfzip"
                uploadFileProgress={uploadFileProgress}
                done={() => applyFilters({ showLoading: false })}
              />
            </div>
          ) : (
            <></>
          )}

          <div className="button-list">
            <FilterActive
              isFiltering={isFilterView}
              onClick={resetFilters}
            />
            {isDownloading && <LabelLoader label={t('download_in_progress', 'Download in progress')} />}
            <button
              className="btn btn--secondary btn--sm btn-issue"
              onClick={() => toggleAside("aside_filter")}
            >
              <svg className="icon">
                <use xlinkHref="/img/sprite.svg#filter" />
              </svg>
              {t("filter", "Filter")}
            </button>
            {currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_Creation) && (
              <button
                className="btn btn--primary btn--sm btn-issue--new ml-2"
                name="issue-create"
                onClick={() => addNewIssue()}
              >
                {t("new_issue", "New Issue")}
              </button>
            )}
          </div>
        </div>
        <div
          className={"main__content issues-content mode-" + viewMode}
          {...getRootProps()}
        >
          <div className="content">
            <input {...getInputProps()} />
            <div className={"issues-dropzone" + (dragging ? " dragging" : "")}>
              <div className="issues-dropzone--wrapper">
                <svg className="icon" viewBox="0 0 61 47">
                  <use xlinkHref="/img/sprite.svg#cloud" />
                </svg>
                <div className="issues-dropzone--title">
                  {t("drag_and_drop_files", "Drag and drop files")}
                </div>
                <div className="issues-dropzone--text">
                  {t(
                    "issue_uploading_description",
                    "Your issues will get added to the issue list"
                  )}
                </div>
              </div>
            </div>
            <div className="issues-filter mobile--hide">
              {viewMode === "table" ? (
                <div className="issues-filter_group">
                  <div className="issues-filter_group__left-part">
                    <div className="issues-filter_group-item">
                      <label className="issues-filter_group-item-label">
                        {t("status", "Status")}
                      </label>
                      <CustomSelect
                        values={statuses}
                        isDisabled={false}
                        onChange={(status) => {
                          updateFilterValues("status", status);
                          onChangeStatusFilter(status)
                        }}
                        value={filterValues.status}
                        isMulti={true}
                        isOptional={true}
                        isModel={true}
                        name="status"
                      />
                    </div>
                    {!!workphases?.length && (
                      <div className="issues-filter_group-item">
                        <label className="issues-filter_group-item-label">
                          {t("workphase", "Workphase")}
                        </label>
                        <CustomSelect
                          values={[default_select, ...workphases]}
                          onChange={(workphase) => {
                            updateFilterValues("workphase", workphase);
                            onChangeWorkphaseFilter(workphase);
                          }}
                          value={filterValues.workphase}
                          isModel={true}
                        />
                      </div>
                    )}
                    <IssueLoadingStatus
                      isFiltering={isFilterView || searchKey}
                      isSearching={!!searchKey}
                      loadedCount={issues.length}
                      totalCount={allIssues.length}
                      onResetSearch={() => onSearchChange(null)}
                    />
                  </div>
                </div>
              ) : (
                <div className="issues-filter_group">
                  {!!workphases?.length && (
                    <div className="issues-filter_group-item">
                      <label className="issues-filter_group-item-label">
                        {t("workphase", "Workphase")}
                      </label>
                      <CustomSelect
                        values={[default_select, ...workphases]}
                        onChange={(workphase) => {
                          updateFilterValues("workphase", workphase);
                          onChangeWorkphaseFilter(workphase);
                        }}
                        value={filterValues.workphase}
                        isModel={true}
                      />
                    </div>
                  )}
                  <div className="issues-filter_group-item">
                    <label className="issues-filter_group-item-label">
                      {t("sort_list", "Sort List")}
                    </label>
                    <CustomSelect
                      values={groupOptions}
                      onChange={(val) => {
                        setGroup(val);
                      }}
                      value={group}
                      isModel={true}
                    />
                  </div>
                </div>
              )}
              {isVisibleIssueFeedback && (
                <ChangeStatusFeedback
                  description={t(
                    "issue_edited_successfully",
                    "Issue was edited successfully"
                  )}
                />
              )}
              <div className="toggler">
                <a
                  className={viewMode === "table" ? "is-active" : ""}
                  onClick={() => {
                    setViewMode("table");
                    resetFilters(false);
                  }}
                >
                  <svg className="icon icon-list-issues">
                    <use xlinkHref="/img/sprite.svg#list-issues" />
                  </svg>
                </a>
                <a
                  className={viewMode === "kanban" ? "is-active" : ""}
                  onClick={() => {
                    setViewMode("kanban");
                    resetFilters(false);
                  }}
                >
                  <svg className="icon icon-kanban">
                    <use xlinkHref="/img/sprite.svg#kanban" />
                  </svg>
                </a>
              </div>
            </div>
            {viewMode === "table" ? (
              <div
                id="issues-table"
                className={
                  "issues-table content__table custom-scrollbar" +
                  (loading ? " content__table--preloader" : "") +
                  (selected.length ? " has-selected" : "")
                }
                ref={scrollRef}
                onScroll={handleScrollIssuesTable}
              >
                {showFilterModal && (
                  <SaveFilterModal
                    hideFilterModal={() => setShowFilterModal(false)}
                    setNewFilters={setNewFilters}
                    newFilters={newFilters}
                    handleSaveFilter={handleSaveFilter}
                  />
                )}
                <Table
                  data={loading ? issues_for_loading : issues}
                  row_events={row_events}
                  hideColumns={hideColumns}
                  setHideColumns={setHideColumns}
                  columns={
                    loading
                      ? columns_loading
                      : columnsForDrag
                  }
                  columnsWidth={columnsWidth}
                  columnsData={columnsData}
                  checkedValues={checkedValues}
                  resized={resized}
                  setResized={setResized}
                  setColumnsForDrag={setColumnsForDrag}
                  isDraggable={isDraggable}
                  setIsDraggable={setIsDraggable}
                  columnsDataHeader={columnsDataHeader}
                  setChangedColumns={setChangedColumns}
                  selectedIssueTree={selectedIssueTree}
                  issueView={!filter_values_from_other_page}
                  show_aside_filter={show_aside_filter}
                  allIssues={allIssues}
                  statuses={statuses}
                  isSearchView={searchKey}
                  isFilterView={isFilterView}
                />
              </div>
            ) : (
              <IssuesBoard
                sortByGroup={group}
                statuses={statuses}
                filteredStatuses={filterValues.status}
                filteredPriorities={
                  filterValues.priority.value
                    ? priorities.filter(
                      (item) => item.id === filterValues.priority.value
                    )
                    : priorities
                }
                priorities={priorities}
                baseIssuesUrl={baseIssuesUrl}
                setCurrentIssue={setCurrentIssue}
                setEditIssue={setEditIssue}
                setIssueDropped={setIssueDropped}
                filter={graphQLFilter}
                toggleAside={toggleAside}
              />
            )}

            <div
              className={
                "content__bottom" + (selected.length ? " is-active" : "")
              }
            >
              <div className="checkbox checkbox--only checkbox--circle">
                <input
                  type="checkbox"
                  name="check"
                  id="uncheck_all"
                  onChange={(e) =>
                    toggleElements(
                      e.target.checked,
                      e.target.checked ? issues.map((issue) => issue.id) : []
                    )
                  }
                />
                <label htmlFor="uncheck_all" />
              </div>

              <span className="mobile--hide">
                {selected.length} {t("issues_selected", "issues selected")}
              </span>

              <div className="button-list mobile--hide">
                {!!currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_Export) && <>
                  <button
                    className="btn btn--primary"
                    disabled={selected.length === 0}
                    onClick={() =>
                      exportIssues(currentProject.id, selected, "bcf")
                    }
                  >
                    {t("download_bcf", "Download BCF")}
                  </button>
                  <button
                    className="btn btn--primary"
                    disabled={selected.length === 0}
                    onClick={() =>
                      exportIssues(currentProject.id, selected, "pdf")
                    }
                  >
                    {t("download_pdf", "Download PDF")}
                  </button>
                </>}
                {selected.length && canEditIssue ? (
                  <ButtonEditIssue
                    name={t("edit_settings", "Edit Issue")}
                    selected={selected}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          {viewMode === "table" && !!current_issue && (
            <AsideIssue
              canEditIssue={canEditIssue}
              show_aside={show_aside}
              users={users}
              statuses={statuses}
              priorities={priorities}
              types={types}
              workphases={workphases}
              issue={current_issue}
              allEmails={allEmails}
              toggleAside={(aside, force_open) => toggleAside(aside, force_open)}
              updateStatus={updateStatus}
              buildings={buildings}
              floors={floors}
              collaboratorRoleDefinitions={collaboratorRoleDefinitions}
              disciplines={categories}
              tagOptions={tagOptions}
              updateTag={debouncedUpdateTag}
              baseIssuesUrl={baseIssuesUrl}
              allDocuments={allDocuments}
              allIssues={allIssues}
              setCurrentIssue={setCurrentIssue}
              setEditIssue={setEditIssue}
              setIsDocLog={setIsDocLog}
            />
          )}

          <AsideIssueFilter
            show_aside={show_aside_filter}
            priorities={getValuesAddedAll(priorities)}
            types={getValuesAddedAll(types)}
            statuses={statuses}
            categories={getValuesAddedAll(categories)}
            floors={getValuesAddedAll(floors)}
            buildings={getValuesAddedAll(buildings)}
            workphases={getValuesAddedAll(workphases)}
            users={getValuesAddedAll(users)}
            tagOptions={tagOptions}
            visibilities={getValuesAddedAll(visibility)}
            documents={getValuesAddedAll(documentsForFilter)}
            emails={getValuesAddedAll(emailsForFilter)}
            filters={filters}
            currentFilter={currentFilter}
            toggleAside={(aside) => toggleAside(aside)}
            onSubmit={onClickApplyFilters}
            onReset={resetFilters}
            setShowFilterModal={setShowFilterModal}
            setCurrentFilter={setCurrentFilter}
            handeleDeleteFilter={handeleDeleteFilter}
            handleSaveFilter={handleSaveFilter}
            filterValues={filterValues}
            updateFilterValues={updateFilterValues}
          />

          {!!notification && (
            <NotificationModal
              onDone={initNotification}
              notification={notification}
              isRenderContent={true}
            />
          )}

          {!!showUploadProgress && (
            <UploadProgressModal onDone={hideProgressModal} progress={progress} />
          )}
        </div>

        <Dialog
          open={issueModalVisible > 0}
          PaperProps={{ sx: { maxWidth: 'unset' } }}
          scroll="body"
        >
          <IssueModal
            {...issueFilterItems}
            tagOptions={tagOptions}
            toggleIssueModal={setIssueModalVisible}
            editIssue={
              issueModalVisible === IssueModalMode.EDIT ? edit_issue : null
            }
            mode={issueModalVisible}
            selected={
              selected.length > 0 ? selected : edit_issue ? [edit_issue.id] : []
            }
            setIssueSavedModalVisible={setIssueSavedModalVisible}
            issues={issues}
            baseIssuesUrl={baseIssuesUrl}
            canEditIssue={
              issueModalVisible == IssueModalMode.EDIT
                ? IssueService.getEditIssueState(edit_issue, currentUserRole?.allowedActions, user)
                : currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_Editing)
            }
            currentIssue={current_issue}
            setCurrentIssue={setCurrentIssue}
            setIsLinkedCreatIssue={setIsLinkedCreatIssue}
            setEditIssue={setEditIssue}
            isMassEdit={isMassEdit}
            setIsMassEdit={setIsMassEdit}
            allIssues={allIssues}
          />
        </Dialog>
        <IssueSaveNotificationDialog open={issueSavedModalVisible} />
        {viewMode !== "table" ? (
          <IssueOverviewModalOld
            canEditIssue={canEditIssue}
            show_aside={show_aside}
            {...issueFilterItems}
            issue={current_issue}
            tagOptions={tagOptions}
            baseIssuesUrl={baseIssuesUrl}
            toggleAside={toggleAside}
            updateStatusCallback={() => queryClient.invalidateQueries(projectBaseQueryKey)}
            updateTag={debouncedUpdateTag}
            allDocuments={allDocuments}
            allIssues={allIssues}
            setCurrentIssue={setCurrentIssue}
            setIsDocLog={setIsDocLog}
          />
        ) : (
          <></>
        )}
        {/* <DocumentPreviewModalTemplate
          allDocuments={allDocuments}
          selectedPreviewDoc={selectedPreviewDoc}
          setSelectedPreviewDoc={setSelectedPreviewDoc}
          issues={allIssues}
          isDocLog={isDocLog}
          setIsDocLog={setIsDocLog}
          tagOptions={tagOptions}
          issueFilterItems={issueFilterItems}
        /> */}
        {premissionError && (
          <div className="premission-modal">
            <NotificationModal
              notification={{
                title: t("no_permission", "No Permission"),
                description: `${t(
                  "not_permitted_view",
                  " You are not permitted to view that"
                )} ${t("issue").toLowerCase()}.`
              }}
              onDone={() => setPremissionError(false)}
            />
          </div>
        )}
      </SuspenseWithErrorBoundary>
    </main>
  );
};
