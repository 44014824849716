import React, { useRef } from "react";
import Select, { components } from "react-select";
import { useMenuPlacement } from "../../Helpers/Hooks";

const versionStyles = {
  singleValue: (styles) => {
    return {
      ...styles,
      ...{
        color: "#4B6FFC",
        fontSize: "14px",
        paddingLeft: '4px',
      },
    };
  },
  option: (styles) => {
    return {
      ...styles,
      ...{
        fontSize: "14px",
      },
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      ...{
        padding: "0px",
      },
    };
  },
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span />
    </components.DropdownIndicator>
  );
};

const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          textAlign: "left",
          borderRadius: "12px",
          width: "75px",
          height: "24px",
          background: "#EBF2FF",
          fontWeight: 600,
        }}
      >
        {children}
      </div>
    </components.ValueContainer>
  );
};

const ModelVersionSelect = ({
  options,
  onChange,
  value,
  menuPlacement = "auto",
}) => {
  const selectRef = useRef();

  const { onMenuOpen, onMenuClose, placement } = useMenuPlacement({
    selectRef,
    menuPlacement,
  });

  return (
    <div className="select--light select-width-full select--light select--no-background select--clearable">
      <Select
        ref={selectRef}
        classNamePrefix="select"
        styles={versionStyles}
        options={options}
        value={value}
        components={{ DropdownIndicator, ValueContainer }}
        onChange={onChange}
        isSearchable={false}
        menuShouldBlockScroll={false}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        menuPlacement={placement}
      />
    </div>
  );
};

export default ModelVersionSelect;
