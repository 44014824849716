import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { Autocomplete, Box, Button, Collapse, IconButton, Radio, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import Collaborator from 'collaborators/types/Collaborator';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';
import useUserGroupsQuery from 'users-groups/hooks/useUserGroupsQuery';
import UserGroupDto from 'users-groups/types/UserGroupDto';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import { grey } from '@mui/material/colors';
import AddBoxIcon from '@mui/icons-material/AddBox';
import InlineTypography from 'common/components/InlineTypography';
import GroupMemberListTooltipButton from 'users-groups/components/GroupMemberListTooltipButton';
import DocumentListUpdateAccessDto from 'documents-lists/types/DocumentListUpdateAccessDto';

enum PlanlistAccessLevel {
  Read = 'read',
  ReadWrite = 'read-write',
}

enum PermissionSubjectType {
  UserGroupEntity = 'user-group',
  CollaboratorEntity = 'collaborator',
}

type PermissionSubject = {
  type: PermissionSubjectType.CollaboratorEntity,
  groupLabel: string,
  label: string,
  entity: Collaborator,
} | {
  type: PermissionSubjectType.UserGroupEntity,
  groupLabel: string,
  label: string,
  entity: UserGroupDto,
};

export type DocumentListAccessIds = Pick<DocumentListUpdateAccessDto, 'readAccessGroupIds' | 'readAccessUserIds' | 'writeAccessGroupIds' | 'writeAccessUserIds'>;

interface PlanlistPermissionsPanelProps extends ISxProps {
  value: DocumentListAccessIds,
  onChange: (value: DocumentListAccessIds) => void,
  disabled?: boolean,
}

export default function PlanlistPermissionsPanel({
  sx,
  value,
  disabled,
  onChange,
}: PlanlistPermissionsPanelProps) {
  const { t } = useTranslation('documents-lists');
  const theme = useTheme();
  const { data: currentProject } = useCurrentProjectQuery();
  const { data: groupsData } = useUserGroupsQuery();
  const groups = useMemo(() => groupsData?.filter((g) => !(g.isDefault && g.name === 'External')), [groupsData]);
  const collaborators = useMemo(() => currentProject?.collaborators, [currentProject?.collaborators]);

  const accessGroupIdsSet = useMemo(() => new Set(value.readAccessGroupIds), [value.readAccessGroupIds]);
  const accessUserIdsSet = useMemo(() => new Set(value.readAccessUserIds), [value.readAccessUserIds]);
  const writeAccessGroupIdsSet = useMemo(() => new Set(value.writeAccessGroupIds), [value.writeAccessGroupIds]);
  const writeAccessUserIdsSet = useMemo(() => new Set(value.writeAccessUserIds), [value.writeAccessUserIds]);
  const readOnlyAccessGroupIdsSet = useMemo(() => new Set(value.readAccessGroupIds.filter((id) => !writeAccessGroupIdsSet.has(id))), [value.readAccessGroupIds, writeAccessGroupIdsSet]);
  const readOnlyAccessUserIdsSet = useMemo(() => new Set(value.readAccessUserIds.filter((id) => !writeAccessUserIdsSet.has(id))), [value.readAccessUserIds, writeAccessUserIdsSet]);

  const addedUsers = useMemo(() => collaborators?.filter((c) => accessUserIdsSet?.has(c.id)), [collaborators, accessUserIdsSet]);
  const addedGroups = useMemo(() => groups?.filter((g) => accessGroupIdsSet?.has(g.id)), [groups, accessGroupIdsSet]);

  const [isGroupsListVisible, setIsGroupsListVisible] = useState(true);
  const onClickToggleGroupsListVisible = useCallback(() => setIsGroupsListVisible((prev) => !prev), []);
  const [isUsersListVisible, setIsUsersListVisible] = useState(true);
  const onClickToggleUsersListVisible = useCallback(() => setIsUsersListVisible((prev) => !prev), []);

  const handleChangeGroupAccessLevel = useCallback((groupId: string, event: React.ChangeEvent<HTMLInputElement>) => {
    if (!value) return;
    onChange({
      ...value,
      writeAccessGroupIds: (event.target.value as PlanlistAccessLevel) === PlanlistAccessLevel.Read
        ? value.writeAccessGroupIds.filter((id) => id !== groupId)
        : value.writeAccessGroupIds.concat(groupId),
    });
  }, [onChange, value]);

  const handleChangeUserAccessLevel = useCallback((groupId: string, event: React.ChangeEvent<HTMLInputElement>) => {
    if (!value) return;
    onChange({
      ...value,
      writeAccessUserIds: (event.target.value as PlanlistAccessLevel) === PlanlistAccessLevel.Read
        ? value.writeAccessUserIds.filter((id) => id !== groupId)
        : value.writeAccessUserIds.concat(groupId),
    });
  }, [onChange, value]);

  const autocompletePermissionSubjects = useMemo(() => (groups && collaborators ? [
    ...groups.map<PermissionSubject>((entity) => ({
      entity,
      type: PermissionSubjectType.UserGroupEntity,
      groupLabel: t('folder-edit-permissions-panel_autocomplete-category-groups', 'Groups'),
      label: entity.name,
    })),
    ...collaborators.map<PermissionSubject>((entity) => ({
      entity,
      type: PermissionSubjectType.CollaboratorEntity,
      groupLabel: t('folder-edit-permissions-panel_autocomplete-category-collaborators', 'Collaborators'),
      label: `${entity.firstName} ${entity.lastName}`,
    })),
  ] : undefined), [collaborators, groups, t]);

  const [subjectStagedToAdd, setSubjectStagedToAdd] = useState<PermissionSubject | undefined>(undefined);

  const onChangeAddSubjectAutocomplete = useCallback((event: SyntheticEvent<Element, Event>, subject: PermissionSubject | null) => {
    setSubjectStagedToAdd(subject ?? undefined);
  }, []);

  const onClickAddSubjectAutocomplete = useCallback(() => {
    if (!subjectStagedToAdd) return;
    if (subjectStagedToAdd.type === PermissionSubjectType.UserGroupEntity) {
      onChange({ ...value, readAccessGroupIds: Array.from(new Set([...(value.readAccessGroupIds ?? []), subjectStagedToAdd.entity.id])) });
    } else if (subjectStagedToAdd.type === PermissionSubjectType.CollaboratorEntity) {
      onChange({ ...value, readAccessUserIds: Array.from(new Set([...(value.readAccessUserIds ?? []), subjectStagedToAdd.entity.id])) });
    }
    setSubjectStagedToAdd(undefined);
  }, [onChange, subjectStagedToAdd, value]);

  const onClickRemoveGroup = useCallback((groupId: string) => {
    onChange({
      ...value,
      readAccessGroupIds: value.readAccessGroupIds.filter((id) => id !== groupId),
      writeAccessGroupIds: value.writeAccessGroupIds.filter((id) => id !== groupId),
    });
  }, [onChange, value]);

  const onClickRemoveCollaborator = useCallback((collaboratorId: string) => {
    onChange({
      ...value,
      readAccessUserIds: value.readAccessUserIds.filter((id) => id !== collaboratorId),
      writeAccessUserIds: value.writeAccessUserIds.filter((id) => id !== collaboratorId),
    });
  }, [onChange, value]);

  const textColor = useMemo(() => (disabled ? theme.palette.text.disabled : undefined), [disabled, theme.palette.text.disabled]);

  const permissionsTableHead = (
    <TableHead>
      <TableRow>
        <TableCell sx={{ width: '40px', color: textColor }} />
        <TableCell sx={{ color: textColor }}>{t('folder-edit-permissions-panel_name-column', 'Name')}</TableCell>
        <TableCell sx={{ width: '100px', textAlign: 'center', color: textColor }}>{t('folder-edit-permissions-panel_read-right-column', 'Read')}</TableCell>
        <TableCell sx={{ width: '100px', textAlign: 'center', color: textColor }}>{t('folder-edit-permissions-panel_read-write-right-column', 'Read & Write')}</TableCell>
        <TableCell sx={{ width: '100px', textAlign: 'center', color: textColor }} />
      </TableRow>
    </TableHead>
  );

  return (
    <Box
      sx={{
        ...sx, display: 'grid', gridTemplateRows: 'auto 1fr', maxHeight: '100%',
      }}
      id="FolderEditPermissionsPanel"
    >
      <Box sx={{ display: 'flex' }}>
        <Autocomplete
          id="grouped-demo"
          options={autocompletePermissionSubjects ?? []}
          groupBy={(option) => option.groupLabel}
          value={subjectStagedToAdd ?? null}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('folder-edit-permissions-panel_add-subjects-autocomplete-label', 'Add groups & collaborators')}
            />
          )}
          sx={{ mr: 1, width: '280px' }}
          onChange={onChangeAddSubjectAutocomplete}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ pl: 1 }}
          onClick={onClickAddSubjectAutocomplete}
          disabled={disabled || !subjectStagedToAdd}
        >
          <AddBoxIcon sx={{ mr: 1 }} />
          {t('', 'Add')}
        </Button>
      </Box>
      <Box sx={{ overflowY: 'auto', gridRow: '2 / 3' }} className="custom-scrollbar">
        <Typography sx={{
          mt: 1, fontWeight: 800, backgroundColor: grey[50], color: textColor,
        }}
        >
          <IconButton onClick={onClickToggleGroupsListVisible} sx={{ p: 0.5, mr: '15px' }}>
            {isGroupsListVisible ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
          {t('folder-edit-permissions-panel_table-groups-subheader', 'Groups')}
        </Typography>
        <Collapse in={isGroupsListVisible} timeout="auto">
          <Table>
            {permissionsTableHead}
            <TableBody>
              {addedGroups?.map((g) => (
                <TableRow key={g.id}>
                  <TableCell sx={{ width: '40px' }}>
                    <GroupIcon color="primary" sx={{ color: textColor }} />
                  </TableCell>
                  <TableCell>
                    <InlineTypography sx={{ fontWeight: 600, color: textColor }}>{`${g.name}`}</InlineTypography>
                    <GroupMemberListTooltipButton groupId={g.id} sx={{ ml: 1 }} />
                  </TableCell>
                  <TableCell sx={{ width: '100px', textAlign: 'center' }}>
                    <Radio
                      checked={!!readOnlyAccessGroupIdsSet.has(g.id)}
                      onChange={(e) => handleChangeGroupAccessLevel(g.id, e)}
                      value={PlanlistAccessLevel.Read}
                      name={`readwrite-radio-${g.id}`}
                      disabled={disabled}
                    />
                  </TableCell>
                  <TableCell sx={{ width: '100px', textAlign: 'center' }}>
                    <Radio
                      checked={!readOnlyAccessGroupIdsSet.has(g.id)}
                      onChange={(e) => handleChangeGroupAccessLevel(g.id, e)}
                      value={PlanlistAccessLevel.ReadWrite}
                      name={`readwrite-radio-${g.id}`}
                      disabled={disabled}
                    />
                  </TableCell>
                  <TableCell sx={{ width: '100px', textAlign: 'center' }}>
                    <Button
                      disabled={disabled}
                      variant="outlined"
                      sx={{ height: '24px' }}
                      size="small"
                      onClick={() => onClickRemoveGroup(g.id)}
                    >
                      {t('folder-edit-permissions-panel_remove-button-label', 'Remove')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
        <Typography sx={{
          mt: 1, fontWeight: 800, backgroundColor: grey[50], color: textColor,
        }}
        >
          <IconButton onClick={onClickToggleUsersListVisible} sx={{ p: 0.5, mr: '15px' }}>
            {isUsersListVisible ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
          {t('folder-edit-permissions-panel_table-users-subheader', 'Users')}
        </Typography>
        <Collapse in={isUsersListVisible} timeout="auto">
          <Table>
            {permissionsTableHead}
            <TableBody>
              {addedUsers?.map((c) => (
                <TableRow key={c.id}>
                  <TableCell sx={{ width: '40px' }}>
                    <PersonIcon color="primary" sx={{ color: textColor }} />
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, color: textColor }}>
                    {`${c.firstName} ${c.lastName}`}
                  </TableCell>
                  <TableCell sx={{ width: '100px', textAlign: 'center' }}>
                    <Radio
                      checked={!!readOnlyAccessUserIdsSet.has(c.id)}
                      onChange={(e) => handleChangeUserAccessLevel(c.id, e)}
                      value={PlanlistAccessLevel.Read}
                      name={`readwrite-radio-${c.id}`}
                      disabled={disabled}
                    />
                  </TableCell>
                  <TableCell sx={{ width: '100px', textAlign: 'center' }}>
                    <Radio
                      checked={!readOnlyAccessUserIdsSet.has(c.id)}
                      onChange={(e) => handleChangeUserAccessLevel(c.id, e)}
                      value={PlanlistAccessLevel.ReadWrite}
                      name={`readwrite-radio-${c.id}`}
                      disabled={disabled}
                    />
                  </TableCell>
                  <TableCell sx={{ width: '100px', textAlign: 'center' }}>
                    <Button
                      disabled={disabled}
                      variant="outlined"
                      sx={{ height: '24px' }}
                      size="small"
                      onClick={() => onClickRemoveCollaborator(c.id)}
                    >
                      {t('folder-edit-permissions-panel_remove-button-label', 'Remove')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </Box>
    </Box>
  );
}
