import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

// Charts
import { Bar } from "react-chartjs-2";

// Helpers
import { IssueService } from "../../Services";
import DisciplineAbbreviation from "./DisciplineAbbreviation";
import DisciplineMenu from "./DisciplineMenu";

const maxVisibleDisciplineCount = 3;

const IssuesPerFlow = ({ issues, floors, categories }) => {
  const { t } = useTranslation();

  const chartData = useMemo(() => {
    return {
      labels: [...floors.map((floor) => floor.name), "N/A"],
      datasets: categories.map((category, index) => ({
        label: category.name,
        data: [
          ...floors.map(
            (floor) =>
              issues.filter(
                (issue) =>
                  issue.floorMetaDataIds.indexOf(floor.id) > -1 &&
                  (issue.disciplineMetaDataIds.indexOf(category.id) > -1 ||
                    (!issue.disciplineMetaDataIds.length && !category.id))
              ).length
          ),
          issues.filter(
            (issue) =>
              !issue.floorMetaDataIds.length &&
              (issue.disciplineMetaDataIds.indexOf(category.id) > -1 ||
                (!issue.disciplineMetaDataIds.length && !category.id))
          ).length,
        ],
        backgroundColor: `${IssueService.getDisciplineColor(index)}33`,
      })),
    };
  }, [issues, floors, categories]);

  const chartOptions = useMemo(
    () => ({
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            callback: (value) => (value > 0 && value % 1 === 0 ? value : ""),
          },
        },
        y: {
          stacked: true,
        },
      },
      indexAxis: "y",
    }),
    []
  );

  if (!floors?.length) return null;

  return (
    <div className="content floor-content mobile--hide">
      <div className="dashboard__content__title">
        <h2>{t("issues_per_floor", "Issues per Floor")}</h2>
      </div>
      <div className="floor-content__categories">
        <div className="floor-content__categories-abbreviation">
          {categories
            .slice(0, maxVisibleDisciplineCount)
            .map((category, index) => (
              <DisciplineAbbreviation
                key={category.id}
                discipline={category}
                index={index}
              />
            ))}
          {categories.length > maxVisibleDisciplineCount && (
            <div className="category-item-count">
              {`+ ${categories.length - maxVisibleDisciplineCount}`}
            </div>
          )}
          {categories.length > maxVisibleDisciplineCount && (
            <DisciplineMenu disciplines={categories} />
          )}
        </div>
      </div>
      <div className="floor-content__chart">
        <Bar
          data={chartData}
          options={chartOptions}
          height={(floors.length + 1) * 15}
        />
      </div>
    </div>
  );
};

export default IssuesPerFlow;
