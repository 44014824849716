import React, { useMemo } from 'react';
import { Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import SuspenseWithErrorBoundary from '../../error/SuspenseWithErrorBoundary';
import IssueBoardColumn from './IssueBoardColumn';

interface IssueBoardStatusColumnProps {
  group: any,
  isVisible: any,
  viewIssue: any,
  issueFilter: any,
  updateObj: { result: DropResult } | undefined,
}

function IssueBoardStatusColumn({
  group,
  isVisible,
  viewIssue,
  issueFilter,
  updateObj,
}: IssueBoardStatusColumnProps) {
  const { t } = useTranslation();

  const issueFilterFixedStatus = useMemo(
    () => (!issueFilter
      ? {
        and: [{ statusId: { eq: group.value } }],
      }
      : {
        and: [{ statusId: { eq: group.value } }, issueFilter],
      }),
    [group.value, issueFilter],
  );

  return (
    <div className="issue-board__column">
      <div className="issue-board__column__title">
        <strong>{t('status_colon', 'Status:')}</strong>
        <span
          className="status"
          style={{
            color: `${group?.color}`,
            backgroundColor: `${group?.color}30`,
          }}
        >
          {group.name}
        </span>
      </div>

      {isVisible ? (
        <SuspenseWithErrorBoundary
          suspenseFallback={<div className="issue-board__column__wrapper" />}
        >
          <IssueBoardColumn
            group={group}
            viewIssue={viewIssue}
            filter={issueFilterFixedStatus}
            updateObj={updateObj}
          />
        </SuspenseWithErrorBoundary>
      ) : (
        <div className="issue-board__column__wrapper">
          <Droppable droppableId={`${group.value}`}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                // Justification: Third party library
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...provided.droppableProps}
                style={{ overflow: 'hidden' }}
              >
                <p className="hidden-column-status">
                  {t('hidden_column_status', 'Content is hidden by the filter')}
                </p>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
    </div>
  );
}
export default IssueBoardStatusColumn;
