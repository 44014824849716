import HardcodedIssueFilter from 'issues/types/HardcodedIssueFilter';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function useHardcodedIssueFilterName() {
  const { t } = useTranslation('issues');
  return useCallback((value: HardcodedIssueFilter) => {
    if (value === HardcodedIssueFilter.AllActiveIssues) return t('hardcoded-issue-filter-name_all-active-issues', 'All active issues');
    if (value === HardcodedIssueFilter.AssignedToMe) return t('hardcoded-issue-filter-name_assigned-to-me', 'Assigned to me');
    if (value === HardcodedIssueFilter.ForReview) return t('hardcoded-issue-filter-name_for-review', 'For review');
    if (value === HardcodedIssueFilter.CreatedByMe) return t('hardcoded-issue-filter-name_created-by-me', 'Created by me');
    if (value === HardcodedIssueFilter.MyDrafts) return t('hardcoded-issue-filter-name_my-drafts', 'My Drafts');
    if (value === HardcodedIssueFilter.Closed) return t('hardcoded-issue-filter-name_closed', 'Closed');
    return '';
  }, [t]);
}
