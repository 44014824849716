import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { MentionsInput, Mention } from "react-mentions";
import useCurrentProjectQuery from "projects/hooks/useCurrentProjectQuery";

const neverMatchingRegex = /($a)/;

const renderSuggestion = (
  suggestion,
  search,
  highlightedDisplay,
  index,
  focused
) => {
  const abbr = suggestion.display
    .split(" ")
    .map((item) => (item ? item[0] : ""));
  return (
    <div className={`user ${focused ? "focused" : ""}`}>
      <span className="user-abbr">{abbr}</span>
      {highlightedDisplay}
    </div>
  );
};

const SymbolTextInput = ({
  value,
  onChange,
  placeholder,
  singleLine,
  isAboveCursor = false,
}) => {
  const { data: currentProject } = useCurrentProjectQuery();
  const { userPreviews } = currentProject;
  const [emojis, setEmojis] = useState([]);

  const userMentionsData = useMemo(
    () =>
      userPreviews.map((user) => {
        const fullName = `${user.firstName} ${user.lastName}`;
        return {
          id: user.id,
          display: fullName,
        };
      }),
    [userPreviews]
  );

  useEffect(() => {
    fetch(
      "https://gist.githubusercontent.com/oliveratgithub/0bf11a9aff0d6da7b46f1490f86a71eb/raw/d8e4b78cfe66862cf3809443c1dba017f37b61db/emojis.json"
    )
      .then((response) => {
        return response.json();
      })
      .then((jsonData) => {
        setEmojis(jsonData.emojis);
      });
  }, []);

  const queryEmojis = (query) => {
    if (query.length === 0) return;

    const matches = emojis
      .filter((emoji) => {
        return emoji.name.indexOf(query.toLowerCase()) > -1;
      })
      .slice(0, 10);
    return matches.map(({ emoji }) => ({ id: emoji }));
  };

  const onChangeInterceptor = useCallback((event, newValue, newPlainTextValue, mentions) => {
    onChange(event);
  }, []);

  return (
    <MentionsInput
      value={value}
      onChange={onChangeInterceptor}
      className="mentions"
      placeholder={placeholder}
      singleLine={singleLine}
      allowSuggestionsAboveCursor={isAboveCursor}
    >
      <Mention
        data={userMentionsData}
        className="mentions__mention"
        trigger="@"
        displayTransform={(id, display) => `@${display}`}
        renderSuggestion={renderSuggestion}
        markup="@User[__display__](__id__)"
      />
      <Mention
        trigger=":"
        markup="__id__"
        regex={neverMatchingRegex}
        data={queryEmojis}
      />
    </MentionsInput>
  );
};

export default SymbolTextInput;
