import React, {
  ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useQueryClient } from '@tanstack/react-query';

import DocumentUploadContext, { DocumentUploadContextState } from 'upload/contexts/DocumentUploadContext';
import DocumentUploadWizardModal from 'upload/components/DocumentUploadWizard/DocumentUploadWizardModal';
import DocumentUploadPayload from 'upload/types/DocumentUploadPayload';

import UploadingFileHandle from 'upload/types/UploadingFileHandle';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import useFileUploadHandler from 'upload/hooks/useDocumentUploadHandler';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useFolderTreeQueryKey from 'documents-folders/hooks/useFolderTreeQueryKey';

interface DocumentUploadProviderProps {
  children: ReactNode,
}

export default function DocumentUploadProvider({
  children,
}: DocumentUploadProviderProps) {
  const { data: currentProject } = useCurrentProjectQuery();
  const [stagedPayload, setStagedPayload] = useState<DocumentUploadPayload | null>(null);
  const [uploadingFileHandles, setUploadingFileHandles] = useState<UploadingFileHandle[]>([]);
  const [isWizardVisible, setIsWizardVisible] = useState(false);
  const showWizard = () => setIsWizardVisible(true);
  const hideWizard = () => setIsWizardVisible(false);
  const handleUploadDocuments = useFileUploadHandler();
  const queryClient = useQueryClient();
  const { baseQueryKey: documentVersionBaseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const folderTreeQueryKey = useFolderTreeQueryKey();
  const clear = useCallback(() => {
    if (uploadingFileHandles?.length) {
      uploadingFileHandles.forEach((handle) => {
        if (!handle.isCompleted) {
          handle.request.abort();
        }
      });
    }
    setUploadingFileHandles([]);
    setStagedPayload(null);
    hideWizard();
  }, [uploadingFileHandles]);

  useEffect(() => {
    if (!uploadingFileHandles?.length) return;
    // clear upload state when leaving/switching projects
    if (!currentProject?.id || uploadingFileHandles.findIndex((h) => currentProject.id !== h.projectId) > -1) {
      clear();
    }
  }, [currentProject, uploadingFileHandles, clear]);

  const state = useMemo<DocumentUploadContextState>(() => ({
    stagedPayload,
    uploadingFileHandles,
    addToDocumentsStagedForUpload: (payload: DocumentUploadPayload) => {
      setStagedPayload((currentlyStagedPayload) => {
        if (!currentlyStagedPayload) return payload;

        // if there are already staged uploads, merge new files with currently staged files
        // but only if projectId, folderId and docStatusId are the same, otherwise ignore the new files (dropzones etc. should ensure this to be impossible)
        // if mixing payloads with differences here, logic and UI must be adjusted accordingly.
        const {
          files: filesToStage,
          folderId: folderIdToStage,
          fileNamesThatAreFolders,
        } = payload;
        const {
          files: currentFiles,
          folderId: currentFolderId,
        } = currentlyStagedPayload;
        if (folderIdToStage !== currentFolderId) return currentlyStagedPayload;

        // make sure we don't upload two files with the same name in the same upload process
        const currentFileNames = new Set(currentFiles.map((file) => file.name));
        const filesToStageNotAlreadyStaged = filesToStage.filter((file) => !currentFileNames.has(file.name));
        const files = [...currentFiles, ...filesToStageNotAlreadyStaged];
        return {
          ...currentlyStagedPayload,
          files,
          fileNamesThatAreFolders,
        };
      });
    },
    startUploadingStagedPayload: () => {
      if (!stagedPayload) return;
      // whenever one of the uploads triggers a progress event, touch the handle array to trigger a re-render of all consumers
      const onProgress = () => setUploadingFileHandles((currentUploadHandles) => Array.from(currentUploadHandles));
      const onFinish = () => {
        queryClient.invalidateQueries(folderTreeQueryKey);
        queryClient.invalidateQueries(documentVersionBaseQueryKey);
      };
      const newUploadHandles = handleUploadDocuments(stagedPayload, onProgress, onFinish);
      setUploadingFileHandles((currentUploadHandles) => [...currentUploadHandles, ...newUploadHandles]);
      setStagedPayload(null);
    },
    discardStagedFiles: (fileNames: string[]) => {
      const fileNamesSet = new Set<string>(fileNames);
      setStagedPayload((payload) => {
        if (!payload) return null;
        return {
          ...payload,
          files: payload?.files.filter((file) => !fileNamesSet.has(file.name)),
        };
      });
    },
    openWizard: showWizard,
    clear,
  }), [clear, folderTreeQueryKey, documentVersionBaseQueryKey, handleUploadDocuments, queryClient, stagedPayload, uploadingFileHandles]);

  return (
    <DocumentUploadContext.Provider value={state}>
      {children}
      {isWizardVisible && <DocumentUploadWizardModal hideWizard={hideWizard} />}
    </DocumentUploadContext.Provider>
  );
}
