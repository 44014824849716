import { useCallback, useMemo } from 'react';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import { VisoDetailsQueryKey } from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { QueryObserverOptions, WithRequired } from '@tanstack/react-query';

export type ResourceQueryKey = VisoDetailsQueryKey<ApiEndpoint.Resource>;
export type ResourceQueryQueryFnData = string | undefined;
export type ResourceQueryOptions = WithRequired<Partial<Omit<QueryObserverOptions<ResourceQueryQueryFnData, unknown, ResourceQueryQueryFnData, ResourceQueryQueryFnData, ResourceQueryKey>, 'queryKey'>>, 'queryFn'>;

export default function useResourceQueryOptions() {
  const axiosInstance = useAxiosInstance();
  const queryFn = useCallback(async ({ queryKey }: { queryKey: ResourceQueryKey }) => {
    const [endpoint, , , id] = queryKey;
    const url = `${endpoint}/${id}`;
    const response = await axiosInstance.get<Blob>(url, { responseType: 'blob' });
    const href = URL.createObjectURL(response.data);
    return href;
  }, [axiosInstance]);
  return useMemo<ResourceQueryOptions>(() => ({
    queryFn,
  }), [queryFn]);
}
