import { useMemo } from 'react';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import LabelDto from 'labels/types/LabelDto';

export function inReviewIssueStatusFilterPredicate(issueStatus: LabelDto) {
  return issueStatus.originalName === 'In Review';
}

export default function useInReviewIssuesStatus() {
  const { data: issueStatuses } = useProjectIssueStatusesQuery();
  return useMemo(() => issueStatuses?.find(inReviewIssueStatusFilterPredicate), [issueStatuses]);
}
