import React, { useCallback, useMemo } from 'react';
import { Box, ButtonBase, Typography, useTheme } from '@mui/material';
import { Core } from '@pdftron/webviewer';
import ISxProps from 'common/types/ISxProps';

interface InternalAnnotationItemProps extends ISxProps {
  annotation: Core.Annotations.Annotation,
  onClick: (annotation: Core.Annotations.Annotation) => void,
  selected: boolean | undefined,
  hideDate: boolean | undefined,
}

export default function InternalAnnotationItem({
  sx,
  annotation,
  onClick,
  selected,
  hideDate,
}: InternalAnnotationItemProps) {
  const onClickButton = useCallback(() => onClick(annotation), [annotation, onClick]);
  const dateString = useMemo(() => {
    const originalDateModified = annotation.getCustomData('originalDateModified');
    if (originalDateModified) return new Date().toLocaleString('de-DE');
    return undefined;
  }, [annotation]);
  const theme = useTheme();
  return (
    <ButtonBase
      id="InternalAnnotationItem"
      onClick={onClickButton}
      key={annotation.Id}
      sx={{
        ...sx,
        borderRadius: '2px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        p: 2,
        gap: 1,
        boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.2)',
        backgroundColor: selected ? theme.palette.secondary.light : undefined,
      }}
    >
      <Box sx={{ display: 'flex', alignSelf: 'stretch' }}>
        <Typography sx={{ fontWeight: 'bold', textAlign: 'left' }}>{annotation.Author}</Typography>
        {!hideDate && !!dateString && <Typography sx={{ ml: 'auto' }}>{dateString}</Typography>}
      </Box>
      {!!annotation.getContents().trim() && <Box>{annotation.getContents()}</Box>}
    </ButtonBase>
  );
}
