import React, {
  Suspense, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { Box, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import FilterDrawer from 'documents-filter/components/FilterDrawer';
import LeftUtilityDrawer from 'documents/components/LeftUtilityDrawer';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import DocumentsDataGrid from 'documents/components/DocumentsDataGrid';
import DocumentsDataGridHeader from 'documents/components/DocumentsDataGridHeader';
import MassEditDrawer from 'documents/components/MassEditDrawer';
import DocumentSelectionContext, { DocumentSelectionContextState } from 'documents/contexts/DocumentSelectionContext';
import ColumnOptionsButtonMenu from 'documents/components/ColumnOptionsButtonMenu';
import DocumentsNavigationMenu from 'documents/components/DocumentsNavigationMenu';
import DocumentDragAndDropContextProvider from 'documents/contexts/DocumentDragAndDropContextProvider';
import useDocumentUploadDropRef from 'upload/hooks/useDocumentUploadDropRef';
import useCurrentFolderId from 'documents-folders/hooks/useCurrentFolderId';
import DocumentDropOverlay from 'upload/components/DocumentDropOverlay';
import DocumentStatisticsContextProvider from 'documents/contexts/DocumentStatisticsContextProvider';
import DocumentUiConfigContext, { DocumentUiConfigContextState } from 'documents/contexts/DocumentUiConfigContext';
import DocumentsFooterDrawer from 'documents/components/DocumentsFooterDrawer';
import DocumentsNavigationTabs from 'documents/components/DocumentsNavigationTabs';
import HorizontalDivider from 'common/styled/HorizontalDivider';
import SuspenseWithErrorBoundary from 'error/SuspenseWithErrorBoundary';
import useDocumentScopeContext from 'documents/hooks/useDocumentScopeContext';
import DocumentDetailsModal from 'documents-details/components/DocumentDetailsModal';
import useDocumentVersionQuery from 'documents/hooks/useDocumentVersionQuery';
import DocumentVersionArchivedErrorDialog from 'documents/components/DocumentVersionArchivedErrorDialog';

export default function DocumentsBrowser() {
  const [isMassEditDrawerOpen, setIsMassEditDrawerOpen] = useState(false);
  const { suppressFooterDrawer } = useContext<DocumentUiConfigContextState>(DocumentUiConfigContext);
  const { selectedDocumentVersionIds } = useContext<DocumentSelectionContextState>(DocumentSelectionContext);
  const { documentVersionId, setDocumentVersionId, setHighlightedDocumentCommentId } = useDocumentScopeContext();
  const { data: documentVersion } = useDocumentVersionQuery(documentVersionId);
  const isFooterDrawerOpen = useMemo(() => selectedDocumentVersionIds.length > 0 && !suppressFooterDrawer, [selectedDocumentVersionIds.length, suppressFooterDrawer]);
  const folderId = useCurrentFolderId();
  const { canDropDocuments, documentUploadDropRef } = useDocumentUploadDropRef(folderId);
  const theme = useTheme();

  const onCloseArchivedErrorDialog = useCallback(() => {
    setDocumentVersionId(undefined);
  }, [setDocumentVersionId]);

  // comment drawer can be opened via a redirect URL to a specific document version comment (see the redirect route at the bottom of App.tsx and the scroll-to code in DocumentCommentsTab.tsx)
  const { state } = useLocation();
  useEffect(() => {
    if (state?.documentVersionId && state?.documentVersionCommentId) {
      setDocumentVersionId(state.documentVersionId as string);
      setHighlightedDocumentCommentId(state.documentVersionCommentId as string);
    }
  }, [setDocumentVersionId, setHighlightedDocumentCommentId, state]);

  return (
    <DocumentDragAndDropContextProvider>
      <DocumentStatisticsContextProvider>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          backgroundColor: theme.palette.background.default,
        }}
        >
          <LeftUtilityDrawer sx={{
            display: 'flex', flexDirection: 'column', flexShrink: 0,
          }}
          >
            <DocumentsNavigationMenu />
            <HorizontalDivider />
            <DocumentsNavigationTabs sx={{ flexGrow: 1 }} />
          </LeftUtilityDrawer>
          <Box sx={{ flexGrow: 1, height: '100%', display: 'flex' }}>
            <SuspenseWithErrorBoundary suspenseFallback={<CenteredCircularProgress />}>
              <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <DocumentsDataGridHeader />
                <Box sx={{ flexGrow: 1, position: 'relative' }} ref={documentUploadDropRef}>
                  <Suspense fallback={<CenteredCircularProgress />}>
                    <DocumentsDataGrid />
                    <ColumnOptionsButtonMenu sx={{ position: 'absolute', right: '16px', top: '12px' }} />
                  </Suspense>
                  {canDropDocuments && (
                    <DocumentDropOverlay sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      zIndex: 3,
                    }}
                    />
                  )}
                </Box>
                <DocumentsFooterDrawer isOpen={isFooterDrawerOpen} onClickMassEdit={() => setIsMassEditDrawerOpen((prev) => !prev)} />
              </Box>
              <MassEditDrawer isOpen={isMassEditDrawerOpen} onClose={() => setIsMassEditDrawerOpen(false)} />
            </SuspenseWithErrorBoundary>
          </Box>
          <FilterDrawer />
        </Box>
        {!!documentVersion && !documentVersion.isArchived && <DocumentDetailsModal />}
        {!!documentVersion && !!documentVersion.isArchived && <DocumentVersionArchivedErrorDialog onClose={onCloseArchivedErrorDialog} />}
      </DocumentStatisticsContextProvider>
    </DocumentDragAndDropContextProvider>
  );
}
