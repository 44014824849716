import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { orderBy } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { SketchPicker } from "react-color";
import { ClickAwayListener } from "@mui/base";

import { AddMoreButton } from "../Buttons";
import TagCreationItem from "../TagCreationItem";
import ChangeStatusFeedback from "../ChangeStatusFeedback";
import NotificationModal from "../NotificationModal";
import Loader from "../Loader";

import * as Utils from "../../Helpers/utils";
import { IssueSettingService, TagService } from "../../Services";
import useCurrentProjectQuery from "projects/hooks/useCurrentProjectQuery";
import useCurrentUserRole from "users/hooks/useCurrentUserRole";
import RoleAction from "projects/types/RoleAction";
import useLegacySettingsNotificationDialogContext from 'settings/hooks/useLegacySettingsNotificationDialogContext';

const IssueSettingEnum = {
  type: 0,
  priority: 1,
  state: 2,
};

const IssueSetting = () => {
  const { saveChangesNotification, setSaveChangesNotification, setHasUnsavedChanges } = useLegacySettingsNotificationDialogContext();
  const { t } = useTranslation("settings");
  const { data: currentProject } = useCurrentProjectQuery();
  const dispatch = useDispatch();
  const [otherMetaData, setOtherMetaData] = useState({
    [IssueSettingEnum.type]: [],
    [IssueSettingEnum.priority]: [],
    [IssueSettingEnum.state]: [],
  });
  const [initialIssueItems, setInitialIssueItems] = useState({
    [IssueSettingEnum.type]: [],
    [IssueSettingEnum.priority]: [],
    [IssueSettingEnum.state]: [],
  });
  const [issueItems, setIssueItems] = useState({
    [IssueSettingEnum.type]: [],
    [IssueSettingEnum.priority]: [],
    [IssueSettingEnum.state]: [],
  });
  const [isEnableSave, setIsEnableSave] = useState(false);
  const [isVisibleColorPicker, setVisibleColorPicker] = useState(false);
  const [pickedColor, setPickedColor] = useState({
    id: "",
    color: "",
  });
  const [errorMsgs, setErrorMsgs] = useState({
    [IssueSettingEnum.type]: [],
    [IssueSettingEnum.priority]: [],
    [IssueSettingEnum.state]: [],
  });
  const [isVisibleSavedFeedback, setVisibleSavedFeedback] = useState(false);
  const [notification, setNotification] = useState(null);
  const currentUserRole = useCurrentUserRole();
  const [selectedIconPopup, setSelectedIconPopup] = useState(null);
  const [selectedColorPickerRef, setSelectedColorPickerRef] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const isExistError = useMemo(
    () =>
      Object.values(errorMsgs).some((errors) =>
        errors.some((error) => !!error)
      ),
    [errorMsgs]
  );

  const fetchData = async () => {
    if (!currentProject?.id) return;
    const bufferType = await IssueSettingService.getIssueType({
      projectId: currentProject.id,
    });
    const otherTypeIndex = bufferType.findIndex(
      (item) => item.originalName === "Other"
    );

    const bufferPriority = await IssueSettingService.getIssuePriority({
      projectId: currentProject.id,
    });
    const otherPriorityIndex = bufferPriority.findIndex(
      (item) => item.originalName === "Other"
    );

    const bufferState = await IssueSettingService.getIssueStatus({
      projectId: currentProject.id,
    });
    const otherStateIndex = bufferState.findIndex(
      (item) => item.originalName === "Other"
    );

    setOtherMetaData({
      [IssueSettingEnum.type]: bufferType[otherTypeIndex]?.id,
      [IssueSettingEnum.priority]: bufferPriority[otherPriorityIndex]?.id,
      [IssueSettingEnum.state]: bufferState[otherStateIndex]?.id,
    });

    bufferType.splice(otherTypeIndex, 1);
    bufferPriority.splice(otherPriorityIndex, 1);
    bufferState.splice(otherStateIndex, 1);

    setIssueItems({
      [IssueSettingEnum.type]: bufferType,
      [IssueSettingEnum.priority]: bufferPriority,
      [IssueSettingEnum.state]: bufferState,
    });
    setErrorMsgs({
      [IssueSettingEnum.type]: bufferType.map(() => ""),
      [IssueSettingEnum.priority]: bufferPriority.map(() => ""),
      [IssueSettingEnum.state]: bufferState.map(() => ""),
    });
    setInitialIssueItems({
      [IssueSettingEnum.type]: JSON.parse(JSON.stringify(bufferType)),
      [IssueSettingEnum.priority]: JSON.parse(JSON.stringify(bufferPriority)),
      [IssueSettingEnum.state]: JSON.parse(JSON.stringify(bufferState)),
    });
  };

  useEffect(() => {
    fetchData();
  }, [currentProject]);

  useEffect(() => {
    setHasUnsavedChanges(isEnableSave);
  }, [isEnableSave]);

  const onSave = useCallback(
    async (e) => {
      e?.preventDefault();
      setIsSaving(true);

      let result = false;
      for (let [key, issueItem] of Object.entries(issueItems)) {
        key = parseInt(key);
        const creationStatuses = [],
          updateStatuses = [];
        let isOrderUpdated = false;

        issueItem.forEach((status) => {
          if (!status.id) creationStatuses.push(status);
          else {
            const index = initialIssueItems[key].findIndex(
              (item) => item.id === status.id
            );
            if (
              index >= 0 &&
              (initialIssueItems[key][index].name !== status.name ||
                initialIssueItems[key][index].color !== status.color ||
                initialIssueItems[key][index].order !== status.order ||
                (key === IssueSettingEnum.type &&
                  initialIssueItems[key][index].icon !== status.icon))
            ) {
              updateStatuses.push(status);
              if (
                initialIssueItems[key][index].order !== status.order &&
                !isOrderUpdated
              )
                isOrderUpdated = true;
            }
          }
        });

        let createdBuffer = [...issueItem];
        //create new statuses
        const createParams = creationStatuses.map((item) => {
          let params = {
            projectId: item.projectId,
            name: item.name,
          };
          if (key !== IssueSettingEnum.priority) {
            params = {
              ...params,
              color: item.color,
            };
          }
          if (key === IssueSettingEnum.type) {
            params = {
              ...params,
              icon: item.icon,
            };
          }
          return params;
        });

        if (createParams.length > 0) {
          let response = null;
          if (key === IssueSettingEnum.type)
            response = await IssueSettingService.createIssueType(createParams);
          if (key === IssueSettingEnum.priority)
            response = await IssueSettingService.createIssuePriority(
              createParams
            );
          if (key === IssueSettingEnum.state)
            response = await IssueSettingService.createIssueStatus(
              createParams
            );

          if (response?.length) {
            createdBuffer = issueItem.filter((status) => !!status.id);
            const orderUpdatedResponse = response.map((item) => {
              item.order = issueItem.find(
                (status) => status.name === item.name
              )?.order;
              return item;
            });
            createdBuffer.push(...orderUpdatedResponse);
            setIssueItems((items) => ({
              ...items,
              [key]: createdBuffer,
            }));
            result = true;
          } else result = false;
        }
        //update issue metadata order
        if (createParams.length || isOrderUpdated) {
          const descriptorIds = orderBy(createdBuffer, ["order"], ["asc"]).map(
            (status) => status.id
          );
          // put Other status/type/priority order at the end
          descriptorIds.push(otherMetaData[key]);

          if (key === IssueSettingEnum.type)
            result = await IssueSettingService.updateIssueTypeOrder({
              projectId: currentProject.id,
              descriptorIds,
            });
          if (key === IssueSettingEnum.priority)
            result = await IssueSettingService.updateIssuePriorityOrder({
              projectId: currentProject.id,
              descriptorIds,
            });
          if (key === IssueSettingEnum.state)
            result = await IssueSettingService.updateIssueStatusOrder({
              projectId: currentProject.id,
              descriptorIds,
            });
        }

        //update the statuses
        const updateParams = [];
        updateStatuses.forEach((item) => {
          if (item.isDefault) return;

          let param = { id: item.id };
          const findItem = initialIssueItems[key].find(
            (attr) => attr.id === item.id
          );
          if (findItem?.name !== item.name) {
            param = {
              ...param,
              name: item.name,
            };
          }

          if (
            findItem?.color !== item.color &&
            key !== IssueSettingEnum.priority
          ) {
            param = {
              ...param,
              color: item.color,
            };
          }
          if (findItem?.icon !== item.icon && key === IssueSettingEnum.type) {
            param = {
              ...param,
              icon: item.icon,
            };
          }
          if (Object.keys(param).length > 1) {
            updateParams.push({
              name: findItem.name,
              color: findItem.color,
              icon: findItem.icon,
              ...param
            });
          }
        });

        if (updateParams.length > 0) {
          if (key === IssueSettingEnum.type)
            result = await IssueSettingService.updateIssueType(updateParams);
          if (key === IssueSettingEnum.priority)
            result = await IssueSettingService.updateIssuePriority(
              updateParams
            );
          if (key === IssueSettingEnum.state)
            result = await IssueSettingService.updateIssueStatus(updateParams);
        }
      }

      if (result) {
        fetchData();
        setVisibleSavedFeedback(true);
        setTimeout(() => {
          setVisibleSavedFeedback(false);
        }, 3000);
        setIsEnableSave(false);
      }
      setIsSaving(false);
    },
    [initialIssueItems, issueItems, currentProject]
  );

  const getAddItemColor = (key) => {
    let index = issueItems[key].length + 1;
    if (index >= TagService.TagColors.length) {
      index =
        index -
        parseInt(index / TagService.TagColors.length) *
          TagService.TagColors.length;
      return TagService.TagColors[index];
    } else {
      return TagService.TagColors[index];
    }
  };

  const onAddMore = useCallback(
    (key) => {
      setIsEnableSave(true);
      setIssueItems((items) => ({
        ...items,
        [key]: Utils.addArrayItem(issueItems[key], {
          projectId: currentProject?.id,
          name: "",
          color: getAddItemColor(key),
          order:
            issueItems[key].length +
            2 /*because Other status/type/priority missing*/,
        }),
      }));
      setErrorMsgs((errors) => ({
        ...errors,
        [key]: Utils.addArrayItem(errorMsgs[key], ""),
      }));
    },
    [issueItems, currentProject?.id, errorMsgs]
  );

  const onRemoveStatus = useCallback(
    async (key, order) => {
      let isRemove = true;
      const index = issueItems[key].findIndex(
        (status) => status.order === order
      );
      if (issueItems[key][index].id) {
        if (key === IssueSettingEnum.type) {
          isRemove = await IssueSettingService.deleteIssueType(
            issueItems[key][index].id
          );
        }
        if (key === IssueSettingEnum.priority) {
          isRemove = await IssueSettingService.deleteIssuePriority(
            issueItems[key][index].id
          );
        }
        if (key === IssueSettingEnum.state) {
          isRemove = await IssueSettingService.deleteIssueStatus(
            issueItems[key][index].id
          );
        }
      }
      if (isRemove) {
        setIsEnableSave(true);
        setVisibleSavedFeedback(true);
        setTimeout(() => {
          setVisibleSavedFeedback(false);
        }, 3000);
        setIssueItems((items) => ({
          ...items,
          [key]: Utils.removeArrayItem(issueItems[key], index),
        }));
        setErrorMsgs((errors) => ({
          ...errors,
          [key]: Utils.removeArrayItem(errorMsgs[key], index),
        }));
      } else {
        let title = "issue_state_in_use";
        if (key === IssueSettingEnum.type) title = "issue_type_in_use";
        else if (key === IssueSettingEnum.priority)
          title = "issue_priority_in_use";

        setNotification({
          title: t(title),
          description: "",
          doneText: "",
        });
      }
    },
    [issueItems, errorMsgs]
  );

  const changeTypeIcon = useCallback(
    (e, iconName) => {
      e.preventDefault();
      setIsEnableSave(true);
      setIssueItems((items) => {
        const types = items[IssueSettingEnum.type].map((item) => {
          if (item.order === selectedIconPopup.order)
            return {
              ...item,
              icon: iconName,
            };
          else return item;
        });
        return {
          ...items,
          [IssueSettingEnum.type]: types,
        };
      });
    },
    [selectedIconPopup]
  );

  const handleStatusChange = useCallback(
    (e, key, order) => {
      setIsEnableSave(true);
      const value = e.target.value;
      const index = issueItems[key].findIndex(
        (status) => status.order === order
      );

      const bufferStatuses = Utils.replaceArrayItem(
        issueItems[key],
        { ...issueItems[key][index], name: value },
        index
      );
      let bufferErrorMsgs = [...errorMsgs[key]];
      bufferStatuses.forEach((item, statusIndex) => {
        if (
          bufferStatuses.findIndex(
            (innerItem, innerStatusIndex) =>
              item.name &&
              statusIndex !== innerStatusIndex &&
              innerItem.name.toLowerCase() === item.name.toLowerCase()
          ) >= 0
        )
          bufferErrorMsgs = Utils.replaceArrayItem(
            bufferErrorMsgs,
            t("choose_unique_name", "*Please choose a unique name"),
            statusIndex
          );
        else if (value.length > 25)
          bufferErrorMsgs = Utils.replaceArrayItem(
            bufferErrorMsgs,
            "too long name",
            bufferStatuses.length - 1
          );
        else
          bufferErrorMsgs = Utils.replaceArrayItem(
            bufferErrorMsgs,
            "",
            statusIndex
          );
      });
      setErrorMsgs((errors) => ({
        ...errors,
        [key]: bufferErrorMsgs,
      }));
      setIssueItems((items) => ({
        ...items,
        [key]: bufferStatuses,
      }));
    },
    [issueItems, errorMsgs]
  );

  const handleShowPicker = useCallback((key, order, color, colorPickerRef) => {
    setVisibleColorPicker(true);
    setPickedColor({ key, order, color });
    setSelectedColorPickerRef(colorPickerRef);
  }, []);

  const handleChangeColor = useCallback(
    (color) => {
      setPickedColor({ ...pickedColor, color: color.hex });
    },
    [pickedColor]
  );

  const handleChangeColorComplete = useCallback(
    (color) => {
      setIsEnableSave(true);
      setIssueItems((buffer) => {
        const result = buffer[pickedColor.key].map((item) => {
          if (item.order === pickedColor.order) item.color = color.hex;
          return item;
        });
        return {
          ...buffer,
          [pickedColor.key]: result,
        };
      });
    },
    [pickedColor]
  );

  useEffect(() => {
    if (currentProject && saveChangesNotification === "discard") {
      fetchData();
      setIsEnableSave(false);
      setSaveChangesNotification("");
    }
    if (currentProject && saveChangesNotification === "save") {
      onSave();
      setSaveChangesNotification("");
    }
  }, [currentProject, saveChangesNotification]);

  const onNextOrder = useCallback(
    (key, status) => {
      if (
        status.order === issueItems[key].length ||
        errorMsgs[key].some((msg) => !!msg)
      )
        return;
      setIsEnableSave(true);
      const { order } = status;
      const nextOrder = order + 1;
      const buffer = issueItems[key].map((item) => {
        if (item.order === order) item.order = nextOrder;
        else if (item.order === nextOrder) item.order = order;
        return item;
      });
      setIssueItems((items) => ({
        ...items,
        [key]: buffer,
      }));
    },
    [issueItems, errorMsgs]
  );

  const onPrevOrder = useCallback(
    (key, status) => {
      if (status.order === 1 || errorMsgs[key].some((msg) => !!msg)) return;
      setIsEnableSave(true);
      const { order } = status;
      const prevOrder = order - 1;
      const buffer = issueItems[key].map((item) => {
        if (item.order === prevOrder) item.order = order;
        else if (item.order === order) item.order = prevOrder;
        return item;
      });
      setIssueItems((items) => ({
        ...items,
        [key]: buffer,
      }));
    },
    [issueItems, errorMsgs]
  );

  return (
    <div id="IssueSetting" className="setting-view issue-setting">
      <form onSubmit={onSave}>
        <div className="project-tags-header p-relative">
          {isVisibleSavedFeedback && (
            <ChangeStatusFeedback
              description={t(
                "issue_setting_saved_successfully",
                "Issue setting saved successfully"
              )}
            />
          )}
          <input
            type="submit"
            className="btn btn--primary btn-save"
            value={t("save", { ns: "common", defaultValue: "Save" })}
            disabled={!isEnableSave || isExistError}
          />
          <Loader
            isSmall={true}
            isLoading={isSaving}
          />
        </div>
        <div className="project-tags-content">
          <div className="project-tags-content-left">
            <h3 className="project-tags-content-left-title">
              {t("issue_settings", "Issue Settings")}
            </h3>
            <p className="project-tags-content-left-description">
              {t(
                "which_states_issues_have",
                "Which states your issues should have? Which columns your do you need in your issue list?"
              )}
            </p>
          </div>
          <div className="project-tags-content-right">
            <div className="settings-group">
              <div className="settings-group-item item-type">
                <div className="title-group">
                  <h3 className="status-item-title">{t("types", "Types")}</h3>
                  {currentUserRole?.allowedActions?.has(RoleAction.IssueStatus_Create_Edit) && (
                    <AddMoreButton
                      onClick={() => onAddMore(IssueSettingEnum.type)}
                      label="add_type"
                    />
                  )}
                </div>
                <div className="content-group">
                  {orderBy(
                    issueItems[IssueSettingEnum.type],
                    ["order"],
                    ["asc"]
                  ).map((status, index) => (
                    <div className="item" key={`item-${index}`}>
                      <div className="status-item">
                        <TagCreationItem
                          id={`${index}-type-item`}
                          item={status}
                          errorMsg={errorMsgs[IssueSettingEnum.type][index]}
                          index={index + 1}
                          selectedIconPopup={selectedIconPopup}
                          isEnableRemove={
                            currentUserRole?.allowedActions?.has(RoleAction.IssueStatus_Delete) &&
                            !status.isDefault
                          }
                          isEnableEdit={
                            currentUserRole?.allowedActions?.has(RoleAction.IssueStatus_Create_Edit) &&
                            !status.isDefault
                          }
                          onChangeInput={(e) =>
                            handleStatusChange(
                              e,
                              IssueSettingEnum.type,
                              status.order
                            )
                          }
                          onClickPicker={(colorPickerRef) =>
                            handleShowPicker(
                              IssueSettingEnum.type,
                              status.order,
                              status.color,
                              colorPickerRef
                            )
                          }
                          onRemoveItem={() =>
                            onRemoveStatus(IssueSettingEnum.type, status.order)
                          }
                          onNextOrder={() =>
                            onNextOrder(IssueSettingEnum.type, status)
                          }
                          onPrevOrder={() =>
                            onPrevOrder(IssueSettingEnum.type, status)
                          }
                          setSelectedIconPopup={setSelectedIconPopup}
                          onChangeTypeIcon={changeTypeIcon}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="settings-group-item">
                <div className="title-group">
                  <h3 className="status-item-title">
                    {t("priority", "Priority")}
                  </h3>
                  {currentUserRole?.allowedActions?.has(RoleAction.IssueStatus_Create_Edit) && (
                    <AddMoreButton
                      onClick={() => onAddMore(IssueSettingEnum.priority)}
                      label="add_priority"
                    />
                  )}
                </div>
                <div className="content-group">
                  {orderBy(
                    issueItems[IssueSettingEnum.priority],
                    ["order"],
                    ["asc"]
                  ).map((status, index) => (
                    <div className="item" key={`item-${index}`}>
                      <div className="status-item">
                        <TagCreationItem
                          item={status}
                          errorMsg={errorMsgs[IssueSettingEnum.priority][index]}
                          isEnableRemove={
                            currentUserRole?.allowedActions?.has(RoleAction.IssueStatus_Delete) &&
                            !status.isDefault
                          }
                          isEnableEdit={
                            currentUserRole?.allowedActions?.has(RoleAction.IssueStatus_Create_Edit) &&
                            !status.isDefault
                          }
                          onChangeInput={(e) =>
                            handleStatusChange(
                              e,
                              IssueSettingEnum.priority,
                              status.order
                            )
                          }
                          onRemoveItem={() =>
                            onRemoveStatus(
                              IssueSettingEnum.priority,
                              status.order
                            )
                          }
                          onNextOrder={() =>
                            onNextOrder(IssueSettingEnum.priority, status)
                          }
                          onPrevOrder={() =>
                            onPrevOrder(IssueSettingEnum.priority, status)
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="settings-group-item">
                <div className="title-group">
                  <h3 className="status-item-title">{t("state", "State")}</h3>
                  {currentUserRole?.allowedActions?.has(RoleAction.IssueStatus_Create_Edit) && (
                    <AddMoreButton
                      onClick={() => onAddMore(IssueSettingEnum.state)}
                      label="add_state"
                    />
                  )}
                </div>
                <div className="content-group">
                  {orderBy(
                    issueItems[IssueSettingEnum.state],
                    ["order"],
                    ["asc"]
                  ).map((status, index) => (
                    <div className="item" key={`item-${index}`}>
                      <div className="status-item">
                        <TagCreationItem
                          id={`${index}-state-item`}
                          item={status}
                          errorMsg={errorMsgs[IssueSettingEnum.state][index]}
                          index={index + 1}
                          isEnableRemove={
                            currentUserRole?.allowedActions?.has(RoleAction.IssueStatus_Delete) &&
                            !status.isDefault
                          }
                          isEnableEdit={
                            currentUserRole?.allowedActions?.has(RoleAction.IssueStatus_Create_Edit) &&
                            !status.isDefault
                          }
                          onChangeInput={(e) =>
                            handleStatusChange(
                              e,
                              IssueSettingEnum.state,
                              status.order
                            )
                          }
                          onClickPicker={(colorPickerRef) =>
                            handleShowPicker(
                              IssueSettingEnum.state,
                              status.order,
                              status.color,
                              colorPickerRef
                            )
                          }
                          onRemoveItem={() =>
                            onRemoveStatus(IssueSettingEnum.state, status.order)
                          }
                          onNextOrder={() =>
                            onNextOrder(IssueSettingEnum.state, status)
                          }
                          onPrevOrder={() =>
                            onPrevOrder(IssueSettingEnum.state, status)
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {isVisibleColorPicker && (
        <ClickAwayListener
          onClickAway={() => setVisibleColorPicker(false)}
        >
          <div
            style={{
              position: "absolute",
              top: selectedColorPickerRef.current?.offsetTop - 270,
              left: selectedColorPickerRef.current?.id.includes("type")
                ? selectedColorPickerRef.current?.offsetLeft + 210
                : selectedColorPickerRef.current?.offsetLeft - 100,
            }}
          >
            <SketchPicker
              color={pickedColor.color}
              onChange={(color) => handleChangeColor(color)}
              onChangeComplete={(color) => handleChangeColorComplete(color)}
            />
          </div>
        </ClickAwayListener>
      )}
      {!!notification && (
        <NotificationModal
          onDone={() => setNotification(null)}
          notification={notification}
          isAutomationClose={true}
        />
      )}
    </div>
  );
};

export default IssueSetting;
