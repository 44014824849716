import React, { useMemo } from 'react';
import { Chip, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import Color from 'color';
import Icon from '@mdi/react';

interface IconChipProps extends ISxProps {
  id?: string | undefined,
  text?: string | undefined,
  tooltip?: string | undefined,
  color?: string | undefined,
  mdiIconPath?: string | undefined,
}

export default function IconChip({
  sx,
  id,
  text,
  tooltip,
  color,
  mdiIconPath,
}: IconChipProps) {
  const theme = useTheme();
  const bgColor = useMemo(() => Color(color ?? theme.palette.grey[700]), [color, theme.palette.grey]);
  if (!text === undefined) return null;
  return (
    <Chip
      id={id ?? 'IconChip'}
      label={mdiIconPath ? (
        <>
          <Icon path={mdiIconPath} size={0.55} />
          {' '}
          {text}
        </>
      ) : text}
      size="small"
      title={tooltip}
      sx={{
        color: bgColor.hex(),
        WebkitTextFillColor: bgColor.hex(),
        backgroundColor: bgColor.alpha(0.15).hexa(),
        cursor: 'unset',
        '& .MuiChip-label': {
          display: 'flex',
          gap: 0.5,
          pt: '1px',
          pl: mdiIconPath ? 0.5 : undefined,
          alignItems: 'center',
        },
        ...sx,
      }}
    />
  );
}
