import { useQueryClient } from '@tanstack/react-query';
import UserGroupDto from 'users-groups/types/UserGroupDto';
import useUserGroupsOdataQueryKey from 'users-groups/hooks/useUserGroupsOdataQueryKey';
import { QueryOptions } from 'odata-query';
import { useCallback } from 'react';

export default function useUserGroupsOdataQueryData() {
  const queryClient = useQueryClient();
  const { getQueryKey } = useUserGroupsOdataQueryKey();
  return useCallback(async (odataQuery: Partial<QueryOptions<UserGroupDto>>) => {
    const queryKey = getQueryKey(odataQuery);
    return await queryClient.fetchQuery<UserGroupDto[]>({ queryKey });
  }, [getQueryKey, queryClient]);
}
