import {
  Color,
  InstancedMesh,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  SphereBufferGeometry,
  Vector3,
} from "three";
import { LiteEvent } from "../../../utils/LiteEvent";
import { BubbleInfo, BubbleState } from "../../../viso-types";
import { IfcContext } from "../../context";
import { IfcManager } from "../ifc-manager";
import { BubbleTooltip } from "./bubbleTooltip";

const mat = new MeshBasicMaterial();
const geometry = new SphereBufferGeometry(0.1);
const bubbleColor = new Color("#E56700");

const DEFAULT_CENTER_DISTANCE = 25;
//@ts-ignore
const ROOM_DISTANCE_THRESHOLD_MIN = 2;
//@ts-ignore
const ROOM_DISTANCE_THRESHOLD_MAX = 20;
//@ts-ignore
const ROOM_CENTER_OF_ROTATION_FACTOR = 0.5;

export class Bubble {
  public position?: Vector3;
  public readonly onBubbleClick = new LiteEvent<any>();

  private vec: Vector3 = new Vector3();
  private mesh?: InstancedMesh;
  private tooltip?: BubbleTooltip;
  private bubbleInfos: BubbleInfo[] = [];
  private bubbleState: BubbleState = BubbleState.BUBBLE_NONE;
  private readonly matrix = new Matrix4();
  private highlightModel: Mesh | null = null;

  constructor(private context: IfcContext, private ifc: IfcManager) {}

  setBubbleState(state: BubbleState) {
    this.bubbleState = state;
    const domElement = this.context.getDomElement();
    if (this.bubbleState == BubbleState.BUBBLE_CREATING) {
      domElement.addEventListener("click", this.onClick);
      domElement.addEventListener("mousemove", this.onMouseMove);
    } else {
      domElement.removeEventListener("click", this.onClick);
      domElement.removeEventListener("mousemove", this.onMouseMove);

      if (this.bubbleState == BubbleState.BUBBLE_NONE) {
        this.deleteBubble();
      }
    }
  }

  showBubble(bubbleInfos: BubbleInfo[]) {
    this.removeBubble();
    this.bubbleInfos = bubbleInfos;

    this.createBubble();
  }

  removeBubble() {
    this.dispose();
    this.position = undefined;
    this.bubbleInfos = [];

    if (this.tooltip) {
      this.context.getScene().remove(this.tooltip);
      this.tooltip = undefined;
    }
  }

  pickBubble() {
    if (this.mesh) {
      const intersection = this.context.castRay([this.mesh]);
      if (intersection.length) {
        const instancedId = intersection[0].instanceId;
        if (instancedId != undefined) {
          if (!!this.bubbleInfos[instancedId]) {
            const { title, position } = this.bubbleInfos[instancedId];
            if (this.tooltip && !this.tooltip.visible) {
              this.tooltip.setContent(title, instancedId);
              this.tooltip.setPosition(position);
              this.tooltip.connect(true);

              this.showModel(
                this.ifc.visoLoader.ifcManager.subsets.highLightComponents(
                  this.bubbleInfos[instancedId].issue.linkedComponentsGlobalIds
                )
              );
            }
          }
        }
      } else {
        if (this.tooltip && this.tooltip.visible) {
          this.hideModel();
          this.onBubbleClick.trigger(undefined);
        }
      }
    }

    return null;
  }

  dispose() {
    if (this.mesh) {
      this.mesh.geometry.dispose();
      this.context.getScene().remove(this.mesh);
      this.mesh = undefined;
    }
  }

  restoreBubble() {
    this.dispose();
    this.createBubble();
  }

  openIssue(instanceId: number) {
    if (this.bubbleInfos[instanceId]) {
      this.onBubbleClick.trigger(this.bubbleInfos[instanceId].issue);
    }
  }

  private onClick = (e: MouseEvent) => {
    if (this.bubbleState != BubbleState.BUBBLE_CREATING) return;
    if (e.button == 0) {
      this.setPosition(e.offsetX, e.offsetY);
    }
  };

  private onMouseMove = (e: MouseEvent) => {
    if (this.bubbleState != BubbleState.BUBBLE_CREATING) return;
    this.setPosition(e.offsetX, e.offsetY);
  };

  private setPosition(x: number, y: number) {
    const item = this.context.castRayIfc();
    const camera = this.context.getCamera();
    if (!this.position) this.position = new Vector3();

    if (item) {
      this.position.copy(item.point);
      // const distance = this.position.distanceTo(camera.position);

      // if (ROOM_DISTANCE_THRESHOLD_MIN < distance && distance <= ROOM_DISTANCE_THRESHOLD_MAX) {
      //     this.calculateNormalizeCameraToMousePointFarPlaneVector(x, y);
      //     this.position.copy(camera.position).add(this.vec.multiplyScalar(distance * ROOM_CENTER_OF_ROTATION_FACTOR));
      // }
    } else {
      this.calculateNormalizeCameraToMousePointFarPlaneVector(x, y);
      this.position
        .copy(camera.position)
        .add(this.vec.multiplyScalar(DEFAULT_CENTER_DISTANCE));
    }

    if (!this.mesh) {
      this.mesh = new InstancedMesh(geometry, mat, 1);
      this.context.getScene().add(this.mesh);
    }

    this.matrix.makeScale(1, 1, 1);
    this.matrix.setPosition(this.position);
    this.mesh.setMatrixAt(0, this.matrix);
    this.mesh.setColorAt(0, bubbleColor);
    this.mesh.instanceMatrix.needsUpdate = true;

    if (this.mesh.instanceColor) this.mesh.instanceColor.needsUpdate = true;
  }

  private calculateNormalizeCameraToMousePointFarPlaneVector(
    x: number,
    y: number
  ) {
    const size = this.context.getDimensions();
    this.vec.set((x / size.x) * 2 - 1, -(y / size.y) * 2 + 1, 0.5);

    const camera = this.context.getCamera();
    this.vec.unproject(camera);
    this.vec.sub(camera.position).normalize();
  }

  private createBubble() {
    const length = this.bubbleInfos.length;
    if (length) {
      this.mesh = new InstancedMesh(geometry, mat, length);

      const matrix = new Matrix4();
      for (let i = 0; i < length; i++) {
        matrix.setPosition(this.bubbleInfos[i].position);

        this.mesh.setMatrixAt(i, matrix);
        this.mesh.setColorAt(i, this.bubbleInfos[i].color);
      }

      this.context.getScene().add(this.mesh);

      this.tooltip = new BubbleTooltip(this);
      this.context.getScene().add(this.tooltip);

      this.hideModel();
    }
  }

  private deleteBubble() {
    this.dispose();
    this.position = undefined;
  }

  private showModel(model: Mesh | null) {
    if (this.highlightModel) {
      if (model) {
        this.highlightModel = model;
        this.highlightModel.visible = true;
      } else {
        this.highlightModel.visible = false;
      }
    } else {
      if (model) {
        this.context.getScene().add(model);
        this.highlightModel = model;
        this.highlightModel.visible = true;
      }
    }
  }

  private hideModel() {
    if (this.highlightModel) {
      this.highlightModel.visible = false;
    }

    this.tooltip?.connect(false);
  }
}
