import React from "react";

export default function ChangeStatusFeedback({ description }) {
  return (
    <div className="change-status mobile--hide">
      <div className="tick-circle" />
      <p className="change-status-description">{description}</p>
    </div>
  );
}
