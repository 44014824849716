import React, { ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps, TextField, Theme } from '@mui/material';
import moment, { Moment } from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  LocalizationProvider, deDE, enUS, DatePicker,
} from '@mui/x-date-pickers';
import useLocalizedDatePattern from 'common/hooks/useLocalizedDatePattern';

interface LocalizedDatePickerProps {
  onChange: (value: Moment | null, keyboardInputValue?: string | undefined) => void,
  value: any,
  disabled?: boolean,
  label?: ReactNode,
  sx?: SxProps<Theme>,
  minDate?: any,
  maxDate?: any,
}

export default function LocalizedDatePicker({
  onChange,
  value,
  disabled,
  minDate,
  maxDate,
  label,
  sx,
}: LocalizedDatePickerProps) {
  const { i18n } = useTranslation();
  const datePattern = useLocalizedDatePattern();

  const adapterLocale = useMemo(() => i18n.language.split('-')[0], [i18n.language]);
  const localeText = useMemo(() => (adapterLocale === 'de' ? deDE : enUS).components.MuiLocalizationProvider.defaultProps.localeText, [adapterLocale]);

  // we have to use this workaround for a bug that occurs when using the MUI date picker with moment
  useEffect(() => {
    moment.updateLocale(adapterLocale, { week: { dow: 1 } });
  }, [adapterLocale]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={adapterLocale} localeText={localeText}>
      <DatePicker
        onChange={onChange}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        inputFormat={datePattern}
        label={label}
        disabled={disabled}
        disableMaskedInput
        // justification for eslint-disable:
        // it's how the rendering callback is meant to be used from that 3rd party library (see MUI docs)
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} sx={sx} />}
      />
    </LocalizationProvider>
  );
}
