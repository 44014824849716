import React from "react";
import IssueList from "issues/components/IssueList";

export const IssuesTree = ({
  prefix,
  loadedIssues,
  groupedSubFolders,
  groupedFolders,
  columns,
  row_events,
  selectedIssueTree,
  setResized,
  resized,
}) => {
  return (
    <IssueList
      prefix={prefix}
      loadedIssues={loadedIssues}
      issues={groupedFolders}
      subIssues={groupedSubFolders}
      columns={columns}
      row_events={row_events}
      selectedIssueTree={selectedIssueTree}
      resized={resized}
      setResized={setResized}
    />
  );
};
