import React, { useRef, useState } from 'react';
import { Button, Menu, MenuItem, useTheme, Radio } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import SortIcon from '@mui/icons-material/Sort';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import IssuesSortMode from 'issues/types/IssuesSortMode';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';

interface IssuesSortButtonMenuProps extends ISxProps {
}

export default function IssuesSortButtonMenu({
  sx,
}: IssuesSortButtonMenuProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { sortMode, setSortMode } = useIssuesFilterContext();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <>
      <Button
        id="IssuesSortMenuButton"
        onClick={() => setIsMenuOpen(true)}
        sx={{
          ...sx, color: theme.palette.text.primary, minWidth: 'unset', px: 1,
        }}
        ref={buttonRef}
        variant="outlined"
      >
        <SortIcon sx={{ mr: '-2px', color: theme.palette.text.secondary }} />
      </Button>
      <Menu
        anchorEl={buttonRef.current}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <MenuItem onClick={() => setSortMode(IssuesSortMode.EditDateDesc)}>
          <Radio checked={sortMode === IssuesSortMode.EditDateDesc} sx={{ ml: 0 }} />
          {t('issues-sort-button-menu_sort-select-edit-date', 'Edit Date')}
          <ArrowDownwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
        <MenuItem onClick={() => setSortMode(IssuesSortMode.EditDateAsc)}>
          <Radio checked={sortMode === IssuesSortMode.EditDateAsc} sx={{ ml: 0 }} />
          {t('issues-sort-button-menu_sort-select-edit-date', 'Edit Date')}
          <ArrowUpwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
        <MenuItem onClick={() => setSortMode(IssuesSortMode.IssueNumberDesc)}>
          <Radio checked={sortMode === IssuesSortMode.IssueNumberDesc} sx={{ ml: 0 }} />
          {t('issues-sort-button-menu_sort-select-number', 'Number')}
          <ArrowDownwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
        <MenuItem onClick={() => setSortMode(IssuesSortMode.IssueNumberAsc)}>
          <Radio checked={sortMode === IssuesSortMode.IssueNumberAsc} sx={{ ml: 0 }} />
          {t('issues-sort-button-menu_sort-select-number', 'Number')}
          <ArrowUpwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
        <MenuItem onClick={() => setSortMode(IssuesSortMode.TitleDesc)}>
          <Radio checked={sortMode === IssuesSortMode.TitleDesc} sx={{ ml: 0 }} />
          {t('issues-sort-button-menu_sort-select-title', 'Title')}
          <ArrowDownwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
        <MenuItem onClick={() => setSortMode(IssuesSortMode.TitleAsc)}>
          <Radio checked={sortMode === IssuesSortMode.TitleAsc} sx={{ ml: 0 }} />
          {t('issues-sort-button-menu_sort-select-title', 'Title')}
          <ArrowUpwardIcon fontSize="small" sx={{ ml: 1 }} />
        </MenuItem>
      </Menu>
    </>
  );
}
