import axios from 'axios';
import { useCallback } from 'react';

export default function useRequestErrorMessage() {
  // errors can be anything, so we have to use the explicit any type
  return useCallback((error: any) => {
    if (axios.isAxiosError(error)) {
      if (!!error.response?.data?.errors && Array.isArray(error.response.data.errors) && error.response.data.errors.length > 0) {
        return error.response?.data?.errors.map((e: any) => `${e.errorMessage} (${e.propertyName})`).join(' \n');
      }
      return error.response?.data?.message ?? error.message ?? error;
    }
    if (typeof error?.message === 'string') return error.message;
    if (typeof error === 'string') return error;
    return undefined;
  }, []);
}
