import { useMemo } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import DynamicLayoutKey from 'dynamic-layout/types/DynamicLayoutKey';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import DynamicLayout from 'dynamic-layout/types/DynamicLayout';
import UpsertDynamicLayoutDto from 'dynamic-layout/types/UpsertDynamicLayoutDto';
import DynamicLayoutDto from 'dynamic-layout/types/DynamicLayoutDto';
import useDynamicLayoutEntityFactory from 'dynamic-layout/hooks/useDynamicLayoutEntityFactory';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';

export default function useDynamicLayoutUpsertMutation(dynamicLayoutKey: DynamicLayoutKey) {
  const axiosInstance = useAxiosInstance();
  const currentProjectId = useCurrentProjectId();
  const queryClient = useQueryClient();
  const { listsByCurrentProjectQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DynamicLayout);
  const queryKey = useMemo(() => [listsByCurrentProjectQueryKey, dynamicLayoutKey], [dynamicLayoutKey, listsByCurrentProjectQueryKey]);
  const entityFactory = useDynamicLayoutEntityFactory();
  return useMutation<DynamicLayout | undefined, unknown, UpsertDynamicLayoutDto>(
    async (dto: UpsertDynamicLayoutDto) => {
      const { data: updatedDynamicLayoutDto } = await axiosInstance.put<DynamicLayoutDto>(`${ApiEndpoint.DynamicLayout}`, dto);
      if (updatedDynamicLayoutDto) return undefined;
      const updatedEntity = entityFactory(updatedDynamicLayoutDto);
      queryClient.setQueryData(queryKey, updatedEntity); // update cache
      return updatedEntity;
    },
    {
      // optimistic update
      onMutate: (dto: UpsertDynamicLayoutDto) => {
        if (!currentProjectId) return;
        const predictedResultDto: DynamicLayoutDto = {
          ...dto,
          projectId: currentProjectId,
          userId: '',
        };
        const predictedResultEntity = entityFactory(predictedResultDto);
        queryClient.setQueryData(queryKey, predictedResultEntity);
      },
    },
  );
}
