import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../HOC";
import IssueModal from ".";
import { useLinkIssues } from "../../Helpers/Hooks";
import LinkExistingIssues from "./LinkExistingIssues";

const BtnGroup = ({
  t,
  modalCount,
  selectedModal,
  selected,
  filteredIssues,
  isParent,
  onCancelAddingIssues,
  setSelected,
  setSelectedModal,
  onAddIssues,
  handleClick,
  setIsLinkExistingIssue,
  setIsDocumentLinkedIssue,
  isDocumentLinkedIssue,
  closeModal,
}) => {
  const onClickCancel = () => {
    onCancelAddingIssues();
    setSelected([]);
    setSelectedModal([]);
    setIsDocumentLinkedIssue && setIsDocumentLinkedIssue(false);
    closeModal && closeModal();
  };

  const onClickAdd = () => {
    onAddIssues(
      filteredIssues.filter((issue) => {
        if (modalCount === 2) {
          return selectedModal.find((id) => id === issue.id);
        } else {
          return selected.find((id) => id === issue.id);
        }
      }),
      isParent
    );
    if (isDocumentLinkedIssue) {
      handleClick({ target: { name: "btn-cancel" } });
    }
    if (setIsLinkExistingIssue) {
      setIsLinkExistingIssue(false);
    }
    setIsDocumentLinkedIssue && setIsDocumentLinkedIssue(false);
  };

  return (
    <div className="issue-modal-btn-group">
      <input
        type="button"
        className="btn btn--secondary btn-cancel"
        name="btn-cancel"
        value={t("cancel", "Cancel")}
        onClick={onClickCancel}
      />

      <div className="issue-modal-btn-group-inner">
        <input
          type="button"
          className="btn btn--primary"
          name="btn-save-issue"
          value={
            isDocumentLinkedIssue
              ? t("save_and_link ", "Save & Link ")
              : t("add_count_issues", {
                  defaultValue: "Add {{count}} issues",
                  count:
                    modalCount === 2 ? selectedModal.length : selected.length,
                })
          }
          onClick={onClickAdd}
        />
      </div>
    </div>
  );
};

const LinkIssues = ({
  onAddIssues,
  onCancelAddingIssues,
  isParent = false,
  selectedTask = [],
  issueModalProps,
  editIssue,
  currentIssue,
  setCurrentIssue,
  issueModalCount,
  setIsLinkedCreatIssue,
  linkedIssues,
  isDocumentLinkedIssue,
  setIsDocumentLinkedIssue,
  handleClick,
  closeModal,
  setIsLinkExistingIssue,
  allIssues,
  setSelectedPreviewDoc,
}) => {
  const { t } = useTranslation();
  const scrollRef = useRef();
  const [selected, setSelected] = useState([]);
  const [selectedModal, setSelectedModal] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [modalCount, setModalCount] = useState(issueModalCount || 1);
  const {
    filteredIssues,
    loading,
    filterValue,
    searchKey,
    columns,
    columns_loading,
    isFiltering,
    handleFilterChange,
    onSearchChange,
    handleScrollIssuesTable,
    setFilteredIssues,
  } = useLinkIssues({
    isParent,
    selectedTask,
    editIssue,
    issueModalProps,
    showCreateModal,
    selected,
    selectedModal,
    modalCount,
    scrollRef,
    setSelectedModal,
    setSelected,
    setIsDocumentLinkedIssue,
    handleClick,
    closeModal,
    linkedIssues,
  });

  useEffect(() => {
    if (
      currentIssue &&
      currentIssue.label === "new" &&
      !selected.includes(currentIssue.id) &&
      currentIssue.id !== editIssue?.id
    ) {
      if (isDocumentLinkedIssue || isParent) {
        setSelected([currentIssue.id]);
      } else {
        const currentSelectedIssues = currentIssue.childrenIds?.length
          ? [currentIssue.id, ...currentIssue.childrenIds]
          : [currentIssue.id];
        setSelected([...selected, ...currentSelectedIssues]);
      }
      setCurrentIssue(null);
    }
  }, [currentIssue, selected]);

  return (
    <>
      {showCreateModal ? (
        <Modal className="issue-modal--wrapper">
          <IssueModal
            setShowCreateModal={setShowCreateModal}
            setIssueSavedModalVisible={() => setShowCreateModal(false)}
            canEditIssue={true}
            setModalCount={setModalCount}
            modalCount={modalCount}
            showCreateModal={showCreateModal}
            setIsLinkedCreatIssue={setIsLinkedCreatIssue}
            allIssues={allIssues}
            setSelectedPreviewDoc={setSelectedPreviewDoc}
            {...issueModalProps}
          />
        </Modal>
      ) : (
        <LinkExistingIssues
          BtnGroup={() =>
            BtnGroup({
              t,
              modalCount,
              selectedModal,
              selected,
              filteredIssues,
              isParent,
              onCancelAddingIssues,
              setSelected,
              setSelectedModal,
              onAddIssues,
              handleClick,
              setIsLinkExistingIssue,
              setIsDocumentLinkedIssue,
              isDocumentLinkedIssue,
              closeModal,
            })
          }
          loading={loading}
          searchKey={searchKey}
          modalCount={modalCount}
          scrollRef={scrollRef}
          columns={loading ? columns_loading : columns}
          filterValue={filterValue}
          isFiltering={isFiltering}
          issueFilterItems={{
            statuses: issueModalProps.statuses,
            types: issueModalProps.types,
            priorities: issueModalProps.priorities,
            workphases: issueModalProps.workphases,
            users: issueModalProps.users,
            tagOptions: issueModalProps.tagOptions,
          }}
          filteredIssues={filteredIssues}
          setFilteredIssues={setFilteredIssues}
          setShowCreateModal={setShowCreateModal}
          handleFilterChange={handleFilterChange}
          handleScrollIssuesTable={handleScrollIssuesTable}
          onSearchChange={onSearchChange}
          allIssues={allIssues}
        />
      )}
    </>
  );
};

export default LinkIssues;
