import React, {
  useContext, useMemo, useRef, useState,
} from 'react';
import {
  Box, Button, Divider, FormControlLabel, ListSubheader, Menu, MenuItem, Radio, RadioGroup,
} from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import ListOptionsIcon from 'icons/ListOptionsIcon';
import theme from 'theme';
import { useNavigate } from 'react-router-dom';
import DocumentListMode from 'documents-filter/types/DocumentListMode';
import useDocumentFilterContext from 'documents/hooks/useDocumentFilterContext';
import DocumentOptionsContext, { DocumentOptionsContextState } from 'documents/contexts/DocumentOptionsContext';
import DocumentAbbreviationDisplayMode from 'documents/types/DocumentAbbreviationDisplayMode';
import DocumentScopeContext, { DocumentScopeContextState } from 'documents/contexts/DocumentScopeContext';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import { useTranslation } from 'react-i18next';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';

interface DocumentsOptionsButtonMenuProps extends ISxProps {
}

export default function DocumentsOptionsButtonMenu({
  sx,
}: DocumentsOptionsButtonMenuProps) {
  const { t } = useTranslation('documents');
  const currentUserRole = useCurrentUserRole();
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { documentScope } = useContext<DocumentScopeContextState>(DocumentScopeContext);
  const { documentListMode, setDocumentListMode } = useDocumentFilterContext();
  const { abbreviationDisplayMode, setAbbreviationDisplayMode } = useContext<DocumentOptionsContextState>(DocumentOptionsContext);

  const isDisabled = useMemo(() => documentScope.key === DocumentScopeKey.Planlist, [documentScope.key]);
  const effectiveDocumentListMode = useMemo(() => (documentScope.key === DocumentScopeKey.Planlist ? DocumentListMode.AllVersions : documentListMode), [documentListMode, documentScope.key]);
  const isAllowedToEditDocumentStatus = useMemo(() => !!currentUserRole?.allowedActions?.has(RoleAction.MainMenu_Settings) && !!currentUserRole?.allowedActions?.has(RoleAction.DocStatus_Create_Edit), [currentUserRole?.allowedActions]);

  return (
    <Box id="DocumentsOptionsButtonMenu" sx={{ ...sx }}>
      <Button onClick={() => setIsMenuOpen(true)} sx={{ mr: 2, color: theme.palette.text.primary }} ref={buttonRef}>
        <ListOptionsIcon sx={{ mr: '-2px' }} />
        {t('documents-page-header_options-button-caption', 'Options')}
      </Button>
      <Menu
        anchorEl={buttonRef.current}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <ListSubheader>{t('documents-page-header_options-menu_versions-header', 'Versions')}</ListSubheader>
        <RadioGroup value={effectiveDocumentListMode} onChange={(_, value) => setDocumentListMode(value as DocumentListMode)}>
          <MenuItem disabled={isDisabled}>
            <FormControlLabel value={DocumentListMode.CurrentVersionOnly} control={<Radio />} label={t('documents-page-header_options-menu_versions-only-current', 'Only current version')} />
          </MenuItem>
          <MenuItem disabled={isDisabled}>
            <FormControlLabel value={DocumentListMode.AllVersions} control={<Radio />} label={t('documents-page-header_options-menu_versions-all', 'All Versions')} />
          </MenuItem>
        </RadioGroup>
        <Divider sx={{ my: 1 }} />
        <ListSubheader>{t('documents-page-header_options-menu_columns-header', 'Columns')}</ListSubheader>
        <RadioGroup value={abbreviationDisplayMode} onChange={(_, value) => setAbbreviationDisplayMode(value as DocumentAbbreviationDisplayMode)}>
          <MenuItem>
            <FormControlLabel value={DocumentAbbreviationDisplayMode.FullLength} control={<Radio />} label={t('documents-page-header_options-menu_columns-full-length', 'Full length')} />
          </MenuItem>
          <MenuItem>
            <FormControlLabel value={DocumentAbbreviationDisplayMode.Abbreviation} control={<Radio />} label={t('documents-page-header_options-menu_columns-abbreviations', 'Abbreviations')} />
          </MenuItem>
        </RadioGroup>
        {isAllowedToEditDocumentStatus && [
          <Divider sx={{ my: 1 }} key="divider" />,
          <ListSubheader key="list-subheader">{t('documents-page-header_options-menu_document-settings-header', 'Document settings')}</ListSubheader>,
          <MenuItem key="edit-status" onClick={() => navigate('../../settings/documents')}>
            {t('documents-page-header_options-menu_edit-status', 'Edit status')}
          </MenuItem>,
        ]}
      </Menu>
    </Box>
  );
}
