import { VisoNode } from "Components/Models/Types/BaseDefinitions";
import { Intersection, Mesh, Scene } from "three";
import { LiteEvent } from "../../../utils/LiteEvent";
import { VisoplanLoader } from "../../../VisoplanLoader";
import { IfcContext } from "../../context";

export class HoverSelection {
  public readonly onFound = new LiteEvent<VisoNode>();
  mesh: Mesh | null = null;
  private selected: string;
  private selectedGlobalId: string;
  private loader: VisoplanLoader;

  private readonly scene: Scene;

  constructor(private context: IfcContext, loader: VisoplanLoader) {
    this.scene = context.getScene();
    this.loader = loader;
    this.selected = "";
    this.selectedGlobalId = "";
  }

  pick = async (item: Intersection) => {
    if (item.faceIndex == null) return;
    const selected = this.getSelectedItemMark(item);
    if (this.selected == selected) return;
    this.selected = selected;

    const globalId = this.loader.ifcManager.getGlobalId(item);

    if (this.selectedGlobalId == globalId || globalId == null) return;
    this.selectedGlobalId = globalId;

    // @ts-ignore
    const modelId = item.object.modelId;
    const mesh = this.loader.ifcManager.hoverComponent(modelId, globalId);

    if (mesh) {
      if (this.mesh == null) {
        this.scene.add(mesh);
        this.mesh = mesh;
        this.context.items.hoverMesh = this.mesh;
      }

      this.mesh.visible = true;
      const components = this.loader.ifcManager.getComponentInfos([globalId]);
      this.onFound.trigger(components[0]);
    } else {
      if (this.mesh) {
        this.mesh.visible = false;
        this.onFound.trigger(null);
      }
    }
  };

  hideSelection() {
    this.selected = "";
    this.selectedGlobalId = "";
    if (this.mesh != null) {
      if (this.mesh.visible === false) return;
      this.mesh.visible = false;

      this.onFound.trigger(null);
    }
  }

  private getSelectedItemMark(item: Intersection) {
    return `${item.object.uuid}-${item.faceIndex}-${item.instanceId ? item.instanceId : "NonInstnace"
      }`;
  }
}
