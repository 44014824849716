import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import DocumentScopeContext, { DocumentScopeContextState } from 'documents/contexts/DocumentScopeContext';
import ArchiveScopeId from 'documents/types/ArchiveScopeId';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import React, { useContext } from 'react';

interface ArchivePrivatePublicSwitchProps extends ISxProps {
}

export default function ArchivePrivatePublicSwitch({
  sx,
}: ArchivePrivatePublicSwitchProps) {
  const { documentScope, setDocumentScope } = useContext<DocumentScopeContextState>(DocumentScopeContext);
  return (
    <ToggleButtonGroup
      value={documentScope.id}
      exclusive
      onChange={(_, value) => setDocumentScope({ key: DocumentScopeKey.Archive, id: value })}
      sx={{ ...sx }}
    >
      <ToggleButton value={ArchiveScopeId.Private}>Private</ToggleButton>
      <ToggleButton value={ArchiveScopeId.Public}>Public</ToggleButton>
    </ToggleButtonGroup>
  );
}
