import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const CopyLink = ({ id, issueId, folderId, documentId, }) => {
  const { t } = useTranslation();
  const [isCopied, setCopied] = useState(false);
  const { projectId } = useParams();

  const handleCopyLink = useCallback(() => {
    let href = window.location.href;
    if (!href.includes("issues")) {
      const index = href.indexOf("projects");
      const hrefByProjects = href.slice(0, index + 8);
      if (issueId) href = `${hrefByProjects}/${projectId}/issues/${issueId}`;
      else if (folderId && documentId)
        href = `${hrefByProjects}/${projectId}/documents/folder/${folderId}/preview/${documentId}`;
    }
    navigator.clipboard.writeText(href);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 4000);
  }, []);

  return (
    <div className="issue-copy-link" id={id}>
      <svg className="icon">
        <use xlinkHref="/img/sprite.svg#link" />
      </svg>
      <span className="issue-copy-link_label" onClick={handleCopyLink}>
        {t("copy_link", "Copy Link")}
      </span>
      <span
        className={`issue-copy-link_tooltip${
          isCopied ? " tooltip-visible" : ""
        }`}
      >
        {t("link_copied", "Link copied to clipboard")}
      </span>
    </div>
  );
};

export default CopyLink;
