import { useMemo } from 'react';
import { PDFAnnotation } from '../../Entities/RestEntities/PDFAnnotation';

export interface UseAnnotationCheckProps {
  pdfAnnotations: PDFAnnotation[];
  pdfAnnotationIds: string[] | undefined;
}

export default function useAnnotationCheck({
  pdfAnnotations,
  pdfAnnotationIds,
}: UseAnnotationCheckProps) {
  const isVisibleAnnotation = useMemo<boolean>(() => {
    if (!pdfAnnotations.length || !pdfAnnotationIds) return false;
    const annotationIds = pdfAnnotations.map((item) => item.id);
    const filteredIds = pdfAnnotationIds.filter((id: string) => annotationIds.includes(id));
    return !!filteredIds.length;
  }, [pdfAnnotations, pdfAnnotationIds]);

  return {
    isVisibleAnnotation,
  };
}
