import React, {
  Suspense, useCallback, useEffect, useState,
} from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import TagCreateSelect from 'tags/components/TagCreateSelect';
import useDocumentVersionTagsUpdateMutation from 'documents/hooks/useDocumentVersionTagsUpdateMutation';
import DocumentVersionTagsUpdateDto from 'documents/types/DocumentVersionTagsUpdateDto';
import { CircularProgress } from '@mui/material';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';
import useCurrentUserFolderAccessLevel from 'documents-folders/hooks/useCurrentUserFolderAccessLevel';

export default function TagsCell({
  data,
  value,
}: ICellRendererParams<DocumentsDataGridRow, string[] | undefined>) {
  const { mutateAsync, isLoading } = useDocumentVersionTagsUpdateMutation();
  const currentUserRole = useCurrentUserRole();
  const [displayValue, setDisplayValue] = useState<string[] | undefined>(value ?? undefined);
  const accessLevel = useCurrentUserFolderAccessLevel(data?.folderId);

  const onChange = useCallback(async (nextSelectedTagIds: string[]) => {
    if (!data) return;
    setDisplayValue(nextSelectedTagIds);
    const persistDto: DocumentVersionTagsUpdateDto = {
      id: data.id,
      tagIds: nextSelectedTagIds,
    };
    await mutateAsync(persistDto);
  }, [data, mutateAsync]);

  useEffect(() => setDisplayValue(value ?? []), [value]);

  return (
    <Suspense fallback={<CircularProgress size={12} />}>
      <TagCreateSelect
        id="TagsCellCreateSelect"
        value={displayValue}
        onChange={onChange}
        disabled={isLoading || data?.isArchived || !currentUserRole?.allowedActions?.has(RoleAction.Document_Editing) || accessLevel !== CollaboratorFolderAccessLevel.ReadWrite}
        sx={{ width: '100%' }}
      />
    </Suspense>
  );
}
