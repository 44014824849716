import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  VIEWER_MARGIN,
  VIEWER_OFFSET_X,
  VIEWER_OFFSET_Y,
} from "../../../Helpers/Common";
import { getGeneralSvg } from "../../SVGs";

const ComponentItem = ({ component, removeComponent }) => {
  const handleRemoveComponent = () => {
    removeComponent(component);
  };

  return (
    <div className="component-list_component-item">
      <p>{component.elementName}</p>
      <div style={{ cursor: "pointer" }} onClick={handleRemoveComponent}>
        {getGeneralSvg("clipping-close")}
      </div>
    </div>
  );
};

const AddingComponentPanel = ({
  containerRect,
  components,
  removeComponent,
  addComponents,
  goBack,
}) => {
  const { t } = useTranslation();
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [isMoving, setIsMoving] = useState(false);
  const movingRef = useRef(isMoving);
  const rectRef = useRef(containerRect);

  useEffect(() => {
    document.addEventListener("mousemove", onDocMouseMove);
    document.addEventListener("mouseup", onDocMouseUp);

    return () => {
      document.removeEventListener("mousemove", onDocMouseMove);
      document.removeEventListener("mouseup", onDocMouseUp);
    };

    function onDocMouseMove(e) {
      if (movingRef.current) {
        calculatePos(e.clientX - VIEWER_OFFSET_X, e.clientY - VIEWER_OFFSET_Y);
      }
    }

    function onDocMouseUp(e) {
      if (movingRef.current && e.button === 0) {
        setIsMoving(false);
      }
    }
  }, []);

  useEffect(() => {
    movingRef.current = isMoving;
  });

  useEffect(() => {
    document.documentElement.style.cursor = isMoving ? "move" : "default";
  }, [isMoving]);

  useEffect(() => {
    rectRef.current = containerRect;
    calculatePos(pos.x, pos.y);
  }, [containerRect]);

  const calculatePos = (posX, posY) => {
    if (rectRef.current) {
      const panelHtml = document.getElementById("adding_component-panel");
      const x = Math.min(
        Math.max(posX, rectRef.current.left - VIEWER_OFFSET_X + VIEWER_MARGIN),
        rectRef.current.right -
          panelHtml.clientWidth -
          VIEWER_OFFSET_X -
          VIEWER_MARGIN
      );

      const y = Math.min(
        Math.max(posY, rectRef.current.top - VIEWER_OFFSET_Y + VIEWER_MARGIN),
        rectRef.current.bottom -
          VIEWER_OFFSET_Y -
          panelHtml.clientHeight -
          VIEWER_MARGIN
      );

      setPos({ x, y });
    }
  };

  const onMouseDown = (e) => {
    if (e.button === 0) {
      setIsMoving(true);
    }
  };

  const onMouseUp = (e) => {
    if (isMoving && e.button === 0) {
      setIsMoving(false);
    }
  };

  return (
    <div
      id="adding_component-panel"
      className="adding_component-panel"
      style={{ left: `${pos.x}px`, top: `${pos.y}px` }}
      onMouseUp={onMouseUp}
    >
      <div className="adding_component-panel_header">
        <div className="moving" onMouseDown={onMouseDown}>
          {getGeneralSvg("moving-label")}
        </div>
        <label>{t("add_component", "Add Component")}</label>
      </div>
      <div className="adding_component-panel_content">
        <div className="component-list custom-scrollbar">
          {components.map((component, index) => (
            <ComponentItem
              key={`component-item=${index}`}
              component={component}
              removeComponent={removeComponent}
            />
          ))}
        </div>
        <div className="button-area">
          <button className="btn btn--secondary" onClick={goBack}>
            {t("back", "Back")}
          </button>
          <button className="btn btn--primary" onClick={addComponents}>
            {t("add_components", "Add Components")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddingComponentPanel;
