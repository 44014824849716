import { useCallback } from 'react';
import FolderDto from 'documents-folders/types/FolderDto';
import Folder from 'documents-folders/types/Folder';

interface FolderStackItem {
  dto: FolderDto,
  parentFolderEntity: Folder | undefined,
}

export default function useFolderEntityFactory() {
  return useCallback((dto: FolderDto) => {
    const foldersById = new Map<string, Folder>();
    const rootFolderDto = dto;
    const folderStack: FolderStackItem[] = [{ dto, parentFolderEntity: undefined }];
    while (folderStack.length > 0) {
      const pivotItem = folderStack.pop();
      if (pivotItem) {
        const folder: Folder = {
          ...pivotItem.dto,
          path: [...(pivotItem.parentFolderEntity?.path ?? []), pivotItem.dto.id],
          parent: pivotItem.parentFolderEntity,
          childFolders: [],
          lastDocumentUpdateDateParsed: pivotItem.dto.lastDocumentUpdateDate ? new Date(pivotItem.dto.lastDocumentUpdateDate) : undefined,
          editDateParsed: new Date(pivotItem.dto.editDate),
          creationDateParsed: new Date(pivotItem.dto.creationDate),
          rootFolderAccessType: rootFolderDto.accessType,
        };
        pivotItem.parentFolderEntity?.childFolders?.push(folder);
        foldersById.set(folder.id, folder);
        folderStack.push(...pivotItem.dto.childFolders?.map((childDto) => ({ dto: childDto, parentFolderEntity: folder })) ?? []);
      }
    }
    const folderEntity = foldersById.get(dto.id);
    if (!folderEntity) throw new Error('Error parsing FolderDto');
    return folderEntity;
  }, []);
}
