import { useMemo } from 'react';
import useDefaultEntityQueryKeys, { VisoOdataQueryKey } from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import UserGroupDto from 'users-groups/types/UserGroupDto';

export type UserGroupsOdataQueryKey = VisoOdataQueryKey<ApiEndpoint.UserGroup, UserGroupDto>;

export default function useUserGroupsOdataQueryKey() {
  const {
    odataQueryQueryKey: queryKeyBase,
    getOdataQueryQueryKey: getQueryKey,
  } = useDefaultEntityQueryKeys(ApiEndpoint.UserGroup);
  return useMemo(() => ({
    queryKeyBase,
    getQueryKey: getQueryKey<UserGroupDto>,
  }), [getQueryKey, queryKeyBase]);
}
