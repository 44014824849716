import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, DialogActions, DialogContent, Table, TableBody, TableHead, DialogTitle, TableCell, TableRow, styled } from '@mui/material';
import useFolderTreeQuery from 'documents-folders/hooks/useFolderTreeQuery';
import FolderAccessType from 'documents-folders/types/FolderAccessType';
import AddToPlanlistDialog from 'documents-lists/components/AddToPlanlistDialog';
import UploadingFileHandleProgressBar from 'upload/components/DocumentUploadWizard/UploadingFileHandleProgressBar';
import DocumentUploadContext, { DocumentUploadContextState } from 'upload/contexts/DocumentUploadContext';

const EllipsisCell = styled(TableCell)(() => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: 0,
}));

interface UploadProgressPageProps {
  goToInformUsersPage: () => void,
  onFinished: () => void,
  onMinimize: () => void,
}

export default function UploadProgressPage({
  goToInformUsersPage,
  onFinished,
  onMinimize,
}: UploadProgressPageProps) {
  const { t } = useTranslation('upload');
  const { uploadingFileHandles } = useContext<DocumentUploadContextState>(DocumentUploadContext);
  const [isAddToPlanListModalOpen, setIsAddToPlanListModalOpen] = useState(false);
  const areAllUploadsSettled = useMemo(() => uploadingFileHandles.every((handle) => handle.isCompleted || handle.isFailed), [uploadingFileHandles]);
  const { data: folderTree } = useFolderTreeQuery();
  const isUploadingInPublicOrRestrictedFolder = useMemo(() => {
    if (!folderTree) return false;
    return uploadingFileHandles.every((handle) => {
      const folder = folderTree?.foldersById.get(handle.targetFolderId);
      return folder?.accessType === FolderAccessType.Public || folder?.accessType === FolderAccessType.Restricted;
    });
  }, [folderTree, uploadingFileHandles]);
  const documentVersionIds = useMemo(() => uploadingFileHandles.filter((handle) => !!handle.documentVersionId).map((handle) => handle.documentVersionId!), [uploadingFileHandles]);
  const onCloseCreatePlanlistDialog = useCallback(() => setIsAddToPlanListModalOpen(false), []);
  return (
    <>
      <DialogTitle id="UploadProgressPage" sx={{ display: 'flex' }}>
        <Box sx={{ flexGrow: 1 }}>{t('upload-wizard_uploadprogress-title', 'Upload {{count}} documents', { count: uploadingFileHandles.length })}</Box>
        <Button variant="outlined" onClick={onMinimize}>{t('upload-wizard_uploadprogress-minimize', 'Minimize')}</Button>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('upload-wizard_uploadprogress-name-column-header', 'Name')}</TableCell>
              <TableCell sx={{ width: '200px' }}>{t('upload-wizard_uploadprogress-status-column-header', 'Status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uploadingFileHandles.map((uploadingFileHandle) => (
              <TableRow key={uploadingFileHandle.fileName}>
                <EllipsisCell component="th" scope="row" sx={{ pl: 1 }} title={uploadingFileHandle.fileName}>{uploadingFileHandle.fileName}</EllipsisCell>
                <TableCell sx={{ pr: 1 }}>
                  <UploadingFileHandleProgressBar uploadingFileHandle={uploadingFileHandle} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disabled={!areAllUploadsSettled}
          onClick={() => setIsAddToPlanListModalOpen(true)}
        >
          {t('upload-wizard_create-planlist', 'Create Planlist')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!areAllUploadsSettled || !isUploadingInPublicOrRestrictedFolder}
          onClick={goToInformUsersPage}
          sx={{ ml: 1 }}
        >
          {t('upload-wizard_inform-users', 'Inform Users')}
        </Button>
        <Button
          variant="contained"
          disabled={!areAllUploadsSettled}
          onClick={onFinished}
          sx={{ ml: 'auto' }}
        >
          {t('upload-wizard_finish-upload', 'Finish Upload')}
        </Button>
      </DialogActions>
      {!!isAddToPlanListModalOpen && <AddToPlanlistDialog documentVersionIds={documentVersionIds} onClose={onCloseCreatePlanlistDialog} />}
    </>
  );
}
