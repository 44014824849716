import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const FilterOptions = ({
  id,
  showSaveFilter,
  handeleDeleteFilter,
  currentFilter,
  filters,
  handleSaveFilter,
}) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div
      id={id}
      className="filter-option mobile--hide"
      onClick={() => setShowDropdown(!showDropdown)}
    >
      <p 
        className="filter-option-over" style={{ textAlign: 'end' }}>
        <svg className="icon mr-1">
          <use xlinkHref="/img/sprite.svg#list-options" />
        </svg>
        {t("filter_options", "Filter Options")}
      </p>
      {showDropdown ? (
        <div className="filter-option-dropdown">
          {filters.length ? (
            <div className="menu">
              <h5 
                id="SaveChangesToFilterDocumentFilter" 
                className="menu-label" 
                onClick={() => handleSaveFilter(true)}>
                {t("save_changes_to_filter", "Save changes to filter")}
              </h5>
            </div>
          ) : (
            ""
          )}
          <div className="menu">
            <h5 
              id="SaveAsNewFilterDocumentFilter" 
              className="menu-label" 
              onClick={() => showSaveFilter(true)}>
              {t("save_as_new_filter", "Save as new filter")}
            </h5>
          </div>
          {filters.length ? (
            <div className="menu">
              <h5
                id="DeleteFilterDocumentFilter"
                className="menu-label"
                onClick={() => handeleDeleteFilter(currentFilter.value)}
              >
                {t("delete_filter", "Delete Filter")}
              </h5>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FilterOptions;
