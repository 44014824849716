import React from "react";

const ProgressBar = ({ state, isVisiblePercent = true }) => (
  <div className="upload__progress progress">
    <div className="progress__bar">
      <span
        style={{
          width: state + "%",
        }}
      />
    </div>
    {isVisiblePercent && <span className="progress__percent">{state}%</span>}
  </div>
);

export default ProgressBar;
