import React, {
  ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Alert, Box, Checkbox, FormControlLabel, TextField, Typography,
} from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import FolderFormValues from 'documents-folders/types/FolderFormValues';
import { useTranslation } from 'react-i18next';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useFolder from 'documents-folders/hooks/useFolder';
import FolderAccessType from 'documents-folders/types/FolderAccessType';
import FolderEditPermissionsPanel from 'documents-folders/components/FolderEditPermissionsPanel';
import { FolderPermissionValues } from 'documents-folders/types/FolderPermissionValues';
import useSiblingFolderNames from 'documents-folders/hooks/useSiblingFolderNames';
import FolderAccessTypeToggleButtonGroup from 'documents-folders/components/FolderAccessTypeToggleButtonGroup';

interface FolderSettingsPanelProps extends ISxProps {
  folderId: string | undefined,
  parentFolderId: string | undefined,
  folderFormValues: FolderFormValues | undefined;
  onChange: (values: FolderFormValues) => void;
  disabled?: boolean,
}

export default function FolderSettingsPanel({
  sx,
  folderId,
  parentFolderId,
  folderFormValues,
  onChange,
  disabled,
}: FolderSettingsPanelProps) {
  const { t } = useTranslation('documents-folders');
  const folder = useFolder(folderId);
  const parentFolder = useFolder(parentFolderId);
  const existingSiblingFolderNames = useSiblingFolderNames(parentFolderId);
  const [nameDisplayValue, setNameDisplayValue] = useState<string | undefined>(folderFormValues?.name);
  const hasNamingConflict = useMemo(() => {
    if (!existingSiblingFolderNames || !nameDisplayValue) return undefined;
    if (nameDisplayValue.length === 0) return false;
    if (folderId) {
      if (!folder) return undefined;
      if (nameDisplayValue === folder.name) return false; // editing a folder => accept unchanged name
    }
    return existingSiblingFolderNames.has(nameDisplayValue);
  }, [existingSiblingFolderNames, folder, folderId, nameDisplayValue]);
  useEffect(() => {
    if (folderFormValues) {
      setNameDisplayValue(folderFormValues.name);
    }
  }, [folderFormValues]);
  const onChangeName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!folderFormValues) return;
    setNameDisplayValue(event.target.value);
    onChange({ ...folderFormValues, name: event.target.value });
  }, [onChange, folderFormValues]);

  const [accessTypeDisplayValue, setAccessTypeDisplayValue] = useState<FolderAccessType | null>(null);
  useEffect(() => {
    if (folderFormValues) {
      setAccessTypeDisplayValue(folderFormValues.accessType);
    }
  }, [folderFormValues]);
  const onChangeAccessType = useCallback((event: MouseEvent<HTMLElement>, value: FolderAccessType) => {
    if (!folderFormValues) return;
    if (value === null) return; // suppress de-selection
    setAccessTypeDisplayValue(value);
    onChange({ ...folderFormValues, accessType: value });
  }, [onChange, folderFormValues]);

  const [inheritAccessDisplayValue, setInheritAccessDisplayValue] = useState<boolean | undefined>(folderFormValues?.inheritAccess);
  useEffect(() => {
    if (!folderFormValues) return;
    setInheritAccessDisplayValue(folderFormValues.inheritAccess);
  }, [folderFormValues]);
  const onChangeInheritAccess = useCallback((event: ChangeEvent<HTMLElement>, value: boolean) => {
    if (!folderFormValues) return;
    setInheritAccessDisplayValue(value);
    onChange({ ...folderFormValues, inheritAccess: value });
  }, [onChange, folderFormValues]);

  const [permissionsDisplayValue, setPermissionsDisplayValue] = useState<FolderPermissionValues | undefined>(folderFormValues?.permissions);
  useEffect(() => {
    if (!folderFormValues) return;
    setPermissionsDisplayValue(folderFormValues.permissions);
  }, [folderFormValues]);
  const onChangePermissions = useCallback((value: FolderPermissionValues) => {
    if (!folderFormValues) return;
    setPermissionsDisplayValue(value);
    onChange({ ...folderFormValues, permissions: value });
  }, [onChange, folderFormValues]);

  const isInheritAccessTypeAvailable = useMemo(() => !!parentFolder, [parentFolder]);

  if (!folderFormValues) return <CenteredCircularProgress />;
  return (
    <Box
      id="FolderSettingsPanel"
      sx={{
        ...sx, display: 'flex', flexDirection: 'column', rowGap: '8px',
      }}
    >
      <TextField
        sx={{ mt: 1 }}
        id="folder-settings-panel_name-textfield"
        label={t('folder-settings-panel_name-textfield-label', 'Folder Name')}
        value={nameDisplayValue ?? ''}
        onChange={onChangeName}
        error={hasNamingConflict}
        helperText={hasNamingConflict ? t('folder-settings-panel_naming-conflict-error-message', 'There already is a folder with this name on this level.') : undefined}
        disabled={disabled}
        autoFocus
      />
      <Typography variant="h4" sx={{ mt: 4 }}>{t('folder-permissions-panel_title', 'Access rights')}</Typography>
      <FormControlLabel
        control={(
          <Checkbox
            checked={inheritAccessDisplayValue ?? true}
            onChange={onChangeInheritAccess}
            disabled={!isInheritAccessTypeAvailable || disabled}
          />
        )}
        label={t('folder-settings-panel_inherit-checkbox-label', 'Inherit access rights from parent folder')}
      />
      <FolderAccessTypeToggleButtonGroup
        folderId={folderId}
        parentFolderId={parentFolderId}
        value={accessTypeDisplayValue}
        onChange={onChangeAccessType}
        disabled={disabled || inheritAccessDisplayValue}
      />
      <Box sx={{ mt: 2, height: '350px' }}>
        {accessTypeDisplayValue !== FolderAccessType.Private ? (
          <>
            <Typography variant="h5">{t('folder-settings-panel_permissions-section-title', 'Individual/group permission configuration')}</Typography>
            <Box sx={{ height: '320px' }}>
              {!!folderFormValues && permissionsDisplayValue && accessTypeDisplayValue !== null ? (
                <FolderEditPermissionsPanel
                  accessType={accessTypeDisplayValue}
                  permissions={permissionsDisplayValue}
                  onChange={onChangePermissions}
                  disabled={inheritAccessDisplayValue || disabled}
                />
              ) : <CenteredCircularProgress />}
            </Box>
          </>
        ) : (
          <Alert severity="info" sx={{ mt: 1 }}>{t('folder-settings-panel_private-folder-config-impossible-info-message', 'Private folders are always accessible by the creator only (read and write). Access rights can not be configured for private folders.')}</Alert>
        )}
      </Box>
    </Box>
  );
}
