import * as Request from "../Helpers/Request";

export const getViewpointsByIds = async (viewpointIds) => {
  if (!viewpointIds?.length) return [];
  return await Request.getChunkedEntitiesByIds(
    "api/viewpoint",
    viewpointIds
  );
};

export const getViewpointLines = async (projectId, filter) => {
  const result = await Request.GET(
    `api/viewpoint/lines${
      filter ? `?$filter=${filter}` : ""
    }`
  );
  return result.status === 200 ? result.body : [];
};

export const createViewpoints = async (params, setApiError = null) => {
  const result = await Request.POST("api/viewpoint", params);
  if (result.status === 200) return result.body;
  else {
    if (setApiError) setApiError(result.body?.message);
    return null;
  }
};

export const deleteViewpoints = async (ids) => {
  const result = await Request.DELETE("api/viewpoint", ids);
  return result.status === 200;
};
