import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ProjectUpdateRolesDto from 'projects/types/ProjectUpdateRolesDto';
import ProjectDto from 'projects/types/ProjectDto';

export default function useProjectUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { baseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.ProjectRoles);
  const mutationFn = useCallback(async (dto: ProjectUpdateRolesDto) => {
    await axiosInstance.patch<ProjectDto>(`${ApiEndpoint.ProjectRoles}`, dto);
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries(baseQueryKey);
  }, [baseQueryKey, queryClient]);
  return useMutation<void, unknown, ProjectUpdateRolesDto>({
    mutationFn,
    onSuccess,
  });
}
