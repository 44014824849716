import React, { useMemo } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import chroma from 'chroma-js';

const { MenuList } = components;

const CustomMenuList = ({ selectProps, isMassEdit, ...props }) => {
  const { t } = useTranslation();
  const value = { value: 'reset', label: 'Reset Value', id: 'reset' };
  return (
    <>
      {isMassEdit ? (
        <label
          className='reset-value'
          onClick={() => selectProps.onChange(value)}
        >
          {t('reset_value', 'Reset Value')}
        </label>
      ) : (
        ''
      )}
      <MenuList selectProps={selectProps} isClearable={false} {...props} />
    </>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div>
        <label>{props.label}</label>
      </div>
    </components.Option>
  );
};

const tagStyles = {
  menu: ({ styles }) => {
    return {
      ...styles,
      position: 'absolute',
      width: '100%',
    };
  },
  menuList: ({ styles }) => {
    return {
      ...styles,
      minWidth: '110px',
      overflow: 'auto',
    };
  },
  option: (styles, { data }) => {
    const color = data.color ? chroma(data.color) : '';
    return {
      ...styles,
      ...{
        display: 'flex',
        padding: '0',
        justifyContent: 'center',
        alignItems: 'center',
        label: {
          color: data.color,
          fontSize: '14px !important',
          lineHeight: '20px',
          fontWeight: 'normal',
          padding: '0',
          margin: '0',
        },
        '& > div': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: data.color
            ? `${color.alpha(0.2).css()} !important`
            : '',
          margin: '10px 5px',
          borderRadius: '3px',
        },
      },
    };
  },
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      color: `${data.color} !important`,
      backgroundColor: data.color
        ? `${chroma(data.color).alpha(0.2).css()} !important`
        : '',
      fontSize: '14px !important',
      padding: `3px 10px !important`,
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      ...{
        height: '32px',
        display: 'flex',
        flexWrap: 'no-wrap',
        '.num-wrapper': {
          borderRadius: '6px',
          padding: '0 3px',
          lineHeight: '20px',
        },
      },
    };
  },
};

const ColorItemSelect = ({
  t,
  values,
  onChange,
  value,
  name,
  isBackgroundVisible = true,
  menuPlacement = 'auto',
  isGrey = false,
  isDisabled = false,
  isModel = false,
  isMassEdit = false,
  isMulti = false
}) => {
  const className = useMemo(() => {
    let name = 'color-item-select select-width-full select--light';
    if (!isBackgroundVisible) name += ' select--no-background';
    if (isGrey) name += ' select--grey';
    if (isModel) name += ' select--model';
    name += ' select--multi';
    return name;
  }, [isBackgroundVisible, isGrey, isModel]);

  return (
    <div className={className}>
      <Select
        className='select__input'
        classNamePrefix='select'
        options={values}
        components={{
          MenuList: (selectProps) => (
            <CustomMenuList isMassEdit={isMassEdit} {...selectProps} />
          ),
          Option,
        }}
        placeholder={<div>{t('select_ellipsis', 'select...')}</div>}
        styles={tagStyles}
        value={value}
        isMulti={isMulti}
        name={name}
        hideSelectedOptions={true}
        isSearchable={false}
        closeMenuOnSelect={true}
        menuPlacement={menuPlacement}
        onChange={onChange}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default withTranslation()(ColorItemSelect);
