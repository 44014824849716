import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import CustomSelect from "../../CustomSelect";
import { FilterOptions } from "../../FilterOptions";
import TagSelect from "../../TagSelect";
import LocalizedDatePicker from 'common/components/LocalizedDatePicker';
import { formatDate } from "../../../Helpers/format-date";
import { Box } from "@mui/material";

export default function AsideEmailFilter({
  users,
  emailFilter,
  initialEmailFilterValue,
  tagOptions,
  show_aside,
  setAsideVisibleStatus,
  setEmailFilter,
  setShowFilterModal,
  filters,
  setCurrentFilter,
  currentFilter,
  handeleDeleteFilter,
  handleSaveFilter,
  // issues
}) {
  const { t } = useTranslation();
  const createdByValues = useMemo(
    () => [...users, { value: "###", label: t("extern", "Extern") }],
    [users]
  );

  const handleSelectChange = useCallback((value, name) => {
    setEmailFilter((filterValue) => ({
      ...filterValue,
      [name]: value,
    }));
  }, []);
  const resetFilters = useCallback(() => {
    setEmailFilter(initialEmailFilterValue);
  }, []);

  return (
    <aside
      id="AsideEmailFilter"
      className={
        "main__aside aside aside-email-filter" + (show_aside ? " is-open" : "")
      }
    >
      <div className="aside-email-filter-header">
        <h2 className="aside-email-filter-header-title">
          {t("email_filter", "Email Filter")}
        </h2>
        <a className="btn-close" onClick={() => setAsideVisibleStatus(0)}>
          {`X`}
        </a>
      </div>
      <div className="aside__block is-visible">
        <div className="filter-otions emails-filter">
          <FilterOptions
            showSaveFilter={setShowFilterModal}
            handeleDeleteFilter={handeleDeleteFilter}
            currentFilter={currentFilter}
            filters={filters}
            handleSaveFilter={handleSaveFilter}
          />

          <CustomSelect
            values={filters}
            onChange={({ value }) => {
              setCurrentFilter({
                ...filters.find((filter) => filter.id === value),
              });
            }}
            value={
              Object.keys(currentFilter).length !== 0
                ? currentFilter
                : { value: null, label: t("load_filter", "Load Filter") }
            }
            isModel={true}
          />
        </div>
        <div className="aside__form custom-scrollbar">
          <p className="aside__subtitle aside__subtitle--small">
            {t("date", "Date")}
          </p>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', columnGap: 1, pt: 1 }}>
            <LocalizedDatePicker
              id="FromDateEmailFilterSelect"
              value={emailFilter.from}
              maxDate={emailFilter.to}
              onChange={(date) => handleSelectChange(formatDate(date), 'from')}
              label={t("from", "From")}
              sx={{ flexGrow: 1 }}
            />
            <LocalizedDatePicker
              id="ToDateEmailFilterSelect"
              value={emailFilter.to}
              minDate={emailFilter.from}
              onChange={(date) => handleSelectChange(formatDate(date), 'to')}
              label={t("to", "To")}
              sx={{ flexGrow: 1 }}
            />
          </Box>
          <p className="aside__subtitle aside__subtitle--small">
            {t("responsible", "Responsible")}
          </p>
          <div className="aside__grid">
            <div className="aside__grid-created">
              <label htmlFor="createdBy">{t("created_by", "Created by")}</label>
              <CustomSelect
                values={createdByValues}
                onChange={(value) => handleSelectChange(value, "createdBy")}
                value={emailFilter.createdBy}
                name="createdBy"
                isGrey={true}
                isOptional={true}
              />
            </div>
            <div>
              <label htmlFor="recipient">{t("recipient", "Recipient")}</label>
              <CustomSelect
                values={users}
                onChange={(value) => handleSelectChange(value, "recipient")}
                value={emailFilter.recipient}
                name="recipient"
                isGrey={true}
                isMulti={true}
                isOptional={true}
              />
            </div>
          </div>
          <p className="aside__subtitle aside__subtitle--small">
            {t("details", "Details")}
          </p>
          <div className="aside__grid">
            <div>
              <label htmlFor="tags">{t("tags", "Tags")}</label>
              <TagSelect
                values={tagOptions}
                onChange={(value) => handleSelectChange(value, "tags")}
                value={emailFilter.tags}
                isBackgroundVisible={!emailFilter.tags.length > 0}
                isGrey={true}
                isOptional={true}
                menuPlacement={"top"}
                isDisableAddTag={true}
              />
            </div>
          </div>
          {/*<div>*/}
          {/*  <div className="aside__subtitle aside__group mb-3">{t("issues", "Issues")}</div>*/}
          {/*  <div>*/}
          {/*    <label className="label label--bold" htmlFor="document-file-type">*/}
          {/*      {t("linked_issue", "Linked Issue")}*/}
          {/*    </label>*/}
          {/*    <CustomSelect*/}
          {/*        values={issues}*/}
          {/*        onChange={(value) =>*/}
          {/*            handleSelectChange(value, "issue_linked")*/}
          {/*        }*/}
          {/*        value={emailFilter.issue_linked}*/}
          {/*        isGrey={true}*/}
          {/*        menuPlacement={"top"}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        <div className="aside__bottom">
          <button
            className="btn btn--secondary"
            type="button"
            onClick={resetFilters}
          >
            {t("reset_filters", "Reset filters")}
          </button>
        </div>
      </div>
    </aside>
  );
}
