import ApiEndpoint from 'api/types/ApiEndpoint';
import QueryArgs from 'api/types/QueryArgs';
import { QueryOptions } from 'odata-query';
import { useMemo } from 'react';

export type QueryKeyBase = ApiEndpoint;

export type VisoBaseQueryKey<TEndpoint> = Readonly<[TEndpoint]>;
export type VisoCurrentQueryKey<TEndpoint> = Readonly<[...VisoBaseQueryKey<TEndpoint>, 'current']>;
export type VisoListQueryKey<TEndpoint> = Readonly<[...VisoBaseQueryKey<TEndpoint>, 'list']>;
export type VisoListByIdBaseQueryKey<TEndpoint> = Readonly<[...VisoListQueryKey<TEndpoint>, 'ids']>;
export type VisoListByCurrentProjectQueryKey<TEndpoint> = Readonly<[...VisoListQueryKey<TEndpoint>, 'project']>;
export type VisoListByIdQueryKey<TEndpoint> = Readonly<[...VisoListByIdBaseQueryKey<TEndpoint>, string[]]>;
export type VisoOdataQueryKeyBase<TEndpoint> = Readonly<[...VisoListQueryKey<TEndpoint>, 'odata']>;
export type VisoOdataQueryKey<TEndpoint, TQueryDto> = Readonly<[...VisoListQueryKey<TEndpoint>, 'odata', Partial<QueryOptions<TQueryDto>>, QueryArgs]>;
export type VisoDetailsQueryKeyBase<TEndpoint> = Readonly<[...VisoBaseQueryKey<TEndpoint>, 'details', 'id']>;
export type VisoDetailsQueryKey<TEndpoint> = Readonly<[...VisoDetailsQueryKeyBase<TEndpoint>, string]>;

export default function useDefaultEntityQueryKeys<TEndpoint extends QueryKeyBase>(endpoint: TEndpoint) {
  return useMemo(() => ({
    baseQueryKey: [endpoint] as VisoBaseQueryKey<TEndpoint>,
    currentQueryKey: [endpoint, 'current'] as VisoCurrentQueryKey<TEndpoint>,
    listQueryKey: [endpoint, 'list'] as VisoListQueryKey<TEndpoint>,
    listsByIdQueryKey: [endpoint, 'list', 'ids'] as VisoListByIdBaseQueryKey<TEndpoint>,
    listsByCurrentProjectQueryKey: [endpoint, 'list', 'project'] as VisoListByCurrentProjectQueryKey<TEndpoint>,
    getListsByIdsQueryKey: (ids: string[]) => [endpoint, 'list', 'ids', ids] as VisoListByIdQueryKey<TEndpoint>,
    odataQueryQueryKey: [endpoint, 'list', 'odata'] as VisoOdataQueryKeyBase<TEndpoint>,
    getOdataQueryQueryKey: <TQueryDto>(odataQuery: Partial<QueryOptions<TQueryDto>>, args: QueryArgs = {}) => [endpoint, 'list', 'odata', odataQuery, args] as VisoOdataQueryKey<TEndpoint, TQueryDto>,
    detailsByIdQueryKey: [endpoint, 'details', 'id'] as VisoDetailsQueryKeyBase<TEndpoint>,
    getDetailsByIdQueryKey: (id: string) => [endpoint, 'details', 'id', id] as VisoDetailsQueryKey<TEndpoint>,
  }), [endpoint]);
}
