import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useProjectsQuery from 'projects/hooks/useProjectsQuery';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import RoleAction from 'projects/types/RoleAction';
import { useNavigate } from 'react-router-dom';

interface CopyProjectDialogProps {
  open: boolean,
  onClose: (confirmed: boolean) => void,
}

export default function CopyProjectSelectDialog({
  open,
  onClose,
}: CopyProjectDialogProps) {
  const { t } = useTranslation('projects');
  const { data: currentUser } = useCurrentUserQuery();
  const { data: projects, isLoading } = useProjectsQuery(currentUser?.projectIds);
  const orderedAndFilteredProjects = useMemo(() => {
    if (!currentUser || !projects) return undefined;
    return projects.filter((p) => {
      if (!p.collaboratorRoleDefinitions || !p.collaboratorRoles) return false;
      return p.roleDefinitionsByCollaboratorId.get(currentUser.id)?.allowedActions.has(RoleAction.Project_Copy);
    }).sort((a, b) => a.name.localeCompare(b.name));
  }, [currentUser, projects]);
  const [sourceProjectId, setSourceProjectId] = useState<string | undefined>(undefined);

  const onChangeSourceProject = useCallback((event: SelectChangeEvent<string>) => {
    setSourceProjectId(event.target.value);
  }, []);

  const onClickCancel = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const canConfirm = useMemo(() => !!sourceProjectId, [sourceProjectId]);

  const navigate = useNavigate();
  const onClickConfirm = useCallback(async () => {
    navigate(`/projects/${sourceProjectId}/copy`);
  }, [navigate, sourceProjectId]);

  return (
    <Dialog
      id="CopyProjectSelectDialog"
      open={open}
      PaperProps={{ sx: { maxWidth: 'unset', minWidth: 480 } }}
    >
      <DialogTitle>
        {t('copy-project-select-dialog_title', 'Copy Project')}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 4 }}>
          <FormControl fullWidth>
            <InputLabel id="copy-project-select-dialog_source-project-select-label">{t('copy-project-select-dialog_source-project-select-label', 'Source Project')}</InputLabel>
            <Select
              labelId="copy-project-select-dialog_source-project-select-label"
              id="copy-project-select-dialog_source-project-select"
              value={sourceProjectId ?? ''}
              label={t('copy-project-select-dialog_source-project-select-label', 'Source Project')}
              onChange={onChangeSourceProject}
            >
              {orderedAndFilteredProjects?.map((project) => <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ gap: 2 }}>
        <Button onClick={onClickCancel} variant="contained" color="secondary">{t('copy-project-select-dialog_cancel-button-label', 'Cancel')}</Button>
        <Button onClick={onClickConfirm} variant="contained" color="primary" sx={{ ml: 'auto' }} disabled={isLoading || !canConfirm}>
          {isLoading && <CircularProgress size={12} sx={{ mr: 1 }} />}
          {t('copy-project-select-dialog_confirm-button-label', 'Continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
