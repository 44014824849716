import React, { useMemo, useState } from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { ICellRendererParams } from '@ag-grid-community/core';
import useLetterNumbering from 'documents/hooks/useLetterNumbering';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import DocumentVersionsDialog from 'documents/components/DocumentVersionsDialog';
import Color from 'color';

export default function VersionNumberCell({
  value,
  data,
}: ICellRendererParams<DocumentsDataGridRow, number>) {
  const theme = useTheme();
  const { data: currentProject } = useCurrentProjectQuery();
  const isLetterVersioningEnabled = currentProject?.namingSchemeSettings?.useLetterVersioning;
  const { numberToLetter } = useLetterNumbering();
  const displayValue = useMemo(() => (isLetterVersioningEnabled ? numberToLetter(value) : value), [isLetterVersioningEnabled, numberToLetter, value]);
  const [isDocumentVersionDialogOpen, setIsDocumentVersionsDialogOpen] = useState<boolean>(false);
  return (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsDocumentVersionsDialogOpen(true)}
          sx={{
            backgroundColor: Color(theme.palette.info.main).lightness(92).hex(),
            fontWeight: 700,
            height: '24px',
            minWidth: '30px',
            px: 1,
            py: 0,
          }}
        >
          {displayValue}
        </Button>
      </Box>
      {isDocumentVersionDialogOpen && data && <DocumentVersionsDialog documentVersionId={data.id} onClose={() => setIsDocumentVersionsDialogOpen(false)} />}
    </>
  );
}
