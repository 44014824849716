import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Alert,
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import RoleAction from 'projects/types/RoleAction';
import RoleDefinitionListItem from 'settings/components/RoleDefinitionListItem';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import RoleActionListItem from 'settings/components/RoleActionListItem';
import CreateRoleDialog from 'settings/components/CreateRoleDialog';

interface RoleActionGroup {
  translationKey: string,
  roleActions: RoleAction[],
  hundrets: number,
}

export default function RoleSettingsTabPane() {
  const { t } = useTranslation('settings');
  const { data: currentProject } = useCurrentProjectQuery();
  const roleDefinitions = useMemo(() => currentProject?.collaboratorRoleDefinitions.filter((d) => !d.isExternal), [currentProject?.collaboratorRoleDefinitions]);
  const roleDefinitionsById = useMemo(() => (roleDefinitions ? new Map(roleDefinitions.map((d) => [d.id, d])) : undefined), [roleDefinitions]);
  const [selectedRoleDefinitionId, setSelectedRoleDefinitionId] = useState<string | undefined>(undefined);
  const selectedRoleDefinition = useMemo(() => (selectedRoleDefinitionId ? roleDefinitionsById?.get(selectedRoleDefinitionId) : undefined), [roleDefinitionsById, selectedRoleDefinitionId]);
  const [isCreateRoleDialogOpen, setIsCreateRoleDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const roleActionGroups = useMemo(() => {
    if (!selectedRoleDefinition) return undefined;
    const groups = new Array<RoleActionGroup>();
    selectedRoleDefinition.actions.forEach((action) => {
      const roleAction = action.action;
      if (RoleAction[roleAction] !== undefined) {
        const roleActionGroupIndex = Math.floor(roleAction / 100); // RoleAction numeric enum values are grouped by hundrets (e.g. IssueManagement is 200-213, Document is 300-309 etc.)
        let roleActionsGroup = groups[roleActionGroupIndex];
        if (!roleActionsGroup) {
          roleActionsGroup = {
            translationKey: `role-settings_restrictions_group-name_${RoleAction[roleAction].split('_')[0]}`,
            roleActions: [],
            hundrets: roleActionGroupIndex,
          };
          groups[roleActionGroupIndex] = roleActionsGroup;
        }
        roleActionsGroup.roleActions.push(roleAction);
      }
    });
    return groups;
  }, [selectedRoleDefinition]);

  useEffect(() => {
    // select first role definition on init
    if (!selectedRoleDefinitionId && roleDefinitions?.length) {
      setSelectedRoleDefinitionId(roleDefinitions[0].id);
    }
  }, [roleDefinitions, selectedRoleDefinitionId]);

  const onClickCreateRole = useCallback(() => {
    setIsCreateRoleDialogOpen(true);
  }, []);

  const onCreateRoleSuccess = useCallback((message: string) => {
    setSuccessMessage(message);
    setIsCreateRoleDialogOpen(false);
  }, []);

  return (
    <Box
      id="RoleSettingsTabPane"
      sx={{
        display: 'grid', gridTemplateColumns: '4fr 1fr 6fr', p: 8,
      }}
    >
      <Box sx={{ minWidth: '320px' }}>
        <Box sx={{ display: 'flex', alignContent: 'baseline', mb: 2 }}>
          <Typography variant="h2" sx={{ mb: 1, mr: 'auto' }}>{t('role-settings_project-roles', 'Project Roles')}</Typography>
          <Button variant="contained" onClick={onClickCreateRole} sx={{ pl: 1 }}>
            <AddIcon sx={{ mr: 1 }} />
            {t('role-settings_create-new-role-button-caption', 'Create new role')}
          </Button>
        </Box>
        <List>
          {roleDefinitions?.map((roleDefinition) => (
            <RoleDefinitionListItem
              key={roleDefinition.id}
              roleDefinition={roleDefinition}
              selected={selectedRoleDefinitionId === roleDefinition.id}
              onClick={() => setSelectedRoleDefinitionId(roleDefinition.id)}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ gridColumn: '3 / 4' }}>
        {!!selectedRoleDefinition && !!roleActionGroups && (
          <>
            <Typography variant="h3">{t('role-settings_restrictions-header', '{{roleName}} Restrictions', { roleName: selectedRoleDefinition?.name })}</Typography>
            {roleActionGroups.map((roleActionGroup) => (
              <Box key={roleActionGroup.hundrets}>
                <Typography variant="h4" sx={{ mt: 2 }}>{t(roleActionGroup.translationKey, roleActionGroup.translationKey)}</Typography>
                {roleActionGroup.roleActions.map((roleAction) => (
                  <RoleActionListItem key={roleAction} roleAction={roleAction} roleDefinition={selectedRoleDefinition} />
                ))}
              </Box>
            ))}
          </>
        )}
      </Box>
      {isCreateRoleDialogOpen && <CreateRoleDialog onSuccess={onCreateRoleSuccess} onClose={() => setIsCreateRoleDialogOpen(false)} />}
      {!!successMessage && (
      <Dialog open>
        <DialogTitle>{t('role-settings-tab-pane_success-dialog-title', 'Success')}</DialogTitle>
        <DialogContent>
          <Alert severity="success">
            {successMessage}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setSuccessMessage(undefined)}>{t('role-settings-tab-pane_success-dialog-close', 'Close')}</Button>
        </DialogActions>
      </Dialog>
      )}
    </Box>
  );
}
