import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import DocumentListDto from 'documents-lists/types/DocumentListDto';

export default function useDocumentListDeleteMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { baseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentList);
  const mutationFn = useCallback(async (documentListId: string) => {
    await axiosInstance.delete<DocumentListDto>(`${ApiEndpoint.DocumentList}/${documentListId}`);
  }, [axiosInstance]);
  const onSettled = useCallback(() => {
    queryClient.invalidateQueries(baseQueryKey);
  }, [baseQueryKey, queryClient]);
  return useMutation({
    mutationFn,
    onSettled,
  });
}
