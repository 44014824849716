import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import CollaboratorRoleSelect from 'collaborators/components/CollaboratorRoleEditSelect';
import UserSearchBox from 'collaborators/components/UserSearchBox';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import Collaborator from 'collaborators/types/Collaborator';
import CollaboratorRoleMap from 'projects/types/CollaboratorRoleMap';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import UpdateCollaboratorRoleMapDto from 'collaborators/types/UpdateCollaboratorRoleMapDto';
import useCollaboratorRoleMapUpdateMutation from 'collaborators/hooks/useCollaboratorRoleMapUpdateMutation';

interface AddCollaboratorDialogProps {
  onClose: (addedCollaboratorsCount: number | undefined) => void,
}

export default function AddCollaboratorDialog({
  onClose,
}: AddCollaboratorDialogProps) {
  const { t } = useTranslation('collaborators');
  const { data: currentProject } = useCurrentProjectQuery();
  const { mutateAsync, isLoading: isLoadingMutation } = useCollaboratorRoleMapUpdateMutation();
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [collaboratorRoleMapList, setCollaboratorRoleMapList] = useState<CollaboratorRoleMap[]>([]);
  const [stagedCollaborator, setStagedCollaborator] = useState<Collaborator | undefined>();
  const [stagedRoleDefinitionId, setStagedRoleDefinitionId] = useState<string | undefined>();
  const [userSearchBoxText, setUserSearchBoxText] = useState<string>('');
  const [collaboratorsById] = useState(new Map<string, Collaborator>());

  const isStagedCollaboratorAlreadyInProject = useMemo(() => currentProject && stagedCollaborator && currentProject?.roleDefinitionsByCollaboratorId?.has(stagedCollaborator.id), [currentProject, stagedCollaborator]);
  const isStagedCollaboratorAlreadyListed = useMemo(() => stagedCollaborator && collaboratorRoleMapList.some((l) => l.collaboratorId === stagedCollaborator.id), [collaboratorRoleMapList, stagedCollaborator]);
  const userSearchBoxHelperText = useMemo(() => {
    if (isStagedCollaboratorAlreadyInProject) return t('add-collaborator-dialog_collaborator-already-in-project', 'This collaborator is already in the project.');
    if (isStagedCollaboratorAlreadyListed) return t('add-collaborator-dialog_collaborator-already-in-list', 'This collaborator is already in the list below.');
    return undefined;
  }, [isStagedCollaboratorAlreadyInProject, isStagedCollaboratorAlreadyListed, t]);

  const collaboratorRoleMapsToAdd = useMemo(() => {
    const stagedMap = stagedCollaborator && stagedRoleDefinitionId ? { collaboratorId: stagedCollaborator.id, roleDefinitionId: stagedRoleDefinitionId } : undefined;
    return stagedMap ? [stagedMap, ...collaboratorRoleMapList] : collaboratorRoleMapList;
  }, [collaboratorRoleMapList, stagedCollaborator, stagedRoleDefinitionId]);

  const queryClient = useQueryClient();
  const { baseQueryKey: userGroupsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.UserGroup);
  const onConfirm = useCallback(async () => {
    if (!currentProject) return;
    const persistDto: UpdateCollaboratorRoleMapDto = {
      collaboratorRoles: [
        ...currentProject.collaboratorRoles,
        ...collaboratorRoleMapsToAdd,
      ],
    };
    try {
      await mutateAsync(persistDto);
      queryClient.invalidateQueries(userGroupsQueryKey);
      onClose(collaboratorRoleMapsToAdd.length);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [currentProject, collaboratorRoleMapsToAdd, mutateAsync, queryClient, userGroupsQueryKey, onClose, getRequestErrorMessage]);

  const stageFoundCollaborator = useCallback(() => {
    if (!stagedCollaborator || !stagedRoleDefinitionId) return;
    collaboratorsById.set(stagedCollaborator.id, stagedCollaborator);
    setCollaboratorRoleMapList((prev) => [...prev, { collaboratorId: stagedCollaborator.id, roleDefinitionId: stagedRoleDefinitionId }]);
    setUserSearchBoxText('');
  }, [collaboratorsById, stagedCollaborator, stagedRoleDefinitionId]);

  const changeStagedRole = useCallback((collaboratorId: string, nextRoleId: string) => {
    setCollaboratorRoleMapList((prev) => prev.map((m) => {
      if (m.collaboratorId === collaboratorId) return { collaboratorId, roleDefinitionId: nextRoleId };
      return m;
    }));
  }, []);

  useEffect(() => {
    // set default role on init
    if (stagedRoleDefinitionId !== undefined) return;
    const adminRoleId = currentProject?.collaboratorRoleDefinitions.find((r) => r.isAdmin)?.id;
    setStagedRoleDefinitionId(adminRoleId);
  }, [currentProject, stagedRoleDefinitionId]);

  return (
    <Dialog
      id="AddCollaboratorDialog"
      open
      PaperProps={{
        sx: {
          maxWidth: 'unset', width: '650px', minHeight: '360px',
        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', columnGap: 2 }}>
        {t('add-collaborator-dialog_title', 'Add Collaborators to the Project')}
        <Button
          variant="contained"
          color="secondary"
          onClick={stageFoundCollaborator}
          disabled={!stagedCollaborator || !stagedRoleDefinitionId || isStagedCollaboratorAlreadyInProject || isStagedCollaboratorAlreadyListed}
          sx={{ minWidth: 'unset', pl: 1, ml: 'auto' }}
        >
          <PlaylistAddIcon sx={{ mr: 1 }} />
          {t('add-collaborator-dialog_add-more-button-label', 'Add more')}
        </Button>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2 }}>
          <TableContainer>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '180px' }}>{t('add-collaborator-dialog_table-header-column-email', 'E-Mail')}</TableCell>
                  <TableCell>{t('add-collaborator-dialog_table-header-column-anem', 'Name')}</TableCell>
                  <TableCell sx={{ width: '125px' }}>{t('add-collaborator-dialog_table-header-column-role', 'Role')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <UserSearchBox
                      sx={{ width: '170px' }}
                      text={userSearchBoxText}
                      onChangeText={setUserSearchBoxText}
                      onChangeCollaborator={setStagedCollaborator}
                      helperText={userSearchBoxHelperText}
                      error={isStagedCollaboratorAlreadyInProject || isStagedCollaboratorAlreadyListed}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography>{!!stagedCollaborator && `${stagedCollaborator?.firstName} ${stagedCollaborator?.lastName}`}</Typography>
                  </TableCell>
                  <TableCell>
                    {!!stagedRoleDefinitionId && <CollaboratorRoleSelect value={stagedRoleDefinitionId} onChange={setStagedRoleDefinitionId} sx={{ width: '120px' }} />}
                  </TableCell>
                </TableRow>
                {collaboratorRoleMapList.map((map) => (
                  <TableRow key={map.collaboratorId}>
                    <TableCell>
                      {`${collaboratorsById.get(map.collaboratorId)?.email}`}
                    </TableCell>
                    <TableCell>
                      {`${collaboratorsById.get(map.collaboratorId)?.firstName} ${collaboratorsById.get(map.collaboratorId)?.lastName}`}
                    </TableCell>
                    <TableCell>
                      <CollaboratorRoleSelect
                        value={map.roleDefinitionId}
                        onChange={(nextRoleId) => changeStagedRole(map.collaboratorId, nextRoleId)}
                        sx={{ width: '120px' }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {!!errorMessage && <Alert severity="error" sx={{ mt: 2 }}>{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={() => onClose(undefined)}>
          {t('add-collaborator-dialog_confirm', 'Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm} sx={{ ml: 'auto' }} disabled={!currentProject || !collaboratorRoleMapsToAdd.length || isStagedCollaboratorAlreadyInProject || isStagedCollaboratorAlreadyListed || isLoadingMutation}>
          {(isLoadingMutation || !currentProject) && <CircularProgress size={12} sx={{ mr: 1 }} />}
          {t('add-collaborator-dialog_cancel', 'Add {{count}} collaborators', { count: collaboratorRoleMapsToAdd.length })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
