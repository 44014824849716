import React, { useEffect, useRef } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import usePlanlistCreateNewDropZoneParams from 'documents-lists/hooks/usePlanlistCreateNewDropZoneParams';
import { useTranslation } from 'react-i18next';

interface PlanlistDocumentDropZoneProps extends ISxProps {
}

export default function PlanlistDocumentDropZone({
  sx,
}: PlanlistDocumentDropZoneProps) {
  const { t } = useTranslation('documents-lists');
  const theme = useTheme();
  const divRef = useRef<HTMLDivElement>();
  const { initializeDropZoneParams, isDraggedOverCreateNewPlanlistDropZone } = usePlanlistCreateNewDropZoneParams();
  useEffect(() => {
    if (!divRef.current) throw new Error('Unable to register planlist document dropzone (missing ref)');
    initializeDropZoneParams(divRef.current);
  }, [initializeDropZoneParams]);

  return (
    <Box ref={divRef} sx={{ ...sx, backgroundColor: theme.palette.background.default, borderRadius: '2px' }}>
      <Box sx={{
        m: 1,
        p: 1,
        height: '100px',
        width: '150px',
        borderWidth: '1px',
        borderStyle: 'dashed',
        borderColor: isDraggedOverCreateNewPlanlistDropZone ? theme.palette.primary.main : theme.palette.grey[400],
        backgroundColor: isDraggedOverCreateNewPlanlistDropZone ? theme.palette.secondary.light : undefined,
        display: 'flex',
        flexGrow: 1,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
      >
        <Typography>{t('planlist_drop-to-create', 'Drop here to create a new list')}</Typography>
      </Box>
    </Box>
  );
}
