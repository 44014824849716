import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import PersistCollaboratorRoleDefinitionDto from 'projects/types/PersistCollaboratorRoleDefinitionDto';
import CollaboratorRoleDefinitionDto from 'projects/types/CollaboratorRoleDefinitionDto';

export default function useCollaboratorRoleDefinitionUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { listQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Project); // role definitions are part of the project dto, so we need to invalidate that
  return useMutation<void, unknown, PersistCollaboratorRoleDefinitionDto>(async (dto: PersistCollaboratorRoleDefinitionDto) => {
    await axiosInstance.put<CollaboratorRoleDefinitionDto>(`${ApiEndpoint.RoleDefinitions}`, [dto]);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(listQueryKey);
    },
  });
}
