import * as Request from '../Helpers/Request';

export const getNamingScheme = async (projectId, setApiError) => {
  const result = await Request.GET(`api/naming-scheme?projectId=${projectId}`);

  if (setApiError)
    setApiError(result.body?.errors ? result.body?.errors[0].errorMessage : '');

  return result.status === 200 ? result.body : null;
};

export const createNamingScheme = async (params, setApiError) => {
  const result = await Request.POST('api/naming-scheme', params);

  if (setApiError)
    setApiError(result.body?.errors ? result.body?.errors[0].errorMessage : '');

  return result.status === 200 ? result.body : null;
};

export const updateNamingScheme = async (params, setApiError) => {
  const result = await Request.PUT('api/naming-scheme', params);

  if (setApiError)
    setApiError(result.body?.errors ? result.body?.errors[0].errorMessage : '');

  return result.status === 200 ? result.body : null;
};

export const deleteNamingScheme = async (schemeId, setApiError) => {
  const result = await Request.DELETE(`api/naming-scheme/${schemeId}`);

  if (setApiError)
    setApiError(result.body?.errors ? result.body?.errors[0].errorMessage : '');

  return result.status === 200 ? result.body : null;
};

export const getGroupTypes = async () => {
  const result = await Request.GET('api/naming-scheme/group-types');
  return result.status === 200 ? result.body : [];
};

export const getDateTypes = async () => {
  const result = await Request.GET('api/naming-scheme/date-types');
  return result.status === 200 ? result.body : [];
};

export const getSeparators = async () => {
  const result = await Request.GET('api/naming-scheme/separator-types');
  return result.status === 200 ? result.body : [];
};

export const updateNamingSettings = async (projectId, namingSettings, setApiError) => {
  const result = await Request.PATCH(
    `api/naming-settings`,
    namingSettings
  );

  if (setApiError)
    setApiError(result.body?.errors ? result.body?.errors[0].errorMessage : '');
    
  return result.status === 200 ? result.body : false;
};
