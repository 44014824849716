import React, {
  SyntheticEvent, useCallback, useMemo, useState,
} from 'react';
import {
  Autocomplete, Box, Button, Checkbox, Collapse, IconButton, Radio, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, useTheme,
} from '@mui/material';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import Collaborator from 'collaborators/types/Collaborator';
import ISxProps from 'common/types/ISxProps';
import FolderAccessType from 'documents-folders/types/FolderAccessType';
import { FolderPermissionValues } from 'documents-folders/types/FolderPermissionValues';
import { useTranslation } from 'react-i18next';
import useUserGroupsQuery from 'users-groups/hooks/useUserGroupsQuery';
import UserGroupDto from 'users-groups/types/UserGroupDto';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import { grey } from '@mui/material/colors';
import AddBoxIcon from '@mui/icons-material/AddBox';
import InlineTypography from 'common/components/InlineTypography';
import GroupMemberListTooltipButton from 'users-groups/components/GroupMemberListTooltipButton';

enum ReadWriteValue {
  Read = 'read',
  ReadWrite = 'read-write',
}

enum PermissionSubjectType {
  UserGroupEntity = 'user-group',
  CollaboratorEntity = 'collaborator',
}

type PermissionSubject = {
  type: PermissionSubjectType.CollaboratorEntity,
  groupLabel: string,
  label: string,
  entity: Collaborator,
} | {
  type: PermissionSubjectType.UserGroupEntity,
  groupLabel: string,
  label: string,
  entity: UserGroupDto,
};

interface FolderEditPermissionsPanelProps extends ISxProps {
  accessType: FolderAccessType,
  permissions: FolderPermissionValues,
  onChange: (value: FolderPermissionValues) => void,
  disabled?: boolean,
}

export default function FolderEditPermissionsPanel({
  sx,
  accessType,
  permissions,
  disabled,
  onChange,
}: FolderEditPermissionsPanelProps) {
  const { t } = useTranslation('documents-folders');
  const theme = useTheme();
  const { data: currentProject } = useCurrentProjectQuery();
  const { data: groupsData } = useUserGroupsQuery();
  const groups = useMemo(() => groupsData?.filter((g) => !(g.isDefault && g.name === 'External')), [groupsData]);
  const collaborators = useMemo(() => currentProject?.collaborators, [currentProject?.collaborators]);

  const readOnlyGroupIdsSet = useMemo(() => new Set<string>(permissions.readOnlyGroupsIds), [permissions.readOnlyGroupsIds]);
  const readOnlyCollaboratorIdsSet = useMemo(() => new Set<string>(permissions.readOnlyUserIds), [permissions.readOnlyUserIds]);
  const addedCollaboratorIdsSet = useMemo(() => new Set<string>(permissions.accessUserIds), [permissions.accessUserIds]);
  const addedGroupIdsSet = useMemo(() => new Set<string>(permissions.accessGroupsIds), [permissions.accessGroupsIds]);

  const addedCollaborators = useMemo(() => {
    if (accessType === FolderAccessType.Public) return collaborators;
    if (accessType === FolderAccessType.Restricted) return collaborators?.filter((c) => addedCollaboratorIdsSet?.has(c.id));
    return [];
  }, [accessType, addedCollaboratorIdsSet, collaborators]);

  const addedGroups = useMemo(() => {
    if (accessType === FolderAccessType.Public) return groups;
    if (accessType === FolderAccessType.Restricted) return groups?.filter((g) => addedGroupIdsSet?.has(g.id));
    return [];
  }, [accessType, addedGroupIdsSet, groups]);

  const [isGroupsListVisible, setIsGroupsListVisible] = useState(true);
  const onClickToggleGroupsListVisible = useCallback(() => setIsGroupsListVisible((prev) => !prev), []);
  const [isUsersListVisible, setIsUsersListVisible] = useState(true);
  const onClickToggleUsersListVisible = useCallback(() => setIsUsersListVisible((prev) => !prev), []);

  const handleChangeGroupReadOnly = useCallback((groupId: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const readWriteValue = event.target.value as ReadWriteValue;
    const updatedReadOnlyIds = (readWriteValue === ReadWriteValue.Read) ? Array.from(new Set([...permissions.readOnlyGroupsIds, groupId])) : permissions.readOnlyGroupsIds.filter((id) => id !== groupId);
    onChange({ ...permissions, readOnlyGroupsIds: updatedReadOnlyIds });
  }, [onChange, permissions]);

  const toggleGroupReadOnly = useCallback((groupId: string, value: boolean) => {
    if (!readOnlyGroupIdsSet) return;
    const isReadOnly = readOnlyGroupIdsSet.has(groupId);
    if (isReadOnly && !value) {
      onChange({
        ...permissions,
        accessGroupsIds: Array.from(new Set(permissions.accessGroupsIds.concat(groupId))),
        readOnlyGroupsIds: permissions.readOnlyGroupsIds.filter((id) => id !== groupId),
      });
    } else if (!isReadOnly && value) {
      onChange({
        ...permissions,
        accessGroupsIds: Array.from(new Set(permissions.accessGroupsIds.concat(groupId))),
        readOnlyGroupsIds: Array.from(new Set(permissions.readOnlyGroupsIds.concat(groupId))),
      });
    }
  }, [onChange, permissions, readOnlyGroupIdsSet]);

  const handleChangeUserReadOnly = useCallback((collaboratorId: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const readWriteValue = event.target.value as ReadWriteValue;
    const updatedReadOnlyIds = (readWriteValue === ReadWriteValue.Read) ? Array.from(new Set([...permissions.readOnlyUserIds, collaboratorId])) : permissions.readOnlyUserIds.filter((id) => id !== collaboratorId);
    onChange({ ...permissions, readOnlyUserIds: updatedReadOnlyIds });
  }, [onChange, permissions]);

  const toggleUserReadOnly = useCallback((collaboratorId: string, value: boolean) => {
    if (!readOnlyCollaboratorIdsSet) return;
    const isReadOnly = readOnlyCollaboratorIdsSet.has(collaboratorId);
    if (isReadOnly && !value) {
      onChange({
        ...permissions,
        readOnlyUserIds: permissions.readOnlyUserIds.filter((id) => id !== collaboratorId),
      });
    } else if (!isReadOnly && value) {
      onChange({
        ...permissions,
        readOnlyUserIds: [...permissions.readOnlyUserIds, collaboratorId],
      });
    }
  }, [onChange, permissions, readOnlyCollaboratorIdsSet]);

  const autocompletePermissionSubjects = useMemo(() => (groups && collaborators ? [
    ...groups.map<PermissionSubject>((entity) => ({
      entity,
      type: PermissionSubjectType.UserGroupEntity,
      groupLabel: t('folder-edit-permissions-panel_autocomplete-category-groups', 'Groups'),
      label: entity.name,
    })),
    ...collaborators.map<PermissionSubject>((entity) => ({
      entity,
      type: PermissionSubjectType.CollaboratorEntity,
      groupLabel: t('folder-edit-permissions-panel_autocomplete-category-collaborators', 'Collaborators'),
      label: `${entity.firstName} ${entity.lastName}`,
    })),
  ] : undefined), [collaborators, groups, t]);

  const [subjectStagedToAdd, setSubjectStagedToAdd] = useState<PermissionSubject | undefined>(undefined);

  const onChangeAddSubjectAutocomplete = useCallback((event: SyntheticEvent<Element, Event>, value: PermissionSubject | null) => {
    setSubjectStagedToAdd(value ?? undefined);
  }, []);
  const onClickAddSubjectAutocomplete = useCallback(() => {
    if (!subjectStagedToAdd) return;
    if (subjectStagedToAdd.type === PermissionSubjectType.UserGroupEntity) {
      onChange({ ...permissions, accessGroupsIds: Array.from(new Set([...(permissions.accessGroupsIds ?? []), subjectStagedToAdd.entity.id])) });
    } else if (subjectStagedToAdd.type === PermissionSubjectType.CollaboratorEntity) {
      onChange({ ...permissions, accessUserIds: Array.from(new Set([...(permissions.accessUserIds ?? []), subjectStagedToAdd.entity.id])) });
    }
    setSubjectStagedToAdd(undefined);
  }, [onChange, permissions, subjectStagedToAdd]);

  const onClickRemoveGroup = useCallback((groupId: string) => {
    onChange({ ...permissions, accessGroupsIds: permissions.accessGroupsIds.filter((id) => id !== groupId) });
  }, [onChange, permissions]);
  const onClickRemoveCollaborator = useCallback((collaboratorId: string) => {
    onChange({ ...permissions, accessUserIds: permissions.accessUserIds.filter((id) => id !== collaboratorId) });
  }, [onChange, permissions]);

  const textColor = useMemo(() => (disabled ? theme.palette.text.disabled : undefined), [disabled, theme.palette.text.disabled]);

  const permissionsTableHead = (
    <TableHead>
      <TableRow>
        <TableCell sx={{ width: '40px', color: textColor }} />
        <TableCell sx={{ color: textColor }}>{t('folder-edit-permissions-panel_name-column', 'Name')}</TableCell>
        {accessType === FolderAccessType.Restricted && (
        <>
          <TableCell sx={{ width: '100px', textAlign: 'center', color: textColor }}>{t('folder-edit-permissions-panel_read-right-column', 'Read')}</TableCell>
          <TableCell sx={{ width: '100px', textAlign: 'center', color: textColor }}>{t('folder-edit-permissions-panel_read-write-right-column', 'Read & Write')}</TableCell>
          <TableCell sx={{ width: '100px', textAlign: 'center', color: textColor }} />
        </>
        )}
        {accessType === FolderAccessType.Public && (
          <TableCell sx={{ width: '100px', textAlign: 'center', color: textColor }}>{t('folder-edit-permissions-panel_read-only-column', 'Read Only')}</TableCell>
        )}
      </TableRow>
    </TableHead>
  );

  return (
    <Box
      sx={{
        ...sx, pt: 1, display: 'grid', gridTemplateRows: 'auto 1fr', maxHeight: '100%',
      }}
      id="FolderEditPermissionsPanel"
    >
      {accessType === FolderAccessType.Restricted && (
        <Box sx={{ display: 'flex' }}>
          <Autocomplete
            id="grouped-demo"
            options={autocompletePermissionSubjects ?? []}
            groupBy={(option) => option.groupLabel}
            value={subjectStagedToAdd ?? null}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                // justification for prop-spreading: by-design from 3rd party library MUI
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label={t('folder-edit-permissions-panel_add-subjects-autocomplete-label', 'Add groups & collaborators')}
              />
            )}
            sx={{ mr: 1, width: '280px' }}
            onChange={onChangeAddSubjectAutocomplete}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ pl: 1 }}
            onClick={onClickAddSubjectAutocomplete}
            disabled={disabled || !subjectStagedToAdd}
          >
            <AddBoxIcon sx={{ mr: 1 }} />
            {t('', 'Add')}
          </Button>
        </Box>
      )}
      <Box sx={{ overflowY: 'auto', gridRow: '2 / 3' }} className="custom-scrollbar">
        <Typography sx={{
          mt: 1, fontWeight: 800, backgroundColor: grey[50], color: textColor,
        }}
        >
          <IconButton onClick={onClickToggleGroupsListVisible} sx={{ p: 0.5, mr: '15px' }}>
            {isGroupsListVisible ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
          {t('folder-edit-permissions-panel_table-groups-subheader', 'Groups')}
        </Typography>
        <Collapse in={isGroupsListVisible} timeout="auto">
          <Table>
            {permissionsTableHead}
            <TableBody>
              {addedGroups?.map((g) => (
                <TableRow key={g.id}>
                  <TableCell sx={{ width: '40px' }}>
                    <GroupIcon color="primary" sx={{ color: textColor }} />
                  </TableCell>
                  <TableCell>
                    <InlineTypography sx={{ fontWeight: 600, color: textColor }}>{`${g.name}`}</InlineTypography>
                    <GroupMemberListTooltipButton groupId={g.id} sx={{ ml: 1 }} />
                  </TableCell>
                  {accessType === FolderAccessType.Restricted && (
                    <>
                      <TableCell sx={{ width: '100px', textAlign: 'center' }}>
                        <Radio
                          checked={!!readOnlyGroupIdsSet.has(g.id)}
                          onChange={(e) => handleChangeGroupReadOnly(g.id, e)}
                          value={ReadWriteValue.Read}
                          name={`readwrite-radio-${g.id}`}
                          disabled={disabled}
                        />
                      </TableCell>
                      <TableCell sx={{ width: '100px', textAlign: 'center' }}>
                        <Radio
                          checked={!readOnlyGroupIdsSet.has(g.id)}
                          onChange={(e) => handleChangeGroupReadOnly(g.id, e)}
                          value={ReadWriteValue.ReadWrite}
                          name={`readwrite-radio-${g.id}`}
                          disabled={disabled}
                        />
                      </TableCell>
                      <TableCell sx={{ width: '100px', textAlign: 'center' }}>
                        <Button
                          disabled={disabled}
                          variant="outlined"
                          sx={{ height: '24px' }}
                          size="small"
                          onClick={() => onClickRemoveGroup(g.id)}
                        >
                          {t('folder-edit-permissions-panel_remove-button-label', 'Remove')}
                        </Button>
                      </TableCell>
                    </>
                  )}
                  {accessType === FolderAccessType.Public && (
                    <TableCell sx={{ width: '100px', textAlign: 'center' }}>
                      <Checkbox
                        checked={!!readOnlyGroupIdsSet.has(g.id)}
                        onChange={(e) => toggleGroupReadOnly(g.id, e.target.checked)}
                        disabled={disabled}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
        <Typography sx={{
          mt: 1, fontWeight: 800, backgroundColor: grey[50], color: textColor,
        }}
        >
          <IconButton onClick={onClickToggleUsersListVisible} sx={{ p: 0.5, mr: '15px' }}>
            {isUsersListVisible ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
          {t('folder-edit-permissions-panel_table-users-subheader', 'Users')}
        </Typography>
        <Collapse in={isUsersListVisible} timeout="auto">
          <Table>
            {permissionsTableHead}
            <TableBody>
              {addedCollaborators?.map((c) => (
                <TableRow key={c.id}>
                  <TableCell sx={{ width: '40px' }}>
                    <PersonIcon color="primary" sx={{ color: textColor }} />
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, color: textColor }}>
                    {`${c.firstName} ${c.lastName}`}
                  </TableCell>
                  {accessType === FolderAccessType.Restricted && (
                    <>
                      <TableCell sx={{ width: '100px', textAlign: 'center' }}>
                        <Radio
                          checked={!!readOnlyCollaboratorIdsSet.has(c.id)}
                          onChange={(e) => handleChangeUserReadOnly(c.id, e)}
                          value={ReadWriteValue.Read}
                          name={`readwrite-radio-${c.id}`}
                          disabled={disabled}
                        />
                      </TableCell>
                      <TableCell sx={{ width: '100px', textAlign: 'center' }}>
                        <Radio
                          checked={!readOnlyCollaboratorIdsSet.has(c.id)}
                          onChange={(e) => handleChangeUserReadOnly(c.id, e)}
                          value={ReadWriteValue.ReadWrite}
                          name={`readwrite-radio-${c.id}`}
                          disabled={disabled}
                        />
                      </TableCell>
                      <TableCell sx={{ width: '100px', textAlign: 'center' }}>
                        <Button
                          disabled={disabled}
                          variant="outlined"
                          sx={{ height: '24px' }}
                          size="small"
                          onClick={() => onClickRemoveCollaborator(c.id)}
                        >
                          {t('folder-edit-permissions-panel_remove-button-label', 'Remove')}
                        </Button>
                      </TableCell>

                    </>
                  )}
                  {accessType === FolderAccessType.Public && (
                    <TableCell sx={{ width: '100px', textAlign: 'center' }}>
                      <Checkbox
                        checked={!!readOnlyCollaboratorIdsSet.has(c.id)}
                        onChange={(e) => toggleUserReadOnly(c.id, e.target.checked)}
                        disabled={disabled}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </Box>
    </Box>
  );
}
