import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import { Portal } from '@mui/material';

// Components
import DocumentLogDialog from 'documents/components/DocumentLogDialog';
import CustomSelect from 'Components/CustomSelect';
import IssueBoardColumnHeaderOld from 'Components/IssueBoard/IssueBoardColumnHeaderOld';
import LinkedTasks from 'Components/IssueBoard/LinkedTasks';
import IssueComments from 'Components/IssueBoard/IssueComments';
import AssignItem from 'Components/IssueBoard/AssignItem';
import LinkedDocumentItem from 'Components/IssueBoard/LinkedDocumentItem';
import { Modal } from 'HOC';
import AsideEmailDetail from 'Components/Aside/AsideEmailDetail';
import OpenWindowsClientLink from 'Components/OpenWindowsClientLink/OpenWindowsClientLink';
import MentionView from 'Components/MentionView';
import DocumentDetailsModal from 'documents-details/components/DocumentDetailsModal';

// Helpers
import * as Request from 'Helpers/Request';
import { EmailService } from 'Services';
import {
  getMetaDataWithoutOther,
  getDisciplineColor,
} from 'Services/IssueService';
import { getNameLinkMapParams } from 'Helpers/utils';
import { useAnnotation, useDocumentLogs } from 'Helpers/Hooks';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import RoleAction from 'projects/types/RoleAction';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import Loader from 'Components/Loader';
import { CopyLink, CloseButton } from 'Components/Buttons';
import TagSelect from 'Components/TagSelect';
import AsideIssueViewpoint from './AsideIssueViewpoint';
import DocumentScopeContextMemoryProvider from 'documents/contexts/DocumentScopeContextMemoryProvider';
import useDocumentScopeContext from 'documents/hooks/useDocumentScopeContext';

export default function AsideIssue({
  canEditIssue,
  show_aside,
  statuses,
  priorities,
  types,
  workphases,
  issue,
  toggleAside,
  updateStatus,
  tagOptions,
  updateTag,
  users,
  floors,
  disciplines,
  allEmails,
  buildings,
  baseIssuesUrl,
  allDocuments,
  allIssues,
  setCurrentIssue,
  setEditIssue,
  isDocumentPreview,
  setIsDocLog,
  isModelIssues = false,
  setIsIssueModal,
  components,
  openViewpointIn3D,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: user } = useCurrentUserQuery();
  const currentUserRole = useCurrentUserRole();
  const [loading, setLoading] = useState(false);
  const [inputComment, setInputComment] = useState('');
  const [comments, setComments] = useState([]);
  const [viewpoints, setViewpoints] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [status, setStatus] = useState(null);
  const [isVisibleHistory, setToggleHistory] = useState(false);
  const [allIssueLogs, setAllIssueLogs] = useState([]);
  const { issueLogs, setIssueLogs } = useDocumentLogs({
    allIssueLogs,
    allDocuments,
  });
  const [tags, setTags] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [emailPreviewModal, setEmailPreviewModal] = useState(false);
  const [isExpanded, setExpanded] = useState({
    explanation: true,
    assigned: true,
    model: true,
    viewpoint: true,
    bimModel: false,
    elements: false,
    document: false,
    email: false,
  });
  const { pdfAnnotations } = useAnnotation({ issueId: issue.id });
  const linkedEmails = useMemo(() => {
    if (!issue.linkedEmailIds?.length || !allEmails?.length) return [];
    return allEmails.filter((email) => issue.linkedEmailIds.some((id) => id === email.id));
  }, [issue, allEmails]);
  const issueDisciplineColors = useMemo(
    () => (issue.disciplines || []).map((item, index) => {
      const color = getDisciplineColor(index);
      return {
        color,
        background: `${color}33`,
      };
    }),
    [issue.disciplines],
  );

  useEffect(() => {
    if (!tagOptions || !issue) return [];
    setTags(tagOptions.filter((item) => issue.tagIds.includes(item.value)));
  }, [tagOptions, issue]);

  const handleSelectTags = useCallback(
    (item, issueId) => {
      updateTag(item, issueId);
      setTags(item);
    },
    [updateTag],
  );

  useEffect(() => {
    if (!issue) return;
    setDocuments(
      sortBy(
        issue.linkedDocumentVersionIds
          .map((id) => allDocuments?.find((d) => d.id === id))
          .filter((d) => !!d),
        'name',
      ),
    );
    setAllIssueLogs([]);
    setIssueLogs([]);
  }, [issue, allDocuments]);

  const canEditIssueStatus = useMemo(() => {
    if (issue && user) {
      if (issue.issueStatus?.originalName === 'Closed') { return currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_StatusEditing_UnsetClosed); }
      if (status?.originalName === 'Closed') { return currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_StatusEditing_SetClosed); }
      if (currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_StatusEditing)) return true;
      if (
        issue.createAuthorId == user.id
        && currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_Creator_StatusEditing)
      ) { return true; }
      if (
        issue.reviewerId == user.id
        && currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_Reviewer_StatusEditing)
      ) { return true; }
      if (
        issue.assignedUserIds.includes(user.id)
        && currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_Assigned_StatusEditing)
      ) { return true; }
    }
    return false;
  }, [currentUserRole, status, issue]);

  const canEditTag = useMemo(() => {
    if (issue) {
      if (issue.issueStatus?.originalName === 'Closed') return false;
      return currentUserRole?.allowedActions?.has(RoleAction.Tag_Create_Update);
    }
  }, [currentUserRole, issue]);

  const canOpenLiveView = useMemo(() => (
    currentUserRole?.allowedActions?.has(RoleAction.MainMenu_LiveView)
      && (!!viewpoints?.length || !!issue?.linkedComponentsGlobalIds?.length)
      && isModelIssues
  ), [viewpoints, issue, currentUserRole]);

  useEffect(() => {
    if (issue?.viewpoints?.length) { getViewpoints(issue.viewpoints); } else { setViewpoints([]); }
  }, [issue?.viewpoints]);

  useEffect(() => {
    if (issue?.commentIds?.length) { getComments(issue.commentIds); } else { setComments([]); }
  }, [issue?.commentIds]);

  useEffect(() => {
    if (issue?.issueStatus) { setStatus(statuses.find((item) => item.id === issue.issueStatus.id)); } else { setStatus(null); }
  }, [statuses, issue?.issueStatus]);

  const getComments = (comment_ids) => Request.GET(`api/comment/${comment_ids}`).then(
    (result) => result.status !== 200 || setComments(result.body),
  );

  const getViewpoints = (viewpoints) => {
    const array_to_load = [];
    const vpItems = [];

    viewpoints.map((item) => {
      if (item?.imageName) {
        array_to_load.push(
          Request.GET(
            `api/resource/${item?.thumbnailImageId || item?.imageId}`,
          ).then(
            (result) => result.status !== 200
              || vpItems.push({ image: result.body, data: item }),
          ),
        );
      }
    });

    return Promise.all(array_to_load).then(() => {
      const viewpointDataList = [];

      vpItems.map((item) => {
        const reader = new FileReader();
        reader.readAsDataURL(item.image);
        reader.onloadend = () => {
          viewpointDataList.push({ image: reader.result, data: item.data });

          if (viewpointDataList.length === vpItems.length) {
            setViewpoints(viewpointDataList);
          }
        };
      });
    });
  };

  const handleChangeStatus = (status) => {
    setStatus(statuses.find((item) => item.value === status.value));
    updateStatus(issue, status);
  };

  const handleCommentChange = (e) => {
    setInputComment(e.target.value);
  };

  const addComment = (e) => {
    e.preventDefault();

    setLoading(true);

    Request.POST('api/comment', [
      {
        timeStamp: new Date(),
        issueId: { value: issue.id },
        text: { value: inputComment },
        nameLinkMap: getNameLinkMapParams(inputComment, users),
      },
    ]).then((result) => {
      setLoading(false);

      if (result.status === 200) {
        const buffer = Object.assign([], comments);
        buffer.push(result.body[0]);
        setComments(buffer);
        setInputComment('');
      }
    });
  };

  const toggleHistory = (event, show) => {
    event.stopPropagation();
    setToggleHistory(show);
  };

  const handleChangeExpand = useCallback(
    (key) => {
      setExpanded({
        ...isExpanded,
        [key]: !isExpanded[key],
      });
    },
    [isExpanded],
  );

  const availableIssueStatuses = useMemo(() => {
    const issueStatusItems = getMetaDataWithoutOther(statuses);
    if (!currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_StatusEditing_SetClosed)) {
      return issueStatusItems.filter((issueStatus) => issueStatus.originalName !== 'Closed');
    }
    return issueStatusItems;
  }, [statuses]);

  if (!issue) return <></>;

  return (
    <DocumentScopeContextMemoryProvider>
      <DocumentDetailsModalWrapper />
      <aside
        className={
          `main__aside main__aside--issue aside${
            show_aside ? ' is-open' : ''}`
        }
      >
        <div className="aside__block is-visible asside_issues">
          <Loader isLoading={loading} />

          <div className="aside__accordion custom-scrollbar">
            <div className="aside_issues--top-header">
              <div className="top-header_row">
                {!!canEditIssue && (
                  <button
                    id="EditAsideIssueButton"
                    className="btn btn--secondary btn--sm"
                    onClick={() => {
                      if (isModelIssues) {
                        setIsIssueModal(true);
                        setEditIssue(issue);
                      } else {
                        toggleAside('aside_edit', true);
                        navigate(`${baseIssuesUrl}${issue.id}/edit`);
                      }
                    }}
                  >
                    {t('edit', 'Edit')}
                  </button>
                )}
                {!isModelIssues && canOpenLiveView && (
                  <OpenWindowsClientLink issueId={issue?.id} view="live">
                    <button id="Launch3DViewAsideIssueButton" className="btn btn--primary mobile--hide btn--sm">
                      {t('launch_3d_view', 'Launch 3D-View')}
                    </button>
                  </OpenWindowsClientLink>
                )}
                <div className="top-header_row-right">
                  <CustomSelect
                    id="StatusAsideIssueDropdown"
                    values={availableIssueStatuses}
                    onChange={handleChangeStatus}
                    value={status}
                    isStatus
                    isDisabled={!canEditIssueStatus}
                    isHideSelectedOptions
                    isModel
                  />
                  <CloseButton
                    id="CloseAsideIssueButton"
                    onClick={() => {
                      toggleAside('aside');
                      setEditIssue(null);
                      baseIssuesUrl && navigate(`${baseIssuesUrl}`);
                    }}
                    topPosition="39px"
                    rightPosition="20px"
                    classNames="position-static"
                  />
                </div>
              </div>
              <div
                style={{
                  justifyContent: isDocumentPreview
                    ? 'space-between'
                    : 'normal',
                }}
              >
                {canEditTag && (
                  <div className="issues-overview-modal--tags mobile--hide">
                    <TagSelect
                      id="TagsEditIssueSelect"
                      values={tagOptions}
                      onChange={(item) => handleSelectTags(item, issue.id)}
                      value={tags}
                      isBackgroundVisible={!(tags.length > 0)}
                      visibleValueCount={isModelIssues ? 1 : 2}
                    />
                  </div>
                )}
                <span className="issue-source">
                  <b>
                    {t('source', 'Source')}
                    :
                  </b>
                  {' '}
                  {issue.source}
                </span>
                <CopyLink id="CopyLinkAsideIssueLink" issueId={issue.id} />
              </div>
            </div>
            <IssueBoardColumnHeaderOld
              issue={issue}
              priorities={priorities}
              types={types}
              workphases={workphases}
              isIssueDetail
              isModelIssues={isModelIssues}
            />
            <div className="aside__accordion-content">
              <div className="issue-explanation issue-content-column">
                <div
                  className="accordion-title"
                  onClick={() => handleChangeExpand('explanation')}
                >
                  <h3>{t('explanation', 'Explanation')}</h3>
                  <i
                    className={`arrow ${
                      isExpanded.explanation ? 'arrow-up' : 'arrow-down'
                    }`}
                  />
                </div>
                {isExpanded.explanation && !!issue.description && (
                  <MentionView value={issue.description} isRichText />
                )}
              </div>
              <div className="issue-model issue-content-column">
                <div
                  className="accordion-title"
                  onClick={() => handleChangeExpand('model')}
                >
                  <h3 className="width--30">{t('model', 'Model')}</h3>
                  <div className="width--60" />
                  <i
                    className={`arrow ${
                      isExpanded.model ? 'arrow-up' : 'arrow-down'
                    }`}
                  />
                </div>
                {isExpanded.model && (
                  <div className="accordion-content issue-model-content">
                    <div className="width--30">
                      <p className="accordion-content_title">
                        {t('discipline', 'Discipline')}
                      </p>
                      {(issue.disciplines || []).map((item, index) => (
                        <div
                          key={`discipline-${index}`}
                          className="label"
                          style={{
                            backgroundColor:
                              issueDisciplineColors[index]?.background,
                            color: issueDisciplineColors[index]?.color,
                          }}
                        >
                          {item.abbreviation || ''}
                        </div>
                      ))}
                    </div>
                    <div className="width--30">
                      <p className="accordion-content_title">
                        {t('building', 'Building')}
                      </p>
                      {(issue.buildings || []).map((item, index) => (
                        <div
                          key={`building-${index}`}
                          className="label bg-light-grey"
                        >
                          {item.abbreviation || ''}
                        </div>
                      ))}
                    </div>
                    <div className="width--30">
                      <p className="accordion-content_title">
                        {t('floor', 'Floor')}
                      </p>
                      {(issue.floors || []).map((item, index) => (
                        <div
                          key={`floor-${index}`}
                          className="label bg-light-grey"
                        >
                          {item.abbreviation || ''}
                        </div>
                      ))}
                    </div>
                    <div className="w-space" />
                  </div>
                )}
              </div>
              <div className="issue-content-column issue-assigned">
                <div
                  className="accordion-title"
                  onClick={() => handleChangeExpand('assigned')}
                >
                  <h3 className="width--30">
                    {t('assigned_to', 'Assigned to')}
                  </h3>
                  <h3 className="width--60 accordion-title_label">
                    {`${
                      issue.assignedUsers?.length || 0
                    } ${t('collaborators', 'Collaborators')}`}

                  </h3>
                  <i
                    className={`arrow ${
                      isExpanded.assigned ? 'arrow-up' : 'arrow-down'
                    }`}
                  />
                </div>
                {isExpanded.assigned && (
                  <>
                    <div className="accordion-inner-title">
                      <p className="width--30">{t('responsible_worker')}</p>
                      <p className="width--60">{t('reviewer', 'Reviewer')}</p>
                      <div className="rest-space" />
                    </div>
                    <AssignItem issue={issue} />
                  </>
                )}
              </div>
              <div className="issue-content-column">
                <div
                  className="accordion-title"
                  onClick={() => handleChangeExpand('viewpoint')}
                >
                  <h3 className="width--30">{t('viewpoints', 'Viewpoints')}</h3>
                  <h3 className="width--60 accordion-title_label">
                    {`${
                      viewpoints?.length
                    } ${t('viewpoints', 'Viewpoints')}`}

                  </h3>
                  <i
                    className={`arrow ${
                      isExpanded.viewpoint ? 'arrow-up' : 'arrow-down'
                    }`}
                  />
                </div>
                {isExpanded.viewpoint && !!viewpoints?.length && (
                  <div className="accordion-content issue-viewpoint-content">
                    {viewpoints.map((item, index) => (
                      <AsideIssueViewpoint
                        key={`viewpoint-${index}`}
                        image={item.image}
                        isDisableFullScreen={isModelIssues}
                        data={item.data}
                        openViewpoint={openViewpointIn3D}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="issue-content-column">
                <div
                  className="accordion-title"
                  onClick={() => handleChangeExpand('elements')}
                >
                  <h3 className="width--30">{t('elements', 'Elements')}</h3>
                  <h3 className="width--60 accordion-title_label">
                    {`${
                      issue.linkedComponentsGlobalIds?.length || 0
                    } ${t('elements', 'Elements')}`}

                  </h3>
                  <i
                    className={`arrow ${
                      isExpanded.elements ? 'arrow-up' : 'arrow-down'
                    }`}
                  />
                </div>
                {isExpanded.elements
                  && (!!issue?.linkedComponentsGlobalIds?.length
                    || !!components?.length) && (
                    <div className="accordion-content issue-bim-model-content">
                      {issue.linkedComponentsGlobalIds.map((gbId, index) => {
                        const comp = components
                          ? components.find(
                            (component) => gbId === component.globalId,
                          )
                          : null;
                        return comp ? (
                          <p
                            key={`element-${index}`}
                          >
                            {`${comp.elementName} (${comp.globalId})`}

                          </p>
                        ) : (
                          <p key={`element-${index}`}>{gbId}</p>
                        );
                      })}
                    </div>
                )}
              </div>
              <div className="issue-content-column">
                <div
                  className="accordion-title"
                  onClick={() => handleChangeExpand('bimModel')}
                >
                  <h3 className="width--30">{t('bim_models', 'BIM-Models')}</h3>
                  <h3 className="width--60 accordion-title_label">
                    {`${
                      issue.models?.length
                    } ${t('bim_models', 'BIM-Models')}`}

                  </h3>
                  <i
                    className={`arrow ${
                      isExpanded.bimModel ? 'arrow-up' : 'arrow-down'
                    }`}
                  />
                </div>
                {isExpanded.bimModel && !!issue?.models?.length && (
                  <div className="accordion-content issue-bim-model-content">
                    {issue.models.map((item, index) => (
                      <p key={`bim-model-${index}`}>{item}</p>
                    ))}
                  </div>
                )}
              </div>
              <div className="issue-content-column">
                <div
                  className="accordion-title"
                  onClick={() => handleChangeExpand('document')}
                >
                  <h3 className="width--30">{t('documents', 'Documents')}</h3>
                  <h3 className="width--60 accordion-title_label">
                    {`${
                      documents?.length
                    } ${t('documents', 'Documents')}`}

                  </h3>
                  <i
                    className={`arrow ${
                      isExpanded.document ? 'arrow-up' : 'arrow-down'
                    }`}
                  />
                </div>
                {isExpanded.document && (
                  <div className="accordion-content link-documents-content custom-scrollbar">
                    {documents.map((item, index) => (
                      <LinkedDocumentItem
                        key={`doc-${index}`}
                        issue={issue}
                        document={item}
                        setAllIssueLogs={setAllIssueLogs}
                        isDocumentPreview={isDocumentPreview}
                        pdfAnnotations={pdfAnnotations}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="issue-content-column column-document">
                <div
                  className="accordion-title"
                  onClick={() => handleChangeExpand('email')}
                >
                  <h3 className="width--30">{t('emails', 'Emails')}</h3>
                  <h3 className="width--60 accordion-title_label">
                    {`${
                      linkedEmails?.length
                    } ${t('emails', 'Emails')}`}

                  </h3>
                  <i
                    className={`arrow ${
                      isExpanded.email ? 'arrow-up' : 'arrow-down'
                    }`}
                  />
                </div>
                {isExpanded.email && (
                  <div className="accordion-content link-documents-content custom-scrollbar">
                    {linkedEmails.map((item, index) => (
                      <div className="link-email-item" key={`email-${index}`}>
                        <p
                          className="link-email-item_name text-ellipsis"
                          onClick={() => {
                            setEmailPreviewModal(
                              EmailService.AsideVisibleStatus.DetailVisible,
                            );
                            setSelectedEmail(item);
                          }}
                        >
                          {item.subject}
                        </p>
                        <p className="link-email-item_sender text-ellipsis">
                          {`${t('send_by', 'Send by')} ${item.from}`}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <LinkedTasks
                issue={issue}
                statuses={statuses}
                types={types}
                allIssues={allIssues}
                isIssueDetail
                setCurrentIssue={setCurrentIssue}
              />
            </div>
            <IssueComments
              issue={issue}
              inputComment={inputComment}
              comments={comments}
              users={users}
              disciplines={disciplines}
              floors={floors}
              buildings={buildings}
              workphases={workphases}
              allDocuments={allDocuments}
              tagOptions={tagOptions}
              isVisibleHistory={isVisibleHistory}
              statuses={statuses}
              priorities={priorities}
              types={types}
              allIssues={allIssues}
              toggleHistory={toggleHistory}
              handleCommentChange={handleCommentChange}
              addComment={addComment}
            />
          </div>
        </div>
      </aside>
      {!!emailPreviewModal && (
        <div className="emails-content modal-tags">
          <div className="content">
            <Modal>
              <AsideEmailDetail
                email={selectedEmail}
                issues={allIssues}
                show_aside={
                  emailPreviewModal
                  === EmailService.AsideVisibleStatus.DetailVisible
                }
                statuses={statuses}
                types={types}
                setAsideVisibleStatus={setEmailPreviewModal}
                isTagsSearch
                setSelectedIssue={setCurrentIssue}
              />
            </Modal>
          </div>
        </div>
      )}
      {!!issueLogs?.length && (
        <div className={isDocumentPreview ? 'preview_doc-log' : ''}>
          <DocumentLogDialog
            logs={issueLogs}
            issue={issue}
            statuses={statuses}
            onClose={() => setIssueLogs([])}
            setIsDocLog={setIsDocLog}
            isDocumentPreview={isDocumentPreview}
          />
        </div>
      )}
    </DocumentScopeContextMemoryProvider>
  );
}

AsideIssue.propTypes = {
  show_aside: PropTypes.bool,
  statuses: PropTypes.array,
  priorities: PropTypes.array,
  types: PropTypes.array,
  issue: PropTypes.object,
  toggleAside: PropTypes.func,
  DropdownIndicator: PropTypes.func,
  updateStatus: PropTypes.func,
  isDocumentPreview: PropTypes.bool,
};

function DocumentDetailsModalWrapper() {
  const { documentVersionId } = useDocumentScopeContext();
  return documentVersionId ? <Portal><DocumentDetailsModal /></Portal> : null;
}
