import React, { useEffect, useMemo } from 'react';
import {
  Tabs, Tab, Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';
import DocumentDetailsTab from 'documents-details/types/DocumentDetailsTab';
import LinkedIssuesTab from 'documents-details/components/LinkedIssuesTab';
import InternalAnnotationsTab from 'documents-details/components/InternalAnnotationsTab';
import DocumentCommentsTab from 'documents-details/components/DocumentCommentsTab';
import useDocumentScopeContext from 'documents/hooks/useDocumentScopeContext';

interface DocumentsDetailsTabsProps extends ISxProps {
}

export default function DocumentsDetailsTabs({
  sx,
}: DocumentsDetailsTabsProps) {
  const { t } = useTranslation('documents-details');
  const { activeTab, setActiveTab, selectedAnnotationNames, annotationItems } = useDocumentViewerContext();
  const { highlightedDocumentCommentId, setHighlightedDocumentCommentId } = useDocumentScopeContext();
  const internalAnnotationNames = useMemo(() => (annotationItems ? new Set(annotationItems.filter((item) => !item.visoplanAnnotation).map((item) => item.viewerAnnotation.Id)) : undefined), [annotationItems]);
  const onChangeTab = (event: React.SyntheticEvent, newValue: DocumentDetailsTab) => {
    setActiveTab(newValue);
  };
  useEffect(() => {
    if (internalAnnotationNames && selectedAnnotationNames?.length) {
      if (selectedAnnotationNames.every((name) => internalAnnotationNames.has(name))) {
        setActiveTab(DocumentDetailsTab.InternalAnnotations);
      } else if (selectedAnnotationNames.every((name) => !internalAnnotationNames.has(name))) {
        setActiveTab(DocumentDetailsTab.LinkedIssues);
      }
    }
  }, [internalAnnotationNames, selectedAnnotationNames, setActiveTab]);

  useEffect(() => {
    if (highlightedDocumentCommentId !== undefined) {
      setActiveTab(DocumentDetailsTab.Comments);
    }
  }, [highlightedDocumentCommentId, setActiveTab, setHighlightedDocumentCommentId]);

  return (
    <Box
      id="DocumentsDetailsTabs"
      sx={{
        ...sx, display: 'flex', flexDirection: 'column', overflow: 'hidden',
      }}
    >
      <Box>
        <Tabs value={activeTab} onChange={onChangeTab} variant="fullWidth">
          <Tab value={DocumentDetailsTab.LinkedIssues} label={t('tabs_linked-issues-label', 'Issues')} />
          <Tab value={DocumentDetailsTab.InternalAnnotations} label={t('tabs_internal-annotations-label', 'Annotations')} />
          <Tab value={DocumentDetailsTab.Comments} label={t('tabs_comments-label', 'Comments')} />
        </Tabs>
      </Box>
      <Box sx={{
        flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', boxShadow: 'inset 0px 24px 24px -24px rgba(0,0,0,0.1)',
      }}
      >
        {activeTab === DocumentDetailsTab.LinkedIssues && <LinkedIssuesTab sx={{ flexGrow: 1 }} />}
        {activeTab === DocumentDetailsTab.InternalAnnotations && <InternalAnnotationsTab sx={{ flexGrow: 1 }} />}
        {activeTab === DocumentDetailsTab.Comments && <DocumentCommentsTab sx={{ flexGrow: 1 }} />}
      </Box>
    </Box>
  );
}
