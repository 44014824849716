import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

enum QueryFilterVisibility {
  Private,
  Public,
}

export interface SaveFilterDialogResult {
  name: string,
  isPrivate: boolean,
}

interface SaveFilterDialogProps {
  open: boolean,
  onClose: (result: SaveFilterDialogResult | undefined) => void,
}

export default function SaveFilterDialog({
  open,
  onClose,
}: SaveFilterDialogProps) {
  const { t } = useTranslation('documents-filter');
  const [name, setName] = useState('');
  const [visibility, setVisibility] = useState<QueryFilterVisibility>(QueryFilterVisibility.Public);

  const canConfirm = useMemo(() => name?.length > 0, [name]);

  const onConfirm = useCallback(() => {
    onClose({
      name,
      isPrivate: visibility === QueryFilterVisibility.Private,
    });
  }, [name, onClose, visibility]);

  const onCancel = useCallback(() => {
    onClose(undefined);
  }, [onClose]);

  return (
    <Dialog open={open}>
      <DialogTitle id="documents-filter_save-modal_header">
        {t('filter-drawer_save-modal_header', 'Save Filter')}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ py: 2, display: 'flex', alignItems: 'flex-end' }}>
          <TextField
            id="documents-filter_save-modal_filter-name-label"
            label={t('filter-drawer_save-modal_filter-name-label', 'Filter Name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ width: 200, mr: 6, mb: '2px' }}
          />
          <FormControl>
            <Typography id="documents-filter_save-modal_filter-query-visibility-header" variant="h5">{t('filter-drawer_save-modal_filter-query-visibility-header', 'Visibility')}</Typography>
            <RadioGroup
              row
              aria-labelledby="documents-filter_save-modal_filter-query-visibility-header"
              value={visibility}
              onChange={(e) => setVisibility(parseInt(e.target.value, 10))}
              name="radio-buttons-group"
            >
              <FormControlLabel value={QueryFilterVisibility.Public} control={<Radio />} label={t('filter-drawer_save-modal_filter-query-visibility-public', 'Public')} />
              <FormControlLabel value={QueryFilterVisibility.Private} control={<Radio />} label={t('filter-drawer_save-modal_filter-query-visibility-private', 'Private')} />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button id="documents-filter_save-modal_cancel" onClick={onCancel} variant="contained" color="secondary" sx={{ mr: 'auto' }}>
          {t('filter-drawer_save-modal_cancel', 'Cancel')}
        </Button>
        <Button id="documents-filter_save-modal_confirm" onClick={onConfirm} variant="contained" color="primary" disabled={!canConfirm}>
          {t('filter-drawer_save-modal_confirm', 'Save Filter')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
