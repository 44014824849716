import React from 'react';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import { useTranslation } from 'react-i18next';
import { mdiAccountHardHat, mdiAccountCheck } from '@mdi/js';
import Icon from '@mdi/react';
import IssueAssignmentActionButtons from 'issues/components/IssueAssignmentActionButtons';
import ReviewActionButtons from 'issues/components/ReviewActionButtons';
import PublishActionButtons from 'issues/components/PublishActionButtons';

interface DocumentDetailsGeneralSectionProps {
  issueId: string,
  onClickCompleteAssignment: () => void,
  onClickRejectAssignment: () => void,
  onClickRejectReview: () => void,
  onClickApproveReview: () => void,
  onClickPublish: () => void,
  nonInteractive?: boolean | undefined,
}

export default function IssueResponsibilityPanel({
  issueId,
  onClickCompleteAssignment,
  onClickRejectAssignment,
  onClickRejectReview,
  onClickApproveReview,
  onClickPublish,
  nonInteractive,
}: DocumentDetailsGeneralSectionProps) {
  const theme = useTheme();
  const { t } = useTranslation('issues');
  const { data: issue } = useIssueQuery(issueId);
  if (!issue) return null;
  return (
    <Box id="IssueResponsibilityPanel">
      <Box sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.3)',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
      >
        <Typography variant="h4">{t('issue-responsibility-panel_header', 'Responsibility')}</Typography>
        {!nonInteractive && (
          <>
            <IssueAssignmentActionButtons issueId={issueId} onClickCompleteAssignment={onClickCompleteAssignment} onClickRejectAssignment={onClickRejectAssignment} />
            <ReviewActionButtons issueId={issueId} onClickRejectReview={onClickRejectReview} onClickApproveReview={onClickApproveReview} />
            <PublishActionButtons issueId={issueId} onClickPublish={onClickPublish} />
          </>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {!!issue.assignedUsers?.length && issue.assignedUsers.map((assignedUser) => (
            <Box key={assignedUser.id} sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              <Box sx={{ backgroundColor: theme.palette.grey[200], borderRadius: 99, width: 36, height: 36, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Icon path={mdiAccountHardHat} size={0.8} />
              </Box>
              <Box>
                <Typography sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: 1.25,
                }}
                >
                  {`${assignedUser.firstName} ${assignedUser.lastName}`}
                </Typography>
                <Typography sx={{ color: theme.palette.grey[800], lineHeight: 1.25 }}>{t('linked-issue-item_assignee-label', 'Assigned')}</Typography>
              </Box>
            </Box>
          ))}
          {(issue?.assignedUsers?.length === 0) && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              <Box sx={{ backgroundColor: theme.palette.grey[100], borderRadius: 99, width: 36, height: 36, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Icon path={mdiAccountHardHat} size={0.8} color={theme.palette.grey[600]} />
              </Box>
              <Box>
                <Typography sx={{
                  fontSize: 14,
                  lineHeight: 1.25,
                  color: theme.palette.grey[600],
                }}
                >
                  {t('issue-responsibility-panel_unassigned-label', 'Not assigned')}
                </Typography>
              </Box>
            </Box>
          )}
          {!!issue.assignedUsers?.length && !!issue.reviewer && (
            <Divider />
          )}
          {!!issue.reviewer && (
            <Box key={issue.reviewer.id} sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              <Box sx={{ backgroundColor: theme.palette.grey[200], borderRadius: 99, width: 36, height: 36, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Icon path={mdiAccountCheck} size={0.8} />
              </Box>
              <Box>
                <Typography sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: 1.25,
                }}
                >
                  {`${issue.reviewer.firstName} ${issue.reviewer.lastName}`}
                </Typography>
                <Typography sx={{ color: theme.palette.grey[800], lineHeight: 1.25 }}>{t('linked-issue-item_reviewer-label', 'Reviewer')}</Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
