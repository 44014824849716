import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../Helpers/Hooks/useWindowDimensions";

export const ChangePositionLine = ({
  canChangePosition,
  canChangeWidth,
  setResized,
  resized,
  isChangedPositions,
  saveNewView,
  droppedColumns,
  cancel,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [widthOfRightSide, setWidthOfRightSide] = useState(width - 240);
  useEffect(() => {
    if (isChangedPositions) setWidthOfRightSide(width - 480);
    if (canChangeWidth) setWidthOfRightSide(width - 340);
    if (!isChangedPositions && !canChangeWidth)
      setWidthOfRightSide(width - 255);
  }, [canChangeWidth, isChangedPositions]);
  return (
    (canChangePosition || canChangeWidth) && (
      <div
        className={"change-positions"}
        onMouseOver={() => setResized(!resized)}
        onMouseLeave={() => setResized(!resized)}
      >
        {canChangePosition ? t("order", "Order") : t("resize", "Resize")}
        <div className="right-side" style={{ left: widthOfRightSide }}>
          <div className={"right-side__text"}>
            {canChangePosition
              ? t(
                  "You_can_change_the_order_via_drag_&_drop",
                  "You can change the order via drag & drop"
                )
              : t(
                  "You_can_change_the_width_via_moving",
                  "You can change the width via moving"
                )}
          </div>
          {isChangedPositions && (
            <div className={"right-side__buttons"}>
              <div
                className={"right-side__buttons--cancel"}
                onClick={() => cancel()}
              >
                {t("cancel", "Cancel")}
              </div>
              <div
                className={"right-side__buttons--order"}
                onClick={() => saveNewView(droppedColumns)}
              >
                {t("save_order", "Save order")}
              </div>
            </div>
          )}
          {canChangeWidth && (
            <div className={"right-side__buttons"}>
              <div
                className={"right-side__buttons--order"}
                onClick={() => saveNewView(droppedColumns)}
              >
                {t("save_width", "Save width")}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};
