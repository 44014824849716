import React from 'react';
import classNames from 'classnames';

import { IssueItem } from 'issues/components/IssueItem';

import IssueOld from 'issues/types/IssueOld';

interface IssueListProps {
  prefix: string;
  loadedIssues: IssueOld[];
  issues: IssueOld[];
  subIssues: { [key: string]: IssueOld[] };
  isChild?: boolean;
  columns: any;
  row_events: () => void;
  selectedIssueTree: string[];
  resized: boolean;
  setResized: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function IssueList(props: IssueListProps) {
  const {
    prefix,
    loadedIssues,
    issues,
    subIssues,
    isChild,
    columns,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    row_events,
    selectedIssueTree,
    resized,
    setResized,
  } = props;

  const getChevronIconName = (issue: IssueOld) => {
    if (issue?.childrenIds?.length) {
      return selectedIssueTree.includes(issue.id) ? 'arrow-hierarchy-down' : 'arrow-hierarchy-right';
    }
    return '';
  };

  const checkVisibleChildrenIssueTree = (issue: IssueOld) => (
    subIssues[issue.id] && subIssues[issue.id].length && selectedIssueTree.includes(issue.id)
  );

  return (
    <div className={classNames(
      'folders-tree--block--list',
      isChild && 'sub-issue',
    )}
    >
      {issues.map((issue, index) => (
        <div className="folders-tree--block--list--item" key={issue.id}>
          <IssueItem
            prefix={prefix}
            issue={issue}
            issues={loadedIssues}
            icon={getChevronIconName(issue)}
            columns={columns}
            index={index}
            row_events={row_events}
            setResized={setResized}
            resized={resized}
            selectFolder={undefined}
          />
          {checkVisibleChildrenIssueTree(issue) && (
            <IssueList
              // Because this props is clear, I used props spreading
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              isChild
              issues={subIssues[issue.id]}
            />
          )}
        </div>
      ))}
    </div>
  );
}

IssueList.defaultProps = {
  isChild: false,
};
