import React from "react";

import DisciplineAbbreviation from "./DisciplineAbbreviation";

const DisciplineMenu = ({ disciplines }) => {
  return (
    <div className="discipline-menu custom-scrollbar">
      {disciplines.map((item, index) => (
        <div
          key={`discipline-abbreviation-${index}`}
          className="discipline-menu-item"
        >
          <DisciplineAbbreviation discipline={item} index={index} />
          <p className="discipline-menu-item-name">{item.name}</p>
        </div>
      ))}
    </div>
  );
};

export default DisciplineMenu;
