import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import DocumentFilterState from 'documents-filter/types/DocumentFilterState';
import useQueryFilterQuery from 'documents-filter/hooks/useQueryFilterQuery';
import QueryFilter from 'documents-filter/types/QueryFilter';
import useQueryFilterCreateMutation from 'documents-filter/hooks/useQueryFilterCreateMutation';
import useQueryFilterUpdateMutation from 'documents-filter/hooks/useQueryFilterUpdateMutation';
import useDocumentFilterConverter from 'documents-filter/hooks/useDocumentFilterConverter';
import useQueryFilterDeleteMutation from 'documents-filter/hooks/useQueryFilterDeleteMutation';
import DocumentFilterPersistableState from 'documents/types/DocumentFilterPersistableState';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';

const emptyFilterState: Readonly<DocumentFilterState> = {
  disciplineMetaDataIds: [],
  buildingMetaDataIds: [],
  floorMetaDataIds: [],
  workPhaseIds: [],
  creatorCollaboratorIds: [],
  editorCollaboratorIds: [],
  fileTypes: [],
  tagIds: [],
  documentStatusIds: [],
  creationDateRange: undefined,
  folderId: undefined,
  includeSubfolders: false,
  linkedIssuesScope: undefined,
  uploadDateRange: undefined,
  versionNumber: undefined,
  tagComparisonMode: undefined,
};

interface UseDocumentFilterPersistableStateProps {
  filterId: string | undefined,
}

export default function useDocumentFilterPersistableState({
  filterId,
}: UseDocumentFilterPersistableStateProps) {
  const { data: projectQueryFilters, isLoading: isLoadingProjectQueryFilters } = useQueryFilterQuery();
  const { mutateAsync: createQueryFilter } = useQueryFilterCreateMutation();
  const { mutateAsync: updateQueryFilter } = useQueryFilterUpdateMutation();
  const { mutate: deleteQueryFilters } = useQueryFilterDeleteMutation();
  const projectId = useCurrentProjectId();
  const projectQueryFiltersById = useMemo(() => new Map<string | undefined, QueryFilter>(projectQueryFilters?.map((f) => [f.id, f])), [projectQueryFilters]);
  const { getCreateDtoFromFilterState, getUpdateDtoFromFilterState, getFilterStateFromDocumentFilterSettings } = useDocumentFilterConverter();

  const loadedQueryFilter = useMemo(() => projectQueryFiltersById.get(filterId), [projectQueryFiltersById, filterId]);
  const [loadedQueryFilterOriginalFilterState, setLoadedQueryFilterOriginalFilterState] = useState<DocumentFilterState | undefined>(undefined);

  const [stagedFilterState, setStagedFilterState] = useState<DocumentFilterState>(emptyFilterState);
  const [appliedFilterState, setAppliedFilterState] = useState<DocumentFilterState>(emptyFilterState);
  const isAppliedFilterStateEmpty = useMemo(() => _.isEqual(emptyFilterState, appliedFilterState), [appliedFilterState]);

  useEffect(() => {
    if (isLoadingProjectQueryFilters) return;
    if (loadedQueryFilter?.uiFilterSettings) {
      const nextFilterState = getFilterStateFromDocumentFilterSettings(loadedQueryFilter.uiFilterSettings.documentFilterSettingsEntity);
      setLoadedQueryFilterOriginalFilterState(nextFilterState);
      setStagedFilterState(nextFilterState);
      setAppliedFilterState(nextFilterState);
    } else {
      setLoadedQueryFilterOriginalFilterState(undefined);
      setStagedFilterState(emptyFilterState);
      setAppliedFilterState(emptyFilterState);
    }
  }, [getFilterStateFromDocumentFilterSettings, loadedQueryFilter, isLoadingProjectQueryFilters]);

  const loadedQueryFilterHasLocalEdits = useMemo(() => !!loadedQueryFilter && !_.isEqual(stagedFilterState, loadedQueryFilterOriginalFilterState), [stagedFilterState, loadedQueryFilter, loadedQueryFilterOriginalFilterState]);

  const saveQueryFilterAsNew = useCallback(async (name: string, isPrivate: boolean) => {
    if (!projectId) return undefined;
    const createDto = getCreateDtoFromFilterState(stagedFilterState, projectId, name, isPrivate);
    const createdEntity = await createQueryFilter(createDto);
    return createdEntity;
  }, [projectId, getCreateDtoFromFilterState, stagedFilterState, createQueryFilter]);

  const saveLocalEditsToLoadedQueryFilter = useCallback(() => {
    if (!loadedQueryFilter?.id || !loadedQueryFilterHasLocalEdits || !projectId) return;
    setLoadedQueryFilterOriginalFilterState(stagedFilterState);
    const updateDto = getUpdateDtoFromFilterState(loadedQueryFilter.id, stagedFilterState, projectId, loadedQueryFilter.name ?? '', loadedQueryFilter.isPrivate);
    updateQueryFilter(updateDto);
  }, [loadedQueryFilter, loadedQueryFilterHasLocalEdits, projectId, stagedFilterState, getUpdateDtoFromFilterState, updateQueryFilter]);

  const resetFilterState = useCallback(() => {
    setStagedFilterState(emptyFilterState);
    setAppliedFilterState(emptyFilterState);
  }, []);

  const deleteLoadedQueryFilter = useCallback(() => {
    if (!projectId || !loadedQueryFilter?.id) return;
    deleteQueryFilters(loadedQueryFilter.id);
    resetFilterState();
  }, [deleteQueryFilters, loadedQueryFilter, projectId, resetFilterState]);

  const applyStagedFilterState = useCallback(() => {
    setAppliedFilterState(stagedFilterState);
  }, [stagedFilterState]);

  return useMemo<DocumentFilterPersistableState>(() => ({
    stagedFilterState,
    setStagedFilterState,
    appliedFilterState,
    applyStagedFilterState,
    resetFilterState,
    loadedQueryFilter,
    loadedQueryFilterHasLocalEdits,
    saveQueryFilterAsNew,
    saveLocalEditsToLoadedQueryFilter,
    deleteLoadedQueryFilter,
    isAppliedFilterStateEmpty,
  }), [appliedFilterState, applyStagedFilterState, deleteLoadedQueryFilter, isAppliedFilterStateEmpty, loadedQueryFilter, loadedQueryFilterHasLocalEdits, resetFilterState, saveLocalEditsToLoadedQueryFilter, saveQueryFilterAsNew, stagedFilterState]);
}
