import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

// import useWindowDimensions from "../../Helpers/Hooks/useWindowDimensions";
import IssueItemOld from "./IssueItemOld";
import CustomSelect from "../CustomSelect";
import SearchInput from "../SearchInput";

const IssueBoardColumnOld = ({
  id,
  group,
  name,
  data,
  types,
  loadMore,
  baseIssuesUrl,
  viewIssue,
  setShowLinkedIssues,
  isLinkedIssues,
  isModelIssues,
  statuses,
  setPreviewIssue,
  selectedIssue,
  setStatus,
  status,
  searchText,
  setSearchText,
  filterQuery,
  isFilterActiveVisible = false,
  resetFilters,
}) => {
  const { t } = useTranslation();
  // const { width } = useWindowDimensions();

  const handleScroll = (event) => {
    if (isLinkedIssues) return;
    if (
      Math.abs(
        event.target.scrollHeight -
          event.target.scrollTop -
          event.target.clientHeight
      ) < 10
    ) {
      if (isModelIssues) {
        loadMore(filterQuery, true);
      } else {
        loadMore(
          {
            ...group,
            group: name,
            skip: (
              data.filter((issue) => issue.issueStatus.id === group.id) || []
            ).length,
          },
          true
        );
      }
    }
  };

  return (
    <div className="issue-board__column" id={id}>
      <div className="issue-board__column__title">
        <strong>{t(name)}:</strong>
        {name === "status" ? (
          <label
            className="status"
            style={{
              color: `${group?.color}`,
              backgroundColor: `${group?.color}30`,
            }}
          >
            {group.name}
          </label>
        ) : (
          group.name
        )}
        {isLinkedIssues ? (
          <div className="issue-board__column__title__right">
            {isFilterActiveVisible && (
              <div
                className={"active_filter_label"}
                onClick={() => resetFilters()}
              >
                {`${t("filter_active", "Filter Active")} X`}
              </div>
            )}
            <div  
              id="CloseLinkedIssuesButton"
              className="close-button"
              onClick={() => {
                setShowLinkedIssues(false);
                setStatus(
                  statuses.filter((status) => status.originalName !== "Closed")
                );
              }}
            >
              {t("close", "Close")}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {isModelIssues ? (
        <SearchInput 
          id="SearchLinkedIssueInput"
          placeholder={t(
            "search_issue_title_explanation",
            "Search Issue title or explanation..."
          )}
          value={searchText}
          onChange={(e) =>
            e ? setSearchText(e.target.value) : setSearchText("")
          }
          isAddIcon={true}
        />
      ) : null}
      {isLinkedIssues ? (
        <div className="issues-filter_group-item">
          {t(
            "select_issue_and_view_annotation",
            "Select issue and view annotation"
          )}
          <CustomSelect
            id="CustomSelectLinkedIssueDropdown"
            values={statuses}
            isDisabled={false}
            onChange={setStatus}
            value={status}
            isMulti={true}
            isOptional={true}
            isModel={true}
            name="status"
          />
        </div>
      ) : (
        ""
      )}
      <div
        className="issue-board__column__wrapper custom-scrollbar"
        onScroll={handleScroll}
      >
        {isLinkedIssues || isModelIssues ? (
          <>
            {(data || []).map((issue, index) => {
              if (issue)
                return (
                  <IssueItemOld
                    key={`issue-item-${issue.id}`}
                    index={index}
                    issue={issue}
                    types={types}
                    baseIssuesUrl={baseIssuesUrl}
                    viewIssue={viewIssue}
                    setPreviewIssue={setPreviewIssue}
                    selectedIssue={selectedIssue}
                    isLinkedIssues={isLinkedIssues}
                    isModelIssues={isModelIssues}
                  />
                );
            })}
          </>
        ) : (
          <Droppable droppableId={`${group.value}`}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {(data || []).map((issue, index) => (
                  <>
                    {!!issue && (
                      <IssueItemOld
                        key={`issue-item-${issue.id}`}
                        index={index}
                        issue={issue}
                        types={types}
                        baseIssuesUrl={baseIssuesUrl}
                        viewIssue={viewIssue}
                      />
                    )}
                  </>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </div>
    </div>
  );
};

export default IssueBoardColumnOld;
