import * as Request from "../Helpers/Request";

export async function getMetadataBuilding(ids) {
  const result = await Request.GET(`api/metaData/building/${ids}`);
  return result.status === 200 ? result.body : [];
}

export async function getMetadataDiscipline(ids) {
  const result = await Request.GET(`api/metaData/discipline/${ids}`);
  return result.status === 200 ? result.body : [];
}

export async function getMetadataFloor(ids) {
  const result = await Request.GET(`api/metaData/floor/${ids}`);
  return result.status === 200 ? result.body : [];
}

export async function createMetadataBuilding(body) {
  const result = await Request.POST("api/metaData/building", body);
  return result.status === 200 ? result.body : [];
}

export async function createMetadataDiscipline(body) {
  const result = await Request.POST("api/metaData/discipline", body);
  return result.status === 200 ? result.body : [];
}

export async function createMetadataFloor(body) {
  const result = await Request.POST("api/metaData/floor", body);
  return result.status === 200 ? result.body : [];
}

export async function editMetadataBuilding(body) {
  const result = await Request.PUT("api/metaData/building", body);
  return result.status === 200 ? result.body : [];
}

export async function editMetadataDiscipline(body) {
  const result = await Request.PUT("api/metaData/discipline", body);
  return result.status === 200 ? result.body : [];
}

export async function editMetadataFloor(body) {
  const result = await Request.PUT("api/metaData/floor", body);
  return result.status === 200 ? result.body : [];
}

export async function deleteMetadataBuilding(ids) {
  const result = await Request.DELETE("api/metaData/building", ids);
  return result.status === 200 ? result.body : [];
}

export async function deleteMetadataDiscipline(ids) {
  const result = await Request.DELETE("api/metaData/discipline", ids);
  return result.status === 200 ? result.body : [];
}

export async function deleteMetadataFloor(ids) {
  const result = await Request.DELETE("api/metaData/floor", ids);
  return result.status === 200 ? result.body : [];
}
