import React, { useCallback } from "react";

import AttachmentItemContentInfo from "./AttachmentItemContentInfo";

import { downloadFile } from "../../Helpers/utils";
import { ResourceService } from "../../Services";

export default function AttachmentItem({
  info,
  isChecked,
  handleCheckChange,
  isTagsSearch,
}) {
  const handleAttachmentDownload = useCallback(async () => {
    const result = await ResourceService.getResource(info.fileId);
    downloadFile(result, info.originalFileName || info.name);
  }, [info]);

  return (
    <div className="attachment-item">
      {!isTagsSearch ? (
        <input
          type="checkbox"
          className="attachment-item-select"
          checked={!!isChecked}
          value={!!isChecked}
          onChange={handleCheckChange}
        />
      ) : (
        ""
      )}
      <div className="attachment-item-content">
        <AttachmentItemContentInfo info={info} />
        <button
          id="DownloadAttachmentEmailDetailButton"
          className="btn btn--secondary btn-download"
          onClick={handleAttachmentDownload}
        >
          <svg className="icon">
            <use xlinkHref="/img/sprite.svg#download" />
          </svg>
        </button>
      </div>
    </div>
  );
}
