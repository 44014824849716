import React from "react";
import { useTranslation } from "react-i18next";

import Radio from "../Radio";

const TagOption = ({ isOverwriteTag, setIsOverwriteTag }) => {
  const { t } = useTranslation();
  return (
    <div className="tag-options">
      <Radio
        id="add_tag"
        label={t("add_plus", "+")}
        checked={!isOverwriteTag}
        handleChange={() => {
          setIsOverwriteTag(false);
        }}
      />
      <Radio
        id="overwrite_tag"
        label={t("overwrite", "Overwrite")}
        checked={isOverwriteTag}
        handleChange={() => {
          setIsOverwriteTag(true);
        }}
      />
    </div>
  );
};

export default TagOption;
