import { CameraProjections, NavigationModes } from "../Components/Models/Types/components";

//3D viewer setting
export const DEFAULT_SETTING = {
    control: NavigationModes.Mouse,
    camera: CameraProjections.Perspective,
    spaces: false,
    openings: false,
    grid: false,
    background: "#F2F3F5",
    navCube: true,
    sectionOutline: "#FFFF00",
    sectionSpace:"#FF0000",
  };