import React from "react";
import moment from "moment";
import Calendar from "react-calendar";
import { useTranslation } from "react-i18next";
import { ClickAwayListener } from '@mui/base';

export const DatePicker = ({
  id,
  setShowDateFromTo,
  date_from_to,
  setDateFromTo,
  showDateFromTo,
  phrase,
  definition,
  dataForReset,
}) => {
  const { t } = useTranslation();
  return (
    <ClickAwayListener id={id} onClickAway={() => setShowDateFromTo(false)}>
      <div>
        <label className="label" htmlFor="created_from">
          {t(phrase, definition)}
        </label>

        <div style={{ position: "relative" }}>
          <input
            className="input input--grey"
            type="text"
            id="created_from"
            value={
              date_from_to
                ? moment(new Date(date_from_to)).format("DD/MM/YYYY")
                : ""
            }
            onClick={() => setShowDateFromTo(true)}
            readOnly
          />

          {date_from_to ? (
            <svg
              className="icon input__clear"
              onClick={() =>
                dataForReset ? setDateFromTo("", dataForReset) : setDateFromTo("")
              }
            >
              <use xlinkHref="/img/sprite.svg#close" />
            </svg>
          ) : (
            ""
          )}
        </div>

        <Calendar
          onChange={(date) =>
            dataForReset
              ? setDateFromTo(date, dataForReset) & setShowDateFromTo(false)
              : setDateFromTo(date) & setShowDateFromTo(false)
          }
          value={date_from_to || new Date()}
          className={"calendar" + (showDateFromTo ? " calendar--open" : "")}
        />
      </div>
    </ClickAwayListener>
  );
};
