import React, {
  ReactNode, useCallback, useMemo, useState,
} from 'react';
import DocumentScopeContext, { DocumentScopeContextState } from 'documents/contexts/DocumentScopeContext';
import DocumentScope from 'documents/types/DocumentScope';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';

interface DocumentScopeContextMemoryProviderProps {
  children?: ReactNode,
}

export default function DocumentScopeContextMemoryProvider({
  children,
}: DocumentScopeContextMemoryProviderProps) {
  const [documentScope, setDocumentScope] = useState<DocumentScope>({ key: DocumentScopeKey.ProjectDocuments });
  const [documentVersionId, setDocumentVersionId] = useState<string | undefined>(undefined);
  const [issueId, setIssueId] = useState<string | undefined>(undefined);

  const setDocumentVersionAndIssueId = useCallback((nextDocumentVersionId: string | undefined, nextIssueId: string | undefined) => {
    setDocumentVersionId(nextDocumentVersionId);
    setIssueId(nextIssueId);
  }, []);
  const [highlightedDocumentCommentId, setHighlightedDocumentCommentId] = useState<string | undefined>(undefined);

  const state = useMemo<DocumentScopeContextState>(() => ({
    documentScope,
    setDocumentScope,
    documentVersionId,
    setDocumentVersionId,
    issueId,
    setIssueId,
    setDocumentVersionAndIssueId,
    highlightedDocumentCommentId,
    setHighlightedDocumentCommentId,
  }), [documentScope, documentVersionId, highlightedDocumentCommentId, issueId, setDocumentVersionAndIssueId]);

  return (
    <DocumentScopeContext.Provider value={state}>
      {children}
    </DocumentScopeContext.Provider>
  );
}
