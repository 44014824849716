import React, { useCallback, useRef, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import AddIcon from '@mui/icons-material/Link';
import { useTranslation } from 'react-i18next';
import LinkExistingIssueModal from 'issues/components/LinkExistingIssueModal';
import LinkNewIssueDialog from 'issues/components/LinkNewIssueDialog';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';
import useDocumentScopeContext from 'documents/hooks/useDocumentScopeContext';
import useDocumentVersionQuery from 'documents/hooks/useDocumentVersionQuery';
import useDocumentVersionNumberString from 'documents/hooks/useDocumentVersionNumberString';
import FileType from 'documents/types/FileType';
import useIssueUpdateMutation from 'issues/hooks/useIssueUpdateMutation';
import useIssueQueryData from 'issues/hooks/useIssueQueryData';
import { useQueryClient } from '@tanstack/react-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';

interface LinkIssueMenuButtonProps extends ISxProps {
}

export default function LinkIssueMenuButton({
  sx,
}: LinkIssueMenuButtonProps) {
  const { t } = useTranslation('documents-details');
  const queryClient = useQueryClient();
  const { baseQueryKey: documentVersionsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const { setIssueIdCurrentlyLinking } = useDocumentViewerContext();
  const { documentVersionId, setIssueId } = useDocumentScopeContext();
  const { data: documentVersion } = useDocumentVersionQuery(documentVersionId);
  const getIssueQueryData = useIssueQueryData();
  const { mutateAsync: updateIssue } = useIssueUpdateMutation();
  const getVersionNumberString = useDocumentVersionNumberString();
  const linkIssueButtonRef = useRef<HTMLButtonElement>(null);
  const [linkIssueSourceMenuOpen, setLinkIssueSourceMenuOpen] = useState<boolean>(false);

  const onCloseLinkIssueSourceMenu = useCallback(() => setLinkIssueSourceMenuOpen(false), []);

  const onClickLinkIssue = useCallback(() => {
    setLinkIssueSourceMenuOpen(true);
  }, []);

  const [linkExisingIssueDialogOpen, setLinkExistingIssueDialogOpen] = useState<boolean>(false);

  const onClickLinkExistingIssue = useCallback(() => {
    setLinkExistingIssueDialogOpen(true);
    setLinkIssueSourceMenuOpen(false);
  }, []);

  const onCloseLinkExistingIssueDialog = useCallback(async (selectedIssueId: string | undefined) => {
    if (!documentVersion || !documentVersionId) return;
    setLinkExistingIssueDialogOpen(false);
    if (documentVersion.fileType === FileType.Pdf || documentVersion.fileType === FileType.DWG || (documentVersion.fileType === FileType.Image && !documentVersion.originalFileName.endsWith('.svg'))) {
      setIssueIdCurrentlyLinking(selectedIssueId);
    } else if (selectedIssueId) {
      // if the viewer is currently not displaying a document (i.e. unsupported file format), immediately persist the link without an annotation
      const issue = await getIssueQueryData(selectedIssueId);
      await updateIssue({
        id: selectedIssueId,
        linkedDocumentVersionIds: { value: Array.from(new Set([...issue.linkedDocumentVersionIds, documentVersionId])) },
      });
      queryClient.invalidateQueries({ queryKey: documentVersionsQueryKey });
      setIssueId(selectedIssueId);
    }
  }, [documentVersion, documentVersionId, documentVersionsQueryKey, getIssueQueryData, queryClient, setIssueId, setIssueIdCurrentlyLinking, updateIssue]);

  const [linkNewIssueDialogOpen, setLinkNewIssueDialogOpen] = useState<boolean>(false);
  const onClickNewIssue = useCallback(() => {
    setLinkNewIssueDialogOpen(true);
    setLinkIssueSourceMenuOpen(false);
  }, []);

  const onCloseLinkNewIssueDialog = useCallback(async (createdIssueId: string | undefined, locate: boolean) => {
    if (!documentVersion || !documentVersionId) return;
    setLinkNewIssueDialogOpen(false);
    if (locate) {
      setIssueIdCurrentlyLinking(createdIssueId);
    } else if (createdIssueId) {
      await updateIssue({
        id: createdIssueId,
        linkedDocumentVersionIds: { value: [documentVersionId] },
      });
      setIssueId(createdIssueId);
    }
  }, [documentVersion, documentVersionId, setIssueId, setIssueIdCurrentlyLinking, updateIssue]);

  return (
    <>
      <Button id="LinkIssueMenuButton" onClick={onClickLinkIssue} ref={linkIssueButtonRef} variant="contained" color="primary" sx={{ ...sx, pl: 1.5, gap: 1 }}>
        <AddIcon />
        {t('link-issue-menu-button_link-issue-button-label', 'Link Issue')}
      </Button>
      <Menu
        id="LinkIssueMenuButton_Menu"
        anchorEl={linkIssueButtonRef.current}
        open={linkIssueSourceMenuOpen}
        onClose={onCloseLinkIssueSourceMenu}
      >
        <MenuItem onClick={onClickLinkExistingIssue}>{t('link-issue-menu-button_select-existing-issue-menu-item', 'Select existing issue')}</MenuItem>
        <MenuItem onClick={onClickNewIssue}>{t('link-issue-menu-button_create-new-issue-menu-item', 'Create new issue')}</MenuItem>
      </Menu>
      {!!linkExisingIssueDialogOpen && !!documentVersion && (
        <LinkExistingIssueModal
          onClose={onCloseLinkExistingIssueDialog}
          title={t('link-issue-menu-button', 'Link issue to "{{documentName}}" (Version {{versionNumber}})', { documentName: documentVersion.originalFileName, versionNumber: getVersionNumberString(documentVersion.versionNumber) })}
          documentVersionIds={[documentVersion.id]}
        />
      )}
      {!!linkNewIssueDialogOpen && !!documentVersionId && (
        <LinkNewIssueDialog onClose={onCloseLinkNewIssueDialog} documentVersionIds={[documentVersionId]} />
      )}
    </>
  );
}
