import React, { useCallback, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import PlanlistDataGrid from 'documents-lists/components/PlanlistDataGrid';
import DocumentDragAndDropContext, { DocumentDragAndDropContextState } from 'documents/contexts/DocumentDragAndDropContext';
import PlanlistDocumentDropZone from 'documents-lists/components/PlanlistDocumentDropZone';
import CreatePlanlistDialog from 'documents-lists/components/CreatePlanlistDialog';
import useDocumentUiConfigContext from 'documents/hooks/useDocumentUiConfigContext';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import { useTranslation } from 'react-i18next';
import RoleAction from 'projects/types/RoleAction';
import CreatePlanlistDialogButton from 'documents-lists/components/CreatePlanlistDialogButton';
import EditPlanlistDialogButton from 'documents-lists/components/EditPlanlistDialogButton';
import DeletePlanlistMenuButton from 'documents-lists/components/DeletePlanlistMenuButton';
import PlanlistFilterToolbar from 'documents-lists/components/PlanlistFilterToolbar';
import PlanlistFilterContextProvider from 'documents-lists/contexts/PlanlistFilterContextProvider';
import useDocumentScopeContext from 'documents/hooks/useDocumentScopeContext';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';

interface PlanlistTreePanelProps extends ISxProps {
}

export default function PlanlistTreePanel({
  sx,
}: PlanlistTreePanelProps) {
  const { t } = useTranslation('documents-lists');
  const currentUserRole = useCurrentUserRole();
  const { isDragOnPlanlistInProgress, documentVersionIdsDroppedOnCreateNewDropzone, setDocumentVersionIdsDroppedOnCreateNewDropzone } = useContext<DocumentDragAndDropContextState>(DocumentDragAndDropContext);
  const { suppressFolderManipulation } = useDocumentUiConfigContext();
  const { setDocumentScope } = useDocumentScopeContext();

  const onCloseCreatePlanlistDialog = useCallback((planlistIdAddedTo: string | undefined) => {
    setDocumentVersionIdsDroppedOnCreateNewDropzone([]);
    if (planlistIdAddedTo) {
      setDocumentScope({ key: DocumentScopeKey.Planlist, id: planlistIdAddedTo });
    }
  }, [setDocumentScope, setDocumentVersionIdsDroppedOnCreateNewDropzone]);

  return (
    <Box
      id="PlanlistTreePanel"
      sx={{
        ...sx, display: 'flex', flexDirection: 'column', pr: 1, gap: 1,
      }}
    >
      <PlanlistFilterContextProvider>
        <Box sx={{ px: 2, mb: 1 }}>
          <Box sx={{
            display: 'flex', alignItems: 'center', pt: 2, pb: 1,
          }}
          >
            <Typography variant="h4">{t('planlist-tree-panel_header', 'Plan Lists')}</Typography>
            {!suppressFolderManipulation && (
            <Box sx={{
              ml: 'auto', display: 'flex', columnGap: 1,
            }}
            >
              <CreatePlanlistDialogButton />
              <EditPlanlistDialogButton />
              {!!currentUserRole?.allowedActions?.has(RoleAction.DocumentList_Delete) && <DeletePlanlistMenuButton />}
            </Box>
            )}
          </Box>
          <PlanlistFilterToolbar />
        </Box>
        <PlanlistDataGrid sx={{ flexGrow: 1 }} />
        {!!documentVersionIdsDroppedOnCreateNewDropzone.length && (
          <CreatePlanlistDialog documentVersionIds={documentVersionIdsDroppedOnCreateNewDropzone} onClose={onCloseCreatePlanlistDialog} />
        )}
        {isDragOnPlanlistInProgress && (
          <PlanlistDocumentDropZone sx={{ position: 'absolute', bottom: 0, right: 0 }} />
        )}
      </PlanlistFilterContextProvider>
    </Box>
  );
}
