import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { MenuItem, Select } from '@mui/material';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import DocumentVersionBuildingUpdateDto from 'documents/types/DocumentVersionBuildingUpdateDto';
import useDocumentVersionBuildingUpdateMutation from 'documents/hooks/useDocumentVersionBuildingUpdateMutation';
import DocumentOptionsContext, { DocumentOptionsContextState } from 'documents/contexts/DocumentOptionsContext';
import DocumentAbbreviationDisplayMode from 'documents/types/DocumentAbbreviationDisplayMode';
import { useTranslation } from 'react-i18next';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import useCurrentUserFolderAccessLevel from 'documents-folders/hooks/useCurrentUserFolderAccessLevel';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';

export default function BuildingCell({
  data,
  value,
}: ICellRendererParams<DocumentsDataGridRow, string | undefined>) {
  const { t } = useTranslation('documents');
  const currentUserRole = useCurrentUserRole();
  const { data: buildings } = useProjectBuildingsQuery();
  const { mutateAsync, isLoading } = useDocumentVersionBuildingUpdateMutation();
  const [displayValue, setDisplayValue] = useState<string>(value ?? '');
  const { abbreviationDisplayMode } = useContext<DocumentOptionsContextState>(DocumentOptionsContext);
  const accessLevel = useCurrentUserFolderAccessLevel(data?.folderId);

  const onChange = useCallback(async (selectedBuildingMetadataId: string) => {
    if (!data) return;
    setDisplayValue(selectedBuildingMetadataId);
    const persistDto: DocumentVersionBuildingUpdateDto = {
      id: data.id,
      buildingId: selectedBuildingMetadataId || undefined,
    };
    await mutateAsync(persistDto);
  }, [data, mutateAsync]);

  useEffect(() => setDisplayValue(value ?? ''), [value]);

  return (
    <Select
      value={displayValue}
      disabled={!buildings?.length || isLoading || data?.isArchived || !currentUserRole?.allowedActions?.has(RoleAction.Document_Editing) || accessLevel !== CollaboratorFolderAccessLevel.ReadWrite}
      onChange={(e) => onChange(e.target.value)}
      variant="standard"
      sx={{ width: '100%' }}
    >
      <MenuItem value=""><i>{t('building-cell_value-none', 'Unassigned')}</i></MenuItem>
      {buildings?.map((buildingMetaData) => (
        <MenuItem value={buildingMetaData.id} key={buildingMetaData.id}>{abbreviationDisplayMode === DocumentAbbreviationDisplayMode.Abbreviation && buildingMetaData.abbreviation?.length ? buildingMetaData.abbreviation : buildingMetaData.name }</MenuItem>
      ))}
    </Select>
  );
}
