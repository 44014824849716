import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClickAwayListener } from '@mui/base';
import Switch from "../../Switch";
import { Modal } from "../../../HOC";
import CustomSelect from "../../CustomSelect";
import { CameraProjections, NavigationModes } from "../Types/base-types";
import { SketchPicker } from "react-color";
import { DEFAULT_SETTING } from "../../../Helpers/Viewer";

const ColorSetter = ({
  label,
  color,
  setColor,
}) => {
  const [isVisibleColorPicker, setVisibleColorPicker] = useState(false);
  const pickRef = useRef(null);

  const handleClickColorRect = () => {
    setVisibleColorPicker(!isVisibleColorPicker);
  }

  return (
    <>
      <div className="viewer-setting-modal_color-picker">
        <p>{label}</p>
        <div
          className="viewer-setting-modal_color-picker_rect"
          style={{ backgroundColor: color }}
          onClick={handleClickColorRect}
          ref={pickRef}
        />
      </div>
      {isVisibleColorPicker && (
        <ClickAwayListener
          onClickAway={() => setVisibleColorPicker(false)}
        >
          <div
            style={{
              position: "absolute",
              top: pickRef.current?.offsetTop - 270,
              left: pickRef.current?.offsetLeft + 30,
            }}
          >
            <SketchPicker
              color={color}
              onChange={(color) => setColor(color.hex)}
            />
          </div>
        </ClickAwayListener>
      )}
    </>
  )
};

const ViewerSettingModal = ({
  handleSetting,
  settings,
  floorMapSetting,
  handleFloorMapSetting,
}) => {
  const { t } = useTranslation();
  const controlOptions = [
    { value: NavigationModes.Mouse, label: t("mouse_mode", "Mouse") },
    { value: NavigationModes.Standard, label: t("standard_mode", "Standard") },
  ];
  const cameraOptions = [
    {
      value: CameraProjections.Perspective,
      label: t("perspective", "Perspective"),
    },
    {
      value: CameraProjections.Orthographic,
      label: t("orthogonal", "Orthogonal"),
    },
  ];
  const [camera, setCamera] = useState(cameraOptions[0]);
  const [controlMode, setControlMode] = useState(controlOptions[0]);
  const [spaces, setSpaces] = useState(false);
  const [openings, setOpenings] = useState(false);
  const [grid, setGrid] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [floorMaps, setFloorMaps] = useState([]);
  const [floorMap, setFloorMap] = useState();
  const [background, setBackground] = useState("#F2F3F5");
  const [sectionOutline, setSectionLine] = useState("#FFFF00");
  const [sectionSpace, setSectionSpace] = useState("#FF0000");
  const [navCube, setNavCube] = useState(false);
  
  useEffect(() => {
    setSettings(settings);
  }, [settings]);

  useEffect(() => {
    setShowMap(floorMapSetting.visible);

    const maps = floorMapSetting.floorMaps.map((f) => {
      return { value: f, label: f.name };
    });

    setFloorMaps(maps);

    setFloorMap(floorMapSetting.storey);
  }, [floorMapSetting]);

  const setSettings = (settings) => {
    setControlMode(controlOptions.find((m) => m.value === settings.control));
    setCamera(cameraOptions.find((c) => c.value === settings.camera));
    setSpaces(settings.spaces);
    setOpenings(settings.openings);
    setGrid(settings.grid);
    setNavCube(settings.navCube);
    if (settings.background) setBackground(settings.background);
    if (settings.sectionOutline) setSectionLine(settings.sectionOutline);
    if (settings.sectionSpace) setSectionSpace(settings.sectionSpace);
  }

  const handleCancel = () => {
    handleSetting(undefined);
  };

  const handleSave = () => {
    handleSetting({
      control: controlMode.value,
      camera: camera.value,
      spaces,
      openings,
      grid,
      background,
      navCube: navCube,
      sectionOutline,
      sectionSpace
    });

    handleFloorMapSetting({
      visible: showMap,
      storey: floorMap,
    });
  };

  const handleChangeControl = (mode) => {
    setControlMode(mode);
  };

  const handleToggleSpaces = () => {
    setSpaces(!spaces);
  };

  const handleToggleOpenings = () => {
    setOpenings(!openings);
  };

  const handleChangeCamera = (projection) => {
    if (projection.value === CameraProjections.Orthographic) {
      setControlMode(controlOptions[0]);
    }

    setCamera(projection);
  };

  const handleToggleGrid = () => {
    setGrid(!grid);
  };

  const handleToggleFloorMap = () => {
    setShowMap(!showMap);
  };

  const toggleNavCube = () => {
    setNavCube(!navCube);
  };

  const handleChangeMap = (map) => {
    setFloorMap(map);
  };

  const resetDefault = () => {
    setSettings(DEFAULT_SETTING);
  };

  return (
    <Modal id="ViewerSettingModal" style={{ overflow: "visible" }}>
      <div className="viewer-setting-modal">
        <div className="viewer-setting-modal_title">
          <label>{t("viewer_settings", "Viewer Settings")}</label>
          <div className="reset" onClick={resetDefault}>
            {t("reset_default", "Reset Default")}
          </div>
        </div>
        <div className="viewer-setting-modal_general">
          <label>{t("general", "General")}</label>
          <div className="select-area">
            <div>
              <p>{t("navigation", "Navigation")}</p>
              <CustomSelect
                values={controlOptions}
                value={controlMode}
                onChange={handleChangeControl}
                isDisabled={camera?.value === CameraProjections.Orthographic}
                isModel={true}
              />
            </div>
            <div>
              <p>{t("camera", "Camera")}</p>
              <CustomSelect
                values={cameraOptions}
                value={camera}
                onChange={handleChangeCamera}
                isModel={true}
              />
            </div>
          </div>
          <div className="nav-cube">
            <p>{t("navigation_cube", "Navigation Cube")}</p>
            <Switch handleChange={toggleNavCube} checked={navCube} />
          </div>
        </div>
        <div className="viewer-setting-modal_setting">
          <div>
            <label>{t("adjust_3d_view", "Adjust 3D View")}</label>
            <div>
              <p>{t("view_spaces", "View Spaces")}</p>
              <Switch handleChange={handleToggleSpaces} checked={spaces} />
            </div>
            <div>
              <p>{t("view_openings", "View Openings")}</p>
              <Switch handleChange={handleToggleOpenings} checked={openings} />
            </div>
            <div>
              <p>{t("show_axis_grid", "Show Axis Grid")}</p>
              <Switch handleChange={handleToggleGrid} checked={grid} />
            </div>
          </div>
          <div>
            <label>{t("2d_map", "2D Map")}</label>
            <div>
              <p>{t("floor_map", "Floor Map")}</p>
              <Switch handleChange={handleToggleFloorMap} checked={showMap} />
            </div>
            <CustomSelect
              values={floorMaps}
              value={floorMap}
              onChange={handleChangeMap}
              isDisabled={!showMap}
              isModel={true}
            />
          </div>
        </div>
        <div className="viewer-setting-modal_color">
          <label>{t("customize_colors", "Customize Colors")}</label>
          <div className="viewer-setting-modal_color_mainboard">
            <div>
              <ColorSetter
                label={t("3d_background", "3D Background")}
                color={background}
                setColor={setBackground}
              />
            </div>
            <div>
              <div className="cut-label">{t("sectional_cut", "Sectional Cut")}</div>
              <ColorSetter
                label={t("outlines", "Outlines")}
                color={sectionOutline}
                setColor={setSectionLine}
              />
              <ColorSetter
                label={t("spaces", "Spaces")}
                color={sectionSpace}
                setColor={setSectionSpace}
              />
            </div>
          </div>
        </div>
        <div className="button-area">
          <button className="btn btn--secondary" onClick={handleCancel}>
            {t("cancel", "Cancel")}
          </button>
          <button className="btn btn--primary" onClick={handleSave}>
            {t("save_viewer_settings", "Save Settings")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ViewerSettingModal;
