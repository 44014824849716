import React, { useMemo } from 'react';
import { DropResult, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import SuspenseWithErrorBoundary from '../../error/SuspenseWithErrorBoundary';
import IssueBoardColumn from './IssueBoardColumn';

interface IssueBoardPriorityColumnProps {
  group: any,
  isVisible: any,
  viewIssue: any,
  issueFilter: any,
  updateObj: { result: DropResult } | undefined,
}

function IssueBoardPriorityColumn({
  group,
  isVisible,
  viewIssue,
  issueFilter,
  updateObj,
}: IssueBoardPriorityColumnProps) {
  const { t } = useTranslation();

  const issueFilterFixedPriority = useMemo(
    () => (!issueFilter
      ? {
        and: [{ priorityId: { eq: group.value } }],
      }
      : {
        and: [{ priorityId: { eq: group.value } }, issueFilter],
      }),
    [group.value, issueFilter],
  );

  return (
    <div className="issue-board__column">
      <div className="issue-board__column__title">
        <strong>
          {`${t('priority', 'Priority')}:`}
        </strong>
        {group.name}
      </div>
      {isVisible ? (
        <SuspenseWithErrorBoundary
          suspenseFallback={<div className="issue-board__column__wrapper" />}
        >
          <IssueBoardColumn
            group={group}
            viewIssue={viewIssue}
            filter={issueFilterFixedPriority}
            updateObj={updateObj}
          />
        </SuspenseWithErrorBoundary>
      ) : (
        <div className="issue-board__column__wrapper">
          <Droppable droppableId={`${group.value}`}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...provided.droppableProps}
                style={{ overflow: 'hidden' }}
              >
                <p className="hidden-column-status">
                  {t('hidden_column_status', 'Content is hidden by the filter')}
                </p>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
    </div>
  );
}

export default IssueBoardPriorityColumn;
