import React from "react";

const SearchInput = ({ inputRef, placeholder, value, onChange, isAddIcon = false }) => {
  return (
    <div className="search-input">
      {isAddIcon && (
        <div className="icon-search">
          <svg className="icon">
            <use xlinkHref="/img/sprite.svg#search" />
          </svg>
        </div>
      )}
      <input
        ref={inputRef}
        className="form-control"
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={{
          paddingLeft: isAddIcon ? "40px" : "7px",
        }}
      />
      {!!value && (
        <div className="btn-clear">
          <svg className="icon" onClick={() => onChange(null)}>
            <use xlinkHref="/img/sprite.svg#close" />
          </svg>
        </div>
      )}
    </div>
  );
};

export default SearchInput;
