import { useState, useCallback, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import { formatFileSize } from 'Helpers/utils';
import { EmailService, IssueService, ModelService } from 'Services';
import { sprintf } from 'sprintf-js';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';

export default function useFileUpload(
  uploadCategory /* 0: issue, 1: model */,
  issueModalVisible = false,
) {
  const { t } = useTranslation();
  const [notification, setNotification] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [progress, setProgress] = useState({});
  const [uploadFileSize, setUploadFileSize] = useState({});
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const [uploadHandles, setUploadHandles] = useState({});
  const currentUserRole = useCurrentUserRole();
  const { data: currentProject } = useCurrentProjectQuery();
  const { authUserToken } = useVisoplanApiContext();

  const isDisabled = useMemo(() => {
    if (uploadCategory === 0) { return !currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_BcfUpload); }
    if (uploadCategory === 1) return false;
    if (uploadCategory === 2) { return !currentUserRole?.allowedActions?.has(RoleAction.Inbox_AllowUploadEmail); }
    return true;
  }, [currentUserRole, uploadCategory]);

  const fileUploaded = useCallback(() => {
    setNotification({
      title: t('uploaded_files', 'Uploaded Files'),
    });
  }, [t]);

  const fileUploadError = useCallback(() => {
    setNotification({
      title: t(
        'error_during_upload_file',
        'An error occurred during file upload, try again',
      ),
      doneText: t('try_again', 'Try Again'),
    });
  }, [t]);

  const initNotification = useCallback(() => {
    setNotification(null);
  }, []);

  const hideProgressModal = useCallback(() => {
    setShowUploadProgress(false);
  }, []);

  const uploadFileProgress = useCallback(
    async (acceptedFiles) => {
      const uploadProgress = {};
      const fileSize = {};
      const bufferHandles = {};

      if (issueModalVisible) return; // when displaying the issue modal, useFileUpload shouldn't be used

      const files = acceptedFiles.filter((file) => {
        const name = file.name.toLowerCase();
        if (uploadCategory === 0) { return name.endsWith('.bcf') || name.endsWith('.bcfzip'); }
        if (uploadCategory === 1) return name.endsWith('.ifc');
        return name.endsWith('.msg') || name.endsWith('.eml');
      });
      if (!files.length) {
        setNotification({
          title: t('wrong_file_format', 'Wrong File Format'),
          description: sprintf(
            t(
              'wrong_file_description',
              'The file you try to upload has the <b>wrong format. Just %1$s files</b> can be uploaded here',
            ),
            `${uploadCategory === 0 ? 'BCF' : 'IFC'}`,
          ),
          doneText: t('try_again', 'Try Again'),
        });
        return;
      }

      Object.keys(uploadProgress).forEach((file) => {
        if (uploadProgress[file] > 99) {
          delete uploadProgress[file];
        }
      });

      files.forEach((file) => {
        uploadProgress[file.name] = 0;
        fileSize[file.name] = formatFileSize(file.size);
      });
      setProgress({ ...uploadProgress });
      setUploadFileSize({ ...fileSize });
      setShowUploadProgress(true);
      await Promise.all(
        files.map(async (file) => {
          if (uploadCategory === 0) {
            return IssueService.uploadIssue(
              file,
              currentProject.id,
              authUserToken,
              (currentProgress) => {
                uploadProgress[file.name] = currentProgress;
                setProgress({ ...uploadProgress });
              },
            );
          }
          if (uploadCategory === 1) {
            const uploadHandle = new Date().getTime().toString();
            bufferHandles[file.name] = uploadHandle;

            return ModelService.uploadModelFiles(
              file,
              currentProject.id,
              authUserToken,
              (currentProgress) => {
                uploadProgress[file.name] = currentProgress;
                setProgress({ ...uploadProgress });
              },
              uploadHandle,
            );
          }
          return EmailService.uploadEmails(
            file,
            currentProject.id,
            authUserToken,
            (currentProgress) => {
              uploadProgress[file.name] = currentProgress;
              setProgress({ ...uploadProgress });
            },
          );
        }),
      );
      setUploadHandles(bufferHandles);
    },
    [issueModalVisible, uploadCategory, t, currentProject?.id, authUserToken],
  );

  const { getRootProps, getInputProps } = useDropzone(
    {
      noClick: true,
      noKeyboard: true,
      disabled: isDisabled,
      onDrop: (acceptedFiles) => {
        setDragging(false);
        uploadFileProgress(acceptedFiles);
      },
      onDragEnter: () => {
        setDragging(true);
      },
      onDragLeave: () => {
        setDragging(false);
      },
    },
    [],
  );

  return {
    notification,
    dragging,
    progress,
    uploadFileSize,
    uploadHandles,
    showUploadProgress,
    uploadFileProgress,
    getRootProps,
    getInputProps,
    fileUploaded,
    fileUploadError,
    setNotification,
    initNotification,
    setProgress,
    setUploadFileSize,
    setShowUploadProgress,
    hideProgressModal,
  };
}
