import { ReactNode, useContext, useMemo } from 'react';
import {
  CellStyle, CellStyleFunc, ColDef, ICellRendererParams,
} from '@ag-grid-community/core';
import useDynamicLayout from 'dynamic-layout/hooks/useDynamicLayout';
import DynamicLayoutKey from 'dynamic-layout/types/DynamicLayoutKey';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import VersionNumberCell from 'documents/components/cell-renderers/VersionNumberCell';
import StatusCell from 'documents/components/cell-renderers/StatusCell';
import NameCell from 'documents/components/cell-renderers/NameCell';
import BuildingCell from 'documents/components/cell-renderers/BuildingCell';
import CommentButtonCell from 'documents/components/cell-renderers/CommentButtonCell';
import DisciplineCell from 'documents/components/cell-renderers/DisciplineCell';
import FloorCell from 'documents/components/cell-renderers/FloorCell';
import LinkedIssuesCell from 'documents/components/cell-renderers/LinkedIssuesCell';
import TagsCell from 'documents/components/cell-renderers/TagsCell';
import WorkPhaseCell from 'documents/components/cell-renderers/WorkPhaseCell';
import DocumentModelImportCell from 'documents/components/cell-renderers/DocumentModelImportCell';
import DocumentScopeContext, { DocumentScopeContextState } from 'documents/contexts/DocumentScopeContext';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import FolderCellRenderer from 'documents/components/cell-renderers/FolderCellRenderer';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import CheckboxCell from 'documents/components/cell-renderers/CheckboxCell';
import CheckboxHeaderCell from 'documents/components/cell-renderers/CheckboxHeaderCell';
import { useTranslation } from 'react-i18next';
import useCurrentUserFolderAccessLevel from 'documents-folders/hooks/useCurrentUserFolderAccessLevel';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';
import { t as translate } from 'i18next';

const columnNames = new Set<string>([
  'name',
  'versionNumber',
  'fileType',
  'status',
  'uploadDateParsed',
  'disciplineMetaDataIds',
  'buildingMetaDataId',
  'floorMetaId',
  'workPhaseId',
  'linkedIssueIds',
  'editDateParsed',
  'creator',
  'editor',
  'commentIds',
  'creationDateParsed',
  'fileSize',
  'tagIds',
  'folderId',
]);

const sortingFieldNameByColumnName = new Map<string, string>([
  ['name', 'originalFileName'],
  ['versionNumber', 'versionNumber'],
  ['fileType', 'fileType'],
  ['status', 'status/id'],
  ['uploadDateParsed', 'uploadDate'],
  ['buildingMetaDataId', 'buildingMetaDataId'],
  ['floorMetaId', 'floorMetaId'],
  ['workPhaseId', 'workPhaseId'],
  ['editDateParsed', 'editDate'],
  ['creator', 'creatorId'],
  ['editor', 'editorId'],
  ['creationDateParsed', 'creationDate'],
  ['fileSize', 'fileSize'],
  ['folderId', 'folderId'],
]);

interface CellRenderersByColumnName {
  [fieldName: string]: (params: ICellRendererParams<DocumentsDataGridRow>) => ReactNode
}

const cellRendererByColumnName: CellRenderersByColumnName = {
  name: NameCell,
  versionNumber: VersionNumberCell,
  status: StatusCell,
  disciplineMetaDataIds: DisciplineCell,
  buildingMetaDataId: BuildingCell,
  floorMetaId: FloorCell,
  workPhaseId: WorkPhaseCell,
  linkedIssueIds: LinkedIssuesCell,
  tagIds: TagsCell,
  folderId: FolderCellRenderer,
};

const cellStyleByColumnName = new Map<string, CellStyle | CellStyleFunc<DocumentVersionDto>>([
  ['versionNumber', { paddingLeft: '2px', paddingRight: '2px' }],
  ['linkedIssueIds', { paddingLeft: '2px', paddingRight: '2px' }],
]);

const minWidthByColumnName = new Map<string, number>([
  ['status', 75],
  ['disciplineMetaDataIds', 75],
  ['buildingMetaDataId', 75],
  ['floorMetaId', 75],
  ['workPhaseId', 75],
  ['tagIds', 75],
  ['folderId', 75],
]);

type DocumentsValueGetter = (params: { data: DocumentVersionDto | undefined }) => any;
export const valueGetterByColumnName = new Map<string, DocumentsValueGetter>([
  ['name', ({ data }) => data],
]);

type DocumentsValueFormatter = (params: { data: DocumentVersionDto | undefined }) => string;
export const valueFormatterByColumnName = new Map<string, DocumentsValueFormatter>([
  ['name', ({ data }) => data?.name ?? ''],
  ['fileType', ({ data }) => (data ? translate(`fileType_${data.fileType}`, `${data.fileType}`, { ns: 'common' }) : '')],
  ['uploadDateParsed', ({ data }) => (data ? new Date(data.uploadDate).toLocaleString('de', { dateStyle: 'medium', timeStyle: 'medium' }) : '')],
  ['editDateParsed', ({ data }) => (data ? new Date(data.editDate).toLocaleString('de', { dateStyle: 'medium', timeStyle: 'medium' }) : '')],
  ['creationDateParsed', ({ data }) => (data?.creationDate ? new Date(data.creationDate).toLocaleString('de', { dateStyle: 'medium', timeStyle: 'medium' }) : '')],
  ['creator', ({ data }) => (data ? `${data.creator.firstName} ${data.creator.lastName}` : '')],
  ['editor', ({ data }) => (data?.editor ? `${data.editor.firstName} ${data.editor.lastName}` : '')],
  ['commentIds', ({ data }) => (data ? `${data.commentIds.length}` : '')],
  ['fileSize', ({ data }) => {
    if (!data) return '';
    if (data.fileSize < 1000000) return `${parseFloat((data.fileSize / 1000).toFixed(2))} kB`;
    if (data.fileSize < 1000000000) return `${parseFloat((data.fileSize / 1000000).toFixed(2))} MB`;
    if (data.fileSize < 1000000000000) return `${parseFloat((data.fileSize / 1000000000).toFixed(2))} GB`;
    return `${parseFloat((data.fileSize / 1000000000000).toPrecision(2))} TB`;
  }],
  ['status', ({ data }) => (data?.status ? data.status.name : '')],
  ['linkedIssueIds', ({ data }) => (data?.documentLinkedIssueIds ? `${data.documentLinkedIssueIds.length}` : '')],
]);

export default function useDocumentsDataGridColumnDefinitions() {
  const { t } = useTranslation('documents');
  const { documentScope } = useContext<DocumentScopeContextState>(DocumentScopeContext);
  const folderId = useMemo(() => (documentScope.key === DocumentScopeKey.Folder ? documentScope.id : undefined), [documentScope.id, documentScope.key]);
  const accessLevel = useCurrentUserFolderAccessLevel(folderId);
  const { columnWidthsByColumnName, orderedVisibleColumnNames } = useDynamicLayout(DynamicLayoutKey.DEFAULT_DOCS_LAYOUT);
  const columnsFromDynamicLayout = useMemo<ColDef<DocumentsDataGridRow>[]>(() => (orderedVisibleColumnNames && columnWidthsByColumnName
    ? orderedVisibleColumnNames
      .filter((columnName) => columnNames.has(columnName))
      .map((columnName) => ({
        colId: sortingFieldNameByColumnName.get(columnName) ?? columnName,
        field: columnName as keyof DocumentsDataGridRow,
        headerName: t(`dms-table-column_${columnName}`, columnName),
        width: columnWidthsByColumnName.get(columnName),
        minWidth: minWidthByColumnName.get(columnName) ?? 50,
        sortable: sortingFieldNameByColumnName.get(columnName) !== undefined,
        cellRenderer: cellRendererByColumnName[columnName],
        cellStyle: cellStyleByColumnName.get(columnName),
        valueGetter: valueGetterByColumnName.get(columnName),
        valueFormatter: valueFormatterByColumnName.get(columnName),
      }))
    : []), [columnWidthsByColumnName, orderedVisibleColumnNames, t]);
  const isImportColumnVisible = useMemo(() => documentScope.key !== DocumentScopeKey.Archive && documentScope.key !== DocumentScopeKey.Planlist, [documentScope.key]);

  const columnDefs = useMemo<(ColDef<DocumentsDataGridRow>)[]>(() => {
    const rightPinnedColumns: (ColDef<DocumentsDataGridRow>)[] = [];
    if (isImportColumnVisible) {
      rightPinnedColumns.push({
        colId: 'import',
        pinned: 'right',
        lockPinned: true,
        resizable: false,
        lockPosition: true,
        sortable: false,
        width: 180,
        cellRenderer: DocumentModelImportCell,
        headerName: 'Import',
        headerClass: 'no-border no-hover',
        cellClass: 'no-separator',
        field: 'documentImportStatus',
      });
    }
    if (documentScope.key !== DocumentScopeKey.Archive) {
      rightPinnedColumns.push({
        colId: 'comments',
        pinned: 'right',
        lockPinned: true,
        resizable: false,
        lockPosition: true,
        sortable: false,
        width: 68,
        cellRenderer: CommentButtonCell,
        cellStyle: { textOverflow: 'unset' },
        headerClass: 'no-border no-hover',
        cellClass: 'no-separator',
      });
    }
    return [
      {
        colId: 'drag-handle',
        resizable: false,
        lockPosition: true,
        sortable: false,
        width: 48,
        rowDrag: documentScope.key !== DocumentScopeKey.Archive && (documentScope.key !== DocumentScopeKey.Folder || accessLevel === CollaboratorFolderAccessLevel.ReadWrite),
        headerClass: 'no-hover no-border',
        cellClass: 'no-separator',
      },
      {
        colId: 'checkbox',
        resizable: false,
        lockPosition: true,
        sortable: false,
        width: 26,
        minWidth: 26,
        headerClass: 'no-hover no-border no-padding',
        cellClass: 'no-separator',
        cellStyle: { paddingLeft: 0, paddingRight: 0 },
        cellRenderer: CheckboxCell,
        headerComponent: CheckboxHeaderCell,
      },
      ...columnsFromDynamicLayout,
      ...rightPinnedColumns,
    ];
  }, [accessLevel, columnsFromDynamicLayout, documentScope.key, isImportColumnVisible]);

  const defaultColumnDef = useMemo(() => ({
    resizable: true,
    initialWidth: 100,
    lockPinned: true,
  }), []);

  return { columnDefs, defaultColumnDef };
}
