import React from 'react';
import { Box, Modal, useTheme } from '@mui/material';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import SuspenseWithErrorBoundary from 'error/SuspenseWithErrorBoundary';
import IssuesFilterContextProvider from 'issues/contexts/IssuesFilterContextProvider';
import IssuesSelectionContextProvider from 'issues/contexts/IssuesSelectionContextProvider';
import LinkExistingIssuePanel from 'issues/components/LinkExistingIssuePanel';

interface LinkExistingIssueModalProps {
  onClose: (selectedIssueId: string | undefined) => void,
  title: string,
  documentVersionIds: string[] | undefined,
}

export default function LinkExistingIssueModal({
  title,
  onClose,
  documentVersionIds,
}: LinkExistingIssueModalProps) {
  const theme = useTheme();
  return (
    <Modal id="LinkExistingIssueModal" open disablePortal sx={{ height: '100%', display: 'flex', alignItems: 'stretch' }}>
      <Box sx={{
        flex: '1 1 0',
        m: 6,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.divider,
        borderRadius: 4,
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <SuspenseWithErrorBoundary suspenseFallback={<CenteredCircularProgress />}>
          <IssuesSelectionContextProvider>
            <IssuesFilterContextProvider>
              <LinkExistingIssuePanel title={title} onClose={onClose} documentVersionIds={documentVersionIds} />
            </IssuesFilterContextProvider>
          </IssuesSelectionContextProvider>
        </SuspenseWithErrorBoundary>
      </Box>
    </Modal>
  );
}
