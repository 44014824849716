import { useCallback, useMemo } from 'react';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import { VisoListByIdQueryKey } from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { QueryObserverOptions, WithRequired } from '@tanstack/react-query';

export type ResourcesQueryKey = VisoListByIdQueryKey<ApiEndpoint.Resource>;
export type ResourcesQueryQueryFnData = string[] | undefined;
export type ResourcesQueryOptions = WithRequired<Partial<Omit<QueryObserverOptions<ResourcesQueryQueryFnData, unknown, ResourcesQueryQueryFnData, ResourcesQueryQueryFnData, ResourcesQueryKey>, 'queryKey'>>, 'queryFn'>;

export default function useResourcesQueryOptions() {
  const axiosInstance = useAxiosInstance();
  const queryFn = useCallback(async ({ queryKey }: { queryKey: ResourcesQueryKey }) => {
    const [endpoint, , , ids] = queryKey;
    return await Promise.all(ids.map(async (id) => {
      const url = `${endpoint}/${id}`;
      const response = await axiosInstance.get<Blob>(url, { responseType: 'blob' });
      const href = URL.createObjectURL(response.data);
      return href;
    }));
  }, [axiosInstance]);
  return useMemo<ResourcesQueryOptions>(() => ({
    queryFn,
  }), [queryFn]);
}
