import { Color, Material, Matrix4, Object3D, Vector3 } from "three";
import { IFCModel } from "./IFCModel";
import { ParserProgress } from "./IFCParser";

export const IdAttrName = "expressID";
export const Position = "position";
export const Normal = "normal";

export const SELECT_ID = "selection_model";
export const COLORGROUP_ID = "color_model";

export const OPENING = "IFCOPENINGELEMENT";
export const SPACE = "IFCSPACE";

export type IdAttributeByMaterial = { [id: number]: number };
export type IdAttributesByMaterials = {
  [materialID: string]: IdAttributeByMaterial;
};

export interface BaseSubsetConfig {
  parent?: Object3D;
  ids: number[];
  removePrevious: boolean;
  material?: Material;
  customID?: string;
  applyBVH?: boolean;
}

export interface SubsetConfig extends BaseSubsetConfig {
  modelID: number;
}

export const DEFAULT = "default";

export type MapFaceindexID = { [key: number]: number };

export interface ModelData {
  nodeData: {[globalId:string]:VisoNode};
  globalIds:{[nodeId:string]:string};
  shapes: VisoObjectMap | null;
}

export interface IfcState {
  models: { [modelID: string]: IFCModel };
  modelData: { [modelID: string]: ModelData };
  onProgress?: (event: ParserProgress) => void;
  coordinationMatrix?: Matrix4;
  sectionColor?: Color;
}

export interface Node {
  expressID: number;
  type: string;
  children: Node[];
}

export interface pName {
  name: number;
  relating: string;
  related: string;
  key: string;
}

export interface VisoObjectMap {
  [key: string]: any;
}

export interface VisoModel {
  id: string;
  model: any;
  shapes: VisoObjectMap;
  styles: VisoObjectMap;
  center: Vector3;
  size: Vector3;
  storeys?: any;
  nodeData: VisoNode[];
}

export interface VisoMesh {
  modelId: string;
}

export interface GlobalIdsMap {
  [modelId: string]: string[];
}

export interface ColorIdsMap {
  [color: string]: GlobalIdsMap;
}

export interface ColorMatMap {
  [color: string]: Material;
}

export interface SelectionData {
  count: number;
  components: Array<VisoNode>;
  modelFileIds?: Array<string>;
  isTrans: boolean;
}

export interface ColorGroup {
  color: string;
  componentIDs: string[];
}

export interface VisualState {
  visibility: boolean;
  colorGroups?: ColorGroup[];
  selectedIDs?: string[];
  transparentIDs?: string[];
  exceptedIDs?: string[];
}

export class VisoNode {
  id: string = "";
  globalId: string = "";
  elementName?: string;
  elementType?: string;
  shapeId: string = "";
  styleIds: Array<string> = [];
  containsNodeIds:Array<string>=[];
  isDecomposedByNodeIds: Array<string> = [];
  decomposesNodeIds: Array<string> = [];
  transform: Array<number> = [];
  modelFileId: string = "";
}
