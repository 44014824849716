import EmailFilterSettings from 'emails/types/EmailFilterSettings';
import EmailFilterSettingsDto from 'emails/types/EmailFilterSettingsDto';
import { useCallback } from 'react';

export default function useEmailFilterSettingsEntityFactory() {
  return useCallback((dto: EmailFilterSettingsDto) => ({
    ...dto,
    editDateParsed: dto.editDate ? new Date(dto.editDate) : undefined,
    creationDateParsed: dto.creationDate ? new Date(dto.creationDate) : undefined,
  } as Readonly<EmailFilterSettings>), []);
}
