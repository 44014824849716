import { uniq } from "lodash";
import { useEffect } from "react";

export default function useFilterOptions(props) {
  const {
    default_select,
    statuses,
    priorities,
    users,
    types,
    categories,
    buildings,
    floors,
    workphases,
    visibility,
    allDocuments,
    allEmails,
    tagOptions,
    currentFilter,
    setFilterValues,
  } = props;

  const setCurrentFilterOptions = (filter) => {
    const disciplineIds = uniq([
      ...(filter.disciplineMetaDataIds || []),
      ...(filter.customDisciplineMetaDataIds || []),
    ]);
    const buildingIds = uniq([
      ...(filter.buildingMetaDataIds || []),
      ...(filter.customBuildingMetaDataIds || []),
    ]);
    const floorIds = uniq([
      ...(filter.floorMetaDataIds || []),
      ...(filter.customFloorMetaDataIds || []),
    ]);

    setFilterValues({
      createdDateFrom: filter.creationDateAfter
        ? new Date(filter.creationDateAfter)
        : null,
      createdDateTo: filter.creationDateBefore
        ? new Date(filter.creationDateBefore)
        : null,
      editDateFrom: filter.editDateAfter
        ? new Date(filter.editDateAfter)
        : null,
      editDateTo: filter.editDateBefore
        ? new Date(filter.editDateBefore)
        : null,
      dueDateFrom: filter.dueDateAfter ? new Date(filter.dueDateAfter) : null,
      dueDateTo: filter.dueDateBefore ? new Date(filter.dueDateBefore) : null,
      status: filter.statusIds
        ? statuses.filter((status) =>
            filter.statusIds?.some((value) => status.value === value)
          )
        : { ...default_select },
      priority: filter.priorityId
        ? priorities.find((item) => item.value === filter.priorityId)
        : { ...default_select },
      assignedTo:
        filter?.assignedUserIds?.length && filter.assignedUserIds[0]
          ? users.find((item) => item.value === filter.assignedUserIds[0])
          : { ...default_select },
      reviewer: filter.reviewerId
        ? users.find((item) => item.value === filter.reviewerId)
        : { ...default_select },
      document: filter.linkedDocumentVersionIds
        ? allDocuments.find(
            (item) => item.name === filter.linkedDocumentVersionIds
          )
        : { ...default_select },
      email: filter.linkedEmailIds
        ? allEmails.find((item) => item.name === filter.linkedEmailIds)
        : { ...default_select },
      creator: filter.createAuthorId
        ? users.find((item) => item.value === filter.createAuthorId)
        : { ...default_select },
      lastEditor: filter.editAuthor
        ? users.find((item) => item.value === filter.createAuthorId)
        : { ...default_select },
      source: filter.source,
      type: filter.type
        ? types.find((item) => item.value === filter.type)
        : { ...default_select },
      tags: filter.tagIds
        ? tagOptions.filter((tag) => {
            const findTag = filter.tagIds.find((id) => tag.value === id);
            if (findTag) return tag;
          })
        : [],
      category:
        disciplineIds?.length && disciplineIds[0]
          ? categories.find((item) => item.value === disciplineIds[0])
          : { ...default_select },
      floor:
        floorIds?.length && floorIds[0]
          ? floors.find((item) => item.value === floorIds[0])
          : { ...default_select },
      building:
        buildingIds?.length && buildingIds[0]
          ? buildings.find((item) => item.value === buildingIds[0])
          : { ...default_select },
      workphase: filter.workPhaseId
        ? workphases.find((item) => item.value === filter.workPhaseId)
        : { ...default_select },
      startDateTo: filter.startDateBefore
        ? new Date(filter.startDateBefore)
        : null,
      startDateFrom: filter.startDateAfter
        ? new Date(filter.startDateAfter)
        : null,
      visibility: filter.visibility
        ? visibility.find((item) => item.value === filter.visibility)
        : { ...default_select },
    });
  };

  useEffect(() => {
    if (Object.keys(currentFilter).length) {
      setCurrentFilterOptions(currentFilter.uiFilterSettings?.issueFilter);
    }
  }, [currentFilter]);
}
