import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Button, Menu, MenuList, MenuItem, useTheme, Alert, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ListOptionsIcon from 'icons/ListOptionsIcon';
import ISxProps from 'common/types/ISxProps';
import ShareDocumentsDialog from 'share/components/ShareDocumentsDialog';
import DocumentSelectionContext, { DocumentSelectionContextState } from 'documents/contexts/DocumentSelectionContext';
import useDocumentVersionArchiveOperationMutation from 'documents/hooks/useDocumentVersionArchiveOperationMutation';
import ArchiveOperation from 'documents/types/ArchiveOperation';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import PrintWizard from 'documents/components/PrintWizard';
import ConfirmRemoveFromPlanlistDialog from 'documents-lists/components/ConfirmRemoveFromPlanlistDialog';
import AddToPlanlistDialog from 'documents-lists/components/AddToPlanlistDialog';
import useCurrentPlanlistId from 'documents-lists/hooks/useCurrentPlanlistId';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import LinkExistingIssueModal from 'issues/components/LinkExistingIssueModal';
import useIssueUpdateMutation from 'issues/hooks/useIssueUpdateMutation';
import useIssuesOdataQueryData from 'issues/hooks/useIssuesOdataQueryData';
import { useQueryClient } from '@tanstack/react-query';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import LinkNewIssueDialog from 'issues/components/LinkNewIssueDialog';
import useCurrentUserFolderAccessLevel from 'documents-folders/hooks/useCurrentUserFolderAccessLevel';
import useCurrentFolderId from 'documents-folders/hooks/useCurrentFolderId';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';
import useDocumentScopeContext from 'documents/hooks/useDocumentScopeContext';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import useDocumentListQuery from 'documents-lists/hooks/useDocumentListQuery';
import useUserGroupsOdataQuery from 'users-groups/hooks/useUserGroupsOdataQuery';
import { ITEM_ROOT } from 'odata-query';

interface DocumentsActionOptionsButtonMenuProps extends ISxProps {
}

export default function DocumentsActionOptionsButtonMenu({
  sx,
}: DocumentsActionOptionsButtonMenuProps) {
  const { t } = useTranslation('documents');
  const theme = useTheme();
  const btnRef = useRef<HTMLButtonElement>(null);
  const queryClient = useQueryClient();
  const folderId = useCurrentFolderId();
  const folderAccessLevel = useCurrentUserFolderAccessLevel(folderId);
  const { baseQueryKey: documentVersionsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const { data: currentUser } = useCurrentUserQuery();
  const { data: currentUsersGroups } = useUserGroupsOdataQuery(currentUser ? { filter: { userIds: { any: { [ITEM_ROOT]: currentUser.id } }, isDeleted: false } } : undefined);
  const { mutateAsync: mutateArchive, isLoading: isLoadingArchiveOperation } = useDocumentVersionArchiveOperationMutation(ArchiveOperation.Archive);
  const { mutateAsync: mutateIssue, isLoading: isLoadingDocumentVersionMutation } = useIssueUpdateMutation();
  const getIssuesOdataQueryData = useIssuesOdataQueryData();
  const isLoading = useMemo(() => isLoadingDocumentVersionMutation || isLoadingArchiveOperation, [isLoadingArchiveOperation, isLoadingDocumentVersionMutation]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const { selectedDocumentVersionIds } = useContext<DocumentSelectionContextState>(DocumentSelectionContext);
  const { data: selectedDocumentVersions, isLoading: isLoadingDocumentVersions } = useDocumentVersionsQuery(selectedDocumentVersionIds ? { filter: { id: { in: selectedDocumentVersionIds } } } : undefined, { enabled: isMenuOpen });
  const currentPlanlistId = useCurrentPlanlistId();
  const { data: planlist } = useDocumentListQuery(currentPlanlistId);
  const isAtLeastOnePrivateDocumentSelected = selectedDocumentVersions?.some((v) => v.isPrivate);
  const canAddToOrRemoveFromPlanlist = useMemo(() => {
    if (isAtLeastOnePrivateDocumentSelected || !planlist || !currentUser || !currentUsersGroups) return false;
    if (planlist.writeAccessUserIds.some((id) => id === currentUser.id)) return true;
    const currentUsersGroupsIdsSet = new Set(currentUsersGroups.map((g) => g.id));
    return planlist.writeAccessGroupIds.some((id) => currentUsersGroupsIdsSet.has(id));
  }, [currentUser, currentUsersGroups, isAtLeastOnePrivateDocumentSelected, planlist]);
  const currentUserRole = useCurrentUserRole();
  const [isPrintWizardOpen, setIsPrintWizardVisible] = useState(false);
  const [isRemoveFromPlanlistConfirmDialogOpen, setIsRemoveFromPlanlistConfirmDialogOpen] = useState(false);
  const [isAddToPlanlistConfirmDialogOpen, setIsAddToPlanlistConfirmDialogOpen] = useState(false);
  const [isAddToIssueDialogOpen, setIsAddToIssueDialogOpen] = useState(false);
  const [isCreateIssueDialogOpen, setIsCreateIssueDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const { setDocumentScope } = useDocumentScopeContext();

  const onClickShare = useCallback(() => {
    setIsMenuOpen(false);
    setIsShareDialogOpen(true);
  }, []);
  const onClickArchive = useCallback(async () => {
    setIsMenuOpen(false);
    try {
      await mutateArchive(selectedDocumentVersionIds);
      setSuccessMessage(t('list-options_archive-success-message', 'Successfully archived {{count}} documents.', { count: selectedDocumentVersionIds.length }));
    } catch (error) {
      const reason = getRequestErrorMessage(error);
      setErrorMessage(t('list-options_archive-error-message', 'Archiving documents failed. Reason: {{reason}}', { reason }));
    }
  }, [getRequestErrorMessage, mutateArchive, selectedDocumentVersionIds, t]);

  const isPrintAvailable = useMemo(() => currentUser?.canUsePrintOrders, [currentUser?.canUsePrintOrders]);
  const onClickPrint = useCallback(() => {
    setIsMenuOpen(false);
    setIsPrintWizardVisible(true);
  }, []);

  const onClickRemoveFromPlanlist = useCallback(() => {
    setIsMenuOpen(false);
    setIsRemoveFromPlanlistConfirmDialogOpen(true);
  }, []);

  const onClickAddToPlanlist = useCallback(() => {
    setIsMenuOpen(false);
    setIsAddToPlanlistConfirmDialogOpen(true);
  }, []);

  const onClickAddToIssue = useCallback(() => {
    setIsMenuOpen(false);
    setIsAddToIssueDialogOpen(true);
  }, []);

  const onClickCreateIssue = useCallback(() => {
    setIsMenuOpen(false);
    setIsCreateIssueDialogOpen(true);
  }, []);

  const onCloseRemoveFromPlanlistDialog = useCallback((dialogSuccessMessage?: string) => {
    setIsRemoveFromPlanlistConfirmDialogOpen(false);
    setSuccessMessage(dialogSuccessMessage);
  }, []);

  const onCloseAddToPlanlistDialog = useCallback((planlistIdAddedTo: string | undefined) => {
    setIsAddToPlanlistConfirmDialogOpen(false);
    if (planlistIdAddedTo) {
      setDocumentScope({ key: DocumentScopeKey.Planlist, id: planlistIdAddedTo });
    }
  }, [setDocumentScope]);

  const onCloseLinkIssueDialog = useCallback(async (selectedIssueId: string | undefined) => {
    setIsAddToIssueDialogOpen(false);
    if (!selectedIssueId) return; // cancelled
    try {
      const issuesData = await getIssuesOdataQueryData({ filter: { id: selectedIssueId } });
      await mutateIssue({
        id: selectedIssueId,
        linkedDocumentVersionIds: { value: Array.from(new Set([...issuesData[0].linkedDocumentVersionIds, ...selectedDocumentVersionIds])) },
      });
      queryClient.invalidateQueries({ queryKey: documentVersionsQueryKey });
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [documentVersionsQueryKey, getIssuesOdataQueryData, getRequestErrorMessage, mutateIssue, queryClient, selectedDocumentVersionIds]);

  const onCloseCreateIssueDialog = useCallback(() => {
    setIsCreateIssueDialogOpen(false);
  }, []);

  return (
    <>
      <Button onClick={() => setIsMenuOpen(true)} sx={{ ...sx, color: theme.palette.text.primary }} ref={btnRef} id="ColumnOptionsMenuButton" disabled={isLoadingDocumentVersions && isMenuOpen}>
        {isLoadingDocumentVersions && isMenuOpen && <CircularProgress size={12} />}
        <ListOptionsIcon sx={{ mr: '-2px' }} />
        {t('list-options_button-label', 'List Options')}
      </Button>
      {isMenuOpen && !isLoadingDocumentVersions && (
        <Menu
          anchorEl={btnRef.current}
          open={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
        >
          <MenuList>
            {!!currentUserRole?.allowedActions?.has(RoleAction.Document_Sharing) && (
              <MenuItem onClick={onClickShare} disabled={isAtLeastOnePrivateDocumentSelected}>{t('list-options_share', 'Share')}</MenuItem>
            )}
            {!!isPrintAvailable && <MenuItem onClick={onClickPrint} disabled={isLoading}>{t('list-options_print', 'Print')}</MenuItem>}
            {!!currentUserRole?.allowedActions?.has(RoleAction.Document_Archiving) && (!folderId || folderAccessLevel === CollaboratorFolderAccessLevel.ReadWrite) && (
              <MenuItem onClick={onClickArchive} disabled={isLoading}>{t('list-options_archive', 'Archive')}</MenuItem>
            )}
            {!!currentPlanlistId && <MenuItem onClick={onClickRemoveFromPlanlist} disabled={canAddToOrRemoveFromPlanlist}>{t('list-options_remove-from-planlist', 'Remove from planlist')}</MenuItem>}
            <MenuItem onClick={onClickAddToPlanlist} disabled={canAddToOrRemoveFromPlanlist}>{t('list-options_add-to-planlist', 'Add to planlist')}</MenuItem>
            {!!currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_Editing) && <MenuItem onClick={onClickAddToIssue} disabled={isAtLeastOnePrivateDocumentSelected}>{t('list-options_add-to-issue-button-label', 'Add to issue')}</MenuItem>}
            {!!currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_Creation) && <MenuItem onClick={onClickCreateIssue} disabled={isAtLeastOnePrivateDocumentSelected}>{t('list-options_create-issue-button-label', 'Create new issue')}</MenuItem>}
          </MenuList>
        </Menu>
      )}
      {isShareDialogOpen && <ShareDocumentsDialog open onClose={() => setIsShareDialogOpen(false)} documentVersionIds={selectedDocumentVersionIds} />}
      {isPrintWizardOpen && <PrintWizard open onClose={() => setIsPrintWizardVisible(false)} documentVersionIds={selectedDocumentVersionIds} />}
      {isRemoveFromPlanlistConfirmDialogOpen && <ConfirmRemoveFromPlanlistDialog open onClose={onCloseRemoveFromPlanlistDialog} />}
      {isAddToPlanlistConfirmDialogOpen && <AddToPlanlistDialog onClose={onCloseAddToPlanlistDialog} documentVersionIds={selectedDocumentVersionIds} />}
      {isAddToIssueDialogOpen && <LinkExistingIssueModal onClose={onCloseLinkIssueDialog} title="" documentVersionIds={selectedDocumentVersionIds} />}
      {isCreateIssueDialogOpen && <LinkNewIssueDialog onClose={onCloseCreateIssueDialog} documentVersionIds={selectedDocumentVersionIds} suppressLocate />}
      {!!errorMessage && (
      <Dialog open>
        <DialogTitle>{t('list-options_error-dialog-title', 'Error')}</DialogTitle>
        <DialogContent>
          <Alert severity="error">{errorMessage}</Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setErrorMessage(undefined)}>{t('list-options_error-dialog-close-button-label', 'Close')}</Button>
        </DialogActions>
      </Dialog>
      )}
      {!!successMessage && (
        <Dialog open>
          <DialogTitle>{t('list-options_success-dialog-title', 'Success')}</DialogTitle>
          <DialogContent>
            <Alert severity="success">{successMessage}</Alert>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => setSuccessMessage(undefined)}>{t('list-options_success-dialog-close-button-label', 'Close')}</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
