import React from "react";
import { formatDateWithHour } from "./Date";
import TagSelect from "../Components/TagSelect";
import { LinkedIssueExpand } from "../Components/Emails";
import { getTextFromHtml, howManyVisibleTags } from "./utils";

export const getColumns = (
  t,
  tagOptions,
  isAllowTagUpdate = true,
  issues,
  statuses,
  statusFilter,
  selectedEmailByIssue,
  setSelectedEmailByIssue,
  setSelectedIssue,
  setStatusFilter,
  assignTag,
  isDraggable
) => [
  {
    dataField: "subject",
    headerClasses: "no-border align-left",
    classes: "no-border align-left emails-column-subject",
    text: t("subject", "Subject"),
    sort: true,
    value: "Subject",
    isDraggable: !isDraggable,
    formatter: (label) => (
      <p className="font-weight-600 column-subject ellipsis">{label}</p>
    ),
  },
  {
    dataField: "emailBody",
    headerClasses: "align-left",
    classes: "align-left emails-column-subject",
    text: t("text", "Text"),
    sort: true,
    value: "Text",
    isDraggable: !isDraggable,
    formatter: (body) => (
      <p className="font-weight-600 text-ellipsis">{getTextFromHtml(body)}</p>
    ),
  },
  {
    dataField: "creationDate",
    headerClasses: "align-left",
    classes: "align-left emails-column-date",
    text: t("date", "Date"),
    value: "Creation Date",
    isDraggable: !isDraggable,
    formatter: (label) => (
      <p className="column-date text-ellipsis">{formatDateWithHour(label)}</p>
    ),
  },
  {
    dataField: "from",
    text: t("sent_by", "Sent by"),
    headerClasses: "align-left mobile--hide",
    classes: "mobile--hide align-left",
    sort: true,
    value: "Sent by",
    isDraggable: !isDraggable,
    formatter: (label) => {
      return <p className="column-sent-by ellipsis">{label}</p>;
    },
  },
  {
    dataField: "to",
    text: t("recipient", "Recipient"),
    headerClasses: "align-left mobile--hide",
    classes: "mobile--hide align-left",
    value: "Recipient",
    isDraggable: !isDraggable,
    formatter: (data) => {
      const userFilter = data.filter(
        (item) => !item.includes("mail.visoplan.de")
      );
      return (
        <div className="column-more-count">
          {userFilter.length > 0 && (
            <p className="first-item text-ellipsis">{userFilter[0]}</p>
          )}
          {userFilter.length > 1 && (
            <p className="more-count">{`+ ${userFilter.length - 1}`}</p>
          )}
        </div>
      );
    },
  },
  {
    dataField: "cc",
    text: t("cc", "CC"),
    headerClasses: "align-left mobile--hide",
    classes: "mobile--hide align-left",
    value: "CC",
    isDraggable: !isDraggable,
    formatter: (data) => {
      const userFilter = data.filter(
        (item) => !item.includes("mail.visoplan.de")
      );
      return (
        <div className="column-more-count">
          {userFilter.length > 0 && (
            <p className="first-item text-ellipsis">{userFilter[0]}</p>
          )}
          {userFilter.length > 1 && (
            <p className="more-count">{`+ ${userFilter.length - 1}`}</p>
          )}
        </div>
      );
    },
  },
  {
    dataField: "linkedIssueIds",
    key: "linkedIssueIds",
    text: t("issues", "Issues"),
    sort: false,
    classes: `mobile--hide`,
    headerClasses: "mobile--hide",
    value: "Issue",
    isDraggable: !isDraggable,
    formatter: (linkedIssueIds, row) => {
      if (!linkedIssueIds || !issues) return <></>;
      const accessibleLinkedIssueIds = linkedIssueIds.filter((id) =>
        issues.some((issue) => issue.id === id)
      );

      return (
        <LinkedIssueExpand
          issues={issues}
          linkedIssueIds={accessibleLinkedIssueIds}
          isShowDropdown={selectedEmailByIssue?.id === row.id}
          statuses={statuses}
          statusFilter={statusFilter}
          onShowExpand={() => setSelectedEmailByIssue(row)}
          onCloseExpand={() => setSelectedEmailByIssue(null)}
          handleChangeStatusFilter={setStatusFilter}
          setSelectedIssue={setSelectedIssue}
        />
      );
    },
    formatExtraData: issues,
    statuses,
    statusFilter,
    selectedEmailByIssue,
  },
  {
    dataField: "attachments",
    headerClasses: "align-left mobile--hide",
    classes: "align-left mobile--hide",
    text: t("attachments", "Attachments"),
    value: "Attachment",
    isDraggable: !isDraggable,
    formatter: (data) => {
      return (
        <div className="column-more-count">
          {data.length > 0 && (
            <p className="first-item text-ellipsis text-underline text-recipient">
              {data[0].name}
            </p>
          )}
          {data.length > 1 && (
            <p className="more-count">{`+ ${data.length - 1}`}</p>
          )}
        </div>
      );
    },
  },
  {
    dataField: "tagIds",
    headerClasses: "align-left",
    classes: "align-left emails-column-tag",
    text: t("tags", "Tags"),
    value: "Tags",
    isDraggable: !isDraggable,
    formatter: (tagIds, row) => {
      const selectedValues = tagOptions.filter((item) =>
        tagIds.includes(item.value)
      );
      const visibleCount = howManyVisibleTags("Tags");
      return (
        <TagSelect
          values={tagOptions}
          onChange={(value) => assignTag(row.id, value)}
          value={selectedValues}
          isBackgroundVisible={tagIds.length <= 0}
          isDisabled={!isAllowTagUpdate}
          visibleValueCount={visibleCount}
        />
      );
    },
  },
];

export const getColumnsLoading = (t) => [
  {
    dataField: "id",
    headerClasses: "no-border align-left",
    classes: "no-border",
    text: "",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "subject",
    headerClasses: "no-border align-left",
    classes: "no-border align-left emails-column-subject",
    text: t("subject", "Subject"),
    value: "Subject",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "emailBody",
    headerClasses: "align-left",
    classes: "align-left emails-column-subject",
    text: t("text", "Text"),
    sort: true,
    value: "Text",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "creationDate",
    headerClasses: "align-left",
    classes: "align-left",
    text: t("date", "Date"),
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "from",
    text: t("sent_by", "Sent by"),
    headerClasses: "align-left mobile--hide",
    classes: "mobile--hide align-left",
    sort: true,
    value: "Sent by",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "to",
    text: t("recipient", "Recipient"),
    headerClasses: "align-left mobile--hide",
    classes: "mobile--hide align-left",
    value: "Recipient",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "cc",
    text: t("cc", "CC"),
    headerClasses: "align-left mobile--hide",
    classes: "mobile--hide align-left",
    value: "CC",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "linkedIssueIds",
    key: "linkedIssueIds",
    text: t("issues", "Issues"),
    classes: `mobile--hide`,
    headerClasses: "mobile--hide",
    value: "Issue",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "attachments",
    headerClasses: "align-left mobile--hide",
    classes: "align-left mobile--hide",
    text: t("attachments", "Attachments"),
    value: "Attachment",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "tagIds",
    headerClasses: "align-left",
    classes: "align-left emails-column-tag",
    text: t("tags", "Tags"),
    value: "Tags",
    formatter: () => <div className="preloader" />,
  },
];
