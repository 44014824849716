import { useState, useEffect } from 'react';
import { PdfAnotationService } from 'Services';
import { PDFAnnotation } from 'Entities/RestEntities/PDFAnnotation';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';

interface UseAnnotationProps {
  issueId: string | undefined;
}

export default function useAnnotation({ issueId }: UseAnnotationProps) {
  const projectId = useCurrentProjectId();
  const [pdfAnnotations, setPdfAnnotations] = useState<PDFAnnotation[] >(
    [],
  );

  useEffect(() => {
    if (!issueId || !projectId) return;

    PdfAnotationService.getAnnotationsByIssueId(projectId, issueId).then(
      (res: PDFAnnotation[] | null) => {
        if (res === null) { return; }
        setPdfAnnotations(res);
      },
    );
  }, [issueId, projectId]);

  return {
    pdfAnnotations,
  };
}
