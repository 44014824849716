import { useMemo } from 'react';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import LabelDto from 'labels/types/LabelDto';

export function resolvedIssueStatusFilterPredicate(issueStatus: LabelDto) {
  return issueStatus.isDefault && issueStatus.originalName === 'Resolved';
}

export default function useResolvedIssueStatus() {
  const { data: issueStatuses } = useProjectIssueStatusesQuery();
  return useMemo(() => issueStatuses?.find(resolvedIssueStatusFilterPredicate), [issueStatuses]);
}
