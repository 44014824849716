import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const ColumnsOrder = ({
  setHideColumns,
  hideColumns,
  setResized,
  resized,
  clickChangeOrder,
  clickChangeWidth,
  columnsData,
  checkedValues,
  saveNewView,
}) => {
  const [selectedValue, setSelectedValue] = useState(true);
  const { t } = useTranslation();
  return (
    <div className={"columns-block mobile--hide"}>
      <div
        className={"change-columns"}
        onClick={() => setHideColumns(!hideColumns)}
      >
        <div className={"three-dots"}>
          <svg className="icon">
            <use xlinkHref="/img/svg/dots.svg#dots" />
          </svg>
        </div>
        <span>{t("columns", "Columns")}</span>
      </div>
      {hideColumns ? (
        <div
          className={"check-columns custom-scrollbar"}
          onMouseOver={() => setResized(!resized)}
        >
          <div className={"change-order"}>
            <span>{t("change", "Change")}</span>
            <span className={"change"} onClick={() => clickChangeOrder()}>
              {t("order", " order ")}
            </span>
            {"/"}
            <span className={"change"} onClick={() => clickChangeWidth()}>
              {t("width", " width")}
            </span>
          </div>
          <div className={"names-of-columns"}>
            {columnsData.map((column) => {
              return (
                <div className={"column-name"} key={column.id}>
                  <input
                    type="checkbox"
                    value={column.value}
                    name="columnsCheck"
                    disabled={column.disable}
                    onClick={() => setSelectedValue(!selectedValue)}
                    defaultChecked={checkedValues.some(
                      (i) => i.value === column.value
                    )}
                  />
                  <span>{column.name}</span>
                </div>
              );
            })}
            <div className={"save-data"} onClick={() => saveNewView()}>
              {t("save", "Save")}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
