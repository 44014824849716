import {
  useCallback, useMemo, useContext, useState,
} from 'react';
import { GridApi, RowDragEndEvent } from '@ag-grid-community/core';
import DocumentDataGridRow from 'documents/types/DocumentDataGridRow';
import DocumentDragAndDropContext, { DocumentDragAndDropContextState } from 'documents/contexts/DocumentDragAndDropContext';
import DocumentListDto from 'documents-lists/types/DocumentListDto';
import DocumentListUpdateDocumentsDto from 'documents-lists/types/DocumentListUpdateDocumentsDto';
import useDocumentListUpdateMutation from 'documents-lists/hooks/useDocumentListUpdateDocumentsMutation';
import { useTranslation } from 'react-i18next';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useDocumentListQueryData from 'documents-lists/hooks/useDocumentListQueryData';
import useCurrentUserQueryData from 'users/hooks/useCurrentUserQueryData';
import useUserGroupsOdataQueryData from 'users-groups/hooks/useUserGroupsOdataQueryData';
import { ITEM_ROOT } from 'odata-query';

export default function usePlanlistDataDropZoneParams() {
  const { t } = useTranslation('documents-lists');
  const {
    setPlanlistDataGridDropZoneParams,
    setDraggedOverDocumentListId,
    setIsDragOnPlanlistInProgress,
  } = useContext<DocumentDragAndDropContextState>(DocumentDragAndDropContext);
  const { mutateAsync } = useDocumentListUpdateMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const clearErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const clearSuccessMessage = useCallback(() => setSuccessMessage(undefined), []);

  const getPlanlist = useDocumentListQueryData();
  const getCurrentUser = useCurrentUserQueryData();
  const getUserGroups = useUserGroupsOdataQueryData();
  const canWritePlanlist = useCallback(async (planlistId: string | undefined) => {
    if (!planlistId) return undefined;
    const currentUser = await getCurrentUser();
    if (!currentUser) throw new Error('currentUser is undefined');
    const planlist = await getPlanlist(planlistId);
    const currentUsersGroups = await getUserGroups({ filter: { userIds: { any: { [ITEM_ROOT]: currentUser.id } }, isDeleted: false } });
    const currentUsersGroupsIdsSet = new Set(currentUsersGroups.map((g) => g.id));
    return (planlist.writeAccessUserIds.some((id) => id === currentUser.id) || planlist.writeAccessGroupIds.some((id) => currentUsersGroupsIdsSet.has(id)));
  }, [getCurrentUser, getPlanlist, getUserGroups]);

  const moveDocuments = useCallback(async (rows: DocumentDataGridRow[], targetPlanlist: DocumentListDto) => {
    const alreadyAssignedDocumentVersionIdsSet = new Set(targetPlanlist.documentVersionIds);
    const newDocumentVersionIds = rows.map((row) => row.id).filter((id) => !alreadyAssignedDocumentVersionIdsSet.has(id));
    if (newDocumentVersionIds.length > 0) {
      if (await canWritePlanlist(targetPlanlist.id)) {
        const updateDto: DocumentListUpdateDocumentsDto = {
          id: targetPlanlist.id,
          documentVersionIds: [...targetPlanlist.documentVersionIds, ...newDocumentVersionIds],
        };
        try {
          await mutateAsync(updateDto);
          let successFeedbackMessage = t('planlist-tree-drop-zone_documents-moved-success-message', 'Successfully added {{count}} documents to the planlist {{planlist}}.', { count: newDocumentVersionIds.length, planlist: targetPlanlist.name });
          if (newDocumentVersionIds.length < rows.length) successFeedbackMessage += ` ${t('planlist-tree-drop-zone_documents-moved-already-on-list-message', 'The remaining {{count}} documents already are on this planlist.', { count: rows.length - newDocumentVersionIds.length })}`;
          setSuccessMessage(successFeedbackMessage);
        } catch (e) {
          const reason = getRequestErrorMessage(e) ?? 'Unknown';
          setErrorMessage(t('planlist-tree-drop-zone_documents-moved-error-message', 'Failed to move {{count}} documents. Reason: {{reason}}', { count: rows.length, reason }));
        }
      } else {
        setErrorMessage(t('planlist-tree-drop-zone_no-planlist-write-rights', 'You can not edit this plan list because you do not have write access rights for it.'));
      }
    } else {
      setErrorMessage(t('planlist-tree-drop-zone_documents-already-on-list', 'All dropped documents are already on the planlist.'));
    }
  }, [canWritePlanlist, getRequestErrorMessage, mutateAsync, t]);

  const initializeDropZoneParams = useCallback((api: GridApi<DocumentListDto>) => {
    const dropZoneParams = api.getRowDropZoneParams({
      async onDragStop({ overNode, nodes }: RowDragEndEvent<DocumentListDto>) {
        // at this point, AG Grid's type system does not allow to set a different type for the drag-and-drop payload (so the inferred type of node.data is wrong)
        // => we have to "cast" the payload. This works as long as we only allow dragging documents on the folder tree
        const documentDataGridRows = nodes.map((node) => node.data as any).filter((data) => !!data?.documentId).map((data) => data as DocumentDataGridRow);
        if (overNode?.data) {
          moveDocuments(documentDataGridRows, overNode.data);
        }
        setDraggedOverDocumentListId(undefined);
        setIsDragOnPlanlistInProgress(false);
      },
      onDragEnter() {
        setIsDragOnPlanlistInProgress(true);
      },
      onDragging({ overNode }: RowDragEndEvent<DocumentListDto>) {
        setDraggedOverDocumentListId(overNode?.id);
      },
      onDragLeave() {
        setIsDragOnPlanlistInProgress(false);
      },
    });
    setPlanlistDataGridDropZoneParams(dropZoneParams);
  }, [moveDocuments, setDraggedOverDocumentListId, setIsDragOnPlanlistInProgress, setPlanlistDataGridDropZoneParams]);

  return useMemo(() => ({
    initializeDropZoneParams,
    errorMessage,
    clearErrorMessage,
    successMessage,
    clearSuccessMessage,
  }), [clearErrorMessage, clearSuccessMessage, errorMessage, initializeDropZoneParams, successMessage]);
}
