import React, { ComponentType } from 'react';
import { Box } from '@mui/material';

interface LegacyTabPaneWrapperProps {
  TabPaneComponent: ComponentType<any>,
}

export default function LegacyTabPaneWrapper({
  TabPaneComponent,
}: LegacyTabPaneWrapperProps) {
  return (
    <Box className="tab-view">
      <Box className="tab-content selected" sx={{ p: 8 }}>
        <TabPaneComponent />
      </Box>
    </Box>
  );
}
