import { useQueryClient } from '@tanstack/react-query';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import useDocumentVersionsOdataQueryKey from 'documents/hooks/useDocumentVersionsOdataQueryKey';
import { QueryOptions } from 'odata-query';
import { useCallback } from 'react';

export default function useDocumentVersionsOdataQueryData() {
  const queryClient = useQueryClient();
  const { getQueryKey } = useDocumentVersionsOdataQueryKey();
  return useCallback(async (odataQuery: Partial<QueryOptions<DocumentVersionDto>>) => {
    const queryKey = getQueryKey(odataQuery);
    return await queryClient.fetchQuery<DocumentVersionDto[]>({ queryKey });
  }, [getQueryKey, queryClient]);
}
