import { useState, useCallback, useEffect } from 'react';

import IssueOld from 'issues/types/IssueOld';

interface UseIssueHierarchyLevelProps {
  issue: IssueOld;
  issues: IssueOld[];
}

export default function useIssueHierarchyLevel({
  issue,
  issues,
}: UseIssueHierarchyLevelProps) {
  const [hierarchyLevel, setHierarchyLevel] = useState(0);

  const getHierarchyLevel = useCallback<any>((rowIssue: IssueOld, hrLevel: number) => {
    const parentIssue = issues.find((item) => item.id === rowIssue.parentId);
    if (parentIssue?.parentId) return getHierarchyLevel(parentIssue, hrLevel + 1);
    return hrLevel;
  }, [issues]);

  useEffect(() => {
    if (!issue.parentId || !issues?.length) return;
    setHierarchyLevel(getHierarchyLevel(issue, 1));
  }, [issue, issues, getHierarchyLevel]);

  return { hierarchyLevel };
}
