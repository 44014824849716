import {
  useMemo, useContext, useCallback, useState,
} from 'react';
import {
  RowDragEndEvent, RowDropZoneParams,
} from '@ag-grid-community/core';
import DocumentDragAndDropContext, { DocumentDragAndDropContextState } from 'documents/contexts/DocumentDragAndDropContext';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';

export default function usePlanlistCreateNewDropZoneParams() {
  const { setPlanlistCreateNewDropZoneParams, setIsDragOnPlanlistInProgress, setDocumentVersionIdsDroppedOnCreateNewDropzone } = useContext<DocumentDragAndDropContextState>(DocumentDragAndDropContext);
  const [isDraggedOverCreateNewPlanlistDropZone, setIsDraggedOverCreateNewPlanlistDropZone] = useState<boolean>(false);

  const initializeDropZoneParams = useCallback((element: HTMLElement) => {
    const dropzoneParams: RowDropZoneParams = {
      getContainer() {
        return element;
      },
      onDragEnter() {
        setIsDragOnPlanlistInProgress(true);
        setIsDraggedOverCreateNewPlanlistDropZone(true);
      },
      onDragLeave() {
        setIsDraggedOverCreateNewPlanlistDropZone(false);
        setIsDragOnPlanlistInProgress(false);
      },
      onDragStop({ nodes }: RowDragEndEvent) {
        const documentVersionIds = nodes.map((node) => node.data as any).filter((data) => !!data?.documentId).map((data) => (data as DocumentsDataGridRow).id) ?? [];
        setDocumentVersionIdsDroppedOnCreateNewDropzone(documentVersionIds);
        setIsDraggedOverCreateNewPlanlistDropZone(false);
        setIsDragOnPlanlistInProgress(false);
      },
    };
    setPlanlistCreateNewDropZoneParams(dropzoneParams);
  }, [setPlanlistCreateNewDropZoneParams, setIsDragOnPlanlistInProgress, setDocumentVersionIdsDroppedOnCreateNewDropzone]);

  return useMemo(() => ({
    initializeDropZoneParams,
    isDraggedOverCreateNewPlanlistDropZone,
  }), [initializeDropZoneParams, isDraggedOverCreateNewPlanlistDropZone]);
}
