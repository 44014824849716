import React from "react";
import { withTranslation } from "react-i18next";

import { Modal } from "../../HOC";

const SaveFilterModal = ({
  t,
  hideFilterModal,
  setNewFilters,
  newFilters,
  handleSaveFilter,
}) => {
  return (
    <Modal>
      <div className="filter-options_modal">
        <h2 className="filter-options_modal--title">
          {t("save_filter", "Save Filter")}
        </h2>

        <div className="filter-options_modal--content">
          <div className="name-filter">
            <label>{t("filter_name", "Name of Filter")}</label>
            <input
              className="form-control"
              type="text"
              value={newFilters.name}
              onChange={(e) =>
                setNewFilters({
                  ...newFilters,
                  name: e.target.value,
                })
              }
            />
          </div>

          <div className="filter-options_modal--radio">
            <label>{t("define_visibility", "Define Visibility")}</label>
            <div className="visibility">
              <div className="radio-button">
                <input
                  id="private"
                  type="radio"
                  checked={newFilters.isPrivate}
                  onChange={() =>
                    setNewFilters({
                      ...newFilters,
                      isPrivate: true,
                    })
                  }
                />
                <label htmlFor="private">{t("private", "Private")}</label>
              </div>
              <div className="radio-button">
                <input
                  id="public"
                  type="radio"
                  checked={!newFilters.isPrivate}
                  onChange={() =>
                    setNewFilters({
                      ...newFilters,
                      isPrivate: false,
                    })
                  }
                />
                <label htmlFor="public">{t("public", "Public")}</label>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons">
          <button
            className="btn btn--secondary"
            onClick={() => hideFilterModal()}
          >
            {t("cancel", "Cancel")}
          </button>
          <button
            className="btn btn--primary"
            onClick={() => handleSaveFilter()}
          >
            {t("save_filter", "Save Filter")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default withTranslation()(SaveFilterModal);
