import { useContext } from 'react';
import { useDrop } from 'react-dnd';
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend';
import DocumentUploadContext, { DocumentUploadContextState } from 'upload/contexts/DocumentUploadContext';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import useCurrentUserFolderAccessLevel from 'documents-folders/hooks/useCurrentUserFolderAccessLevel';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';

export function CustomHtml5Backend(manager: any) {
  const backend = HTML5Backend(manager);
  // @ts-ignore
  const orgTopDropCapture = backend.handleTopDropCapture;

  // @ts-ignore
  backend.handleTopDropCapture = (ev) => {
    orgTopDropCapture.call(backend, ev);
    if (ev.dataTransfer.items) {
      const folderNames: string[] = [];
      [...ev.dataTransfer.items].forEach((item) => {
        if (item.kind === 'file' && 'webkitGetAsEntry' in item) {
          const file = item.webkitGetAsEntry() as FileSystemEntry;
          if (file.isDirectory) {
            folderNames.push(file.name);
          }
        }
      });
      // @ts-ignore
      backend.currentNativeSource.item.folderNames = folderNames;
    }
  };

  return backend;
}

export default function useDocumentUploadDropRef(folderId: string | undefined) {
  const { openWizard, addToDocumentsStagedForUpload, uploadingFileHandles } = useContext<DocumentUploadContextState>(DocumentUploadContext);
  const currentUserRole = useCurrentUserRole();
  const accessLevel = useCurrentUserFolderAccessLevel(folderId);

  const [{ isActive: canDropDocuments }, documentUploadDropRef] = useDrop<{ files: File[] }, void, { isActive: boolean }>(() => ({
    accept: [NativeTypes.FILE],
    canDrop: () => !uploadingFileHandles?.length && !!folderId && !!currentUserRole?.allowedActions?.has(RoleAction.Document_Upload) && accessLevel === CollaboratorFolderAccessLevel.ReadWrite,
    collect: (monitor) => ({ isActive: monitor.canDrop() && monitor.isOver() }),
    drop(item: { files: File[], folderNames?: string[] } | undefined) {
      if (item?.files?.length && folderId) {
        addToDocumentsStagedForUpload({
          files: item.files,
          fileNamesThatAreFolders: item.folderNames,
          folderId,
        });
        openWizard();
      }
    },
  }), [folderId, uploadingFileHandles]);

  if (!folderId) return { canDropDocuments: false, documentUploadDropRef: undefined };

  return { canDropDocuments, documentUploadDropRef };
}
