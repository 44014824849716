import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import UploadEmailAttachmentsModal from 'emails/components/UploadEmailAttachmentsModal';
import { AttachmentItem } from "../../Emails";
import TagSelect from "../../TagSelect";
import NotificationModal from "../../NotificationModal";
import { IconButton } from "../../Buttons";
import LinkIssueDropdown from "../../Emails/LinkIssueDropdown";
import LinkedTaskItem from "../../IssueBoard/LinkedTaskItem";

import * as Utils from "../../../Helpers/utils";
import { formatDateWithHour } from "../../../Helpers/Date";
import { useNavigate } from "react-router-dom";

export default function AsideEmailDetail({
  email,
  issues,
  tagOptions,
  show_aside,
  isAllowTagUpdate,
  baseEmailsUrl,
  statuses,
  types,
  isTagsSearch,
  assignTag,
  setAsideVisibleStatus,
  setShowExistingIssueView,
  setShowCreateIssueView,
  setSelectedIssue,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [attachSelectedStates, setAttachSelectedStates] = useState([]);
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [isVisibleSaveAttachmentModal, setIsVisibleSaveAttachmentModal] =
    useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [notification, setNotification] = useState(null);

  const linkedIssues = useMemo(() => {
    if (!email || !email.linkedIssueIds?.length || !issues) return [];
    return issues.filter((issue) =>
      email.linkedIssueIds.some((id) => id === issue.id)
    );
  }, [email, issues]);

  //initialize tag select
  useEffect(() => {
    if (!email) return;
    setSelectedTags(
      tagOptions?.filter((item) => email.tagIds.includes(item.value))
    );
  }, [tagOptions, email]);

  //initialize attachment checkbox status
  useEffect(() => {
    const buffer = [];
    if (email)
      email.attachments.forEach(() => {
        buffer.push(false);
      });
    setAttachSelectedStates(buffer);
  }, [email]);

  const handleCheckChange = useCallback(
    (e, index) => {
      const isChecked = e.target.checked;
      setAttachSelectedStates(
        Utils.replaceArrayItem(attachSelectedStates, isChecked, index)
      );
    },
    [attachSelectedStates]
  );

  const toggleSaveAttachmentModal = useCallback(() => {
    if (!isVisibleSaveAttachmentModal)
      setSelectedAttachments(
        email.attachments.filter((item, index) => attachSelectedStates[index])
      );
    setIsVisibleSaveAttachmentModal(!isVisibleSaveAttachmentModal);
  }, [isVisibleSaveAttachmentModal, attachSelectedStates, email]);

  if (!email) return <></>;

  return (
    <aside
      className={
        "main__aside aside aside-email-detail custom-scrollbar" +
        (show_aside ? " is-open" : "")
      }
    >
      {/* <div className="aside-email-detail-separator mobile--show" /> */}
      <div className="aside-email-detail-header">
        <div className="aside-email-detail-header-row">
          <div className="aside-email-detail-header-left">
            <label className="aside-email-detail-header-label">
              {`${t("from", "From")}:`}
            </label>
            <p className="aside-email-detail-header-email">{email.from}</p>
          </div>
          <div className="aside-email-detail-header-right">
            {!isAllowTagUpdate && !selectedTags?.length ? (
              <></>
            ) : (
              <TagSelect
                values={tagOptions}
                onChange={(value) => assignTag(email.id, value)}
                value={selectedTags}
                isBackgroundVisible={selectedTags.length <= 0}
                isDisabled={!isAllowTagUpdate}
              />
            )}
            <IconButton
              id="CloseEmailDetailButton"
              className="btn-close"
              iconId="close"
              onClick={() => {
                setAsideVisibleStatus(0);
                !!navigate && navigate(`${baseEmailsUrl}`);
              }}
            />
          </div>
        </div>
        <div className="aside-email-detail-header-row">
          <div className="aside-email-detail-header-recipient">
            <label className="aside-email-detail-header-label aside-email-detail-header-recipient-label">{`${t(
              "to_recipient",
              "to"
            )}:`}</label>
            <div className="aside-email-detail-header-recipient-email">
              {email.to.map((item, index) => (
                <p
                  key={index}
                  className="aside-email-detail-header-recipient-email-item"
                >
                  {item}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`aside-email-detail-content${
          email.attachments?.length ? "" : " bigger-height"
        }`}
      >
        <div className="aside-email-detail-content-header">
          <h4 className="aside-email-detail-content-subject text-ellipsis">
            {email.subject}
          </h4>
          <p className="aside-email-detail-content-date">
            {email.creationDate
              ? formatDateWithHour(new Date(email.creationDate))
              : ""}
          </p>
        </div>
        <div className="aside-email-detail-content-body custom-scrollbar">
          <article
            dangerouslySetInnerHTML={{ __html: email ? email.emailBody : "" }}
          />
        </div>
      </div>
      {email.attachments?.length > 0 && (
        <div className="aside-email-detail-attachment">
          <div className="aside-email-detail-attachment-top mobile--hide">
            <div>
              <h3>{t("attachment", "Attachment")}</h3>
              <p>
                {`${email.attachments.length} ${t(
                  "attachments",
                  "Attachments"
                )}`}
              </p>
            </div>
            {!isTagsSearch ? (
              <button
                id="AddToDocumentsEmailDetailButton"
                className="btn btn--primary btn-add"
                onClick={toggleSaveAttachmentModal}
                disabled={!attachSelectedStates.some((state) => !!state)}
              >
                {t("add_to_documents", "Add to documents")}
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="aside-email-detail-attachment-bottom custom-scrollbar">
            {email.attachments.map((item, index) => (
              <AttachmentItem
                id="SelectAttachmentEmailDetailCheckbox"
                key={`attach-item-${index}`}
                info={item}
                isChecked={!!attachSelectedStates[index]}
                handleCheckChange={(e) => handleCheckChange(e, index)}
                isTagsSearch={isTagsSearch}
              />
            ))}
          </div>
        </div>
      )}
      {
        <div className="aside-email-detail-footer">
          <div className="aside-email-detail-footer_header">
            <p className="aside-email-detail-header-email">
              {`${t("linked_issues", "Linked Issues")}`}
            </p>
            {!!email.linkedIssueIds?.length && (
              <p className="aside-email-detail-footer-email-count">
                {`${email.linkedIssueIds?.length} ${t("emails", "Emails")}`}
              </p>
            )}
            {!isTagsSearch && (
              <LinkIssueDropdown
                id="LinkIssueEmailDetailButton"
                setShowExistingIssueView={setShowExistingIssueView}
                setShowCreateIssueView={setShowCreateIssueView}
              />
            )}
          </div>
          <div className="aside-email-detail-footer_content">
            <div className="issue-link-tasks_item">
              <div className="issue-link-tasks_item-title">
                {t("issue_name", "Issue Name")}
              </div>
              <div className="issue-link-tasks_item-content mobile--hide">
                <div className="item-content_column">
                  <p className="column-header">{t("due_to", "Due to")}</p>
                </div>
                <div className="item-content_column">
                  <p className="column-header">
                    {t("assigned_to", "Assigned to")}
                  </p>
                </div>
                <div className="item-content_column">
                  <p className="column-header">{t("reviewer", "Reviewer")}</p>
                </div>
                <div className="item-content_column">
                  <p className="column-header">{t("status", "Status")}</p>
                </div>
              </div>
            </div>
            <div className="issue-link-tasks_content custom-scrollbar">
              {!!email.linkedIssueIds?.length &&
                email.linkedIssueIds.map((id, index) => (
                  <LinkedTaskItem
                    key={`task-${index}`}
                    linkedIssue={linkedIssues.find((issue) => issue.id === id)}
                    types={types}
                    statuses={statuses}
                    setCurrentIssue={setSelectedIssue}
                    isVisibleDetail={true}
                  />
                ))}
            </div>
          </div>
        </div>
      }
      {isVisibleSaveAttachmentModal && (
        <UploadEmailAttachmentsModal
          initialAttachments={selectedAttachments}
          emailId={email.id}
          onClose={() => setIsVisibleSaveAttachmentModal(false)}
        />
      )}
      {!!notification && (
        <NotificationModal
          onDone={() => {
            setNotification(null);
            setIsVisibleSaveAttachmentModal(false);
          }}
          notification={notification}
          isRenderContent={true}
        />
      )}
    </aside>
  );
}
