import React, { useCallback, useMemo } from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import HorizontalDivider from 'common/styled/HorizontalDivider';
import IssuesDataGrid from 'issues/components/IssuesDataGrid';
import { Resizable } from 're-resizable';
import { useTranslation } from 'react-i18next';
import useIssuesSelectionContext from 'issues/hooks/useIssuesSelectionContext';
import IssueDetailsPanel from 'issues/components/IssueDetailsPanel';
import IssueQuickFilterBar from 'issues/components/IssueQuickFilterBar';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';

interface LinkExistingIssuePanelProps extends ISxProps {
  title: string,
  documentVersionIds: string[] | undefined,
  onClose: (selectedIssueId: string | undefined) => void,
}

export default function LinkExistingIssuePanel({
  sx,
  title,
  documentVersionIds,
  onClose,
}: LinkExistingIssuePanelProps) {
  const { t } = useTranslation('issues');
  const { selectedIssueIds } = useIssuesSelectionContext();
  const { data: documentVersions } = useDocumentVersionsQuery(documentVersionIds ? { filter: { id: { in: documentVersionIds } } } : undefined);
  const issueIdsAlreadyLinkedToAnyVersion = useMemo(() => (documentVersions ? new Set(documentVersions.flatMap((v) => v.documentLinkedIssueIds)) : undefined), [documentVersions]);
  const errorMessage = useMemo(() => {
    if (!documentVersions) return undefined;
    const uniqueDocumentIds = new Set(documentVersions.map((d) => d.documentId));
    if (uniqueDocumentIds.size < documentVersions.length) {
      return t('link-existing-issue-modal_multiple-versions-of-same-document-error-message', 'You have selected multiple versions of the same document, but an issue can only be linked to a single version of the same document.');
    }
    return undefined;
  }, [documentVersions, t]);
  const onClickCancel = useCallback(() => {
    onClose(undefined);
  }, [onClose]);
  const onClickConfirm = useCallback(() => {
    if (selectedIssueIds.length !== 1) return;
    onClose(selectedIssueIds[0]);
  }, [onClose, selectedIssueIds]);
  return (
    <Box id="LinkExistingIssuePanel" sx={{ ...sx, flex: '1 1 0', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flex: '0 0 0', p: 2, gap: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h3">{title}</Typography>
        <IssueQuickFilterBar />
      </Box>
      <HorizontalDivider />
      <Box sx={{ flex: '1 1 0', overflow: 'hidden', display: 'flex' }}>
        <Box sx={{ flex: '1 1 0', overflow: 'hidden' }}>
          <IssuesDataGrid disabledIssueIds={issueIdsAlreadyLinkedToAnyVersion} />
        </Box>
        {selectedIssueIds.length === 1 && (
          <Resizable
            style={{ boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.1)' }}
            enable={{ top: false, right: false, bottom: false, left: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
            defaultSize={{ width: '350px', height: 'auto' }}
            minWidth="350px"
            handleComponent={{ left: <Box sx={{ height: '100%', width: '8px', backgroundColor: 'transparent' }}><Box sx={{ height: '100%' }} /></Box> }}
            handleStyles={{ left: { left: 0 } }}
          >
            <Box
              sx={{
                height: '100%',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <IssueDetailsPanel issueId={selectedIssueIds[0]} nonInteractive />
            </Box>
          </Resizable>
        )}
      </Box>
      <HorizontalDivider />
      <Box sx={{ flex: '0 0 0', display: 'flex', justifyContent: 'flex-end', gap: 4, p: 2 }}>
        {!!errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Button variant="contained" color="secondary" onClick={onClickCancel}>{t('link-existing-issue-dialog_cancel-button-label', 'Cancel')}</Button>
        <Button variant="contained" color="primary" onClick={onClickConfirm} disabled={selectedIssueIds.length !== 1} sx={{ minWidth: 120 }}>{t('link-existing-issue-dialog_confirm-button-label', 'Confirm')}</Button>
      </Box>
    </Box>
  );
}
