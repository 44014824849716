import React, { useEffect, useState } from "react";
import Direction from "./Direction";

const Resizer = ({ onResize }) => {
  const [direction, setDirection] = useState("");
  const [mouseDown, setMouseDown] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!direction) return;

      const ratio = window.devicePixelRatio;
      onResize(direction, e.movementX / ratio, e.movementY / ratio);
    };

    if (mouseDown) {
      window.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [mouseDown, direction, onResize]);

  useEffect(() => {
    const handleMouseUp = () => setMouseDown(false);

    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const handleMouseDown = (direction) => () => {
    setDirection(direction);
    setMouseDown(true);
  };

  return (
    <>
      <div
        className="resizer_top"
        onMouseDown={handleMouseDown(Direction.Top)}
      ></div>

      <div
        className="resizer_right"
        onMouseDown={handleMouseDown(Direction.Right)}
      ></div>

      <div
        className="resizer_bottom"
        onMouseDown={handleMouseDown(Direction.Bottom)}
      ></div>

      <div
        className="resizer_left"
        onMouseDown={handleMouseDown(Direction.Left)}
      ></div>
    </>
  );
};

export default Resizer; //https://github.com/nmingaleev/react-draggable-resizable-panel
