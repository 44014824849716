import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { MenuItem, Select } from '@mui/material';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import DocumentVersionFloorUpdateDto from 'documents/types/DocumentVersionFloorUpdateDto';
import useDocumentVersionFloorUpdateMutation from 'documents/hooks/useDocumentVersionFloorUpdateMutation';
import DocumentAbbreviationDisplayMode from 'documents/types/DocumentAbbreviationDisplayMode';
import DocumentOptionsContext, { DocumentOptionsContextState } from 'documents/contexts/DocumentOptionsContext';
import { useTranslation } from 'react-i18next';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import useCurrentUserFolderAccessLevel from 'documents-folders/hooks/useCurrentUserFolderAccessLevel';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';

export default function FloorCell({
  data,
  value,
}: ICellRendererParams<DocumentsDataGridRow, string | undefined>) {
  const { t } = useTranslation('documents');
  const currentUserRole = useCurrentUserRole();
  const { data: floors } = useProjectFloorsQuery();
  const { mutateAsync, isLoading } = useDocumentVersionFloorUpdateMutation();
  const [displayValue, setDisplayValue] = useState<string>(value ?? '');
  const { abbreviationDisplayMode } = useContext<DocumentOptionsContextState>(DocumentOptionsContext);
  const accessLevel = useCurrentUserFolderAccessLevel(data?.folderId);

  const onChange = useCallback(async (selectedFloorMetadataId: string) => {
    if (!data) return;
    setDisplayValue(selectedFloorMetadataId);
    const persistDto: DocumentVersionFloorUpdateDto = {
      id: data.id,
      floorId: selectedFloorMetadataId || undefined,
    };
    await mutateAsync(persistDto);
  }, [data, mutateAsync]);

  useEffect(() => setDisplayValue(value ?? ''), [value]);

  return (
    <Select
      value={displayValue}
      disabled={!floors?.length || isLoading || data?.isArchived || !currentUserRole?.allowedActions?.has(RoleAction.Document_Editing) || accessLevel !== CollaboratorFolderAccessLevel.ReadWrite}
      onChange={(e) => onChange(e.target.value)}
      variant="standard"
      sx={{ width: '100%' }}
    >
      <MenuItem value=""><i>{t('floor-cell_value-none', 'Unassigned')}</i></MenuItem>
      {floors?.map((floorMetaData) => (
        <MenuItem value={floorMetaData.id} key={floorMetaData.id}>{abbreviationDisplayMode === DocumentAbbreviationDisplayMode.Abbreviation && floorMetaData.abbreviation?.length ? floorMetaData.abbreviation : floorMetaData.name }</MenuItem>
      ))}
    </Select>
  );
}
