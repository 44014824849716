import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QueryOptions } from 'odata-query';
import UserGroupDto from 'users-groups/types/UserGroupDto';
import { useMemo } from 'react';
import useUserGroupsOdataQueryKey, { UserGroupsOdataQueryKey } from 'users-groups/hooks/useUserGroupsOdataQueryKey';

type UserGroupsUseQueryOptions = Omit<UseQueryOptions<UserGroupDto[] | undefined, unknown, UserGroupDto[] | undefined, UserGroupsOdataQueryKey | readonly []>, 'queryFn'>;

export default function useUserGroupsOdataQuery(odataQuery: Partial<QueryOptions<UserGroupDto>> | undefined, options?: Partial<UserGroupsUseQueryOptions>) {
  const { getQueryKey } = useUserGroupsOdataQueryKey();
  const queryKey = useMemo(() => (odataQuery ? getQueryKey(odataQuery) : ([] as const)), [getQueryKey, odataQuery]);
  const queryOptions = useMemo<UserGroupsUseQueryOptions>(() => ({
    ...options,
    queryKey,
    enabled: (options?.enabled !== false) && (queryKey.length > 0),
  }), [queryKey, options]);
  return useQuery(queryOptions);
}
