enum HardcodedIssueFilter {
  AllActiveIssues = 'all-active-issues',
  AssignedToMe = 'assigned-to-me',
  ForReview = 'for-review',
  CreatedByMe = 'created-by-me',
  MyDrafts = 'my-drafts',
  Closed = 'closed',
}

export default HardcodedIssueFilter;
