import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Link,
  Theme,
  useTheme,
  Typography,
} from '@mui/material';

import InlineTypography from 'common/components/InlineTypography';

interface IssueLoadingStatusProps {
  isFiltering: boolean;
  isSearching: boolean;
  loadedCount: number;
  totalCount: number;
  onResetSearch: () => void;
}

export default function IssueLoadingStatus({
  isFiltering,
  isSearching,
  loadedCount,
  totalCount,
  onResetSearch,
}: IssueLoadingStatusProps) {
  const { t } = useTranslation();
  const theme = useTheme<Theme>();

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      ml: 4,
      mb: 1.25,
    }}
    >
      <Typography sx={{
        fontWeight: 600,
      }}
      >
        <InlineTypography sx={{
          fontWeight: 700,
          mr: 0.25,
        }}
        >
          {isFiltering ? t('search', 'Search Results') : t('issue', 'Issue')}
        </InlineTypography>
        {`${loadedCount === 0 ? 0 : 1} - ${loadedCount} of ${totalCount}`}
      </Typography>
      {
        isSearching && (
          <Link
            sx={{
              ml: 2,
              color: theme.palette.text.secondary,
            }}
            onClick={onResetSearch}
          >
            {t('reset_search', 'Reset Search')}
          </Link>
        )
      }
    </Box>
  );
}
