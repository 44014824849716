import React from "react";
import moment from "moment";

import TagSelect from "../Components/TagSelect";
import { getTypeSvg } from "../Components/SVGs";
import { getFormattedColumnInfo, howManyVisibleTags } from "./utils";
import CheckboxOnly from "../Components/CheckboxOnly";

import useAllNestedChildrenIds from 'issues/hooks/useAllNestedChildrenIds'

/**
 * Max symbols for title to show before cut
 * @type {Number}
 */

const getFormattedDate = (t, dateStr) => {
  return t("date_only", {
    val: new Date(dateStr || ""),
    defaultValue: "{{val, datetime}}",
    formatParams: {
      val: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
    },
  });
};

export const getColumns = ({
  t,
  selected = [],
  tagOptions,
  statuses = [],
  types = [],
  workphases,
  priorities = [],
  setSelected,
  handleChange,
  cannotBeChanged,
  canAddTagsForIssue,
  isLinkIssue,
  isParent,
  selectedTask = [],
  isSelectedAll,
  setSelectedAll,
  selectAll,
  isDraggable,
  issues,
}) => [
  {
    dataField: "id",
    text: "",
    value: "ID",
    isDraggable: true,
    width: "50px",
    headerFormatter: () => {
      const random = Math.random() * 9999;
      return (
        <CheckboxOnly
          id={`check-all-${random}`}
          name="check"
          checked={!!isSelectedAll}
          onChange={() => {
            setSelectedAll(!isSelectedAll);
            selectAll(!isSelectedAll);
          }}
        />
      );
    },
    headerClasses: "no-border",
    classes: "no-border",
    formatter: (id, row) => {
      const getNestedChildrenIds = useAllNestedChildrenIds();
      const onClick = (e) => {
        const buffer = [...selected];
        const allNestedChildrenIds = getNestedChildrenIds(row, issues);
        if (e.target.checked) buffer.push(...allNestedChildrenIds);
        else allNestedChildrenIds.map((childId) => buffer.splice(buffer.indexOf(childId), 1));
        setSelected(isParent ? [row.id] : buffer);
      };
      return (
        <div className="issue-table-status d-flex">
          <CheckboxOnly
            id={isLinkIssue ? "check-link-" + row.id : "check-" + row.id}
            name="check"
            checked={!!selected.includes(row.id)}
            disabled={selectedTask.includes(row.id)}
            isRadio={isParent}
            onChange={(e) => onClick(e)}
          />
        </div>
      );
    },
    formatExtraData: selected,
    isSelectedAll,
  },
  {
    dataField: "issueType",
    text: t("type", "Type"),
    headerClasses: "no-border mobile--hide",
    classes: "no-border mobile--hide",
    sort: false,
    isDraggable: true,
    value: "Type",
    formatter: (data) => {
      const type = types.find((item) => item.id === data?.id);
      return (
        <div className="issue-type" style={{ background: `${type?.color}50` }}>
          {!!type?.icon && getTypeSvg(type?.icon, type?.color)}
        </div>
      );
    },
    formatExtraData: types,
  },
  {
    dataField: "issueNumber",
    text: t("id", "ID"),
    headerClasses: "no-border align-left issue-name",
    classes: "no-border align-left issue-name",
    sort: false,
    isDraggable: !isDraggable,
    value: "Number",
    formatter: (data) => <span className="issue-number--text">{data}</span>,
  },
  {
    dataField: "title",
    text: t("issue_name", "Issue Name"),
    headerClasses: "no-border align-left issue-name",
    classes: "no-border align-left issue-name",
    sort: false,
    isDraggable: !isDraggable,
    width: "30%",
    value: "Name",
    formatter: (data) => (
      <span className="issue-name--text text-ellipsis">{data}</span>
    ),
  },
  {
    dataField: "description",
    text: t("description", "Description"),
    headerClasses: `align-left issue-description${isLinkIssue ? " hide" : ""}`,
    classes: `align-left${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Description",
    isDraggable: !isDraggable,
    formatter: (data) => <p className="txt-maxline-1">{data || ""}</p>,
  },
  {
    dataField: "issueStatus",
    text: t("status", "Status"),
    headerClasses: "issue-status-column",
    classes: "issue-status-column",
    sort: false,
    value: "Status",
    width: "20%",
    isDraggable: !isDraggable,
    formatter: (data) => {
      const status = statuses.find((item) => item.id === data?.id);
      return (
        <p
          className="issue-status ellipsis"
          style={{
            background: `${status?.color}50`,
            color: `${status?.color}`,
          }}
        >
          {status?.label || ""}
        </p>
      );
    },
    formatExtraData: statuses,
  },
  {
    dataField: "dueDate",
    text: t("due_date", "Due date"),
    headerClasses: "mobile--hide issue-due-date-column",
    classes: "mobile--hide issue-due-date-column",
    sort: false,
    value: "Due Date",
    isDraggable: !isDraggable,
    formatter: (dateStr, row) => {
      if (!dateStr) {
        return "";
      }
      const date = new Date(dateStr);
      return (
        <p
          className={`issue--due-date label mobile--hide ellipsis ${
            row.issueStatus?.originalName !== 'Resolved' && row.issueStatus?.originalName !== 'Closed' && date.getTime() < new Date().getTime() ? "bg-red" : "bg-light-grey"
          }`}
        >
          {getFormattedDate(t, dateStr)}
        </p>
      );
    },
  },
  {
    dataField: "disciplines",
    text: t("category", "Category"),
    headerClasses: "issue-category mobile--hide",
    classes: "mobile--hide",
    isDraggable: !isDraggable,
    sort: false,
    value: "Discipline",
    formatter: (data, row, prefix = "") => {
      const doc = document.getElementById(`${prefix}Discipline`);

      if (!doc) return <></>;
      const { formattedStr, numberOfUnvisibleData } = getFormattedColumnInfo(
        data,
        doc.offsetWidth
      );

      return (
        <div className="issues-table--field">
          {formattedStr}
          {numberOfUnvisibleData > 0 && (
            <span className="label bg-light ml-auto">{`+${numberOfUnvisibleData}`}</span>
          )}
        </div>
      );
    },
  },
  {
    dataField: "models",
    text: t("models", "Models"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Models",
    isDraggable: !isDraggable,
    formatter: (data) => {
      const doc = document.getElementById("Models");
      if (!doc) return <></>;
      const { formattedStr, numberOfUnvisibleData } = getFormattedColumnInfo(
        data,
        doc.offsetWidth
      );

      return (
        <div className="issues-table--field">
          {formattedStr}
          {numberOfUnvisibleData > 0 && (
            <span className="label bg-light ml-auto">
              {`+${numberOfUnvisibleData}`}
            </span>
          )}
        </div>
      );
    },
  },
  {
    dataField: "issuePriority",
    text: t("priority", "Priority"),
    headerClasses: `issue-priority mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Priority",
    isDraggable: !isDraggable,
    formatter: (data) => {
      const priority = priorities.find((item) => item.id === data?.id);
      return <span className="ellipsis">{priority?.label || ""}</span>;
    },
    formatExtraData: priorities,
  },
  {
    dataField: "assignedUsers",
    text: t("assigned_to", "Assigned to"),
    headerClasses: "issue-assigned mobile--hide",
    classes: "issue-assigned mobile--hide",
    sort: false,
    value: "Assigned to",
    isDraggable: !isDraggable,
    formatter: (data, row, prefix = "") => {
      const doc = document.getElementById(`${prefix}Assignedto`);
      if (!doc) return <></>;
      const { formattedStr, numberOfUnvisibleData } = getFormattedColumnInfo(
        data,
        doc.offsetWidth
      );

      return (
        <div className="issues-table--field">
          {formattedStr}
          {numberOfUnvisibleData > 0 && (
            <span className="label bg-light ml-auto">
              {`+${numberOfUnvisibleData}`}
            </span>
          )}
        </div>
      );
    },
  },
  {
    dataField: "reviewer",
    text: t("reviewer", "Reviewer"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Reviewer",
    isDraggable: !isDraggable,
    formatter: (data) => (
      <span className="text-ellipsis">
        {data ? `${data.firstName} ${data.lastName}` : ""}
      </span>
    ),
  },
  {
    dataField: "workPhaseId",
    text: t("workphase", "Workphase"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Workphase",
    isDraggable: !isDraggable,
    formatter: (workphase) => {
      const selectValue = workphases.filter(
        ({ value }) => value && workphase && value === workphase
      )[0];
      return (
        <span className="text-ellipsis">
          {selectValue ? selectValue.label : ""}
        </span>
      );
    },
    formatExtraData: workphases,
  },
  {
    dataField: "editDate",
    text: t("edit_date", "Edit Date"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Edit Date",
    isDraggable: !isDraggable,
    formatter: (data) => {
      return (
        <span className="issue-edit-date--text ellipsis">
          {moment(new Date(data)).format("DD.MM.YYYY - h:mm")}
        </span>
      );
    },
  },
  {
    dataField: "createAuthor",
    text: t("creator", "Creator"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Creator",
    isDraggable: !isDraggable,
    formatter: (data) => {
      return (
        <span className={"text-ellipsis"}>{`${data?.firstName || ""} ${
          data?.lastName || ""
        }`}</span>
      );
    },
  },
  {
    dataField: "creationDate",
    text: t("creation_date", "Creation date"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Creation date",
    isDraggable: !isDraggable,
    formatter: (data) => {
      return (
        <span className="issue-creation-date--text ellipsis">
          {moment(new Date(data)).format("DD.MM.YYYY - h:mm")}
        </span>
      );
    },
  },
  {
    dataField: "startingDate",
    text: t("start_date", "Start Date"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Start Date",
    isDraggable: !isDraggable,
    formatter: (dateStr) => {
      if (!dateStr) {
        return "";
      }
      const date = new Date(dateStr);
      return (
        <div
          className={`issue--due-date label mobile--hide ellipsis ${
            date.getTime() > new Date().getTime() ? "bg-red" : "bg-dark-1"
          }`}
        >
          <span className="mobile--hide">{getFormattedDate(t, dateStr)}</span>
        </div>
      );
    },
  },
  {
    dataField: "editAuthor",
    text: t("last_edited_by", "Last Edited by"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Last Edited by",
    isDraggable: !isDraggable,
    formatter: (data) => {
      return (
        <span className="text-ellipsis">{`${data?.firstName || ""} ${
          data?.lastName || ""
        }`}</span>
      );
    },
  },
  {
    dataField: "floors",
    text: t("floor", "Storey"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    isDraggable: !isDraggable,
    value: "Storey",
    formatter: (data) => {
      const doc = document.getElementById("Storey");
      if (!doc) return <></>;
      const { formattedStr, numberOfUnvisibleData } = getFormattedColumnInfo(
        data,
        doc.offsetWidth
      );

      return (
        <div className="issues-table--field">
          {formattedStr}
          {numberOfUnvisibleData > 0 && (
            <span className="label bg-light ml-auto">{`+${numberOfUnvisibleData}`}</span>
          )}
        </div>
      );
    },
  },
  {
    dataField: "buildings",
    text: t("building", "Building"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Building",
    isDraggable: !isDraggable,
    formatter: (data) => {
      const doc = document.getElementById("Building");
      if (!doc) return <></>;
      const { formattedStr, numberOfUnvisibleData } = getFormattedColumnInfo(
        data,
        doc.offsetWidth
      );

      return (
        <div className="issues-table--field">
          {formattedStr}
          {numberOfUnvisibleData > 0 && (
            <span className="label bg-light ml-auto">{`+${numberOfUnvisibleData}`}</span>
          )}
        </div>
      );
    },
  },
  {
    dataField: "viewpointIds",
    text: t("viewpoints", "Viewpoints"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    isDraggable: !isDraggable,
    value: "Viewpoints",
    formatter: (data) => data?.length,
  },
  {
    dataField: "source",
    text: t("source", "Source"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    isDraggable: !isDraggable,
    value: "Source",
    formatter: (data) => <p className="text-ellipsis">{data}</p>,
  },
  {
    dataField: "linkedDocumentVersionIds",
    text: t("documents", "Documents"),
    headerClasses: `issue-documents mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    isDraggable: !isDraggable,
    value: "Documents (amount of Documents)",
    formatter: (data) => data?.length,
  },
  {
    dataField: "visibility",
    text: t("visibility", "Visibility"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Visibility",
    isDraggable: !isDraggable,
    formatter: (data) => {
      return (
        <span className="text-ellipsis">
          {data === 0
            ? t("public", "Public")
            : data === 1
            ? t("private", "Private")
            : t("restricted", "Restricted")}
        </span>
      );
    },
  },
  {
    dataField: "linkedEmailIds",
    text: t("linked_emails", "Linked Emails"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    isDraggable: !isDraggable,
    value: "Linked Emails",
    formatter: (data) => data?.length,
  },
  {
    dataField: "commentIds",
    text: t("comments", "Comments"),
    headerClasses: `issue-comments mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    isDraggable: !isDraggable,
    value: "Comments",
    formatter: (commentIds) => commentIds?.length,
  },
  {
    dataField: "tagIds",
    text: t("tags", "Tags"),
    headerClasses: "issue-tag",
    sort: false,
    isDraggable: !isDraggable,
    value: "Tags",
    width: "40%",
    formatter: (tagIds, row) => {
      if (!tagIds) return <></>;

      const selectValues = tagOptions?.filter((item) =>
        tagIds.includes(item.value)
      );
      const bEnable = row.name !== "Closed";
      if (
        (isLinkIssue && tagIds.length <= 0) ||
        (cannotBeChanged && tagIds.length <= 0) ||
        (row.name === "Closed" && tagIds.length <= 0)
      ) {
        return "";
      }
      const visibleCount = howManyVisibleTags("Tags");
      return canAddTagsForIssue ? (
        <TagSelect
          values={tagOptions}
          onChange={(item) => handleChange(item, row.id)}
          value={selectValues}
          isBackgroundVisible={tagIds.length < 0}
          isDisabled={cannotBeChanged || !bEnable || isLinkIssue}
          visibleValueCount={visibleCount}
        />
      ) : (
        <span>{t("no_available_tags", "No available tags")}</span>
      );
    },
    formatExtraData: tagOptions,
  },
  {
    dataField: "label",
    text: "",
    isDraggable: !isDraggable,
    headerClasses: "no-border mobile--hide",
    classes: "no-border mobile--hide",
    formatter: (label) => (
      <div>
        <div
          className={
            "mobile--hide label issue-label " +
            (label === "new" ? "bg-light" : "bg-orange-1")
          }
          style={{ visibility: label ? "visible" : "hidden" }}
        >
          {t(label) || label}
        </div>
        {/* <div
          className={
            "mobile--show label " + (label === "new" ? "bg-light" : "bg-red")
          }
          style={{ visibility: label ? "visible" : "hidden" }}
        ></div> */}
      </div>
    ),
  },
];

export const getColumnsLoading = (t, isLinkIssue) => [
  {
    dataField: "id",
    text: "",
    value: "ID",
    headerFormatter: () => {
      return (
        <div className="issue-table-status d-flex">
          <div className="checkbox checkbox--only mobile--hide">
            <input type="checkbox" name="check" id="check-all" />
            <label htmlFor="check-all" />
          </div>
        </div>
      );
    },
    headerClasses: "no-border",
    classes: "no-border",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "type",
    text: t("type", "Type"),
    headerClasses: "align-left",
    classes: "align-left",
    value: "Type",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "title",
    text: t("issue_name", "Issue Name"),
    headerClasses: "align-left",
    classes: "align-left",
    value: "Name",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "description",
    text: t("description", "Description"),
    headerClasses: "align-left mobile--hide",
    classes: "align-left mobile--hide",
    value: "Description",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "reviewer",
    text: t("reviewer", "Reviewer"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Reviewer",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "workPhaseId",
    text: t("workphase", "Workphase"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Workphase",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "editDate",
    text: t("edit_date", "Edit Date"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Edit Date",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "createAuthor",
    text: t("creator", "Creator"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Creator",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "creationDate",
    text: t("creation_date", "Creation date"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Creation date",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "startingDate",
    text: t("start_date", "Start Date"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Start Date",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "editAuthor",
    text: t("last_edited_by", "Last Edited by"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Last Edited by",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "floors",
    text: t("floor", "Storey"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Storey",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "buildings",
    text: t("building", "Building"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Building",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "viewpointIds",
    text: t("viewpoints", "Viewpoints"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Viewpoints",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "source",
    text: t("source", "Source"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Source",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "visibility",
    text: t("visibility", "Visibility"),
    headerClasses: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    sort: false,
    value: "Visibility",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "status",
    text: t("status", "Status"),
    headerClasses: "align-left mobile--hide",
    classes: "align-left mobile--hide",
    value: "Status",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "dueDate",
    text: t("due_date", "Due date"),
    value: "Due Date",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "disciplines",
    text: t("category", "Category"),
    headerClasses: "align-left mobile--hide",
    classes: "align-left mobile--hide",
    value: "Discipline",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "priority",
    text: t("priority", "Priority"),
    headerClasses: "mobile--hide",
    classes: "mobile--hide",
    value: "Priority",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "assignedUsers",
    text: t("assigned_to", "Assigned to"),
    headerClasses: "align-left mobile--hide",
    classes: "align-left mobile--hide",
    value: "Assigned to",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "linkedDocumentVersionIds",
    text: t("documents", "Documents"),
    headerClasses: `issue-documents mobile--hide${isLinkIssue ? " hide" : ""}`,
    classes: `mobile--hide${isLinkIssue ? " hide" : ""}`,
    value: "Documents (amount of Documents)",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "commentIds",
    text: t("comments", "Comments"),
    headerClasses: "mobile--hide",
    classes: "mobile--hide",
    value: "Comments",
    formatter: () => <div className="preloader" />,
  },
  {
    dataField: "tagIds",
    text: t("tags", "Tags"),
    value: "Tags",
    formatter: () => <div className="preloader" />,
  },
];
