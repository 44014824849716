/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import { Box } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import { Resizable } from 're-resizable';
import IssuesDataGrid from 'issues/components/IssuesDataGrid';
import IssueDetailsPanel from 'issues/components/IssueDetailsPanel';
import useIssuesSelectionContext from 'issues/hooks/useIssuesSelectionContext';
import IssuesTimeline from 'timeline/components/IssuesTimeline';
import IssuesFilterContextProvider from 'issues/contexts/IssuesFilterContextProvider';

interface TimelinePanelProps extends ISxProps {
}

export default function TimelinePanel({
  sx,
}: TimelinePanelProps) {
  const { selectedIssueIds } = useIssuesSelectionContext();
  return (
    <Box id="TimelinePanel" sx={{ ...sx, overflow: 'auto', boxShadow: 'inset 0px 0px 6px -2px rgba(0,0,0,0.1)', flex: '1 1 0', display: 'flex' }}>
      <Box sx={{ flex: '1 1 0', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <IssuesFilterContextProvider>
          <IssuesTimeline />
        </IssuesFilterContextProvider>
      </Box>
      {selectedIssueIds.length === 1 && (
        <Resizable
          style={{ boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.1)' }}
          enable={{ top: false, right: false, bottom: false, left: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
          defaultSize={{ width: '350px', height: 'auto' }}
          minWidth="350px"
          handleComponent={{ left: <Box sx={{ height: '100%', width: '8px', backgroundColor: 'transparent' }}><Box sx={{ height: '100%' }} /></Box> }}
          handleStyles={{ left: { left: 0 } }}
        >
          <Box
            sx={{
              height: '100%',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <IssueDetailsPanel issueId={selectedIssueIds[0]} nonInteractive />
          </Box>
        </Resizable>
      )}
    </Box>
  );
}
