import {
  useCallback, useMemo, useContext, useState,
} from 'react';
import { GridApi, RowDragEndEvent } from '@ag-grid-community/core';
import DocumentDataGridRow from 'documents/types/DocumentDataGridRow';
import FolderTreeGridRow, { RowType } from 'documents-folders/types/FolderTreeGridRow';
import DocumentDragAndDropContext, { DocumentDragAndDropContextState } from 'documents/contexts/DocumentDragAndDropContext';
import useDocumentVersionMassEditMutation from 'documents/hooks/useDocumentVersionMassEditMutation';
import UpdateManyDocumentVersionsDto from 'documents/types/UpdateManyDocumentVersionsDto';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import { useTranslation } from 'react-i18next';
import Folder from 'documents-folders/types/Folder';

export default function useFolderTreeDropZoneParams() {
  const { t } = useTranslation('documents-folders');
  const { setFolderTreeDropZoneParams, setDraggedOverFolderId } = useContext<DocumentDragAndDropContextState>(DocumentDragAndDropContext);
  const { mutateAsync } = useDocumentVersionMassEditMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const clearErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const clearSuccessMessage = useCallback(() => setSuccessMessage(undefined), []);

  const moveDocuments = useCallback(async (rows: DocumentDataGridRow[], targetFolder: Folder) => {
    const documentVersionIdsToMove = rows.map((row) => row.id);
    const updateDto: UpdateManyDocumentVersionsDto = {
      ids: documentVersionIdsToMove,
      folderId: { value: targetFolder.id },
    };
    try {
      await mutateAsync(updateDto);
      setSuccessMessage(t('folder-tree-drop-zone_documents-moved-success-message', 'Successfully moved {{count}} documents to the folder {{folder}}.', { count: rows.length, folder: targetFolder.name }));
    } catch (e) {
      const reason = getRequestErrorMessage(e) ?? 'Unknown';
      setErrorMessage(t('folder-tree-drop-zone_documents-moved-error-message', 'Failed to move {{count}} documents. Reason: {{reason}}', { count: rows.length, reason }));
    }
  }, [getRequestErrorMessage, mutateAsync, t]);

  const initializeDropZoneParams = useCallback((api: GridApi<FolderTreeGridRow>) => {
    const dropZoneParams = api.getRowDropZoneParams({
      onDragStop({ overNode, nodes }: RowDragEndEvent<FolderTreeGridRow>) {
        // at this point, AG Grid's type system does not allow to set a different type for the drag-and-drop payload (so the inferred type of node.data is wrong)
        // => we have to "cast" the payload. This works as long as we only allow dragging documents on the folder tree
        const documentDataGridRows = nodes.map((node) => node.data as any).filter((data) => !!data?.documentId).map((data) => data as DocumentDataGridRow);
        if (overNode?.data?.rowType === RowType.FolderRow) {
          moveDocuments(documentDataGridRows, overNode.data);
        }
        setDraggedOverFolderId(undefined);
      },
      onDragging({ overNode }: RowDragEndEvent<FolderTreeGridRow>) {
        setDraggedOverFolderId(overNode?.id);
      },
      onDragLeave() {
        setDraggedOverFolderId(undefined);
      },
    });
    setFolderTreeDropZoneParams(dropZoneParams);
  }, [moveDocuments, setDraggedOverFolderId, setFolderTreeDropZoneParams]);

  return useMemo(() => ({
    initializeDropZoneParams,
    errorMessage,
    clearErrorMessage,
    successMessage,
    clearSuccessMessage,
  }), [clearErrorMessage, clearSuccessMessage, errorMessage, initializeDropZoneParams, successMessage]);
}
