import * as Request from "../Helpers/Request";
import i18n from "i18next";
import * as moment from "moment";
import * as MetaDataService from "./MetadataService";
import * as WorkphaseService from "./WorkphaseService";

export const getProject = async (projectId) => {
  return await Request.GET(`api/project/${projectId}`)
    .then(async (result) => {
      if (result.status === 200) {
        const project = result.body[0];
        const [disciplines, buildings, floors] = await Promise.all([
          MetaDataService.getMetadataDiscipline(
            project["disciplineMetaDataIds"].join(",")
          ),
          MetaDataService.getMetadataBuilding(
            project["buildingMetaDataIds"].join(",")
          ),
          MetaDataService.getMetadataFloor(
            project["layerMetaDataIds"].join(",")
          ),
        ]);

        const workphases = await WorkphaseService.getWorkphases(
          project.workPhaseIds
        );

        workphases.forEach((w) => ((w.value = w.id), (w.label = w.name)));
        return {
          ...project,
          disciplines,
          buildings,
          floors,
          workphases,
        };
      } else {
        return null;
      }
    })
    .catch(() => null);
};

export const getTrialDaysLeft = (project) => {
  if (!project.trialEndDate) {
    return 0;
  }

  return moment(project.trialEndDate).diff(moment(), "days");
};
export const getCreator = (project) => {
  if (!project) return;

  let creator = {
    id: "",
    full_name: "",
  };
  const filteredUser = project.userPreviews.find(
    (item) => item.id === project.creatorId
  );
  if (filteredUser)
    creator = {
      id: filteredUser["id"],
      full_name: filteredUser["firstName"] + " " + filteredUser["lastName"],
    };

  return creator;
};

export const copyProjectSettings = async (
  project,
  user,
  {
    disciplines,
    buildings,
    storeys,
    workStages,
    users,
    userRoles,
    folderStructure,
    tags,
  }
) => {
  const payload = {
    name: {
      value: project.name,
    },
    abbreviation: {
      value: project.abbreviation,
    },
  };
  payload.disciplines = disciplines
    ? (
        await MetaDataService.getMetadataDiscipline(
          project["disciplineMetaDataIds"].join(",")
        )
      ).map((discipline) => ({
        abbreviation: {
          value: discipline.abbreviation,
        },
        name: {
          value: discipline.name,
        },
        isArchitecture: {
          value: discipline.isArchitecture,
        },
      }))
    : [
        {
          abbreviation: {
            value: i18n.t("default_discipline_abb", "ARC"),
          },
          name: {
            value: i18n.t("default_discipline_name", "Architecture"),
          },
          isArchitecture: {
            value: true,
          },
          timeStamp: new Date().toISOString(),
        },
      ];
  payload.buildings = buildings
    ? (
        await MetaDataService.getMetadataBuilding(
          project["buildingMetaDataIds"].join(",")
        )
      ).map((building) => ({
        abbreviation: {
          value: building.abbreviation,
        },
        name: {
          value: building.name,
        },
      }))
    : [
        {
          abbreviation: {
            value: i18n.t("default_building_abb", "B1"),
          },
          name: {
            value: i18n.t("default_building_name", "Building 1"),
          },
          timeStamp: new Date().toISOString(),
        },
      ];
  payload.layers = storeys
    ? (
        await MetaDataService.getMetadataFloor(
          project["layerMetaDataIds"].join(",")
        )
      )
        .filter((layer) => layer.name !== "All storeys")
        .map((layer) => ({
          abbreviation: {
            value: layer.abbreviation,
          },
          name: {
            value: layer.name,
          },
        }))
    : [];
  payload.workPhaseKind = {
    value: workStages ? project.workPhaseKind : 3,
  };
  const roles = await Request.POST(
    userRoles
      ? `api/copyRoleDefinitions/${project.id}`
      : `api/defaultRoleDefinitions`
  ).then((result) => result.body);
  payload.collaboratorRoleDefinitionIds = {
    value: roles && roles.length ? roles.map((role) => role.id) : [user.id],
  };
  const roleNames = project.collaboratorRoles.map(
    (role) =>
      project.collaboratorRoleDefinitions.find(
        (rd) => rd.id === role.roleDefinitionId
      ).name
  );
  payload.collaboratorRoles = {
    value: (users ? project.collaboratorRoles : []).map((role, index) => ({
      roleDefinitionId: roles.find((r) => r.name === roleNames[index]).id,
      collaboratorId: role.collaboratorId,
    })),
  };
  payload.projectSourceId = project.id;
  if (folderStructure) {
    payload.copyFolders = true;
  }
  if (tags) {
    payload.copyTags = true;
  }
  return Request.POST("api/project", [payload]).then(async (result) => {
    if (result.status === 200) {
      return result.body[0];
    } else {
      return Promise.reject(result);
    }
  });
};

export const getClonedProject = async (
  project,
  user,
  {
    disciplines,
    buildings,
    storeys,
    workStages,
    users,
    userRoles,
    folderStructure,
    tags,
    namingScheme,
    groups,
  }
) => {
  const payload = {
    name: project.name,
    abbreviation: project.abbreviation,
    userPreviews: users ? project.userPreviews : [],
  };
  payload.disciplines = disciplines
    ? (
        await MetaDataService.getMetadataDiscipline(
          project["disciplineMetaDataIds"].join(",")
        )
      ).map((discipline) => ({
        abbreviation: discipline.abbreviation,
        name: discipline.name,
        isArchitecture: discipline.isArchitecture,
      }))
    : [];
  payload.buildings = buildings
    ? (
        await MetaDataService.getMetadataBuilding(
          project["buildingMetaDataIds"].join(",")
        )
      ).map((building) => ({
        abbreviation: building.abbreviation,
        name: building.name,
      }))
    : [];
  payload.floors = storeys
    ? (
        await MetaDataService.getMetadataFloor(
          project["layerMetaDataIds"].join(",")
        )
      )
        .filter((layer) => layer.name !== "All storeys")
        .map((layer) => ({
          abbreviation: layer.abbreviation,
          name: layer.name,
        }))
    : [];
  payload.workPhaseKind = workStages ? project.workPhaseKind : 0;
  const roleDefinitions = await Request.POST(
    userRoles
      ? `api/copyRoleDefinitions/${project.id}`
      : `api/defaultRoleDefinitions`
  ).then((result) => result.body);
  payload.collaboratorRoleDefinitionIds = roleDefinitions?.length
    ? roleDefinitions.map((role) => role.id)
    : [user.id];

  const roleNames = project.collaboratorRoles.map(
    (role) =>
      project.collaboratorRoleDefinitions.find(
        (roleDefinition) => roleDefinition.id === role.roleDefinitionId
      )?.name
  );
  project.collaboratorRoleDefinitions.forEach((role) => {
    const r = roleDefinitions.find((r1) => r1.name === role.name);
    role.id = r ? r.id : "";
  });
  const externalRole = roleDefinitions.find((r) => r.name === "External");

  payload.collaboratorRoles = (users ? project.collaboratorRoles : [])
    .map((role, index) => {
      const r = roleDefinitions.find((r) => r.name === roleNames[index]);
      return {
        roleDefinitionId: r && userRoles ? r.id : roleDefinitions[0].id,
        collaboratorId: role.collaboratorId,
      };
    })
    .filter((r) => r.roleDefinitionId !== externalRole.id);
  payload.collaboratorRoleDefinitions = roleDefinitions.filter(
    (r) => r.name !== "External"
  );
  payload.projectSourceId = project.id;
  if (folderStructure) {
    payload.copyFolders = true;
  }
  if (tags) {
    payload.copyTags = true;
  }
  if (namingScheme) {
    payload.copyNamingScheme = true;
  }
  if (groups) {
    payload.copyGroups = true;
  }
  return payload;
};

export const updateProject = async (params) => {
  const result = await Request.PUT("api/project", params);
  return result.status === 200 ? result.body : [];
};

export const updateProjectDocumentStampSettings = async (projectId, params) => {
  const result = await Request.PATCH(`api/stamp-settings`, params);
  return result.status === 200;
}
