import React, {
  useCallback, useContext, useEffect, useMemo, useRef,
} from 'react';
import { Box } from '@mui/material';
import { AgGridReact } from '@ag-grid-community/react';
import {
  ColDef, GetRowIdParams, IRowNode, RowClickedEvent,
} from '@ag-grid-community/core';
import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import useQueryFilterQuery from 'documents-filter/hooks/useQueryFilterQuery';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import DocumentScopeContext, { DocumentScopeContextState } from 'documents/contexts/DocumentScopeContext';
import FilterTreeCellRenderer from 'documents-filter/components/FilterTreeCellRenderer';
import FilterTreeGridRow from 'documents-filter/types/FilterTreeGridRow';
import useQueryFilterFilterContext from 'documents-filter/hooks/useQueryFilterFilterContext';
import QueryFilterSortMode from 'documents-filter/types/QueryFilterSortMode';

export default function FilterDataGrid() {
  const gridRef = useRef<AgGridReact<FilterTreeGridRow>>(null);
  const { data: filters } = useQueryFilterQuery();
  const { documentScope, setDocumentScope } = useContext<DocumentScopeContextState>(DocumentScopeContext);
  const {
    isFilterActive, filterPredicate, sortMode,
  } = useQueryFilterFilterContext();
  const documentFilters = useMemo(() => {
    const entities = filters?.filter((f) => !!f.uiFilterSettings?.documentFilterSettingsEntity);
    if (isFilterActive && entities) {
      return entities.filter(filterPredicate);
    }
    return entities;
  }, [filterPredicate, filters, isFilterActive]);
  const updateSelection = useCallback(() => {
    if (!gridRef.current?.api) return;
    const { api } = gridRef.current;
    if (documentScope.key === DocumentScopeKey.Filter && !!documentScope.id) {
      api.forEachNode((node) => {
        node.setSelected(node.id === documentScope.id);
      });
    } else {
      api.deselectAll();
    }
  }, [documentScope]);

  useEffect(() => {
    updateSelection();
  }, [updateSelection]);

  const onRowDataUpdated = useCallback(() => {
    updateSelection();
  }, [updateSelection]);

  const onRowClicked = useCallback(({ data }: RowClickedEvent<FilterTreeGridRow>) => {
    if (!data) return;
    setDocumentScope({ key: DocumentScopeKey.Filter, id: data.id });
  }, [setDocumentScope]);

  const columnDefs = useMemo<ColDef<FilterTreeGridRow>[]>(() => ([
    {
      cellRenderer: FilterTreeCellRenderer,
      suppressSizeToFit: true,
      flex: 1,
      resizable: false,
      sort: sortMode === QueryFilterSortMode.NameDesc || sortMode === QueryFilterSortMode.CreationDateDesc ? 'desc' : 'asc',
      comparator: (valueA: any, valueB: any, nodeA: IRowNode<FilterTreeGridRow>, nodeB: IRowNode<FilterTreeGridRow>) => {
        if (!nodeA.data?.name) return -1;
        if (!nodeB.data?.name) return 1;
        if (sortMode === QueryFilterSortMode.NameAsc || sortMode === QueryFilterSortMode.NameDesc) {
          return nodeA.data.name.localeCompare(nodeB.data.name);
        }
        if (sortMode === QueryFilterSortMode.CreationDateAsc || sortMode === QueryFilterSortMode.CreationDateDesc) {
          return nodeA.data.creationDateParsed.getTime() - nodeB.data.creationDateParsed.getTime();
        }
        return 0;
      },
    }]), [sortMode]);

  const getRowId = useCallback((params: GetRowIdParams<FilterTreeGridRow>) => params.data.id ?? '', []);

  // TypeScript does not like those AG Grid modules so we use an explicit any variable to suppress the error without ts-ignore
  const modules: any = useMemo(() => [ViewportRowModelModule, RowGroupingModule], []);

  return (
    <Box sx={{ height: '100%' }} className="ag-theme-material ag-theme-visoplan tree-view-data-grid">
      <AgGridReact<FilterTreeGridRow>
        ref={gridRef}
        rowData={documentFilters}
        getRowId={getRowId}
        groupDisplayType="singleColumn"
        rowSelection="single"
        columnDefs={columnDefs}
        suppressRowClickSelection
        suppressCellFocus
        onRowClicked={onRowClicked}
        modules={modules}
        onRowDataUpdated={onRowDataUpdated}
      />
    </Box>
  );
}
