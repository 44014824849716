import { QueryObserverOptions, WithRequired } from '@tanstack/react-query';
import { useMemo } from 'react';
import IssueDto from 'issues/types/IssueDto';
import useDefaultOdataQueryFn from 'api/hooks/useDefaultOdataQueryFn';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { VisoOdataQueryKey } from 'api/hooks/useDefaultEntityQueryKeys';

export type IssuesOdataQueryKey = VisoOdataQueryKey<ApiEndpoint.Issue, IssueDto>;
export type IssuesOdataQueryQueryFnData = IssueDto[] | undefined;
export type IssuesOdataQueryOptions = WithRequired<Partial<Omit<QueryObserverOptions<IssuesOdataQueryQueryFnData, unknown, IssuesOdataQueryQueryFnData, IssuesOdataQueryQueryFnData, IssuesOdataQueryKey>, 'queryKey'>>, 'queryFn'>;

export default function useIssuesOdataQueryOptions() {
  const queryFn = useDefaultOdataQueryFn<IssueDto, IssueDto, ApiEndpoint.Issue>();
  return useMemo<IssuesOdataQueryOptions>(() => ({
    queryFn,
  }), [queryFn]);
}
