import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QueryOptions } from 'odata-query';
import DocumentListDto from 'documents-lists/types/DocumentListDto';
import { useMemo } from 'react';
import useDocumentListsOdataQueryKey, { DocumentListsOdataQueryKey } from 'documents-lists/hooks/useDocumentListsOdataQueryKey';

type DocumentListsUseQueryOptions = Omit<UseQueryOptions<DocumentListDto[] | undefined, unknown, DocumentListDto[] | undefined, DocumentListsOdataQueryKey | readonly []>, 'queryFn'>;

export default function useDocumentListsOdataQuery(odataQuery: Partial<QueryOptions<DocumentListDto>> | undefined, options?: Partial<DocumentListsUseQueryOptions>) {
  const { getQueryKey } = useDocumentListsOdataQueryKey();
  const queryKey = useMemo(() => (odataQuery ? getQueryKey(odataQuery) : ([] as const)), [getQueryKey, odataQuery]);
  const queryOptions = useMemo<DocumentListsUseQueryOptions>(() => ({
    ...options,
    queryKey,
    enabled: (options?.enabled !== false) && (queryKey.length > 0),
  }), [queryKey, options]);
  return useQuery(queryOptions);
}
