import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Calendar, Event, momentLocalizer } from 'react-big-calendar';
import { Box, Button, GlobalStyles, Typography, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import useToday from 'common/hooks/useToday';
import useIssuesOdataQuery from 'issues/hooks/useIssuesOdataQuery';
import { Direction } from 're-resizable/lib/resizer';
import { Resizable } from 're-resizable';
import IssueDetailsPanel from 'issues/components/IssueDetailsPanel';
import IssuesTimelineIssuesList from 'timeline/components/IssuesTimelineIssuesList';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import IssueQuickFilterBar from 'issues/components/IssueQuickFilterBar';
import Icon from '@mdi/react';
import { mdiArrowLeftBold, mdiArrowRightBold } from '@mdi/js';

const localizer = momentLocalizer(moment);

interface IssuesTimelineProps extends ISxProps {
}

// this determines the time interval for which we request issues
// we request a couple of days before and after the current month, because the calender displays days of these months if they belong to the first or last week of the current month
// (e.g. Monday, 26th of July 2024 is visible when viewing September 2024 starting Sunday of the same week)
function getMonthRange(date: Date) {
  const start = new Date(date);
  start.setDate(-5);
  const end = new Date(date);
  end.setMonth(end.getMonth() + 1);
  end.setDate(-1);
  end.setDate(end.getDate() + 8);
  end.setMilliseconds(-1);
  return { start, end };
}

export default function IssuesTimeline({
  sx,
}: IssuesTimelineProps) {
  const theme = useTheme();
  const calendarRef = useRef<Calendar>(null);
  const [selectedIssueId, setSelectedIssueId] = useState<string | undefined>(undefined);
  const { odataQuery, setDateInterval } = useIssuesFilterContext();
  const { data: issues } = useIssuesOdataQuery(odataQuery);

  const calendarEvents = useMemo(() => issues?.filter((issue) => !!issue.dueDate)
    .map((issue) => {
      const start = issue.startingDate ? new Date(issue.startingDate) : new Date(issue.dueDate!);
      return {
        allDay: true,
        title: issue.title,
        start,
        end: new Date(issue.dueDate!),
        resource: issue.id,
      };
    }), [issues]);

  const calendarEventsByIssueId = useMemo(() => {
    if (!calendarEvents) return undefined;
    return new Map(calendarEvents.map((event) => [event.resource as string, event]));
  }, [calendarEvents]);

  const selectedEvents = useMemo(() => (calendarEventsByIssueId && selectedIssueId ? calendarEventsByIssueId.get(selectedIssueId) : []) ?? [], [calendarEventsByIssueId, selectedIssueId]);

  const onSelectIssue = useCallback((issueId: string | undefined) => {
    setSelectedIssueId(issueId);
  }, []);

  const onSelectEvent = useCallback((event: Event) => {
    const issueId = event.resource as string | undefined;
    onSelectIssue(issueId);
  }, [onSelectIssue]);

  const onDeselectIssue = useCallback(() => {
    setSelectedIssueId(undefined);
  }, []);

  const today = useToday();
  const getNow = useCallback(() => today, [today]);

  useEffect(() => {
    setDateInterval((prev) => {
      if (prev) return prev;
      return getMonthRange(today);
    });
  }, [setDateInterval, today]);

  const [date, setDate] = useState<Date>(getNow);

  useEffect(() => {
    setDateInterval(getMonthRange(date));
  }, [date, setDateInterval]);

  const onClickNextMonth = useCallback(() => {
    setDate((currentValue) => {
      const nextValue = new Date(currentValue);
      nextValue.setMonth(currentValue.getMonth() + 1);
      return nextValue;
    });
  }, []);

  const onClickPreviousMonth = useCallback(() => {
    setDate((currentValue) => {
      const nextValue = new Date(currentValue);
      nextValue.setMonth(currentValue.getMonth() - 1);
      return nextValue;
    });
  }, []);

  const [sidebarDefaultWidth, setSidebarDefaultWidth] = useState(() => {
    const storedWidth = localStorage.getItem('timeline_default-sidebar-width');
    if (storedWidth?.length) {
      const parsedWidth = parseInt(storedWidth, 10);
      if (!Number.isNaN(parsedWidth)) return parsedWidth;
    }
    return 350;
  });

  const onResizeStopSidebar = useCallback((event: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement) => {
    localStorage.setItem('timeline_default-sidebar-width', `${elementRef.clientWidth}`);
    setSidebarDefaultWidth(elementRef.clientWidth);
  }, []);

  const monthTitle = useMemo(() => moment(date).format('MMMM YYYY'), [date]);

  return (
    <Box sx={{
      flex: '1 1 0',
      display: 'flex',
      overflow: 'hidden',
      backgroundColor: theme.palette.secondary.light,
      boxShadow: 'inset 0px 24px 24px -24px rgba(0,0,0,0.2)',
    }}
    >
      <Box id="IssuesTimeline" sx={{ ...sx, flexGrow: 1, overflow: 'auto', p: 3 }}>
        <GlobalStyles styles={{
          '.rbc-button-link': {
            cursor: 'default',
          },
          '.rbc-toolbar': {
            display: 'none',
          },
        }}
        />
        <Calendar
          getNow={getNow}
          localizer={localizer}
          events={calendarEvents}
          selected={selectedEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ minHeight: '100%' }}
          date={date}
          onSelectEvent={onSelectEvent}
          ref={calendarRef}
          views={['month']}
        />
      </Box>
      <Resizable
        style={{ backgroundColor: theme.palette.background.default, boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.2)' }}
        enable={{ top: false, right: false, bottom: false, left: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
        defaultSize={{ width: sidebarDefaultWidth, height: 'auto' }}
        minWidth="320px"
        handleComponent={{ left: <Box sx={{ height: '100%', width: '8px', backgroundColor: 'transparent' }}><Box sx={{ height: '100%' }} /></Box> }}
        handleStyles={{ left: { left: 0 } }}
        onResizeStop={onResizeStopSidebar}
      >
        {!selectedIssueId && (
          <Box sx={{ height: '100%', pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', px: 2, gap: 2, justifyContent: 'space-between', alignItems: 'center' }}>
              <Button onClick={onClickPreviousMonth} variant="outlined" sx={{ flexGrow: 1 }}>
                <Icon path={mdiArrowLeftBold} size={1} />
              </Button>
              <Typography variant="h3">{monthTitle}</Typography>
              <Button onClick={onClickNextMonth} variant="outlined" sx={{ flexGrow: 1 }}>
                <Icon path={mdiArrowRightBold} size={1} />
              </Button>
            </Box>
            <IssueQuickFilterBar sx={{ px: 2 }} />
            <Box sx={{ flex: '1 1 0', overflow: 'auto', background: theme.palette.grey[200], boxShadow: 'inset 0px 24px 24px -24px rgba(0,0,0,0.1)' }}>
              <IssuesTimelineIssuesList onSelectIssue={onSelectIssue} sx={{ p: 2 }} />
            </Box>
          </Box>
        )}
        {!!selectedIssueId && (
          <IssueDetailsPanel issueId={selectedIssueId} onClose={onDeselectIssue} />
        )}
      </Resizable>
    </Box>
  );
}
