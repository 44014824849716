import { useCallback } from 'react';
import QueryFilterDto from 'documents-filter/types/QueryFilterDto';
import QueryFilter from 'documents-filter/types/QueryFilter';
import useDocumentFilterSettingsEntityFactory from 'documents-filter/hooks/useDocumentFilterSettingsEntityFactory';
import useIssueFilterSettingsEntityFactory from 'issues/hooks/useIssueFilterSettingsEntityFactory';
import useEmailFilterSettingsEntityFactory from 'emails/hooks/useEmailFilterSettingsEntityFactory';

export default function useQueryFilterEntityFactory() {
  const documentFilterSettingsEntityFactory = useDocumentFilterSettingsEntityFactory();
  const issueFilterSettingsEntityFactory = useIssueFilterSettingsEntityFactory();
  const emailFilterSettingsEntityFactory = useEmailFilterSettingsEntityFactory();
  return useCallback((dto: QueryFilterDto) => {
    const queryFilter: Readonly<QueryFilter> = {
      ...dto,
      creationDateParsed: new Date(dto.creationDate),
      updateDateParsed: new Date(dto.updateDate),
      uiFilterSettings: dto.uiFilterSettings?.documentFilter
        ? { documentFilterSettingsEntity: documentFilterSettingsEntityFactory(dto.uiFilterSettings.documentFilter) }
        : dto.uiFilterSettings?.issueFilter
          ? { issueFilterSettingsEntity: issueFilterSettingsEntityFactory(dto.uiFilterSettings.issueFilter) }
          : dto.uiFilterSettings?.emailFilter
            ? { emailFilterSettingsEntity: emailFilterSettingsEntityFactory(dto.uiFilterSettings.emailFilter) }
            : undefined,
    };
    return queryFilter;
  }, [documentFilterSettingsEntityFactory, emailFilterSettingsEntityFactory, issueFilterSettingsEntityFactory]);
}
