import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useLabelCreateMutation from 'labels/hooks/useLabelCreateMutation';
import CreateLabelDto from 'labels/types/CreateLabelDto';
import UpdateLabelDto from 'labels/types/UpdateLabelDto';
import LabelType from 'labels/types/LabelType';
import useLabelUpdateMutation from 'labels/hooks/useLabelUpdateMutation';
import useLabelDeleteMutation from 'labels/hooks/useLabelDeleteMutation';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';

export enum MutationMode {
  Create,
  Edit,
}

export enum DialogResult {
  Cancelled,
  Created,
  Updated,
  Deleted,
}

type MutateLabelDialogProps = {
  id?: string | undefined,
  labelType: LabelType,
  onClose: (result: DialogResult) => void,
};

export default function MutateLabelDialog({
  id,
  labelType,
  onClose,
}: MutateLabelDialogProps) {
  const { t } = useTranslation('settings');

  const { data: labelData } = useLabelsOdataQuery(id ? { filter: { id } } : undefined);
  const label = useMemo(() => labelData?.[0], [labelData]);

  const { data: currentProject } = useCurrentProjectQuery();

  const { mutateAsync: createAsync, isLoading: isLoadingCreate } = useLabelCreateMutation();
  const { mutateAsync: updateAsync, isLoading: isLoadingUpdate } = useLabelUpdateMutation();
  const { mutateAsync: deleteAsync, isLoading: isLoadingDelete } = useLabelDeleteMutation();

  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => setName(e.target.value), []);
  const [abbreviation, setAbbreviation] = useState<string | undefined>(undefined);
  const onChangeAbbreviation = useCallback((e: ChangeEvent<HTMLInputElement>) => setAbbreviation(e.target.value), []);
  const confirmButtonDisabled = useMemo(() => (id && !label) || isLoadingUpdate || isLoadingCreate || !name?.trim().length || !abbreviation?.trim().length, [id, label, isLoadingUpdate, isLoadingCreate, name, abbreviation]);

  const canBeDeleted = useMemo(() => {
    if (labelType === LabelType.Discipline && id) {
      if (!label || !currentProject) return undefined;
      return label.id !== currentProject.architectureLabelId;
    }
    if (labelType === LabelType.Floor && id) {
      if (!label) return undefined;
      return label.originalName !== 'All storeys';
    }
    return true;
  }, [labelType, id, label, currentProject]);

  const onClickConfirm = useCallback(async () => {
    if (!name || !abbreviation) return;
    setErrorMessage(undefined);
    try {
      if (id) {
        const updateDto: UpdateLabelDto = {
          id,
          name: name.trim(),
          abbreviation: abbreviation.trim(),
        };
        await updateAsync(updateDto);
      } else {
        const createDto: CreateLabelDto = {
          name: name.trim(),
          abbreviation: abbreviation.trim(),
          type: labelType,
        };
        await createAsync(createDto);
      }
      const result = id ? DialogResult.Updated : DialogResult.Created;
      onClose(result);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [abbreviation, createAsync, getRequestErrorMessage, labelType, id, name, onClose, updateAsync]);
  const onClickCancel = useCallback(() => {
    onClose(DialogResult.Cancelled);
  }, [onClose]);

  const [isConfirmDeleteDialogVisible, setIsConfirmDeleteDialogVisible] = useState(false);
  const onClickDelete = useCallback(() => {
    setIsConfirmDeleteDialogVisible(true);
  }, []);
  const onClickConfirmDelete = useCallback(async () => {
    if (!id) throw new Error('Delete failed, missing id');
    try {
      await deleteAsync(id);
      onClose(DialogResult.Deleted);
    } catch (error) {
      setIsConfirmDeleteDialogVisible(false);
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [deleteAsync, id, onClose, getRequestErrorMessage]);
  const onClickCancelDelete = useCallback(() => {
    setIsConfirmDeleteDialogVisible(false);
  }, []);

  // init values
  useEffect(() => {
    if (id) {
      if (!label) return;
      setName((prev) => prev ?? label.name);
      setAbbreviation((prev) => prev ?? label.abbreviation);
    } else {
      setName('');
      setAbbreviation('');
    }
  }, [label, id]);

  const labelTypeId = useMemo(() => LabelType[labelType].toLowerCase(), [labelType]);

  return (
    <>
      <Dialog open>
        <DialogTitle sx={{ display: 'flex' }}>
          {!!id && t(`mutate-${labelTypeId}-dialog_edit-mode-title`, `Edit ${labelTypeId}`)}
          {!id && t(`mutate-${labelTypeId}-dialog_create-mode-title`, `Create ${labelTypeId}`)}
          {!!id && (
          <Button onClick={onClickDelete} variant="contained" color="secondary" disabled={canBeDeleted !== true} sx={{ pl: 1, ml: 'auto' }}>
            <DeleteIcon sx={{ mr: 1 }} />
            {t(`mutate-${labelTypeId}-dialog_delete-button-label`, 'Delete')}
          </Button>
          )}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, gap: 2, minWidth: 350 }}>
            <TextField value={name ?? ''} onChange={onChangeName} label={t(`mutate-${labelTypeId}-dialog_name-textfield-label`, 'Name')} />
            <TextField value={abbreviation ?? ''} onChange={onChangeAbbreviation} label={t(`mutate-${labelTypeId}-dialog_abbreviation-textfield-label`, 'Abbreviation')} />
            {!!errorMessage && <Alert severity="error" onClose={() => setErrorMessage(undefined)}>{errorMessage}</Alert>}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" sx={{ mr: 'auto' }} onClick={onClickCancel}>{t(`mutate-${labelTypeId}-dialog_cancel-button-label`, 'Cancel')}</Button>
          <Button variant="contained" color="primary" onClick={onClickConfirm} disabled={confirmButtonDisabled}>
            {(isLoadingUpdate || isLoadingCreate) && <CircularProgress size={12} sx={{ mr: 1 }} />}
            {t(`mutate-${labelTypeId}-dialog_confirm-button-label`, 'Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isConfirmDeleteDialogVisible}>
        <DialogTitle>{t(`mutate-${labelTypeId}-dialog_delete-dialog-title`, 'Delete "{{name}}"', { name: label?.name })}</DialogTitle>
        <DialogContent>
          <Alert severity="warning">
            {t(`mutate-${labelTypeId}-dialog_delete-dialog-message`, 'Are you sure that you want to permanently delete "{{name}}"?', { name: label?.name })}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" sx={{ mr: 'auto' }} onClick={onClickCancelDelete}>{t(`mutate-${labelTypeId}-dialog_delete-dialog-cancel-button-label`, 'Cancel')}</Button>
          <Button variant="contained" color="primary" onClick={onClickConfirmDelete} disabled={confirmButtonDisabled}>
            {(isLoadingDelete) && <CircularProgress size={12} sx={{ mr: 1 }} />}
            {t(`mutate-${labelTypeId}-dialog_delete-dialog-confirm-button-label`, 'Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
