import React, { useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { Droppable, DropResult } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { SyncDataType } from 'Helpers/Common';
import IssueItem, { ISSUE_ITEM_ISSUE_FRAGMENT } from 'Components/IssueBoard/IssueItem';
import { IssueBoardColumnQuery, IssueBoardColumnQueryVariables } from 'Components/IssueBoard/__generated__/IssueBoardColumnQuery';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';

const DEFAULT_ISSUES_BOARD_PAGINATION_COUNT = 5;

interface Group {
  id: any;
  value: any;
}

interface Filter {
}

interface IssueBoardColumnProps {
  group: Group,
  viewIssue: () => void,
  filter: Filter,
  updateObj: { result: DropResult } | undefined,
}

interface Project {
  sync_data: SyncData
}

interface SyncData {
  dataSummaryEntries: {
    dataType: number
  }[]
}

function IssueBoardColumn({
  group,
  viewIssue,
  filter,
  updateObj,
}: IssueBoardColumnProps) {
  const { graphQlClient } = useVisoplanApiContext();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { sync_data } = useSelector<
  { project: Project },
  { sync_data: SyncData }
  >((state) => state.project);

  const ISSUE_BOARD_COLUMN_QUERY = gql`
  ${ISSUE_ITEM_ISSUE_FRAGMENT}
  query IssueBoardColumnQuery(
    $cursor: String
    $issueFilter: IssueFilterInput
    $count: Int!
  ) {
    issues(
      first: $count
      after: $cursor
      where: $issueFilter
      order: { editDate: DESC }
    ) {
      edges {
        node {
          id
          ...IssueItem_issue
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  `;

  const {
    data,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery(
    ['issue', 'graphql', 'IssueBoardColumnQuery', group.value, filter],
    ({ pageParam = null }: { pageParam?: string | null }) => graphQlClient.request<IssueBoardColumnQuery, IssueBoardColumnQueryVariables>(ISSUE_BOARD_COLUMN_QUERY, { count: DEFAULT_ISSUES_BOARD_PAGINATION_COUNT, cursor: pageParam, issueFilter: filter }),
    { getNextPageParam: (lastPage) => lastPage.issues?.pageInfo.endCursor },
  );

  useEffect(() => {
    if (!sync_data?.dataSummaryEntries) return;

    if (sync_data.dataSummaryEntries.find((entry) => entry.dataType === SyncDataType.Issue)) {
      // TODO: improve this sync related re-fetch. We put so much effort into pagination, but if a single issue changes, we currently just reload everything.
      refetch();
    }
  }, [sync_data, refetch]);

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  useEffect(() => {
    // remove as soon as we have mutations
    if (!updateObj) return;

    const destinationId = updateObj.result?.destination?.droppableId;
    const sourceId = updateObj.result.source.droppableId;
    if (destinationId === group.value || sourceId === group.value) {
      refetch();
    }
  }, [updateObj, refetch, group]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const targetDomElement = event.target as HTMLDivElement;
    if (!targetDomElement) return;
    if (
      Math.abs(
        targetDomElement.scrollHeight
          - targetDomElement.scrollTop
          - targetDomElement.clientHeight,
      ) < 10
      && hasNextPage
    ) {
      fetchNextPage();
    }
  };

  return (
    <div
      className="issue-board__column__wrapper custom-scrollbar"
      onScroll={handleScroll}
    >
      <Droppable droppableId={`${group.value}`}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            data-rbd-droppable-context-id={provided.droppableProps['data-rbd-droppable-context-id']}
            data-rbd-droppable-id={provided.droppableProps['data-rbd-droppable-id']}
          >
            {(data?.pages.flatMap((page) => page.issues?.edges ?? []) || []).map(
              (edge, index) => !!edge && (
              <IssueItem
                key={`${group.id}-${edge.node.id}`}
                issue={edge.node}
                viewIssue={viewIssue}
                index={index}
                group={group}
              />
              ),
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}
export default IssueBoardColumn;
