import React, { useCallback, useContext, useMemo } from 'react';
import {
  FormControl, InputLabel, MenuItem, Select, OutlinedInput, SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useDocumentFilterContext from 'documents/hooks/useDocumentFilterContext';
import QueryFilter from 'documents-filter/types/QueryFilter';
import useQueryFilterQuery from 'documents-filter/hooks/useQueryFilterQuery';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import DocumentScopeContext, { DocumentScopeContextState } from 'documents/contexts/DocumentScopeContext';

export default function LoadFilterSelect() {
  const { data: filters } = useQueryFilterQuery();
  const documentFilters = useMemo(() => filters?.filter((f) => !!f.uiFilterSettings?.documentFilterSettingsEntity), [filters]);
  const { t } = useTranslation('documents-filter');
  const { setDocumentScope } = useContext<DocumentScopeContextState>(DocumentScopeContext);
  const {
    loadedQueryFilter,
    loadedQueryFilterHasLocalEdits,
  } = useDocumentFilterContext();
  const documentFiltersById = useMemo(() => new Map<string | undefined, QueryFilter>(documentFilters?.map((f) => [f.id, f])), [documentFilters]);

  const handleChangeSelectedFilterId = useCallback((e: SelectChangeEvent<string>) => {
    const filterId = e.target.value;
    setDocumentScope({ key: DocumentScopeKey.Filter, id: filterId });
  }, [setDocumentScope]);

  return (
    <FormControl id="LoadFilterSelect" sx={{ width: '100%', gridColumn: '1 / 3' }}>
      <InputLabel id="load-filter-select_label" shrink>{t('load-filter-select_label', 'Saved Filters')}</InputLabel>
      <Select
        labelId="load-filter-select_label"
        value={loadedQueryFilter?.id ?? ''}
        onChange={handleChangeSelectedFilterId}
        displayEmpty
        input={<OutlinedInput notched label={t('load-filter-select_label', 'Saved Filters')} />}
        renderValue={(value) => (value
          ? `${documentFiltersById.get(value)?.name ?? ''}${loadedQueryFilterHasLocalEdits ? t('load-filter-select_edit', ' (edited)') : ''}`
          : <em>{t('load-filter-select_unsaved', 'Unsaved filter')}</em>)}
      >
        {documentFilters?.map((queryFilter) => (
          <MenuItem value={queryFilter.id} key={queryFilter.id}>{queryFilter.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
