import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

// @ts-ignore (getConfig() is defined in the separately loaded, plain javascript file config.js, that is different per environment)
const config = getConfig() as ApiConfig;

export const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: config.appInsightsConnectionString,
    enableAutoRouteTracking: true,
    // @ts-ignore (see https://github.com/microsoft/applicationinsights-react-js/issues/32)
    extensions: [reactPlugin],
  },
});
appInsights.loadAppInsights();
export default appInsights;
