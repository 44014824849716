import React from 'react';
import { useTranslation } from 'react-i18next';
import useOpenIssueStatus from 'issues/hooks/useOpenIssueStatus';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import IssueResponsibilityActionPanel from 'issues/components/IssueResponsibilityActionPanel';
import IssueVisibility from 'issues/types/IssueVisibility';

interface PublishPanelProps {
  issueId: string,
  onClose: () => void,
}

export default function IssuePublishPanel({
  issueId,
  onClose,
}: PublishPanelProps) {
  const { t } = useTranslation('issues');
  const openIssueStatus = useOpenIssueStatus();
  if (!openIssueStatus) return <CenteredCircularProgress />;
  return (
    <IssueResponsibilityActionPanel
      id="PublishPanel"
      issueId={issueId}
      title={t('publish-panel_header', 'Assign and publish')}
      onClose={onClose}
      confirmButtonLabel={t('publish-panel_confirm-button-label', 'Assign and publish')}
      commentDefaultText={t('publish-panel_comment-default-text', 'Entry reviewed and completed.')}
      targetStatusId={openIssueStatus.id}
      canSelectAssigneeAndReviewer
      targetVisibility={IssueVisibility.Public}
      commentMandatory
    />
  );
}
