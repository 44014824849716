import React, { useState, useMemo, useCallback } from 'react';
import {
  Alert,
  Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import PersistCollaboratorRoleDefinitionDto from 'projects/types/PersistCollaboratorRoleDefinitionDto';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useCollaboratorRoleDefinitionCreateMutation from 'projects/hooks/useCollaboratorRoleDefinitionCreateMutation';
import RoleActionMapDto from 'projects/types/RoleActionMapDto';
import useProjectUpdateRolesMutation from 'projects/hooks/useProjectUpdateRolesMutation';
import ProjectUpdateRolesDto from 'projects/types/ProjectUpdateRolesDto';

interface CreateRoleDialogProps {
  onClose: () => void,
  onSuccess: (message: string) => void,
}

export default function CreateRoleDialog({
  onClose,
  onSuccess,
}: CreateRoleDialogProps) {
  const { t } = useTranslation('settings');
  const [name, setName] = useState<string>('');
  const { data: currentProject } = useCurrentProjectQuery();
  const existingRoleDefinitionNames = useMemo(() => (currentProject ? new Set<string>(currentProject?.collaboratorRoleDefinitions.map((d) => d.name.trim())) : undefined), [currentProject]);
  const hasNamingConflict = useMemo(() => !!name.length && existingRoleDefinitionNames?.has(name.trim()), [existingRoleDefinitionNames, name]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const { mutateAsync: mutateCreateRole, isLoading: isLoadingCreateRole } = useCollaboratorRoleDefinitionCreateMutation();
  const { mutateAsync: mutateUpdateProjectRoles, isLoading: isLoadingUpdateProjectRoles } = useProjectUpdateRolesMutation();
  const isLoading = useMemo(() => isLoadingCreateRole || isLoadingUpdateProjectRoles, [isLoadingCreateRole, isLoadingUpdateProjectRoles]);
  const initialRoleActions = useMemo<RoleActionMapDto[] | undefined>(() => currentProject?.collaboratorRoleDefinitions.find((d) => d.isAdmin)?.actions.map((a) => ({ ...a, isAllowed: true })), [currentProject?.collaboratorRoleDefinitions]);

  const onClickConfirm = useCallback(async () => {
    if (!currentProject || !initialRoleActions) return;
    setErrorMessage(undefined);
    const persistRoleDefinitionDto: PersistCollaboratorRoleDefinitionDto = {
      name: { value: name },
      actions: { value: initialRoleActions },
    };
    try {
      const existingRoleIds = currentProject.collaboratorRoleDefinitions.map((d) => d.id);
      const { id: createdRoleId } = await mutateCreateRole(persistRoleDefinitionDto);
      if (!createdRoleId) throw new Error(t('create-role-dialog_server-error-message', 'Creating the project failed due to a server error'));
      const projectUpdateRolesDto: ProjectUpdateRolesDto = {
        id: currentProject.id,
        collaboratorRoleDefinitionIds: [...existingRoleIds, createdRoleId],
      };
      await mutateUpdateProjectRoles(projectUpdateRolesDto);
      onSuccess(t('create-role-dialog_success-message', 'Role "{{roleName}}" created.', { roleName: name }));
      setName('');
      onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [currentProject, getRequestErrorMessage, initialRoleActions, mutateCreateRole, mutateUpdateProjectRoles, name, onClose, onSuccess, t]);

  const onClickCancel = useCallback(() => {
    setErrorMessage(undefined);
    setName('');
    onClose();
  }, [onClose]);

  return (
    <Dialog id="CreateRoleDialog" open>
      <DialogTitle>{t('create-role-dialog_title', 'Create New Role')}</DialogTitle>
      <DialogContent sx={{ minHeight: '84px', width: '420px' }}>
        <Box sx={{ pt: 1 }}>
          <TextField
            fullWidth
            label={t('role-options-button-menu_create-textfield-label', 'New Role Name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={hasNamingConflict}
            helperText={hasNamingConflict ? t('create-role-dialog_name-conflict-messge', 'A role with this name already exists') : undefined}
          />
          {!!errorMessage && <Alert sx={{ mt: 1 }} severity="error">{errorMessage}</Alert>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClickCancel} disabled={isLoading}>{t('create-role-dialog_cancel-button-label', 'Cancel')}</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickConfirm}
          sx={{ ml: 2 }}
          disabled={!name.trim().length || hasNamingConflict || isLoading}
        >
          {isLoading && <CircularProgress size={12} sx={{ mr: 1 }} />}
          {t('create-role-dialog_confirm-button-label', 'Create Role')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
