import {
  QueryKey, useQuery, UseQueryOptions,
} from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import _ from 'lodash';

export default function useDefaultEntitiesByIdsEndpointQuery<
  TDto extends { id: string },
  TCachedEntity extends TDto,
  TSelectedData extends TCachedEntity[] | TCachedEntity | undefined = TCachedEntity[] | undefined>(
  endpoint: ApiEndpoint,
  ids: string[] | undefined,
  entityFactory?: ((dto: TDto) => Readonly<TCachedEntity>) | undefined,
  options?: Omit<UseQueryOptions<TCachedEntity[] | undefined, unknown, TSelectedData, QueryKey>, 'queryKey' | 'queryFn'> | undefined,
) {
  const axiosInstance = useAxiosInstance();
  const { getListsByIdsQueryKey } = useDefaultEntityQueryKeys(endpoint);
  const queryKey = ids ? getListsByIdsQueryKey(ids) : [];
  return useQuery<TCachedEntity[] | undefined, unknown, TSelectedData>(queryKey, async () => {
    if (!ids) return undefined;
    if (ids.length === 0) return [];
    const requestedEntitiesById = new Map<string, TCachedEntity>();
    const idChunks = _.chunk(ids, 300); // chunking into smaller requests limits URL length for large lists
    const requestPromises = idChunks.map(async (idsChunk) => {
      const response = await axiosInstance.get<TDto[]>(`/${endpoint}/${idsChunk.join(',')}`);
      const dtos = response.data;
      if (dtos.length < idsChunk.length) {
        throw new Error('API response incomplete');
      }
      const requestedEntities = entityFactory ? dtos.map((dto) => entityFactory(dto)) : (dtos as TCachedEntity[]);
      requestedEntities.forEach((entity) => requestedEntitiesById.set(entity.id, entity)); // collect result entities in a map for later assembly of the result array
    });
    await Promise.all(requestPromises);
    const resultEntities = ids.map((id) => requestedEntitiesById.get(id)!);
    return resultEntities;
  }, {
    retry: 1, retryDelay: 1000, ...options, enabled: (options?.enabled ?? true) && !!ids,
  });
}
