import React from 'react';
import { GlobalStyles } from '@mui/material';
import Color from 'color';

export default function VisoGlobalStyles() {
  return (
    <GlobalStyles
      styles={(theme) => ({
        '*': {
          '@font-face': {
            fontFamily: 'Material Icons',
            fontStyle: 'normal',
            fontWeight: 400,
            src: `url("${process.env.PUBLIC_URL}/fonts/material-icons.woff2") format('woff2')`,
          },
          scrollbarColor: '#E4E4E4 #F5F5F5',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '10px',
            height: '10px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#F5F5F5',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#E4E4E4',
            borderRadius: '22px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#E4E4E4',
          },
        },
        '.inset-shadow-left-27': {
          boxShadow: 'inset 27px 0px 27px -27px rgba(141, 141, 141, 0.25)',
        },
        '.inset-shadow-top-left-27-20': {
          boxShadow: 'inset 27px 20px 27px -27px rgba(141, 141, 141, 0.25)',
        },
        '.ag-theme-visoplan': {
          '--ag-font-family': 'Open Sans, sans-serif',
          '--ag-font-size': '14px',
          '--ag-selected-row-background-color': theme.palette.secondary.main,
          '--ag-checkbox-checked-color': theme.palette.primary.main,
          '--ag-checkbox-indeterminate-color': theme.palette.primary.main,
          '.ag-icon': {
            '&.ag-icon-filter': {
              '--ag-icon-font-family': 'Material Icons',
              '--ag-icon-font-code-filter': '"\\EF4F"',
              color: theme.palette.info.main,
            },
            '&.ag-icon-asc': {
              color: theme.palette.info.main,
            },
            '&.ag-icon-desc': {
              color: theme.palette.info.main,
            },
          },
          '&.table-view-data-grid': {
            '--ag-background-color': 'transparent',
            '--ag-header-background-color': 'transparent',
            '--ag-header-column-separator-display': 'block',
            '--ag-header-column-separator-height': '20px',
            '--ag-cell-horizontal-padding': '16px',
            '--ag-foreground-color': theme.palette.text.primary,
            '--ag-secondary-foreground-color': theme.palette.text.secondary,
            '--ag-header-foreground-color': '--ag-foreground-color',
            '--ag-selected-row-background-color': Color(theme.palette.secondary.main).lightness(75).alpha(0.1).hexa(),
            '.ag-header-row': {
              fontSize: '14px',
              fontWeight: '700',
            },
            '.ag-header-cell.no-hover:hover': {
              backgroundColor: 'unset !important',
            },
            '.issue-number-cell': {
              '.ag-cell-wrapper': {
                height: '100%',
                '.ag-cell-value': {
                  borderRadius: '4px',
                  backgroundColor: theme.palette.grey[700],
                  color: theme.palette.background.default,
                  fontWeight: 600,
                  textAlign: 'center',
                  alignSelf: 'center',
                  lineHeight: '1em',
                  padding: '2px 4px',
                  fontSize: '1.1em',
                  flexGrow: 0,
                },
              },
            },
            '.ag-header-cell.no-border::after': {
              display: 'none',
            },
            '.ag-header-cell.no-padding': {
              padding: 0,
            },
            '.ag-cell': {
              lineHeight: '54px',
              '&:not(.no-separator)': {
                '::after': {
                  right: 0,
                  content: '""',
                  position: 'absolute',
                  zIndex: 1,
                  width: '1px',
                  height: '20px',
                  top: '17px',
                  backgroundColor: 'rgb(226, 226, 226)',
                },
              },
            },
            '.viso-selected': {
              '.ag-cell': {
                backgroundColor: theme.palette.secondary.light,
              },
            },
            '.viso-disabled': {
              '.ag-cell': {
                color: theme.palette.grey[600],
                backgroundColor: 'rgba(226, 226, 226, 0.3)',
                cursor: 'default',
              },
            },
          },
          '&.tree-view-data-grid': {
            '--ag-selected-row-background-color': 'transparent',
            '--ag-row-hover-color': 'transparent',
            '--ag-row-group-indent-size': '0',
            '--ag-cell-horizontal-padding': '0',
            '--ag-cell-vertical-padding': '0',
            '--ag-grid-size': '7px',
            '--ag-borders': 'none',
            '--ag-secondary-borders': 'none',
            '--ag-row-border-style': 'none',
            '--ag-row-border-width': '0',
            '.ag-header': {
              display: 'none',
            },
            '.ag-cell': {
              borderRadius: '8px',
              boxSizing: 'border-box',
            },
            '.ag-row-selected': {
              '.ag-cell': {
                backgroundColor: theme.palette.secondary.light,
              },
            },
            '.ag-row-hover:not(.no-hover), .drag-over': {
              '.ag-cell': {
                backgroundColor: Color(theme.palette.secondary.light).lightness(98).hex(),
              },
            },
            '.ag-row-hover.ag-row-selected:not(.no-hover)': {
              '.ag-cell': {
                backgroundColor: Color(theme.palette.secondary.light).lightness(95).hex(),
              },
            },
            '.ag-row:not(.no-hover)': {
              '.ag-cell': {
                cursor: 'pointer',
              },
            },
          },
          '.viso-webviewer-button': {
            backgroundColor: theme.palette.primary.main,
          },
        },
      })}
    />
  );
}
