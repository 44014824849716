import React from 'react';

export interface DocumentSelectionContextState {
  selectedDocumentVersionIds: string[],
  setSelectedDocumentVersionIds: (ids: string[]) => void,
  nonDeselectableVersionIds: Set<string> | undefined,
  nonSelectableVersionIds: Set<string> | undefined,
  resetSelection: () => void,
}

const defaultValue: DocumentSelectionContextState = {
  selectedDocumentVersionIds: [],
  setSelectedDocumentVersionIds: () => {},
  nonDeselectableVersionIds: new Set<string>(),
  nonSelectableVersionIds: new Set<string>(),
  resetSelection: () => {},
};

const DocumentSelectionContext = React.createContext(defaultValue);

export default DocumentSelectionContext;
