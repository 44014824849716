import React, { useMemo, useCallback } from "react";
import { withTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import ProgressBar from "../../ProgressBar";
import ModalFooter from "./ModalFooter";
import CustomSelect from "../../CustomSelect";
import UploadZone from "../UploadZone";
import { IconButton } from "../../Buttons";

const SetupModel = ({
  t,
  uploadFileSize,
  uploadCategory,
  currentSetupModelIndex,
  totalUploadSize,
  uploadState,
  progress,
  disciplines,
  buildings,
  floors,
  metadatas,
  isStoreDoc,
  doneText,
  handleButtonClick,
  handleSelectChange,
  onChangeCheckbox,
  onDone,
  uploadFileProgress,
  setConfirmNotification,
}) => {
  const fileName = useMemo(
    () => Object.keys(uploadFileSize)[currentSetupModelIndex] || "",
    [uploadFileSize, currentSetupModelIndex]
  );
  const handleSelect = useCallback(
    (property, fileName, value) => {
      handleSelectChange(value, property, fileName);
    },
    [handleSelectChange]
  );

  const closeSetupModel = useCallback(() => {
    if (!isEmpty(uploadFileSize)) {
      setConfirmNotification({
        title: t("delete_title_last", "Are you sure?"),
        confirmText: t(
          "finalize_model_import",
          "You need to <b>set up your models to finalize the import.</b> When you cancel that process, your progress will be lost."
        ),
        description: "",
      });
    } else {
      onDone();
    }
  }, [uploadFileSize, onDone, setConfirmNotification]);

  return (
    <>
      {currentSetupModelIndex < 0 ? (
        <>
          <div className="upload-progress-modal--header model-upload-progress-modal--header">
            <h2>{t("upload_models_simple", "Upload Models")}</h2>
            <IconButton
              className="btn-close"
              iconId="close"
              onClick={closeSetupModel}
            />
          </div>
          <UploadZone
            uploadLabel={t(
              "drag_and_drop_model",
              "<b>Drag & Drop IFC File</b> anywhere to "
            )}
            uploadLinkLabel={t("upload_ifc_model", "upload")}
            uploadHint={t(
              "supported_files_ifc",
              "Supported Files .IFC file formats 2 GB max"
            )}
            acceptedFilesFormats={".ifc"}
            uploadFileProgress={uploadFileProgress}
            isBackgroundGray
          />
          <div className="upload-progress-modal--body model-upload-progress-modal--body">
            <h3>{t("uploaded_files", "Uploaded Files")}</h3>
            <div className="upload-progress-modal--body--row upload-progress-modal--body--title model-upload-progress-modal--body--row model-upload-progress-modal--body--title">
              <div>{t("name", "Name")}</div>
              <div>{t("state", "State")}</div>
            </div>
            <div className="progress-group custom-scrollbar">
              {!isEmpty(progress) &&
                Object.keys(progress).map((file, index) => (
                  <div
                    className="upload-progress-modal--body--row model-upload-progress-modal--body--row"
                    key={`progress-${index}`}
                  >
                    <div className="row-file-name text-ellipsis">{file}</div>
                    <ProgressBar state={progress[file]} />
                  </div>
                ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="upload-progress-modal--header setup-model--header">
            <div className="header-title">
              <h2>{t("setup_model_file", "Set up your model file")}</h2>
              <IconButton
                className="btn-close"
                iconId="close"
                onClick={closeSetupModel}
              />
            </div>
            <div className="header-progress">
              {!isEmpty(uploadFileSize) && (
                <>
                  <div>
                    <p className="file-count">
                      <span>{`${currentSetupModelIndex + 1}`}</span>
                      {`/${Object.keys(uploadFileSize).length} ${t(
                        "files",
                        "Files"
                      )}`}
                    </p>
                    <p className="file-name">{fileName}</p>
                  </div>
                  <div>
                    <span className="file-size">{`${totalUploadSize} MB`}</span>
                    <ProgressBar state={uploadState} />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="upload-progress-modal--body setup-model--body">
            <p className="setup-model--body_title">{t("options", "Options")}</p>
            <div className="form-control-group">
              <div className="group-item">
                <label>{`${t("layer_section", "Layer/Section/View")}*`}</label>
                <CustomSelect
                  values={floors}
                  onChange={(value) => handleSelect("floor", fileName, value)}
                  value={metadatas[fileName]?.floor || {}}
                  isModel={true}
                  isOptional={false}
                />
              </div>
              <div className="group-item">
                <label>{`${t("discipline", "Discipline")}*`}</label>
                <CustomSelect
                  values={disciplines}
                  onChange={(value) =>
                    handleSelect("discipline", fileName, value)
                  }
                  value={metadatas[fileName]?.discipline || {}}
                  isModel={true}
                  isOptional={false}
                />
              </div>
              <div className="group-item">
                <label>{`${t("building", "Building")}*`}</label>
                <CustomSelect
                  values={buildings}
                  onChange={(value) =>
                    handleSelect("building", fileName, value)
                  }
                  value={metadatas[fileName]?.building || {}}
                  isModel={true}
                  isOptional={false}
                />
              </div>
            </div>
            <div className="form-control-group">
              <label className="item-add-document">
                <input
                  type="checkbox"
                  value={!!isStoreDoc[fileName]}
                  checked={!!isStoreDoc[fileName]}
                  onChange={(e) => onChangeCheckbox(e, fileName)}
                />
                {t("add_model_to_documents", "Add model to documents")}
              </label>
            </div>
          </div>
        </>
      )}
      <ModalFooter
        uploadCategory={uploadCategory}
        currentSetupModelIndex={currentSetupModelIndex}
        handleButtonClick={handleButtonClick}
        isEnableFinish={uploadState >= 100}
        onDone={onDone}
        doneText={doneText}
        uploadFileSize={uploadFileSize}
      />
    </>
  );
};

export default withTranslation()(SetupModel);
