import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  DocumentStatusService,
  EncodingService,
  WorkphaseService,
} from '../../Services';
import { useIssueFilterItems, useTags } from '../../Helpers/Hooks';
import { getEncodingTitle } from '../../Helpers/Encoding';

import ChangeStatusFeedback from '../ChangeStatusFeedback';
import EncodingSchema from '../Encoding/EncodingSchema';
import Loader from '../Loader';
import ConfirmModal from '../ConfirmModal';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import useLegacySettingsNotificationDialogContext from 'settings/hooks/useLegacySettingsNotificationDialogContext';

const ProjectEncoding = () => {
  const { saveChangesNotification, setSaveChangesNotification, setHasUnsavedChanges } = useLegacySettingsNotificationDialogContext();
  const { t } = useTranslation('settings');

  const { data: currentProject } = useCurrentProjectQuery();
  const currentUserRole = useCurrentUserRole();
  const { issueFilterItems } = useIssueFilterItems();
  const { categories, buildings, floors } = issueFilterItems;
  const { tagOptions } = useTags(currentProject?.id, currentProject?.tagIds);
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);

  const defaultSchemeParams = {
    allowMetaMapping: false,
    groups: [],
    applyFileTypes: [],
    useFullFileName: false,
  };

  const [apiError, setApiError] = useState('');
  const [isEnableSave, setIsEnableSave] = useState(false);
  const [namingSettings, setNamingSettings] = useState({
    forceUploadRestriction: false,
    bypassRestrictionForPrivateDocs: false,
    allowUploadFilesWhenSchemaByTypeNotFound: false
  });
  const projectsName = [
    'plan_encoding',
    'document_encoding',
    'model_encoding',
    'custom_encoding',
    'settings',
  ];
  const [curEncodingName, setCurEncodingName] = useState('plan_encoding');
  const [encodings, setEncodings] = useState({
    plan_encoding: defaultSchemeParams,
    document_encoding: defaultSchemeParams,
    model_encoding: defaultSchemeParams,
    custom_encoding: defaultSchemeParams,
  });
  const [visoplanGroups, setVisoplanGroups] = useState([]);
  const [orgEncodingGroupLength, setOrgEncodingGroupLength] = useState({
    plan_encoding: 0,
    document_encoding: 0,
    model_encoding: 0,
    custom_encoding: 0,
  });
  const [separatorsTypes, setSeparatorsTypes] = useState([]);
  const [dateTypes, setDateTypes] = useState([]);
  const [documentStatuses, setDocumentStatuses] = useState([]);
  const [workphases, setWorkphases] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isVisibleSavedFeedback, setVisibleSavedFeedback] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [isExistValidEncodings, setIsExistValidEncodings] = useState(false);

  // TODO: instead of passing this down, just call the useCurrentUserRole hook gain down there.
  const userRoles = useMemo(() => ({
    NamingScheme_Create_Edit: currentUserRole?.allowedActions?.has(RoleAction.NamingScheme_Create_Edit),
    NamingScheme_Delete: currentUserRole?.allowedActions?.has(RoleAction.NamingScheme_Delete),
  }));

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    if (isEnableSave) {
      window.onbeforeunload = function () {
        return true;
      };

      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [isEnableSave]);

  useEffect(() => {
    setHasUnsavedChanges(isEnableSave);
  }, [isEnableSave]);

  const initDefaultSettingsValue = () => {
    setNamingSettings({
      forceUploadRestriction:
        currentProject.namingSchemeSettings?.forceUploadRestriction,
      bypassRestrictionForPrivateDocs:
        currentProject.namingSchemeSettings?.bypassRestrictionForPrivateDocs,
      allowUploadFilesWhenSchemaByTypeNotFound:
        currentProject.namingSchemeSettings
          ?.allowUploadFilesWhenSchemaByTypeNotFound,
    });
  };

  useEffect(() => {
    if (currentProject) {
      initDefaultSettingsValue();
    }
  }, [currentProject]);

  const getOptions = async () => {
    const groupTypes = await EncodingService.getGroupTypes();
    const separators = await EncodingService.getSeparators();
    const dateTypes = await EncodingService.getDateTypes();

    let translatedGroupTypes = {};
    for (const [key, value] of Object.entries(groupTypes)) {
      translatedGroupTypes = {
        ...translatedGroupTypes,
        //Possible Values:
        //t('disciplines', {ns: 'common', defaultValue: 'Disciplines'});
        //t('building', {ns: 'common', defaultValue: 'Building'});
        //t('floor', {ns: 'common', defaultValue: 'Floor'});
        //t('workPhases', {ns: 'common', defaultValue: 'WorkPhases'});
        //t('creationDate', {ns: 'common', defaultValue: 'CreationDate'});
        //t('version', {ns: 'common', defaultValue: 'Version'});
        //t('freeText', {ns: 'common', defaultValue: 'FreeText'});
        //t('project', {ns: 'common', defaultValue: 'Project'});
        //t('custom', {ns: 'common', defaultValue: 'Custom'});
        //t('docStatus', {ns: 'common', defaultValue: 'docStatus'});

        [key]: t(value.toLowerCase(), { ns: 'settings' }),
      };
    }

    setOptions(translatedGroupTypes, setVisoplanGroups);
    setOptions(separators, setSeparatorsTypes);
    setOptions(dateTypes, setDateTypes);
  };

  const setOptions = useCallback(
    (obj, setState) => {
      for (const [key, value] of Object.entries(obj)) {
        setState((prevState) => [
          ...prevState,
          {
            value: key,
            label: value,
          },
        ]);
      }
    },
    [separatorsTypes]
  );

  const showFeedback = (response) => {
    if (response) {
      setVisibleSavedFeedback(true);
      setTimeout(() => {
        setVisibleSavedFeedback(false);
      }, 3000);
    }
  };

  const deleteEncoding = () => {
    setIsEnableSave(true);
    setEncodings({
      ...encodings,
      [curEncodingName]: defaultSchemeParams
    });
  };

  const onSubmit = useCallback(
    async (e) => {
      e?.preventDefault();
      setIsSaving(true);

      const payload = {
        id: encodings[curEncodingName]?.id,
        name: curEncodingName,
        allowMetaMapping: encodings[curEncodingName]?.allowMetaMapping,
        groups: encodings[curEncodingName]?.groups,
        applyFileTypes: encodings[curEncodingName]?.applyFileTypes,
        useFullFileName: encodings[curEncodingName]?.useFullFileName,
      };

      let nmSettings = {
        ...currentProject.namingSchemeSettings,
        ...namingSettings
      };

      if (e?.target.id === 'delete' && encodings[curEncodingName].id) {
        const response = await EncodingService.deleteNamingScheme(
          encodings[curEncodingName].id,
          setApiError
        );

        const isEmptyScheme = Object.values(encodings).every((encoding) => (
          encoding.id === encodings[curEncodingName].id || !encoding.groups.length
        ));

        // If there aren't any schemes
        if (isEmptyScheme) {
          await EncodingService.updateNamingSettings(currentProject?.id, {
            ...currentProject.namingSchemeSettings,
            forceUploadRestriction: false,
            bypassRestrictionForPrivateDocs: false,
            allowUploadFilesWhenSchemaByTypeNotFound: false,
          });
        }

        deleteEncoding();
        setUpdated(true);
        setOrgEncodingGroupLength({
          ...orgEncodingGroupLength,
          [curEncodingName]: 0,
        });
        setShowModal(false);
        showFeedback(response);
        setIsEnableSave(false);
      }

      if (e?.target.id === 'delete' && !encodings[curEncodingName].id) {
        deleteEncoding();
        setShowModal(false);
        setIsEnableSave(false);
      }

      if (curEncodingName === 'settings') {
        await EncodingService.updateNamingSettings(
          currentProject?.id,
          nmSettings
        );
        Object.keys(encodings).forEach(
          async (key) => {
            const encoding = encodings[key];
            if (encoding.id) {
              const payload = {
                id: encoding?.id,
                name: key,
                allowMetaMapping: encoding.allowMetaMapping,
                groups: encoding.groups,
                applyFileTypes: [...encoding.applyFileTypes],
                useFullFileName: encoding.useFullFileName,
              };
              const response = await EncodingService.updateNamingScheme(
                payload,
                setApiError
              );
              if (!response) {
                setIsSaving(false);
                return;
              }

              showFeedback(true);
              setUpdated(true);
            }
          }
        );
        setIsEnableSave(false);
      }
      
      if (!e?.target.id && orgEncodingGroupLength[curEncodingName] > 0) {
        await EncodingService.updateNamingSettings(
          currentProject?.id,
          nmSettings
        );
        const response = await EncodingService.updateNamingScheme(
          payload,
          setApiError
        );
        if (!response) {
          setIsSaving(false);
          return;
        }

        showFeedback(true);
        setUpdated(true);
        setIsEnableSave(false);
      }

      if (!e?.target.id && orgEncodingGroupLength[curEncodingName] === 0) {
        // if some groups are added into the empty encoding,
        // allowMetaMapping of a speficic encoding should be true as a default value
        const response = await EncodingService.createNamingScheme(
          {
            ...payload,
            allowMetaMapping: true,
          },
          setApiError
        );

        if (!response) {
          setIsSaving(false);
          return;
        }

        // If the scheme is added from the empty status at the beginning,
        // forceUploadRestriction should be true automatically.
        if (Object.values(orgEncodingGroupLength).every((value) => !value)) {
          nmSettings = {
            ...nmSettings,
            forceUploadRestriction: true,
          }
        }

        await EncodingService.updateNamingSettings(
          currentProject?.id,
          nmSettings
        );

        setUpdated(true);
        showFeedback(true);
        setIsEnableSave(false);
      }

      setIsSaving(false);
    },
    [
      namingSettings,
      curEncodingName,
      encodings,
      orgEncodingGroupLength,
    ]
  );

  const fetchData = useCallback(async () => {
    if (!currentProject?.id) return;

    setIsLoading(true);
    const namingSchemeData = await EncodingService.getNamingScheme(
      currentProject.id
    );
    const namingScheme = namingSchemeData.map((item) => ({ ...item, useFullFileName: item?.useFullFileName ?? false }));

    const workphases = await WorkphaseService.getWorkphases(
      currentProject.workPhaseIds
    );

    const docStatuses = await DocumentStatusService.getDocumentStatus({
      projectId: currentProject?.id,
    });

    workphases.forEach(
      (type) => (
        (type.abbreviation = type.name),
        (type.label = type.name),
        (type.value = type.name),
        (type.metaId = type.id)
      )
    );

    docStatuses.forEach(
      (type) => (
        (type.abbreviation = ''),
        (type.label = type.name),
        (type.value = type.name),
        (type.metaId = type.id)
      )
    );

    setWorkphases(workphases);
    setDocumentStatuses(docStatuses);
    updateScheme(namingScheme);
    setIsLoading(false);
    setApiError('');
  }, [currentProject?.id]);

  useEffect(() => {
    const initLoading = () => {
      fetchData();
    }
    initLoading();
  }, []);

  useEffect(() => {
    if (!apiError && updated) {
      fetchData();
      setUpdated(false);
    }
  }, [updated]);

  useEffect(() => {
    if (saveChangesNotification === 'discard') {
      fetchData();
      setIsEnableSave(false);
      setSaveChangesNotification('');
    }
    if (saveChangesNotification === 'save') {
      onSubmit();
      setSaveChangesNotification('');
    }
  }, [saveChangesNotification]);

  const addNewGroup = () => {
    const defaultObj = {
      name: '',
      type: '',
      separator: '',
      groupElements: [],
    };

    setEncodings({
      ...encodings,
      [curEncodingName]: {
        ...encodings[curEncodingName],
        groups: [
          ...encodings[curEncodingName].groups,
          defaultObj
        ]
      }
    });
    setIsEnableSave(true);
  };

  const updateScheme = (scheme) => {
    if (!scheme) return;

    setIsExistValidEncodings(!!scheme?.length);

    scheme.map((item) => {
      setOrgEncodingGroupLength((prevState) => ({
        ...prevState,
        [item.name]: item.groups.length,
      }));
      setEncodings((prevState) => ({
        ...prevState,
        [item.name]: {
          ...item
        }
      }));
    });
  };

  const handleCheckChange = (e) => {
    switch (e.target.name) {
      case 'plan_encoding':
      case 'document_encoding':
      case 'model_encoding':
      case 'custom_encoding':
        setEncodings({
          ...encodings,
          [e.target.name]: {
            ...encodings[e.target.name],
            allowMetaMapping: !encodings[e.target.name].allowMetaMapping,
          }
        });
        break;
      case 'allowUploadFiles':
        setNamingSettings({
          ...namingSettings,
          allowUploadFilesWhenSchemaByTypeNotFound:
            !namingSettings.allowUploadFilesWhenSchemaByTypeNotFound,
        });
        break;
      case 'forceUploadRestriction':
        setNamingSettings({
          ...namingSettings,
          forceUploadRestriction: !namingSettings.forceUploadRestriction,
        });
        break;
      case 'allDocs':
        setNamingSettings({
          ...namingSettings,
          bypassRestrictionForPrivateDocs: false,
        });
        break;
      case 'privateDocs':
        setNamingSettings({
          ...namingSettings,
          bypassRestrictionForPrivateDocs: true,
        });
        break;
      default:
        break;
    }
    setIsEnableSave(true);
  };

  const projectElement = [
    {
      abbreviation: currentProject?.abbreviation,
      label: '',
      name: currentProject?.name,
      value: currentProject?.name,
    },
  ];

  const isActive = useMemo(() => (
    orgEncodingGroupLength[curEncodingName] >= 2
  ), [orgEncodingGroupLength, curEncodingName]);

  const checkSaveChanges = (name) => {
    if (isEnableSave) {
      setIsUnsaved(true);
      setProjectName(name.replace(/\s+/g, ''));
    } else {
      setCurEncodingName(name.replace(/\s+/g, ''));
    }
  };

  const onSaveChanges = async (e) => {
    await onSubmit(e);
    setCurEncodingName(projectName);
    setIsUnsaved(false);
  }

  const onDismissChanges = async () => {
    deleteEncoding();
    await fetchData();
    setCurEncodingName(projectName);
    setIsEnableSave(false);
    setIsUnsaved(false);
    initDefaultSettingsValue();
  }

  return (
    <div className='setting-view project-encoding'>
      <form onSubmit={onSubmit}>
        <div className='project-encoding-header p-relative'>
          <div className='project-encoding-button'>
            {projectsName.map((name, index) => (
              <button
                key={`project + ${index}`}
                className={curEncodingName === name ? 'active' : ''}
                onClick={() => checkSaveChanges(name)}
                type='button'
                disabled={name === 'settings' ? !isExistValidEncodings : false}
              >
                {getEncodingTitle(name)}
              </button>
            ))}
          </div>
          {isActive && (
            <div className='scheme-active'>{t('active', 'Active')}</div>
          )}
          <button
            id="ProjectEncodingSaveButton"
            type='submit'
            className='btn btn--primary btn-save'
            disabled={
              !userRoles.NamingScheme_Create_Edit ? true : !isEnableSave
            }
          >
            {t('save', { ns: 'common', defaultValue: 'Save' })}
          </button>
          <Loader
            isSmall={true}
            isLoading={isSaving}
          />
        </div>
        {isVisibleSavedFeedback && (
          <ChangeStatusFeedback
            description={t('saved_successfully', 'Saved successfully')}
          />
        )}
        {apiError ? (
          <span className='scheme--error'>
            {apiError === 'Id required'
              ? t('minimum_group_required', 'Minimum 2 groups required')
              : apiError === 'Model required'
              ? t('all_fields_required', 'All fields should be filled')
              : apiError}
          </span>
        ) : (
          ''
        )}
        <div className='project-encoding-content'>
          {isLoading ? (
            <div>
              <Loader isLoading={true} />
            </div>
          ) : (
            <EncodingSchema
              curEncodingName={curEncodingName}
              encodings={encodings}
              setEncodings={setEncodings}
              addNewGroup={addNewGroup}
              visoplanGroups={visoplanGroups}
              dateTypes={dateTypes}
              separatorsTypes={separatorsTypes}
              documentStatuses={documentStatuses}
              categories={categories}
              buildings={buildings}
              workphasesType={workphases}
              floors={floors}
              onSubmit={onSubmit}
              handleCheckChange={handleCheckChange}
              tagOptions={tagOptions}
              namingSettings={namingSettings}
              setApiError={setApiError}
              setShowModal={setShowModal}
              showModal={showModal}
              projectElement={projectElement}
              setIsEnableSave={setIsEnableSave}
              getTitle={getEncodingTitle}
            />
          )}
        </div>
        {isUnsaved && (
          <ConfirmModal
            title={t('unsaved_changes', 'Unsaved Changes')}
            confirmText={t(
              'dismiss_changes',
              'You have unsaved changes. Please select if you want to save or dismiss those changes.'
            )}
            onYes={onSaveChanges}
            onNo={onDismissChanges}
            yesText={t('save', { ns: 'common', defaultValue: 'Save' })}
            noText={t('dismiss', { ns: 'common', defaultValue: 'Dismiss' })}
          />
        )}
      </form>
    </div>
  );
};

export default ProjectEncoding;
