import { useMutation } from '@tanstack/react-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDocumentVersionUpdateMutationFn from 'documents/hooks/useDocumentVersionUpdateMutationFn';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import DocumentVersionBuildingUpdateDto from 'documents/types/DocumentVersionBuildingUpdateDto';

export default function useDocumentVersionBuildingUpdateMutation() {
  const mutationFn = useDocumentVersionUpdateMutationFn<DocumentVersionBuildingUpdateDto>(ApiEndpoint.DocumentVersionBuilding);
  return useMutation<DocumentVersionDto, unknown, DocumentVersionBuildingUpdateDto>(mutationFn);
}
