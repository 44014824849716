import { useQuery } from '@tanstack/react-query';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useFolderEntityFactory from 'documents-folders/hooks/useFolderEntityFactory';
import useFolderTreeQueryKey from 'documents-folders/hooks/useFolderTreeQueryKey';
import Folder from 'documents-folders/types/Folder';
import FolderDto from 'documents-folders/types/FolderDto';
import buildQuery from 'odata-query';

export default function useFolderTreeQuery() {
  const odataQueryString = buildQuery({ filter: { isTopLevel: true } });
  const projectId = useCurrentProjectId();
  const queryKey = useFolderTreeQueryKey(projectId);
  const axiosInstance = useAxiosInstance();
  const entityFactory = useFolderEntityFactory();
  return useQuery(queryKey ?? [], async () => {
    const url = `/${ApiEndpoint.Folder}${odataQueryString}&includeDescendants=true`;
    const response = await axiosInstance.get<FolderDto[]>(url);
    const folders = response.data.map(entityFactory);
    const foldersById = new Map<string, Folder>();
    const stack: Folder[] = [...folders];
    while (stack.length > 0) {
      const pivot = stack.pop();
      if (pivot) {
        foldersById.set(pivot.id, pivot);
        if (pivot.childFolders) {
          stack.push(...pivot.childFolders);
        }
      }
    }
    return {
      foldersById,
      folders,
    };
  }, { structuralSharing: false });
}
