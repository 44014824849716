import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ChangeStatusFeedback from "Components/ChangeStatusFeedback";
import NotificationModal from "Components/NotificationModal";
import Loader from "Components/Loader";

import { EmailService } from "Services";
import useCurrentProjectQuery from "projects/hooks/useCurrentProjectQuery";
import { useQueryClient } from "@tanstack/react-query";
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useLegacySettingsNotificationDialogContext from 'settings/hooks/useLegacySettingsNotificationDialogContext';

const visoplan_mail_suffix = "@inbox.visoplan.de";

const ProjectEmailSetting = () => {
  const { saveChangesNotification, setSaveChangesNotification, setHasUnsavedChanges } = useLegacySettingsNotificationDialogContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: currentProject } = useCurrentProjectQuery();
  const [isEnableSave, setIsEnableSave] = useState(false);
  const [projectEmail, setProjectEmail] = useState(
    currentProject?.inboxEmailAddress
  );
  const [isVisibleSavedFeedback] = useState(false);
  const [notification, setNotification] = useState(null);
  const [input, setInput] = useState({
    new: "",
    repeat: "",
  });
  const [error, setError] = useState({
    match: "",
    api: "",
  });
  const [isSaving, setIsSaving] = useState(false);

  const updateApiError = (msg) => {
    setError({
      match: "",
      api: msg,
    });
  };

  const queryClient = useQueryClient();
  const { baseQueryKey: projectBaseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Project);

  const onSave = useCallback(
    async (e) => {
      if (!e || !input.new) return;
      e.preventDefault();
      setIsSaving(true);

      const response = await EmailService.updateProjectEmail(
        {
          projectId: currentProject?.id,
          name: input.new,
        },
        updateApiError
      );
      if (response) {
        const newMail = `${input.new}${visoplan_mail_suffix}`;
        queryClient.invalidateQueries(projectBaseQueryKey);
        setProjectEmail(newMail);
        setInput({
          new: "",
          repeat: "",
        });
        setError({
          match: "",
          api: "",
        });
        setIsEnableSave(false);
      }
      setIsSaving(false);
    },
    [input, currentProject]
  );

  const handleInputChange = useCallback((value, key) => {
    setInput((input) => ({
      ...input,
      [key]: value,
    }));
  }, []);

  useEffect(() => {
    if (input.repeat && input.new !== input.repeat) {
      setError((error) => ({
        ...error,
        match: "email isn't matched",
      }));
      setIsEnableSave(false);
    } else {
      setError((error) => ({
        ...error,
        match: "",
      }));
      if (input.new || input.repeat) setIsEnableSave(true);
    }
  }, [input]);

  const initialize = useCallback(() => {
    setInput({
      new: "",
      repeat: "",
    });
    setError({
      api: "",
      match: "",
    });
  }, []);

  useEffect(() => {
    setHasUnsavedChanges(isEnableSave);
  }, [isEnableSave]);

  useEffect(() => {
    if (currentProject && saveChangesNotification === "discard") {
      initialize();
      setIsEnableSave(false);
      setSaveChangesNotification("");
    }
    if (currentProject && saveChangesNotification === "save") {
      onSave();
      setSaveChangesNotification("");
    }
  }, [currentProject, saveChangesNotification]);

  return (
    <div className="setting-view project-email">
      <form onSubmit={onSave}>
        <div className="project-tags-header p-relative">
          {isVisibleSavedFeedback && (
            <ChangeStatusFeedback
              description={t(
                "project_email_updated_successfully",
                "Project email was updated successfully"
              )}
            />
          )}
          <input
            id="SubmitProjectEmailButton"
            type="submit"
            className="btn btn--primary btn-save"
            value={t("save", { ns: "common", defaultValue: "Save" })}
            disabled={!isEnableSave}
          />
          <Loader
            isSmall={true}
            isLoading={isSaving}
          />
        </div>
        <div className="project-tags-content project-email-content">
          <div className="project-tags-content-left">
            <h3 className="project-tags-content-left-title">
              {t("project_email", "Project Email")}
            </h3>
            <p className="project-tags-content-left-description">
              {t(
                "define_project_email",
                "Set up your project email. With this email you can store project emails in the email overview of Visoplan"
              )}
            </p>
          </div>
          <div className="project-tags-content-right">
            <p className="project-email-content_api-error">{error.api}</p>
            <div className="project-email-content_item">
              <label className="project-email-content_item-label">
                {t("current_project_email", "Current project email")}
              </label>
              <p className="project-email-content_item-para">{projectEmail}</p>
            </div>
            <div className="project-email-content_item">
              <label className="project-email-content_item-label">
                {t("new_project_email", "New project email")}
              </label>
              <div className="project-email-content_item-group">
                <input
                  id="ProjectEmailInput"
                  className="project-email-content_item-input"
                  value={input.new}
                  onChange={(e) => handleInputChange(e.target.value, "new")}
                  required
                />
                <span className="project-email-content_item-span">
                  {visoplan_mail_suffix}
                </span>
              </div>
            </div>
            <div className="project-email-content_item">
              <label className="project-email-content_item-label">
                {t("repeat_project_email", "Repeat project email")}
              </label>
              <div className="project-email-content_item-group">
                <input
                  id="RepeatProjectEmailInput"
                  className={`project-email-content_item-input ${
                    error.match ? "input-error" : ""
                  }`}
                  value={input.repeat}
                  onChange={(e) => handleInputChange(e.target.value, "repeat")}
                  required
                />
                <span className="project-email-content_item-span">
                  {visoplan_mail_suffix}
                </span>
              </div>
              <p className="project-email-content_match-error">{error.match}</p>
            </div>
          </div>
        </div>
      </form>
      {!!notification && (
        <NotificationModal
          onDone={() => setNotification(null)}
          notification={notification}
          isAutomationClose={true}
        />
      )}
    </div>
  );
};

export default ProjectEmailSetting;
