import QueryFilter from 'documents-filter/types/QueryFilter';
import QueryFilterSortMode from 'documents-filter/types/QueryFilterSortMode';
import React, { Dispatch, SetStateAction } from 'react';

export interface QueryFilterFilterContextState {
  isFilterActive: boolean,
  searchString: string | undefined,
  setSearchString: Dispatch<SetStateAction<string | undefined>>,
  filterPredicate: (filter: QueryFilter) => boolean,
  sortMode: QueryFilterSortMode,
  setSortMode: Dispatch<SetStateAction<QueryFilterSortMode>>,
}

const defaultValue: QueryFilterFilterContextState = {
  isFilterActive: false,
  searchString: undefined,
  setSearchString: () => {},
  filterPredicate: () => true,
  sortMode: QueryFilterSortMode.CreationDateDesc,
  setSortMode: () => {},
};

const QueryFilterFilterContext = React.createContext(defaultValue);

export default QueryFilterFilterContext;
