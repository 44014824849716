import useDefaultEntityFactory from 'api/hooks/useDefaultEntityFactory';
import useCollaboratorEntityFactory from 'collaborators/hooks/useCollaboratorEntityFactory';
import useCollaboratorRoleDefinitionEntityFactory from 'collaborators/hooks/useCollaboratorRoleDefinitionEntityFactory';
import BuiltInRoleNames from 'projects/types/BuiltInRoleNames';
import Project from 'projects/types/Project';
import ProjectDto from 'projects/types/ProjectDto';
import RoleAction from 'projects/types/RoleAction';
import CollaboratorRoleDefinition from 'projects/types/CollaboratorRoleDefinition';
import { useCallback } from 'react';

export default function useProjectEntityFactory() {
  const defaultProjectEntityFactory = useDefaultEntityFactory<ProjectDto, Project>();
  const collaboratorRoleDefinitionEntityFactory = useCollaboratorRoleDefinitionEntityFactory();
  const collaboratorEntityFactory = useCollaboratorEntityFactory();
  return useCallback<(dto: ProjectDto) => Readonly<Project>>((dto: ProjectDto) => {
    if (!dto.collaboratorRoleDefinitions) {
      return {
        ...defaultProjectEntityFactory(dto),
        roleDefinitionsByCollaboratorId: new Map<string, CollaboratorRoleDefinition>(),
      } as Readonly<Project>;
    }
    const roleDefinitionEntities = dto.collaboratorRoleDefinitions.map((roleDefinitionDto) => ({
      ...collaboratorRoleDefinitionEntityFactory(roleDefinitionDto),
      allowedActions: new Set<RoleAction>(roleDefinitionDto.actions.filter((a) => a.isAllowed).map((a) => a.action)),
      isAdmin: roleDefinitionDto.name === BuiltInRoleNames.Admin,
      isMember: roleDefinitionDto.name === BuiltInRoleNames.Member,
      isCoordinator: roleDefinitionDto.name === BuiltInRoleNames.Coordinator,
      isExternal: roleDefinitionDto.name === BuiltInRoleNames.External,
    }));
    const roleDefinitionsById = new Map<string, CollaboratorRoleDefinition>(roleDefinitionEntities.map((d) => [d.id, d]));
    const roleDefinitionsByCollaboratorId = new Map<string, CollaboratorRoleDefinition>(dto.collaboratorRoles.map((role) => {
      const roleDefinition = roleDefinitionsById.get(role.roleDefinitionId);
      if (!roleDefinition) throw new Error('A role definition that is assigned to a collaborator is not present in the project data.');
      return [role.collaboratorId, roleDefinition];
    }));
    return {
      ...defaultProjectEntityFactory(dto),
      roleDefinitionsByCollaboratorId,
      collaborators: dto.userPreviews.map(collaboratorEntityFactory),
      collaboratorRoleDefinitions: roleDefinitionEntities,
    } as Readonly<Project>;
  }, [defaultProjectEntityFactory, collaboratorRoleDefinitionEntityFactory, collaboratorEntityFactory]);
}
