import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import UpdateFolderNameDto from 'documents-folders/types/UpdateFolderNameDto';
import useFolderTreeQueryKey from 'documents-folders/hooks/useFolderTreeQueryKey';
import FolderDto from 'documents-folders/types/FolderDto';

export default function useFolderUpdateNameMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const folderTreeQueryKey = useFolderTreeQueryKey();
  return useMutation<FolderDto | undefined, unknown, UpdateFolderNameDto>(async (dto: UpdateFolderNameDto) => {
    const { data: folderDto } = await axiosInstance.patch<FolderDto>(`${ApiEndpoint.Folder}/name`, dto);
    return folderDto;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(folderTreeQueryKey);
    },
  });
}
