import React, { useContext, useEffect, useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import FolderTreePanel from 'documents-folders/components/FolderTreePanel';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import PlanlistTreePanel from 'documents-lists/components/PlanlistTreePanel';
import DocumentScopeContext, { DocumentScopeContextState } from 'documents/contexts/DocumentScopeContext';
import FilterTreePanel from 'documents-filter/components/FilterTreePanel';

interface DocumentsNavigationTabsProps extends ISxProps {
}

function isNavigationScopeKey(key: DocumentScopeKey) {
  return key === DocumentScopeKey.Folder || key === DocumentScopeKey.Filter || key === DocumentScopeKey.Planlist;
}

export default function DocumentsNavigationTabs({
  sx,
}: DocumentsNavigationTabsProps) {
  const { t } = useTranslation('documents');
  const { documentScope } = useContext<DocumentScopeContextState>(DocumentScopeContext);
  const [navigationScope, setNavigationScope] = useState<DocumentScopeKey>(isNavigationScopeKey(documentScope.key) ? documentScope.key : DocumentScopeKey.Folder);
  const onChangeTab = (event: React.SyntheticEvent, newValue: DocumentScopeKey) => {
    setNavigationScope(newValue);
  };
  useEffect(() => {
    if (isNavigationScopeKey(documentScope.key)) {
      setNavigationScope(documentScope.key);
    }
  }, [documentScope.key]);

  return (
    <Box
      id="DocumentsNavigationTabs"
      sx={{
        ...sx, display: 'flex', flexDirection: 'column', flexShrink: 0,
      }}
    >
      <Tabs value={navigationScope} onChange={onChangeTab} variant="fullWidth">
        <Tab value={DocumentScopeKey.Folder} label={t('navigation-tabs_folder-label', 'Folders')} />
        <Tab value={DocumentScopeKey.Filter} label={t('navigation-tabs_filter-label', 'Filters')} />
        <Tab value={DocumentScopeKey.Planlist} label={t('navigation-tabs_planlists-label', 'Planlists')} />
      </Tabs>
      <Box sx={{
        flexGrow: 1, p: 1, pr: 0, display: 'flex', flexDirection: 'column', boxShadow: 'inset 0px 3px 6px -1px rgba(0,0,0,0.1)',
      }}
      >
        {navigationScope === DocumentScopeKey.Folder && <FolderTreePanel sx={{ flexGrow: 1 }} />}
        {navigationScope === DocumentScopeKey.Filter && <FilterTreePanel sx={{ flexGrow: 1 }} />}
        {navigationScope === DocumentScopeKey.Planlist && <PlanlistTreePanel sx={{ flexGrow: 1 }} />}
      </Box>
    </Box>
  );
}
