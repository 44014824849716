import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import useDocumentListsOdataQuery from 'documents-lists/hooks/useDocumentListsOdataQuery';
import useDocumentListCreateMutation from 'documents-lists/hooks/useDocumentListCreateMutation';
import useUserGroupsOdataQuery from 'users-groups/hooks/useUserGroupsOdataQuery';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import PlanlistPermissionsPanel, { DocumentListAccessIds } from 'documents-lists/components/PlanlistPermissionsPanel';
import DocumentListCreationDto from 'documents-lists/types/DocumentListCreationDto';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import EffectiveAccessRightsDialog from 'documents-lists/components/EffectiveAccessRightsDialog';

interface CreatePlanlistDialogProps {
  documentVersionIds?: string[],
  onClose: (createdPlanlistId: string | undefined) => void,
  canGoBack?: boolean,
  open?: boolean,
}

export default function CreatePlanlistDialog({
  documentVersionIds,
  onClose,
  canGoBack,
  open,
}: CreatePlanlistDialogProps) {
  const { t } = useTranslation('documents-lists');
  const { data: userGroups } = useUserGroupsOdataQuery({});
  const { data: planlists, isLoading: isLoadingPlanlists } = useDocumentListsOdataQuery({});
  const { mutateAsync: mutateCreateAsync, isLoading: isLoadingCreate } = useDocumentListCreateMutation();
  const [createdPlanlistId, setCreatedPlanlistId] = useState<string | undefined>();
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [requestErrorMessage, setRequestErrorMessage] = useState<string | undefined>(undefined);
  const [effectiveAccessRightsDialogOpen, setEffectiveAccessRightsDialogOpen] = useState(false);
  const [createDto, setCreateDto] = useState<DocumentListCreationDto | undefined>(undefined);
  const planlistNames = useMemo(() => (planlists ? new Set(planlists.map((p) => p.name)) : undefined), [planlists]);
  const nameError = useMemo(() => (planlistNames && createDto && !successDialogOpen && planlistNames.has(createDto.name) ? t('create-planlist-dialog_already-exists-error', 'A planlist with this name already exists.') : undefined), [createDto, planlistNames, successDialogOpen, t]);

  useEffect(() => setCreateDto((prev) => {
    // set initial default value for the create dto
    if (prev || !userGroups) return prev; // init
    const defaultGroup = userGroups.find((group) => group.name === 'All') ?? userGroups[0];
    return {
      name: '',
      documentVersionIds: documentVersionIds ?? [],
      readAccessGroupIds: defaultGroup ? [defaultGroup.id] : [],
      writeAccessGroupIds: defaultGroup ? [defaultGroup.id] : [],
      readAccessUserIds: [],
      writeAccessUserIds: [],
    };
  }), [documentVersionIds, userGroups]);

  useEffect(() => setCreateDto((prev) => {
    if (!prev || !documentVersionIds) return prev;
    return { ...prev, documentVersionIds };
  }), [documentVersionIds]);

  const getRequestErrorMessage = useRequestErrorMessage();
  const clearErrorMessage = useCallback(() => setRequestErrorMessage(undefined), []);

  const onChangeName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCreateDto((prev) => (prev ? { ...prev, name: event.target.value } : prev));
  }, []);

  const documentListAccessIds = useMemo(() => {
    if (!createDto) return undefined;
    return {
      readAccessUserIds: createDto.readAccessUserIds,
      readAccessGroupIds: createDto.readAccessGroupIds,
      writeAccessUserIds: createDto.writeAccessUserIds,
      writeAccessGroupIds: createDto.writeAccessGroupIds,
    };
  }, [createDto]);

  const onChangeDocumentListAccessIds = useCallback((value: DocumentListAccessIds) => {
    setCreateDto((prev) => (prev ? { ...prev, ...value } : prev));
  }, []);

  const onClickShowEffectiveAccessRightsDialog = useCallback(() => setEffectiveAccessRightsDialogOpen(true), []);
  const onCloseEffectiveAccessRightsDialog = useCallback(() => setEffectiveAccessRightsDialogOpen(false), []);

  const onConfirm = useCallback(async () => {
    if (!createDto) return;
    try {
      const createdPlanlist = await mutateCreateAsync(createDto);
      setCreatedPlanlistId(createdPlanlist.id);
      setSuccessDialogOpen(true);
    } catch (error) {
      setRequestErrorMessage(getRequestErrorMessage(error));
    }
  }, [mutateCreateAsync, createDto, getRequestErrorMessage]);

  const onCloseSuccessDialog = useCallback(() => onClose(createdPlanlistId), [createdPlanlistId, onClose]);
  const onCancel = useCallback(() => onClose(undefined), [onClose]);

  return (
    <Dialog
      id="CreatePlanlistDialog"
      open={open ?? true}
      scroll="paper"
      PaperProps={{ style: { width: '600px', minHeight: '600px', maxHeight: '800px' } }}
    >
      <DialogTitle>
        {documentVersionIds?.length
          ? t('create-planlist-dialog_count-title', 'Create New Planlist with {{count}} documents', { count: documentVersionIds.length })
          : t('create-planlist-dialog_empty-title', 'Create New Planlist')}
      </DialogTitle>
      <Divider sx={{ mt: 1 }} />
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: 4, overflow: 'hidden' }}>
          <TextField
            value={createDto?.name || ''}
            disabled={!createDto}
            onChange={onChangeName}
            label={t('create-planlist-dialog_name-textfield-label', 'Name')}
            sx={{
              mt: 1, flexGrow: 1, flexShrink: 1, minWidth: '40px',
            }}
            error={!!nameError}
            helperText={nameError}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, overflow: 'auto', flexGrow: 1 }}>
            <Typography variant="h5">{t('create-planlist-dialog_permissions-header', 'Access Rights')}</Typography>
            {!!documentListAccessIds && <PlanlistPermissionsPanel value={documentListAccessIds} onChange={onChangeDocumentListAccessIds} />}
            {!documentListAccessIds && <CenteredCircularProgress />}
          </Box>
          {!!requestErrorMessage && <Alert severity="error" onClose={clearErrorMessage}>{requestErrorMessage}</Alert>}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2, pt: 3 }}>
        <Button variant="contained" color="secondary" onClick={onCancel} sx={{ mr: 'auto' }}>
          {canGoBack
            ? t('create-planlist-dialog_back-button-label', 'Back')
            : t('create-planlist-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button
          variant="text"
          color="primary"
          disabled={!createDto}
          onClick={onClickShowEffectiveAccessRightsDialog}
          sx={{ pl: 1, ml: 'auto' }}
        >
          <InfoIcon sx={{ mr: 0.5 }} />
          {t('create-planlist-dialog_effective-access-rights-button-label', 'Show me who will have access')}
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm} disabled={isLoadingPlanlists || isLoadingCreate || !createDto?.name || !!nameError}>
          {t('create-planlist-dialog_confirm-button-label', 'Create Planlist')}
          {isLoadingCreate && <CircularProgress size={12} sx={{ ml: 1 }} />}
        </Button>
      </DialogActions>
      {!!successDialogOpen && (
        <Dialog open>
          <DialogTitle>{t('create-planlist-dialog_create-success-dialog-title', 'Planlist Created')}</DialogTitle>
          <DialogContent>
            <Alert severity="success">{t('create-planlist-dialog_success-message', 'The Planlist {{planlist}} was created with {{count}} documents.', { count: documentVersionIds?.length ?? 0, planlist: createDto?.name ?? '' })}</Alert>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={onCloseSuccessDialog}>{t('create-planlist-dialog_success-dialog-close-button-label', 'Close')}</Button>
          </DialogActions>
        </Dialog>
      )}
      {!!effectiveAccessRightsDialogOpen && !!documentListAccessIds && (
        <EffectiveAccessRightsDialog accessIds={documentListAccessIds} onClose={onCloseEffectiveAccessRightsDialog} />
      )}
    </Dialog>
  );
}
