import React from 'react';
import { useTranslation } from 'react-i18next';

import useIssueHierarchyLevel from 'issues/hooks/useIssueHierarchyLevel';

const HierarchyColors = [
  'transparent',
  '#F0F0F0',
  '#E6E6E6',
  '#DCDCDC',
  '#D1D1D1',
  '#C8C8C8'
];

export const IssueItem = ({
  prefix,
  issue,
  issues,
  icon,
  selectFolder,
  columns,
  index,
  row_events,
  setResized,
  resized,
}) => {
  const { t } = useTranslation();
  const { hierarchyLevel } = useIssueHierarchyLevel({ issue, issues });

  let positionOfRow = null;
  return (
    <div
      className="row-for-issue"
      style={{
        backgroundColor: HierarchyColors[hierarchyLevel > 5 ? 5 : hierarchyLevel],
      }}
    >
      <div
        className="table-row pl-10"
        onClick={() => selectFolder && selectFolder(issue)}
      >
        {icon ? (
          <svg
            className={
              "chevron " +
              (icon === "arrow-hierarchy-down"
                ? "child-issue-down-chevron"
                : "child-issue-chevron")
            }
          >
            <use xlinkHref={"/img/sprite.svg#" + icon} />
          </svg>
        ) : (
          <></>
        )}
        {columns?.map((column, columnIdx) => {
          const headerColumn = document.getElementById(
            `${prefix}${column?.value?.replace(/\s/g, "")}`
          );
          const width = headerColumn?.style?.width;
          const minWidth = headerColumn?.style?.minWidth;
          const id = `${issue.id}${column?.value?.replace(/\s/g, "")}`;
          const conditionForChildNameCol = width;
          positionOfRow = document
            .getElementById(`${issue.parentId}ID`)
            ?.getBoundingClientRect()?.x;
          return (
            <div
              className={`table-row__part ${column?.dataField} ${
                id !== `${issue.id}ID` ? " border-right-tables" : ""
              } ${
                issue?.childrenIds?.length < 1 && id === `${issue.id}Name`
                  ? "fw-500"
                  : ""
              }`}
              key={`column-${columnIdx}`}
              style={{
                width: conditionForChildNameCol,
                minWidth:
                  minWidth === "unset" ? conditionForChildNameCol : minWidth,
              }}
              id={id}
              onClick={(e) => row_events(e, issue, index)}
              onMouseOver={!setResized ? () => {} : () => setResized(!resized)}
              onMouseLeave={!setResized ? () => {} : () => setResized(!resized)}
            >
              {column.formatter(issue[column.dataField], issue, prefix)}
            </div>
          );
        })}
        <div className="table-btn">
          <div
            className={
              "mobile--hide label issue-label " +
              (issue.label === "new" ? "bg-light" : "bg-orange-1")
            }
            style={{ visibility: issue.label ? "visible" : "hidden" }}
          >
            {t(issue.label) || issue.label}
          </div>
        </div>
      </div>
      <div
        className={"borderBottomForHierarchy"}
        style={{ left: positionOfRow && `-${positionOfRow}px` }}
      ></div>
    </div>
  );
};
