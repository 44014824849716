import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { isEqual, sortBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Core, UI } from '@pdftron/webviewer';

import IssueOld from 'issues/types/IssueOld';
import IssueStatus from 'issues/types/IssueStatus';
import $TSFixMe from 'common/types/FixMeAny';

import * as Request from 'Helpers/Request';
import {
  FileTypeIndexes,
  IssueModalMode,
} from 'Helpers/Common';
import RoleAction from 'projects/types/RoleAction';

import CustomSelect from 'Components/CustomSelect';
import Loader from 'Components/Loader';
import PDFPreview from 'Components/DocumentPreviewModal/PDFPreview';
import { CompareView } from 'Components/DocumentPreviewModal/CompareView';
import CompareFeature from 'Components/DocumentPreviewModal/CompareFeature';
import CompareFeatureByImage from 'Components/DocumentPreviewModal/CompareFeatureByImage';
import CopyLink from 'Components/Buttons/CopyLink';
import IssueBoardColumnOld from 'Components/IssueBoard/IssueBoardColumnOld';
import IssueModal from 'Components/IssueModal';
import { getGeneralSvg } from 'Components/SVGs';
import AsideIssue from 'Components/Aside/AsideIssue/AsideIssue';
import { CloseButton } from 'Components/Buttons';
import { DocumentVersionOld } from 'Entities/RestEntities/DocumentVersion';
import { PreviewVersions } from 'Helpers/Hooks/usePreviewVersions';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import TagCreateSelect from 'tags/components/TagCreateSelect';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import useDocumentVersionTagsUpdateMutation from 'documents/hooks/useDocumentVersionTagsUpdateMutation';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import DocumentVersionTagsUpdateDto from 'documents/types/DocumentVersionTagsUpdateDto';

export interface DocumentPreviewModalProps {
  versions?: PreviewVersions[];
  setSelectedPreviewDoc: React.Dispatch<React.SetStateAction<DocumentVersionOld | null>>;
  previewDoc: DocumentVersionOld | null;
  changeVersion: (documentId: string, version: number) => void;
  folderId?: string;
  baseDocumentUrl?: string;
  issueDocument: boolean;
  baseRoute?: string;
  mode: string;
  tagOptions: $TSFixMe;
  issues?: IssueOld[];
  collaboratorRoleDefinitions: $TSFixMe;
  allDocuments: $TSFixMe;
  allEmails: $TSFixMe;
  updateTag: $TSFixMe;
  isDocLog: $TSFixMe;
  setIsDocLog: $TSFixMe;
  statuses: $TSFixMe;
  priorities: $TSFixMe;
  types: $TSFixMe;
  categories: $TSFixMe;
  buildings: $TSFixMe;
  floors: $TSFixMe;
  workphases: $TSFixMe;
  users: $TSFixMe;
}

function DocumentPreviewModal({
  versions,
  setSelectedPreviewDoc,
  previewDoc,
  changeVersion,
  folderId,
  baseDocumentUrl,
  issueDocument,
  baseRoute,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mode,
  tagOptions,
  issues,
  collaboratorRoleDefinitions,
  allDocuments,
  allEmails,
  updateTag,
  isDocLog,
  setIsDocLog,
  statuses,
  priorities,
  types,
  categories,
  buildings,
  floors,
  workphases,
  users,
}: DocumentPreviewModalProps) {
  const { t, i18n } = useTranslation();
  const currentUserRole = useCurrentUserRole();
  const navigate = useNavigate();
  const [version, setVersion] = useState<$TSFixMe>({});
  const [isLoading, setIsLoading] = useState(true);
  const [comparing, setComparing] = useState(false);
  const [compare, setCompare] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState<DocumentVersionOld | null>(null);
  const [selectVersion, setSelectVersion] = useState({});
  const [view, setView] = useState('semantic');
  const [areLinkedIssuesVisible, setAreLinkedIssuesVisible] = useState(false);
  const [linkedIssues, setLinkedIssues] = useState<IssueOld[]>([]);
  const [addIssue, setAddIssue] = useState(false);
  const [isLinkedCreatIssue, setIsLinkedCreatIssue] = useState(false);
  const [documentLinkedIssue, setDocumentLinkedIssue] = useState<IssueOld[]>([]);
  const [isLinkExistingIssue, setIsLinkExistingIssue] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState<IssueOld | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<IssueStatus[]>([]);
  const [previewIssue, setPreviewIssue] = useState<IssueOld | null>(null);
  const [linkedIssuesFiltered, setLinkedIssuesFiltered] = useState<IssueOld[]>([]);
  const [previewerUI, setPreviewerUI] = useState<typeof UI | null>(null);
  const [downloadWithMarkings, setDownloadWithMarkings] = useState(false);
  const [isPdfPreviewInitialized, setPdfPreviewInitialized] = useState(false);
  const [
    pdfAnnotationManager,
    setPDFAnnotationManager] = useState<Core.AnnotationManager | null>(null);
  const comparingVersions = useMemo(() => {
    if (!version) return [];
    return versions?.map(
      (item) => (version.value > item.value ? item : { ...item, isDisabled: true }),
    );
  }, [versions, version]);

  const linkedIssuesFilteredByStatus = useMemo(() => linkedIssuesFiltered
    .filter((i) => selectedStatus
      .map((s) => s.id)
      .includes(i.issueStatus.id)), [linkedIssuesFiltered, selectedStatus]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { current_user_role } = useSelector(
    (state: any) => state.projects,
  );

  const date = new Date(previewDoc?.timeStamp ?? 0);
  const time = date.toLocaleTimeString(i18n.language, {
    timeStyle: 'short',
    timeZone: 'UTC',
  });

  const fileType = useMemo(() => {
    if (!previewDoc) return '';
    const splits = previewDoc.name?.split('.');
    if (splits) {
      return splits[splits.length - 1];
    }
    return undefined;
  }, [previewDoc]);

  useEffect(() => {
    if (statuses.length) {
      setSelectedStatus(
        statuses.filter(
          (status: IssueStatus) => status.originalName !== 'Closed',
        ),
      );
    }
  }, [statuses]);

  useEffect(() => {
    (async () => {
      if (issues && issues.length) {
        const filteredIssues = issues.filter((issue) => (
          previewDoc?.linkedIssueIds.find((id) => issue.id === id)
        ));

        setLinkedIssues(filteredIssues);
        setLinkedIssuesFiltered(filteredIssues);
        if (isLinkedCreatIssue) {
          setSelectedIssue(
            sortBy(
              filteredIssues.filter((issue: $TSFixMe) => issue.label === 'new'),
              'creationDate',
            ).reverse()[0],
          );
        }
      }
    })();
  }, [previewDoc, issues, isLinkedCreatIssue]);

  useEffect(() => {
    if (!previewDoc) return;
    const filters = versions?.filter(
      (item) => item.value === previewDoc?.versionNumber,
    );
    setVersion(
      filters?.length
        ? filters[0]
        : { value: null, label: t('select_ellipsis', 'select...') },
    );
    setSelectVersion(
      filters?.length
        ? filters[0]
        : { value: null, label: t('select_ellipsis', 'select...') },
    );
  }, [versions, previewDoc, t]);

  const onClose = useCallback(() => {
    setIsLoading(false);
    setSelectedPreviewDoc(null);
    if (setIsDocLog) {
      setIsDocLog(false);
    }
    if (!issueDocument) navigate(`${baseDocumentUrl}${folderId}`);
  }, [
    baseDocumentUrl,
    folderId,
    navigate,
    issueDocument,
    setIsDocLog,
    setSelectedPreviewDoc,
  ]);

  const handleSelectChange = useCallback(
    (value: $TSFixMe, name: string) => {
      switch (name) {
        case 'version':
          if (!previewDoc || isEqual(version, value)) return;
          setVersion(value);
          changeVersion(previewDoc.documentId, value.value);
          setIsLoading(true);
          break;
        default:
          break;
      }
    },
    [previewDoc, changeVersion, version],
  );

  const onDownload = useCallback(() => {
    if (previewDoc === null) throw new Error('Preview doc is null.');
    if (!previewerUI) throw new Error('previewerUI missing.');
    previewerUI.downloadPdf({ includeAnnotations: downloadWithMarkings, flatten: true });
  }, [previewDoc, previewerUI, downloadWithMarkings]);

  const onPdfPreviewInitialized = useCallback(() => {
    setPdfPreviewInitialized(true);
  }, []);

  const selectedVersionDoc = useCallback(
    (documentId: string, versionToBeSelected: number) => {
      Request.GET(`api/document/${documentId}`)
        .then((result: $TSFixMe) => {
          const documentsVersions = result.body[0].documentVersionIds;
          return documentsVersions;
        })
        .then((documentsVersions: string[]) => {
          Request.GET(`api/documentVersion/${documentsVersions}`)
            .then((result: $TSFixMe) => {
              const selectedDocVersion = result.body;
              return selectedDocVersion;
            })
            .then((selectedDocVersion: $TSFixMe) => {
              const selected = selectedDocVersion.filter(
                (item: DocumentVersionOld) => item.versionNumber === versionToBeSelected,
              );
              setSelectedVersion(selected[0]);
              setCompare(true);
            });
        });
    },
    [],
  );

  const optionsToken = useMemo(() => {
    let extension: string;
    if (previewDoc?.fileType === FileTypeIndexes.DWG) {
      extension = 'pdf';
    } else if (previewDoc?.name.slice(-4) === 'jpeg') {
      extension = 'jpg';
    } else {
      extension = previewDoc?.name.slice(-3) ?? '';
    }

    return {
      customHeaders: {
        Authorization: `bearer ${sessionStorage.getItem('userTokenBearer')}`,
        'X-Project-Authorization': `bearer ${sessionStorage.getItem('projectTokenBearer')}`,
        'Visoplan-Client-Id': 'Visoplan Webclient',
        'Visoplan-Webclient-Request-Source': 'DocumentPreviewModal',
      },
      fileName: previewDoc?.name,
      extension,
    };
  }, [previewDoc?.fileType, previewDoc?.name]);

  const data = useMemo(
    () => (previewDoc?.fileType === FileTypeIndexes.DWG
      ? selectedVersion?.previewFileId
      : selectedVersion?.fileId),
    [previewDoc, selectedVersion],
  );

  const data2 = useMemo(
    () => (previewDoc?.fileType === FileTypeIndexes.DWG
      ? previewDoc?.previewFileId
      : previewDoc?.fileId),
    [previewDoc],
  );

  const updateStatus = (issue: IssueOld, status: IssueStatus) => {
    if (
      status.id !== issue?.issueStatus?.id
      && (current_user_role !== 2 || status.originalName !== 'Closed')
    ) {
      setIsLoading(true);

      return Request.PUT('api/issue', [
        {
          id: issue.id,
          statusId: { value: status.id },
        },
      ]).then(async () => {
        setIsLoading(false);
      });
    }
    return Promise.resolve();
  };

  useEffect(() => {
    setSelectedIssue(previewIssue);
  }, [previewIssue]);

  const openIssueDetails = (openIssues: IssueOld[]) => {
    setAreLinkedIssuesVisible(true);
    setSelectedIssue(openIssues[0]);
    setPreviewIssue(openIssues[0]);
  };

  const setShownIssuesList = useCallback(
    (issueIds?: string[]) => {
      if (!issueIds) {
        setLinkedIssuesFiltered(linkedIssues);
        return;
      }
      const issuesToBeShown = linkedIssues.filter((i) => issueIds.includes(i.id));
      setLinkedIssuesFiltered(issuesToBeShown);
    },
    [linkedIssues],
  );

  const { mutateAsync } = useDocumentVersionTagsUpdateMutation();
  const { data: currentProject } = useCurrentProjectQuery();
  const { data: documentVersions } = useDocumentVersionsQuery(previewDoc?.id ? { filter: { id: { eq: previewDoc.id } } } : undefined);
  const documentVersion = useMemo(() => documentVersions?.[0], [documentVersions]);
  const handleSelectTags = useCallback(async (nextSelectedTagIds: string[]) => {
    if (!documentVersion || !currentProject?.tagIds) return;
    const nextSelectedTagIdsSet = new Set(nextSelectedTagIds);
    const reorderedNextSelectedTagIds = currentProject.tagIds.filter((tagId) => nextSelectedTagIdsSet.has(tagId));
    const persistDto: DocumentVersionTagsUpdateDto = {
      id: documentVersion.id,
      tagIds: reorderedNextSelectedTagIds,
    };
    await mutateAsync(persistDto);
  }, [currentProject, documentVersion, mutateAsync]);

  return (
    <>
      <div className={`document-preview ${addIssue ? 'hide' : ''}`}>
        <Loader isLoading={isLoading} />
        <div className="pdfviewer__header">
          <span className="pdfviewer__header__type hide-all">{fileType}</span>
          <div className="pdfviewer__header__group">
            <span className="pdfviewer__header__group__name show-at550">
              {previewDoc?.originalFileName || previewDoc?.name}
            </span>
            <div className="pdfviewer__header__group__btns-group ">
              <div className="buttons">
                {!previewDoc?.isPrivate && (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <div
                    id="LinkedIssuesDocumentPreviewButton"
                    className="buttons_compare hide-at550"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      if (baseRoute) {
                        navigate(`${baseRoute}/${previewDoc?.id}=preview`);
                      }
                      setAreLinkedIssuesVisible(true);
                    }}
                  >
                    {t('linked_issues', 'Linked issues')}
                  </div>
                )}
                {previewDoc?.fileType === FileTypeIndexes.Pdf
                  || previewDoc?.fileType === FileTypeIndexes.DWG ? (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <div
                      id="CompareDocumentPreviewButton"
                      className="buttons_compare hide-at550"
                      role="button"
                      tabIndex={0}
                      onClick={() => setComparing(true)}
                    >
                      {t('compare', 'Compare')}
                    </div>
                  ) : null}
                {!previewDoc?.isPrivate && (
                  <>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                    <div
                      id="OpenCommentsDocumentPreviewButton"
                      className="buttons_comments hide-at550"
                      key={`${previewDoc?.id}-${previewDoc?.id}`}
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        if (baseRoute) {
                          navigate(
                            `${baseRoute}/${previewDoc?.id}/comments`,
                          );
                        }
                        setAreLinkedIssuesVisible(false);
                      }}
                    >
                      { getGeneralSvg('add-comment') }
                      {t('open_comments', 'Open Comments')}
                    </div>

                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                    <div
                      className="buttons_comments-small show-at550"
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        if (baseRoute) {
                          navigate(
                            `${baseRoute}/${previewDoc?.id}/comments`,
                          );
                        }
                        setAreLinkedIssuesVisible(false);
                      }}
                    >
                      { getGeneralSvg('add-comment') }
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                    <div
                      className="buttons_linked-issues show-at550"
                      role="button"
                      tabIndex={0}
                      onClick={() => setAreLinkedIssuesVisible(true)}
                    >
                      {getGeneralSvg('linked-issues')}
                    </div>
                  </>
                )}
              </div>
              <span className="pdfviewer__header__group__name hide-at550">
                {previewDoc?.originalFileName || previewDoc?.name}
              </span>
              <div className="buttons">
                <div className="copy-btn mobile--hide">
                  <CopyLink
                    id="CopyLinkDocumentPreviewButton"
                    issueId={undefined}
                    folderId={previewDoc?.folderId}
                    documentId={previewDoc?.id}
                  />
                </div>
                <div className="width-30px">
                  <CloseButton
                    id="CloseDocumentPreviewButton"
                    onClick={onClose}
                    rightPosition="30px"
                    topPosition="38px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="preview-section-viewer">
          {previewDoc && compare && view === 'semantic' && (
            <CompareFeature
              optionsToken={optionsToken}
              data={data}
              data2={data2}
              setComparing={setComparing}
            />
          )}
          {previewDoc && compare && view === 'image' && (
            <CompareFeatureByImage
              optionsToken={optionsToken}
              data={data}
              data2={data2}
              currentLanguage={undefined}
            />
          )}
          {previewDoc && !compare && (
            <PDFPreview
              resourceId={
                previewDoc.fileType === FileTypeIndexes.DWG
                  ? previewDoc.previewFileId
                  : previewDoc.id
              }
              resourceType={previewDoc.fileType}
              setLoading={setIsLoading}
              previewDoc={previewDoc}
              optionsToken={optionsToken}
              setAddIssue={setAddIssue}
              documentLinkedIssue={documentLinkedIssue}
              setDocumentLinkedIssue={setDocumentLinkedIssue}
              setIsLinkExistingIssue={setIsLinkExistingIssue}
              selectedIssue={selectedIssue ?? undefined}
              setShownIssuesList={setShownIssuesList}
              setPreviewerUI={setPreviewerUI}
              openLinkedIssues={() => setAreLinkedIssuesVisible(true)}
              pdfAnnotationManager={pdfAnnotationManager}
              setPDFAnnotationManager={setPDFAnnotationManager}
              onInitialized={onPdfPreviewInitialized}
            />
          )}
        </div>
        <div className="preview-section-footer">
          <div className="version-group">
            {!isDocLog ? (
              <div className="version-group-version">
                <p>{t('version', 'Version')}</p>
                <CustomSelect
                  id="VersionDocumentPreviewDropdown"
                  values={versions}
                  isDisabled={false}
                  onChange={(value: $TSFixMe) => handleSelectChange(value, 'version')}
                  value={version}
                  isModel
                  menuPlacement="top"
                />
              </div>
            ) : (
              ''
            )}
            <div className="version-group-tags">
              <p>{t('tags', 'Tags')}</p>
              <TagCreateSelect
                id="TagsDocumentPreviewSelect"
                value={documentVersion?.tagIds}
                onChange={handleSelectTags}
                sx={{ width: '200px' }}
              />
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              onClick={onDownload}
              className="btn--primary buttnon-download  show-at550"
              role="button"
              tabIndex={0}
            >
              <svg className="icon">
                <use xlinkHref="/img/svg/download.svg#download" />
              </svg>
            </div>
          </div>
          <div className="hide-at550 download-group">
            <div className="download-markings">
              <input
                type="checkbox"
                id="download-markings"
                onChange={() => setDownloadWithMarkings(!downloadWithMarkings)}
                checked={downloadWithMarkings}
              />
              <label htmlFor="download-markings">
                {t('download-with-markings', 'Download with markings')}
              </label>
            </div>
            <button
              id="DownloadDocumentPreviewButton"
              type="button"
              className="btn btn--primary btn-download"
              disabled={!isPdfPreviewInitialized}
              onClick={onDownload}
            >
              {t('download', 'Download')}
            </button>
            <span className="date">
              {t('date_only', {
                defaultValue: '{{val, datetime}}',
                var: date,
              })
                + t('space_character', ' ')
                + time}
            </span>
          </div>
        </div>
        {areLinkedIssuesVisible ? (
          <IssueBoardColumnOld
            id="LinkedIssuesDocumentPreviewButton"
            name={t('linked_issues', 'Linked Issues')}
            group={{}}
            // @ts-ignore
            tagOptions={tagOptions}
            types={types}
            priorities={priorities}
            data={linkedIssuesFilteredByStatus}
            viewIssue={setSelectedIssue}
            setShowLinkedIssues={setAreLinkedIssuesVisible}
            isLinkedIssues={areLinkedIssuesVisible}
            statuses={statuses}
            setPreviewIssue={setPreviewIssue}
            selectedIssue={selectedIssue}
            setStatus={setSelectedStatus}
            status={selectedStatus}
            setSelectedIssue={setSelectedIssue}
            isFilterActiveVisible={
              linkedIssues.length !== linkedIssuesFiltered.length
            }
            resetFilters={() => pdfAnnotationManager?.deselectAllAnnotations()}
          />
        ) : (
          ''
        )}
        {comparing ? (
          <CompareView
            setComparing={setComparing}
            setCompare={setCompare}
            version={selectVersion}
            // @ts-ignore
            handleSelectChange={handleSelectChange}
            versions={comparingVersions}
            previewDoc={previewDoc}
            selectedVersionDoc={selectedVersionDoc}
            setSelectVersion={setSelectVersion}
            view={view}
            setView={setView}
            t={t}
          />
        ) : null}
        {previewIssue && (
          // @ts-ignore
          <AsideIssue
            canEditIssue={false}
            show_aside={previewIssue && true}
            statuses={statuses}
            users={users || []}
            disciplines={categories || []}
            buildings={buildings || []}
            floors={floors || []}
            workphases={workphases || []}
            tagOptions={tagOptions}
            types={types}
            priorities={priorities}
            issue={previewIssue}
            toggleAside={() => setPreviewIssue(null)}
            updateStatus={updateStatus}
            updateTag={updateTag}
            allEmails={allEmails}
            allDocuments={allDocuments}
            allIssues={issues}
            setSelectedPreviewDoc={setSelectedPreviewDoc}
            setCurrentIssue={setPreviewIssue}
            setEditIssue={() => { }}
            isDocumentPreview
          />
        )}
      </div>
      {addIssue ? (
        // @ts-ignore
        <IssueModal
          users={users || []}
          categories={categories || []}
          buildings={buildings || []}
          floors={floors || []}
          workphases={workphases || []}
          priorities={priorities}
          types={types}
          statuses={statuses}
          tagOptions={tagOptions}
          toggleIssueModal={setAddIssue}
          mode={IssueModalMode.CREATE}
          selected={[]}
          setIssueSavedModalVisible={() => { }}
          issues={issues}
          collaboratorRoleDefinitions={collaboratorRoleDefinitions}
          canEditIssue={currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_Editing)}
          setIsLinkedCreatIssue={setIsLinkedCreatIssue}
          isLinkedIssues
          linkedIssues={linkedIssues}
          previewDocument={previewDoc?.id}
          setDocumentLinkedIssue={setDocumentLinkedIssue}
          documentLinkedIssue={documentLinkedIssue}
          isLinkExistingIssue={isLinkExistingIssue}
          setIsLinkExistingIssue={setIsLinkExistingIssue}
          currentIssue={selectedIssue}
          setCurrentIssue={setSelectedIssue}
          openIssueDetails={openIssueDetails}
          allIssues={issues}
          addIssue={addIssue}
          setSelectedPreviewDoc={setSelectedPreviewDoc}
        />
      ) : (
        ''
      )}
    </>
  );
}

DocumentPreviewModal.defaultProps = {
  versions: undefined,
  folderId: undefined,
  baseDocumentUrl: undefined,
  baseRoute: undefined,
  issues: undefined,
};

export default DocumentPreviewModal;
