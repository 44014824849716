import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import useIssuesCreateMutation from 'issues/hooks/useIssuesCreateMutation';
import useDefaultIssueStatus from 'issues/hooks/useDefaultIssueStatus';
import useProjectIssueTypesQuery from 'issues/hooks/useProjectIssueTypesQuery';
import useCollaboratorsQuery from 'collaborators/hooks/useCollaboratorsQuery';
import LocalizedDatePicker from 'common/components/LocalizedDatePicker';
import FileType from 'documents/types/FileType';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';

interface SelectNewIssueDialogProps {
  documentVersionIds: string[],
  onClose: (createdIssueId: string | undefined, locate: boolean) => void,
  suppressLocate?: boolean | undefined,
}

export default function LinkNewIssueDialog({
  documentVersionIds,
  onClose,
  suppressLocate,
}: SelectNewIssueDialogProps) {
  const { t } = useTranslation('issues');
  const defaultIssueStatus = useDefaultIssueStatus();
  const { data: issueTypes } = useProjectIssueTypesQuery();
  const { data: collaborators } = useCollaboratorsQuery();
  const { data: documentVersions } = useDocumentVersionsQuery(documentVersionIds ? { filter: { id: { in: documentVersionIds } } } : undefined);
  const [issueTitle, setIssueTitle] = useState<string | undefined>();
  const [issueDescription, setIssueDescription] = useState<string | undefined>();
  const [issueType, setIssueType] = useState<string | undefined>();
  const [assignedUserIds, setAssignedUserIds] = useState<string[]>([]);
  const [reviewerId, setReviewerId] = useState<string | undefined>(undefined);
  const [dueDate, setDueDate] = useState<Date | undefined>(undefined);
  const [mutationErrorMessage, setMutationErrorMessage] = useState<string | undefined>(undefined);
  const versionErrorMessage = useMemo(() => {
    if (!documentVersions) return undefined;
    const uniqueDocumentIds = new Set(documentVersions.map((d) => d.documentId));
    if (uniqueDocumentIds.size < documentVersions.length) {
      return t('link-new-issue-dialog_multiple-versions-of-same-document-error-message', 'You have selected multiple versions of the same document, but an issue can only be linked to a single version of the same document.');
    }
    return undefined;
  }, [documentVersions, t]);
  const errorMessage = useMemo(() => versionErrorMessage || mutationErrorMessage, [mutationErrorMessage, versionErrorMessage]);
  const getRequestErrorMessage = useRequestErrorMessage();
  const { mutateAsync } = useIssuesCreateMutation();
  const onChangeIssueTitle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIssueTitle(event.target.value);
  }, []);
  const onChangeIssueDescription = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIssueDescription(event.target.value);
  }, []);
  const onChangeIssueType = useCallback((event: SelectChangeEvent<string>) => {
    setIssueType(event.target.value);
  }, []);
  const onChangeAssignedUserIds = useCallback((event: SelectChangeEvent<string[]>) => {
    if (!Array.isArray(event.target.value)) return;
    setAssignedUserIds(event.target.value);
  }, []);
  const onChangeReviewerId = useCallback((event: SelectChangeEvent<string>) => {
    setReviewerId(event.target.value);
  }, []);
  const onChangeDueDate = useCallback((value: Moment | null) => {
    setDueDate(value?.toDate() ?? undefined);
  }, []);
  const onClickCancel = useCallback(() => {
    onClose(undefined, false);
  }, [onClose]);
  const confirmDisabled = useMemo(() => !issueTitle?.trim().length, [issueTitle]);
  const canLocate = useMemo(() => (documentVersions?.every((documentVersion) => documentVersion
    && (documentVersion.fileType === FileType.Pdf
      || documentVersion.fileType === FileType.DWG
      || (documentVersion.fileType === FileType.Image && !documentVersion.originalFileName.endsWith('.svg'))
    ))
  ), [documentVersions]);
  const createIssue = useCallback(async (locate: boolean) => {
    if (!defaultIssueStatus || !issueTitle?.trim().length || !documentVersionIds) return;
    try {
      const trimmedDescription = issueDescription?.trim();
      const issues = await mutateAsync([{
        title: { value: issueTitle.trim() },
        statusId: { value: defaultIssueStatus.id },
        linkedDocumentVersionIds: { value: documentVersionIds },
        description: trimmedDescription ? { value: trimmedDescription } : undefined,
        typeId: issueType ? { value: issueType } : undefined,
        assignedUserIds: assignedUserIds.length ? { value: assignedUserIds } : undefined,
        reviewerId: reviewerId ? { value: reviewerId } : undefined,
        dueDate: dueDate ? { value: dueDate.toISOString() } : undefined,
      }]);
      if (issues.length !== 1) throw new Error('Unexpected API response payload');
      onClose(issues[0].id, locate);
    } catch (error) {
      setMutationErrorMessage(getRequestErrorMessage(error));
    }
  }, [assignedUserIds, defaultIssueStatus, documentVersionIds, dueDate, getRequestErrorMessage, issueDescription, issueTitle, issueType, mutateAsync, onClose, reviewerId]);
  const onClickCreate = useCallback(async () => {
    await createIssue(false);
  }, [createIssue]);
  const onClickCreateAndLocate = useCallback(async () => {
    await createIssue(true);
  }, [createIssue]);

  // init with default value
  useEffect(() => {
    if (!issueTypes?.length || issueType !== undefined) return;
    setIssueType(issueTypes[0].id);
  }, [issueType, issueTypes]);

  return (
    <Dialog open id="LinkNewIssueDialog" PaperProps={{ sx: { minWidth: '400px' } }}>
      <DialogTitle>{t('link-new-issue-dialog_title', 'Create Issue')}</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <TextField
            label={t('link-new-issue-dialog_title-textfield-label', 'Title')}
            id="link-new-issue-dialog_title-textfield"
            value={issueTitle ?? ''}
            onChange={onChangeIssueTitle}
            sx={{ flex: '1 1 0' }}
          />
          <TextField
            label={t('link-new-issue-dialog_description-textfield-label', 'Description')}
            id="link-new-issue-dialog_description-textfield"
            value={issueDescription ?? ''}
            onChange={onChangeIssueDescription}
            multiline
            sx={{ flex: '1 1 0' }}
          />
          <FormControl sx={{ flex: '1 1 0' }}>
            <InputLabel id="link-new-issue-dialog_issue-type-select-label">
              {t('link-new-issue-dialog_issue-type-select-label', 'Type')}
            </InputLabel>
            <Select
              id="link-new-issue-dialog_issue-type-select"
              labelId="link-new-issue-dialog_issue-type-select-label"
              value={issueType ?? ''}
              onChange={onChangeIssueType}
              label={t('link-new-issue-dialog_issue-type-select-label', 'Type')}
            >
              {!!issueTypes && issueTypes.map((type) => <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl sx={{ flex: '1 1 0' }}>
            <InputLabel id="link-new-issue-dialog_assignee-select-label">
              {t('link-new-issue-dialog_assignee-select-label', 'Assigned')}
            </InputLabel>
            <Select
              id="link-new-issue-dialog_assignee-select"
              labelId="link-new-issue-dialog_assignee-select-label"
              multiple
              value={assignedUserIds ?? []}
              onChange={onChangeAssignedUserIds}
              label={t('link-new-issue-dialog_assignee-select-label', 'Assigned')}
            >
              {!!collaborators && collaborators.map((collaborator) => <MenuItem key={collaborator.id} value={collaborator.id}>{`${collaborator.firstName} ${collaborator.lastName}`}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl sx={{ flex: '1 1 0' }}>
            <InputLabel id="link-new-issue-dialog_reviewer-select-label">
              {t('link-new-issue-dialog_reviewer-select-label', 'Reviewer')}
            </InputLabel>
            <Select
              id="link-new-issue-dialog_reviewer-select"
              labelId="link-new-issue-dialog_reviewer-select-label"
              value={reviewerId ?? ''}
              onChange={onChangeReviewerId}
              label={t('link-new-issue-dialog_reviewer-select-label', 'Reviewer')}
            >
              {!!collaborators && collaborators.map((collaborator) => <MenuItem key={collaborator.id} value={collaborator.id}>{`${collaborator.firstName} ${collaborator.lastName}`}</MenuItem>)}
            </Select>
          </FormControl>
          <LocalizedDatePicker
            label={t('link-new-issue-dialog_due-date-label', 'Due Date')}
            value={dueDate ?? null}
            onChange={onChangeDueDate}
          />
          {!!errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </Box>
      </DialogContent>
      <DialogActions sx={{ gap: 1 }}>
        <Button variant="contained" color="secondary" onClick={onClickCancel} sx={{ mr: 'auto' }}>{t('link-new-issue-dialog_cancel-button-label', 'Cancel')}</Button>
        <Button variant="contained" color="primary" onClick={onClickCreate} disabled={confirmDisabled}>{t('link-new-issue-dialog_create-button-label', 'Create')}</Button>
        {canLocate && !suppressLocate && (
          <Button variant="contained" color="primary" onClick={onClickCreateAndLocate} disabled={confirmDisabled}>{t('link-new-issue-dialog_create-and-locate-button-label', 'Create and locate')}</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
