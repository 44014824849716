import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  useTheme, Box, Typography, Switch, CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import useCollaboratorRoleDefinitionUpdateMutation from 'projects/hooks/useCollaboratorRoleDefinitionUpdateMutation';
import PersistCollaboratorRoleDefinitionDto from 'projects/types/PersistCollaboratorRoleDefinitionDto';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import RoleAction from 'projects/types/RoleAction';
import CollaboratorRoleDefinition from 'projects/types/CollaboratorRoleDefinition';

interface RoleActionListItemProps extends ISxProps {
  roleDefinition: CollaboratorRoleDefinition,
  roleAction: RoleAction,
}

export default function RoleActionListItem({
  sx,
  roleDefinition,
  roleAction,
}: RoleActionListItemProps) {
  const { t } = useTranslation('settings');
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [displaySuccessIndicator, setDisplaySuccessIndicator] = useState<boolean>(false);
  const getRequestErrorMessage = useRequestErrorMessage();
  const { mutateAsync, isLoading } = useCollaboratorRoleDefinitionUpdateMutation();

  const [displayValue, setDisplayValue] = useState(roleDefinition.allowedActions.has(roleAction));
  useEffect(() => {
    setDisplayValue(roleDefinition.allowedActions.has(roleAction));
  }, [roleAction, roleDefinition.allowedActions]);

  const onChangeSwitch = useCallback(async () => {
    setErrorMessage(undefined);
    const persistDto: PersistCollaboratorRoleDefinitionDto = {
      id: roleDefinition.id,
      actions: { value: roleDefinition.actions.map((a) => (a.action === roleAction ? { ...a, isAllowed: !a.isAllowed } : a)) },
    };
    try {
      setDisplayValue(!roleDefinition.allowedActions.has(roleAction));
      await mutateAsync(persistDto);
      setDisplaySuccessIndicator(true);
      setTimeout(() => setDisplaySuccessIndicator(false), 1000);
    } catch (error) {
      setDisplayValue(roleDefinition.allowedActions.has(roleAction));
      const m = getRequestErrorMessage(error);
      setErrorMessage(m);
      setTimeout(() => setErrorMessage(undefined), 6000);
    }
  }, [getRequestErrorMessage, mutateAsync, roleAction, roleDefinition]);

  return (
    <Box sx={{
      ...sx, display: 'flex', alignItems: 'center', mt: 1, maxWidth: '520px',
    }}
    >
      <Typography sx={{ mr: 'auto' }}>{t(`role-settings_restrictions_role-action-name_${RoleAction[roleAction]}`, RoleAction[roleAction])}</Typography>
      {isLoading && <CircularProgress size={12} sx={{ mr: 1 }} />}
      <Typography sx={{ color: theme.palette.error.main, mr: 1 }}>{errorMessage ?? ''}</Typography>
      <Switch color={displaySuccessIndicator ? 'success' : errorMessage ? 'error' : undefined} checked={displayValue} disabled={isLoading || roleDefinition.isAdmin} onChange={onChangeSwitch} />
    </Box>
  );
}
