import useDefaultEntityFactory from 'api/hooks/useDefaultEntityFactory';
import ColorDescriptor from 'common/types/ColorDescriptor';
import ColorDescriptorDto from 'common/types/ColorDescriptorDto';
import { useCallback } from 'react';

export default function useColorDescriptorEntityFactory() {
  const defaultEntityFactory = useDefaultEntityFactory<ColorDescriptorDto, ColorDescriptor>();
  return useCallback((dto: ColorDescriptorDto) => ({
    ...defaultEntityFactory(dto),
  } as Readonly<ColorDescriptor>), [defaultEntityFactory]);
}
