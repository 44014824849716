import { useMemo } from 'react';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import _ from 'lodash';

export default function useDefaultIssueStatus() {
  const { data: issueStatuses } = useProjectIssueStatusesQuery();
  return useMemo(() => {
    if (!issueStatuses) return undefined;
    return _.minBy(issueStatuses, (issueStatus) => issueStatus.order);
  }, [issueStatuses]);
}
