import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import useQueryFilterEntityFactory from 'documents-filter/hooks/useQueryFilterEntityFactory';
import UpdateQueryFilterDto from 'documents-filter/types/UpdateQueryFilterDto';
import ApiEndpoint from 'api/types/ApiEndpoint';
import QueryFilter from 'documents-filter/types/QueryFilter';
import QueryFilterDto from 'documents-filter/types/QueryFilterDto';

export default function useQueryFilterUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { listsByCurrentProjectQueryKey: queryKey } = useDefaultEntityQueryKeys(ApiEndpoint.QueryFilter);
  const entityFactory = useQueryFilterEntityFactory();
  return useMutation<boolean | undefined, unknown, UpdateQueryFilterDto>(
    async (dto: UpdateQueryFilterDto) => {
      try {
        if (!dto.id?.length) return false;
        const { data: patchResult } = await axiosInstance.patch<boolean>(`${ApiEndpoint.QueryFilter}`, dto);
        if (!patchResult) return false;
        // cache update
        queryClient.setQueryData<QueryFilter[] | undefined>(queryKey, ((cachedData: QueryFilter[] | undefined) => {
          if (!cachedData) return undefined;
          const cachedEntityIndex = cachedData?.findIndex((f) => f.id === dto.id);
          if (cachedEntityIndex > -1) {
            const cachedEntity = cachedData[cachedEntityIndex];
            const localOnlyDto: QueryFilterDto = { ...cachedEntity };
            if (dto.name) localOnlyDto.name = dto.name;
            if (dto.uiFilterSettings) localOnlyDto.uiFilterSettings = dto.uiFilterSettings;
            const updatedCachedEntity = entityFactory(localOnlyDto);
            return [...cachedData.slice(0, cachedEntityIndex), updatedCachedEntity!, ...cachedData.slice(cachedEntityIndex + 1)];
          }
          return cachedData;
        }));
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 400) return error.response.data?.errorMessages[0]?.errorMessage;
        return undefined;
      }
    },
  );
}
