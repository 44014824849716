import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function TimelineIcon({
  htmlColor,
  fontSize,
}: Pick<SvgIconProps, 'htmlColor' | 'fontSize'>) {
  return (
    <SvgIcon htmlColor={htmlColor} fontSize={fontSize} viewBox="0 0 16 16">
      <path d="M1.32907 4.35933V7.33333H12.0872L13.0149 6.40333C13.3073 6.11067 13.7844 6.11067 14.0762 6.40333L15.0039 7.33333H16V8.66667H15.0032L14.0768 9.596C13.7844 9.88933 13.3073 9.88933 13.0156 9.596L12.0885 8.66667H1.32907V11.6413H2.27271L3.20106 10.7107C3.49346 10.418 3.97059 10.418 4.26233 10.7107L5.19068 11.6413H16V12.9747H5.18869L4.26166 13.904C3.96993 14.1973 3.49279 14.1973 3.20106 13.904L2.27404 12.9747H1.32907V16H0.0511692H0V0H0.0511692H1.32907V3.026H5.9529L6.88059 2.09533C7.17299 1.80267 7.65012 1.80267 7.94185 2.09533L8.87021 3.026H16V4.35933H8.86954L7.94252 5.28867C7.65079 5.582 7.17365 5.582 6.88192 5.28867L5.95423 4.35933H1.32907Z" />
    </SvgIcon>
  );
}
