import { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { isEmpty, uniqBy } from "lodash";

import { PER_PAGE_RECORDS, SyncDataType } from "../../Helpers/Common";
import { IssueService, ViewpointService } from "../../Services";
import { formatIssue } from "../../Services/IssueService";
import * as IssueHelper from "../../Helpers/Issue";
import useCurrentProjectQuery from "projects/hooks/useCurrentProjectQuery";
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';

const useLinkIssues = ({
  isParent,
  selectedTask,
  editIssue,
  issueModalProps,
  showCreateModal,
  selected,
  selectedModal,
  modalCount,
  scrollRef,
  hideIssueIds = [],
  setSelectedModal,
  setSelected,
  linkedIssues,
}) => {
  const { t } = useTranslation();
  const { sync_data } = useSelector((state) => state.project);
  const { data: currentProject } = useCurrentProjectQuery();
  const { data: disciplines } = useProjectDisciplinesQuery();
  const { data: floors } = useProjectFloorsQuery();
  const { data: buildings } = useProjectBuildingsQuery();
  const { filter_values_from_other_page } = useSelector(
    (state) => state.project
  );
  const [issues, setIssues] = useState([]);
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterValue, setFilterValue] = useState({});
  const [filterQuery, setFilterQuery] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [isSelectedAll, setSelectedAll] = useState(false);

  const getList = async (filter = null, showLoading = true, more = {}) => {
    if (showLoading) {
      if (scrollRef?.current) scrollRef.current.scrollTop = 0;
      setLoading(true);
    }

    filter = filter || {};
    //if not filtering
    if (!filter.statusId || isEmpty(filter.statusId)) {
      filter.statusId = {
        and: {
          or: (issueModalProps.statuses || [])
            .filter((status) => status.originalName !== "Closed")
            .map((status) => ({
              "": status.id,
            })),
        },
      };
      filter.parentId = { "": null };
    }
    Object.keys(filter).forEach((key) => {
      if (!filter[key]) {
        delete filter[key];
      }
    });

    let buffer = [];

    buffer = await IssueService.getIssuesByProjectId(
      currentProject.id,
      more?.skip ? issues.length : PER_PAGE_RECORDS,
      more?.skip ? more.skip : 0,
      filter
    );
    if (more?.skip) {
      buffer = uniqBy(filteredIssues.concat(buffer), "id");
    }

    buffer = buffer.map((item) => formatIssue(item, disciplines, buildings, floors));

    //fill viewpoints and issueLogs
    const viewpointIds = buffer.flatMap((item) => item.viewpointIds);
    const viewpointBuffer = viewpointIds?.length
      ? await ViewpointService.getViewpointsByIds(viewpointIds)
      : [];

    const issueLogs = !buffer?.length
      ? []
      : await IssueService.getIssueLogs(buffer.map((b) => b.issueLogId));

    buffer = buffer.map((item) => {
      item.viewpoints = viewpointBuffer.filter((vp) =>
        item.viewpointIds.some((id) => id === vp.id)
      );
      item.issueLog = issueLogs.find((log) => log?.id && log.id === item.issueLogId) || {
        issueLogEntries: [],
      };
      return item;
    });

    const filteredBuffer = buffer.filter(
      (item) =>
        item.id !== editIssue?.id && !hideIssueIds.find((id) => id === item.id)
    );

    setIssues(filteredBuffer);
    setFilteredIssues(filteredBuffer);

    setLoading(false);
  };

  //synchronize
  useEffect(() => {
    if (!currentProject || !sync_data?.dataSummaryEntries) return;

    sync_data.dataSummaryEntries.forEach((entry) => {
      if (entry.dataType === SyncDataType.Issue) {
        getList(filterQuery);
      }
    });
  }, [sync_data]);

  useEffect(() => {
    if (!currentProject?.id) return;
    getList();
  }, [showCreateModal, linkedIssues, currentProject?.id]);

  const selectAll = (isAllChecked) => {
    setSelectedAll(isAllChecked);
    if (modalCount === 2) {
      if (isAllChecked) {
        setSelectedModal(filteredIssues.map((issue) => issue.id));
      } else {
        setSelectedModal([]);
      }
    } else {
      if (isAllChecked) {
        setSelected(filteredIssues.map((issue) => issue.id));
      } else {
        setSelected([]);
      }
    }
  };

  const columns = useMemo(() => {
    const { tagOptions, statuses, types, workphases, priorities } =
      issueModalProps;

    return IssueHelper.getColumns({
      t,
      selected: modalCount === 2 ? selectedModal : selected,
      tagOptions,
      statuses,
      types,
      workphases,
      priorities,
      setSelected: modalCount === 2 ? setSelectedModal : setSelected,
      handleChange: () => {},
      cannotBeChanged: false,
      canAddTagsForIssue: true,
      isLinkIssue: true,
      isParent,
      selectedTask: selectedTask || [],
      isSelectedAll,
      setSelectedAll: selectAll,
      issues,
    });
  }, [
    selected,
    selectedModal,
    issueModalProps,
    isParent,
    selectedTask,
    isSelectedAll,
    issues,
  ]);
  const columns_loading = IssueHelper.getColumnsLoading(t);

  const handleFilterChange = useCallback(
    (valueType, value) => {
      setFilterValue({
        ...filterValue,
        [valueType]: value,
      });
    },
    [filterValue]
  );

  const getFilteredIssues = async (value) => {
    if (!currentProject?.id) return;

    let filter = {};
    if (filterValue.assignedTo?.length) {
      filter["assignedUserIds"] = {
        any: {
          "": {
            in: filterValue.assignedTo.map((assign) => assign.value),
          },
        },
      };
    }
    if (filterValue.reviewer?.value) {
      filter["reviewerId"] = { eq: filterValue.reviewer.value };
    }
    if (filterValue.priority?.value) {
      filter["priorityId"] = { eq: filterValue.priority.value };
    }
    if (filterValue.type?.value) {
      filter["typeId"] = { eq: filterValue.type.value };
    }
    if (filterValue.tags?.length) {
      filter["tagIds"] = {
        any: {
          "": {
            in: filterValue.tags.map((tag) => tag.value),
          },
        },
      };
    }
    if (filterValue.workphase?.value) {
      filter["workphaseId"] = { eq: filterValue.workphase.value };
    }
    if (filterValue.status?.value) {
      filter["statusId"] = { eq: filterValue.status.value };
    }
    if (value) {
      filter.and = {
        or: [
          { "tolower(title)": { contains: value.toLowerCase() } },
          { "tolower(description)": { contains: value.toLowerCase() } },
        ],
      };
    }

    setFilterQuery(filter);

    const issues = await IssueService.getIssuesByProjectId(
      currentProject.id,
      null,
      null,
      filter
    );
    const filtred = issues.filter(
      (item) =>
        item.id !== editIssue?.id && !hideIssueIds.find((id) => id === item.id)
    );
    const findIssues = filtred.filter((issue) =>
      issue.title.toLowerCase().includes(searchKey.trim().toLowerCase())
    );
    setFilteredIssues(findIssues);
  };

  //filtering the issues according to the filter value
  useEffect(() => {
    if (currentProject && Object.keys(filterValue).length) {
      getFilteredIssues();
    }
  }, [filterValue, searchKey]);

  const onSearchChange = (event) => {
    setSearchKey(event.target.value);
    getFilteredIssues(event.target.value);
  };

  const loadMore = useDebouncedCallback((params) => {
    if (
      !filter_values_from_other_page &&
      issues.length < currentProject.issueIds.length
    ) {
      getList(filterQuery, false, params);
    }
  }, 500);

  const handleScrollIssuesTable = (event) => {
    if (
      Math.abs(
        event.target.scrollHeight -
          event.target.scrollTop -
          event.target.clientHeight
      ) < 10
    ) {
      loadMore({
        skip: issues.length,
      });
    }
  };

  const isFiltering = useMemo(() => {
    return !isEmpty(filterQuery) || !!searchKey;
  }, [filterQuery, searchKey]);

  return {
    filteredIssues,
    loading,
    filterValue,
    searchKey,
    columns,
    columns_loading,
    isFiltering,
    handleFilterChange,
    onSearchChange,
    handleScrollIssuesTable,
    setFilteredIssues,
  };
};

export default useLinkIssues;
