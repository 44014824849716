import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAnnotationCheck } from 'Helpers/Hooks';
import { PDFAnnotation } from 'Entities/RestEntities/PDFAnnotation';

interface AnnotationCellProps {
  pdfAnnotations: PDFAnnotation[];
  pdfAnnotationIds: string[];
}

function AnnotationCell({
  pdfAnnotations,
  pdfAnnotationIds,
}: AnnotationCellProps) {
  const { t } = useTranslation();
  const { isVisibleAnnotation } = useAnnotationCheck({
    pdfAnnotations,
    pdfAnnotationIds,
  });

  return (
    <div className="documents-history--table--cell documents-history--table--cell--annotations">
      {isVisibleAnnotation ? t('yes', 'Yes') : t('no', 'No')}
    </div>
  );
}

export default AnnotationCell;
