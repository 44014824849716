import * as Request from "../Helpers/Request";

export const TagColors = [
  "#002FE9",
  "#56B977",
  "#F88282",
  "#FFAA66",
  "#C46BCB",
  "#6BC9F3",
  "#E9D100",
  "#00D449",
  "#DD006A",
  "#6456B9",
];

export const getTags = async (tagIds) => {
  const result = await Request.GET(`api/Tag/${tagIds}`);
  return result.status === 200 ? result.body : [];
};

export const getProjectTags = async () => {
  const result = await Request.GET(`api/Tag`);
  return result.status === 200 ? result.body : [];
};

export const createNewTag = async (dto) => {
  const result = await Request.POST("api/Tag", dto);
  return result.status === 200 ? result.body : [];
};

export const updateTag = async (dto) => {
  const result = await Request.PATCH("api/Tag", dto);
  return result.status === 200;
};

export const deleteTag = async (id) => {
  const result = await Request.DELETE(`api/Tag/${id}`);
  return result.status === 200;
};
