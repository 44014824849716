import ApiEndpoint from 'api/types/ApiEndpoint';
import useQueryFilterEntityFactory from 'documents-filter/hooks/useQueryFilterEntityFactory';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import { useQuery } from '@tanstack/react-query';
import QueryFilterDto from 'documents-filter/types/QueryFilterDto';

export default function useQueryFilterQuery() {
  const entityFactory = useQueryFilterEntityFactory();
  const axiosInstance = useAxiosInstance();
  const endpoint = ApiEndpoint.QueryFilter;
  const { listsByCurrentProjectQueryKey: queryKey } = useDefaultEntityQueryKeys(endpoint);
  return useQuery(queryKey, async () => {
    const response = await axiosInstance.get<QueryFilterDto[]>(`/${endpoint}`);
    return response.data.map(entityFactory);
  }, {
    retry: 1, retryDelay: 1000,
  });
}
