import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import useDocumentListUpdateDocumentsMutation from 'documents-lists/hooks/useDocumentListUpdateDocumentsMutation';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useDocumentListQuery from 'documents-lists/hooks/useDocumentListQuery';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';

interface ClearPlanlistDialogProps {
  planlistId: string,
  onClose: () => void,
}

export default function ClearPlanlistDialog({
  planlistId,
  onClose,
}: ClearPlanlistDialogProps) {
  const { t } = useTranslation('documents-lists');
  const { data: planlist } = useDocumentListQuery(planlistId);
  const getRequestErrorMessage = useRequestErrorMessage();
  const { mutateAsync, isLoading: isLoadingMutation } = useDocumentListUpdateDocumentsMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const onConfirm = useCallback(async () => {
    try {
      await mutateAsync({
        id: planlistId,
        documentVersionIds: [],
      });
      setSuccessDialogOpen(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, mutateAsync, planlistId]);
  return (
    <Dialog open PaperProps={{ sx: { maxWidth: '1000px' } }}>
      <DialogTitle component="div">
        <Typography variant="h2">{t('clear-planlist-dialog_title', 'Clear Plan List')}</Typography>
      </DialogTitle>
      <DialogContent>
        {!!planlist && <Alert severity="warning">{t('clear-planlist-dialog_message', 'Are you sure that you want to remove all documents from the plan list "{{planlist}}"?', { planlist: planlist.name })}</Alert>}
        {!planlist && <CenteredCircularProgress />}
        {!!errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={onClose}
          sx={{ mr: 'auto' }}
        >
          {t('clear-planlist-dialog_cancel-button-label', 'Cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={onConfirm}
          disabled={!planlist || isLoadingMutation}
          sx={{ gap: 1 }}
        >
          {!!isLoadingMutation && <CircularProgress size={12} />}
          {t('clear-planlist-dialog_confirm-button-label', 'Clear Planlist')}
        </Button>
      </DialogActions>
      {!!successDialogOpen && (
        <Dialog open>
          <DialogTitle>{t('clear-planlist-dialog_success-dialog-title', 'Planlist Updated')}</DialogTitle>
          <DialogContent>
            <Alert severity="success">{t('clear-planlist-dialog_success-message', 'The plan list {{planlist}} has been cleared.', { planlist: planlist?.name ?? '' })}</Alert>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={onClose}>{t('clear-planlist-dialog_success-dialog-close-button-label', 'Close')}</Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
}
