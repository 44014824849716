import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button, Checkbox, Menu, MenuList, MenuItem, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ListOptionsIcon from 'icons/ListOptionsIcon';
import ISxProps from 'common/types/ISxProps';
import DynamicLayoutKey from 'dynamic-layout/types/DynamicLayoutKey';
import useDynamicLayout from 'dynamic-layout/hooks/useDynamicLayout';

interface ColumnOptionsMenuButtonProps extends ISxProps {
}

const fieldNames = [
  'name',
  'versionNumber',
  'fileType',
  'status',
  'uploadDateParsed',
  'disciplineMetaDataIds',
  'buildingMetaDataId',
  'floorMetaId',
  'workPhaseId',
  'linkedIssueIds',
  'editDateParsed',
  'creator',
  'editor',
  'commentIds',
  'creationDateParsed',
  'fileSize',
  'tagIds',
  'folderId',
];

export default function ColumnOptionsButtonMenu({
  sx,
}: ColumnOptionsMenuButtonProps) {
  const { t } = useTranslation('documents');
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const btnRef = useRef<HTMLButtonElement>(null);
  const {
    columnWidthsByColumnName: columnWidthByFieldName, columnNamesForcedHidden: fieldNamesForcedHidden, columnNamesForcedVisible: fieldNamesForcedVisible, persistAsync, isLoadingMutation, isLoadingQuery,
  } = useDynamicLayout(DynamicLayoutKey.DEFAULT_DOCS_LAYOUT);
  const isLoading = useMemo(() => isLoadingMutation || isLoadingQuery, [isLoadingMutation, isLoadingQuery]);
  const [visibleColumnFieldNames, setVisibleColumnFieldNames] = useState<string[] | undefined>(columnWidthByFieldName ? fieldNames.filter((fieldName) => columnWidthByFieldName.has(fieldName)) : undefined);
  const selectionSet = useMemo<Set<string>>(() => new Set<string>(visibleColumnFieldNames), [visibleColumnFieldNames]);

  const toggleColumn = useCallback(async (fieldNameToToggle: string) => {
    if (!columnWidthByFieldName || !visibleColumnFieldNames) return;
    const updatedVisibleColumnFieldNames = columnWidthByFieldName.has(fieldNameToToggle) ? visibleColumnFieldNames.filter((f) => f !== fieldNameToToggle) : [...visibleColumnFieldNames, fieldNameToToggle];
    const updatedColumns = updatedVisibleColumnFieldNames.map((pivotFieldName) => ({ fieldName: pivotFieldName, width: columnWidthByFieldName.get(pivotFieldName) ?? 150 }));
    setVisibleColumnFieldNames(updatedVisibleColumnFieldNames);
    await persistAsync(updatedColumns);
  }, [columnWidthByFieldName, persistAsync, visibleColumnFieldNames]);

  useEffect(() => {
    setVisibleColumnFieldNames(columnWidthByFieldName ? Array.from(columnWidthByFieldName.keys()) : undefined);
  }, [columnWidthByFieldName]);

  return (
    <>
      <Button onClick={() => setIsMenuOpen(true)} sx={{ ...sx, color: theme.palette.text.primary, minWidth: 'unset' }} ref={btnRef} id="ColumnOptionsMenuButton">
        <ListOptionsIcon />
      </Button>
      <Menu
        anchorEl={btnRef.current}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <MenuList
          dense
        >
          {fieldNames.map((fieldName) => (
            <MenuItem key={fieldName} onClick={() => toggleColumn(fieldName)} disabled={isLoading || fieldNamesForcedHidden.has(fieldName) || fieldNamesForcedVisible.has(fieldName)}>
              <Checkbox checked={(selectionSet.has(fieldName) && !fieldNamesForcedHidden.has(fieldName)) || fieldNamesForcedVisible.has(fieldName)} />
              {t(`dms-table-column_${fieldName}`, fieldName)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}
