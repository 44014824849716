import React, { useCallback, useContext, useMemo } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import { Checkbox } from '@mui/material';
import DocumentSelectionContext, { DocumentSelectionContextState } from 'documents/contexts/DocumentSelectionContext';

export default function CheckboxCell({
  data,
}: ICellRendererParams<DocumentsDataGridRow, string>) {
  const documentVersionId = data?.id;
  const {
    selectedDocumentVersionIds, nonDeselectableVersionIds, nonSelectableVersionIds, setSelectedDocumentVersionIds,
  } = useContext<DocumentSelectionContextState>(DocumentSelectionContext);
  const selectedDocumentVersionIdsSet = useMemo(() => new Set(selectedDocumentVersionIds), [selectedDocumentVersionIds]);
  const selected = useMemo(() => !!documentVersionId && selectedDocumentVersionIdsSet.has(documentVersionId) && !nonSelectableVersionIds?.has(documentVersionId), [documentVersionId, selectedDocumentVersionIdsSet, nonSelectableVersionIds]);
  const disabled = useMemo(() => !documentVersionId || nonDeselectableVersionIds?.has(documentVersionId) || nonSelectableVersionIds?.has(documentVersionId), [documentVersionId, nonDeselectableVersionIds, nonSelectableVersionIds]);
  const onClick = useCallback(() => {
    if (!documentVersionId || !nonDeselectableVersionIds || !nonSelectableVersionIds) return;
    if (selected) setSelectedDocumentVersionIds(selectedDocumentVersionIds.filter((id) => id !== documentVersionId));
    else setSelectedDocumentVersionIds([...selectedDocumentVersionIds, documentVersionId]);
  }, [documentVersionId, nonDeselectableVersionIds, nonSelectableVersionIds, selected, selectedDocumentVersionIds, setSelectedDocumentVersionIds]);
  return <Checkbox checked={selected} disabled={disabled} sx={{ ml: 0, mb: '2px' }} onClick={onClick} />;
}
