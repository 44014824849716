import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import CollaboratorRoleDefinitionDto from 'projects/types/CollaboratorRoleDefinitionDto';

export default function useCollaboratorRoleDefinitionDeleteMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { listQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Project); // role definitions are part of the project dto, so we need to invalidate that
  return useMutation<void, unknown, string>(async (id: string) => {
    await axiosInstance.delete<CollaboratorRoleDefinitionDto>(`${ApiEndpoint.RoleDefinitions}/${id}`);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(listQueryKey);
    },
  });
}
