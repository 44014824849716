import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import UserGroupDto from 'users-groups/types/UserGroupDto';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import { useCallback } from 'react';

export default function useUserGroupRestoreMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { baseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.UserGroup);
  const mutationFn = useCallback(async (id: string) => {
    const { data } = await axiosInstance.delete<UserGroupDto>(`${ApiEndpoint.UserGroup}/${id}`);
    return data;
  }, [axiosInstance]);
  const onSettled = useCallback(() => queryClient.invalidateQueries({ queryKey: baseQueryKey }), [baseQueryKey, queryClient]);
  return useMutation<UserGroupDto, unknown, string>({
    mutationFn,
    onSettled,
  });
}
