import React from "react";
import chroma from "chroma-js";

const IssueStatusItem = ({ status, name, onClick }) => {
  if (!status) return null;
  return (
    <div className="issue-status-item">
      <p
        className="item-status"
        style={{
          color: status.color,
          backgroundColor: chroma(status.color).alpha(0.2).css(),
        }}
      >
        {status.label}
      </p>
      {!!name && (
        <p className="item-name text-ellipsis" onClick={onClick}>
          {name}
        </p>
      )}
    </div>
  );
};

export default IssueStatusItem;
