import React from "react";

const Radio = ({ handleChange, label, id, checked, disabled = false }) => {
  return (
    <div className="radio-item" onClick={handleChange}>
      <input
        id={id}
        type="radio"
        onChange={() => {}}
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Radio;
