import React from 'react';
import { useTranslation } from 'react-i18next';

import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import ConfirmModal from '../ConfirmModal';
import Group from './Group';
import Settings from './Settings';

interface EncodingSchemaProps {
  curEncodingName: string;
  encodings: any;
  setEncodings: React.Dispatch<React.SetStateAction<any>>;
  addNewGroup: any;
  visoplanGroups: any;
  dateTypes: any;
  separatorsTypes: any;
  documentStatuses: any;
  categories: any;
  buildings: any;
  floors: any;
  onSubmit: any;
  workphasesType: any;
  handleCheckChange: any;
  tagOptions: any;
  namingSettings: any;
  setApiError: React.Dispatch<React.SetStateAction<any>>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  projectElement: any;
  setIsEnableSave: React.Dispatch<React.SetStateAction<boolean>>;
  getTitle: any;
}

function EncodingSchema({
  curEncodingName,
  encodings,
  setEncodings,
  addNewGroup,
  visoplanGroups,
  dateTypes,
  separatorsTypes,
  documentStatuses,
  categories,
  buildings,
  floors,
  onSubmit,
  workphasesType,
  handleCheckChange,
  tagOptions,
  namingSettings,
  setApiError,
  showModal,
  setShowModal,
  projectElement,
  setIsEnableSave,
  getTitle,
}: EncodingSchemaProps) {
  const { t } = useTranslation();
  const currentUserRole = useCurrentUserRole();

  return (
    <div className="project-encoding-content__schema">
      <div className="project-encoding-content__add-group">
        {curEncodingName === 'settings' && (
          <Settings
            encodings={encodings}
            setEncodings={setEncodings}
            handleCheckChange={handleCheckChange}
            namingSettings={namingSettings}
            setIsEnableSave={setIsEnableSave}
          />
        )}
        {curEncodingName !== 'settings' && (
          <>
            <h3 className="title">{getTitle(curEncodingName)}</h3>
            <button
              id="ProjectEncodingAddGroupButton"
              type="button"
              className="btn btn-setting-item"
              onClick={() => addNewGroup()}
              style={{
                pointerEvents: !currentUserRole?.allowedActions?.has(RoleAction.NamingScheme_Create_Edit)
                  ? 'none'
                  : 'auto',
              }}
              disabled={!currentUserRole?.allowedActions?.has(RoleAction.NamingScheme_Create_Edit)}
            >
              <span>+</span>
              <p className="subtitle">{t('add_group', 'Add Group')}</p>
            </button>
            <button
              id="ProjectEncodingDeleteScheme"
              className="btn btn--primary ml-20"
              name="delete"
              type="button"
              onClick={() => setShowModal(true)}
              disabled={
                !currentUserRole?.allowedActions?.has(RoleAction.NamingScheme_Delete)
                  ? true
                  : !encodings[curEncodingName].groups.length
              }
            >
              {t('delete_scheme', 'Delete Scheme')}
            </button>
          </>
        )}
      </div>
      {curEncodingName !== 'settings'
        && !!encodings[curEncodingName].groups.length && (
        <div className="project-encoding--group">
          <div className="column">
            <span className="column__title mb-40">
              {t('group_name', 'Group Name')}
            </span>
            <span className="column__title mb-30">
              {t('example', 'Example')}
            </span>
            <span className="column__title">{t('element', 'Element')}</span>
          </div>
          {curEncodingName === 'plan_encoding' && (
            <Group
              schema={encodings.plan_encoding}
              setGroups={(value: any) => setEncodings({ ...encodings, plan_encoding: value })}
              visoplanGroups={visoplanGroups}
              dateTypes={dateTypes}
              // @ts-ignore
              addNewGroup={addNewGroup}
              separatorsTypes={separatorsTypes}
              documentStatuses={documentStatuses}
              categories={categories}
              buildings={buildings}
              floors={floors}
              workphasesType={workphasesType}
              setShowModal={setShowModal}
              showModal={showModal}
              tagOptions={tagOptions}
              setApiError={setApiError}
              projectElement={projectElement}
              setIsEnableSave={setIsEnableSave}
            />
          )}
          {curEncodingName === 'document_encoding' && (
            <Group
              schema={encodings.document_encoding}
              setGroups={(value: any) => setEncodings({ ...encodings, document_encoding: value })}
              visoplanGroups={visoplanGroups}
              dateTypes={dateTypes}
              // @ts-ignore
              addNewGroup={addNewGroup}
              separatorsTypes={separatorsTypes}
              documentStatuses={documentStatuses}
              categories={categories}
              buildings={buildings}
              floors={floors}
              workphasesType={workphasesType}
              setShowModal={setShowModal}
              showModal={showModal}
              tagOptions={tagOptions}
              setApiError={setApiError}
              projectElement={projectElement}
              setIsEnableSave={setIsEnableSave}
            />
          )}
          {curEncodingName === 'model_encoding' && (
            <Group
              schema={encodings.model_encoding}
              setGroups={(value: any) => setEncodings({ ...encodings, model_encoding: value })}
              visoplanGroups={visoplanGroups}
              dateTypes={dateTypes}
              // @ts-ignore
              addNewGroup={addNewGroup}
              separatorsTypes={separatorsTypes}
              documentStatuses={documentStatuses}
              categories={categories}
              buildings={buildings}
              floors={floors}
              workphasesType={workphasesType}
              setShowModal={setShowModal}
              showModal={showModal}
              tagOptions={tagOptions}
              setApiError={setApiError}
              projectElement={projectElement}
              setIsEnableSave={setIsEnableSave}
            />
          )}
          {curEncodingName === 'custom_encoding' && (
            <Group
              schema={encodings.custom_encoding}
              setGroups={(value: any) => setEncodings({ ...encodings, custom_encoding: value })}
              visoplanGroups={visoplanGroups}
              dateTypes={dateTypes}
              // @ts-ignore
              addNewGroup={addNewGroup}
              separatorsTypes={separatorsTypes}
              documentStatuses={documentStatuses}
              categories={categories}
              buildings={buildings}
              floors={floors}
              workphasesType={workphasesType}
              setShowModal={setShowModal}
              showModal={showModal}
              tagOptions={tagOptions}
              setApiError={setApiError}
              projectElement={projectElement}
              setIsEnableSave={setIsEnableSave}
            />
          )}
        </div>
      )}
      {!!showModal && (
        // @ts-ignore
        <ConfirmModal
          title={`${t('delete')} ${t('scheme')}`}
          confirmText={t(
            'confirm_delete_scheme',
            'Are you sure you want to delete this scheme?',
          )}
          onYes={onSubmit}
          onNo={() => setShowModal(false)}
          yesText={t('yes', 'Yes')}
          noText={t('no', 'No')}
          buttonId="delete"
        />
      )}
    </div>
  );
}

export default EncodingSchema;
