import { useMemo } from 'react';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';

export default function useResourcesQueryOptions() {
  const {
    listsByIdQueryKey: queryKeyBase,
    getListsByIdsQueryKey: getQueryKey,
  } = useDefaultEntityQueryKeys(ApiEndpoint.Resource);
  return useMemo(() => ({
    queryKeyBase,
    getQueryKey,
  }), [getQueryKey, queryKeyBase]);
}
