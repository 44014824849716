import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-locize-backend';
// @ts-ignore because there is no @types/locize npm package
import { locizePlugin } from 'locize';
import 'moment/locale/de';

// TODO: remove TimeAgo, we want to stick to a single date time library (moment.js)
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import de from 'javascript-time-ago/locale/de';

TimeAgo.addDefaultLocale(de);
TimeAgo.addLocale(en);

i18n
  .use(locizePlugin)
  .use(Backend)
  .use(initReactI18next)
  .init({
    // debug: true,
    ns: [
      'activity',
      'chat',
      'common',
      'docFields',
      'documents',
      'emails',
      'issueFields',
      'main_menu',
      'pdfPreview',
      'roleAction',
      'settings',
      'share',
      'upload',
      'timeline',
    ],
    defaultNS: 'common',
    lng: 'de-DE',
    fallbackLng: 'en-US',
    backend: {
      projectId: 'ef780b50-0e8f-4601-88f3-41b9c91cf34e',
      referenceLng: 'en-US',
      version: 'latest',
    },
    react: {
      bindI18n: 'loaded languageChanged',
      useSuspense: false,
    },
    keySeparator: false, // we do not use keys in form messages.
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnNull: false,
  });

export default i18n;
