enum ApiEndpoint {
  Project = 'project',
  ProjectCopy = 'project/copy',
  ProjectRoles = 'project/roles',
  ProjectAuth = 'project/auth',
  ProjectName = 'project/name',
  Issue = 'issue',
  Comment = 'comment',
  Model = 'model',
  Document = 'document',
  Activity = 'activity',
  DocumentVersion = 'documentVersion',
  DocumentVersionDisciplines = 'documentVersion/disciplines',
  DocumentVersionBuilding = 'documentVersion/building',
  DocumentVersionFloor = 'documentVersion/floor',
  DocumentVersionStatus = 'documentVersion/status',
  DocumentVersionTags = 'documentVersion/tags',
  DocumentVersionWorkPhase = 'documentVersion/workphase',
  Collaborators = 'collaborators',
  DisciplineMetaData = 'metaData/discipline',
  BuildingMetaData = 'metaData/building',
  FloorMetaData = 'metaData/floor',
  IssueLog = 'issue/issuelog',
  FolderTree = 'folderTree',
  Folder = 'folder',
  Process = 'process',
  QueryFilter = 'query-filter',
  Tag = 'tag',
  DocumentList = 'document-list',
  DocumentStatus = 'document-status',
  IssueType = 'issue-type',
  IssueStatus = 'issue-status',
  IssuePriority = 'issue-priority',
  InboxEmail = 'inbox-email',
  DynamicLayout = 'dynamic-layout',
  PropertyList = 'property-list',
  SmartView = 'smartview',
  WorkPhase = 'workPhase',
  DocumentComment = 'documentComment',
  RoleDefinitions = 'roleDefinitions',
  UserGroup = 'usergroup',
  CollaboratorRoleMap = 'CollaboratorRoleMap',
  EmailSettings = 'EmailSettings',
  CopyRoleDefinitions = 'copyRoleDefinitions',
  DefaultRoleDefinitions = 'defaultRoleDefinitions',
  Label = 'label',
  Resource = 'resource',
  PdfAnnotation = 'pdf-annotation',
  User = 'user',
  Inbox = 'inbox',
  Totp = 'totp',
  ResetPassword = 'reset-password',
}

export default ApiEndpoint;
