import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import UpdateManyDocumentVersionsDto from 'documents/types/UpdateManyDocumentVersionsDto';
import useCachedEntityListPartialReplace from 'api/hooks/useCachedEntityListPartialReplace';
import DocumentVersionsRowRangeData from 'documents/types/DocumentVersionsRowRangeData';

export default function useDocumentVersionMassEditMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { listQueryKey: listsQueryKey, listsByIdQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const { baseQueryKey: folderBaseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Folder);
  const partiallyUpdateCachedEntityList = useCachedEntityListPartialReplace();
  return useMutation<boolean | undefined, unknown, UpdateManyDocumentVersionsDto>(
    async (updateDto: UpdateManyDocumentVersionsDto) => {
      if (!updateDto.ids?.length) return false;
      const { data: documentVersionDtos } = await axiosInstance.patch<DocumentVersionDto[]>(`${ApiEndpoint.DocumentVersion}/many`, updateDto);
      if (!documentVersionDtos?.length) return false;
      // optimistic update
      // update id entpoint cache
      queryClient.setQueriesData<DocumentVersionDto[] | undefined>({ queryKey: listsByIdQueryKey }, ((cachedData: DocumentVersionDto[] | undefined) => {
        if (!cachedData) return undefined;
        return partiallyUpdateCachedEntityList(cachedData, documentVersionDtos);
      }));
      // update row range (data grid) cache
      queryClient.setQueriesData<DocumentVersionsRowRangeData | undefined>({ queryKey: [...listsQueryKey, 'rowRange'] }, ((cachedData: DocumentVersionsRowRangeData | undefined) => {
        if (!cachedData) return undefined;
        return {
          ...cachedData,
          entities: partiallyUpdateCachedEntityList(cachedData.entities, documentVersionDtos),
        };
      }));
      return true;
    },
    {
      onSuccess(data, dto) {
        if (dto.folderId) {
          queryClient.invalidateQueries(folderBaseQueryKey);
        }
      },
    },
  );
}
