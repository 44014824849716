import React, { useCallback, useMemo } from 'react';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { mdiCancel, mdiCheck, mdiFileDocumentEdit } from '@mdi/js';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import usePdfAnnotationCreateMutation from 'documents-annotations/hooks/usePdfAnnotationCreateMutation';
import useDocumentScopeContext from 'documents/hooks/useDocumentScopeContext';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import ISxProps from 'common/types/ISxProps';
import Icon from '@mdi/react';

interface IssueManageAnnotationsPanelProps extends ISxProps {
}

export default function IssueManageAnnotationsPanel({
  sx,
}: IssueManageAnnotationsPanelProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { documentVersionId, issueId, setIssueId } = useDocumentScopeContext();
  const { webViewerInstance, setIssueIdCurrentlyLinking, issueIdCurrentlyLinking, setSelectedAnnotationNames, annotationItems } = useDocumentViewerContext();
  const { data: currentUser } = useCurrentUserQuery();
  const currentUserRole = useCurrentUserRole();
  const { data: issue } = useIssueQuery(issueId);
  const { mutateAsync } = usePdfAnnotationCreateMutation();

  const issueAnnotations = useMemo(() => annotationItems?.filter((a) => a.visoplanAnnotation?.linkedIssueId && a.visoplanAnnotation.linkedIssueId === issueId), [annotationItems, issueId]);

  const canEditIssue = useMemo(() => {
    if (!currentUserRole || !currentUser || !issue) return undefined;
    if (currentUserRole.allowedActions.has(RoleAction.IssueManagement_Editing)) return true;
    if (issue.createAuthor.id === currentUser.id && currentUserRole.allowedActions.has(RoleAction.IssueManagement_Creator_Editing)) return true;
    if (issue.assignedUserIds.some((id) => id === currentUser.id) && currentUserRole.allowedActions.has(RoleAction.IssueManagement_Assigned_Editing)) return true;
    return false;
  }, [currentUser, currentUserRole, issue]);

  const onClickSelectAnnotations = useCallback(() => {
    if (!issueAnnotations) return;
    setSelectedAnnotationNames(issueAnnotations.map((a) => a.viewerAnnotation.Id));
  }, [issueAnnotations, setSelectedAnnotationNames]);

  const onClickAddAnnotations = useCallback(() => {
    setIssueIdCurrentlyLinking(issueId);
  }, [issueId, setIssueIdCurrentlyLinking]);

  const onClickDiscard = useCallback(() => {
    if (!webViewerInstance) return;
    const annotations = webViewerInstance.Core.annotationManager.getAnnotationsList().filter((a) => a.getCustomData('annotationKind') === 'temp');
    webViewerInstance.Core.annotationManager.deleteAnnotations(annotations);
    setIssueIdCurrentlyLinking(undefined);
  }, [webViewerInstance, setIssueIdCurrentlyLinking]);

  const onClickConfirm = useCallback(async () => {
    if (!webViewerInstance) return;
    const annotations = webViewerInstance.Core.annotationManager.getAnnotationsList().filter((a) => a.getCustomData('annotationKind') === 'temp');
    annotations.forEach((a) => {
      a.deleteCustomData('annotationKind');
    });
    const annotationNames = annotations.map((a) => a.Id);
    const xfdfString = await webViewerInstance.Core.annotationManager.exportAnnotations({ annotationList: annotations });
    webViewerInstance.Core.annotationManager.deleteAnnotations(annotations);
    if (!documentVersionId) throw new Error('documentVersionId missing');
    if (!issueIdCurrentlyLinking) return;
    setIssueIdCurrentlyLinking(undefined);
    await mutateAsync({
      documentVersionId,
      linkedIssueId: issueIdCurrentlyLinking,
      XFDFString: xfdfString,
    });
    setIssueId(issueIdCurrentlyLinking);
    setSelectedAnnotationNames((prev) => (isEqual(prev, annotationNames) ? prev : annotationNames));
  }, [webViewerInstance, documentVersionId, issueIdCurrentlyLinking, setIssueIdCurrentlyLinking, mutateAsync, setIssueId, setSelectedAnnotationNames]);

  return (
    <Box id="IssueManageAnnotationsPanel" sx={{ ...sx }}>
      {!!canEditIssue && !issueIdCurrentlyLinking && (
        <>
          <Divider />
          <Box sx={{ px: 2, pb: 2, pt: 2, display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
            <Typography sx={{ mr: 1, fontSize: 14, fontWeight: 500 }}>{t('issue-manage-annotations-panel_location-count-label', '{{count}} Locations', { count: issueAnnotations?.length })}</Typography>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Button variant="outlined" onClick={onClickSelectAnnotations} size="small" disabled={!issueAnnotations?.length}>
                {t('issue-manage-annotations-panel_select-locations-button-label', 'Select')}
              </Button>
              <Button variant="contained" onClick={onClickAddAnnotations} size="small" sx={{ gap: 0.5, pl: 1 }} disabled={!issueAnnotations}>
                <Icon path={mdiFileDocumentEdit} size={0.75} color={theme.palette.primary.contrastText} />
                {t('issue-manage-annotations-panel_add-locations-button-label', 'Add location')}
              </Button>
            </Box>
          </Box>
          <Divider />
        </>
      )}
      {!!issueIdCurrentlyLinking && (
      <Box sx={{ p: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2, backgroundColor: theme.palette.info.main }}>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Icon path={mdiFileDocumentEdit} size={1} color={theme.palette.primary.contrastText} />
          <Typography sx={{ mr: 'auto', color: theme.palette.primary.contrastText, fontSize: 16, fontWeight: 600 }}>
            {t('issue-manage-annotations-panel_locating-text', 'Add issue location annotations')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button variant="contained" color="primary" onClick={onClickConfirm} sx={{ borderWidth: 1, borderStyle: 'solid', borderColor: theme.palette.primary.light, pl: 1, gap: 0.5 }}>
            <Icon path={mdiCheck} size={0.8} />
            {t('issue-manage-annotations-panel_confirm-button-label', 'Confirm')}
          </Button>
          <Button variant="contained" color="secondary" onClick={onClickDiscard} sx={{ ml: 'auto', pl: 1, gap: 0.5 }}>
            <Icon path={mdiCancel} size={0.8} />
            {t('issue-manage-annotations-panel_discard-button-label', 'Discard')}
          </Button>
        </Box>
      </Box>
      )}
    </Box>
  );
}
