import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { sprintf } from "sprintf-js";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import saveAs from "save-as";

import * as Common from "../../Helpers/Common";

import PluginItem from "./PluginItem";
import Loader from "../Loader";
import { CloseButton } from "../Buttons";

const useLink = (selectedItems) => {
  const [downloadItems, setDownloadItems] = useState([]);
  const config = getConfig();

  useEffect(() => {
    const items = [];
    let prefix = config.environment;

    for (let title in selectedItems) {
      if (title !== "Revit" && title !== "ArchiCAD" && title !== "Navisworks") {
        if (selectedItems[title]) {
          let link = "";
          Common.downloadData.forEach((item) => {
            if (item.title === title) link = sprintf(item.link, prefix);
            if (item.versions) {
              item.versions.forEach((itemVersion) => {
                if (itemVersion.title === title)
                  link = sprintf(itemVersion.link, prefix);
              });
            }
          });
          const links = link.split("/");
          const downloadItem = {
            link,
            fileName: links[links.length - 1],
          };
          items.push(downloadItem);
        }
      }
    }
    setDownloadItems(items);
  }, [selectedItems, location]);

  return { downloadItems };
};

const DownloadPlugin = ({ isLogginedPreview, onClose }) => {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState({});
  const [isEnableDownload, setIsEnableDownload] = useState(false);
  const { downloadItems } = useLink(selectedItems);
  const [loading, setLoading] = useState(false);

  const onDownload = useCallback(() => {
    const zip = new JSZip();
    let count = 0;
    const zipFileName = "download.zip";
    setLoading(true);

    downloadItems.forEach(async (item) => {
      try {
        const file = await JSZipUtils.getBinaryContent(item.link);
        zip.file(item.fileName, file, { binary: true });
        count++;
        if (count === downloadItems.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, zipFileName);
            setLoading(false);
          });
        }
      } catch (err) {
        console.error(err);
      }
    });
  }, [downloadItems]);

  const onItemSelect = useCallback(
    (event, title, isChild) => {
      if (isChild) event.stopPropagation();

      const items = Object.assign({}, selectedItems);
      items[title] = !items[title];
      setSelectedItems(items);
    },
    [selectedItems]
  );

  useEffect(() => {
    let isEnable = false;
    for (let key in selectedItems) {
      if (key !== "Revit" && key !== "ArchiCAD" && key !== "Navisworks") {
        if (selectedItems[key]) isEnable = true;
      }
    }
    setIsEnableDownload(isEnable);
  }, [selectedItems]);

  return (
    <section
      className={`download-plugin-section ${!!isLogginedPreview && "width--100"}`}
    >
      {!!isLogginedPreview && (
        <div>
          {!!onClose && <CloseButton onClick={onClose} />}
          <p className={"download-plugin-section__text-for-select"}>
            {t(
              "please_select_the_files_you_want_to_download",
              "Please select the files you want to download"
            )}
          </p>
        </div>
      )}
      <Loader isLoading={loading} />
      {!isLogginedPreview && (
        <h2>{t("download_area", "Download area for software and plug-ins")}</h2>
      )}
      <div className="plugin-group">
        {Common.downloadData.map((item, index) => (
          <PluginItem
            key={`plugin-item_${index}`}
            item={item}
            onItemSelect={onItemSelect}
            selectedItems={selectedItems}
          />
        ))}
      </div>
      <div className="btn-wrapper">
        <button
          className="btn btn--primary btn-download"
          onClick={onDownload}
          disabled={!isEnableDownload}
        >
          {t("download", "Download")}
          <svg className="icon">
            <use xlinkHref="/img/sprite.svg#download" />
          </svg>
        </button>
      </div>
    </section>
  );
};

export default DownloadPlugin;
