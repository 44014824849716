import React, { useContext, useMemo } from 'react';
import {
  Chip,
  Dialog, DialogContent, DialogTitle, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import RoundIconButton from 'common/components/RoundIconButton';
import CloseIcon from '@mui/icons-material/Close';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useFolderTreeQuery from 'documents-folders/hooks/useFolderTreeQuery';
import DocumentScopeContext, { DocumentScopeContextState } from 'documents/contexts/DocumentScopeContext';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import useLetterNumbering from 'documents/hooks/useLetterNumbering';
import Color from 'color';

interface DocumentVersionDialogProps {
  documentVersionId: string | undefined,
  onClose: () => void,
}

function FolderLink({ folderId }: { folderId: string }) {
  const { t } = useTranslation('documents');
  const { setDocumentScope } = useContext<DocumentScopeContextState>(DocumentScopeContext);
  const { data: folderTreeData } = useFolderTreeQuery();
  const folder = useMemo(() => (folderId ? folderTreeData?.foldersById.get(folderId) : undefined), [folderId, folderTreeData]);
  if (folder) return <Link onClick={() => setDocumentScope({ key: DocumentScopeKey.Folder, id: folderId })}>{folder.name}</Link>;
  return <>{t('document-versions-dialog_no-access-to-folder', 'No access to folder')}</>;
}

export default function DocumentVersionsDialog({
  documentVersionId,
  onClose,
}: DocumentVersionDialogProps) {
  const { t } = useTranslation('documents');
  const theme = useTheme();
  // orderby=versionNumber%20desc
  // orderby=versionNumber%20desc
  const { data: pivotDocumentVersionData, isLoading: isLoadingPivotDocumentVersionData } = useDocumentVersionsQuery(documentVersionId ? { filter: { id: { eq: documentVersionId } } } : undefined);
  const documentId = useMemo(() => pivotDocumentVersionData?.[0]?.documentId, [pivotDocumentVersionData]);
  const { data: documentVersions, isLoading: isLoadingDocumentVersionsData } = useDocumentVersionsQuery(documentId ? { filter: { documentId: { eq: documentId } }, orderBy: 'versionNumber desc' } : undefined);
  const isLoading = useMemo(() => isLoadingPivotDocumentVersionData || isLoadingDocumentVersionsData, [isLoadingDocumentVersionsData, isLoadingPivotDocumentVersionData]);
  const { data: currentProject } = useCurrentProjectQuery();
  const isLetterVersioningEnabled = currentProject?.namingSchemeSettings?.useLetterVersioning;
  const { numberToLetter } = useLetterNumbering();
  return (
    <Dialog open PaperProps={{ sx: { maxWidth: 'unset', minHeight: '600px' } }}>
      <DialogTitle sx={{ display: 'flex' }}>
        {t('document-versions-dialog_title', 'Document Versions')}
        <RoundIconButton Icon={CloseIcon} onClick={onClose} sx={{ ml: 'auto' }} />
      </DialogTitle>
      <DialogContent>
        <TableContainer className="custom-scrollbar" sx={{ width: '800px', overflowX: 'unset' }}>
          {isLoading && <CenteredCircularProgress />}
          {!isLoading && (
          <Table size="small" stickyHeader>
            <TableHead sx={{ fontWeight: 700 }}>
              <TableRow>
                <TableCell>{t('document-versions-dialog_cell-header_document-name', 'Document Name')}</TableCell>
                <TableCell>{t('document-versions-dialog_cell-header_version', 'Version')}</TableCell>
                <TableCell>{t('document-versions-dialog_cell-header_upload-date', 'Upload Date')}</TableCell>
                <TableCell>{t('document-versions-dialog_cell-header_uploaded-by', 'Uploaded By')}</TableCell>
                <TableCell>{t('document-versions-dialog_cell-header_folder', 'Folder')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentVersions?.map((documentVersion) => (
                <TableRow key={documentVersion.id}>
                  <TableCell sx={{ fontWeight: 600, ...(documentVersionId === documentVersion.id ? { backgroundColor: Color(theme.palette.info.main).lightness(94).hex() } : {}) }}>
                    {documentVersion.name}
                  </TableCell>
                  <TableCell sx={documentVersionId === documentVersion.id ? { backgroundColor: Color(theme.palette.info.main).lightness(94).hex() } : {}}>
                    <Chip
                      size="small"
                      color="info"
                      sx={documentVersionId === documentVersion.id ? { backgroundColor: Color(theme.palette.info.main).alpha(0.24).hexa() } : undefined}
                      label={isLetterVersioningEnabled ? numberToLetter(documentVersion.versionNumber) : documentVersion.versionNumber}
                    />
                  </TableCell>
                  <TableCell sx={documentVersionId === documentVersion.id ? { backgroundColor: Color(theme.palette.info.main).lightness(94).hex() } : {}}>
                    {new Date(documentVersion.uploadDate).toLocaleString('de', { dateStyle: 'medium', timeStyle: 'medium' })}
                  </TableCell>
                  <TableCell sx={documentVersionId === documentVersion.id ? { backgroundColor: Color(theme.palette.info.main).lightness(94).hex() } : {}}>
                    {`${documentVersion.creator.firstName} ${documentVersion.creator.lastName}`}
                  </TableCell>
                  <TableCell sx={documentVersionId === documentVersion.id ? { backgroundColor: Color(theme.palette.info.main).lightness(94).hex() } : {}}>
                    {!documentVersion.isArchived ? <FolderLink folderId={documentVersion.folderId} /> : <i>{t('document-versions-dialog_folder-value-archived', 'Archived')}</i>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          )}
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}
