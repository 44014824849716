import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { uniqBy } from "lodash";

import FilterSection from "../Timeline/FilterSection";
import SearchInput from "../SearchInput";
import Loader from "../Loader";
import { Table } from "../../emails/Table";

import { defaultLinkIssueColumns } from "../../Services/IssueService";
import { getColumnsForIssues } from "../../Helpers/Common";
import { IssueService } from "../../Services";
import RoleAction from 'projects/types/RoleAction';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';

const LinkExistingIssues = ({
  BtnGroup,
  loading: isLoading,
  searchKey,
  modalCount,
  scrollRef,
  columns,
  filterValue,
  isFiltering,
  issueFilterItems,
  filteredIssues,
  setFilteredIssues,
  setShowCreateModal,
  handleFilterChange,
  handleScrollIssuesTable,
  onSearchChange,
  handleClick,
  setIsLinkExistingIssue,
  setIsDocumentLinkedIssue,
  isDocumentLinkedIssue,
  closeModal,
  allIssues,
  apiError = "",
}) => {
  const { t } = useTranslation();
  const currentUserRole = useCurrentUserRole();
  const { tagOptions } = issueFilterItems;
  const [selectedIssueTree, setSelectedIssueTree] = useState([]);

  const columnsLink = useMemo(() => {
    return defaultLinkIssueColumns.map((column) =>
      columns.find((item) => item.value === column.name)
    );
  }, [columns, defaultLinkIssueColumns]);

  const rowEvents = async (e, row) => {
    if (e.target.className.includes("id")) {
      //if folding a main issue
      if (selectedIssueTree.includes(row.id))
        setSelectedIssueTree(selectedIssueTree.filter((i) => i !== row.id));
      //if unfolding a main issue
      else {
        if (!row.childrenIds?.length) return;
        //get the sub issue ids which hasn't been loaded yet
        const missedIds = [];

        row.childrenIds.forEach((id) => {
          if (!filteredIssues.find((issue) => issue.id === id))
            missedIds.push(id);
        });
        if (missedIds.length) {
          const missedIssues = await IssueService.getIssuesByIds(missedIds);
          if (missedIssues?.length)
            setFilteredIssues((prevIssues) =>
              uniqBy([...prevIssues, ...missedIssues], "id")
            );
        }
        setSelectedIssueTree((prevState) => [row.id, ...prevState]);
      }
    }
  };

  return (
    <div id="issues-table" className={"issues-table issue-links"}>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <>
          <div className="header-panel">
            <h2 className="subtitle">{t("link_issues", "Link Issues")}</h2>
            <div>
              <p className="api-error">{apiError}</p>
              {modalCount < 2 && setShowCreateModal && currentUserRole?.allowedActions?.has(RoleAction.IssueManagement_Creation) ? (
                <button
                  id="CreateIssueLinkIssueButton"
                  className="btn btn--primary mobile--hide"
                  onClick={() => setShowCreateModal(true)}
                >
                  {t("create_issue", "Create Issue")}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <SearchInput
            id="SearchIssueLinkIssueInput"
            placeholder={t("search_issue", "Search Issue")}
            value={searchKey}
            onChange={onSearchChange}
          />
          <FilterSection
            id="FilterSectionLinkIssue"
            filterValue={filterValue}
            tagOptions={tagOptions}
            issueFilterItems={issueFilterItems}
            handleFilterChange={handleFilterChange}
            resetFilters={() => {}}
          />
          <div
            className="issue-links-table custom-scrollbar"
            ref={scrollRef}
            onScroll={handleScrollIssuesTable}
          >
            <Table
              isLinkIssueView={true}
              title="issues"
              data={filteredIssues}
              columns={columnsLink}
              columnsWidth={defaultLinkIssueColumns}
              columnsData={getColumnsForIssues()}
              columnsDataHeader={columnsLink}
              isDefault={true}
              issueView={!isFiltering}
              row_events={rowEvents}
              selectedIssueTree={selectedIssueTree}
              setSelectedIssueTree={setSelectedIssueTree}
              allIssues={allIssues}
              statuses={
                filterValue.status
                  ? [filterValue.status]
                  : issueFilterItems.statuses
              }
            />
          </div>
          <BtnGroup
            id="ButtonGroupLinkIssues"
            handleClick={handleClick}
            setIsLinkExistingIssue={setIsLinkExistingIssue}
            setIsDocumentLinkedIssue={setIsDocumentLinkedIssue}
            isDocumentLinkedIssue={isDocumentLinkedIssue}
            closeModal={closeModal}
          />
        </>
      )}
    </div>
  );
};

export default LinkExistingIssues;
