import {
  BufferGeometry,
  Intersection,
  LineBasicMaterial,
  Mesh,
  Vector3,
} from "three";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { IfcComponent } from "../../../base-types";
import { IfcDimensionLine } from "./dimension-line";
import { IfcContext } from "../../context";
import { LiteEvent } from "../../../utils/LiteEvent";

export class IfcDimensions extends IfcComponent {
  public readonly onClosed = new LiteEvent<IfcDimensionLine>();

  private readonly context: IfcContext;
  private dimensions: IfcDimensionLine[] = [];
  private currentDimension?: IfcDimensionLine;
  private readonly previewClassName = "viewer-dimension_preview";
  private total = 0;

  // State
  private enabled = false;
  private preview = false;
  private dragging = false;
  snapDistance = 0.25;

  // Geometries
  private readonly previewElement: CSS2DObject;

  // Materials
  private lineMaterial = new LineBasicMaterial({
    color: "#4B6FFC",
    linewidth: 2,
    depthTest: false,
  });

  constructor(context: IfcContext) {
    super(context);
    this.context = context;
    const htmlPreview = document.createElement("div");
    htmlPreview.className = this.previewClassName;
    this.previewElement = new CSS2DObject(htmlPreview);
    this.previewElement.visible = false;
  }

  update(_delta: number) {
    if (this.enabled && this.preview) {
      const intersects = this.context.castRayIfc();
      this.previewElement.visible = !!intersects;
      if (!intersects) return;
      this.previewElement.visible = true;
      const closest = this.getClosestVertex(intersects);
      this.previewElement.visible = !!closest;
      if (!closest) return;
      this.previewElement.position.set(closest.x, closest.y, closest.z);
      if (this.dragging) {
        this.drawInProcess();
      }
    }
  }

  get active() {
    return this.enabled;
  }

  get previewActive() {
    return this.preview;
  }

  get previewObject() {
    return this.previewElement;
  }

  set previewActive(state: boolean) {
    this.preview = state;
    const scene = this.context.getScene();
    if (this.preview) {
      scene.add(this.previewElement);
    } else {
      scene.remove(this.previewElement);
    }
  }

  set active(state: boolean) {
    this.enabled = state;
    this.dimensions.forEach((dim) => {
      dim.visibility = state;
    });
  }

  set dimensionsWidth(width: number) {
    this.lineMaterial.linewidth = width;
  }

  create() {
    if (!this.enabled) return;
    this.drawStart();
  }

  deleteAll() {
    this.dimensions.forEach((dim) => {
      dim.removeFromScene();
    });

    this.total = 0;
    this.dimensions = [];
  }

  drawEnd() {
    if (!this.currentDimension) return;
    this.dragging = false;
    this.currentDimension?.removeLastPoint();
    if (this.currentDimension?.pointCount > 1) {
      this.dimensions.push(this.currentDimension);
      this.total += this.currentDimension?.pointCount;
    } else {
      this.currentDimension?.removeFromScene();
    }

    this.currentDimension = undefined;
    if (this.dimensions.length) {
      return this.dimensions[this.dimensions.length - 1];
    }
  }

  selectDimension(label: string | undefined) {
    this.dimensions.forEach((dim) => {
      dim.isSelected = dim.dimensionLabel == label;
    });
  }

  deleteDimension(dimension: IfcDimensionLine) {
    if (!dimension) return;
    const index = this.dimensions.indexOf(dimension);
    this.dimensions.splice(index, 1);
    dimension.removeFromScene();
  }

  private getPoint() {
    const intersects = this.context.castRayIfc();
    if (!intersects) return;
    return this.getClosestVertex(intersects);
  }

  private drawStart() {
    const point = this.getPoint();
    if (!!point) {
      this.dragging = true;
      if (!this.currentDimension) {
        this.currentDimension = this.drawDimension();
        this.currentDimension.onClosed.on(() => this.handleDimensionClosed());
      }

      this.currentDimension?.addPoint(point);
    }
  }

  private handleDimensionClosed() {
    if (!this.currentDimension) return;

    this.dragging = false;

    this.dimensions.push(this.currentDimension);
    this.total += this.currentDimension?.pointCount;

    this.currentDimension = undefined;

    this.onClosed.trigger(this.dimensions[this.dimensions.length - 1]);
  }

  private drawInProcess() {
    if (!this.currentDimension) return;
    const endpoint = this.getPoint();
    if (!!endpoint) {
      this.currentDimension.endPoint = endpoint;
    }
  }

  private drawDimension() {
    return new IfcDimensionLine(
      this.context,
      this.lineMaterial.clone(),
      this.total
    );
  }

  private getClosestVertex(intersects: Intersection) {
    let closestVertex = new Vector3();
    let vertexFound = false;
    let closestDistance = Number.MAX_SAFE_INTEGER;
    const vertices = this.getVertices(intersects);
    if (!!this.currentDimension)
      vertices.push(this.currentDimension.getStartPoint());
    for (let i = 0, n = vertices.length; i < n; i++) {
      const vertex = vertices[i];
      if (!vertex) continue;
      const distance = intersects.point.distanceTo(vertex);
      if (distance > closestDistance || distance > this.snapDistance) continue;
      vertexFound = true;
      closestVertex = vertex;
      closestDistance = intersects.point.distanceTo(vertex);
    }

    return vertexFound ? closestVertex : intersects.point;
  }

  private getVertices(intersects: Intersection) {
    const mesh = intersects.object as Mesh;
    if (!intersects.face || !mesh) return [];
    const geom = mesh.geometry;
    return [
      this.getVertex(intersects.face.a, geom),
      this.getVertex(intersects.face.b, geom),
      this.getVertex(intersects.face.c, geom),
    ];
  }

  private getVertex(index: number, geom: BufferGeometry) {
    if (index === undefined) return null;
    const vertices = geom.attributes.position;
    return new Vector3(
      vertices.getX(index),
      vertices.getY(index),
      vertices.getZ(index)
    );
  }
}
