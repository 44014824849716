import { useCallback, useEffect } from 'react';
import { QueryObserverOptions, useQueryClient } from '@tanstack/react-query';
import { QueryKeyBase, VisoBaseQueryKey } from 'api/hooks/useDefaultEntityQueryKeys';
import useCurrentUserQueryKey from 'users/hooks/useCurrentUserQueryKey';
import useCurrentUserQueryOptions from 'users/hooks/useCurrentUserQueryOptions';
import useIssuesOdataQueryKey from 'issues/hooks/useIssuesOdataQueryKey';
import useIssuesOdataQueryOptions from 'issues/hooks/useIssuesOdataQueryOptions';
import useIssueQueryKey from 'issues/hooks/useIssueQueryKey';
import useIssueQueryOptions from 'issues/hooks/useIssueQueryOptions';
import useLabelsOdataQueryKey from 'labels/hooks/useLabelsOdataQueryKey';
import useLabelsOdataQueryOptions from 'labels/hooks/useLabelsOdataQueryOptions';
import useLabelQueryKey from 'labels/hooks/useLabelQueryKey';
import useLabelQueryOptions from 'labels/hooks/useLabelQueryOptions';
import usePdfAnnotationsQueryKey from 'documents-annotations/hooks/usePdfAnnotationsQueryKey';
import usePdfAnnotationsQueryOptions from 'documents-annotations/hooks/usePdfAnnotationsQueryOptions';
import useResourceQueryOptions from 'resources/hooks/useResourceQueryOptions';
import useResourceQueryKey from 'resources/hooks/useResourceQueryKey';
import useResourcesQueryKey from 'resources/hooks/useResourcesQueryKey';
import useResourcesQueryOptions from 'resources/hooks/useResourcesQueryOptions';
import useSetupTwoFactorAuthQueryKey from 'users/hooks/useSetupTwoFactorAuthQueryKey';
import useSetupTwoFactorAuthQueryOptions from 'users/hooks/useSetupTwoFactorAuthQueryOptions';
import useUserGroupsOdataQueryKey from 'users-groups/hooks/useUserGroupsOdataQueryKey';
import useUserGroupsOdataQueryOptions from 'users-groups/hooks/useUserGroupsOdataQueryOptions';
import useDocumentVersionsOdataQueryKey from 'documents/hooks/useDocumentVersionsOdataQueryKey';
import useDocumentVersionsOdataQueryOptions from 'documents/hooks/useDocumentVersionsOdataQueryOptions';
import useDocumentListsOdataQueryKey from 'documents-lists/hooks/useDocumentListsOdataQueryKey';
import useDocumentListsOdataQueryOptions from 'documents-lists/hooks/useDocumentListsOdataQueryOptions';
import useDocumentListQueryKey from 'documents-lists/hooks/useDocumentListQueryKey';
import useDocumentListQueryOptions from 'documents-lists/hooks/useDocumentListQueryOptions';

type VisoQueryKey<TEndpoint> = Readonly<[...VisoBaseQueryKey<TEndpoint>, ...(string | unknown)[]]>;
type QueryOptions<TEndpoint extends QueryKeyBase, TQueryKey extends VisoQueryKey<TEndpoint>, TFnData, TData = TFnData> = Partial<Omit<QueryObserverOptions<TFnData, unknown, TData, TFnData, Readonly<[...TQueryKey ]>>, 'queryKey'>>;

export default function useVisoplanApiQueryDefaults() {
  const queryClient = useQueryClient();

  const genericSetQueryDefaults = useCallback(<
    TEndpoint extends QueryKeyBase,
    TQueryKeyBase extends VisoQueryKey<TEndpoint>,
    TQueryKey extends VisoQueryKey<TEndpoint>,
    TFnData,
    TData = TFnData,
  > (
      queryKey: TQueryKeyBase,
      options: QueryOptions<TEndpoint, TQueryKey, TData, TFnData>,
    ) => {
    // Tanstack Query has inconsistencies with custom query key typings so we use this helper for a type-safe way to ignore errors resulting from this inconsistency
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    queryClient.setQueryDefaults(queryKey, options);
  }, [queryClient]);

  // user/current
  const currentUserQueryKey = useCurrentUserQueryKey();
  const currentUserQueryOptions = useCurrentUserQueryOptions();
  useEffect(() => {
    genericSetQueryDefaults(currentUserQueryKey, currentUserQueryOptions);
  }, [queryClient, currentUserQueryOptions, currentUserQueryKey, genericSetQueryDefaults]);

  // issues (ODATA)
  const { queryKeyBase: issuesQueryKeyBase } = useIssuesOdataQueryKey();
  const issuesOdataQueryOptions = useIssuesOdataQueryOptions();
  useEffect(() => {
    genericSetQueryDefaults(issuesQueryKeyBase, issuesOdataQueryOptions);
  }, [issuesOdataQueryOptions, issuesQueryKeyBase, genericSetQueryDefaults]);

  // issue (Details)
  const { queryKeyBase: issueQueryKeyBase } = useIssueQueryKey();
  const issueQueryOptions = useIssueQueryOptions();
  useEffect(() => {
    genericSetQueryDefaults(issueQueryKeyBase, issueQueryOptions);
  }, [issueQueryOptions, issueQueryKeyBase, genericSetQueryDefaults]);

  // labels (ODATA)
  const { queryKeyBase: labelsQueryKeyBase } = useLabelsOdataQueryKey();
  const labelsOdataQueryOptions = useLabelsOdataQueryOptions();
  useEffect(() => {
    genericSetQueryDefaults(labelsQueryKeyBase, labelsOdataQueryOptions);
  }, [labelsOdataQueryOptions, labelsQueryKeyBase, genericSetQueryDefaults]);

  // label (Details)
  const { queryKeyBase: labelQueryKeyBase } = useLabelQueryKey();
  const labelQueryOptions = useLabelQueryOptions();
  useEffect(() => {
    genericSetQueryDefaults(labelQueryKeyBase, labelQueryOptions);
  }, [labelQueryKeyBase, labelQueryOptions, genericSetQueryDefaults]);

  // pdf-annotations
  const { queryKeyBase: pdfAnnotationsQueryKeyBase } = usePdfAnnotationsQueryKey();
  const pdfAnnotationsQueryOptions = usePdfAnnotationsQueryOptions();
  useEffect(() => {
    genericSetQueryDefaults(pdfAnnotationsQueryKeyBase, pdfAnnotationsQueryOptions);
  }, [pdfAnnotationsQueryKeyBase, pdfAnnotationsQueryOptions, genericSetQueryDefaults]);

  // resource (single)
  const { queryKeyBase: resourceQueryKeyBase } = useResourceQueryKey();
  const resourceQueryOptions = useResourceQueryOptions();
  useEffect(() => {
    genericSetQueryDefaults(resourceQueryKeyBase, resourceQueryOptions);
  }, [resourceQueryKeyBase, resourceQueryOptions, genericSetQueryDefaults]);

  // resources (list)
  const { queryKeyBase: resourcesQueryKeyBase } = useResourcesQueryKey();
  const resourcesQueryOptions = useResourcesQueryOptions();
  useEffect(() => {
    genericSetQueryDefaults(resourcesQueryKeyBase, resourcesQueryOptions);
  }, [resourcesQueryKeyBase, resourcesQueryOptions, genericSetQueryDefaults]);

  // TOTP setup
  const setupTwoFactorAuthQueryKey = useSetupTwoFactorAuthQueryKey();
  const setupTwoFactorAuthQueryOptions = useSetupTwoFactorAuthQueryOptions();
  useEffect(() => {
    genericSetQueryDefaults(setupTwoFactorAuthQueryKey, setupTwoFactorAuthQueryOptions);
  }, [setupTwoFactorAuthQueryKey, setupTwoFactorAuthQueryOptions, genericSetQueryDefaults]);

  // user-groups (ODATA)
  const { queryKeyBase: userGroupsQueryKeyBase } = useUserGroupsOdataQueryKey();
  const userGroupsOdataQueryOptions = useUserGroupsOdataQueryOptions();
  useEffect(() => {
    genericSetQueryDefaults(userGroupsQueryKeyBase, userGroupsOdataQueryOptions);
  }, [userGroupsOdataQueryOptions, userGroupsQueryKeyBase, genericSetQueryDefaults]);

  // document-versions (ODATA)
  const { queryKeyBase: documentVersionsQueryKeyBase } = useDocumentVersionsOdataQueryKey();
  const documentVersionsOdataQueryOptions = useDocumentVersionsOdataQueryOptions();
  useEffect(() => {
    genericSetQueryDefaults(documentVersionsQueryKeyBase, documentVersionsOdataQueryOptions);
  }, [documentVersionsOdataQueryOptions, documentVersionsQueryKeyBase, genericSetQueryDefaults]);

  // document lists (ODATA)
  const { queryKeyBase: documentListsOdataQueryKeyBase } = useDocumentListsOdataQueryKey();
  const documentListsOdataQueryOptions = useDocumentListsOdataQueryOptions();
  useEffect(() => {
    genericSetQueryDefaults(documentListsOdataQueryKeyBase, documentListsOdataQueryOptions);
  }, [documentListsOdataQueryOptions, documentListsOdataQueryKeyBase, genericSetQueryDefaults]);

  // document lists (Details)
  const { queryKeyBase: documentListQueryKeyBase } = useDocumentListQueryKey();
  const documentListQueryOptions = useDocumentListQueryOptions();
  useEffect(() => {
    genericSetQueryDefaults(documentListQueryKeyBase, documentListQueryOptions);
  }, [documentListQueryOptions, documentListQueryKeyBase, genericSetQueryDefaults]);
}
