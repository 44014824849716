import { useMemo } from 'react';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import LabelDto from 'labels/types/LabelDto';

export function draftIssueStatusFilterPredicate(issueStatus: LabelDto) {
  return issueStatus.originalName === 'Draft' || issueStatus.name === 'Entwurf';
}

export default function useDraftIssueStatus() {
  const { data: issueStatuses } = useProjectIssueStatusesQuery();
  return useMemo(() => issueStatuses?.find(draftIssueStatusFilterPredicate), [issueStatuses]);
}
