import { useTranslation } from 'react-i18next';
import ShareLinkMessage from 'share/types/ShareLinkMessage';
import ShareLinkMessageEndpoint from 'share/types/ShareLinkMessageEndpoint';
import ShareDocumentsRequest from 'share/types/ShareDocumentsRequest';
import useAxiosInstance from 'api/hooks/useAxiosInstance';

export default function useDocumentsShareLinkApi() {
  const { i18n } = useTranslation();
  const axiosInstance = useAxiosInstance();

  const sendShareLinkMessage = async (
    shareLinkMessage: ShareLinkMessage,
    documentVersionIds: string[],
    endpointOption: ShareLinkMessageEndpoint,
  ) => {
    const requestPayload: ShareDocumentsRequest = {
      documentVersionIds,
      emails: shareLinkMessage.externalEmailAddresses,
      expirationDateTime: new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * shareLinkMessage.expirationDurationDays).toISOString(),
      emailLanguage: i18n.language === 'en-US' ? 1 : 2,
      comment: shareLinkMessage.comment,
      usersIds: shareLinkMessage.collaboratorIds,
      userGroupIds: shareLinkMessage.userGroupIds,
    };
    const { data: result } = await axiosInstance.post<boolean>(`documents/share/${endpointOption}`, requestPayload);
    return result;
  };

  return { sendShareLinkMessage };
}
