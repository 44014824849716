import React, { useCallback } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useDocumentVersionQuery from 'documents/hooks/useDocumentVersionQuery';
import DocumentsDetailsTabs from 'documents-details/components/DocumentsDetailsTabs';
import { useTranslation } from 'react-i18next';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import useDocumentScopeContext from 'documents/hooks/useDocumentScopeContext';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';
import useDocumentVersionNumberString from 'documents/hooks/useDocumentVersionNumberString';
import FileType from 'documents/types/FileType';

interface DocumentVersionDetailsPanelProps extends ISxProps {
}

export default function DocumentVersionDetailsPanel({
  sx,
}: DocumentVersionDetailsPanelProps) {
  const { t } = useTranslation('documents-details');
  const { documentVersionId, setDocumentVersionId } = useDocumentScopeContext();
  const { data: documentVersion } = useDocumentVersionQuery(documentVersionId);
  const { data: otherDocumentVersions } = useDocumentVersionsQuery(documentVersion ? { filter: { documentId: documentVersion.documentId, isArchived: false }, orderBy: 'uploadDate desc' } : undefined);
  const { setCompareOptions } = useDocumentViewerContext();
  const onClickCompare = useCallback(() => {
    if (!otherDocumentVersions) return;
    const indexOf = otherDocumentVersions?.findIndex((v) => v.id === documentVersionId);
    const compareIndex = (indexOf < otherDocumentVersions.length - 1) ? indexOf + 1 : (indexOf > 0) ? indexOf - 1 : indexOf;
    const compareDocumentVersion = otherDocumentVersions[compareIndex];
    if (!compareDocumentVersion) return;
    setCompareOptions((prev) => ({ enabled: true, compareDocumentVersionId: compareDocumentVersion.id, mode: prev.mode }));
  }, [documentVersionId, otherDocumentVersions, setCompareOptions]);
  const onChangeDocumentVersion = useCallback((event: SelectChangeEvent<string>) => {
    setDocumentVersionId(event.target.value);
  }, [setDocumentVersionId]);
  const getVersionNumberString = useDocumentVersionNumberString();
  if (!documentVersion) return <CenteredCircularProgress />;
  return (
    <Box id="DocumentVersionDetailsPanel" sx={{ height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
      <Box sx={{ ...sx, p: 2, pb: 4, overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box>
          <Typography variant="h4">{documentVersion.originalFileName}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <FormControl sx={{ flexGrow: 1 }}>
              <InputLabel id="document-version-details-panel_version-select-label">
                {t('document-version-details-panel_version-select-label', 'Version')}
              </InputLabel>
              <Select
                id="document-version-details-panel_version-select"
                value={otherDocumentVersions ? documentVersionId : ''}
                onChange={onChangeDocumentVersion}
                label={t('document-version-details-panel_version-select-label', 'Version')}
              >
                {!!otherDocumentVersions && otherDocumentVersions.map((otherDocumentVersion) => (
                  <MenuItem value={otherDocumentVersion.id} key={otherDocumentVersion.id}>
                    {t('document-version-details-panel_version-select-value', 'V. {{versionNumber}} ({{uploadDate}})', { versionNumber: getVersionNumberString(otherDocumentVersion.versionNumber), uploadDate: new Date(otherDocumentVersion.uploadDate).toLocaleString('de-DE') })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {(documentVersion.fileType === FileType.Pdf || documentVersion.fileType === FileType.DWG || (documentVersion.fileType === FileType.Image && !documentVersion.originalFileName.endsWith('.svg'))) && (
              <Button
                variant="contained"
                color="secondary"
                onClick={onClickCompare}
              >
                {t('document-version-details-panel_compare-versions-button-label', 'Compare')}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <DocumentsDetailsTabs />
    </Box>
  );
}
