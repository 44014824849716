import React, { useState, useEffect } from 'react';
import { Buffer } from 'buffer';
import { Link } from 'react-router-dom';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';

interface OpenWindowsClientLinkProps {
  view: string | undefined,
  revisionId?: string,
  modelFileIds?: string[],
  issueId?: string,
  children: JSX.Element | undefined,
}

function OpenWindowsClientLink({
  view,
  revisionId,
  modelFileIds,
  issueId,
  children,
}: OpenWindowsClientLinkProps) {
  const [link, setLink] = useState('');
  const { data: currentProject } = useCurrentProjectQuery();
  const { authUserToken } = useVisoplanApiContext();

  useEffect(() => {
    const parameters = {
      token: authUserToken,
      projectId: currentProject?.id,
      view,
      revisionId,
      modelFileIds,
      issueId,
    };
    const x = Buffer.from(JSON.stringify(parameters)).toString('base64');
    setLink(x);
  }, [currentProject, authUserToken, view, revisionId, modelFileIds, issueId]);

  return (
    <Link to={{ pathname: '/openWindowsClient', hash: link }} target="_blank">
      {children}
    </Link>
  );
}

OpenWindowsClientLink.defaultProps = {
  revisionId: undefined,
  modelFileIds: undefined,
  issueId: undefined,
};

export default OpenWindowsClientLink;
