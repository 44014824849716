import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Draggable } from "react-beautiful-dnd";

import * as Request from "../../Helpers/Request";
import IssueItemContentOld from "./IssueItemContentOld";
import {
  formatIssue,
  getIssuesWithLogViewpoints,
} from "../../Services/IssueService";
import { useSelector } from "react-redux";
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';

const IssueItemOld = ({
  index,
  issue,
  types,
  baseIssuesUrl,
  viewIssue,
  isLinkedIssues,
  isModelIssues,
  setPreviewIssue,
  selectedIssue,
}) => {
  const navigate = useNavigate();
  const { data: disciplines } = useProjectDisciplinesQuery();
  const { data: floors } = useProjectFloorsQuery();
  const { data: buildings } = useProjectBuildingsQuery();
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setImageName] = useState("");

  const issueType = useMemo(
    () => types.find((item) => item.id === issue?.issueType?.id),
    [issue?.issueType, types]
  );

  const getViewpoints = async (viewpoints) => {
    const blob_images = await Promise.all(
      viewpoints
        .filter((item) => !!item?.imageName)
        .map(
          (item) => (
            setImageName(item?.imageName),
            Request.GET(
              `api/resource/${item?.thumbnailImageId || item?.imageId}`
            ).then((result) => result.status !== 200 || result.body)
          )
        )
    );
    const image = blob_images.find((b) => !!b);

    if (!image) return;
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
  };

  useEffect(() => {
    if (issue.viewpoints?.length) {
      getViewpoints(issue.viewpoints);
    }
  }, [issue.viewpoints]);

  const onClickItem = async (e) => {
    const formattedIssue = formatIssue(issue, disciplines, buildings, floors);
    const formattedIssueWithLogViewpoint = await getIssuesWithLogViewpoints([
      formattedIssue,
    ]);

    if (e.detail !== 2) {
      viewIssue(
        selectedIssue?.id !== issue.id ? formattedIssueWithLogViewpoint[0] : ""
      );
      baseIssuesUrl && navigate(`${baseIssuesUrl}${issue.id}`);
    } else if (isLinkedIssues && e.detail === 2) {
      viewIssue(null);
      setPreviewIssue(formattedIssueWithLogViewpoint[0]);
    }
  };

  return (
    <>
      {!isLinkedIssues && !isModelIssues ? (
        <Draggable draggableId={issue.id} index={index}>
          {(provided) => {
            return (
              <div
                className="issue-item"
                onClick={() => {
                  viewIssue(issue);
                  navigate(`${baseIssuesUrl}${issue.id}`);
                }}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <IssueItemContentOld
                  issue={issue}
                  issueType={issueType}
                  imageUrl={imageUrl}
                  imageName={imageName}
                  baseIssuesUrl={baseIssuesUrl}
                  viewIssue={viewIssue}
                />
              </div>
            );
          }}
        </Draggable>
      ) : (
        <div
          className={
            "issue-item" +
            (isLinkedIssues && selectedIssue?.id === issue.id
              ? " show-border"
              : "")
          }
          onClick={onClickItem}
        >
          <IssueItemContentOld
            issue={issue}
            issueType={issueType}
            imageUrl={imageUrl}
            imageName={imageName}
            baseIssuesUrl={baseIssuesUrl}
            viewIssue={viewIssue}
            isModelIssues={isModelIssues}
          />
        </div>
      )}
    </>
  );
};

export default IssueItemOld;
