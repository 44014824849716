import { QueryObserverOptions, WithRequired } from '@tanstack/react-query';
import { useMemo } from 'react';
import UserGroupDto from 'users-groups/types/UserGroupDto';
import useDefaultOdataQueryFn from 'api/hooks/useDefaultOdataQueryFn';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { UserGroupsOdataQueryKey } from 'users-groups/hooks/useUserGroupsOdataQueryKey';

export type UserGroupsOdataQueryQueryFnData = UserGroupDto[] | undefined;
export type UserGroupsOdataQueryOptions = WithRequired<Partial<Omit<QueryObserverOptions<UserGroupsOdataQueryQueryFnData, unknown, UserGroupsOdataQueryQueryFnData, UserGroupsOdataQueryQueryFnData, UserGroupsOdataQueryKey>, 'queryKey'>>, 'queryFn'>;

export default function useUserGroupsOdataQueryOptions() {
  const queryFn = useDefaultOdataQueryFn<UserGroupDto, UserGroupDto, ApiEndpoint.UserGroup>();
  return useMemo<UserGroupsOdataQueryOptions>(() => ({
    queryFn,
  }), [queryFn]);
}
