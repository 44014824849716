import React, { useMemo } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import CollaboratorRoleDefinition from 'projects/types/CollaboratorRoleDefinition';

interface RoleCollaboratorsListPopupProps {
  roleDefinition: CollaboratorRoleDefinition,
  onClose: () => void,
}

export default function RoleCollaboratorsListPopup({
  roleDefinition,
  onClose,
}: RoleCollaboratorsListPopupProps) {
  const { t } = useTranslation('settings');
  const { data: currentProject } = useCurrentProjectQuery();
  const roleCollaborators = useMemo(() => (currentProject ? currentProject.collaborators.filter((c) => currentProject.roleDefinitionsByCollaboratorId.get(c.id)?.id === roleDefinition.id) : undefined), [currentProject, roleDefinition]);

  return (
    <Dialog open>
      <DialogTitle>{t('role-collaborators-list-popup_title', 'Collaborators with the role "{{roleName}}"', { roleName: roleDefinition.name })}</DialogTitle>
      <DialogContent sx={{ minHeight: '84px', width: '420px' }}>
        {roleCollaborators?.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('role-collaborators-list-popup_name-column-header', 'Name')}</TableCell>
                <TableCell>{t('role-collaborators-list-popup_email-column-header', 'E-Mail')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roleCollaborators?.map((c) => (
                <TableRow>
                  <TableCell>{`${c.firstName} ${c.lastName}`}</TableCell>
                  <TableCell>{c.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : <Typography>{t('role-collaborators-list-popup_empty-role-message', 'There are no project collaborators with this role.')}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>{t('role-collaborators-list-popup_close-button-label', 'Close')}</Button>
      </DialogActions>
    </Dialog>
  );
}
