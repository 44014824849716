import {
  useCallback, useMemo, useState,
} from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import RowRange from 'documents/types/RowRange';
import buildQuery, { QueryOptions } from 'odata-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import { range } from 'lodash';
import DocumentVersionsRowRangeData from 'documents/types/DocumentVersionsRowRangeData';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';

export default function useDocumentVersionsRowRangeData(
  filter: Partial<QueryOptions<DocumentVersionDto>> | undefined,
) {
  const projectId = useCurrentProjectId();
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { listQueryKey: listsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const queryKey = useMemo(() => [...listsQueryKey, 'rowRange', { filter, projectId }], [filter, listsQueryKey, projectId]);
  const [subscribedRowRange, setSubscribedRowRange] = useState<RowRange | undefined>(undefined);
  const { getListsByIdsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);

  const queryFn = useCallback(async (rowRange: RowRange | undefined) => {
    if (!rowRange || !filter) return undefined; // viewport range must be defined and must have a non-zero length for this query to make any sense

    // The strategy here is that we request the part of the result-list which is within the viewport range, then take the remaining results
    // from the cache and insert the requested chunk into that large array. Then we put that partially refreshed array back into the cache (by returning it from the query fn).
    // We don't include the viewport range in the query key because we don't want to store the whole result list for each requested viewport range, as that would be a huge waste of RAM.
    // Instead, we manually refetch the query whenever the viewport range changes.

    // We take the (always complete) list of ids from the cache (re-requesting on cache miss) and returned it as is.

    const cachedData = queryClient.getQueryData<DocumentVersionsRowRangeData | undefined>(queryKey, { stale: false });

    // if the viewport range has zero length, return cached list, or if it does not exist yet, initialize the cache by returning an empty one.
    // this happens when we open an empty folder, and we don't want to disable the query here, but act as normal as possible.
    if (rowRange.rowCount === 0) return cachedData ?? { entities: [], ids: [] };

    let resultEntities: (DocumentVersionDto | undefined)[];
    let resultIds: string[];
    if (!cachedData) {
      const idsFilterQueryString = buildQuery<DocumentVersionDto>(filter);
      const idsResponse = await axiosInstance.get<string[]>(`/${ApiEndpoint.DocumentVersion}/ids${idsFilterQueryString}`);
      resultIds = idsResponse.data;
      resultEntities = [];
    } else {
      resultIds = cachedData.ids;
      resultEntities = Array.from(cachedData.entities); // we don't want to mutate the original cached array => copy (or init if not done yet)
    }

    if (resultIds.length === 0) return { entities: [], ids: [] };

    let requestRange = rowRange;
    if (cachedData) {
      // viewport range can be larger than known entity count => cap
      const cappedViewportRangeEndIndex = Math.min(rowRange.firstRowIndex + rowRange.rowCount, resultIds.length);

      // generate separate array with just the viewport range's row indices for easier iteration
      const rowIndices = range(rowRange.firstRowIndex, cappedViewportRangeEndIndex);

      // determine rows within the viewport range that are not in cache
      const nonCachedRowIndices = rowIndices.filter((rowIndex) => !resultEntities[rowIndex]);

      // if every row in viewport range is cached => no request needed, early exit with cached data
      if (nonCachedRowIndices.length === 0) return { entities: resultEntities, ids: resultIds };

      // cut cached rows from the beginning and the end of the viewport range so we make a minimal, single request for all uncached rows
      // in rare cases there may be some cached rows in between, but we accept the "overhead" in order to stick with a single (or no) request per viewport change
      const firstNonCachedRowIndex = nonCachedRowIndices[0];
      const lastNonCachedRowIndex = nonCachedRowIndices[nonCachedRowIndices.length - 1];
      requestRange = { firstRowIndex: firstNonCachedRowIndex, rowCount: lastNonCachedRowIndex - firstNonCachedRowIndex + 1 };
    }
    // fetch entities in viewport range
    const paginatedOdataQueryString = buildQuery<DocumentVersionDto>({ ...filter, top: requestRange.rowCount, skip: requestRange.firstRowIndex });
    const response = await axiosInstance.get<DocumentVersionDto[]>(`/${ApiEndpoint.DocumentVersion}${paginatedOdataQueryString}`);
    response.data.forEach((dto, index) => {
      queryClient.setQueryData(getListsByIdsQueryKey([dto.id]), [dto]);
      resultEntities[index + requestRange.firstRowIndex] = dto;
    });

    const updatedData: DocumentVersionsRowRangeData = {
      entities: resultEntities,
      ids: resultIds,
    };
    return updatedData;
  }, [filter, queryClient, queryKey, axiosInstance, getListsByIdsQueryKey]);

  const subscribedQueryFn = useCallback(() => queryFn(subscribedRowRange), [queryFn, subscribedRowRange]);
  const { data } = useQuery<DocumentVersionsRowRangeData | undefined>(queryKey, subscribedQueryFn, {
    enabled: !!filter && !!subscribedRowRange,
  });

  const requestViewportRangeData = useCallback(async (viewportRange: RowRange) => {
    const rangeData = await queryClient.fetchQuery(queryKey, () => queryFn(viewportRange), { staleTime: 0, cacheTime: 0, isDataEqual: () => false });
    setSubscribedRowRange(viewportRange);
    return rangeData;
  }, [queryClient, queryFn, queryKey]);

  return useMemo(() => ({
    ...data,
    rowRange: subscribedRowRange,
    requestViewportRangeData,
  }), [data, requestViewportRangeData, subscribedRowRange]);
}
