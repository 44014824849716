import React, { useCallback, useMemo, useState } from 'react';
import {
  Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Trans, useTranslation } from 'react-i18next';
import UserGroupListItem from 'settings/components/UserGroupListItem';
import CreateUserGroupDialog from 'settings/components/CreateUserGroupDialog';
import useUserGroupsQuery from 'users-groups/hooks/useUserGroupsQuery';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import { useNavigate } from 'react-router-dom';

export default function UserGroupSettingsTabPane() {
  const { t } = useTranslation('settings');
  const currentUserRole = useCurrentUserRole();
  const { data: userGroups } = useUserGroupsQuery();
  const defaultUserGroups = useMemo(() => userGroups?.filter((g) => g.isDefault && g.name !== 'External'), [userGroups]);
  const customUserGroups = useMemo(() => userGroups?.filter((g) => !g.isDefault), [userGroups]);
  const [isCreateUserGroupDialogOpen, setIsCreateUserGroupDialogOpen] = useState(false);
  const onClickCreateUserGroup = useCallback(() => setIsCreateUserGroupDialogOpen(true), []);
  const onCloseCreateUserGroupDialog = useCallback(() => setIsCreateUserGroupDialogOpen(false), []);
  const [successMessage, setSuccessMessage] = useState<string | undefined>();
  const navigate = useNavigate();
  return (
    <>
      <Box id="UserGroupSettingsTabPane" sx={{ display: 'grid', gridTemplateColumns: '1fr 300px 1fr 360px', columnGap: 4, p: 8 }}>
        <Box sx={{ minWidth: '320px' }}>
          <Typography variant="h2" sx={{ mb: 1, mr: 'auto' }}>{t('user-group-settings_user-groups-title', 'User Groups')}</Typography>
        </Box>
        <Box>
          <Typography variant="h4" sx={{ mb: 2 }}>{t('user-group-settings_default-groups-title', 'Default Groups')}</Typography>
          {defaultUserGroups?.map((userGroup) => (
            <UserGroupListItem
              key={userGroup.id}
              userGroup={userGroup}
              sx={{ mb: 1 }}
            />
          ))}
          <Box sx={{
            display: 'flex', mb: 1, mt: 8, alignItems: 'center',
          }}
          >
            <Typography variant="h4" sx={{ mr: 'auto' }}>{t('user-group-settings_created-groups-title', 'Created Groups')}</Typography>
            {!!currentUserRole?.allowedActions?.has(RoleAction.Project_CreateGroups) && (
              <Button variant="contained" onClick={onClickCreateUserGroup} sx={{ pl: 1 }}>
                <AddIcon sx={{ mr: 1 }} />
                {t('user-group-settings_create-new-user-group-button-caption', 'Create')}
              </Button>
            )}
          </Box>
          <Alert
            severity="info"
            sx={{
              py: 0, px: 1, mb: 1,
            }}
          >
            <Trans
              t={t}
              i18nKey="upload-wizard_validate-success"
              components={{ s: <strong />, l: <Link onClick={() => navigate('../../collaborators')} /> }}
              defaults="<s>Hinweis:</s> Das Zuweisen von Teilnehmern zu Benutzergruppen erfolgt in der <l>Teilnehmer-Ansicht</l>."
            />
          </Alert>
          {customUserGroups?.map((userGroup) => (
            <UserGroupListItem
              key={userGroup.id}
              userGroup={userGroup}
              sx={{ mb: 1 }}
            />
          ))}
        </Box>
      </Box>
      {isCreateUserGroupDialogOpen && <CreateUserGroupDialog onClose={onCloseCreateUserGroupDialog} onSuccess={setSuccessMessage} />}
      {!!successMessage && (
      <Dialog open>
        <DialogTitle>{t('user-group-settings_success-dialog-title', 'Success')}</DialogTitle>
        <DialogContent>
          <Alert severity="success">
            {successMessage}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setSuccessMessage(undefined)}>{t('user-group-settings_success-dialog-close', 'Close')}</Button>
        </DialogActions>
      </Dialog>
      )}
    </>
  );
}
