enum IssuesSortMode {
  EditDateAsc = 'editDate asc',
  EditDateDesc = 'editDate desc',
  IssueNumberAsc = 'issueNumber asc',
  IssueNumberDesc = 'issueNumber desc',
  TitleAsc = 'title asc',
  TitleDesc = 'title desc',
}

export default IssuesSortMode;
