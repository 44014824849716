import React, { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl, FormControlLabel, InputLabel, Radio, RadioGroup, SelectChangeEvent, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FolderSelect from 'documents-folders/components/FolderSelect';
import FolderScope from 'documents-filter/types/FolderScope';
import DocumentFilterState from 'documents-filter/types/DocumentFilterState';
import useDocumentScopeContext from 'documents/hooks/useDocumentScopeContext';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';

interface FoldersFilterSectionProps {
  value: DocumentFilterState,
  onChange: (state: DocumentFilterState) => void,
}

export default function FoldersFilterSection({
  value,
  onChange,
}: FoldersFilterSectionProps) {
  const { t } = useTranslation('documents-filter');
  const { documentScope } = useDocumentScopeContext();
  const [folderScope, setFolderScope] = useState(value.folderId ? FolderScope.Selected : FolderScope.Any);
  const [folderId, setFolderId] = useState<string | undefined>(undefined);
  const [includeSubfolders, setIncludeSubfolders] = useState<boolean>(false);

  const handleChangeIncludeSubfolders = useCallback((nextIncludeSubfolders: boolean) => {
    setIncludeSubfolders(nextIncludeSubfolders);
    if (folderId === undefined) return;
    onChange({ ...value, includeSubfolders: nextIncludeSubfolders });
  }, [folderId, onChange, value]);

  const handleChangeFolderScope = useCallback((scope: FolderScope) => {
    setFolderScope(scope);
    let selectedFolderId = folderId;
    if (folderId === undefined) {
      if (documentScope.key === DocumentScopeKey.Folder) {
        selectedFolderId = documentScope.id; // by default, select currently open folder
        setFolderId(selectedFolderId);
      } else {
        return;
      }
    }
    if (scope === FolderScope.Selected) onChange({ ...value, folderId: selectedFolderId, includeSubfolders });
    else onChange({ ...value, folderId: undefined, includeSubfolders });
  }, [documentScope.id, documentScope.key, folderId, includeSubfolders, onChange, value]);

  const handleChangeFolderId = useCallback((e: SelectChangeEvent<string>) => {
    const id = e.target.value;
    setFolderId(id);
    if (FolderScope.Selected && id) {
      onChange({ ...value, folderId: id, includeSubfolders });
    }
  }, [includeSubfolders, onChange, value]);

  useEffect(() => {
    if (value.folderId !== undefined) setFolderId(value.folderId);
    setFolderScope(value.folderId ? FolderScope.Selected : FolderScope.Any);
  }, [handleChangeIncludeSubfolders, onChange, value, value.folderId]);

  useEffect(() => {
    setFolderScope(value.folderId ? FolderScope.Selected : FolderScope.Any);
    setFolderId(value.folderId);
  }, [value.folderId]);

  useEffect(() => {
    setIncludeSubfolders(!!value.includeSubfolders);
  }, [value.includeSubfolders]);

  return (
    <>
      <Typography id="documents-filter_folders_header" variant="h4" sx={{ mt: 3 }}>{t('filter-drawer_folders_header', 'Folders')}</Typography>
      <FormControl fullWidth sx={{ mt: 1 }}>
        <RadioGroup
          aria-labelledby="documents-filter_folders_header"
          value={folderScope}
          onChange={(e) => handleChangeFolderScope(parseInt(e.target.value, 10) as FolderScope)}
          name="radio-buttons-group"
        >
          <FormControlLabel value={FolderScope.Any} control={<Radio />} label={t('filter-drawer_folders_any', 'All folders')} />
          <FormControlLabel value={FolderScope.Selected} control={<Radio />} label={t('filter-drawer_folders_selected', 'Selected folder')} />
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth sx={{ mt: 1, flexGrow: 1 }}>
        <InputLabel id="documents-filter_folders_folder-label">
          {t('filter-drawer_folders_folder-label', 'Folder')}
        </InputLabel>
        <FolderSelect
          id="documents-filter_folders_folder-select"
          labelId="documents-filter_folders_folder-label"
          value={folderId ?? ''}
          onChange={handleChangeFolderId}
          label={t('filter-drawer_folders_folder-label', 'Folder')}
          disabled={folderScope !== FolderScope.Selected}
        />
      </FormControl>
      <FormControlLabel
        control={(
          <Checkbox
            checked={includeSubfolders}
            onChange={(e) => handleChangeIncludeSubfolders(e.target.checked)}
            disabled={folderScope !== FolderScope.Selected}
          />
        )}
        label={t('filter-drawer_folders_include-subdirectories', 'Include subdirectories')}
      />
    </>
  );
}
