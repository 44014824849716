import React, { Suspense, useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import UploadStatusDisplay from 'upload/components/UploadStatusDisplay';
import ActivityLogDownloadButton from 'projects/components/ActivityLogDownloadButton';
import UserDropdownMenuButton from 'users/components/UserDropdownMenuButton';
import MainHeaderBackButton from 'projects/components/MainHeaderLeaveProjectButton';
import PluginsDownloadButton from 'projects/components/PluginsDownloadButton';

interface MainHeaderProps extends ISxProps {
}

export default function MainHeader({
  sx,
}: MainHeaderProps) {
  const theme = useTheme();
  const { data: currentProject } = useCurrentProjectQuery();
  const currentUserRole = useCurrentUserRole();
  const activityLogAvailable = useMemo(() => currentUserRole?.allowedActions?.has(RoleAction.ActivityLog_Access), [currentUserRole]);
  return (
    <Box
      id="MainHeader"
      sx={{
        ...sx,
        height: 56,
        flexShrink: 0,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        display: 'flex',
        gap: 2,
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
      }}
    >
      {!!currentProject && (
        <>
          <MainHeaderBackButton />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography sx={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.text.primary,
              borderRadius: '4px',
              px: 0.5,
              py: 0.25,
              textTransform: 'uppercase',
              fontWeight: 800,
              fontSize: 15,
            }}
            >
              {currentProject.abbreviation}
            </Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
              {currentProject.name}
            </Typography>
          </Box>
        </>
      )}
      <Box sx={{ ml: 'auto', mr: 2, height: '100%' }}>
        <Suspense fallback={<CenteredCircularProgress />}>
          <UploadStatusDisplay />
        </Suspense>
      </Box>
      <PluginsDownloadButton sx={{ pl: 1 }} />
      {!!currentProject && !!activityLogAvailable && (
        <ActivityLogDownloadButton />
      )}
      <UserDropdownMenuButton sx={{ alignSelf: 'stretch' }} />
    </Box>
  );
}
