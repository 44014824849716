import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QueryOptions } from 'odata-query';
import LabelDto from 'labels/types/LabelDto';
import { useMemo } from 'react';
import useLabelsOdataQueryKey, { LabelsOdataQueryKey } from 'labels/hooks/useLabelsOdataQueryKey';

type LabelsUseQueryOptions = Omit<UseQueryOptions<LabelDto[] | undefined, unknown, LabelDto[] | undefined, LabelsOdataQueryKey | readonly []>, 'queryFn'>;

export default function useLabelsOdataQuery(odataQuery: Partial<QueryOptions<LabelDto>> | undefined, options?: Partial<LabelsUseQueryOptions>) {
  const { getQueryKey } = useLabelsOdataQueryKey();
  const queryKey = useMemo(() => (odataQuery ? getQueryKey(odataQuery) : ([] as const)), [getQueryKey, odataQuery]);
  const queryOptions = useMemo<LabelsUseQueryOptions>(() => ({
    ...options,
    queryKey,
    enabled: (options?.enabled !== false) && (queryKey.length > 0),
  }), [queryKey, options]);
  return useQuery(queryOptions);
}
