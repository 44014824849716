import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import useApiConfig from 'api/hooks/useApiConfig';
import { useEffect, useState } from 'react';
import { licenseKey } from '../CompareFeature';

export default function useWebViewerInstance(viewerElement: HTMLElement | null) {
  const [webViewerInstance, setWebViewerInstance] = useState<WebViewerInstance | null>(null);
  const { pdfTronUrl } = useApiConfig();

  useEffect(() => {
    if (!viewerElement) return;
    WebViewer(
      {
        licenseKey,
        path: '/webviewer',
        enableMeasurement: true,
        webviewerServerURL: pdfTronUrl,
        forceClientSideInit: true,
        fullAPI: true,
      },
      viewerElement,
    ).then(setWebViewerInstance);
  }, [viewerElement, pdfTronUrl]);

  return webViewerInstance;
}
