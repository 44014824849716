import useDefaultEntitiesQuery from 'api/hooks/useDefaultEntitiesQuery';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useCollaboratorWithCompanyEntityFactory from 'collaborators/hooks/useCollaboratorWithCompanyEntityFactory';
import CollaboratorWithCompany from 'collaborators/types/CollaboratorWithCompany';
import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

export default function useCollaboratorsQuery(options?: Omit<UseQueryOptions<CollaboratorWithCompany[] | undefined, unknown, CollaboratorWithCompany[] | undefined, QueryKey>, 'queryKey' | 'queryFn'> | undefined) {
  const entityFactory = useCollaboratorWithCompanyEntityFactory();
  return useDefaultEntitiesQuery(ApiEndpoint.Collaborators, entityFactory, options);
}
