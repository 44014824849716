import React, {
  ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react';
import DocumentScopeContext, { DocumentScopeContextState } from 'documents/contexts/DocumentScopeContext';
import { useNavigate, useParams } from 'react-router-dom';
import DocumentScope from 'documents/types/DocumentScope';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import ArchiveScopeId from 'documents/types/ArchiveScopeId';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import useFolderTreeQuery from 'documents-folders/hooks/useFolderTreeQuery';

interface DocumentFilterContextUrlProviderProps {
  children?: ReactNode,
}

export default function DocumentScopeContextUrlProvider({
  children,
}: DocumentFilterContextUrlProviderProps) {
  const navigate = useNavigate();
  const currentUserRole = useCurrentUserRole();
  const { documentScopeKey, documentScopeId, documentVersionId, issueId } = useParams<'documentScopeKey' | 'documentScopeId' | 'documentVersionId' | 'issueId'>();
  const [highlightedDocumentCommentId, setHighlightedDocumentCommentId] = useState<string | undefined>(undefined);
  const setDocumentScope = useCallback((value: DocumentScope) => {
    if (documentScopeKey !== value.key || value.id !== documentScopeId) {
      if (value.key === DocumentScopeKey.Archive) {
        if (value.id === ArchiveScopeId.Public && currentUserRole?.allowedActions?.has(RoleAction.Document_GetArchived)) {
          navigate(`../${DocumentScopeKey.Archive}/${ArchiveScopeId.Public}`);
        } else {
          navigate(`../${DocumentScopeKey.Archive}/${ArchiveScopeId.Private}`);
        }
      } else if (value.key === DocumentScopeKey.Filter) {
        if (value.id) navigate(`../${DocumentScopeKey.Filter}/${value.id}`);
        else navigate(`../${DocumentScopeKey.Filter}`);
      } else if (value.key === DocumentScopeKey.Planlist) {
        if (value.id) navigate(`../${DocumentScopeKey.Planlist}/${value.id}`);
        else navigate(`../${DocumentScopeKey.Filter}`);
      } else if (value.key === DocumentScopeKey.Folder) {
        if (value.id) navigate(`../${DocumentScopeKey.Folder}/${value.id}`);
        else navigate(`../${DocumentScopeKey.ProjectDocuments}`);
      } else if (value.key === DocumentScopeKey.MyUploads) {
        navigate(`../${DocumentScopeKey.MyUploads}`);
      } else if (value.key === DocumentScopeKey.ProjectDocuments) {
        navigate(`../${DocumentScopeKey.ProjectDocuments}`);
      }
    }
  }, [currentUserRole?.allowedActions, documentScopeId, documentScopeKey, navigate]);

  const documentScope = useMemo<DocumentScope>(() => {
    if (documentScopeKey === DocumentScopeKey.Archive) {
      if (documentScopeId === ArchiveScopeId.Public && currentUserRole?.allowedActions?.has(RoleAction.Document_GetArchived)) {
        return { key: documentScopeKey, id: ArchiveScopeId.Public };
      }
      if (documentScopeId === ArchiveScopeId.Private) {
        return { key: documentScopeKey, id: ArchiveScopeId.Private };
      }
      return { key: documentScopeKey, id: ArchiveScopeId.Private };
    }
    if (documentScopeKey === DocumentScopeKey.Filter) {
      return { key: documentScopeKey, id: documentScopeId };
    }
    if (documentScopeKey === DocumentScopeKey.Folder) {
      return { key: documentScopeKey, id: documentScopeId };
    }
    if (documentScopeKey === DocumentScopeKey.Planlist) {
      return { key: documentScopeKey, id: documentScopeId };
    }
    if (documentScopeKey === DocumentScopeKey.MyUploads) {
      return { key: documentScopeKey };
    }
    if (documentScopeKey === DocumentScopeKey.ProjectDocuments) {
      return { key: documentScopeKey };
    }
    return { key: DocumentScopeKey.ProjectDocuments };
  }, [documentScopeKey, documentScopeId, currentUserRole?.allowedActions]);

  const setDocumentVersionId = useCallback((id: string | undefined) => {
    if (!id) {
      navigate('.');
    } else {
      navigate(`./preview/${id}`);
    }
  }, [navigate]);

  const setIssueId = useCallback((id: string | undefined) => {
    if (!documentVersionId) return;
    if (id) {
      navigate(`./preview/${documentVersionId}/issue/${id}`);
    } else {
      navigate(`./preview/${documentVersionId}`);
    }
  }, [documentVersionId, navigate]);

  const setDocumentVersionAndIssueId = useCallback((nextDocumentVersionId: string | undefined, nextIssueId: string | undefined) => {
    if (nextDocumentVersionId && nextIssueId) {
      navigate(`./preview/${nextDocumentVersionId}/issue/${nextIssueId}`);
    } else if (nextDocumentVersionId) {
      setDocumentVersionId(nextDocumentVersionId);
    } else if (nextIssueId) {
      setIssueId(nextIssueId);
    } else {
      navigate('.');
    }
  }, [navigate, setDocumentVersionId, setIssueId]);

  useEffect(() => {
    // redirect (useMemo must not have side effects like navigation)
    // don't allow navigation to private archive if the user does not have the appropriate right
    if (documentScopeId === ArchiveScopeId.Public && !currentUserRole?.allowedActions?.has(RoleAction.Document_GetArchived)) {
      navigate(`../${DocumentScopeKey.Archive}/${ArchiveScopeId.Private}`);
    }
  }, [navigate, documentScopeId, currentUserRole?.allowedActions]);

  useEffect(() => {
    // redirect (useMemo must not have side effects like navigation)
    // the useMemo above returned the fallback "all documents" => redirect to "all documents"
    if (documentScope.key === DocumentScopeKey.ProjectDocuments && documentScopeKey !== DocumentScopeKey.ProjectDocuments) {
      navigate(`../${DocumentScopeKey.ProjectDocuments}`);
    }
  }, [documentScope.key, documentScopeKey, navigate]);

  const folderId = useMemo(() => (documentScopeKey === DocumentScopeKey.Folder ? documentScopeId : undefined), [documentScopeKey, documentScopeId]);
  const { data: folderTreeData, isLoading: isLoadingFolderTreeData, isStale: isStaleFolderTreeData } = useFolderTreeQuery();
  useEffect(() => {
    if (!folderId || isLoadingFolderTreeData || isStaleFolderTreeData) return;
    if (folderTreeData && !folderTreeData.foldersById.has(folderId)) throw new Error('Folder not found');
  }, [folderId, folderTreeData, isLoadingFolderTreeData, isStaleFolderTreeData]);

  const state = useMemo<DocumentScopeContextState>(() => ({
    documentScope,
    setDocumentScope,
    documentVersionId,
    setDocumentVersionId,
    issueId,
    setIssueId,
    setDocumentVersionAndIssueId,
    highlightedDocumentCommentId,
    setHighlightedDocumentCommentId,
  }), [documentScope, setDocumentScope, documentVersionId, setDocumentVersionId, issueId, setIssueId, setDocumentVersionAndIssueId, highlightedDocumentCommentId]);

  return (
    <DocumentScopeContext.Provider value={state}>
      {children}
    </DocumentScopeContext.Provider>
  );
}
