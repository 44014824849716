import { useQueryClient } from '@tanstack/react-query';
import IssueDto from 'issues/types/IssueDto';
import useIssueQueryKey from 'issues/hooks/useIssueQueryKey';
import { useCallback } from 'react';

export default function useIssueQueryData() {
  const queryClient = useQueryClient();
  const { getQueryKey } = useIssueQueryKey();
  return useCallback(async (id: string) => {
    const queryKey = getQueryKey(id);
    return await queryClient.fetchQuery<IssueDto>({ queryKey });
  }, [getQueryKey, queryClient]);
}
