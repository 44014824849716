import React, {
  useCallback, useContext, useMemo,
} from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import {
  Box, Button, useTheme, styled,
} from '@mui/material';
import FileType from 'documents/types/FileType';
import Color from 'color';
import DocumentScopeContext, { DocumentScopeContextState } from 'documents/contexts/DocumentScopeContext';

const supportedFileTypes = new Set<FileType>([
  FileType.Pdf,
  FileType.DWG,
  FileType.Image,
]);

const PreviewLink = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
  fontWeight: 600,
  flexShrink: 1,
  justifyContent: 'left',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  display: 'inline',
}));

export default function NameCell({
  value: row,
}: ICellRendererParams<DocumentsDataGridRow, DocumentsDataGridRow>) {
  const theme = useTheme();
  const { setDocumentVersionId } = useContext<DocumentScopeContextState>(DocumentScopeContext);
  const onClick = useCallback(() => {
    if (!row?.id) return;
    setDocumentVersionId(row.id);
  }, [row?.id, setDocumentVersionId]);
  const isPreviewAvailable = useMemo(() => {
    if (!row || !row.fileType || row.isArchived) return false;
    if (row.fileType === FileType.DWG && !row.previewFileId) return false;
    if (row.originalFileName.endsWith('.svg')) return false;
    return (supportedFileTypes.has(row.fileType) && !!row.fileId);
  }, [row]);

  return (
    <Box sx={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center' }}>
      {!!isPreviewAvailable && (
        <Box sx={{ backgroundColor: Color(theme.palette.info.main).lightness(92).hex(), width: '16px', height: '16px', flexShrink: 0, mr: 0.75, borderRadius: '2px' }}>
          <svg style={{ display: 'block' }} viewBox="-1 -4 17 17">
            <use xlinkHref="/img/sprite.svg#preview-eye" />
          </svg>
        </Box>
      )}
      <PreviewLink onClick={onClick} disabled={row?.isArchived}>{row?.name}</PreviewLink>
    </Box>
  );
}
