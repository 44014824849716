import React, { useCallback, useRef, useState } from 'react';
import { Box, ButtonBase, Divider, Menu, MenuItem, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import AccountSettingsDialog from 'users/components/AccountSettingsDialog';
import EmailSettingsDialog from 'emails/components/EmailSettingsDialog';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import { useTranslation } from 'react-i18next';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';
import LanguageSwitch from 'users/components/LanguageSwitch';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { useQueryClient } from '@tanstack/react-query';

interface UserDropdownMenuProps extends ISxProps {
  hideEmailSettingsMenuItem?: boolean | undefined,
}

export default function UserDropdownMenu({
  sx,
  hideEmailSettingsMenuItem,
}: UserDropdownMenuProps) {
  const { t } = useTranslation('users');
  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const queryClient = useQueryClient();
  const { signOut } = useVisoplanApiContext();
  const { data: currentUser } = useCurrentUserQuery();
  const [accountSettingsDialogOpen, setAccountSettingsDialogOpen] = useState(false);
  const [emailSettingsOpen, setEmailSettingsOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const onClickOpenMenu = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const onCloseMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const onClickOpenAccountSettingsDialog = useCallback(() => {
    setAccountSettingsDialogOpen(true);
    setMenuOpen(false);
  }, []);

  const onCloseAccountSettingsDialog = useCallback(() => {
    setAccountSettingsDialogOpen(false);
    setMenuOpen(false);
  }, []);

  const onClickOpenEmailSettingsDialog = useCallback(() => {
    setEmailSettingsOpen(true);
    setMenuOpen(false);
  }, []);

  const onCloseEmailSettingsDialog = useCallback(() => {
    setEmailSettingsOpen(false);
    setMenuOpen(false);
  }, []);

  const onClickHelp = useCallback(() => {
    window.open('https://help.visoplan.de', '_blank', 'noreferrer');
  }, []);

  const onClickLogout = useCallback(async () => {
    await signOut();
    queryClient.clear();
  }, [queryClient, signOut]);

  if (!currentUser) return null;

  return (
    <>
      <ButtonBase
        id="UserDropdownMenuButton"
        sx={{
          ...sx,
          fontSize: 18,
          fontWeight: 700,
          display: 'flex',
          gap: 2,
          px: 4,
          '&:hover': {
            backgroundColor: theme.palette.secondary.light,
          },
        }}
        onClick={onClickOpenMenu}
        ref={buttonRef}
      >
        {`${currentUser.firstName} ${currentUser.lastName}`}
        <Box sx={{ backgroundColor: theme.palette.secondary.light, width: 28, height: 28, borderRadius: '14px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {menuOpen ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </ButtonBase>
      {menuOpen && (
        <Menu
          anchorEl={buttonRef.current}
          open={menuOpen}
          onClose={onCloseMenu}
        >
          <Box sx={{ py: 1, px: 2, minWidth: 200 }}>
            <LanguageSwitch />
          </Box>
          <Divider orientation="horizontal" sx={{ my: 1 }} />
          <MenuItem onClick={onClickOpenAccountSettingsDialog}>
            {t('user-dropdown-menu-button_account-settings-button-label', 'Account Settings')}
          </MenuItem>
          {!hideEmailSettingsMenuItem && (
            <MenuItem onClick={onClickOpenEmailSettingsDialog}>
              {t('user-dropdown-menu-button_email-settings-button-label', 'E-Mail Settings')}
            </MenuItem>
          )}
          <MenuItem onClick={onClickHelp}>
            {t('user-dropdown-menu-button_help-button-label', 'Help')}
          </MenuItem>
          <Divider orientation="horizontal" sx={{ my: 1 }} />
          <MenuItem onClick={onClickLogout}>
            {t('user-dropdown-menu-button_logout-button-label', 'Log Out')}
          </MenuItem>
        </Menu>
      )}
      {accountSettingsDialogOpen && (
        <AccountSettingsDialog onClose={onCloseAccountSettingsDialog} />
      )}
      {emailSettingsOpen && (
        <EmailSettingsDialog onClose={onCloseEmailSettingsDialog} />
      )}
    </>
  );
}
