import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { LocalizationProvider, PickersDay, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Badge } from "@mui/material";
import { uniqBy } from "lodash";
import moment from "moment";

import { setFilterValuesFromOtherPage } from "Containers/ProjectContainer/Actions";
import { formatDate } from "Helpers/format-date";
import useInitialFilterValues from 'issues/hooks/useInitialFilterValues';

const UpcomingDeadlines = ({ issues, statuses }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [dueDates, setDueDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { initialFilterValues } = useInitialFilterValues();

  useEffect(() => {

    const dueDates = uniqBy(
      issues
        .filter((issue) => issue.dueDate)
        .map((issue) => issue.dueDate.slice(0, 10))
    );
    setDueDates(dueDates);
  }, [issues]);

  const customDayRenderer = useCallback(
    (date, selectedDates, pickersDayProps) => {
      const props = { ...pickersDayProps, sx: { fontSize: 14 }};
      const stringifiedDate = date.toISOString().slice(0, 10);
      if (dueDates.length) {
        if (dueDates.includes(stringifiedDate)) {
          return <Badge
            key={date.toString()}
            overlap="circular"
            color="warning"
            variant="dot"
          >
            <PickersDay {...props} />
          </Badge>
        }
      }
      return <PickersDay {...props} />;
    },
    [dueDates]
  );

  // we have to use this workaround for a bug that occurs when using the MUI date picker with moment
  useEffect(() => {
    moment.updateLocale(i18n.language.split('-')[0], { week: { dow: 1 } });
  }, [t]);

  const openIssues = () => {
    const prevDay = new Date(selectedDate);
    prevDay.setHours(prevDay.getHours() - 24);
    const fValues = {
      ...initialFilterValues,
      dueDateFrom: prevDay,
      dueDateTo: selectedDate,
      status: statuses.map((item) => ({ ...item, value: item.id, label: item.originalName })),
    };

    dispatch(setFilterValuesFromOtherPage(fValues));
    navigate("../issues");
  }

  return (
    <div className="content dashboard__content__calendar">
      <div className="dashboard__content__title">
        <h2>{t("upcoming_deadlines", "Upcoming Deadlines")}</h2>
        <button
          id="UpcomingDeadlines"
          className="btn btn--secondary mobile--hide"
          onClick={openIssues}
        >
          {t("due_issues", "Due Issues")}
        </button>
      </div>
      <div className="dashboard__content__calendar__body">
        <div className="dashboard__content__calendar__wrapper">
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              openTo="day"
              value={selectedDate}
              onChange={(date) => setSelectedDate(formatDate(date))}
              renderInput={(params) => <TextField {...params} />}
              renderDay={customDayRenderer}
            />
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};

export default UpcomingDeadlines;
