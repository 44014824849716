import { useMemo } from 'react';
import useDefaultEntityQueryKeys, { VisoDetailsQueryKey } from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';

export type DocumentListQueryKey = VisoDetailsQueryKey<ApiEndpoint.DocumentList>;

export default function useDocumentListQueryKey() {
  const {
    detailsByIdQueryKey: queryKeyBase,
    getDetailsByIdQueryKey: getQueryKey,
  } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentList);
  return useMemo(() => ({
    queryKeyBase,
    getQueryKey,
  }), [getQueryKey, queryKeyBase]);
}
