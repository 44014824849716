import React from 'react';
import { useTranslation } from 'react-i18next';
import IssueResponsibilityActionPanel from 'issues/components/IssueResponsibilityActionPanel';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useRejectedIssueStatus from 'issues/hooks/useRejectedIssueStatus';

interface RejectAssignmentPanelProps {
  issueId: string,
  onClose: () => void,
}

export default function IssueRejectAssignmentPanel({
  issueId,
  onClose,
}: RejectAssignmentPanelProps) {
  const { t } = useTranslation('issues');
  const rejectedIssueStatus = useRejectedIssueStatus();
  if (!rejectedIssueStatus) return <CenteredCircularProgress />;
  return (
    <IssueResponsibilityActionPanel
      id="RejectAssignmentPanel"
      issueId={issueId}
      title={t('reject-assignment-panel_header', 'Reject Assignment')}
      onClose={onClose}
      confirmButtonLabel={t('reject-assignment-panel_confirm-button-label', 'Reject and assign to creator')}
      targetStatusId={rejectedIssueStatus.id}
      commentMandatory
    />
  );
}
