import React, {
  useCallback, useRef, useMemo, useContext, useEffect,
} from 'react';
import { Box } from '@mui/material';
import { AgGridReact } from '@ag-grid-community/react';
import {
  ColumnApi,
  ColumnMovedEvent,
  ColumnResizedEvent,
  GetRowIdParams,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  RowSelectedEvent,
  SortChangedEvent,
  ViewportChangedEvent,
} from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import useFolderTreeQuery from 'documents-folders/hooks/useFolderTreeQuery';
import useDocumentFilterContext from 'documents/hooks/useDocumentFilterContext';
import useDocumentVersionsRowRangeData from 'documents/hooks/useDocumentVersionsRowRangeData';
import DocumentVersionsRowRangeData from 'documents/types/DocumentVersionsRowRangeData';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import DocumentSelectionContext, { DocumentSelectionContextState } from 'documents/contexts/DocumentSelectionContext';
import useDocumentsDataGridColumnDefinitions from 'documents/hooks/useDocumentsDataGridColumnDefinitions';
import useDynamicLayout from 'dynamic-layout/hooks/useDynamicLayout';
import DynamicLayoutKey from 'dynamic-layout/types/DynamicLayoutKey';
import DocumentSortDefinition from 'documents-filter/types/DocumentSortDefinition';
import DocumentScopeContext, { DocumentScopeContextState } from 'documents/contexts/DocumentScopeContext';
import DocumentDragAndDropContext, { DocumentDragAndDropContextState } from 'documents/contexts/DocumentDragAndDropContext';
import DocumentStatisticsContext, { DocumentStatisticsContextState } from 'documents/contexts/DocumentStatisticsContext';
import DocumentVersionDto from 'documents/types/DocumentVersionDto';
import { isEqual } from 'lodash';

export default function DocumentsDataGrid() {
  const gridRef = useRef<AgGridReact<DocumentsDataGridRow>>(null);
  const { odataFilter, documentListMode, setSortDefinitions } = useDocumentFilterContext();
  const { documentScope } = useContext<DocumentScopeContextState>(DocumentScopeContext);
  const { data: folderTreeData } = useFolderTreeQuery();
  const totalDocumentsCount = useMemo(() => (folderTreeData ? Array.from(folderTreeData.foldersById.values()).reduce((prev, curr) => prev + curr.documentCount, 0) : undefined), [folderTreeData]);
  const {
    selectedDocumentVersionIds, setSelectedDocumentVersionIds, nonDeselectableVersionIds, resetSelection,
  } = useContext<DocumentSelectionContextState>(DocumentSelectionContext);
  const selectedDocumentVersionIdsSet = useMemo(() => new Set<string>(selectedDocumentVersionIds), [selectedDocumentVersionIds]);
  const { columnDefs, defaultColumnDef } = useDocumentsDataGridColumnDefinitions();
  const {
    folderTreeDropZoneParams,
    planlistDataGridDropZoneParams,
    planlistCreateNewDropZoneParams,
  } = useContext<DocumentDragAndDropContextState>(DocumentDragAndDropContext);
  const { setDisplayedRowRange, setCurrentDocumentVersionIds, currentDocumentVersionIds } = useContext<DocumentStatisticsContextState>(DocumentStatisticsContext);
  const {
    entities,
    ids,
    requestViewportRangeData,
  } = useDocumentVersionsRowRangeData(odataFilter);

  // make sure nothing is selected that is not part of the currently viewed list of document versions
  // (e.g. from editing a metadata field we are currently filtering by)
  useEffect(() => {
    if (!currentDocumentVersionIds) return;
    const currentDocumentVersionIdsSet = new Set(currentDocumentVersionIds);
    const filteredSelection = selectedDocumentVersionIds.filter((id) => currentDocumentVersionIdsSet.has(id));
    if (isEqual(filteredSelection, selectedDocumentVersionIds)) return;
    setSelectedDocumentVersionIds(filteredSelection);
  }, [selectedDocumentVersionIds, currentDocumentVersionIds, setSelectedDocumentVersionIds]);

  const datasource: IServerSideDatasource = useMemo(() => ({
    getRows: async (params: IServerSideGetRowsParams<DocumentsDataGridRow>) => {
      const { request, success, fail } = params;
      if (request.startRow === undefined || request.endRow === undefined || request.startRow < 0 || request.endRow <= request.startRow) {
        fail();
        return;
      }
      const requestRange = { firstRowIndex: request.startRow, rowCount: request.endRow - request.startRow };
      let immediateData: DocumentVersionsRowRangeData | undefined;
      try {
        immediateData = await requestViewportRangeData(requestRange);
      } catch (e) {
        fail();
        return;
      }
      if (!immediateData) {
        fail();
        return;
      }
      const {
        entities: immediateEntities,
        ids: immediateIds,
      } = immediateData;
      const rowData: (DocumentsDataGridRow | undefined)[] = new Array(Math.min(requestRange.rowCount, immediateIds.length - requestRange.firstRowIndex));
      for (let i = 0; i < rowData.length; i += 1) {
        rowData[i] = immediateEntities[i + requestRange.firstRowIndex];
      }
      success({ rowData, rowCount: immediateIds.length });
    },
  }), [requestViewportRangeData]);

  const updateSelection = useCallback(() => {
    if (!gridRef.current?.api) return;
    gridRef.current.api.forEachNode((rowNode) => {
      if (rowNode.data?.id) {
        rowNode.setSelected(selectedDocumentVersionIdsSet.has(rowNode.data?.id));
      }
    });
  }, [selectedDocumentVersionIdsSet]);

  useEffect(() => {
    // cache invalidation (from sync or mutations)
    if (entities && gridRef.current?.api) {
      gridRef.current.api.refreshServerSide();
      updateSelection();
    }
  }, [entities, updateSelection]);

  useEffect(() => {
    // reset scroll position when navigating
    if (totalDocumentsCount !== undefined && gridRef.current?.api) {
      gridRef.current.api.setRowCount(totalDocumentsCount);
      if (totalDocumentsCount > 0) {
        gridRef.current.api.ensureIndexVisible(0, 'top');
      }
    }
  }, [odataFilter, totalDocumentsCount]);

  useEffect(() => {
    // reset selection when navigating or changing version list mode
    resetSelection();
    // TODO: we want to react to changing scope, but we don't want to react to the callback changing.
    // => this is a case for https://react.dev/reference/react/experimental_useEffectEvent for the reset callback.
    // but we can't use it until it is out of experimental. Until then we just supress the warning and assume that the effect works without the callback as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentScope.id, documentScope.key, documentListMode]);

  const onSelectionChanged = useCallback((event: RowSelectedEvent<DocumentsDataGridRow>) => {
    if (!gridRef.current?.api || event.source.startsWith('api')) return;
    const selectedRows = gridRef.current!.api.getSelectedRows();
    const nextSelection = selectedRows.map((row) => row.id).concat(Array.from(nonDeselectableVersionIds ?? []));
    setSelectedDocumentVersionIds(nextSelection);
  }, [nonDeselectableVersionIds, setSelectedDocumentVersionIds]);

  useEffect(() => {
    if (!folderTreeDropZoneParams) return;
    if (!gridRef.current?.api) throw new Error('API not initialized when folder drop zone params were set.');
    gridRef.current.api.removeRowDropZone(folderTreeDropZoneParams);
    gridRef.current.api.addRowDropZone(folderTreeDropZoneParams);
    // TODO: if this leads to a race condition, we could do this instead:
    // the context returns a promise on the target dropzone params that is consumed by the source.
    // The promise fulfills once the target dropzone has put its dropzone params into the context.
  }, [folderTreeDropZoneParams]);

  useEffect(() => {
    if (!planlistDataGridDropZoneParams) return;
    if (!gridRef.current?.api) throw new Error('API not initialized when planlist drop zone params were set.');
    gridRef.current.api.addRowDropZone(planlistDataGridDropZoneParams);
  }, [planlistDataGridDropZoneParams]);

  useEffect(() => {
    if (!planlistCreateNewDropZoneParams) return;
    if (!gridRef.current?.api) throw new Error('API not initialized when planlist-creation drop zone params were set.');
    gridRef.current.api.addRowDropZone(planlistCreateNewDropZoneParams);
  }, [planlistCreateNewDropZoneParams]);

  const getRowId = useCallback((params: GetRowIdParams<DocumentsDataGridRow>) => params.data?.id, []);

  const { persistAsync } = useDynamicLayout(DynamicLayoutKey.DEFAULT_DOCS_LAYOUT);
  const onColumnEvent = useCallback((columnApi: ColumnApi) => {
    const columns = columnApi.getAllDisplayedColumns();
    const updatedColumns = columns.filter((c) => !!c.getColDef().field).map((c) => ({ fieldName: c.getColDef().field!, width: c.getActualWidth() }));
    persistAsync(updatedColumns);
  }, [persistAsync]);

  const onColumnResized = useCallback(({ source, finished, columnApi }: ColumnResizedEvent<DocumentsDataGridRow>) => {
    if (source.startsWith('ui') && finished) onColumnEvent(columnApi);
  }, [onColumnEvent]);

  const onColumnMoved = useCallback(({ source, finished, columnApi }: ColumnMovedEvent<DocumentsDataGridRow>) => {
    if (source.startsWith('ui') && finished) onColumnEvent(columnApi);
  }, [onColumnEvent]);

  const onSortChanged = useCallback((event: SortChangedEvent<DocumentsDataGridRow>) => {
    const updatedSortDefinitions: DocumentSortDefinition[] = event.columnApi.getColumnState().filter((columnState) => !!columnState.sort).map((columnState) => ({ fieldName: columnState.colId as keyof DocumentVersionDto, descending: columnState.sort === 'desc' }));
    setSortDefinitions(updatedSortDefinitions);
  }, [setSortDefinitions]);

  useEffect(() => {
    setCurrentDocumentVersionIds(ids);
  }, [ids, setCurrentDocumentVersionIds]);

  const onViewportChanged = useCallback((e: ViewportChangedEvent<DocumentsDataGridRow>) => {
    setDisplayedRowRange({ firstRowIndex: e.firstRow, rowCount: e.lastRow - e.firstRow });
  }, [setDisplayedRowRange]);

  const onModelUpdated = useCallback(() => {
    updateSelection();
  }, [updateSelection]);

  return (
    <Box sx={{ height: '100%', backgroundColor: '#F7F8FB' }} className="ag-theme-material ag-theme-visoplan table-view-data-grid inset-shadow-top-left-27-20">
      <AgGridReact<DocumentsDataGridRow>
        rowHeight={54}
        ref={gridRef}
        rowModelType="serverSide"
        serverSideDatasource={datasource}
        maxConcurrentDatasourceRequests={1}
        getRowId={getRowId}
        defaultColDef={defaultColumnDef}
        columnDefs={columnDefs}
        onSortChanged={onSortChanged}
        rowSelection="multiple"
        suppressCellFocus
        suppressRowHoverHighlight
        suppressRowClickSelection
        onSelectionChanged={onSelectionChanged}
        onColumnResized={onColumnResized}
        onColumnMoved={onColumnMoved}
        rowDragMultiRow
        onViewportChanged={onViewportChanged}
        onModelUpdated={onModelUpdated}
        // @ts-ignore
        modules={[ServerSideRowModelModule]}
      />
    </Box>
  );
}
