import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  VIEWER_MARGIN,
  VIEWER_OFFSET_X,
  VIEWER_OFFSET_Y,
} from "../../../Helpers/Common";
import CustomSelect from "../../CustomSelect";
import { getGeneralSvg } from "../../SVGs";
import { TOOLBAR_POS } from "./ViewerToolbar";

const MeasurePanel = ({
  containerRect,
  closePanel,
  addMeasurement,
  measurements,
  measurement,
  changeMeasurement,
  removeMeasurement,
  resetMeasurements,
}) => {
  const { t } = useTranslation();
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [isMoving, setIsMoving] = useState(false);
  const movingRef = useRef(isMoving);
  const rectRef = useRef(containerRect);

  useEffect(() => {
    document.addEventListener("mousemove", onDocMouseMove);
    document.addEventListener("mouseup", onDocMouseUp);

    return () => {
      document.removeEventListener("mousemove", onDocMouseMove);
      document.removeEventListener("mouseup", onDocMouseUp);
    };

    function onDocMouseMove(e) {
      if (movingRef.current) {
        calculatePos(e.clientX - VIEWER_OFFSET_X, e.clientY - VIEWER_OFFSET_Y);
      }
    }

    function onDocMouseUp(e) {
      if (movingRef.current && e.button === 0) {
        setIsMoving(false);
      }
    }
  }, []);

  useEffect(() => {
    movingRef.current = isMoving;
  });

  useEffect(() => {
    rectRef.current = containerRect;

    if (pos.x === 0 && pos.y === 0) {
      const domString = localStorage.getItem(TOOLBAR_POS);
      if (domString) {
        const toolbarHtml = document.getElementById("viewer-toolbar");
        const toolbarPos = JSON.parse(domString);
        calculatePos(
          toolbarPos.x + toolbarHtml.clientWidth + VIEWER_MARGIN,
          toolbarPos.y
        );
      }
    } else {
      calculatePos(pos.x, pos.y);
    }
  }, [containerRect]);

  useEffect(() => {
    document.documentElement.style.cursor = isMoving ? "move" : "default";
  }, [isMoving]);

  const calculatePos = (posX, posY) => {
    if (rectRef.current) {
      const htmlElement = document.getElementById("measure-panel");
      const width = htmlElement ? htmlElement.clientWidth : 0;
      const height = htmlElement ? htmlElement.clientHeight : 0;

      const x = Math.min(
        Math.max(posX, rectRef.current.left - VIEWER_OFFSET_X + VIEWER_MARGIN),
        rectRef.current.right - width - VIEWER_OFFSET_X - VIEWER_MARGIN
      );
      const y = Math.min(
        Math.max(posY, rectRef.current.top - VIEWER_OFFSET_Y + VIEWER_MARGIN),
        rectRef.current.bottom - VIEWER_OFFSET_Y - height - VIEWER_MARGIN
      );

      setPos({ x, y });
    }
  };

  const onMouseDown = (e) => {
    if (e.button === 0) {
      setIsMoving(true);
    }
  };

  const onMouseUp = (e) => {
    if (isMoving && e.button === 0) {
      setIsMoving(false);
    }
  };

  return (
    <div
      id="measure-panel"
      className="clipping-panel"
      style={{ left: `${pos.x}px`, top: `${pos.y}px` }}
      onMouseUp={onMouseUp}
    >
      <div className="clipping-panel_header">
        <div className="moving" onMouseDown={onMouseDown}>
          {getGeneralSvg("moving-label")}
        </div>
        <div className="section-manager">
          <div style={{ maxWidth: "120px" }}>
            <p>{t("measurement", "Measurement")}</p>
            <CustomSelect
              values={measurements}
              value={measurement}
              isModel={true}
              onChange={changeMeasurement}
            />
          </div>
          <button className="btn btn--primary" onClick={addMeasurement}>
            {t("add_measure", "Add Measurement")}
          </button>
          <div style={{ cursor: "pointer" }} onClick={closePanel}>
            {getGeneralSvg("clipping-close")}
          </div>
        </div>
      </div>
      <div className="clipping-panel_middle_measure">
        <p>{t("stop_measure", "Click ESC to stop measurement")}</p>
        <div className="display-result">
          <div>
            <label>
              {t("strecke", "Distance")}
              <span style={{ color: "#4B6FFC", paddingLeft: "5px" }}>
                {measurement?.label}
              </span>
            </label>
            <label>
              {measurement
                ? `${parseFloat(measurement.data.totalLength.toFixed(2))}m`
                : ""}
            </label>
          </div>
          {/* <div>
                <label>{t('area', 'Area')}</label>
                <label>{measurement?.area}<span>&#13217;</span></label>
              </div> */}
        </div>
      </div>
      <div className="clipping-panel_bottom">
        <label onClick={removeMeasurement}>
          {t("remove_selection", "Remove Selection")}
        </label>
        <label onClick={resetMeasurements}>{t("reset_all", "Reset All")}</label>
      </div>
    </div>
  );
};

export default MeasurePanel;
