import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import useCurrentUserRole from 'users/hooks/useCurrentUserRole';
import RoleAction from 'projects/types/RoleAction';
import ProjectSettingsProjectEmailAddressSection from 'settings/components/ProjectSettingsProjectEmailAddressSection';
import ProjectSettingsTwoFactorAuthSection from 'settings/components/ProjectSettingsTwoFactorAuthSection';

export default function ProjectSettingsTabPane() {
  const { t } = useTranslation('settings');
  const { data: currentUser } = useCurrentUserQuery();
  const currentUserRole = useCurrentUserRole();
  const { data: currentProject } = useCurrentProjectQuery();
  if (!currentProject || !currentUser || !currentUserRole) return <CenteredCircularProgress />;
  return (
    <Box
      id="ProjectSettingsTabPane"
      sx={{
        height: '100%',
        py: 4,
        pr: 4,
        display: 'grid',
        gridTemplateColumns: 'minmax(auto, 200px) auto 4fr',
      }}
    >
      <Box />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1000,
        minWidth: 600,
        gap: 6,
        py: 4,
        px: 8,
      }}
      >
        <Box>
          <Typography variant="h2" sx={{ mb: 1, mr: 'auto' }}>{t('project-settings-tab-pane_title', 'Project Settings')}</Typography>
        </Box>
        {!!(currentUserRole.isAdmin) && (
          <>
            <Divider />
            <ProjectSettingsTwoFactorAuthSection />
          </>
        )}
        {!!currentUserRole.allowedActions?.has(RoleAction.Inbox_Edit) && (
          <>
            <Divider />
            <ProjectSettingsProjectEmailAddressSection />
          </>
        )}
      </Box>
    </Box>
  );
}
