import React from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components-n/checkbox';

interface GroupCheckboxProps {
  group: any;
  currentIndex: number;
  handleChangeGroup: (param: any) => void;
}

export default function GroupCheckbox({
  group,
  currentIndex,
  handleChangeGroup,
}: GroupCheckboxProps) {
  const { t } = useTranslation();

  return (
    <div className="scheme-checkbox ml-0" style={{ gap: '16px' }}>
      <Checkbox
        className="scheme-label"
        label={t('exlude_from_versioning', 'Exclude From Versioning')}
        checked={group.excludeFromCleanName || false}
        onChange={() => {
          handleChangeGroup({
            currentIndex,
            elemIndex: 0,
            excludeFromCleanName: !group.excludeFromCleanName,
          });
        }}
      />
      <Checkbox
        className="scheme-label"
        label={t('optional_group', 'Optional Group')}
        checked={group.isOptional || false}
        onChange={() => {
          handleChangeGroup({
            name: 'optional',
            currentIndex,
            elemIndex: 0,
            isOptional: !group.isOptional,
          });
        }}
      />
    </div>
  );
}
