import React from "react";

import { getDisciplineColor } from "../../Services/IssueService";

export default function DisciplineAbbreviation({ discipline, index }) {
  return (
    <div className="discipline-abbreviation">
      <div
        className="category-item text-ellipsis"
        style={{
          backgroundColor: `${getDisciplineColor(index)}33`,
          color: getDisciplineColor(index),
        }}
      >
        {discipline.abbreviation}
      </div>
    </div>
  );
}
