export const defaultPropertyColumns = [
  { name: "Component Type", width: 159 },
  { name: "Property Name", width: 150 },
  { name: "PSet", width: 183 },
  { name: "Property Type", width: 147 },
  { name: "Unit", width: 109 },
  { name: "Discipline", width: 115 },
  { name: "Building", width: 132 },
  { name: "Floor", width: 98 },
  { name: "Base Type", width: 122 },
  { name: "Value", width: 281 },
];
