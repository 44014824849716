import React from "react";
import { useTranslation } from "react-i18next";

import { IconButton } from "../Buttons";
import { getTypeSvg } from "../SVGs";

const IconPopup = ({ setSelectedIconPopup, onChangeTypeIcon }) => {
  const { t } = useTranslation();
  return (
    <div className="icon-popup">
      <div className="icon-popup-category">
        <div className="icon-popup-category-title">
          <p>{t("standard_icons", "Standard Icons")}</p>
          <IconButton
            className="btn-close"
            onClick={() => setSelectedIconPopup(null)}
            iconId="close"
          />
        </div>
        <div className="icon-popup-category-icons">
          {Array.apply(null, Array(10)).map((item, index) => (
            <button
              key={`default-${index}`}
              className="icon-popup-category-icons-item"
              onClick={(e) => {
                onChangeTypeIcon(e, `icon-default-type-${index + 1}`);
                setSelectedIconPopup(null);
              }}
            >
              {getTypeSvg(`icon-default-type-${index + 1}`, "#838383")}
            </button>
          ))}
        </div>
      </div>
      <div className="icon-popup-category">
        <div className="icon-popup-category-title">
          <p>{t("other", "Other")}</p>
        </div>
        <div className="icon-popup-category-icons">
          {Array.apply(null, Array(30)).map((item, index) => (
            <button
              key={`other-${index}`}
              className="icon-popup-category-icons-item"
              onClick={(e) => {
                onChangeTypeIcon(e, `issue-other-type-${index + 1}`);
                setSelectedIconPopup(null);
              }}
            >
              {getTypeSvg(`issue-other-type-${index + 1}`, "#838383")}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IconPopup;
