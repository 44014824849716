import DocumentsNavigationState from 'documents/types/DocumentsNavigationState';
import React, { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';

export default function RedirectHub() {
  const { projectId, documentVersionId, documentVersionCommentId } = useParams<'projectId' | 'documentVersionId' | 'documentVersionCommentId'>();
  const to = useMemo(() => (projectId ? `/projects/${projectId}/documents/all` : undefined), [projectId]);
  const state = useMemo<DocumentsNavigationState | undefined>(() => {
    if (!documentVersionCommentId || !documentVersionId) return undefined;
    return {
      documentVersionId,
      documentVersionCommentId,
    };
  }, [documentVersionCommentId, documentVersionId]);
  if (!to || !state) return null;
  return <Navigate to={to} state={state} />;
}
