import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';
import useDefaultEntityQueryKeys from 'api/hooks/useDefaultEntityQueryKeys';

export default function useFolderUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const projectId = useCurrentProjectId();
  if (!projectId) throw new Error('projectId missing');
  const { baseQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.Folder);
  return useMutation<boolean | undefined, unknown, string>(async (folderId: string) => {
    const { data: wasDeletionSuccessful } = await axiosInstance.delete<boolean>(`${ApiEndpoint.Folder}/${folderId}`);
    return wasDeletionSuccessful;
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(baseQueryKey);
    },
  });
}
