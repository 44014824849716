import React, { useCallback, useState } from 'react';
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography, useTheme } from '@mui/material';
import RichTextField from 'rich-text/components/RichTextField';
import useIssueCommentCreateMutation from 'issues/hooks/useCommentCreateMutation';
import RichTextEditorContextProvider from 'rich-text/contexts/RichTextEditorContextProvider';
import useRichTextEditorContext from 'rich-text/hooks/useRichTextEditorContext';
import PersistIssueCommentDto from 'issues/types/PersistIssueCommentDto';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import { useTranslation } from 'react-i18next';
import useIssueUpdateMutation from 'issues/hooks/useIssueUpdateMutation';
import UpdateIssueDto from 'issues/types/UpdateIssueDto';
import IssueVisibility from 'issues/types/IssueVisibility';
import useCollaboratorsQuery from 'collaborators/hooks/useCollaboratorsQuery';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';

interface IssueResponsibilityActionPanelProps {
  onClose: () => void,
  id: string,
  issueId: string,
  title: string,
  confirmButtonLabel: string,
  commentDefaultText?: string | undefined,
  targetStatusId?: string,
  targetAssignedUserId?: string,
  commentMandatory?: boolean,
  canSelectAssigneeAndReviewer?: boolean,
  targetVisibility?: IssueVisibility,
}

function IssueResponsibilityActionPanel({
  onClose,
  id,
  issueId,
  title,
  confirmButtonLabel,
  commentDefaultText,
  targetStatusId,
  targetAssignedUserId,
  commentMandatory,
  canSelectAssigneeAndReviewer,
  targetVisibility,
}: IssueResponsibilityActionPanelProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { editor, text, setIsDisabled } = useRichTextEditorContext();
  const { mutateAsync: createComment, isLoading: isLoadingCommentMutation } = useIssueCommentCreateMutation();
  const { mutateAsync: updateIssue, isLoading: isLoadingIssueMutation } = useIssueUpdateMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { data: collaborators } = useCollaboratorsQuery();
  const [selectedAssignedUserId, setSelectedAssignedUserId] = useState<string | undefined>(undefined);
  const onChangeSelectedAssignedUserId = useCallback((event: SelectChangeEvent<string>) => setSelectedAssignedUserId(event.target.value || undefined), []);
  const [selectedReviewerId, setSelectedReviewerId] = useState<string | undefined>(undefined);
  const onChangeSelectedReviewerId = useCallback((event: SelectChangeEvent<string>) => setSelectedReviewerId(event.target.value || undefined), []);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const getRequestErrorMessage = useRequestErrorMessage();

  const onClickConfirm = useCallback(async () => {
    if (!editor || !issueId) return;
    setErrorMessage(undefined);
    setIsDisabled(true);

    try {
      // update issue
      const assignedUserIds = selectedAssignedUserId ? { value: [selectedAssignedUserId] } : targetAssignedUserId ? { value: [targetAssignedUserId] } : undefined;
      const updateIssueDto: UpdateIssueDto = {
        id: issueId,
        statusId: targetStatusId ? { value: targetStatusId } : undefined,
        assignedUserIds,
        reviewerId: selectedReviewerId ? { value: selectedReviewerId } : undefined,
        visibility: targetVisibility ? { value: targetVisibility } : undefined,
      };
      await updateIssue(updateIssueDto);

      // create comment
      const commentText = (text?.trim() || commentDefaultText) || '';
      if (commentText.length) {
        const persistIssueCommentDto: PersistIssueCommentDto = {
          issueId: { value: issueId },
          text: { value: commentText },
        };
        await createComment(persistIssueCommentDto);
        editor.commands.clearContent();
      }

      setIsDisabled(false);
      onClose();
    } catch (error: any) {
      setIsDisabled(false);
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [editor, issueId, setIsDisabled, selectedAssignedUserId, targetAssignedUserId, targetStatusId, selectedReviewerId, targetVisibility, updateIssue, text, commentDefaultText, onClose, createComment, getRequestErrorMessage]);

  const onClickCancel = useCallback(() => {
    if (!editor) return;
    editor.commands.clearContent();
    onClose();
  }, [editor, onClose]);

  if (!issueId) return null;
  if (!collaborators) return <CenteredCircularProgress />;
  return (
    <Box id={id} sx={{ px: 2 }}>
      <Box sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.3)',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
      >
        <Typography variant="h4">{title}</Typography>
        {!!canSelectAssigneeAndReviewer && !!collaborators && (
          <>
            <FormControl sx={{ flexGrow: 1 }}>
              <InputLabel id="issue-responsibility-action-panel_assignee-select-label">
                {t('issue-responsibility-action-panel_assignee-select-label', 'Assignee')}
              </InputLabel>
              <Select
                id="issue-responsibility-action-panel_assignee-select"
                value={selectedAssignedUserId ?? ''}
                onChange={onChangeSelectedAssignedUserId}
                label={t('issue-responsibility-action-panel_assignee-select-label', 'Assignee')}
              >
                <MenuItem value="" sx={{ fontStyle: 'italic' }}>{t('issue-responsibility-action-panel_no-assignee-item', 'Unassigned')}</MenuItem>
                {collaborators.map((collaborator) => (
                  <MenuItem value={collaborator.id} key={collaborator.id}>
                    {`${collaborator.firstName} ${collaborator.lastName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ flexGrow: 1 }}>
              <InputLabel id="issue-responsibility-action-panel_reviewer-select-label">
                {t('issue-responsibility-action-panel_reviewer-select-label', 'Reviewer')}
              </InputLabel>
              <Select
                id="issue-responsibility-action-panel_reviewer-select"
                value={selectedReviewerId ?? ''}
                onChange={onChangeSelectedReviewerId}
                label={t('issue-responsibility-action-panel_reviewer-select-label', 'Reviewer')}
              >
                <MenuItem value="" sx={{ fontStyle: 'italic' }}>{t('issue-responsibility-action-panel_no-reviewer-item', 'No Reviewer')}</MenuItem>
                {collaborators.map((collaborator) => (
                  <MenuItem value={collaborator.id} key={collaborator.id}>
                    {`${collaborator.firstName} ${collaborator.lastName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: canSelectAssigneeAndReviewer ? 130 : 180 }}>
          <RichTextField
            sx={{ minHeight: 0, flexGrow: 1 }}
            label={t('issue-responsibility-action-panel_comment-textfield-label', 'Comment')}
          />
          {!!errorMessage && (
            <Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClickCancel}
            disabled={isLoadingCommentMutation || isLoadingIssueMutation}
          >
            {t('issue-responsibility-action-panel_cancel-button-label', 'Cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickConfirm}
            sx={{ flexGrow: 1 }}
            disabled={isLoadingCommentMutation || isLoadingIssueMutation || (!!commentMandatory && text.trim().length === 0)}
          >
            {confirmButtonLabel}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default function IssueResponsibilityActionPanelWrapper(props: IssueResponsibilityActionPanelProps) {
  return (
    <RichTextEditorContextProvider>
      <IssueResponsibilityActionPanel {...props} />
    </RichTextEditorContextProvider>
  );
}
