import React from 'react';
import { Box, Typography } from '@mui/material';
import VerticalDivider from 'common/styled/VerticalDivider';
import DocumentsSearchTextField from 'documents-filter/components/DocumentsSearchTextField';
import FilterActiveInidicator from 'documents-filter/components/FilterActiveIndicator';
import DocumentsOptionsButtonMenu from 'documents/components/DocumentsOptionsButtonMenu';
import { useTranslation } from 'react-i18next';
import HeaderBox from 'common/components/HeaderBox';
import FilterDrawerToggleButton from 'documents-filter/components/FilterDrawerToggleButton';

export default function IssueLinkDocumentsDialogHeader() {
  const { t } = useTranslation('issues');
  return (
    <HeaderBox id="IssueLinkDocumentsDialogHeader" sx={{ height: '64px' }}>
      <Typography variant="h2" sx={{ width: 292, pl: 4 }}>{t('issue-link-documents-dialog-header_title', 'Link Documents')}</Typography>
      <VerticalDivider sx={{ height: '48px' }} />
      <DocumentsSearchTextField />
      <Box sx={{ ml: 'auto', display: 'flex' }}>
        <FilterActiveInidicator sx={{ mr: 2 }} />
        <DocumentsOptionsButtonMenu />
        <FilterDrawerToggleButton />
      </Box>
    </HeaderBox>
  );
}
