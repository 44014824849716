import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AsideIssueViewpoint from "../Aside/AsideIssue/AsideIssueViewpoint";
import { IconButton } from "../Buttons";

import { getResource } from "../../Services/ResourceService";
import { MODE_ADD_COMPONENT, MODE_ADD_VIEWPOINT } from "../../Helpers/Common";
import { ViewpointService } from "../../Services";

const ComponentItem = ({
  component,
  index,
  setAddComponents,
  setIsModifiedIssueComponents,
}) => {
  const removeComponent = useCallback(() => {
    setAddComponents((prev) => {
      const buffer = [...prev];
      buffer.splice(index, 1);
      return buffer;
    });
    setIsModifiedIssueComponents(true);
  }, [index]);

  return (
    <div className="component-item">
      <p className="component-item_title">{component.elementName || ""}</p>
      <IconButton
        className="btn-close"
        iconId="close"
        onClick={removeComponent}
      />
    </div>
  );
};

const ViewpointItem = ({ viewpoint, index, setAddViewpoints }) => {
  const { t } = useTranslation();
  const [image, setImage] = useState(null);

  const callAPI = (viewpointIds) => {
    ViewpointService.deleteViewpoints(viewpointIds);
  };

  const removeViewpoint = useCallback(() => {
    setAddViewpoints((prev) => {
      const buffer = [...prev];
      callAPI([buffer[index].id]);
      buffer.splice(index, 1);
      return buffer;
    });
  }, [index]);

  useEffect(() => {
    const effect = async () => {
      if (viewpoint.image) return;

      const result = await getResource(
        viewpoint.thumbnailImageId || viewpoint.imageId
      );

      const reader = new FileReader();
      reader.readAsDataURL(result);
      reader.onloadend = () => {
        setImage(reader.result);
      };
    }
    effect();
  }, [viewpoint]);

  return (
    <div className="viewpoint-item">
      <AsideIssueViewpoint image={viewpoint.image || image} />
      <p className="viewpoint-item_remove" onClick={removeViewpoint}>
        {t("remove", "Remove")}
      </p>
    </div>
  );
};

export default function IssueModalModel(props) {
  const {
    components,
    viewpoints,
    setIssueAddMode,
    setAddComponents,
    setAddViewpoints,
    setIsModifiedIssueComponents,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="issue-modal-model">
      <div className="issue-modal-model_component">
        <div className="model-header">
          <h4 className="model-header_title">
            {t("components", "Components")}
          </h4>
          <button
            className="btn-action btn btn--secondary"
            onClick={() => {
              setIssueAddMode(MODE_ADD_COMPONENT);
            }}
          >
            {t("add_component", "Add Component")}
          </button>
        </div>
        <div className="model_component-content custom-scrollbar">
          {components.map((component, index) => (
            <ComponentItem
              key={`component-item-${index}`}
              component={component}
              index={index}
              setAddComponents={setAddComponents}
              setIsModifiedIssueComponents={setIsModifiedIssueComponents}
            />
          ))}
        </div>
      </div>
      <div className="issue-modal-model_viewpoint">
        <div className="model-header">
          <h4 className="model-header_title">
            {t("viewpoints", "Viewpoints")}
          </h4>
          <button
            className="btn-action btn btn--secondary"
            onClick={() => {
              setIssueAddMode(MODE_ADD_VIEWPOINT);
            }}
          >
            {t("add_viewpoint", "Add Viewpoint")}
          </button>
        </div>
        <div className="model_viewpoint-content custom-scrollbar">
          {viewpoints.map((viewpoint, index) => (
            <ViewpointItem
              key={`viewpoint-item-${index}`}
              viewpoint={viewpoint}
              index={index}
              setAddViewpoints={setAddViewpoints}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
