import React from "react";
import { useTranslation } from "react-i18next";

// Helpers
import * as DateHelper from "../../Helpers/Date";

// Services
import { IssueService } from "../../Services";

const IssueLog = ({
  log,
  users,
  documents,
  disciplines,
  buildings,
  floors,
  tags,
  workphases,
  statuses,
  priorities,
  types,
  allIssues,
}) => {
  const { t } = useTranslation();

  const toggleDetails = (event) => {
    event.target.parentElement.parentElement.classList.toggle("opened");
  };

  switch (log.entryType) {
    case IssueService.IssueLogType.IssueCreated:
      return (
        <div className="issue-history--row">
          {t("issue_created", "Issue was created")}
        </div>
      );
    case IssueService.IssueLogType.StatusUpdate:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("issue_status", "Issue status"),
              (statuses &&
                statuses.find((status) => status.value === log.oldValue)
                  ?.label) ||
                t("no_value", "no value"),
              (statuses &&
                statuses.find((status) => status.value === log.newValue)
                  ?.label) ||
                t("no_value", "no value")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.TypeUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("type", "Type"),
              (types &&
                types.find((type) => type.value === log.oldValue)?.label) ||
                t("no_value", "no value"),
              (types &&
                types.find((type) => type.value === log.newValue)?.label) ||
                t("no_value", "no value")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.DescriptionUpdated:
      return (
        <div className="issue-history--row">
          <div className="issue-history--row--title">
            <span
              dangerouslySetInnerHTML={{
                __html: sprintf(
                  t(
                    "description_changed",
                    "<strong>Description</strong> changed to <strong>%1$s</strong>"
                  ),
                  log.newValue
                ),
              }}
            ></span>
            <a
              className="btn-toggle-issue-log btn-toggle-issue-log--show"
              onClick={toggleDetails}
            >
              {t("show_changes", "Show Changes")}
            </a>
            <a
              className="btn-toggle-issue-log btn-toggle-issue-log--hide"
              onClick={toggleDetails}
            >
              {t("hide_changes", "Hide Changes")}
            </a>
          </div>
          <div className="issue-history--row--details">
            <div className="issue-history--row--details--row">
              <strong>{t("old", "Old")}</strong>
              <div>{log.oldValue}</div>
            </div>
            <div className="issue-history--row--details--row">
              <strong>{t("new", "New")}</strong>
              <div>{log.newValue}</div>
            </div>
          </div>
        </div>
      );
    case IssueService.IssueLogType.TitleUpdated:
      return (
        <div className="issue-history--row">
          <div className="issue-history--row--title">
            <span
              dangerouslySetInnerHTML={{
                __html: sprintf(
                  t("title_changed", "<strong>Title</strong> changed")
                ),
              }}
            ></span>
            <a
              className="btn-toggle-issue-log btn-toggle-issue-log--show"
              onClick={toggleDetails}
            >
              {t("show_changes", "Show Changes")}
            </a>
            <a
              className="btn-toggle-issue-log btn-toggle-issue-log--hide"
              onClick={toggleDetails}
            >
              {t("hide_changes", "Hide Changes")}
            </a>
          </div>
          <div className="issue-history--row--details">
            <div className="issue-history--row--details--row">
              <strong>{t("old", "Old")}</strong>
              <div>{log.oldValue}</div>
            </div>
            <div className="issue-history--row--details--row">
              <strong>{t("new", "New")}</strong>
              <div>{log.newValue}</div>
            </div>
          </div>
        </div>
      );
    case IssueService.IssueLogType.PriorityUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("priority", "Priority"),
              (priorities &&
                priorities.find((priority) => priority.value === log.oldValue)
                  ?.label) ||
                t("no_value", "no value"),
              (priorities &&
                priorities.find((priority) => priority.value === log.newValue)
                  ?.label) ||
                t("no_value", "no value")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.DueDateUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("due_date", "Due date"),
              log.oldValue
                ? DateHelper.formatDate(new Date(log.oldValue))
                : t("no_value", "no value"),
              DateHelper.formatDate(new Date(log.newValue))
            ),
          }}
        />
      );
    case IssueService.IssueLogType.DueDateRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_removed", "<strong>%1$s</strong> removed"),
              t("due_date", "Due date")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.AssignedToUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("assigned_user", "Assigned user"),
              log.oldValue.map((u) => users[u]).join(", "),
              log.newValue.map((u) => users[u]).join(", ")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.AssignedToRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_removed", "<strong>%1$s</strong> removed"),
              t("assigned_user", "Assigned user")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.LabelAdded:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(t("issue_log_added"), t("label")),
          }}
        ></div>
      );
    case IssueService.IssueLogType.LabelRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_removed", "<strong>%1$s</strong> removed"),
              t("label", "Label")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.StageAdded:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(t("issue_log_added"), t("stage")),
          }}
        ></div>
      );
    case IssueService.IssueLogType.StageRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_removed", "<strong>%1$s</strong> removed"),
              t("stage", "Stage")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.StageUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("stage", "Stage"),
              log.oldValue || t("no_value", "no value"),
              log.newValue || t("no_value", "no value")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.DocumentFirstLinked:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html:
              sprintf(
                t("issue_log_added", "<strong>%1$s</strong> added"),
                t("activity_document", "Document")
              ) +
              ": " +
              documents[log.documentVersionId],
          }}
        ></div>
      );
    case IssueService.IssueLogType.DocumentFirstLinkRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_removed", "<strong>%1$s</strong> removed"),
              t("activity_document", "Document")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.ReviewerUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("reviewer", "Reviewer"),
              users[log.oldValue] || t("no_value", "no value"),
              users[log.newValue] || t("no_value", "no value")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.ReviewerRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_removed", "<strong>%1$s</strong> removed"),
              t("reviewer", "Reviewer")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.DisciplineMetaDataUpdated:
    case IssueService.IssueLogType.CustomDisciplineMetaDataUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("discipline", "Discipline"),
              log.oldValue.map((d) => disciplines[d]).join(", ") ||
                t("no_value", "no value"),
              log.newValue.map((d) => disciplines[d]).join(", ") ||
                t("no_value", "no value")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.DisciplineMetaDataRemoved:
    case IssueService.IssueLogType.CustomDisciplineMetaDataRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_removed", "<strong>%1$s</strong> removed"),
              t("discipline", "Discipline")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.BuildingMetaDataUpdated:
    case IssueService.IssueLogType.CustomBuildingMetaDataUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("building", "Building"),
              log.oldValue.map((b) => buildings[b]).join(", ") ||
                t("no_value", "no value"),
              log.newValue.map((b) => buildings[b]).join(", ") ||
                t("no_value", "no value")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.BuildingMetaDataRemoved:
    case IssueService.IssueLogType.CustomBuildingMetaDataRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_removed", "<strong>%1$s</strong> removed"),
              t("building", "Building")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.FloorMetaDataUpdated:
    case IssueService.IssueLogType.CustomFloorMetaDataUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("floor", "Storey"),
              log.oldValue.map((f) => floors[f]).join(", ") ||
                t("no_value", "no value"),
              log.newValue.map((f) => floors[f]).join(", ") ||
                t("no_value", "no value")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.FloorMetaDataRemoved:
    case IssueService.IssueLogType.CustomFloorMetaDataRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_removed", "<strong>%1$s</strong> removed"),
              t("floor", "Storey")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.ModelsUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("model", "Model"),
              log.oldValue || t("no_value", "no value"),
              log.newValue || t("no_value", "no value")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.ModelsRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_removed", "<strong>%1$s</strong> removed"),
              t("model", "Model")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.TagsUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("tags", "Tags"),
              log.oldValue.map((tag) => tags[tag]).join(", ") ||
                t("no_value", "no value"),
              log.newValue.map((tag) => tags[tag]).join(", ") ||
                t("no_value", "no value")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.TagsRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_removed", "<strong>%1$s</strong> removed"),
              t("tags", "Tags")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.ViewpointAdded:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_added", "<strong>%1$s</strong> added"),
              t("viewpoints", "Viewpoints")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.ViewpointRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_removed", "<strong>%1$s</strong> removed"),
              t("viewpoints", "Viewpoints")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.LinkedComponentsUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("linked_components", "Linked Components"),
              log.oldValue.length,
              log.newValue.length
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.LinkedComponentsRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_removed", "<strong>%1$s</strong> removed"),
              t("linked_components", "Linked Components")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.StartingDateUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("starting_date", "Starting date"),
              log.oldValue
                ? DateHelper.formatDate(new Date(log.oldValue))
                : t("no_value", "no value"),
              DateHelper.formatDate(new Date(log.newValue))
            ),
          }}
        />
      );
    case IssueService.IssueLogType.StartingDateRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_removed", "<strong>%1$s</strong> removed"),
              t("starting_date", "Starting date")
            ),
          }}
        />
      );
    case IssueService.IssueLogType.WorkPhaseUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("workphase", "Workphase"),
              log.oldValue
                ? workphases.find((item) => item.id === log.oldValue)?.label ||
                    t("no_workphase_value", "no workphase value")
                : t("no_value", "no value"),
              log.newValue
                ? workphases.find((item) => item.id === log.newValue)?.label ||
                    t("no_workphase_value", "no workphase value")
                : t("no_value", "no value")
            ),
          }}
        />
      );
    case IssueService.IssueLogType.WorkPhaseRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_removed", "<strong>%1$s</strong> removed"),
              t("workphase", "Workphase")
            ),
          }}
        />
      );
    case IssueService.IssueLogType.VisibilityUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("visibility", "Visibility"),
              log.oldValue,
              log.newValue
            ),
          }}
        />
      );
    case IssueService.IssueLogType.AllowedRolesAdded:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_added", "<strong>%1$s</strong> added"),
              t("allowed_roles", "Allowed roles")
            ),
          }}
        />
      );
    case IssueService.IssueLogType.LinkedEmailUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("linked_email", "Linked email"),
              log.oldValue,
              log.newValue
            ),
          }}
        />
      );
    case IssueService.IssueLogType.LinkedEmailRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("issue_log_removed", "<strong>%1$s</strong> removed"),
              t("linked_email", "Linked email")
            ),
          }}
        />
      );
    case IssueService.IssueLogType.DocumentLinkVersionUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "document_link_version_updated",
                "<strong>%1$s</strong>: <strong>%2$s</strong> version was updated"
              ),
              t("activity_document", "Document"),
              documents[log.documentVersionId] || t("no_value", "no value")
            ),
          }}
        />
      );
    case IssueService.IssueLogType.ParentUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "parent_updated",
                "<strong>parent</strong> was updated to <strong>%1$s</strong>."
              ),
              (allIssues &&
                allIssues.find((issue) => issue.id === log.newValue)?.title) ||
                t("no_value", "no value")
            ),
          }}
        />
      );
    case IssueService.IssueLogType.ParentRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t("parent_removed", "<strong>parent</strong> was removed")
            ),
          }}
        />
      );
    case IssueService.IssueLogType.StatusIdUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("issue_status", "Issue status"),
              log.oldValue || t("no_value", "no value"),
              log.newValue || t("no_value", "no value")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.TypeIdUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("type", "Type"),
              log.oldValue || t("no_value", "no value"),
              log.newValue || t("no_value", "no value")
            ),
          }}
        ></div>
      );
    case IssueService.IssueLogType.PriorityIdUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: sprintf(
              t(
                "issue_changed",
                "<strong>%1$s</strong> changed from <strong>%2$s</strong> to <strong>%3$s</strong>."
              ),
              t("priority", "Priority"),
              log.oldValue || t("no_value", "no value"),
              log.newValue || t("no_value", "no value")
            ),
          }}
        />
      );
    default:
      return "";
  }
};

const IssueHistory = ({
  log,
  users,
  documents,
  disciplines,
  buildings,
  floors,
  tags,
  workphases,
  statuses,
  priorities,
  types,
  allIssues,
}) => {
  return (
    <div className="issue-history">
      <div className="issue-history--title">
        <strong>{users[log.userId]}</strong>
        {log.creationDate ? (
          <span className="issue-history--creation-date">
            {DateHelper.formatDateWithSeconds(new Date(log.creationDate))}
          </span>
        ) : (
          <></>
        )}
      </div>
      <div>
        <IssueLog
          log={log}
          users={users}
          documents={documents}
          disciplines={disciplines}
          buildings={buildings}
          floors={floors}
          tags={tags}
          workphases={workphases}
          statuses={statuses}
          priorities={priorities}
          types={types}
          allIssues={allIssues}
        />
      </div>
    </div>
  );
};

export default IssueHistory;
