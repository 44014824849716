import React, { useCallback, useContext, useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import AddIcon from '@mui/icons-material/Add';
import CreatePlanlistDialog from 'documents-lists/components/CreatePlanlistDialog';
import DocumentSelectionContext, { DocumentSelectionContextState } from 'documents/contexts/DocumentSelectionContext';
import useDocumentScopeContext from 'documents/hooks/useDocumentScopeContext';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';

interface CreatePlanlistDialogButtonProps extends ISxProps {
}

export default function CreatePlanlistDialogButton({
  sx,
}: CreatePlanlistDialogButtonProps) {
  const { t } = useTranslation('documents-lists');
  const [isCreatePlanlistDialogOpen, setIsCreatePlanlistDialogOpen] = useState(false);
  const { setDocumentScope } = useDocumentScopeContext();
  const { selectedDocumentVersionIds } = useContext<DocumentSelectionContextState>(DocumentSelectionContext);
  const onClick = useCallback(() => setIsCreatePlanlistDialogOpen(true), []);
  const onCloseCreatePlanlistDialog = useCallback((planlistIdAddedTo: string | undefined) => {
    setIsCreatePlanlistDialogOpen(false);
    if (planlistIdAddedTo) {
      setDocumentScope({ key: DocumentScopeKey.Planlist, id: planlistIdAddedTo });
    }
  }, [setDocumentScope]);

  return (
    <>
      <Tooltip title={t('create-planlist-dialog-button_tooltip', 'Create New Planlist')}>
        <span>
          <Button
            onClick={onClick}
            sx={{ ...sx, minWidth: 'unset', px: 1 }}
            variant="contained"
            id="CreatePlanlistDialogButton"
          >
            <AddIcon />
          </Button>
        </span>
      </Tooltip>
      {isCreatePlanlistDialogOpen && <CreatePlanlistDialog onClose={onCloseCreatePlanlistDialog} documentVersionIds={selectedDocumentVersionIds} />}
    </>
  );
}
