import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useIssuesOdataQuery from 'issues/hooks/useIssuesOdataQuery';
import IssueItemCard from 'issues/components/IssueItemCard';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';

interface IssuesTimelineIssuesListProps extends ISxProps {
  onSelectIssue: (id: string | undefined) => void,
}

export default function IssuesTimelineIssuesList({
  sx,
  onSelectIssue,
}: IssuesTimelineIssuesListProps) {
  const { odataQuery } = useIssuesFilterContext();
  const { data: issues } = useIssuesOdataQuery(odataQuery);

  const onClickIssue = useCallback((issueId: string) => {
    onSelectIssue(issueId);
  }, [onSelectIssue]);

  return (
    <Box
      id="IssuesTimelineIssuesList"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        ...sx,
      }}
    >
      {!!issues && issues.map((issue) => <IssueItemCard key={issue.id} issue={issue} onClick={onClickIssue} />)}
      {!issues && <CenteredCircularProgress />}
    </Box>
  );
}
