import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import IChildren from 'common/types/IChildren';
import QueryFilterFilterContext, { QueryFilterFilterContextState } from 'documents-filter/contexts/QueryFilterFilterContext';
import QueryFilter from 'documents-filter/types/QueryFilter';
import QueryFilterSortMode from 'documents-filter/types/QueryFilterSortMode';

interface QueryFilterContextProviderProps extends IChildren {
}

export default function QueryFilterFilterContextProvider({
  children,
}: QueryFilterContextProviderProps) {
  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const isFilterActive = useMemo(() => !!searchString?.length, [searchString]);
  const filterPredicate = useCallback((queryFilter: QueryFilter) => !searchString || (!!queryFilter.name && queryFilter.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())), [searchString]);
  const [sortMode, setSortMode] = useState<QueryFilterSortMode>(localStorage.getItem('filterSortMode') as QueryFilterSortMode ?? QueryFilterSortMode.CreationDateDesc);

  useEffect(() => {
    const storedSortMode = localStorage.getItem('filterSortMode') as QueryFilterSortMode | null;
    if (sortMode !== storedSortMode) localStorage.setItem('filterSortMode', sortMode);
  }, [sortMode]);

  const state: QueryFilterFilterContextState = useMemo(() => ({
    isFilterActive,
    searchString,
    setSearchString,
    filterPredicate,
    sortMode,
    setSortMode,
  }), [filterPredicate, searchString, isFilterActive, sortMode, setSortMode]);

  return (
    <QueryFilterFilterContext.Provider value={state}>
      {children}
    </QueryFilterFilterContext.Provider>
  );
}
