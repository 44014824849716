import React, { useMemo } from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import DocumentVersionItem from 'documents-details/components/DocumentVersionItem';
import FileType from 'documents/types/FileType';

interface IssueLinkedDocumentsPanelProps {
  issueId: string,
  onClickDocumentVersionItem?: ((documentVersionId: string) => void) | undefined,
}

export default function IssueLinkedDocumentsPanel({
  issueId,
  onClickDocumentVersionItem,
}: IssueLinkedDocumentsPanelProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { data: issue } = useIssueQuery(issueId);
  const odataQuery = useMemo(() => (issue ? { filter: { id: { in: issue.linkedDocumentVersionIds } } } : undefined), [issue]);
  const { data: linkedDocumentVersions } = useDocumentVersionsQuery(odataQuery);
  const linkedNonImages = useMemo(() => linkedDocumentVersions?.filter((v) => v.fileType !== FileType.Image || v.originalFileName.endsWith('.svg')), [linkedDocumentVersions]);
  return (
    <Box id="IssueLinkedDocumentsPanel">
      <Box sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.3)',
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <Typography variant="h4" sx={{ p: 2 }}>
          {t('issue-linked-documents-panel_title', 'Linked documents')}
          {!!linkedNonImages && ` (${linkedNonImages.length})`}
          {!linkedNonImages && <CircularProgress size={12} sx={{ ml: 1 }} />}
        </Typography>
        {!!linkedNonImages?.length && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {linkedNonImages.map((documentVersion) => (
              <DocumentVersionItem key={documentVersion.id} documentVersion={documentVersion} onClick={onClickDocumentVersionItem} />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
