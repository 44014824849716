import React from "react";

export default function AttachmentItemContentInfo({ info }) {
  return (
    <div className="attachment-item-content-info-wrapper">
      <svg className="icon">
        <use xlinkHref="/img/sprite.svg#attachment" />
      </svg>
      <div className="attachment-item-content-info">
        <p className="file-name text-ellipsis">{info.name}</p>
        <p className="file-size text-ellipsis">{`${parseInt(
          info.contentLength / 1024
        )}KB`}</p>
      </div>
    </div>
  );
}
