enum RoleAction {
  // ActivityLog
  ActivityLog_Access = 0,
  ActivityLog_Export,
  // Project
  Project_ModelUpload = 100,
  Project_Copy,
  Project_EditMembers = 103,
  Project_CreateGroups,
  Project_DeleteGroups,
  Project_EditGroups,
  // IssueManagement
  IssueManagement_Creation = 200,

  /// <summary>
  /// User can completely edit an issues except the status BUT is not allowed to edit closed issues.
  /// </summary>
  IssueManagement_Editing,

  /// <summary>
  /// User can edit issues status BUT is not allowed to edit closed issues or set the status to closed.
  /// </summary>
  IssueManagement_StatusEditing,
  IssueManagement_CreatingComments,
  IssueManagement_BcfUpload,
  IssueManagement_Export,

  /// <summary>
  /// Assigned user can edit an issues except the status BUT is not allowed to edit closed issues.
  /// </summary>
  IssueManagement_Assigned_Editing,

  /// <summary>
  /// Assigned user can edit issues status BUT is not allowed to edit closed issues or set the status to closed.
  /// </summary>
  IssueManagement_Assigned_StatusEditing,

  /// <summary>
  /// Creator can edit an issues except the status BUT is not allowed to edit closed issues.
  /// </summary>
  IssueManagement_Creator_Editing,

  /// <summary>
  /// Creator can edit issues status BUT is not allowed to edit closed issues or set the status to closed.
  /// </summary>
  IssueManagement_Creator_StatusEditing,

  /// <summary>
  /// Reviewer can edit an issues except the status BUT is not allowed to edit closed issues.
  /// </summary>
  IssueManagement_Reviewer_Editing,

  /// <summary>
  /// Reviewer can edit issues status BUT is not allowed to edit closed issues.
  /// </summary>
  IssueManagement_Reviewer_StatusEditing,

  /// <summary>
  /// User can edit issues status TO closed, BUT is not allowed to change the status FROM closed to something else.
  /// </summary>
  IssueManagement_StatusEditing_SetClosed,

  /// <summary>
  /// User can edit issues status FROM closed to something else, BUT is not allowed to change the status TO closed.
  /// </summary>
  IssueManagement_StatusEditing_UnsetClosed,

  // Document

  /// <summary>
  /// User can upload new documents.
  /// </summary>
  Document_Upload = 300,

  /// <summary>
  /// User can download documents.
  /// </summary>
  Document_Download,

  /// <summary>
  /// User can edit the properties of a document.
  /// </summary>
  Document_Editing,

  /// <summary>
  /// User can create sharelinks for documents.
  /// </summary>
  Document_Sharing,

  /// <summary>
  /// User can create and delete comments.
  /// </summary>
  Document_CommentEditing = 305,

  /// <summary>
  /// User can archive document
  /// </summary>
  Document_Archiving,

  /// <summary>
  /// User can get archived documents
  /// </summary>
  Document_GetArchived,

  /// <summary>
  /// User can restore archived documents
  /// </summary>
  Document_RestoreArchived,

  /// <summary>
  /// User allow to run IFC import from document
  /// </summary>
  Document_IfcImport,

  /// <summary>
  /// User allow to run 2D import from document
  /// </summary>
  Document_FloorPlanImport,

  // Folder
  Folder_Edit = 400,
  Folder_EditAccessList,
  Folder_Create = 402,
  Folder_Delete = 403,

  Tag_Create_Update = 500,
  Tag_Delete,

  // Main Menu

  /// <summary>
  /// User is able to see the Dasboard-Button in the Main Menu.
  /// </summary>
  MainMenu_Dashboard = 600,

  /// <summary>
  /// User is able to see the ModelsView-Button in the Main Menu.
  /// </summary>
  MainMenu_Models,

  /// <summary>
  /// User is able to see the Issues-Button in the Main Menu.
  /// </summary>
  MainMenu_Issues,

  /// <summary>
  /// User is able to see the ModelHistory-Button in the Main Menu.
  /// </summary>
  MainMenu_History,

  /// <summary>
  /// User is able to see the Documents-Button in the Main Menu.
  /// </summary>
  MainMenu_Documents,

  /// <summary>
  /// User is able to launch the liveView.
  /// </summary>
  MainMenu_LiveView,

  /// <summary>
  /// User is able to launch the collaborators view.
  /// </summary>
  MainMenu_Collaborators,

  /// <summary>
  /// User is able to launch the settings view.
  /// </summary>
  MainMenu_Settings,

  /// <summary>
  /// User is able to see the Issues Timeline -Button in the Main Menu.
  /// </summary>
  MainMenu_IssueTimeLine,

  /// <summary>
  /// User able to view emails
  /// </summary>
  Email_View = 700,

  /// <summary>
  /// User able to create, edit naming scheme
  /// </summary>
  NamingScheme_Create_Edit = 800,

  /// <summary>
  /// User able to delete naming scheme
  /// </summary>
  NamingScheme_Delete = 801,

  /// <summary>
  /// User is able to delete public document lists
  /// </summary>
  DocumentList_Delete = 900,

  /// <summary>
  /// User is able to create new processes inside the project.
  /// </summary>
  Process_Create = 1000,

  /// <summary>
  /// User is able to view processes that are not directly assigned to him.
  /// </summary>
  Process_ViewAll,

  /// <summary>
  /// User able to create, edit document status.
  /// </summary>
  DocStatus_Create_Edit = 1100,

  /// <summary>
  /// User is able to delete document status.
  /// </summary>
  DocStatus_Delete = 1101,

  /// <summary>
  /// User can change from any status to status "published"
  /// </summary>
  DocStatus_ChangeToPublished = 1103,

  /// <summary>
  /// User can change from status "published" to any other status
  /// </summary>
  DocStatus_ChangeFromPublished = 1104,

  /// <summary>
  /// User can enable, disable and configure document stamps (inserted on download)
  /// </summary>
  DocStamp_Edit = 1150,

  /// <summary>
  /// User able to create, edit issue status
  /// </summary>
  IssueStatus_Create_Edit = 1200,

  /// <summary>
  /// User is able to delete issue status
  /// </summary>
  IssueStatus_Delete = 1201,

  /// <summary>
  /// User able to create, edit issue type
  /// </summary>
  IssueType_CreateEdit = 1202,

  /// <summary>
  /// User able to delete issue type
  /// </summary>
  IssueType_Delete = 1203,

  /// <summary>
  /// User able to create, edit issue priority
  /// </summary>
  IssuePriority_CreateEdit = 1204,

  /// <summary>
  /// User able to delete issue priority
  /// </summary>
  IssuePriority_Delete = 1205,

  /// <summary>
  /// User can delete public smartViews created by different User.
  /// </summary>
  SmartView_DeleteFromDifferentCreator = 1300,

  /// <summary>
  /// User can rename inbox email address
  /// </summary>
  Inbox_Edit = 1400,

  /// <summary>
  /// User allow to upload emails to inbox
  /// </summary>
  Inbox_AllowUploadEmail = 1401,

  /// <summary>
  /// User allow to use BIMQ integration
  /// </summary>
  BimqIntegration = 1500,

  /// <summary>
  /// User allow to use NovaAva integration
  /// </summary>
  NovaAvaIntegration = 1600,
}

export default RoleAction;
