import React from 'react';
import { Box, SvgIcon, SvgIconProps, Typography, useTheme } from '@mui/material';

export default function ModelsIcon({
  htmlColor,
  fontSize,
}: Pick<SvgIconProps, 'htmlColor' | 'fontSize'>) {
  const theme = useTheme();
  return (
    <Box sx={{ position: 'relative' }}>
      <SvgIcon htmlColor={htmlColor} fontSize={fontSize} viewBox="0 0 16 16" sx={{ position: 'relative', top: -5 }}>
        <path d="m 15.533504,13.276605 c -0.08826,0 -0.201728,0.02522 -0.264768,0.06304 L 13.20096,12.154429 V 6.4050532 c 0,0 0.01261,-0.1008896 0,-0.1639296 -0.01261,-0.037824 -0.03782,-0.050432 -0.03782,-0.050432 L 8.321536,3.3159844 V 1.4499492 C 8.447616,1.3616932 8.54848,1.1977828 8.54848,1.0212644 8.54848,0.74388204 8.308928,0.516931 8.031552,0.516931 c -0.27744,0 -0.491776,0.22695104 -0.491776,0.5043334 0,0.1891264 0.037824,0.3530368 0.277376,0.4412928 V 3.3159844 L 2.9503552,6.1654756 c -0.06304,0.037824 -0.1008704,0.06304 -0.1386944,0.12608 -0.025216,0.050438 -0.012608,0.1765376 -0.012608,0.1765376 v 5.6485118 l -2.0425536,1.22304 C 0.6808512,13.301821 0.5925952,13.276605 0.5043328,13.276605 0.2269504,13.276605 0,13.503549 0,13.768317 c 0,0.264768 0.2143424,0.491712 0.4917248,0.491712 0.2773824,0 0.4917248,-0.226944 0.4917248,-0.491712 L 2.9755712,12.557885 7.82976,15.420029 c 0.050432,0.03782 0.100864,0.06304 0.163904,0.06304 0.037888,0 0.08832,-0.01261 0.126144,-0.03782 l 4.854208,-2.862144 2.04256,1.197824 c 0,0.277376 0.226944,0.491712 0.491712,0.491712 0.277376,0 0.491712,-0.226944 0.491712,-0.491712 0,-0.264768 -0.201728,-0.50432 -0.466496,-0.50432 z M 7.804544,3.8959716 V 8.9066852 L 3.5555584,6.4050532 Z m 0.516992,0 4.24896,2.5090816 -4.24896,2.509056 z m 0,10.8935934 V 9.7840612 L 12.54528,12.280509 Z M 12.69664,11.864445 8.435008,9.3427812 12.69664,6.8337252 Z" />
      </SvgIcon>
      <Box sx={{ position: 'absolute', bottom: -3, left: 0, right: 0, display: 'flex', justifyContent: 'center' }}>
        <Typography sx={{ backgroundColor: htmlColor, color: theme.palette.common.white, fontSize: 9, lineHeight: 1.1, px: '2px', borderRadius: '2px' }}>
          beta
        </Typography>
      </Box>
    </Box>
  );
}
