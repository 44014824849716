import RoleAction from "projects/types/RoleAction";
import React, { useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import useCurrentUserRole from "users/hooks/useCurrentUserRole";

import { getTypeSvg } from "../SVGs";

const ValueContainer = ({ children, ...props }) => {
  const { selectProps } = props;
  return (
    <components.ValueContainer {...props}>
      <div
        className="type-select"
        style={{
          background: selectProps.value.color
            ? `${selectProps.value.color}20`
            : "transparent",
          color: selectProps.value.color
            ? `${selectProps.value.color}`
            : "#434b59",
        }}
      >
        {!!selectProps.value.icon &&
          getTypeSvg(selectProps.value.icon, selectProps.value.color)}
        <label className="text-ellipsis">{children}</label>
      </div>
    </components.ValueContainer>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div
        className="type-select"
        style={{
          background: `${props.data.color}20`,
          color: `${props.data.color}`,
        }}
      >
        {!!props.data.icon && getTypeSvg(props.data.icon, props.data.color)}
        <label className="text-ellipsis">{props.label}</label>
      </div>
    </components.Option>
  );
};

const typeStyles = {
  option: (styles) => {
    return {
      ...styles,
      ...{
        display: "flex",
        padding: "0 10px",
        margin: "4px 0",
        height: "24px",
        justifyContent: "flex-start",
        alignItems: "center",
        input: {
          width: "15px",
          height: "15px",
          margin: "0 15px 0 0",
        },
        label: {
          fontSize: "14px !important",
          lineHeight: "20px",
          fontWeight: "normal",
          padding: "0",
          margin: "0",
          opacity: 0.8,
        },
      },
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      ...{
        display: "flex",
        padding: "0px !important",
        height: "32px",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "nowrap",
        "> div": {
          width: "100px",
          height: "24px",
        },
        ".num-wrapper": {
          borderRadius: "6px",
          padding: "0 3px",
          lineHeight: "20px",
        },
        label: {
          lineHeight: "20px",
          fontWeight: "600",
          padding: "0",
          margin: "0",
          opacity: 0.8,
        },
      },
    };
  },
  singleValue: (styles, { selectProps }) => {
    return {
      ...styles,
      ...{
        color: selectProps.value.color,
      },
    };
  },
};

const TypeSelect = ({
  values,
  onChange,
  value,
  isGrey = false,
  menuPlacement = "auto",
  isDisabled = false,
  isCreationHide = false,
  classNames = "",
  isDisableAddTag = false,
}) => {
  const currentUserRole = useCurrentUserRole();
  const isTagCreateUpdateAllowed = currentUserRole?.allowedActions?.has(RoleAction.Tag_Create_Update);
  const containerRef = useRef(null);
  const [inputValue, setInputValue] = useState("");

  const typeClassName = useMemo(() => {
    let name =
      "tag-select custom-select select--light select-width-full select--multi select--no-background";
    if (isCreationHide) name += " select--creation-hide";
    if (isGrey) name += " select--grey";
    else name += " select--model";
    if (isDisableAddTag) name += " disable-add-tag";
    return name;
  }, [isCreationHide, isGrey, isDisableAddTag]);
  const { t } = useTranslation();

  return (
    <div ref={containerRef} className={typeClassName}>
      <Select
        className={`select__input ${classNames}`}
        classNamePrefix="select"
        options={values}
        components={{
          ValueContainer,
          Option,
        }}
        placeholder={<div>{t("select_ellipsis", "select...")}</div>}
        styles={typeStyles}
        value={value}
        inputValue={inputValue}
        isSearchable={false}
        isClearable={false}
        closeMenuOnSelect={true}
        menuPlacement={menuPlacement}
        onChange={(item) => onChange(item)}
        onInputChange={(val) => setInputValue(val)}
        isDisabled={isDisabled}
        noOptionsMessage={(e) =>
          !isTagCreateUpdateAllowed ? (e.inputValue ? "No options" : null) : "No options"
        }
      />
    </div>
  );
};

export default TypeSelect;
