import React, { useMemo, useState } from 'react';
import { WebViewerInstance } from '@pdftron/webviewer';
import IChildren from 'common/types/IChildren';
import DocumentViewerContext, { DocumentViewerContextState } from 'documents-details/contexts/DocumentViewerContext';
import DocumentDetailsTab from 'documents-details/types/DocumentDetailsTab';
import AnnotationItem from 'documents-annotations/types/AnnotationItem';
import CompareOptions from 'documents-details/types/CompareOptions';
import { CompareMode } from 'documents-details/components/DocumentViewer';

export default function DocumentViewerContextProvider({
  children,
}: IChildren) {
  const [webViewerInstance, setWebViewerInstance] = useState<WebViewerInstance | undefined>(undefined);
  const [annotationItems, setAnnotationItems] = useState<AnnotationItem[] | undefined>(undefined);
  const [selectedAnnotationNames, setSelectedAnnotationNames] = useState<string[] | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<DocumentDetailsTab>(DocumentDetailsTab.LinkedIssues);
  const [issueIdCurrentlyLinking, setIssueIdCurrentlyLinking] = useState<string | undefined>(undefined);
  const [compareOptions, setCompareOptions] = useState<CompareOptions>({ enabled: false, mode: CompareMode.Text, compareDocumentVersionId: undefined });

  const state = useMemo<DocumentViewerContextState>(() => ({
    webViewerInstance,
    setWebViewerInstance,
    annotationItems,
    setAnnotationItems,
    selectedAnnotationNames,
    setSelectedAnnotationNames,
    activeTab,
    setActiveTab,
    issueIdCurrentlyLinking,
    setIssueIdCurrentlyLinking,
    compareOptions,
    setCompareOptions,
  }), [activeTab, annotationItems, compareOptions, issueIdCurrentlyLinking, selectedAnnotationNames, webViewerInstance]);
  return (
    <DocumentViewerContext.Provider value={state}>
      {children}
    </DocumentViewerContext.Provider>
  );
}
