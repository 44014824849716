import useCurrentProjectQuery from "projects/hooks/useCurrentProjectQuery";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LinkedTaskItem from "./LinkedTaskItem";

const LinkedTasks = ({
  issue,
  statuses,
  types,
  allIssues,
  isIssueDetail,
  setCurrentIssue,
}) => {
  const { t } = useTranslation();
  const { data: currentProject } = useCurrentProjectQuery();
  const [mainTask, setMainTask] = useState(null);
  const [subTasks, setSubTasks] = useState([]);

  useEffect(() => {
    const initLoading = async () => {
      if (!currentProject || !issue?.id) return;

      if (issue.parentId)
        setMainTask(allIssues.find((item) => item.id === issue.parentId));
      else setMainTask(null);

      if (issue.childrenIds?.length)
        setSubTasks(
          allIssues.filter((item) => issue.childrenIds.includes(item.id))
        );
      else setSubTasks([]);
    };
    initLoading();
  }, [
    currentProject,
    issue?.id,
    issue?.parentId,
    issue?.childrenIds,
    allIssues,
  ]);

  return (
    <div className="issue-link-tasks custom-scrollbar">
      <div className="accordion-title">
        <h3>{t("linked_tasks", "Linked Tasks")}</h3>
      </div>
      <div className="accordion-content issue-link-tasks-content">
        {!isIssueDetail && (
          <div className="issue-link-tasks_item">
            <div className="issue-link-tasks_item-title" />
            <div className="issue-link-tasks_item-content">
              <div className="item-content_column">
                <p className="column-header">{t("due_to", "Due to")}</p>
              </div>
              <div className="item-content_column">
                <p className="column-header">
                  {t("assigned_to", "Assigned to")}
                </p>
              </div>
              <div className="item-content_column">
                <p className="column-header">{t("reviewer", "Reviewer")}</p>
              </div>
              <div className="item-content_column">
                <p className="column-header">{t("status", "Status")}</p>
              </div>
            </div>
          </div>
        )}
        {!!issue.parentId && (
          <div className="issue-main-task">
            <div className="issue-link-tasks_item">
              <p className="item-content_header">
                {t("main_task", "Main Task")}
              </p>
            </div>
            <LinkedTaskItem
              linkedIssue={mainTask}
              types={types}
              statuses={statuses}
              setCurrentIssue={setCurrentIssue}
              isVisibleDetail={!isIssueDetail}
            />
          </div>
        )}
        {!!issue.childrenIds?.length && (
          <div className="issue-sub-tasks">
            <div className="issue-link-tasks_item">
              <p className="item-content_header">
                {t("sub_tasks", "Sub Tasks")}
              </p>
            </div>
            <div className="issue-sub-tasks-content custom-scrollbar">
              {issue.childrenIds.map((id, index) => (
                <LinkedTaskItem
                  key={`task-${index}`}
                  linkedIssue={subTasks.find((task) => task.id === id)}
                  types={types}
                  statuses={statuses}
                  setCurrentIssue={setCurrentIssue}
                  isVisibleDetail={!isIssueDetail}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LinkedTasks;
