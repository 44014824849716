import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getGeneralSvg, getTypeSvg } from "../SVGs";

import LinkedSubTasksOld from "./LinkedSubTasksOld";
import MentionView from "../MentionView";

const IssueItemContentOld = ({
  issue,
  issueType,
  imageUrl,
  imageName,
  baseIssuesUrl,
  viewIssue,
  isModelIssues,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="issue-item__header">
        <div className="issue-item__header-flex">
          <div
            className="issue-item__type"
            style={{
              background: `${issueType?.color}33`,
              color: issueType?.color,
            }}
          >
            {getTypeSvg(issueType?.icon, issueType?.color)}
            <p className="issue-item__type-label">{issueType?.label || ""}</p>
          </div>
          {!!issue.dueDate && (
            <div className={`issue-item__date-created label${new Date(issue.dueDate) < new Date() ? ' bg-red' : ' bg-dark-grey'}`}>
              {getGeneralSvg("clock")}
              <p>
                {t('issue_due_date_new', 'Due on {{dateString}}', { dateString: new Date(issue.dueDate).toLocaleDateString('de-DE') })}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="issue-item__body">
        <h3 className="issue-item__title text-ellipsis">
          <span className="issue-number">{issue.issueNumber}</span>
          {issue.title}
        </h3>
        {!!issue.description && (
          <MentionView
            className="issue-item__description txt-maxline-2"
            value={issue.description}
          />
        )}
        {!!imageUrl && issue.viewpointIds.length ? (
          <div className="issue-item__image">
            <img
              className={imageName === "visoplan.png" ? "image-logo" : ""}
              src={imageUrl}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="issue-item__footer">
        <LinkedSubTasksOld
          issue={issue}
          baseIssuesUrl={baseIssuesUrl}
          viewIssue={viewIssue}
          isModelIssues={isModelIssues}
        />
        {!!issue.assignedUsers?.length && (
          <p className="issue-item__assigned">
            <strong>{`${t("assigned_to", "Assigned to")}: `}</strong>
            <span
              className={`issue-item__assigned-name text-ellipsis ${
                issue.assignedUsers.length - 1 > 0 ? "" : "w-fit"
              }`}
            >
              {`${issue.assignedUsers[0].firstName} ${issue.assignedUsers[0].lastName}`}
            </span>
            {issue.assignedUsers.length - 1 > 0 && (
              <span className="text-underline">{`+${
                issue.assignedUsers.length - 1
              } more`}</span>
            )}
          </p>
        )}
      </div>
    </>
  );
};

export default IssueItemContentOld;
