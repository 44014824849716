import buildQuery from 'odata-query';
import { useCallback } from 'react';
import _ from 'lodash';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import { VisoOdataQueryKey } from 'api/hooks/useDefaultEntityQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';

export default function useDefaultOdataQueryFn<
  TDto,
  TCachedEntity extends TDto,
  TEndpoint extends ApiEndpoint,
  >(entityFactory?: (dto: TDto) => Readonly<TCachedEntity>) {
  const axiosInstance = useAxiosInstance();
  return useCallback(async ({ queryKey }: { queryKey: VisoOdataQueryKey<TEndpoint, TDto> }) => {
    const [endpoint, , , odataQuery, args] = queryKey;
    const odataQueryString = buildQuery<TDto>(odataQuery);
    const argsArray = _.map(args, (value, key) => ({ key, value }));
    const queryArgsString = argsArray.length ? `${odataQueryString.length ? '&' : '?'}${argsArray.map(({ value, key }) => `${key}=${value}`).join('&')}` : '';
    const url = `${endpoint}${odataQueryString}${queryArgsString}`;
    const response = await axiosInstance.get<TDto[]>(url);
    if (entityFactory) return response.data.map(entityFactory);
    return response.data;
  }, [axiosInstance, entityFactory]);
}
