import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useMemo } from 'react';
import useCurrentUserQueryKey from 'users/hooks/useCurrentUserQueryKey';
import useCurrentUserQueryOptions, { CurrentUserQueryKey } from 'users/hooks/useCurrentUserQueryOptions';
import User from 'users/types/User';

type CurrentUserUseQueryOptions = Omit<UseQueryOptions<User | undefined, unknown, User | undefined, CurrentUserQueryKey>, 'queryFn'>;

export default function useCurrentUserQuery(options?: Omit<UseQueryOptions<User | undefined, unknown, User | undefined, CurrentUserQueryKey>, 'queryKey' | 'queryFn'> | undefined) {
  const queryKey = useCurrentUserQueryKey();
  const currentUserQueryOptions = useCurrentUserQueryOptions();
  const queryOptions = useMemo<CurrentUserUseQueryOptions>(() => ({
    queryKey,
    ...currentUserQueryOptions,
    ...options,
  }), [currentUserQueryOptions, options, queryKey]);
  return useQuery(queryOptions);
}
